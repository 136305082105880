import { AnimatedSwitch } from '@shared/components/AnimatedSwitch';
import React, { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { FlowContext } from '.';
import { Account } from '../Account';
import { Flow } from './routes/Flow/Flow';
import { ThankYou } from './routes/ThankYou/ThankYou';

export const Routes = (props) => {
  const history = useHistory();
  const context = useContext(FlowContext);
  return (
    <AnimatedSwitch>
      <Route exact path="/">
        <Flow />
      </Route>
      <Route exact path="/thank-you">
        <ThankYou />
      </Route>
      <Route path="/account/:path">
        <Account goBack={history.goBack} linkArgs={{ flow: context.flow }} />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </AnimatedSwitch>
  );
};
