import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Controller } from './Controller';
import { Session } from './Session';

export const App = () => {
  return (
    <BrowserRouter>
      <Session>
        <Controller />
      </Session>
    </BrowserRouter>
  );
};
