import { Paragraph, RichText, Styles, media } from '@foyyay/flow-elements';
import { AccountPrompt } from '@shared/components/AccountPrompt';
import { ActionButton } from '@shared/components/ActionButton';
import { DelayedRender } from '@shared/components/DelayedRender';
import { selectFlowConfigById } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FlowContext } from '../../..';
import { AccountButtons } from '../components/AccountButtons';
import { ThankYouLayout } from '../components/Layout';
import { SectionText } from '../components/SectionText';

export const CustomThankYouMessage = (props) => {
  return (
    <ThankYouLayout.Container>
      <Header />
      <Actions />
      <Footer />
      <SignInPrompt />
    </ThankYouLayout.Container>
  );
};

const Header = () => {
  const { message_thankyou_title: title, message_thankyou_body: body } = useFlowConfig();

  if (title === undefined && body === undefined) {
    return null;
  }

  return <SectionText title={title}>{body}</SectionText>;
};

const Actions = () => {
  const { message_thankyou_actions_type: type, message_thankyou_actions: actions } = useFlowConfig();

  if (type === 'default') {
    return <AccountButtons />;
  }

  if (actions === undefined || actions.length < 1) {
    return null;
  }

  return (
    <SectionActions>
      {actions.map((action) => (
        <ActionButton key={action.id} action={action} />
      ))}
    </SectionActions>
  );
};

const Footer = () => {
  const config = useFlowConfig();

  if (config.message_thankyou_footer === undefined) {
    return null;
  }

  return (
    <SectionFooter>
      <Container>
        <RichText nodes={config.message_thankyou_footer} />
      </Container>
    </SectionFooter>
  );
};

const Container = styled.div`
  padding: 0 1.2rem;

  ${media.tabletLandscapeAndUp`
  padding: 0 1.5rem;
`}
`;

const SignInPrompt = () => {
  const { message_thankyou_sign_in_prompt_title: title, message_thankyou_sign_in_prompt_body: body } = useFlowConfig();

  if (title === undefined && body === undefined) {
    return null;
  }

  return (
    <DelayedRender delay={3000}>
      <AccountPrompt headline={title} statement={body} />
    </DelayedRender>
  );
};

const useFlowConfig = () => {
  const { currentFlowId } = useContext(FlowContext);
  return useSelector((state) => selectFlowConfigById(state, currentFlowId));
};

const SectionActions = styled.div`
  max-width: 35rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;

  ${media.tabletPortraitAndUp`
    margin-top: 5.8rem;
    margin-bottom: 8.4rem;
  `}
`;

const SectionFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;

  > div {
    max-width: 30rem;
    opacity: 0.65;
  }

  ${Paragraph} {
    ${Styles.T1}
  }
`;
