import { sleep } from '@nucleus/lib-function';
import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

export const AnimatedSwitch = (props) => {
  const { children } = props;
  const location = useLocation();
  const transitions = useTransition(location, (location) => location.pathname, {
    from: {
      opacity: 0,
    },
    enter: (item) => async (next, cancel) => {
      await sleep(300);
      await next({ opacity: 1 });
    },
    leave: {
      opacity: 0,
    },
    config: {
      tension: 120,
      friction: 20,
    },
  });

  return (
    <>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props} className="AnimatedSwitch">
          <Switch location={item}>{children}</Switch>
        </animated.div>
      ))}
    </>
  );
};
