import styled from 'styled-components';

const CSS_TRANSITION_BUTTON_TRANSFORM = 'transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1)';

export const Button = styled.button.attrs((props) => ({ onTouchStart: () => true }))`
  align-items: center;
  text-align: center;
  background: none;
  border-radius: 3rem;
  border: none;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  outline: none;
  overflow: hidden;
  padding: 0 2rem;
  user-select: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  pointer-events: auto;
  cursor: pointer;

  transition: ${CSS_TRANSITION_BUTTON_TRANSFORM}, background-color 0.1s cubic-bezier(0, 0, 1, 1);

  @media (hover: hover) {
    :hover {
      transition: ${CSS_TRANSITION_BUTTON_TRANSFORM}, background-color 0s cubic-bezier(0, 0, 1, 1);
    }
  }

  :active:not([disabled]) {
    transition: ${CSS_TRANSITION_BUTTON_TRANSFORM}, background-color 0s cubic-bezier(0, 0, 1, 1);
  }

  // TODO: remove this when we have a better solution for the button hover state transitions
  & > * {
    transition: ${CSS_TRANSITION_BUTTON_TRANSFORM}, background-color 0.1s cubic-bezier(0, 0, 1, 1);
  }

  @media (hover: hover) {
    :hover {
      & > * {
        transition: ${CSS_TRANSITION_BUTTON_TRANSFORM}, background-color 0s cubic-bezier(0, 0, 1, 1);
      }
    }
  }

  :active:not([disabled]) {
    & > * {
      transition: ${CSS_TRANSITION_BUTTON_TRANSFORM}, background-color 0s cubic-bezier(0, 0, 1, 1);
    }
  }
`;
