import { ButtonOptionPrimary, ButtonOptionSecondary, H2, Prompt, PromptActions, T1 } from '@foyyay/flow-elements';
import { ModalOverlay } from '@shared/components/Modal';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const ModalDialogPlaidSignIn = (props) => {
  const history = useHistory();

  const handleClick = () => {
    props.onClose();
    history.push('/account/sign-in');
  };

  return (
    <ModalOverlay show={props.show}>
      <Prompt>
        <H2>To link your bank using Plaid, you’ll need a Nucleus account (Sign Up or Sign In)</H2>
        <T1>
          This is to ensure your bank information stays secure and is linked to your account for use in the future.
        </T1>
        <PromptActions>
          <ButtonOptionPrimary onClick={handleClick}>Continue to Sign In</ButtonOptionPrimary>
          <ButtonOptionSecondary onClick={props.onClose}>or, Back to options</ButtonOptionSecondary>
        </PromptActions>
      </Prompt>
    </ModalOverlay>
  );
};
