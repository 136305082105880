/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { NUCLEUS_PLATFORM_API_URL } from '@shared/constants/urls';
import { LocalStorage } from '@shared/lib/localstorage';
import { richFetch } from '@shared/lib/richFetch';
import { selectLauncherShortcode } from '@shared/reducers/launcher';
import { addDays } from 'date-fns';
import { Dispatch } from 'react-redux';
import type { History } from 'history';
import { InfoCard } from 'types/launcher';
import { LauncherConfig } from 'types/launcherconfig';
import {
  ACTION_OPEN_FLOW,
  ACTION_OPEN_LAUNCHER,
  ACTION_OPEN_LAUNCHER_ALIASES,
  ACTION_OPEN_PRAYER_HUB,
  ACTION_OPEN_SEARCH,
  ACTION_OPEN_SIGN_IN,
  BANNER_ACTION_DISMISS_INTERVAL_DEFAULT,
  BANNER_ACTION_DISMISS_VALUE_DEFAULT,
  BANNER_DISMISS_INTERVAL_DEFAULT,
  BANNER_DISMISS_VALUE_DEFAULT,
  COOKIE_BANNER_DISMISSED,
  COOKIE_FEATURED_ITEM_CALLOUT,
  COOKIE_LAUNCHER_DISCOVERED,
  NUCLEUS_LAUNCHER_ACTION_TRIGGER,
} from '../constants/launcher';
import { fetchFlowConfig } from './flows';
import { getParentWindow } from '@shared/lib/getParentWindow';
import { objectToQueryString } from '@nucleus/lib-shape';

const MAP_SETTINGS_TO_ACTIONS = {
  account: async (dispatch: Dispatch, history: History, value: string) => await dispatch(applyAccountSettings(value)),
  calloutMessage: async (dispatch: Dispatch, history: History, value: string) =>
    await dispatch(applyCalloutSettings(value)),
  discoveryMessage: async (dispatch: Dispatch, history: History, value: string) =>
    await dispatch(applyDiscoverySettings(value)),
  featuredItemCallout: async (dispatch: Dispatch, history: History, value: string) =>
    await dispatch(applyFeaturedItemCalloutSettings(value)),
  flowId: async (dispatch: Dispatch, history: History, value: string) =>
    await dispatch(initializeLauncherWithFlow(history, value)),
  launcherId: async (dispatch: Dispatch, history: History, value: string) =>
    await dispatch(fetchLauncherConfig(value))
      .then(unwrapResult)
      .catch((error) => {
        console.error(error);
        throw new Error('Failed to load Launcher');
      }),
  mode: async (dispatch: Dispatch, history: History, value: string) => await dispatch(applyModeSettings(value)),
  overlay: async (dispatch: Dispatch, history: History, value: string) => await dispatch(applyOverlaySettings(value)),
  title: async (dispatch: Dispatch, history: History, value: string) => await dispatch(applyTitleSettings(value)),
  trigger: async (dispatch: Dispatch, history: History, value: string) => await dispatch(applyTriggerSettings(value)),
  theme: async (dispatch: Dispatch, history: History, value: string) => await dispatch(applyThemeSettings(value)),
};

const ORDERED_SETTINGS: (keyof typeof MAP_SETTINGS_TO_ACTIONS)[] = [
  'mode',
  'launcherId',
  'flowId',
  'account',
  'calloutMessage',
  'discoveryMessage',
  'featuredItemCallout',
  'overlay',
  'title',
  'trigger',
  'theme',
];

export function initializeLauncher(history: History, settings: { [x: string]: any }) {
  return async function (dispatch: Dispatch) {
    for (const key of ORDERED_SETTINGS) {
      const value = settings[key];

      if (value === undefined) {
        continue;
      }

      const action = MAP_SETTINGS_TO_ACTIONS[key];

      if (typeof action === 'function') {
        await action(dispatch, history, value);
      }
    }
  };
}

export function initializeLauncherWithFlow(history: History, flowShortcode: string) {
  return async function (dispatch: Dispatch) {
    const payload = await dispatch((fetchFlowConfig as any)({ flowShortcode: flowShortcode }))
      .then(unwrapResult)
      .catch((error: any) => {
        throw new Error('Failed to load flow');
      });
    await dispatch(setLauncherConfigFlow(payload.config));
    history.replace(`/flow/${flowShortcode}`);
  };
}

export const fetchLauncherConfig = createAsyncThunk<LauncherConfig, string>(
  'launcher/config/fetch',
  async (launcherShortcode, thunkAPI) => {
    const state = thunkAPI.getState();
    const params = objectToQueryString({
      mode: state.launcher.mode,
    });

    return await richFetch('GET', NUCLEUS_PLATFORM_API_URL, `/launcher/config/${launcherShortcode}?${params}`);
  }
);

export const setLauncherConfigFlow = createAction<LauncherConfig>('launcher/config/flow');

export const applyAccountSettings = createAction<any>('launcher/settings/account');

export const applyTriggerSettings = createAction<any>('/launcher/settings/trigger');

export const applyThemeSettings = createAction<any>('/launcher/settings/theme');

export const applyCalloutSettings = createAction<any>('/launcher/settings/callout');

export const applyOverlaySettings = createAction<any>('/launcher/settings/overlay');

export const applyDiscoverySettings = createAction<any>('/launcher/settings/discovery');

export const applyFeaturedItemCalloutSettings = createAction<any>('/launcher/settings/featureditemcallout');

export const applyModeSettings = createAction<any>('/launcher/settings/mode');

export const applyTitleSettings = createAction<any>('/launcher/settings/title');

export function discoverLauncher(action: { payload: any; type: string }, expiresDays: number) {
  return function (dispatch: Dispatch) {
    dispatch(action);
    LocalStorage.setItem(COOKIE_LAUNCHER_DISCOVERED, true, addDays(new Date(), expiresDays).toISOString());
  };
}

export function dismissFeaturedItemCallout(action: any, expiresDays: number, actionId: string) {
  return function (dispatch: Dispatch) {
    dispatch(action);
    LocalStorage.setItem(
      `${COOKIE_FEATURED_ITEM_CALLOUT}_${actionId}`,
      true,
      addDays(new Date(), expiresDays).toISOString()
    );
  };
}

export function openOverlayAfter(action: { (): any; (): any; (): any; (): any; (): any; (): any; (): void }) {
  return function (dispatch: Dispatch) {
    action();
    dispatch(openOverlay());
  };
}

export const openOverlay = createAction('launcher/overlay/open');

export const closeOverlay = createAction<any>('launcher/overlay/close');

export const toggleOverlay = createAction<any>('launcher/overlay/toggle');

export const openCallout = createAction<any>('launcher/callout/open');

export const closeCallout = createAction<any>('launcher/callout/close');

export const toggleCallout = createAction<any>('launcher/callout/toggle');

export const openFeaturedItemCallout = createAction<any>('launcher/featureditemcallout/open');

export const closeFeaturedItemCallout = createAction<any>('launcher/featureditemcallout/close');

export const toggleFeaturedItemCallout = createAction<any>('launcher/featureditemcallout/toggle');

export const openFullscreen = createAction<any>('launcher/fullscreen/open');

export const closeFullscreen = createAction<any>('launcher/fullscreen/close');

export const toggleFullscreen = createAction<any>('launcher/fullscreen/toggle');

export const setForceFullscreen = createAction<any>('launcher/force-fullscreen/set');

export const openDiscoveryMessage = createAction<any>('launcher/discovery/open');

export const closeDiscoveryMessage = createAction<any>('launcher/discovery/close');

export const toggleDiscoveryMessage = createAction<any>('launcher/discovery/toggle');

export const dismissBannerByType = createAction('launcher/banner/dismiss', (type: string, id: string) => ({
  payload: {
    type: type,
    id: id,
  },
}));

export const fetchBanners = createAsyncThunk<LauncherConfig, void>('launcher/banners/fetch', async (args, thunkAPI) => {
  const state = thunkAPI.getState();
  const launcherShortcode = selectLauncherShortcode(state);
  return await richFetch('GET', NUCLEUS_PLATFORM_API_URL, `/launcher/config/${launcherShortcode}`);
});

export const refreshActiveBannersByType = createAction('launcher/banners/refresh', (type: string) => ({
  payload: {
    type: type,
  },
}));

export function dismissBanner(banner: {
  dismissible: { durationInterval: any; durationValue: any };
  type: string;
  id: string;
}) {
  return async function (dispatch: Dispatch) {
    const daysToDismiss = calculateBannerDismissedIntervalInDays(
      banner.dismissible?.durationInterval ?? BANNER_DISMISS_INTERVAL_DEFAULT,
      banner.dismissible?.durationValue ?? BANNER_DISMISS_VALUE_DEFAULT
    );
    await dispatch(dismissBannerByType(banner.type, banner.id));
    LocalStorage.setItem(
      `${COOKIE_BANNER_DISMISSED}_${banner.type}_${banner.id}`,
      true,
      addDays(new Date(), daysToDismiss).toISOString()
    );
  };
}

export function activateBannerAction(
  banner: {
    action: { dismissible: { enabled: boolean; durationInterval: any; durationValue: any }; type: string | number };
    type: string;
    id: string;
  },
  actionByTypeMap: {
    [x: string]: any;
    flow?: (action: any) => void;
    prayerhub?: (action: any) => void;
    url?: (action: any) => any;
  }
) {
  return async function (dispatch: Dispatch) {
    if (banner.action?.dismissible?.enabled === true) {
      const daysToDismiss = calculateBannerDismissedIntervalInDays(
        banner.action?.dismissible?.durationInterval ?? BANNER_ACTION_DISMISS_INTERVAL_DEFAULT,
        banner.action?.dismissible?.durationValue ?? BANNER_ACTION_DISMISS_VALUE_DEFAULT
      );
      await dispatch(dismissBannerByType(banner.type, banner.id));
      LocalStorage.setItem(
        `${COOKIE_BANNER_DISMISSED}_${banner.type}_${banner.id}`,
        true,
        addDays(new Date(), daysToDismiss).toISOString()
      );
    }

    const doAction = actionByTypeMap[banner.action.type];
    if (typeof doAction === 'function') {
      doAction(banner.action);
    }
  };
}

function calculateBannerDismissedIntervalInDays(interval: string, value: number | undefined) {
  if (value === undefined) {
    return 0;
  }

  if (interval === 'minute') {
    return value / (60 * 24);
  }

  if (interval === 'day') {
    return value;
  }

  if (interval === 'week') {
    return value * 7;
  }

  if (interval === 'month') {
    return value * 30;
  }

  if (interval === 'year') {
    return value * 365;
  }

  if (interval === 'infinite') {
    return 365 * 10; // 10 years out is long enough
  }

  return 0;
}

function navigateToFlow(history: History, flowShortcode: string, data: any) {
  return function (dispatch: Dispatch) {
    const basePath = window.location.protocol.includes('http') === true ? window.location.pathname : '';
    const pathParts = [`${basePath}/flow/${flowShortcode}`];

    if (data) {
      pathParts.push('?nldata=', data);
    }

    history.push(pathParts.join(''));
  };
}

function navigateToSpringboard(history: History, params: any) {
  return function (dispatch: Dispatch) {
    const basePath = window.location.protocol.includes('http') === true ? window.location.pathname : '';
    history.replace({
      pathname: `${basePath}/`,
      search: '',
      state: {
        itemIdToActivate: params.nlitem,
        infocardIdToActivate: params.nlinfocard,
        initialPath: params.initialPath,
      },
    });
  };
}

function navigateToPrayerHub(history: History, shortcode: string) {
  return function (dispatch: Dispatch) {
    history.push(`/prayer/${shortcode}`);
  };
}

const MAP_TRIGGER_TO_ACTION: { [key: string]: (history: History, params: any) => (dispatch: Dispatch) => void } = {
  [ACTION_OPEN_LAUNCHER]: (history: History, params: any) => (dispatch: Dispatch) => {
    dispatch(navigateToSpringboard(history, params));
    getParentWindow().NucleusLauncher?.showTrigger?.();
    getParentWindow().NucleusLauncher?.showLauncher?.();
    dispatch(openOverlay());
  },
  [ACTION_OPEN_SEARCH]: (history: History) => () => history.push('/'),
  [ACTION_OPEN_SIGN_IN]: (history: History) => (dispatch) => {
    dispatch(navigateToSpringboard(history, { initialPath: '/account/sign-in' }));
    getParentWindow().NucleusLauncher?.showTrigger?.();
    getParentWindow().NucleusLauncher?.showLauncher?.();
    dispatch(openOverlay());
  },
  [ACTION_OPEN_FLOW]: (history: History, params: { nlflow: string; nldata: any }) => (dispatch: Dispatch) => {
    dispatch(navigateToFlow(history, params.nlflow, params.nldata));
    getParentWindow().NucleusLauncher?.showTrigger?.();
    getParentWindow().NucleusLauncher?.showLauncher?.();
    dispatch(openOverlay());
  },
  [ACTION_OPEN_PRAYER_HUB]: (history: History, params: { nlprayerhub: string }) => (dispatch: Dispatch) => {
    dispatch(navigateToPrayerHub(history, params.nlprayerhub));
    getParentWindow().NucleusLauncher?.showTrigger?.();
    getParentWindow().NucleusLauncher?.showLauncher?.();
    dispatch(openOverlay());
  },
};

export function applyLinkParameters(history: History, params?: any) {
  return function (dispatch: Dispatch) {
    if (params === undefined) {
      return;
    }

    let trigger = params[NUCLEUS_LAUNCHER_ACTION_TRIGGER];

    if (trigger === undefined) {
      return;
    }

    if (ACTION_OPEN_LAUNCHER_ALIASES.includes(trigger)) {
      trigger = ACTION_OPEN_LAUNCHER;
    }

    const action = MAP_TRIGGER_TO_ACTION[trigger];

    if (action === undefined) {
      return;
    }

    dispatch(action(history, params));

    return true;
  };
}

interface FetchInfoCardConfigArgs {
  launcherShortcode: string;
  infocardId: string;
}

interface FetchInfoCardConfigResponse {
  infocard: InfoCard;
}

export const fetchInfoCardConfig = createAsyncThunk<FetchInfoCardConfigResponse, FetchInfoCardConfigArgs>(
  'infocard/config/fetch',
  async (args) => {
    return await richFetch(
      'GET',
      NUCLEUS_PLATFORM_API_URL,
      `/launcher/config/${args.launcherShortcode}/infocards/${args.infocardId}`
    );
  }
);
