import { Theme } from '@foyyay/flow-elements';
import {
  KeyPrefixFlow as KEY_PREFIX_FLOW,
  KeyPrefixLauncher as KEY_PREFIX_LAUNCHER,
  KeyPrefixPrayerhub as KEY_PREFIX_PRAYERHUB,
} from '@nucleus/ncu-key-prefix';
import ImgNucleusAppIcon192 from '@shared/assets/nucleus-app-icon-n-reversed-vibrant-192.png';
import ImgNucleusAppIcon256 from '@shared/assets/nucleus-app-icon-n-reversed-vibrant-256.png';
import {
  default as ImgNucleusAppIcon,
  default as ImgNucleusAppIcon512,
} from '@shared/assets/nucleus-app-icon-n-reversed-vibrant-512.png';
import { NUCLEUS_PLATFORM_API_URL, PUBLIC_URL } from '@shared/constants/urls';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

export const Manifest = (props) => {
  const theme = useContext(Theme);

  if (theme === undefined) {
    return null;
  }

  const [currentApp, manifest] = buildManifest(props.config, props.location, theme);
  const manifestEncoded = encodeURIComponent(JSON.stringify(manifest));
  const manifestUrl = `${NUCLEUS_PLATFORM_API_URL}/${currentApp}/config/${props.config.shortcode}/manifest.json?manifest=${manifestEncoded}`;

  return (
    <Helmet>
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content={theme.Background.Color.Default.Background} />
      <link rel="apple-touch-icon" href={props.config.manifest?.icon256 ?? ImgNucleusAppIcon} />
      <link rel="apple-touch-icon" sizes="180x180" href={props.config.manifest?.icon192 ?? ImgNucleusAppIcon192} />
      <link rel="apple-touch-icon" sizes="256x256" href={props.config.manifest?.icon256 ?? ImgNucleusAppIcon256} />
      <link rel="apple-touch-icon" sizes="512x512" href={props.config.manifest?.icon512 ?? ImgNucleusAppIcon512} />
      <link rel="manifest" href={manifestUrl} crossorigin />
    </Helmet>
  );
};

const buildManifest = (config, location, theme) => {
  const currentApp = computeCurrentApp(config);
  const manifest = {
    icons: [
      {
        src: config.manifest?.icon192 ?? ImgNucleusAppIcon192,
        type: 'image/png',
        purpose: 'maskable',
        sizes: '192x192', // android/chrome required size
      },
      {
        src: config.manifest?.icon256 ?? ImgNucleusAppIcon256,
        type: 'image/png',
        purpose: 'maskable',
        sizes: '256x256', // high res for iPhone/iPad
      },
      {
        src: config.manifest?.icon512 ?? ImgNucleusAppIcon512,
        type: 'image/png',
        purpose: 'maskable',
        sizes: '512x512', // windows, desktop chrome installations
      },
      {
        src: config.manifest?.icon192 ?? ImgNucleusAppIcon192,
        type: 'image/png',
        purpose: 'any',
        sizes: '192x192', // android/chrome required size
      },
      {
        src: config.manifest?.icon256 ?? ImgNucleusAppIcon256,
        type: 'image/png',
        purpose: 'any',
        sizes: '256x256', // high res for iPhone/iPad
      },
      {
        src: config.manifest?.icon512 ?? ImgNucleusAppIcon512,
        type: 'image/png',
        purpose: 'any',
        sizes: '512x512', // windows, desktop chrome installations
      },
    ],
    id: currentApp + '_' + config.shortcode,
    start_url: PUBLIC_URL + location.pathname,
    scope: PUBLIC_URL + location.pathname,
    display: 'standalone',
    theme_color: theme.Background.Color.Default.Background,
    background_color: theme.Background.Color.Default.Background,
    ...customAppManifest(currentApp, config, location, theme),
  };

  return [currentApp, manifest];
};

const MANIFEST_DEFAULTS_BY = {
  [KEY_PREFIX_LAUNCHER]: (config, location, theme) => ({
    name: config.manifest?.name ?? 'Next Steps Launcher',
    short_name: config.manifest?.short_name ?? 'Next Steps',
    description: config.manifest?.description ?? 'Take your next step here.',
  }),
  [KEY_PREFIX_PRAYERHUB]: (config, location, theme) => ({
    name: config.manifest?.name ?? 'Prayer Hub',
    short_name: config.manifest?.short_name ?? 'Prayer Hub',
    description: config.manifest?.description ?? 'Pray with us.',
  }),
  [KEY_PREFIX_FLOW]: (config, location, theme) => ({
    name: config.manifest?.name ?? config.title,
    short_name: config.manifest?.short_name ?? config.title,
    description: config.manifest?.description ?? '',
  }),
};

const customAppManifest = (app, config, location, theme) => MANIFEST_DEFAULTS_BY[app](config, location, theme);

const computeCurrentApp = (config) => {
  if (isPrefixedKey(KEY_PREFIX_LAUNCHER, config.id)) {
    return KEY_PREFIX_LAUNCHER;
  }

  if (isPrefixedKey(KEY_PREFIX_FLOW, config.id)) {
    return KEY_PREFIX_FLOW;
  }

  if (isPrefixedKey(KEY_PREFIX_PRAYERHUB, config.id)) {
    return KEY_PREFIX_PRAYERHUB;
  }

  return KEY_PREFIX_LAUNCHER; // default to launcher if no match for some reason
};

const isPrefixedKey = (prefix, value) => {
  const parts = value?.split('_');

  if (parts === undefined) {
    return false;
  }

  if (parts.length !== 2) {
    return false;
  }

  if (prefix !== undefined && parts[0] !== prefix) {
    return false;
  }

  return true;
};
