export const enum LAUNCHER_ACTION_TYPE {
  Dismiss = 'dismiss',
  Flow = 'flow',
  Giving = 'giving',
  InfoCard = 'infocard',
  Url = 'url',
  Page = 'page',
  Prayer = 'prayer',
  PrayerHub = 'prayerhub',
}
