export type StatusPageActionType = 'url' | 'launcher' | 'flow' | 'email' | 'button';

export const STATUS_PAGE_ACTION_TYPE_URL = 'url';
export const STATUS_PAGE_ACTION_TYPE_LAUNCHER = 'launcher';
export const STATUS_PAGE_ACTION_TYPE_FLOW = 'flow';
export const STATUS_PAGE_ACTION_TYPE_EMAIL = 'email';
export const STATUS_PAGE_ACTION_TYPE_BUTTON = 'button';

export type StatusPageActionPriority = 'primary' | 'secondary' | 'tertiary';

export const STATUS_PAGE_ACTION_PRIORITY_PRIMARY = 'primary';
export const STATUS_PAGE_ACTION_PRIORITY_SECONDARY = 'secondary';
export const STATUS_PAGE_ACTION_PRIORITY_TERTIARY = 'tertiary';
