import { SOURCE_TYPES_OFFLINE } from '@shared/constants';
import { PLAID_CLIENT_FRAGMENT } from '@shared/constants/plaid';
import { REBELPAY_API_BASE_URL, REBELPAY_API_KEY_PUBLIC } from '../constants/rebelpay';
import { richFetch } from './richFetch';

export async function getToken(merchantId, tokenOptions) {
  let token = createToken(merchantId, tokenOptions);

  try {
    token = await token;
  } catch (error) {
    console.error('Error fetching token', error);
    throw error;
  }

  if (token?.challenge !== undefined) {
    const challengeError = new Error('Challenge required');
    challengeError.body = {
      ...token,
      failed: tokenOptions.challenge?.id !== undefined,
    };
    throw challengeError;
  }

  return token;
}

async function createToken(merchantId, tokenOptions) {
  const body = {
    merchant_id: process.env['REACT_APP_REBELPAY_MERCHANT_ID'] || merchantId,
    ...tokenOptions,
  };

  const options = {
    headers: {
      Authorization: REBELPAY_API_KEY_PUBLIC,
    },
  };

  const response = await richFetch('POST', REBELPAY_API_BASE_URL, '/token', body, options);

  const { type, data } = response;
  const token = data[type];

  return token;
}

export async function getFee(merchantId, sourceType, amount) {
  // if sourceType is cash/check/other, we don't need to fetch a fee
  if (SOURCE_TYPES_OFFLINE.includes(sourceType)) {
    return {
      amount: amount,
      processorFee: 0,
      processorExtra: 0,
      source_type: sourceType,
    };
  }

  let feeData = fetchFee(merchantId, sourceType, amount);

  try {
    feeData = await feeData;
  } catch (error) {
    console.error('Error fetching fee', error);
  }

  feeData.source_type = sourceType;

  return feeData;
}

async function fetchFee(merchantId, sourceType, amount) {
  const body = {
    merchant_id: process.env['REACT_APP_REBELPAY_MERCHANT_ID'] || merchantId,
    source_type: sourceType,
    amount: amount,
  };

  const options = {
    headers: {
      Authorization: REBELPAY_API_KEY_PUBLIC,
    },
  };

  const response = await richFetch('POST', REBELPAY_API_BASE_URL, '/fee', body, options);

  const { type, data } = response;
  const feeData = data[type];

  return feeData;
}

export async function getPlaidLinkToken(userId) {
  const body = {
    plaidClientIdFragment: PLAID_CLIENT_FRAGMENT,
    userId: userId,
  };

  const options = {
    headers: {
      Authorization: REBELPAY_API_KEY_PUBLIC,
    },
  };

  const response = await richFetch('POST', REBELPAY_API_BASE_URL, '/plaid/linktoken', body, options);

  const { type, data } = response;
  const token = data[type];

  return token;
}
