import { STEP_TYPE } from '@shared/constants';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { StepAdditionalContact } from './StepAdditionalContact';
import { StepAmount } from './StepAmount';
import { StepBirthday } from './StepBirthday/StepBirthday';
import { StepContact } from './StepContact/StepContact';
import { StepContactText } from './StepContactText';
import { StepDateTime } from './StepDateTime/StepDateTime';
import { StepEmail } from './StepEmail';
import { StepFileUpload } from './StepFileUpload/StepFileUpload';
import { StepGender } from './StepGender';
import { StepInfo } from './StepInfo';
import { StepMultipleChoice } from './StepMultipleChoice/StepMultipleChoice';
import { StepName } from './StepName';
import { StepPaymentAmount } from './StepPaymentAmount';
import { StepPaymentMemo } from './StepPaymentMemo';
import { StepPaymentScheduleInterval } from './StepPaymentScheduleInterval';
import { StepPaymentScheduleStartDate } from './StepPaymentScheduleStartDate';
import { StepPaymentSourceNew } from './StepPaymentSourceNew';
import { StepPaymentSourceType } from './StepPaymentSourceType';
import { StepPaymentSummary } from './StepPaymentSummary';
import { StepPhone } from './StepPhone';
import { StepPhysicalAddress } from './StepPhysicalAddress/StepPhysicalAddress';
import { StepPrayerPrivacy } from './StepPrayerPrivacy/StepPrayerPrivacy';
import { StepProfile } from './StepProfile';
import { StepSmartBirthday } from './StepSmartBirthday/StepSmartBirthday';
import { StepSmartContact } from './StepSmartContact';
import { StepSmartEmailAddress } from './StepSmartEmailAddress/StepSmartEmailAddress';
import { StepSmartGender } from './StepSmartGender';
import { StepSmartName } from './StepSmartName/StepSmartName';
import { StepSmartPhone } from './StepSmartPhone/StepSmartPhone';
import { StepSmartPhysicalAddress } from './StepSmartPhysicalAddress/StepSmartPhysicalAddress';
import { StepSummary } from './StepSummary';
import { StepText } from './StepText';

const STEP_COMPONENT_BY = deepFreeze({
  [STEP_TYPE.ADDITIONAL_CONTACT]: StepAdditionalContact,
  [STEP_TYPE.BIRTHDAY]: StepBirthday,
  [STEP_TYPE.CONDITIONAL_PRESTEP]: StepMultipleChoice,
  [STEP_TYPE.CONTACT]: StepContact,
  [STEP_TYPE.CONTACT_TEXT]: StepContactText,
  [STEP_TYPE.DATETIME]: StepDateTime,
  [STEP_TYPE.EMAIL_ADDRESS]: StepEmail,
  [STEP_TYPE.FILE_UPLOAD]: StepFileUpload,
  [STEP_TYPE.GENDER]: StepGender,
  [STEP_TYPE.GIVING_AMOUNT]: StepAmount,
  [STEP_TYPE.GIVING_DESIGNATION]: StepMultipleChoice,
  [STEP_TYPE.GIVING_SUMMARY]: StepSummary,
  [STEP_TYPE.INFO]: StepInfo,
  [STEP_TYPE.MULTIPLE_CHOICE]: StepMultipleChoice,
  [STEP_TYPE.NAME]: StepName,
  [STEP_TYPE.PAYMENT_AMOUNT]: StepPaymentAmount,
  [STEP_TYPE.PAYMENT_INFO]: StepPaymentSourceNew,
  [STEP_TYPE.PAYMENT_MEMO]: StepPaymentMemo,
  [STEP_TYPE.PAYMENT_SCHEDULE_INTERVAL]: StepPaymentScheduleInterval,
  [STEP_TYPE.PAYMENT_SCHEDULE_START_DATE]: StepPaymentScheduleStartDate,
  [STEP_TYPE.PAYMENT_SUMMARY]: StepPaymentSummary,
  [STEP_TYPE.PAYMENT_TYPE]: StepPaymentSourceType,
  [STEP_TYPE.PHONE]: StepPhone,
  [STEP_TYPE.PHYSICAL_ADDRESS]: StepPhysicalAddress,
  [STEP_TYPE.POST_STEP_TEXT]: StepText,
  [STEP_TYPE.PRAYER_PRIVACY]: StepPrayerPrivacy,
  [STEP_TYPE.PROFILE]: StepProfile,
  [STEP_TYPE.SMART_BIRTHDAY]: StepSmartBirthday,
  [STEP_TYPE.SMART_CONTACT]: StepSmartContact,
  [STEP_TYPE.SMART_EMAIL_ADDRESS]: StepSmartEmailAddress,
  [STEP_TYPE.SMART_GENDER]: StepSmartGender,
  [STEP_TYPE.SMART_NAME]: StepSmartName,
  [STEP_TYPE.SMART_PHONE]: StepSmartPhone,
  [STEP_TYPE.SMART_PHYSICAL_ADDRESS]: StepSmartPhysicalAddress,
  [STEP_TYPE.TEXT]: StepText,
});

export const getStep = (step) => STEP_COMPONENT_BY[step.type];
