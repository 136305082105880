import { validateStep } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext } from 'react';
import { getStep } from '../../../index';
import { saveContactField, setCurrentStep } from '../actions/stepContactActions';
import { selectCurrentContact, selectCurrentStep } from '../reducers/stepMultipleContactReducer';
import { StepMultipleContactContext } from '../StepMultipleContact';

export const StepContactField = (props) => {
  const { dispatch, useSelector } = useContext(StepMultipleContactContext);

  const steps = props.config.steps?.filter((step) => validateStep(step) === true && step.config.active === true);

  const currentStepId = useSelector(selectCurrentStep);
  const step = steps.find((step) => step.id === currentStepId);

  const contact = useSelector(selectCurrentContact);

  const handleComplete = (data) => dispatch(saveContactField(step, data));
  const handleGoBack = () => dispatch(setCurrentStep('card'));

  return React.createElement(getStep(step), {
    key: step.id,
    id: step.id,
    type: step.type,
    config: step.config,
    data: contact.data[step.id],
    completeStep: handleComplete,
    goBack: handleGoBack,
  });
};
