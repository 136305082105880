import { Footer as FooterComponent, getThemeValue, media } from '@foyyay/flow-elements';
import Color from 'color';
import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const getPrayerTimeThemeValue = _partial(getThemeValue, 'PrayerTime.Color.Default');

export const PrayerTimeFooter = (props) => {
  return (
    <FooterContainer>
      <StyledFooterComponent position={props.position}>
        <FooterFade />
        <FooterButtons>{props.children}</FooterButtons>
      </StyledFooterComponent>
    </FooterContainer>
  );
};

PrayerTimeFooter.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string,
};

PrayerTimeFooter.defaultProps = {
  position: 'fixed',
};

const StyledFooterComponent = styled(FooterComponent)`
  position: ${(props) => props.position};
  padding: 0 2.5rem 2.5rem;

  ${media.tabletLandscapeAndUp`
    padding: 0 6.7rem 6.7rem;
  `}
`;

export const FooterContainer = styled.div`
  position: relative;
  z-index: 2147483004;
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;

  & > * {
    margin-left: 1rem;

    ${media.tabletLandscapeAndUp`
      margin-left: 2rem;
    `}

    &:first-child {
      margin-left: 0 !important;
    }
  }
`;

const FooterFade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    ${(props) => Color(props.theme.PrayerTime.Color.Default.Background).alpha(0).string()} 0%,
    ${getPrayerTimeThemeValue('Background')} 95%
  );
  width: 100%;
  height: 12rem;
  transform: translateY(-99%);
`;
