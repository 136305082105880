import { useTimeout } from '@shared/hooks/useTimeout';
import React, { useState } from 'react';

interface Props {
  children?: React.ReactNode;
  delay?: number | null;
}

export const DelayedRender = ({ children, delay = null }: Props): JSX.Element | null => {
  const [state, setState] = useState(false);

  useTimeout(() => setState(true), delay);

  if (state === false) {
    return null;
  }

  return <>{children}</>;
};
