import { ButtonOptionPrimary, ButtonOptionSecondary, H2, Prompt, PromptActions, T1 } from '@foyyay/flow-elements';
import { ModalOverlay } from '@shared/components/Modal';
import { SOURCE_TYPE_ACH_DEBIT } from '@shared/constants';
import { withStopPropagation } from '@shared/lib/events';
import { getCurrencyFormater } from '@shared/lib/format';
import {
  selectMerchantByFlowId,
  selectSharedDataByFlowId,
  selectSourceTypesByFlowId,
} from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FlowContext } from '..';

export const ModalDialogGivingFees = (props) => {
  const handleCloseClick = withStopPropagation(() => {
    // close learn more dialog
    props.onClose();
  });
  const handleUseBankAccountClick = withStopPropagation(() => {
    // go to payment method type step
    props.onUseBankAccount();
  });

  const { currentFlowId } = useContext(FlowContext);
  const merchant = useSelector((state) => selectMerchantByFlowId(state, currentFlowId));
  const formatCurrency = getCurrencyFormater(merchant.bank_currency_code);
  const sourceTypes = useSelector((state) => selectSourceTypesByFlowId(state, currentFlowId));
  const sharedData = useSelector((state) => selectSharedDataByFlowId(state, currentFlowId));
  const achAvailable = sourceTypes?.includes(SOURCE_TYPE_ACH_DEBIT);
  const achBeingUsed = sharedData.paymentType?.id === SOURCE_TYPE_ACH_DEBIT;
  const shouldOfferAch = achAvailable && !achBeingUsed;

  const fixedAchFee = merchant?.fee_structure?.[SOURCE_TYPE_ACH_DEBIT]?.processorFixed;
  const hasFixedAchFees = fixedAchFee > 0;

  const achSwitchText = hasFixedAchFees ? (
    <T1>
      Want to help even more? Switch to a bank account and the convenience fee is just {formatCurrency(fixedAchFee)}!
    </T1>
  ) : (
    <T1>Want to help even more? Using a bank account is easy and there are no convenience&nbsp;fees!</T1>
  );

  return (
    <ModalOverlay show={props.show}>
      <Prompt>
        <H2>
          {achAvailable ? 'In the US alone, churches' : 'Churches'}
          {' are losing over $35 million to giving fees every year.'}
        </H2>
        <T1>
          That’s because when you give online, you think you’re giving $100, but instead the church (at best) receives
          just $96.80 after the platform and processor each take their cut. Nucleus Giving is different. We’ve
          negotiated the lowest rates, we don’t take money from your gifts, and together with your help, we make sure
          all the costs of digital giving are fully covered. In this way, 100% of your intended gift always goes
          directly to your church as it should. Thank you so much for helping!
        </T1>
        {shouldOfferAch && achSwitchText}
        <PromptActions>
          <ButtonOptionPrimary onClick={handleCloseClick}>Back to Summary</ButtonOptionPrimary>
          {shouldOfferAch && (
            <ButtonOptionSecondary onClick={handleUseBankAccountClick}>Or, use bank account</ButtonOptionSecondary>
          )}
        </PromptActions>
      </Prompt>
    </ModalOverlay>
  );
};
