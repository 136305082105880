import { ButtonGroup, ButtonGroupCircleX, InputEmail } from '@foyyay/flow-elements';
import { EMAIL_REGEX } from '@shared/constants';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { withPreventDefault, withStopPropagation } from '@shared/lib/events';
import React from 'react';
import { SmartStepActionButtons } from '../../../components/StepActionButtons';
import { setCurrentStep, setEmailAddress } from '../actions/stepSmartEmailAddressActions';
import { selectEmailAddress, selectOptions } from '../reducers/stepSmartEmailAddressReducer';

export const StepSmartEmailAddressInput = (props) => {
  const { useSelector, dispatch, onComplete } = props;

  const inputRef = useInputFocusOnceRef();

  const emailAddress = useSelector(selectEmailAddress);
  const options = useSelector(selectOptions);

  const handleEmailChange = (value) => dispatch(setEmailAddress(value));

  const readyToSubmit = ![emailAddress].includes('') && EMAIL_REGEX.test(emailAddress);

  const submitEmail = () => {
    if (readyToSubmit !== true) {
      return;
    }
    onComplete({ value: emailAddress.trim() });
  };

  const handleSubmit = withPreventDefault(submitEmail);
  const handleCancelClick = withStopPropagation(() => dispatch(setCurrentStep('select')));
  const handleContinueClick = withStopPropagation(submitEmail);

  return (
    <>
      <form style={{ marginBottom: '3.6rem' }} onSubmit={handleSubmit}>
        <InputEmail
          name="email"
          autoComplete="email"
          placeholder="name@example.com"
          value={emailAddress}
          onChange={handleEmailChange}
          ref={inputRef}
        />
      </form>
      <ButtonGroup>
        {options.length > 0 && <ButtonGroupCircleX onClick={handleCancelClick} />}
        <SmartStepActionButtons onContinueClick={handleContinueClick} readyToContinue={readyToSubmit} />
      </ButtonGroup>
    </>
  );
};
