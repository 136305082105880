import { deepFreeze } from '@shared/lib/deepFreeze';
import enUS from 'date-fns/locale/en-US';

const formatRelativeLocale = deepFreeze({
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'M/d/yyyy',
});

export const CUSTOM_LOCALE = deepFreeze({
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
});
