import { InputAddress } from '@foyyay/flow-elements';
import { Select } from '@shared/components/Select';
import { useAddressPredictions } from '@shared/hooks/useAddressPredictions';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { withStopPropagation } from '@shared/lib/events';
import { getParentWindow } from '@shared/lib/getParentWindow';
import React from 'react';
import { SmartStepActionButtons } from '../../../components/StepActionButtons';
import { getAddressFromOption, setAddress, setCurrentStep } from '../actions/stepSmartPhysicalAddressActions';
import { selectAddress, selectOptions } from '../reducers/stepSmartPhysicalAddressReducer';
import { HiddenAutofillFields } from './HiddenAutofillFields';

export const StepSmartPhysicalAddressSearch = (props) => {
  const { useSelector, dispatch } = props;

  const address = useSelector(selectAddress);
  const options = useSelector(selectOptions);

  const inputRef = useInputFocusOnceRef();

  const handleChangeSearch = (value) => {
    dispatch(setAddress({ street_address: value }));
  };

  const handleChangeAddress = (value) => {
    dispatch(setAddress(value));
    dispatch(setCurrentStep('confirm'));
  };

  const handleCancelClick = withStopPropagation(() => dispatch(setCurrentStep('select')));
  const handleContinueClick = useClickHandler(() => dispatch(setCurrentStep('confirm')));
  const handleSkipClick = useClickHandler(() => {
    props.onComplete({
      skipped: true,
      value: undefined,
    });
  });
  const readyToContinue = (address.street_address ?? '').length > 0;

  const handleChangeAddressField = (value) => {
    dispatch(setAddress(value));
    dispatch(setCurrentStep('confirm'));
  };

  const handleSubmit = useSubmitHandler(() => {}, []);

  const predictions = useAddressPredictions(address.street_address);

  const suggestedAddressOptions = predictions.map((item) => ({
    id: item.place_id,
    label: item.description,
  }));

  return (
    <>
      <form style={{ marginBottom: '3.6rem' }} onSubmit={handleSubmit}>
        <InputAddress
          name="street_address"
          autoComplete="street_address"
          placeholder="1234 Street"
          value={address.street_address || ''}
          onChange={handleChangeSearch}
          ref={inputRef}
        />
        <Suggestions style={{ marginTop: '2rem' }} options={suggestedAddressOptions} onChange={handleChangeAddress} />
        <HiddenAutofillFields address={address} onChange={handleChangeAddressField} />
      </form>
      <SmartStepActionButtons
        onCancelClick={options?.length > 0 ? handleCancelClick : undefined}
        onContinueClick={handleContinueClick}
        onSkipClick={handleSkipClick}
        readyToContinue={readyToContinue}
        required={props.required}
      />
    </>
  );
};

const Suggestions = (props) => {
  const handleChange = async (option) => {
    const window = getParentWindow();

    let address;
    try {
      address = await getAddressFromOption(option, window);
    } catch (error) {
      return;
    }
    props.onChange(address);
  };

  return <Select style={props.style} options={props.options} onChange={handleChange} />;
};
