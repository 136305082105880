import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { Styles } from './Typography';

const InputF3 = styled(Input)`
  ${Styles.F3};
  color: ${({ theme }) => theme.InputAddress.Color};
  height: 3rem;
`;

export const InputAddress = React.forwardRef((props, ref) => {
  const { value, onChange, onEnter, ...otherProps } = props;

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && typeof onEnter === 'function') {
      onEnter(e);
    }
  };

  return (
    <InputF3 type="text" value={value} onChange={handleChange} onKeyPress={handleKeyPress} ref={ref} {...otherProps} />
  );
});

InputAddress.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
};
