import { Theme } from '@foyyay/flow-elements';
import { Footer } from '@shared/components/Footer';
import { FlowLayout } from '@shared/components/Layout';
import { COLOR_MODE_DARK } from '@shared/constants/color';
import { selectLauncher } from '@shared/reducers/launcher';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { LauncherItems } from '../../components/LauncherItems';

export const Home = (): JSX.Element => {
  const { config, title } = useSelector(selectLauncher);
  const theme = useContext(Theme);
  const logoFileUrl = theme?.mode === COLOR_MODE_DARK ? config.logo_file_url_dark : config.logo_file_url;

  return (
    <>
      <FlowLayout contentPadding={'2.2rem 0 6rem'} logoFileUrl={logoFileUrl} title={title}>
        <div>
          <LauncherItems />
        </div>
      </FlowLayout>
      <Footer />
    </>
  );
};
