import { ButtonOptionMoreInfo } from '@foyyay/flow-elements';
import { withStopPropagation } from '@shared/lib/events';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ModalDialogStepOptionMoreInfo } from './ModalDialogStepOptionMoreInfo';

export const OptionMoreInfoModalTrigger = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpenClick = withStopPropagation(() => {
    setOpen(true);
  });

  const handleCloseClick = withStopPropagation(() => {
    setOpen(false);
  });

  return (
    <>
      <ButtonOptionMoreInfo onClick={handleOpenClick} />
      <ModalDialogStepOptionMoreInfo
        option={props.option}
        open={open}
        onCloseClick={handleCloseClick}
        onContinueClick={props.onContinueClick}
      />
    </>
  );
};

OptionMoreInfoModalTrigger.propTypes = {
  onContinue: PropTypes.func,
  option: PropTypes.object.isRequired,
};

OptionMoreInfoModalTrigger.defaultProps = {
  onContinue: () => {},
};
