import { STATE_AUTHENTICATED, STATE_UNAUTHENTICATED } from '../constants/state';
import { NUCLEUS_PLATFORM_API_URL } from '../constants/urls';
import { richFetch } from './richFetch';

const MAP_AUTH_TO_FILEUPLOAD_PATH = {
  [STATE_AUTHENTICATED]: '/flow/uploadfile/authenticated',
  [STATE_UNAUTHENTICATED]: '/flow/uploadfile',
};

export const requestFileUpload = async (file, authState, flowId) => {
  const path = MAP_AUTH_TO_FILEUPLOAD_PATH[authState];

  const body = {
    flow_id: flowId,
    content_type: file.type,
    filename: file.name,
    content_length: file.size,
  };

  return await richFetch('POST', NUCLEUS_PLATFORM_API_URL, path, body);
};

export const putFile = async (file, url, progressCallback) => {
  await new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.open('PUT', url);

    request.setRequestHeader('Content-Type', file.type);

    if (typeof progressCallback === 'function') {
      request.upload.addEventListener('progress', function (e) {
        const percentCompleted = (e.loaded / e.total) * 100;
        progressCallback(percentCompleted);
      });
    }

    request.addEventListener('load', function (e) {
      if (request.status !== 200) {
        reject(request.response);
      }

      resolve(request.response);
    });

    request.addEventListener('error', function (e) {
      reject(request.response);
    });

    request.send(file);
  });
};
