import { ButtonOption, ButtonOptionCounter, useButtonOptionCounterState } from '@foyyay/flow-elements';
import { useDelayedBool } from '@shared/hooks/useDelayedBool';
import { withStopPropagation } from '@shared/lib/events';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { OptionMoreInfoModalTrigger } from '../../../components/OptionMoreInfoModalTrigger';
import { setOptionQuantity, setSelectedOptions } from '../actions/multipleChoiceActions';
import { formatLimitLabel, haveQuantityRemaining } from '../lib/option';
import { selectCustomOptions, selectReadyToContinue, selectSelectedOptions } from '../reducers/multipleChoiceReducer';
import { StepMultipleChoice } from '../StepMultipleChoice';

export const RadioOption = (props) => {
  const { config } = useContext(StepMultipleChoice.Context);

  if (config.allowQuantity === true) {
    return <RadioOptionWithQuantity {...props} />;
  }

  return <RadioOptionWithoutQuantity {...props} />;
};

RadioOption.propTypes = {
  option: PropTypes.object.isRequired,
};

const RadioOptionWithQuantity = (props) => {
  const { dispatch, config, useSelector } = useContext(StepMultipleChoice.Context);

  const selectedOptions = useSelector(selectSelectedOptions);
  const selectedOption = selectedOptions.find((option) => option.id === props.option.id);

  const [quantityButtonProps, openWithTimeout] = useButtonOptionCounterState(selectedOption?.quantity);
  const clickable = useDelayedBool(!quantityButtonProps.open);

  const selectOption = () => {
    dispatch(setSelectedOptions([{ ...props.option, quantity: 1 }]));
  };

  const handleOptionClick = withStopPropagation(() => {
    openWithTimeout();
    selectOption();
  });

  const handleQuantityClick = withStopPropagation(() => {
    selectOption();
  });

  const handleQuantityChange = (value) => {
    dispatch(setOptionQuantity(props.option, value));
  };

  const readyToContinue = useSelector((state) => selectReadyToContinue(state, config));

  const disabled =
    haveQuantityRemaining(props.option) === false ||
    (props.maxTotal < 1 && selectedOption === undefined) ||
    (readyToContinue && selectedOption === undefined);

  const subText = formatLimitLabel(props.option.limitTotal, props.option.limitMaxOriginal, selectedOption?.quantity);

  const limitMax = Math.min(props.option.limitMax ?? Infinity, (selectedOption?.quantity ?? 0) + props.maxTotal);

  return (
    <ButtonOption
      alignText={props.alignText}
      clickable={clickable && selectedOption?.quantity === undefined}
      disabled={disabled}
      onClick={handleOptionClick}
      subtext={subText}
      badge={props.option.badge}
      right={
        !disabled && (
          <ButtonOptionCounter
            value={selectedOption?.quantity}
            max={limitMax}
            onClick={handleQuantityClick}
            onChange={handleQuantityChange}
            {...quantityButtonProps}
          />
        )
      }
    >
      {props.option.label}
    </ButtonOption>
  );
};

const RadioOptionWithoutQuantity = (props) => {
  const { config, useSelector, onComplete } = useContext(StepMultipleChoice.Context);

  const selectedOptions = useSelector(selectSelectedOptions);
  const selectedOption = selectedOptions.find((option) => option.id === props.option.id);
  const customOptions = useSelector(selectCustomOptions);

  const handleOptionClick = withStopPropagation(() => {
    onComplete({
      customOptions: config.allowCustom ? customOptions : undefined,
      value: [props.option],
    });
  });

  const disabled = haveQuantityRemaining(props.option) === false || props.maxTotal < 1;

  const subText = formatLimitLabel(props.option.limitTotal, undefined, selectedOption?.quantity);

  const showMoreInfo = props.option.description !== undefined;

  return (
    <ButtonOption
      alignText={props.alignText}
      disabled={disabled}
      subtext={subText}
      onClick={handleOptionClick}
      badge={props.option.badge}
      right={
        showMoreInfo ? (
          <OptionMoreInfoModalTrigger option={props.option} onContinueClick={handleOptionClick} />
        ) : undefined
      }
    >
      {props.option.label}
    </ButtonOption>
  );
};
