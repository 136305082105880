import React from 'react';
import styled from 'styled-components';
import { L3, T2 } from './Typography';

const _FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.Footer.Color.Default.Background};
  width: 100%;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  min-width: 320px;

  a,
  button,
  input {
    pointer-events: auto;
  }
`;

const FooterContainer = _FooterContainer;

const ProgressContainer = styled.div`
  margin: 0 auto;
  max-width: 34.5rem;
  box-sizing: content-box;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;

  a {
    transition: 250ms opacity ease-out;
  }

  > ${L3} {
    font-size: 1.4rem;
    > span {
      opacity: 0.55;
    }
    > a {
      opacity: 0.55;
    }
  }

  > ${T2} {
    font-size: 1.2rem;
    display: inline-block;
    margin-top: 0.15em;
    > span {
      opacity: 0.42;
    }
    > a {
      opacity: 0.42;
    }
  }

  @media (hover: hover) {
    & a:hover {
      opacity: 1 !important;
    }
  }
`;

export const Footer = (props) => {
  return <FooterContainer className={props.className}>{props.children}</FooterContainer>;
};

Footer.ProgressContainer = ProgressContainer;
Footer.TextContainer = TextContainer;
