import { H1, media, T1 } from '@foyyay/flow-elements';
import React from 'react';
import styled from 'styled-components';

export const SectionText = (props) => {
  return (
    <Section>
      {props.title && <H1>{props.title}</H1>}
      {props.children && <T1>{props.children}</T1>}
    </Section>
  );
};

const Section = styled.div`
  max-width: 47.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.2rem;
  text-align: center;

  ${media.tabletPortraitAndUp`
    margin-bottom: 3.2rem;
  `}
`;
