import React from 'react';
import styled from 'styled-components';
import { media } from './style-utils';

const Container = styled.div`
  background-color: ${({ theme }) => theme.Prompt.Color.Default.Background};
  color: ${({ theme }) => theme.Prompt.Color.Default.Foreground};
  margin: 0 auto;
  padding: 6rem 2rem;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 84rem;
  text-align: center;

  ${media.tabletLandscapeAndUp`
    padding: 6rem 8rem;
  `}
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3001;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${({ theme }) => theme.Prompt.Color.Default.Background};
  padding: 0;

  ${media.tabletLandscapeAndUp`
    padding: 4rem 0;
  `}
`;

export const Overlay = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Wrapper {...otherProps}>
      <Container>{props.children}</Container>
    </Wrapper>
  );
};
