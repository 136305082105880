import { LoadingSpinner } from '@foyyay/flow-elements';
import React from 'react';
import styled from 'styled-components';

export const LoadingStep = () => {
  return (
    <PositionRelative>
      <PositionAbsolute>
        <LoadingSpinner />
      </PositionAbsolute>
    </PositionRelative>
  );
};

const PositionRelative = styled.div`
  position: relative;
  padding: 10rem 0;
`;

const PositionAbsolute = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
