import { ButtonOptionSecondary } from '@foyyay/flow-elements';
import { Select } from '@shared/components/Select';
import React from 'react';
import { clearEmailAddress, setCurrentStep } from '../actions/stepSmartEmailAddressActions';
import { selectOptions } from '../reducers/stepSmartEmailAddressReducer';

export const StepSmartEmailAddressSelect = (props) => {
  const { dispatch, useSelector } = props;

  const options = useSelector(selectOptions);

  const handleChange = (option) => {
    props.onComplete({
      attribute: option.attribute,
      value: option.value,
    });
  };

  const handleClick = () => {
    dispatch(clearEmailAddress());
    dispatch(setCurrentStep('input'));
  };

  return (
    <>
      <Select options={options} onChange={handleChange} />
      <ButtonOptionSecondary onClick={handleClick}>+ Add new email</ButtonOptionSecondary>
    </>
  );
};
