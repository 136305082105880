import styled from 'styled-components';

export const TextArea = styled.textarea`
  background: none;
  border: 0;
  caret-color: inherit;
  color: inherit;
  display: inline-block;
  font-family: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
  max-width: 100%;

  :focus {
    outline: 0;
  }

  ::placeholder {
    color: inherit;
    opacity: 0.25;
    font-family: inherit;
  }
`;
