import { AnimatedSwitch } from '@shared/components/AnimatedSwitch';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Account from './routes/Account/Account';
import SignIn from './routes/SignIn/SignIn';

export const Routes = (props) => {
  return (
    <AnimatedSwitch>
      <Route exact path="/sign-in" component={SignIn}></Route>
      <Route exact path="/my-account" component={Account}></Route>
      <Route>
        <Redirect to="/sign-in" />
      </Route>
    </AnimatedSwitch>
  );
};
