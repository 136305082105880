import { ButtonCircleArrow, LabelStep, LayoutStepRowSingle, Step, StepInput } from '@foyyay/flow-elements';
import { requestReceipt } from '@shared/actions/flows';
import { checkEmail } from '@shared/actions/user';
import { FLOW_SUBMISSION_LABEL_BY } from '@shared/constants/flow';
import { selectFlowConfigById } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FlowContext } from '../../..';
import { useThankYouController } from '../../../controllers/ThankYouController';
import { THANKYOU_STEP_BY } from '../ThankYou';
import { SectionText } from '../components/SectionText';

export const EnterEmail = (props) => {
  const { user, setUser, submission } = useThankYouController();
  const { currentFlowId } = useContext(FlowContext);
  const flowConfig = useSelector((state) => selectFlowConfigById(state, currentFlowId));

  const initialState = {
    processing: false,
    error: undefined,
  };

  const [state, setState] = useState(initialState);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleEmailChange = (value) => {
    setUser((prevState) => ({
      ...prevState,
      email: value,
    }));
  };

  const handleHiddenPasswordChange = (e) => {
    const value = e.target.value;
    setUser((prevState) => ({
      ...prevState,
      password: value,
    }));
  };

  const handleCheckEmail = async () => {
    setState({ processing: true });

    let data;
    try {
      data = await dispatch(checkEmail(user.email));
    } catch (error) {
      console.error(error);
      setState({
        processing: false,
        error: 'Not a valid email, try again',
      });
      return;
    }

    setUser((prevState) => ({
      ...prevState,
      userExists: data.exists,
    }));

    dispatch(requestReceipt({ id: currentFlowId }, submission, user.email, user.name));

    const nextStep = THANKYOU_STEP_BY[data.status] ?? 'thank-you';
    history.push('/thank-you', { currentStep: nextStep });
  };

  return (
    <>
      <SectionText title="Get a Receipt">
        So we can get you a record of your {FLOW_SUBMISSION_LABEL_BY[flowConfig.flow_type]}, please share your email and
        we'll send you a confirmation right away.
      </SectionText>
      <SectionEmail>
        <Step>
          <LabelStep absolute error={state.error}>
            ENTER YOUR EMAIL FOR A RECEIPT
          </LabelStep>
          <LayoutStepRowSingle>
            <form onSubmit={handleCheckEmail}>
              <StepInput
                id="email"
                name="email"
                type="email"
                autoComplete="username"
                placeholder="email@example.com"
                spellCheck="false"
                value={user.email}
                onChange={handleEmailChange}
                onEnter={handleCheckEmail}
                error={state.error}
              />
              <HiddenInput
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={user.password}
                onChange={handleHiddenPasswordChange}
              />
            </form>
            <ButtonCircleArrow onClick={handleCheckEmail} loading={state.processing} />
          </LayoutStepRowSingle>
        </Step>
      </SectionEmail>
    </>
  );
};

const SectionEmail = styled.div`
  max-width: 58rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.2rem;
`;

const HiddenInput = styled.input`
  display: none;
`;
