import { Buffer } from 'buffer';
import { compose } from 'redux';

const BASE_64_PREFIX = '__64';
const BASE_64_EXP = new RegExp(`^${BASE_64_PREFIX}`);

function toBinary(string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
}

function fromBinary(binary) {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

export const toBase64 = (value) => Buffer.from(value, 'utf8').toString('base64');
export const fromBase64 = (value) => Buffer.from(value, 'base64').toString('utf8');

const addPrefix = (value) => [BASE_64_PREFIX, value].join('');
const removePrefix = (value) => value.replace(BASE_64_EXP, '');

const base64EncodeObject = (object) =>
  compose(addPrefix, encodeURIComponent, toBase64, toBinary, JSON.stringify)(object);
const base64DecodeObject = (object) =>
  compose(JSON.parse, fromBinary, fromBase64, decodeURIComponent, removePrefix)(object);

const uriEncodeString = (value) => encodeURIComponent(value).replace(/%20/g, '+');
const uriDecodeString = (value) => decodeURIComponent(value.replace(/\+/g, '%20'));

export const encodeURIValue = (value) =>
  typeof value === 'object' ? base64EncodeObject(value) : uriEncodeString(value);
export const decodeURIValue = (value) =>
  value.match(BASE_64_EXP) === null ? uriDecodeString(value) : base64DecodeObject(value);
