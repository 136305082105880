import styled, { css } from 'styled-components';
import { font, media } from './style-utils';

/**
 * Headings: H1, H2
 *
 * Labels: L1, L2, L3
 *
 * Field styles: F1, F2, F3, F4
 *
 * Text Styles: T1, T2
 */

export const Styles = {
  H1: css`
    ${font(32, 'Bold', -1.75, 30)}
    margin-bottom: 1.2rem;
    margin-top: 1rem;

    ${media.tabletLandscapeAndUp`
      ${font(64, 'Black', -1.5, 64)}
    `};
  `,
  H2: css`
    ${font(26, 'Black', -0.75, 26)}

    ${media.tabletLandscapeAndUp`
      ${font(30)}
    `};
  `,
  H3: css`
    ${font(22, 'Black', -0.75, 22)}

    ${media.tabletLandscapeAndUp`
      ${font(30)}
    `}
  `,
  H4: css`
    ${font(24, 'Bold', -1.25, 26)}
  `,
  L1: css`
    ${font(20, 'Medium', -0.75)}
    margin-bottom: 1.5rem;
    margin-top: 1rem;

    ${media.tabletLandscapeAndUp`
      ${font(24)}
    `};
  `,
  L2: css`
    ${font(14, 'Bold', 1)}
    text-transform: uppercase;

    ${media.tabletLandscapeAndUp`
      ${font(14)}
    `};
  `,
  L3: css`
    ${font(12, 'Bold', -0.5, 12)}

    ${media.tabletLandscapeAndUp`
      ${font(16)}
    `};
  `,
  L4: css`
    ${font(14, 'Bold', -0.5, 18)}

    ${media.tabletLandscapeAndUp`
      ${font(16)}
    `};
  `,
  T1: css`
    ${font(16, 'Book', -0.5, 20)}

    ${media.tabletLandscapeAndUp`
      ${font(18)}
    `};
  `,
  T2: css`
    ${font(10, 'Book', -0.25, 10)}

    ${media.tabletLandscapeAndUp`
      ${font(14)}
    `};
  `,
  T3: css`
    ${font(14, 'Book', -0.5, 14)}

    ${media.tabletLandscapeAndUp`
    ${font(16)}
  `};
  `,
  F1: css`
    ${font(46, 'Book', -1)}

    ${media.tabletLandscapeAndUp`
      ${font(64)}
    `};
  `,
  F2: css`
    ${font(36, 'Bold', -0.75)}

    ${media.tabletLandscapeAndUp`
      ${font(46)}
    `};
  `,
  F3: css`
    ${font(24, 'Book', -0.5)}

    ${media.tabletLandscapeAndUp`
      ${font(30)}
    `};
  `,
  F4: css`
    ${font(20, 'Book', -0.75)}

    ${media.tabletLandscapeAndUp`
      ${font(26)}
    `};
  `,
  F5: css`
    ${font(18, 'Book', -0.5)}

    ${media.tabletLandscapeAndUp`
      ${font(20)}
    `};
  `,
  P1: css`
    ${font(18, 'Book', -0.75)}

    ${media.tabletLandscapeAndUp`
      ${font(21)}
    `};
  `,
  P2: css`
    ${font(16, 'Book', -0.5, 20)}
  `,
};

export const H1 = styled.h1`
  ${Styles.H1};
`;

export const H2 = styled.h2`
  ${Styles.H2};
`;

export const H3 = styled.h3`
  ${Styles.H3};
`;

export const H4 = styled.div`
  ${Styles.H4};
`;

export const L1 = styled.div`
  ${Styles.L1};
`;

export const L2 = styled.div`
  ${Styles.L2};
`;

export const L3 = styled.div`
  ${Styles.L3};
`;

export const L4 = styled.div`
  ${Styles.L4};
`;

export const F1 = styled.span`
  ${Styles.F1};
`;

export const F2 = styled.span`
  ${Styles.F2};
`;

export const F3 = styled.span`
  ${Styles.F3};
`;

export const F4 = styled.span`
  ${Styles.F4};
`;

export const T1 = styled.p`
  ${Styles.T1};
`;

export const T2 = styled.span`
  ${Styles.T2};
`;

export const T3 = styled.p`
  ${Styles.T3};
`;

export const P1 = styled.p`
  ${Styles.P1};
`;

export const P2 = styled.p`
  ${Styles.P2};
`;
