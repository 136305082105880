import { createReducer } from '@reduxjs/toolkit';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { clearEmailAddress, setCurrentStep, setEmailAddress } from '../actions/stepSmartEmailAddressActions';
import { sortBy as _sortBy } from 'lodash';
import { formatValueWithPrefix } from '@shared/lib/format';

export const selectCurrentStep = (state) => state.currentStep;
export const selectEmailAddress = (state) => state.emailAddress;
export const selectOptions = (state) => state.options;

export const selectLabel = (state, config) => {
  if (state.currentStep === 'select') {
    return 'Choose an email';
  }
  return config.label;
};

export const selectMessage = (state, config, data) => {
  return formatValueWithPrefix(config.valuePrefixLabel, data?.value.trim(), config.valuePrefixSeparator);
};

const initialState = deepFreeze({
  currentStep: 'input',
  emailAddress: '',
  options: [],
});

export const init = (args) => ({
  ...initialState,
  emailAddress: args.data?.value ?? initialState.emailAddress,
  options: initOptions(args),
  currentStep: initCurrentStep(args),
});

const initOptions = (args) => {
  return _sortBy(args.emails, ['primary'])
    .reverse()
    .map((email) => ({
      id: email.address,
      label: email.address,
      attribute: email,
      value: email.address,
    }));
};

const initCurrentStep = (args) => {
  const { data, emails } = args;

  if (data?.value === undefined && emails.length > 0) {
    return 'select';
  }

  if (data?.attribute !== undefined) {
    return 'select';
  }

  return 'input';
};

export const stepSmartEmailAddressReducer = createReducer(initialState, {
  [setCurrentStep]: (state, action) => ({
    ...state,
    currentStep: action.payload,
  }),
  [setEmailAddress]: (state, action) => ({
    ...state,
    emailAddress: action.payload,
  }),
  [clearEmailAddress]: (state, action) => ({
    ...state,
    emailAddress: initialState.emailAddress,
  }),
});
