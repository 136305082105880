import { createAction } from '@reduxjs/toolkit';

export const setCurrentStep = createAction('StepContact/currentStep/set');

export const addContact = createAction('StepContact/contact/add');
export const saveAndAddContact = (contact, onSave) => async (dispatch, getState) => {
  await dispatch(addContact(contact));
  await onSave({
    value: getState().contacts,
  });
};
export const removeContact = createAction('StepContact/contact/remove');
export const editContact = createAction('StepContact/contact/edit');
export const editContactField = createAction('StepContact/contact/field/edit');
export const saveContactField = createAction('StepContact/contact/field/save', (step, data) => {
  return {
    payload: {
      step: step,
      data: data,
    },
  };
});
