import { H1, T1 } from '@foyyay/flow-elements';
import React from 'react';
import styled from 'styled-components';

export const StatusPageText = (props) => {
  return (
    <Section>
      <H1>{props.headline}</H1>
      <T1>{props.children}</T1>
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.1rem;
  text-align: center;

  ${T1} {
    max-width: 26.5em;
    margin-bottom: 1.2rem;
    margin-left: auto;
    margin-right: auto;
  }
`;
