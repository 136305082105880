import { createReducer } from '@reduxjs/toolkit';
import { dateToIsoString, isoStringToAmerican } from '@shared/lib/datetime';
import { deepFreeze } from '@shared/lib/deepFreeze';
import * as dateFns from 'date-fns';
import { setBirthday, setCurrentStep } from '../actions/stepSmartBirthdayActions';

export const selectCurrentStep = (state) => state.currentStep;
export const selectBirthday = (state) => state.birthday;
export const selectOptions = (state) => state.options;

export const selectLabel = (state, config) => {
  return config.label;
};

export const selectMessage = (state, config, data) => {
  if (data?.skipped === true) {
    return `Skipped: "${config.label}"`;
  }

  let birthday = '';
  if (data?.value !== undefined) {
    birthday = isoStringToAmerican(data.value);
  }
  return ['My birthday is:', birthday].join(' ').trim();
};

const initialState = deepFreeze({
  currentStep: 'picker',
  birthday: dateToIsoString(dateFns.subYears(new Date(), 28)),
  modified: false,
});

export const init = (args) => ({
  ...initialState,
  birthday: args.data?.value ?? args.birthdayAttribute?.value?.date ?? initialState.birthday,
  currentStep: 'picker',
  modified: args.data?.value !== undefined || args.birthdayAttribute?.value?.date !== undefined,
});

export const stepSmartBirthdayReducer = createReducer(initialState, {
  [setCurrentStep]: (state, action) => ({
    ...state,
    currentStep: action.payload,
  }),
  [setBirthday]: (state, action) => ({
    ...state,
    birthday: action.payload,
    modified: true,
  }),
});
