import React from 'react';
import styled from 'styled-components';
import { Church } from './Icons';
import { font } from './style-utils';

const Container = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledChurch = styled(Church)`
  color: ${({ theme }) => theme.PlaceholderLogo.Color.Default.Foreground};
  width: 6.08rem;
`;

const P = styled.p`
  ${font(15, 'Book', -0.25)}
  color: ${({ theme }) => theme.PlaceholderLogo.Color.Default.Foreground};
  width: 100%;
  max-width: 21.7rem;
`;

export const PlaceholderLogo = (props) => {
  return (
    <Container>
      <StyledChurch />
      <P>Your church logo will display here once you upload it!</P>
    </Container>
  );
};
