import {
  ButtonCircleArrow,
  ButtonOptionPrimary,
  ButtonOptionSecondary,
  Container,
  font,
  H1,
  LabelStep,
  LayoutStepRowSingle,
  media,
  Step,
  StepInput,
  T1,
} from '@foyyay/flow-elements';
import { checkEmail, linkWithChurch, signIn, signOut, signUp } from '@shared/actions/user';
import { Footer } from '@shared/components/Footer';
import { NUCLEUS_DASHBOARD_BASE_URL } from '@shared/constants/urls';
import { Auth } from 'aws-amplify';
import React, { Component, useContext } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';
import { AccountContext } from '../../index';

const STEP = {
  GETEMAIL: 'getEmail',
  GETPASSWORD: 'getPassword',
  CREATEPASSWORD: 'createPassword',
  SIGNEDIN: 'signedIn',
  RESETPASSWORD: 'resetPassword',
  EMAILSENT: 'emailSent',
};

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      asyncProcessing: false,
      asyncError: undefined,
      user: {
        email: '',
        password: '',
        username: '',
      },
      errors: {
        email: undefined,
        password: undefined,
      },
      userExists: undefined,
    };
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
      errors: {},
    });
  };

  onChangeDeep = (key, value) => {
    this.setState((prevState) => ({
      [key]: {
        ...prevState[key],
        ...value,
      },
      errors: {},
    }));
  };

  checkEmail = async () => {
    this.setState({
      asyncProcessing: true,
      asyncError: undefined,
    });

    let data;
    try {
      data = await this.props.checkEmail(this.state.user.email);
    } catch (error) {
      console.error(error);
      this.setState((prevState) => ({
        userExists: false,
        errors: {
          ...prevState.errors,
          email: 'Not a valid email, try again',
        },
      }));
      return;
    }

    this.setState({ userExists: data.exists });

    if (data.UserStatus === 'FORCE_CHANGE_PASSWORD') {
      this.props.history.push('/sign-in', { currentStep: STEP.EMAILSENT });
    } else {
      this.props.history.push('/sign-in', { currentStep: STEP.GETPASSWORD });
    }

    this.setState({ asyncProcessing: false });
  };

  signIn = async () => {
    this.setState({ asyncProcessing: true });

    try {
      await this.props.signIn(this.state.user.email, this.state.user.password);
    } catch (error) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          password: 'Sorry, wrong password',
        },
        asyncProcessing: false,
      }));
      return;
    }

    this.props.linkWithChurch();
    this.props.history.replace('/sign-in', { currentStep: STEP.SIGNEDIN });
    this.setState({ asyncProcessing: false });
  };

  signUp = async () => {
    if (this.asyncProcessing === true) {
      return;
    }

    this.setState({ asyncProcessing: true });

    try {
      await this.props.signUp(this.state.user.email, this.state.user.password);
    } catch (error) {
      const invalidPassword = error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException';
      const errorMessage = invalidPassword ? 'Must be at least 8 characters' : 'Sorry, could not sign up';

      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          password: errorMessage,
        },
        asyncProcessing: false,
      }));
      return;
    }

    this.props.linkWithChurch();
    this.props.history.replace('/sign-in', { currentStep: STEP.SIGNEDIN });
    this.setState({ asyncProcessing: false });
  };

  signOut = async () => {
    this.setState({
      asyncProcessing: true,
      visible: false,
    });

    try {
      await this.props.signOut();
      return this.props.goBack();
    } catch (error) {
      this.setState({
        asyncProcessing: false,
        visible: true,
      });
      return;
    }
  };

  resetPassword = async () => {
    try {
      Auth.forgotPassword(this.state.user.email);
      this.props.history.replace('sign-in', { currentStep: STEP.RESETPASSWORD });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    let currentStep = STEP.GETEMAIL;

    if (this.props.location?.state) {
      currentStep = this.props.location?.state.currentStep;
    }

    if (currentStep === STEP.GETPASSWORD && this.state.user.email === '') {
      this.props.history.replace('/sign-in', { currentStep: STEP.GETEMAIL });
    }

    if (this.props.user) {
      currentStep = STEP.SIGNEDIN;
    }

    // Todo: Use Shared Flow layout component
    return (
      <>
        <FlowBody>
          <ScrollContainer>
            <FlowContent>
              <Flow>
                {![STEP.EMAILSENT].includes(currentStep) && (
                  <FlowContainer>
                    <PositionContainer>
                      <Container>
                        {currentStep === STEP.GETEMAIL && (
                          <>
                            <SectionText
                              title={`Hi ${
                                this.props.user && this.props.user.profile.first_name
                                  ? this.props.user.profile.first_name
                                  : 'there'
                              },`}
                            >
                              Just enter your email and we’ll help you get to the right place. If you already have an
                              account, try to use that same&nbsp;email.
                            </SectionText>
                            <SectionEmail>
                              <Step>
                                <LabelStep absolute error={this.state.errors.email}>
                                  WHAT'S YOUR EMAIL?
                                </LabelStep>
                                <LayoutStepRowSingle>
                                  <StepInput
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="username"
                                    placeholder="email@example.com"
                                    spellCheck="false"
                                    value={this.state.user.email}
                                    onChange={(value) => this.onChangeDeep('user', { email: value })}
                                    onEnter={this.checkEmail}
                                    error={this.state.errors.email}
                                  />
                                  <HiddenInput
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={this.state.user.password}
                                    onChange={(e) => this.onChangeDeep('user', { password: e.target.value })}
                                  />
                                  <ButtonCircleArrow onClick={this.checkEmail} loading={this.state.asyncProcessing} />
                                </LayoutStepRowSingle>
                              </Step>
                            </SectionEmail>
                            <SignInBackTextLink />
                          </>
                        )}

                        {currentStep === STEP.GETPASSWORD && this.state.userExists && (
                          <>
                            <SectionText title="Welcome Back!">
                              Now, just enter the password for your Nucleus account. Can’t remember it? No worries, just{' '}
                              <TextLink onClick={this.resetPassword}>request a reset email.</TextLink>
                            </SectionText>
                            <SectionEmail>
                              <Step>
                                <LabelStep absolute error={this.state.errors.password}>
                                  Enter Your Password
                                </LabelStep>
                                <LayoutStepRowSingle>
                                  <HiddenInput
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="username"
                                    value={this.state.user.email}
                                    readOnly
                                  />
                                  <StepInput
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    placeholder="password"
                                    value={this.state.user.password}
                                    onChange={(value) => this.onChangeDeep('user', { password: value })}
                                    onEnter={this.signIn}
                                    isValid={!this.state.errors.password}
                                    error={this.state.errors.password}
                                  />
                                  <ButtonCircleArrow onClick={this.signIn} loading={this.state.asyncProcessing} />
                                </LayoutStepRowSingle>
                              </Step>
                            </SectionEmail>
                            <SignInBackTextLink />
                          </>
                        )}

                        {currentStep === STEP.GETPASSWORD && !this.state.userExists && (
                          <>
                            <SectionText title="Create account">
                              Simply enter a password for your new Nucleus account and you're ready to go!
                            </SectionText>
                            <SectionEmail>
                              <Step>
                                <LabelStep absolute error={this.state.errors.password}>
                                  Create A Password
                                </LabelStep>
                                <LayoutStepRowSingle>
                                  <HiddenInput
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="username"
                                    value={this.state.user.email}
                                    readOnly
                                  />
                                  <StepInput
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    placeholder="password"
                                    value={this.state.user.password}
                                    onChange={(value) => this.onChangeDeep('user', { password: value })}
                                    onEnter={this.signUp}
                                    isValid={!this.state.errors.password}
                                    error={this.state.errors.password}
                                  />
                                  <ButtonCircleArrow onClick={this.signUp} loading={this.state.asyncProcessing} />
                                </LayoutStepRowSingle>
                              </Step>
                            </SectionEmail>
                            <TryAgainTextLink />
                          </>
                        )}

                        {currentStep === STEP.SIGNEDIN && this.state.userExists && (
                          <>
                            <SectionText
                              title={`Hi ${
                                this.props.user && this.props.user.profile.first_name
                                  ? this.props.user.profile.first_name
                                  : 'there'
                              },`}
                            >
                              Welcome back! Just choose what you want to do, and we’ll send you there to get started
                              right&nbsp;away.
                            </SectionText>
                            <MyAccountButtons />
                            <SectionReturn>
                              <T1>
                                <span>If you're on a shared device, you can</span>{' '}
                                <TextLink role="button" onClick={this.signOut}>
                                  sign out
                                </TextLink>
                              </T1>
                            </SectionReturn>
                          </>
                        )}

                        {currentStep === STEP.SIGNEDIN && !this.state.userExists && (
                          <>
                            <SectionText title="Welcome!">
                              You're ready to go! Just choose what you want to do, and we’ll send you there to get
                              started right&nbsp;away.
                            </SectionText>
                            <MyAccountButtons />
                            <SectionReturn>
                              <T1>
                                <span>If you're on a shared device, you can</span>{' '}
                                <TextLink role="button" onClick={this.signOut}>
                                  sign out
                                </TextLink>
                              </T1>
                            </SectionReturn>
                          </>
                        )}

                        {currentStep === STEP.RESETPASSWORD && (
                          <>
                            <Container maxWidth="60rem" style={{ textAlign: 'center' }}>
                              <H1>Reset link sent! Please check your&nbsp;email</H1>
                            </Container>
                            <Container maxWidth="51.9rem" style={{ textAlign: 'center' }}>
                              <T1>
                                We’ve sent a password reset link to your email address. Click the button in that email
                                to finish resetting your password.
                              </T1>
                            </Container>
                          </>
                        )}
                      </Container>
                    </PositionContainer>
                  </FlowContainer>
                )}

                {currentStep === STEP.EMAILSENT && (
                  <div style={{ paddingTop: '6rem' }}>
                    <Container maxWidth="80rem" style={{ textAlign: 'center' }}>
                      <H1>Please check your&nbsp;email!</H1>
                    </Container>
                    <Container maxWidth="51.9rem" style={{ textAlign: 'center' }}>
                      <T1>
                        You never finished setting up your account, so you’ll need to verify your email, and then create
                        a password. To do this, simply click the “Create a password” button in the email we just sent
                        you, and we’ll get you to the right place.
                      </T1>
                    </Container>
                  </div>
                )}
              </Flow>
            </FlowContent>
          </ScrollContainer>
        </FlowBody>
        <FlowFooter>
          <Footer />
        </FlowFooter>
      </>
    );
  }
}

const withLinkArgs = (Component) => (props) => {
  const context = useContext(AccountContext);
  return <Component linkArgs={context.linkArgs} {...props} />;
};

const withGoBack = (Component) => (props) => {
  const context = useContext(AccountContext);
  return <Component goBack={context.goBack} {...props} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkEmail: (email) => dispatch(checkEmail(email)),
    signIn: (email, password) => dispatch(signIn(email, password)),
    signOut: (callBack) => dispatch(signOut(callBack)),
    signUp: (email, password) => dispatch(signUp(email, password)),
    linkWithChurch: (linkArgs) => dispatch(linkWithChurch(linkArgs)),
  };
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    linkWithChurch: () => dispatchProps.linkWithChurch(ownProps.linkArgs),
  };
}

export default compose(withLinkArgs, withGoBack, connect(mapStateToProps, mapDispatchToProps, mergeProps))(SignIn);

const MyAccountButtons = (props) => {
  const context = useContext(AccountContext);
  return (
    <SectionButtons>
      <ButtonOptionPrimary onClick={context.goBack}>Continue</ButtonOptionPrimary>
      <a href={`${NUCLEUS_DASHBOARD_BASE_URL}/my`} target="_blank" rel="noopener noreferrer">
        <ButtonOptionSecondary>or, Go to my account</ButtonOptionSecondary>
      </a>
    </SectionButtons>
  );
};

const SectionText = (props) => {
  return (
    <StyledSectionText>
      <H1>{props.title}</H1>
      <T1>{props.children}</T1>
    </StyledSectionText>
  );
};

const BackTextLinkContainer = styled.div`
  text-align: center;
  margin-top: 6rem;
`;

const BackTextLink = styled(Link)`
  ${font(16, 'Book', -0.3, 16)}
  opacity: 0.5;
  text-align: center;
  margin: 0 auto;
  transition: 250ms opacity ease-out;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;

const UnderlineText = styled.span`
  text-decoration: underline;
`;

const SignInBackTextLink = (props) => {
  const context = useContext(AccountContext);
  return (
    <BackTextLinkContainer>
      <BackTextLink as="span" onClick={context.goBack}>
        Nevermind, <UnderlineText>don't sign in</UnderlineText>
      </BackTextLink>
    </BackTextLinkContainer>
  );
};

const TryAgainTextLink = (props) => (
  <BackTextLinkContainer>
    <BackTextLink to="/sign-in">
      or, <UnderlineText>Use different email address</UnderlineText>
    </BackTextLink>
  </BackTextLinkContainer>
);

const FlowBody = styled.div`
  position: relative;
  flex: 1;
`;

const ScrollContainer = styled.div`
  z-index: 2147483002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FlowContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
`;

const Flow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 8rem 0 20rem;
  flex: 1 0 auto;
`;

const HORIZ_PAD = '1.2rem';

const FlowContainer = styled.div`
  width: 100%;
  max-width: calc(49rem + ${HORIZ_PAD} * 2);
  padding-left: ${HORIZ_PAD};
  padding-right: ${HORIZ_PAD};
  min-height: 32vh;

  ${media.tabletLandscapeAndUp`
    max-width: calc(58rem + ${HORIZ_PAD} * 2);
    min-height: 34vh;
  `}
`;

const FlowFooter = styled.div`
  position: relative;
  z-index: 2147483004;
`;

const HiddenInput = styled.input`
  display: none;
`;

const PositionContainer = styled.div`
  margin-top: 164 / 880 * 100vh;
`;

const SectionReturn = styled.div`
  width: 100%;
  text-align: center;
  span {
    opacity: 0.65;
  }
`;

const TextLink = styled.span`
  text-decoration: underline;
  transition: opacity 150ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const SectionEmail = styled.div`
  max-width: 58rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.2rem;
`;

const StyledSectionText = styled.div`
  max-width: 47.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.2rem;
  text-align: center;

  ${media.tabletPortraitAndUp`
    margin-bottom: 3.2rem;
  `}
`;

const SectionButtons = styled.div`
  max-width: 35rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;

  ${media.tabletPortraitAndUp`
    margin-top: 5.8rem;
    margin-bottom: 8.4rem;
    `}
`;
