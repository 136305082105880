import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { LoadingSpinner } from './LoadingSpinner';
import { media } from './style-utils';
import { getThemeValue } from './Theme';

const getScopedThemeValue = _partial(getThemeValue, 'ButtonPrimary.Color');

const Background = styled.div`
  border-radius: ${({ large }) => (large ? '1.4rem' : '3rem')};
  background: ${getScopedThemeValue('Default.Background')};
  bottom: 0;
  color: ${getScopedThemeValue('Default.Foreground')};
  left: 0;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 0.8)};
  position: absolute;
  right: 0;
  top: 0;

  & + * {
    z-index: 1;
  }
`;

const Foreground = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  color: ${getScopedThemeValue('Default.Foreground')};
  height: ${({ large }) => (large ? '10.8rem' : '6rem')};
  border-radius: ${({ large }) => (large ? '1.4rem' : '3rem')};
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  position: relative;
  box-sizing: border-box;

  @media (hover: hover) {
    :not([disabled]):hover > ${Background} {
      background: ${getScopedThemeValue('Hover.Background')};
      color: ${getScopedThemeValue('Hover.Foreground')};
    }
  }

  :not([disabled]):active {
    transform: scale(0.94);
  }
`;

const Text = styled.span`
  display: inline-block;
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.023em;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${media.tabletLandscapeAndUp`
    font-size: 2.8rem;
  `};
`;

export const ButtonPrimary = React.forwardRef(({ large, processing, children, ...otherProps }, ref) => (
  <StyledButton ref={ref} large={large} {...otherProps}>
    <Background large={large} />
    <Foreground>{processing ? <LoadingSpinner /> : <Text>{children}</Text>}</Foreground>
  </StyledButton>
));

ButtonPrimary.propTypes = {
  processing: PropTypes.bool,
  disabled: PropTypes.bool,
};

ButtonPrimary.defaultProps = {
  processing: false,
};
