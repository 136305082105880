import { colorStyles, getThemeValue, media } from '@foyyay/flow-elements';
import { Button } from '@shared/components/Button';
import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { Footer } from './Footer';

const getSheetThemeValue = _partial(getThemeValue, 'Sheet.Color.Default');

const SheetWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  pointer-events: auto;
`;

const SheetBackdrop = styled(animated.div)`
  position: absolute;
  background: ${getSheetThemeValue('Background')};
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const SheetContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  min-width: 320px;
`;

const SheetContent = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 3rem 3rem 0;
  background: ${getSheetThemeValue('Background')};
  box-shadow: 0 1px 2.4rem 0 ${getSheetThemeValue('Shadow')};
  border-radius: 2.8rem 2.8rem 0 0;

  ${media.tabletLandscapeAndUp`
    padding: 3rem 3rem 1rem;
    border-radius: 3.5rem 3.5rem 0 0;
    background: none;
    box-shadow: none;
  `}

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 4rem;
    bottom: 0;
    left: 0;
    transform: translateY(99%);
    background: ${getSheetThemeValue('Background')};
  }
`;

const SheetButtons = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45rem;
  max-width: 100%;

  ${media.tabletLandscapeAndUp`
    width: 50rem;
  `}

  & > * {
    margin-bottom: 0.8rem;

    ${media.tabletLandscapeAndUp`
      margin-bottom: 1.2rem;
    `}
  }
`;

const SheetButton = styled(Button)`
  flex: 1 1 auto;
  width: 100%;

  ${(props) => colorStyles(props.theme.SheetButton.Color)}

  &.activated {
    ${(props) => colorStyles(props.theme.SheetButton.Color.Activated)}
  }
`;

SheetButton.defaultProps = {
  size: 'xlarge',
  shape: 'rounded',
};

export const Sheet = Object.assign(
  (props) => {
    const backdropStyle = useSpring({
      opacity: props.show ? 0.9 : 0,
      config: {
        mass: 1,
        friction: 40,
        tension: 600,
        clamp: true,
      },
    });

    const sheetMenuStyle = useSpring({
      opacity: props.show ? 1 : 0,
      transform: props.show ? 'translateY(0%)' : 'translateY(100%)',
      config: {
        mass: 1.5,
        tension: 240,
        friction: 29,
        precision: 0.001,
      },
    });

    const handleClick = (e) => {
      e.stopPropagation();
      props.onClose();
    };

    if (props.show !== true) {
      return null;
    }

    return (
      <SheetWrapper>
        <SheetBackdrop style={backdropStyle} onClick={handleClick} />
        <SheetContainer>
          <SheetContent style={sheetMenuStyle}>
            <SheetButtons style={sheetMenuStyle}>{props.children}</SheetButtons>
          </SheetContent>
          <Footer position="static">{props.footer}</Footer>
        </SheetContainer>
      </SheetWrapper>
    );
  },
  {
    Button: SheetButton,
  }
);

Sheet.defaultProps = {
  onClose: () => {},
};

Sheet.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
