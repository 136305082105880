import { partial as _partial } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { media } from './style-utils';
import { getThemeValue } from './Theme';

const getScopedThemeValue = _partial(getThemeValue, 'InfoText.Color');

const Text = styled.div`
  color: ${getScopedThemeValue('Default')};
  text-align: center;
  padding: 0 1.5rem;
  margin-bottom: 2.4rem;

  ${media.tabletLandscapeAndUp`
    padding: 0 4rem;
    margin-bottom: 3.6rem;
  `}
`;

export const InfoText = (props) => {
  return <Text {...props}>{props.children}</Text>;
};
