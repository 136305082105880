import { sleep } from '@nucleus/lib-function';

const UNIQUE_ATTRIBUTE = 'key';
const HASH_ATTRIBUTE = 'hash';
const LOADED_ATTRIBUTE = 'loaded';
const LOADED_VALUE = 'true';
const ERROR_VALUE = 'false';
const SLEEP_DELAY = 250;
const LOAD_TIMEOUT = 10 * 1000;
const HASH_ALGORITHM = 'SHA-256';

export async function loadScript(window, src) {
  const id = await hash(src);
  const scriptNode = addScriptNode(window, src, id);
  return await waitForLoaded(scriptNode);
}

function addScriptNode(window, src, id) {
  const uniqueValue = Math.random().toString();
  const parent = window.document.getElementsByTagName('head')[0];

  const script = window.document.createElement('script');
  script.setAttribute(HASH_ATTRIBUTE, id);
  script.setAttribute(UNIQUE_ATTRIBUTE, uniqueValue);
  parent.appendChild(script);

  const existingNodes = Array.from(parent.getElementsByTagName('script')).filter((node) => {
    return node.getAttribute(HASH_ATTRIBUTE) === id;
  });

  if (existingNodes[0].getAttribute(UNIQUE_ATTRIBUTE) !== uniqueValue) {
    parent.removeChild(script);
    return existingNodes[0];
  }

  script.onload = () => {
    script.setAttribute(LOADED_ATTRIBUTE, LOADED_VALUE);
  };

  script.onerror = (e) => {
    script.setAttribute(LOADED_ATTRIBUTE, ERROR_VALUE);
    parent.removeChild(script);
  };

  script.setAttribute('src', src);

  return script;
}

async function waitForLoaded(scriptNode) {
  const timedOutAfter = Date.now() + LOAD_TIMEOUT;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const attributeValue = scriptNode.getAttribute(LOADED_ATTRIBUTE);

    if (attributeValue === LOADED_VALUE) {
      return true;
    }

    if (attributeValue === ERROR_VALUE) {
      throw new Error('The script failed to load');
    }

    if (Date.now() > timedOutAfter) {
      throw new Error('Timed out waiting for the script to load');
    }

    await sleep(SLEEP_DELAY);
  }
}

async function hash(str) {
  const hashed = await crypto.subtle.digest(HASH_ALGORITHM, new TextEncoder().encode(str));
  // https://stackoverflow.com/questions/40031688/javascript-arraybuffer-to-hex
  return Array.prototype.map.call(new Uint8Array(hashed), (x) => ('00' + x.toString(16)).slice(-2)).join('');
}
