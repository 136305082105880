export const ACTION_CLEAR_CARD = 'ACTION_CLEAR_CARD';
export const ACTION_SET_CARD = 'ACTION_SET_CARD';

export const frozenObject = Object.freeze({});
export const frozenArray = Object.freeze([]);

export function buildAction(action, payload) {
  const data = {
    type: action,
  };

  if (payload !== undefined) {
    data.payload = payload;
  }

  return data;
}
