export type COLOR_MODE = 'COLOR_MODE_AUTO' | 'COLOR_MODE_LIGHT' | 'COLOR_MODE_DARK';
export const COLOR_MODE_AUTO = 'COLOR_MODE_AUTO';
export const COLOR_MODE_LIGHT = 'COLOR_MODE_LIGHT';
export const COLOR_MODE_DARK = 'COLOR_MODE_DARK';
export const COLOR_MODES = Object.freeze([COLOR_MODE_LIGHT, COLOR_MODE_DARK]);
export const MODE = { AUTO: COLOR_MODE_AUTO, LIGHT: COLOR_MODE_LIGHT, DARK: COLOR_MODE_DARK };

export const DEFAULT_COLOR_MODE = MODE.LIGHT;
export const DEFAULT_THEME_COLOR = '#000000';

export const ERROR_COLOR_FLOW = '#ffffff';
export const ERROR_COLOR_MODE = MODE.LIGHT;
export const ERROR_THEME = {
  color: ERROR_COLOR_FLOW,
  mode: ERROR_COLOR_MODE,
};
