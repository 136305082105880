import { Select } from '@shared/components/Select';
import { PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE, PROFILE_ATTRIBUTE_ENUM_GENDER_MALE } from '@shared/constants/profile';
import React, { useMemo } from 'react';
import { FlowStep } from '../components/FlowStep';
import { SmartStepActionButtons } from '../components/StepActionButtons';

const GENDER_LABEL_BY = {
  [PROFILE_ATTRIBUTE_ENUM_GENDER_MALE]: 'Male',
  [PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE]: 'Female',
};

export const StepSmartGender = (props) => {
  const message = useMemo(() => {
    if (props.data?.skipped === true) {
      return `Skipped: "${props.config.label}"`;
    }
    return ['My gender is: ', GENDER_LABEL_BY[props.data?.value]].join(' ');
  }, [props.config.label, props.data]);

  return (
    <FlowStep message={message}>
      <SelectGender onComplete={props.completeStep} required={props.config.required} />
    </FlowStep>
  );
};

const options = [
  {
    id: 'male',
    label: 'Male',
    value: PROFILE_ATTRIBUTE_ENUM_GENDER_MALE,
  },
  {
    id: 'female',
    label: 'Female',
    value: PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE,
  },
];

const SelectGender = (props) => {
  const handleChange = (option) => {
    props.onComplete({
      value: option.value,
    });
  };

  const handleSkipClick = () => {
    props.onComplete({
      skipped: true,
      value: undefined,
    });
  };

  return (
    <>
      <Select options={options} onChange={handleChange} />
      <SmartStepActionButtons
        onSkipClick={handleSkipClick}
        readyToContinue={false}
        required={props.required}
        style={{ marginTop: '3rem' }}
      />
    </>
  );
};
