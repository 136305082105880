export const PROFILE_ATTRIBUTE_TYPE_ADDRESS = 'address';
export const PROFILE_ATTRIBUTE_TYPE_EMAIL = 'email';
export const PROFILE_ATTRIBUTE_TYPE_FILE = 'file';
export const PROFILE_ATTRIBUTE_TYPE_NAME = 'name';
export const PROFILE_ATTRIBUTE_TYPE_PHONE = 'phone';
export const PROFILE_ATTRIBUTE_TYPE_IDENTIFIER = 'identifier';
export const PROFILE_ATTRIBUTE_TYPE_DATE = 'date';
export const PROFILE_ATTRIBUTE_TYPE_ENUM = 'enum';

export const PROFILE_ATTRIBUTE_MAP = Object.freeze({
  ADDRESS: PROFILE_ATTRIBUTE_TYPE_ADDRESS,
  EMAIL: PROFILE_ATTRIBUTE_TYPE_EMAIL,
  FILE: PROFILE_ATTRIBUTE_TYPE_FILE,
  NAME: PROFILE_ATTRIBUTE_TYPE_NAME,
  PHONE: PROFILE_ATTRIBUTE_TYPE_PHONE,
  IDENTIFIER: PROFILE_ATTRIBUTE_TYPE_IDENTIFIER,
  DATE: PROFILE_ATTRIBUTE_TYPE_DATE,
  ENUM: PROFILE_ATTRIBUTE_TYPE_ENUM,
});

export const PROFILE_ATTRIBUTE_TYPES = Object.freeze([
  PROFILE_ATTRIBUTE_TYPE_ADDRESS,
  PROFILE_ATTRIBUTE_TYPE_EMAIL,
  PROFILE_ATTRIBUTE_TYPE_FILE,
  PROFILE_ATTRIBUTE_TYPE_NAME,
  PROFILE_ATTRIBUTE_TYPE_PHONE,
  PROFILE_ATTRIBUTE_TYPE_IDENTIFIER,
  PROFILE_ATTRIBUTE_TYPE_DATE,
  PROFILE_ATTRIBUTE_TYPE_ENUM,
]);

export const PROFILE_ATTRIBUTE_LABEL_FAX = 'fax';
export const PROFILE_ATTRIBUTE_LABEL_HOME = 'home';
export const PROFILE_ATTRIBUTE_LABEL_MAILING = 'mailing';
export const PROFILE_ATTRIBUTE_LABEL_MOBILE = 'mobile';
export const PROFILE_ATTRIBUTE_LABEL_OTHER = 'other';
export const PROFILE_ATTRIBUTE_LABEL_WORK = 'work';

export const PROFILE_DESIGNATION_PRIMARY = 'primary';

export const ADDRESS_LABELS = [
  PROFILE_ATTRIBUTE_LABEL_HOME,
  PROFILE_ATTRIBUTE_LABEL_WORK,
  PROFILE_ATTRIBUTE_LABEL_MAILING,
];
export const PHONE_LABELS = [
  PROFILE_ATTRIBUTE_LABEL_HOME,
  PROFILE_ATTRIBUTE_LABEL_WORK,
  PROFILE_ATTRIBUTE_LABEL_MOBILE,
  PROFILE_ATTRIBUTE_LABEL_FAX,
  PROFILE_ATTRIBUTE_LABEL_OTHER,
];
export const EMAIL_LABELS = [PROFILE_ATTRIBUTE_LABEL_HOME, PROFILE_ATTRIBUTE_LABEL_WORK, PROFILE_ATTRIBUTE_LABEL_OTHER];

export const PROFILE_ATTRIBUTE_ENUM_GENDER = 'gender';
export const PROFILE_ATTRIBUTE_ENUM_GENDER_MALE = 'male';
export const PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE = 'female';
export const PROFILE_ATTRIBUTE_ENUM_GENDERS = Object.freeze([
  PROFILE_ATTRIBUTE_ENUM_GENDER_MALE,
  PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE,
]);

export const PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUS = 'maritalStatus';
export const PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUS_MARRIED = 'married';
export const PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUS_SINGLE = 'single';
export const PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUS_DIVORCED = 'divorced';
export const PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUS_WIDOWED = 'widowed';
export const PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUSES = Object.freeze([
  PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUS_MARRIED,
  PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUS_SINGLE,
  PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUS_DIVORCED,
  PROFILE_ATTRIBUTE_ENUM_MARITAL_STATUS_WIDOWED,
]);

export const PROFILE_ATTRIBUTE_DATE_BIRTHDATE = 'birthdate';
export const PROFILE_ATTRIBUTE_DATE_ANNIVERSARY = 'anniversary';
export const PROFILE_ATTRIBUTE_DATES = Object.freeze([
  PROFILE_ATTRIBUTE_DATE_ANNIVERSARY,
  PROFILE_ATTRIBUTE_DATE_BIRTHDATE,
]);

export const PROFILE_ATTRIBUTE_FIELDS_MAP = {
  [PROFILE_ATTRIBUTE_TYPE_ADDRESS]: ['address_mailing'],
  [PROFILE_ATTRIBUTE_TYPE_EMAIL]: ['address_email'],
  [PROFILE_ATTRIBUTE_TYPE_FILE]: ['profile_file_id', 'profile_file_url'],
  [PROFILE_ATTRIBUTE_TYPE_IDENTIFIER]: ['owner_provided_id'],
  [PROFILE_ATTRIBUTE_TYPE_NAME]: ['first_name', 'middle_name', 'last_name'],
  [PROFILE_ATTRIBUTE_TYPE_PHONE]: ['phone'],
};
