import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import { ButtonIconCircle } from './ButtonIconCircle';
import { PlusRounded, XRounded } from './Icons';
import { LoadingSpinner } from './LoadingSpinner';
import { font, media } from './style-utils';
import { getThemeValue } from './Theme';

const getTheme = _partial(getThemeValue, 'ButtonFile.Color');

const ButtonBase = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 10.8rem;
  width: 100%;
  margin: 1rem 0;

  ${font(24, 'Bold', -0.5)}

  ${media.largePhoneAndUp`
    ${font(28, 'Bold', -0.5)}
  `}

  text-align: center;

  background: none;
  border: none;
  border-radius: 2.1rem;

  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const ButtonVariant = {
  upload: css`
    background-color: ${getTheme('Upload.Default.Background')};
    color: ${getTheme('Upload.Default.Foreground')};
    cursor: pointer;
    transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1);

    :hover {
      background-color: ${getTheme('Upload.Hover.Background')};
      color: ${getTheme('Upload.Hover.Foreground')};
    }

    :not([disabled]):active {
      transform: scale(0.94);
    }

    ${({ dragging }) => {
      if (dragging) {
        return css`
          background-color: ${getTheme('Upload.Hover.Background')};
          color: ${getTheme('Upload.Hover.Foreground')};
        `;
      }
    }}
  `,
  uploading: css`
    background-color: ${getTheme('Uploading.Default.Background')};
    color: ${getTheme('Uploading.Default.Foreground')};
    overflow: hidden;
  `,
  success: css`
    background-color: ${getTheme('Success.Default.Background')};
    color: ${getTheme('Success.Default.Foreground')};
    border-color: ${getTheme('Success.Default.Foreground')};
    border-width: 0.5rem;
    border-style: solid;
  `,
  error: css`
    background-color: ${getTheme('Error.Default.Background')};
    color: ${getTheme('Error.Default.Foreground')};
  `,
};

const Button = styled.button.attrs((props) => ({ onTouchStart: () => true }))`
  ${ButtonBase};
  ${({ variant }) => ButtonVariant[variant]};
`;

const PositionIcon = styled.div`
  position: absolute;
  left: 1.45rem;

  ${media.largePhoneAndUp`
    left: 2.9rem;
  `}
`;

const Smalltext = styled.span`
  ${font(14, 'Book', -0.25)}
  max-width: 60%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ButtonFileUpload = (props) => {
  const { onClick, onDrop, ...otherProps } = props;

  const [dragging, setDragging] = useState();

  const handleDrop = (e) => {
    props.onDrop(e);
    e.preventDefault();
  };
  const handleDragOver = (e) => {
    setDragging(true);
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    setDragging(false);
    e.preventDefault();
  };

  return (
    <Button
      variant="upload"
      onClick={props.onClick}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      dragging={dragging}
      {...otherProps}
    >
      <PositionIcon>
        <ButtonIconCircle as="span">
          <PlusRounded />
        </ButtonIconCircle>
      </PositionIcon>
      {props.children}
    </Button>
  );
};

ButtonFileUpload.propTypes = {
  onClick: PropTypes.func,
  onDrop: PropTypes.func,
};

const Progress = animated(styled.span`
  background-color: ${getTheme('Uploading.Hover.Background')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`);

const ButtonFileUploading = (props) => {
  const { onClick, progress, ...otherProps } = props;

  const { width } = useSpring({
    from: { width: progress },
    to: { width: progress },
    config: { tension: 150, friction: 14, clamp: true },
  });

  return (
    <Button as="div" variant="uploading" {...otherProps}>
      <Progress style={{ width: width.interpolate((w) => `${w}%`) }} />
      <PositionIcon>
        <ButtonIconCircle onClick={props.onClick}>
          <LoadingSpinner />
        </ButtonIconCircle>
      </PositionIcon>
      <span style={{ position: 'relative' }}>{props.children}</span>
    </Button>
  );
};

ButtonFileUploading.propTypes = {
  onClick: PropTypes.func,
  progress: PropTypes.number,
};

const ButtonFileSuccess = (props) => {
  const { filename, onClick, ...otherProps } = props;

  return (
    <Button as="div" variant="success" {...otherProps}>
      <PositionIcon>
        <ButtonIconCircle onClick={props.onClick}>
          <XRounded />
        </ButtonIconCircle>
      </PositionIcon>
      {props.children}
      <Smalltext>{props.filename}</Smalltext>
    </Button>
  );
};

ButtonFileSuccess.propTypes = {
  filename: PropTypes.string,
  onClick: PropTypes.func,
};

const ButtonFileError = (props) => {
  const { errorMessage, onClick, ...otherProps } = props;

  return (
    <Button as="div" variant="error" {...otherProps}>
      <PositionIcon>
        <ButtonIconCircle onClick={props.onClick}>
          <XRounded />
        </ButtonIconCircle>
      </PositionIcon>
      {props.children}
      <Smalltext>{props.errorMessage}</Smalltext>
    </Button>
  );
};

ButtonFileError.propTypes = {
  errorMessage: PropTypes.string,
  onClick: PropTypes.func,
};

export const ButtonFile = ButtonFileUpload;

ButtonFile.Upload = ButtonFileUpload;
ButtonFile.Uploading = ButtonFileUploading;
ButtonFile.Success = ButtonFileSuccess;
ButtonFile.Error = ButtonFileError;
