import { ButtonOptionSecondary } from '@foyyay/flow-elements';
import { loadSavedSources } from '@shared/actions/user';
import { Select, SelectSecondary } from '@shared/components/Select';
import {
  SOURCE_TYPE_LABELS,
  SOURCE_TYPE_ACH_DEBIT,
  SOURCE_TYPE_CARD,
  SOURCE_TYPE_CASH,
  SOURCE_TYPE_CHECK,
  SOURCE_TYPE_OTHER,
  SOURCE_TYPES_OFFLINE,
  SOURCE_TYPES_ONLINE,
} from '@shared/constants';
import { buildConfigValidator } from '@shared/lib/schema';
import { selectSourceTypesByFlowId } from '@shared/reducers/flows/flowsByIdReducer';
import { selectIsAuthenticated, selectSavedPaymentSources } from '@shared/reducers/user';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { FlowContext } from '..';
import { FlowStep } from '../components/FlowStep';
import { LoadingStep } from '../LoadingStep';

export const StepPaymentSourceType = (props) => {
  const defaultValue = {};
  const initialValue = props.data?.value ?? defaultValue;

  let message = sourceTypeMessage(initialValue.sourceType);
  if (initialValue?.source !== undefined) {
    message = message + ' ending in ' + initialValue.source?.account_last_few;
  }

  return (
    <FlowStep message={message}>
      <PaymentSourceTypeSelect config={props.config} onComplete={props.completeStep} value={initialValue} />
    </FlowStep>
  );
};

const optionSchema = yup.object().shape({
  label: yup.string(),
  value: yup.string(),
});

const configSchema = {
  description: yup.string(),
  label: yup.string().min(3).required(),
  options: yup.array().of(optionSchema).min(1),
  required: yup.bool().required(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepPaymentSourceType.isConfigValid = isConfigValid;

const PaymentSourceTypeSelect = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const refreshSavedSources = useCallback(async () => {
    setLoading(true);
    await dispatch(loadSavedSources());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    refreshSavedSources();
  }, [refreshSavedSources]);

  const { currentFlowId } = useContext(FlowContext);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const allowedSourceTypes = useSelector((state) => selectSourceTypesByFlowId(state, currentFlowId));
  const sourceTypes = allowedSourceTypes.map((sourceType) => ({
    id: sourceType,
    label: SOURCE_TYPE_LABELS[sourceType].label,
  }));

  const addNewPaymentSourceOptions = [];
  SOURCE_TYPES_ONLINE.forEach((sourceType) => {
    if (allowedSourceTypes.includes(sourceType)) {
      addNewPaymentSourceOptions.push({
        id: sourceType,
        label: `+ Add new ${SOURCE_TYPE_LABELS[sourceType].label_new}`,
      });
    }
  });

  const offlineSources = [];
  SOURCE_TYPES_OFFLINE.forEach((sourceType) => {
    if (allowedSourceTypes.includes(sourceType)) {
      offlineSources.push({
        id: sourceType,
        label: SOURCE_TYPE_LABELS[sourceType].label,
      });
    }
  });

  const allSavedPaymentSources = useSelector(selectSavedPaymentSources);
  const savedPaymentSources = allSavedPaymentSources.filter((source) => allowedSourceTypes.includes(source.type));
  const hasSavedPaymentSources = savedPaymentSources.length > 0;

  const handleSourceTypeChange = (value) => {
    props.onComplete({
      value: {
        sourceType: { id: value.id, label: SOURCE_TYPE_LABELS[value.id].label },
        source: undefined,
      },
    });
  };

  const handleSavedSourceChange = (value) => {
    if (SOURCE_TYPES_OFFLINE.includes(value.id)) {
      value = {
        sourceType: { id: value.id, label: SOURCE_TYPE_LABELS[value.id].label },
        source: undefined,
      };
    } else {
      value = {
        sourceType: { id: value.type, label: SOURCE_TYPE_LABELS[value.type].label_short },
        source: value,
      };
    }
    props.onComplete({
      value: value,
    });
  };

  if (loading) {
    return <LoadingStep />;
  }

  return hasSavedPaymentSources ? (
    <>
      <Select options={[...savedPaymentSources, ...offlineSources]} onChange={handleSavedSourceChange} />
      <SelectSecondary options={addNewPaymentSourceOptions} onChange={handleSourceTypeChange} />
    </>
  ) : (
    <>
      <Select options={sourceTypes} onChange={handleSourceTypeChange} />
      {!isAuthenticated && (
        <Link to={`/account/sign-in`}>
          <ButtonOptionSecondary style={{ marginTop: '1rem' }}>Or, use saved info (Sign In)</ButtonOptionSecondary>
        </Link>
      )}
    </>
  );
};

const SOURCE_TYPE_VALUE_PREFIX_LABEL_BY = {
  [SOURCE_TYPE_ACH_DEBIT]: 'Using my',
  [SOURCE_TYPE_CARD]: 'Using my',
  [SOURCE_TYPE_CASH]: 'with',
  [SOURCE_TYPE_CHECK]: 'With a',
  [SOURCE_TYPE_OTHER]: 'with',
};
const SOURCE_TYPE_VALUE_SUFFIX_LABEL_BY = {
  [SOURCE_TYPE_OTHER]: 'method',
};
const sourceTypeMessage = (sourceType) => {
  if (sourceType === undefined) {
    return 'Select a payment method';
  }
  let message = sourceType;
  const prefix = SOURCE_TYPE_VALUE_PREFIX_LABEL_BY[sourceType.id];
  const suffix = SOURCE_TYPE_VALUE_SUFFIX_LABEL_BY[sourceType.id];
  if (prefix !== undefined) {
    message = prefix + ' ' + sourceType.label;
  }
  if (suffix !== undefined) {
    message = message + ' ' + suffix;
  }
  return message;
};
