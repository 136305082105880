import { ButtonOptionPrimary, ButtonOptionSecondary, ButtonPrimary } from '@foyyay/flow-elements';
import { withStopPropagation } from '@shared/lib/events';
import { validateStep } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext } from 'react';
import { FlowStep } from '../../../../components/FlowStep';
import { editContact, setCurrentStep } from '../actions/stepContactActions';
import { buildContactDataParser } from '../lib/ContactData';
import { selectContacts, selectLabel, selectMessage } from '../reducers/stepMultipleContactReducer';
import { StepMultipleContactContext } from '../StepMultipleContact';

export const StepContactList = (props) => {
  const { useSelector } = useContext(StepMultipleContactContext);

  const label = useSelector((state) => selectLabel(state, props.config, props.data));
  const message = useSelector((state) => selectMessage(state, props.config, props.data));

  const contacts = useSelector(selectContacts);

  const steps = props.config.steps?.filter((step) => validateStep(step) === true && step.config.active === true);
  const parser = buildContactDataParser(steps);

  return (
    <FlowStep description={props.config.description} label={label} message={message}>
      {contacts.length > 0 ? (
        <ContactList contacts={contacts} parser={parser} onComplete={props.completeStep} />
      ) : (
        <AddContact onComplete={props.completeStep} required={props.config.required} />
      )}
    </FlowStep>
  );
};

const AddContact = (props) => {
  const { dispatch } = useContext(StepMultipleContactContext);

  const handleAddClick = withStopPropagation(() => dispatch(setCurrentStep('add')));
  const handleSkipClick = withStopPropagation(() =>
    props.onComplete({
      skipped: true,
      value: undefined,
    })
  );

  return (
    <>
      <ButtonOptionPrimary onClick={handleAddClick}>Add information</ButtonOptionPrimary>
      {props.required !== true && (
        <ButtonOptionSecondary onClick={handleSkipClick}>or, Skip &amp; Continue</ButtonOptionSecondary>
      )}
    </>
  );
};

const ContactList = (props) => {
  const { dispatch } = useContext(StepMultipleContactContext);

  const handleEditClick = withStopPropagation((contact) => dispatch(editContact(contact)));
  const handleAddClick = withStopPropagation(() => dispatch(setCurrentStep('add')));
  const handleSaveClick = withStopPropagation(() => {
    props.onComplete({
      value: props.contacts,
    });
  });

  return (
    <>
      {props.contacts.map((contact) => {
        const name = props.parser.parseName(contact);
        return (
          <ButtonOptionPrimary key={contact.id} onClick={(e) => handleEditClick(e, contact)}>
            Added: {name}
          </ButtonOptionPrimary>
        );
      })}
      <ButtonOptionSecondary onClick={handleAddClick}>+ Add Another</ButtonOptionSecondary>
      <ButtonPrimary style={{ marginTop: '3rem' }} onClick={handleSaveClick}>
        or, Save &amp; Continue
      </ButtonPrimary>
    </>
  );
};
