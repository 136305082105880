import { createReducer } from '@reduxjs/toolkit';
import { deepFreeze } from '@shared/lib/deepFreeze';
import {
  addContact,
  editContact,
  editContactField,
  removeContact,
  saveContactField,
  setCurrentStep,
} from '../actions/stepContactActions';

export const selectCurrentStep = (state) => state.currentStep;
export const selectContacts = (state) => state.contacts;
export const selectContactById = (state, id) => state.contacts.find((contact) => contact.id === id);
export const selectCurrentContact = (state) => selectContactById(state, state.currentContactId);

export const selectLabel = (state, config, data) => {
  return config.label;
};

export const selectMessage = (state, config, data) => {
  if (data?.skipped === true) {
    return ['Skipped: ', config.label].join(' ').trim();
  }
  return `Added: (${data?.value?.length}) ${
    (config.contactLabel !== undefined) & (config.contactLabel !== '') ? config.contactLabel : 'Person'
  }`;
};

const initialState = deepFreeze({
  contacts: [],
  currentStep: 'list',
  currentContactId: undefined,
});

export const init = (args) => ({
  ...initialState,
  contacts: args.data?.value ?? initialState.contacts,
});

export const stepMultipleContactReducer = createReducer(initialState, {
  [setCurrentStep]: (state, action) => ({
    ...state,
    currentStep: action.payload,
  }),
  [addContact]: (state, action) => ({
    ...state,
    contacts: [...state.contacts, action.payload],
  }),
  [editContact]: (state, action) => ({
    ...state,
    currentStep: 'options',
    currentContactId: action.payload.id,
  }),
  [editContactField]: (state, action) => ({
    ...state,
    currentStep: action.payload.id,
  }),
  [saveContactField]: (state, action) => {
    const index = state.contacts.findIndex((contact) => contact.id === state.currentContactId);

    return {
      ...state,
      currentStep: 'card',
      contacts: [
        ...state.contacts.slice(0, index),
        {
          ...state.contacts[index],
          data: {
            ...state.contacts[index].data,
            [action.payload.step.id]: action.payload.data,
          },
        },
        ...state.contacts.slice(index + 1),
      ],
    };
  },
  [removeContact]: (state, action) => ({
    ...state,
    currentStep: 'list',
    currentContactId: undefined,
    contacts: state.contacts.filter((contact) => contact.id !== action.payload.id),
  }),
});
