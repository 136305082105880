import { ButtonPrimary, H2, InputLine, Prompt, PromptActions, T1 } from '@foyyay/flow-elements';
import { ModalOverlay } from '@shared/components/Modal';
import { withPreventDefault, withStopPropagation } from '@shared/lib/events';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ModalPromptChallenge = (props) => {
  const [challengeAnswer, setChallengeAnswer] = useState('');
  const showChallenge = props.challenge.id !== undefined;

  useEffect(() => {
    setChallengeAnswer('');
  }, [props.challenge.id]);

  const handleChallengeAnswerChange = (e) => {
    setChallengeAnswer(e.target.value);
  };

  const doRetry = () => {
    props.challengeRetryFunction({
      challenge: {
        id: props.challenge?.id,
        answer: challengeAnswer,
      },
    });
  };

  const handleEnter = withPreventDefault(doRetry);
  const handleClick = withStopPropagation(doRetry);

  return (
    <ModalOverlay show={showChallenge}>
      <Prompt>
        <H2>For additional security, please type the numbers and/or letters you&nbsp;see:</H2>
        {!props.challenge?.failed && (
          <T1>
            You may be able to skip this step by{' '}
            <Link to={`/account/sign-in`}>
              <UnderlineText>Signing In</UnderlineText>
            </Link>{' '}
            and using a saved payment&nbsp;method.
          </T1>
        )}
        {props.challenge?.failed && (
          <T1>Sorry… your entry didn’t seem to match what was shown below. Please try again.</T1>
        )}
        <ChallengeImage dangerouslySetInnerHTML={{ __html: props.challenge.challenge }} />
        <ChallengeAnswer
          placeholder="Start typing here"
          value={challengeAnswer}
          onChange={handleChallengeAnswerChange}
          onEnter={handleEnter}
        />
        <PromptActions>
          <ButtonPrimary disabled={challengeAnswer.length < 5} onClick={handleClick} processing={props.processing}>
            Continue
          </ButtonPrimary>
        </PromptActions>
      </Prompt>
    </ModalOverlay>
  );
};

ModalPromptChallenge.propTypes = {
  challenge: PropTypes.object,
  challengeRetryFunction: PropTypes.func,
  open: PropTypes.bool,
  processing: PropTypes.bool,
};

ModalPromptChallenge.defaultProps = {
  challenge: {},
  challengeRetryFunction: () => {},
  processing: false,
};

const UnderlineText = styled.span`
  text-decoration: underline;
`;

const ChallengeImage = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 26rem;
  margin: 2.6rem auto;
  line-height: 0;
  border-radius: 1.4rem;
  overflow: hidden;

  & > svg {
    width: 100%;
    height: auto;
  }
`;

const ChallengeAnswer = styled(InputLine)`
  text-align: center;
`;
