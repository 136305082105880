import { font, getThemeValue, media } from '@foyyay/flow-elements';
import { Button } from '@shared/components/Button';
import { partial as _partial } from 'lodash';
import styled from 'styled-components';

const getPrayerCardThemeValue = _partial(getThemeValue, 'PrayerCard.Color.Default');
const getPrayCardNoticeThemeValue = _partial(getThemeValue, 'PrayCardNotice.Color.Default');

const PrayCardNoticeText = styled.div`
  color: ${getPrayCardNoticeThemeValue('Foreground')};

  ${font(12, 'Book', -0.5, 12)}

  ${media.tabletLandscapeAndUp`
    ${font(16, 'Book', -0.75, 16)}
  `}
`;

const DesktopOnlySpan = styled.span`
  display: none;

  ${media.tabletLandscapeAndUp`
    display: inline;
  `}
`;

const PrayCardNoticeTopline = styled.div`
  ${font(10, 'Book', -0.5)}
  opacity: 0.75;

  ${media.tabletLandscapeAndUp`
    ${font(12, 'Book', -0.5)}
  `}
`;

const PrayCardNoticeContent = styled.div`
  flex: 1 1 auto;
`;

const PrayCardNoticeButton = styled(Button)`
  flex: 0 0 auto;
`;

const PrayCardNoticePostline = styled.div`
  ${font(10, 'Book', -0.25)}
  color: ${getPrayerCardThemeValue('Foreground')};
  opacity: 0.35;
  text-align: center;

  ${media.tabletLandscapeAndUp`
  ${font(12, 'Book', -0.25)}
  `}
`;

export const PrayCardNotice = Object.assign(
  styled.div`
    background: ${getPrayCardNoticeThemeValue('Background')};
    border-radius: 1rem;
    display: flex;
    align-items: center;
    padding: 1rem;

    ${media.tabletLandscapeAndUp`
      padding: 1.2rem 1.5rem 1.2rem 2rem;
    `}
  `,
  {
    Button: PrayCardNoticeButton,
    Content: PrayCardNoticeContent,
    DesktopOnlySpan: DesktopOnlySpan,
    Text: PrayCardNoticeText,
    Topline: PrayCardNoticeTopline,
    Postline: PrayCardNoticePostline,
  }
);
