import {
  booleanPropHelperFactory,
  Chevron,
  Collapse,
  Expand,
  font,
  Header as HeaderElement,
  media,
  X,
} from '@foyyay/flow-elements';
import { SignInProfile } from '@shared/components/SignInProfile';
import { ControllerContext } from '@shared/context/ControllerContext';
import { ScrollContext } from '@shared/context/ScrollContext';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { selectIsAuthenticated } from '@shared/reducers/user';
import Color from 'color';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Alerts } from './Alerts';

export const Header = React.forwardRef((props, ref) => {
  const context = useContext(ControllerContext);
  const { scrollTop } = useContext(ScrollContext);

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const history = useHistory();
  const navigated = history.index > 0;

  const showBackButton = navigated || context.showBackButton;
  const handleBackClick = useClickHandler(() => {
    if (navigated) {
      return history.goBack();
    }

    if (context.showBackButton) {
      return context.goBack();
    }
  });

  const showCloseButton = typeof context.handleClickCloseOverlay === 'function' && context.showCloseButton;
  const showFullscreenButton =
    typeof context.handleClickFullscreen === 'function' && context.showFullscreenButton === true;
  const showSignInProfile = (context.showSignInProfile ?? true) || isAuthenticated;

  const headerBarProps = {
    isScrolled: scrollTop > 2,
    showTitle: scrollTop > 100 && props.title !== undefined && props.title !== '',
  };

  return (
    <>
      <Alerts />
      <HeaderElement className={props.className} ref={ref}>
        <HeaderBar {...headerBarProps}>
          <HeaderButtons>
            {showCloseButton && (
              <CloseButton
                variant={showBackButton || showFullscreenButton ? 'primary' : undefined}
                onClick={context.handleClickCloseOverlay}
              />
            )}
            {showBackButton && <BackButton onClick={handleBackClick} />}
            {showFullscreenButton && (
              <FullscreenButton isFullscreen={context.isFullscreen} onClick={context.handleClickFullscreen} />
            )}
          </HeaderButtons>
          <Center>
            <HeaderTitle>{props.title}</HeaderTitle>
          </Center>
          {showSignInProfile && <SignInProfile />}
        </HeaderBar>
      </HeaderElement>
    </>
  );
});

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${font(14, 'Medium', -0.5, 16)};
`;

const HeaderTitle = styled.div`
  position: absolute;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 auto;
  max-width: calc(100vw - 22rem);
  transform: translateX(-50%, -50%);
`;

const HeaderButtons = styled.div`
  margin: 0 1.1rem;
  display: flex;
  pointer-events: auto;
`;

const StyledHeaderButton = styled(HeaderElement.Button)`
  width: 4rem;
  padding: 0;
  margin: 0 0.4rem;

  ${media.tabletLandscapeAndUp`
    width: 5rem;
  `}
`;
const StyledBackIcon = styled(Chevron)`
  width: 40%;
`;

const BackButton = (props) => (
  <StyledHeaderButton {...props}>
    <StyledBackIcon />
  </StyledHeaderButton>
);

const StyledExpandIcon = styled(Expand)`
  width: 100%;
`;

const StyledCollapseIcon = styled(Collapse)`
  width: 100%;
`;

const FullscreenButton = (props) => {
  const Icon = props.isFullscreen ? StyledCollapseIcon : StyledExpandIcon;
  return (
    <StyledHeaderButton {...props}>
      <Icon />
    </StyledHeaderButton>
  );
};

const StyledCloseIcon = styled(X)`
  width: 30%;
`;

const CloseButton = (props) => (
  <StyledHeaderButton {...props}>
    <StyledCloseIcon />
  </StyledHeaderButton>
);

const isScrolled = booleanPropHelperFactory('isScrolled');
const showTitle = booleanPropHelperFactory('showTitle');

const HeaderBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  will-change: background;
  pointer-events: none;

  ${Center} {
    opacity: 0;
    transform: translateY(2rem);
    transition:
      opacity 0.15s cubic-bezier(0.22, 1, 0.36, 1),
      transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }

  transition:
    background 0.2s ease-in-out,
    backdrop-filter 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;

  ${media.phoneOnly`
    ${isScrolled(css`
      background: ${(props) => props.theme.Background.Color.Default.Background};
      /* background: linear-gradient(
        180deg,
        ${(props) => Color(props.theme.HeaderBar.Color.Default.Background).alpha(1).string()} 57%,
        ${(props) => Color(props.theme.HeaderBar.Color.Default.Background).alpha(0.8).string()} 100%
      ); */
      box-shadow: 0 1px 18px 0 ${(props) => props.theme.HeaderBar.Color.Default.Shadow};
      color: ${(props) => props.theme.HeaderBar.Color.Default.Foreground};

      @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: linear-gradient(
          180deg,
          ${(props) => Color(props.theme.HeaderBar.Color.Default.Background).alpha(1).string()} 0%,
          ${(props) => Color(props.theme.HeaderBar.Color.Default.Background).alpha(1).string()} 1%,
          ${(props) => Color(props.theme.HeaderBar.Color.Default.Background).alpha(0.7).string()} 60%,
          ${(props) => Color(props.theme.HeaderBar.Color.Default.Background).alpha(0.5).string()} 100%
        );

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          -webkit-backdrop-filter: blur(1.8rem);
          backdrop-filter: blur(1.8rem);
          pointer-events: none;
          z-index: -1;
        }
      }
    `)}


    ${showTitle(css`
      ${Center} {
        opacity: 1;
        transform: translateY(0);
      }
    `)}
  `}
`;
