/* eslint-disable @typescript-eslint/no-explicit-any */
import memoizee from 'memoizee';

export const memoize = <T extends (...args: any[]) => any>(
  fn: T,
  options: memoizee.Options<T> = {}
): T & memoizee.Memoized<T> => memoizee(fn, { ...options });

export const memoizePromise = <T extends (...args: any[]) => any>(
  fn: T,
  options: memoizee.Options<T> = {}
): T & memoizee.Memoized<T> => memoizee(fn, { ...options, promise: true });
