import { ButtonOptionSecondary } from '@foyyay/flow-elements';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { buildConfigValidator } from '@shared/lib/schema';
import { selectAuthState } from '@shared/reducers/user';
import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { FlowStep } from '../../components/FlowStep';
import { SkipOrContinueButton } from '../../components/StepActionButtons';
import { FlowContext } from '../../index';
import { FileButton, FileUploadButton } from './components/FileUploadButton/FileUploadButton';
import { getAcceptedMimeTypes } from './constants';

const MAX_FILES = 5;

export const StepFileUpload = (props) => {
  const initialValue = props.data?.value ?? [];

  const skipped = props.data?.skipped === true;

  const message = useMemo(() => {
    if (skipped) {
      return `Skipped: File Upload`;
    }

    const files = props.data?.value ?? [];

    if (files.length > 1) {
      return `Files added: (${files.length}) ${files.map((file) => file.filename).join(', ')}`;
    }

    if (files.length > 0) {
      return `File added: ${files[0].filename}`;
    }
  }, [skipped, props.data?.value]);

  const description = [props.config.description, '(Maximum 5 files)'].filter((str) => str).join(' ');

  const acceptedMimeTypes = useMemo(() => getAcceptedMimeTypes(props.config.acceptedFileTypes), [
    props.config.acceptedFileTypes,
  ]);

  return (
    <FlowStep description={description} message={message}>
      <InputFiles
        acceptedMimeTypes={acceptedMimeTypes}
        allowMultiple={props.config.allowMultiple}
        onComplete={props.completeStep}
        required={props.config.required}
        value={initialValue}
      />
    </FlowStep>
  );
};

const configSchema = {
  acceptedFileTypes: yup.array().required(),
  allowMultiple: yup.bool().required(),
  description: yup.string(),
  label: yup.string().min(3).required(),
  required: yup.mixed().oneOf([true, false, 'conditional']).required(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepFileUpload.isConfigValid = isConfigValid;

const InputFiles = (props) => {
  const { currentFlowId } = useContext(FlowContext);

  const authState = useSelector(selectAuthState);

  const [adding, setAdding] = useState(false);
  const [files, setFiles] = useState(props.value);

  const handleAddClick = useClickHandler(() => setAdding(true));

  const handleFileUpload = (value) => {
    value = Array.isArray(value) ? value : [value];
    setFiles((files) => [...files, ...value]);
    setAdding(false);
  };

  const handleDelete = (index) => {
    setFiles((files) => [...files.slice(0, index), ...files.slice(index + 1)]);
  };

  const handleContinueClick = useClickHandler(() => {
    props.onComplete({
      value: files,
    });
  });

  const handleSkipClick = useClickHandler(() => {
    props.onComplete({
      skipped: true,
      value: [],
    });
  });

  const maxFiles = MAX_FILES - files.length;
  const readyToContinue = files.length > 0;

  const showFileUploadButton = files.length < 1 || adding;

  const showAddFileButton = props.allowMultiple && files.length > 0 && maxFiles > 0;

  return (
    <>
      <FileList files={files} handleDelete={handleDelete} />
      {showFileUploadButton && (
        <FileUploadButton
          acceptedMimeTypes={props.acceptedMimeTypes}
          allowMultiple={props.allowMultiple}
          authState={authState}
          flowId={currentFlowId}
          maxFiles={maxFiles}
          onUpload={handleFileUpload}
        />
      )}
      {showAddFileButton && <ButtonOptionSecondary onClick={handleAddClick}>+ Add another File?</ButtonOptionSecondary>}
      <SkipOrContinueButton
        onClick={handleContinueClick}
        onClickSkip={handleSkipClick}
        readyToContinue={readyToContinue}
        required={props.required}
      />
    </>
  );
};

const FileList = (props) => {
  return props.files.map((file, index) => (
    <FileButton key={file.id} filename={file.filename} onClick={() => props.handleDelete(index)} />
  ));
};
