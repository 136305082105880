import {
  ButtonGroup,
  ButtonGroupCircleX,
  ButtonGroupColumn,
  ButtonPrimary,
  ButtonSecondary,
} from '@foyyay/flow-elements';
import { ControllerContext } from '@shared/context/ControllerContext';
import { selectIsAuthenticated } from '@shared/reducers/user';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const SkipOrContinueButton = React.forwardRef((props, ref) => {
  const skippable = props.required === false;
  const notReadyToContinue = props.readyToContinue === false;

  if (skippable && notReadyToContinue) {
    return (
      <SkipButton
        style={props.style}
        size={props.size}
        className={props.className}
        onClick={props.onClickSkip}
        ref={ref}
      />
    );
  }

  return (
    <ContinueButton
      style={props.style}
      size={props.size}
      className={props.className}
      disabled={notReadyToContinue}
      onClick={props.onClick}
      ref={ref}
    >
      {props.children}
    </ContinueButton>
  );
});

SkipOrContinueButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  onClickSkip: PropTypes.func,
  readyToContinue: PropTypes.bool,
  required: PropTypes.bool,
};

export const ContinueButton = (props) => {
  return <ButtonPrimary {...props} />;
};

ContinueButton.propTypes = {
  children: PropTypes.any,
};

ContinueButton.defaultProps = {
  children: 'Continue',
};

export const SkipButton = (props) => {
  return <ButtonSecondary {...props} />;
};

SkipButton.propTypes = {
  children: PropTypes.any,
};

SkipButton.defaultProps = {
  children: 'or, Skip & Continue',
};

export const ContinueOrSignInButton = (props) => {
  const { allowSignIn } = useContext(ControllerContext);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const notReadyToContinue = props.readyToContinue === false;

  if (allowSignIn !== false && isAuthenticated === false && notReadyToContinue) {
    return (
      <Link to="/account/sign-in" style={{ width: '100%' }}>
        <ButtonSecondary style={{ marginTop: '1rem' }}>or, Use my info (Sign In)</ButtonSecondary>
      </Link>
    );
  }

  return <ContinueButton onClick={props.onClick} disabled={notReadyToContinue} />;
};

ContinueOrSignInButton.propTypes = {
  readyToContinue: PropTypes.bool,
  onClick: PropTypes.func,
};

export const SignInButton = (props) => {
  return (
    <ButtonSecondary as={Link} to="/account/sign-in">
      or, Use my info (Sign In)
    </ButtonSecondary>
  );
};

export const StepActionButtons = (props) => {
  const { allowSignIn } = useContext(ControllerContext);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const renderSkipButton =
    props.required === false && typeof props.onSkipClick === 'function' && props.readyToContinue !== true;
  const renderSignInButton =
    props.showSignIn === true && props.readyToContinue !== true && allowSignIn !== false && isAuthenticated !== true;
  const renderContinueButton =
    typeof props.onContinueClick === 'function' &&
    (props.readyToContinue === true || (!renderSkipButton && !renderSignInButton));
  const renderCancelButton =
    !renderContinueButton && !renderSignInButton && !renderSkipButton && typeof props.onCancelClick === 'function';
  const renderSmallCancelButton = !renderCancelButton && typeof props.onCancelClick === 'function';

  return (
    <ButtonGroup className={props.className} style={props.style}>
      {renderSmallCancelButton && <ButtonGroupCircleX onClick={props.onCancelClick} />}

      <ButtonGroupColumn>
        {renderCancelButton && <ButtonSecondary onClick={props.onCancelClick}>or, Go back</ButtonSecondary>}
        {renderSignInButton && <SignInButton />}
        {renderSkipButton && <SkipButton size={props.size} onClick={props.onSkipClick} />}
        {renderContinueButton && (
          <ContinueButton size={props.size} disabled={!props.readyToContinue} onClick={props.onContinueClick}>
            {props.children}
          </ContinueButton>
        )}
      </ButtonGroupColumn>
    </ButtonGroup>
  );
};

StepActionButtons.propTypes = {
  readyToContinue: PropTypes.bool,
  onContinueClick: PropTypes.func,
  onSkipClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  showSignIn: PropTypes.bool,
};

StepActionButtons.defaultProps = {
  showSignIn: false,
};

export const SmartStepActionButtons = (props) => <StepActionButtons {...props} />;

SmartStepActionButtons.propTypes = {
  readyToContinue: PropTypes.bool,
  onContinueClick: PropTypes.func,
  onSkipClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  showSignIn: PropTypes.bool,
};

SmartStepActionButtons.defaultProps = {
  showSignIn: true,
};
