import { ButtonGroup, ButtonGroupCircleX, ButtonOptionSecondary } from '@foyyay/flow-elements';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { getParentWindow } from '@shared/lib/getParentWindow';
import React, { useCallback, useEffect } from 'react';
import { ContinueButton } from '../../../components/StepActionButtons';
import {
  clearAddress,
  getCityAndState,
  isAddressValid,
  setAddress,
  setCurrentStep,
} from '../actions/stepSmartPhysicalAddressActions';
import { ADDRESS_FIELDS } from '../constants';
import { selectAddress, selectAttribute } from '../reducers/stepSmartPhysicalAddressReducer';
import { HiddenAutofillFields } from './HiddenAutofillFields';

export const StepSmartPhysicalAddressConfirm = (props) => {
  const { dispatch, useSelector, onComplete } = props;
  const attribute = useSelector(selectAttribute);
  const address = useSelector(selectAddress);

  const handleClearAddress = useCallback(() => {
    dispatch(setCurrentStep('search'));
    dispatch(clearAddress());
  }, [dispatch]);

  const handleComplete = () => {
    onComplete({
      attribute: attribute,
      value: address,
    });
  };

  const handleEditAddressField = useCallback((field) => dispatch(setCurrentStep(field)), [dispatch]);

  const handleChangeAddressField = useCallback(
    (value) => {
      dispatch(setAddress(value));
      setCurrentStep('confirm');
    },
    [dispatch]
  );

  const handleSubmit = useSubmitHandler(() => {});

  return (
    <form onSubmit={handleSubmit}>
      <AddressFields
        address={address}
        onComplete={handleComplete}
        onCancel={handleClearAddress}
        onEditAddressField={handleEditAddressField}
        onChangeAddressField={handleChangeAddressField}
      />
      <HiddenAutofillFields address={address} onChange={handleChangeAddressField} />
    </form>
  );
};

const AddressFields = (props) => {
  const { onChangeAddressField } = props;

  const handleCancelClick = useClickHandler(props.onCancel);
  const handleContinueClick = useClickHandler(props.onComplete);

  const isValid = isAddressValid(props.address);

  const setCityAndState = useCallback(
    async (address) => {
      const window = getParentWindow();
      const updates = await getCityAndState(address, window);
      onChangeAddressField(updates);
    },
    [onChangeAddressField]
  );

  // Use postal code to set city and state if either are missing
  useEffect(() => {
    if (props.address.postal_code && (!props.address.city || !props.address.state)) {
      setCityAndState(props.address);
    }
    // Fixme: There are issues here with running efects
  }, [props.address, setCityAndState]);

  // Set default country if missing
  useEffect(() => {
    if (!props.address.country) {
      onChangeAddressField({
        country: 'United States',
      });
    }
    // Fixme: There are issues here with running efects
  }, [props.address, onChangeAddressField]);

  return (
    <>
      <AddressFieldButtons address={props.address} onEditAddressField={props.onEditAddressField} />
      <ButtonGroup style={{ marginTop: '3rem' }}>
        <ButtonGroupCircleX onClick={handleCancelClick} />
        <ContinueButton disabled={!isValid} onClick={handleContinueClick} />
      </ButtonGroup>
    </>
  );
};

const ORDERED_ADDRESS_FIELDS = ['country', 'street_address', 'street_address_2', 'postal_code', 'city', 'state'];

const AddressFieldButtons = (props) => {
  return ORDERED_ADDRESS_FIELDS.map((key) => ADDRESS_FIELDS[key]).map((field) => {
    return (
      <ButtonOptionSecondary
        key={field.id}
        style={{ textAlign: 'left' }}
        badge={props.address[field.id] && field.label}
        onClick={() => props.onEditAddressField(field.id)}
      >
        {props.address[field.id] || <span style={{ opacity: 0.6 }}>+ Add {field.label}</span>}
      </ButtonOptionSecondary>
    );
  });
};
