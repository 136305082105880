import { getParentWindow } from '@shared/lib/getParentWindow';
import { useCallback, useLayoutEffect, useState } from 'react';

interface Dimensions {
  width?: number;
  height?: number;
}

export function useDimensions(): [React.RefCallback<HTMLElement | null>, Dimensions] {
  const window = getParentWindow();

  const [node, setState] = useState<HTMLElement | null>(null);

  const refCallback = useCallback((node: HTMLElement) => setState(node), []);

  const [dimensions, setDimensions] = useState<Dimensions>({});

  useLayoutEffect(() => {
    if (isHtmlElement(node)) {
      const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));

      measure();

      window.document.fonts.ready.then(() => measure());

      window.addEventListener('resize', measure);
      return () => window.removeEventListener('resize', measure);
    }
  }, [node, window]);

  return [refCallback, dimensions];
}

const isHtmlElement = (node: HTMLElement | null): node is HTMLElement => node !== null;

function getDimensionObject(node: HTMLElement) {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
  };
}

export function isOverflowingWidth(element: HTMLElement): boolean {
  return element?.scrollWidth > element?.offsetWidth;
}

export function isOverflowingHeight(element: HTMLElement): boolean {
  return element?.scrollHeight > element?.offsetHeight;
}
