import { ButtonOptionSecondary, ButtonPrimary, LabelStep, StepAlert } from '@foyyay/flow-elements';
import { createAlertError } from '@shared/actions/alert';
import { PlaidLink } from '@shared/components/PlaidLink';
import { SOURCE_TYPE_ACH_DEBIT } from '@shared/constants';
import { PLAID_CLIENT_FRAGMENT } from '@shared/constants/plaid';
import { TOKEN_CREATION_KEY_PLAID } from '@shared/constants/rebelpay';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import * as RebelPayApi from '@shared/lib/rebelpay';
import { selectMerchantByFlowId } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlowContext } from '..';
import { FlowStep } from '../components/FlowStep';
import { ModalPromptChallenge } from '../components/ModalPromptChallenge';

export const StepPaymentSourceNewAchPlaid = (props) => {
  const defaultValue = {};
  const initialValue = props.data?.value?.[SOURCE_TYPE_ACH_DEBIT] ?? defaultValue;

  const message = `ending in ${initialValue.account_last_few}`;

  return (
    <FlowStep label={''} message={message} header={<StepError error={props.error} />}>
      <StepPaymentSourcePlaidEntry
        config={props.config}
        onComplete={props.completeStep}
        onIncomplete={props.incompleteStep}
        onSave={props.saveStep}
        onBankUseManualEntryClick={props.onBankUseManualEntryClick}
        value={initialValue}
        data={props.data}
      />
    </FlowStep>
  );
};

const StepPaymentSourcePlaidEntry = (props) => {
  if (props.value.id === undefined) {
    return <PlaidConnect {...props} />;
  }

  return <DisplayPlaidConnection {...props} />;
};

const DisplayPlaidConnection = (props) => {
  const handleContinueClick = (e) => {
    e.stopPropagation();
    props.onComplete({
      value: {
        ...props.data?.value,
        [SOURCE_TYPE_ACH_DEBIT]: props.value,
      },
    });
  };

  const handlePlaidDisconnectClick = useClickHandler(() => {
    props.onIncomplete();
    props.onSave({
      value: {
        ...props.data?.value,
        [SOURCE_TYPE_ACH_DEBIT]: undefined,
      },
    });
  }, [props]);

  return (
    <>
      <PlaidLink
        key="linked"
        linked={true}
        message={`${props.value.account_name} ending in *${props.value.account_last_few}`}
        onDisconnect={handlePlaidDisconnectClick}
      />
      <ButtonPrimary onClick={handleContinueClick} processing={false}>
        Continue
      </ButtonPrimary>
    </>
  );
};

const PlaidConnect = (props) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [plaidData, setPlaidData] = useState({});
  const [challenge, setChallenge] = useState({});

  const { currentFlowId } = useContext(FlowContext);
  const merchant = useSelector((state) => selectMerchantByFlowId(state, currentFlowId));

  const onSave = props.onSave;
  const existingValue = props.data?.value;

  const doSubmit = useCallback(
    async (plaidData, challenge) => {
      if (submitting === true) {
        return;
      }
      setSubmitting(true);

      // Try/catch?
      let result = getPaymentToken(merchant.rebelpay_merchant_id, plaidData, challenge);
      try {
        result = await result;
      } catch (error) {
        dispatch(createAlertError(error.message, true));
        return;
      }

      setChallenge(result.challenge);
      onSave({
        value: {
          ...existingValue,
          [SOURCE_TYPE_ACH_DEBIT]: result.source,
        },
      });

      setSubmitting(false);
    },
    [dispatch, existingValue, merchant.rebelpay_merchant_id, onSave, submitting]
  );

  const handlePlaidOnSuccess = useCallback(
    (token, metadata) => {
      if (metadata.accounts.length < 1) {
        console.error('Plaid did not give us any accounts');
        return;
      }
      if (metadata.accounts.length > 1) {
        console.warn('Got extra accounts, taking the first one.');
      }

      const plaidData = { token: token, metadata: metadata };
      setPlaidData(plaidData);
      doSubmit(plaidData, {});
    },
    [doSubmit]
  );

  const handleChallengeSubmit = useCallback(
    (attempt) => {
      const nextChallenge = {
        ...challenge,
        ...attempt.challenge,
      };
      setChallenge(nextChallenge);
      doSubmit(plaidData, nextChallenge);
    },
    [challenge, doSubmit, plaidData]
  );

  return (
    <>
      <LabelStep>Ok, Let's Link Your Account</LabelStep>
      <PlaidLink key="connect" linked={false} onSuccess={handlePlaidOnSuccess} processing={submitting} />
      <ModalPromptChallenge
        challenge={challenge}
        challengeRetryFunction={handleChallengeSubmit}
        processing={submitting}
      />
      <ButtonOptionSecondary onClick={props.onBankUseManualEntryClick} style={{ marginTop: '1.6rem' }}>
        Or, enter manually
      </ButtonOptionSecondary>
    </>
  );
};

const getPaymentToken = async (rpMerchantId, plaidData, challenge) => {
  // Plaid returns the selected accounts. We have Link configured for selecting only one.
  const account = plaidData.metadata.accounts[0];

  const tokenOptions = {
    [TOKEN_CREATION_KEY_PLAID]: {
      client_id_fragment: PLAID_CLIENT_FRAGMENT,
      public_token: plaidData.token,
      account_id: account.id,
    },
  };

  if (challenge.id !== undefined && challenge.answer !== undefined) {
    tokenOptions.challenge = {
      id: challenge.id,
      answer: challenge.answer,
    };
  }

  let token = RebelPayApi.getToken(rpMerchantId, tokenOptions);

  try {
    token = await token;
  } catch (error) {
    if (error?.body?.challenge !== undefined) {
      return { challenge: error.body };
    }
    throw error;
  }

  const newPaymentSource = {
    id: token.id,
    type: SOURCE_TYPE_ACH_DEBIT,
    account_last_few: account.mask,
    account_name: account.name,
    institution_name: plaidData.metadata.institution.name,
    expires: token.expires,
    isPlaid: true,
  };

  return {
    challenge: {},
    source: newPaymentSource,
  };
};

const StepError = (props) => {
  if (props.error === undefined) {
    return null;
  }

  return (
    <StepAlert label={props.error.label} variant="error">
      {props.error.message}
    </StepAlert>
  );
};
