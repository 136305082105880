import { createTheme, Theme } from '@foyyay/flow-elements';
import { createPalette } from '@nucleus/launcher';
import { getParentWindow } from '@shared/lib/getParentWindow';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { COLOR_MODE_AUTO, COLOR_MODE_DARK, COLOR_MODE_LIGHT, DEFAULT_THEME_COLOR } from '../constants/color';

export const ThemeContext = (props) => {
  const parentTheme = useContext(Theme);
  const colorMode = usePreferredColorMode(props.mode, props.overrideParentTheme, parentTheme);

  const theme = useMemo(() => {
    if (props.overrideParentTheme !== true && parentTheme !== undefined) {
      // no need to recalculate theme if it's already done and not overridden
      return;
    }

    const palette = createPalette(props.color || DEFAULT_THEME_COLOR, props.paletteVibrancy);

    return createTheme(palette, colorMode);
  }, [props.color, props.paletteVibrancy, props.overrideParentTheme, parentTheme, colorMode]);

  if (props.overrideParentTheme !== true && parentTheme !== undefined) {
    return props.children;
  }

  return <Theme.Provider value={theme}>{props.children}</Theme.Provider>;
};

ThemeContext.propTypes = {
  overrideParentTheme: PropTypes.bool,
  color: PropTypes.string,
  mode: PropTypes.string,
};

export function getActiveColorMode(mode = COLOR_MODE_AUTO, prefersDarkMode) {
  if (mode === COLOR_MODE_AUTO) {
    return prefersDarkMode ? COLOR_MODE_DARK : COLOR_MODE_LIGHT;
  }

  return mode;
}

export function usePreferredColorMode(mode, parentTheme, overrideParentTheme) {
  const window = getParentWindow();
  const [prefersDarkMode, setPrefersDarkMode] = useState();
  useEffect(() => {
    if (mode !== COLOR_MODE_AUTO || (parentTheme !== undefined && overrideParentTheme !== true)) {
      // Don't add event listeners if not auto or if theme is already defined and not overridden
      return;
    }

    const matchMediaDarkMode = window?.matchMedia('(prefers-color-scheme: dark)');

    if (matchMediaDarkMode === undefined) {
      // Don't add event listeners if not undefined
      return;
    }

    function handleChange(e) {
      setPrefersDarkMode(e.matches);
    }

    handleChange(matchMediaDarkMode);

    if (typeof matchMediaDarkMode.addEventListener === 'function') {
      matchMediaDarkMode.addEventListener('change', handleChange);
      return () => matchMediaDarkMode.removeEventListener('change', handleChange);
    }

    // Fallback Use deprecated addListener to support Safari >14
    matchMediaDarkMode.addListener(handleChange);
    return () => matchMediaDarkMode.removeListener(handleChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, parentTheme, overrideParentTheme]);

  return getActiveColorMode(mode, prefersDarkMode);
}
