import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { ButtonPrimary } from './ButtonPrimary';

const StyledProgressButton = styled(ButtonPrimary)`
  margin: 0;
  opacity: 1;
  position: absolute;
  max-width: 100%;
  z-index: 1;

  span {
    text-overflow: clip;
  }
`;

const AnimatedProgressButton = animated(StyledProgressButton);

const AnimatedBackground = styled(animated.div)`
  background: ${({ theme }) => theme.Progress.Color.Default.Background};
  border-radius: 1rem;
  height: 2rem;
  position: absolute;
  width: 100%;
`;

const Wrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0 0;
  position: relative;
`;

export const ProgressButton = (props) => {
  const { children, disabled, max, onClick, value, ...otherProps } = props;
  const completed = props.value && props.max && props.value >= props.max;
  const progress = (props.value / props.max) * 100;

  const chain = [
    { width: progress },
    { height: completed ? 6 : 2, backgroundOpacity: completed ? 0 : 1 },
    { opacity: completed ? 1 : 0 },
  ];

  const { backgroundOpacity, height, opacity, width } = useSpring({
    from: Object.assign({}, ...chain),
    to: completed ? chain : chain.reverse(),
    config: { tension: 150, friction: 14, clamp: true },
  });

  return (
    <Wrapper className={props.className} style={{ height: height.interpolate((h) => `${h}rem`) }}>
      <AnimatedBackground style={{ opacity: backgroundOpacity }} />
      <AnimatedProgressButton
        disabled={props.disabled || !completed}
        onClick={props.onClick}
        style={{ height: height.interpolate((h) => `${h}rem`), width: width.interpolate((w) => `${w}%`) }}
        {...otherProps}
      >
        <animated.span style={{ opacity: opacity }}>{props.children}</animated.span>
      </AnimatedProgressButton>
    </Wrapper>
  );
};

ProgressButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  onClick: PropTypes.func,
  value: PropTypes.number,
};
