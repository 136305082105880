import { CircleIconAngle, CircleIconContainer, getThemeValue, media } from '@foyyay/flow-elements';
import { Button } from '@shared/components/Button';
import { nextPrayerSelector, previousPrayerSelector } from '@shared/reducers/prayerhub';
import { partial as _partial } from 'lodash';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PrayerDetailContext } from '../context/PrayerDetailContext';
import { PrayerHubContext } from '../context/PrayerHubContext';

const getPrevNextButtonsThemeValue = _partial(getThemeValue, 'PrevNextButtons.Color.Default');

export const PrevNextPrayerButtons = () => {
  const { currentPrayerHubId, currentPrayerWallId } = useContext(PrayerHubContext);
  const { goToPrayer, prayer } = useContext(PrayerDetailContext);
  const prevPrayerId = useSelector(previousPrayerSelector(currentPrayerHubId, currentPrayerWallId, prayer.id));
  const nextPrayerId = useSelector(nextPrayerSelector(currentPrayerHubId, currentPrayerWallId, prayer.id));
  const prevButtonOnClick = prevPrayerId ? () => goToPrayer(prevPrayerId) : undefined;
  const nextButtonOnClick = nextPrayerId ? () => goToPrayer(nextPrayerId) : undefined;

  return (
    <PrevNextButtonContainer>
      <PrevButton onClick={prevButtonOnClick} />
      <NextButton onClick={nextButtonOnClick} />
    </PrevNextButtonContainer>
  );
};

const ArrowButton = styled(Button)`
  width: 3.5rem;
  height: 3.5rem;

  ${media.tabletLandscapeAndUp`
    width: 4rem;
    height: 4rem;
  `}

  ${CircleIconContainer} {
    &:before {
      display: none;
    }
  }
`;

export const PrevButton = (props) => {
  const disabled = props.onClick === undefined && props.to === undefined;

  return <ArrowButton {...props} disabled={disabled} />;
};

PrevButton.defaultProps = {
  size: 'large',
  shape: 'circle',
  variant: 'primary-reverse',
  icon: <CircleIconAngle direction="left" width="100%" />,
};

export const NextButton = (props) => {
  const disabled = props.onClick === undefined && props.to === undefined;

  return <ArrowButton {...props} disabled={disabled} />;
};

NextButton.defaultProps = {
  size: 'large',
  shape: 'circle',
  variant: 'primary-reverse',
  icon: <CircleIconAngle direction="right" width="100%" />,
};

const PrevNextButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${getPrevNextButtonsThemeValue('Track')};
  border-radius: 2.5rem;
  height: 4.5rem;
  width: 10rem;
  padding: 0 0.5rem;

  ${media.tabletLandscapeAndUp`
    height: 5rem;
    width: 11rem;
  `}
`;
