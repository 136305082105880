import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { font, media } from './style-utils';
import { Styles } from './Typography';

const InputBankAccountNumber = styled(Input)`
  ${font(36, 'Bold', -0.75)}

  ${media.tabletLandscapeAndUp`
    font-size: 4.8rem;
  `};
  color: ${({ theme }) => theme.InputCard.Color.Default};
`;

const InputBankRoutingNumber = styled(Input)`
  ${Styles.F3};
  color: ${({ theme }) => theme.InputCard.Color.Default};
  width: 5.5em;
`;

const InputBankAccountHolderName = styled(Input)`
  ${Styles.F3};
  color: ${({ theme }) => theme.InputCard.Color.Default};
  width: 9em;
  padding-left: 0.5em;

  ${media.tabletPortraitAndUp`
    padding-left: 1em;
  `}
`;

const F2Container = styled.div`
  align-items: center;
  display: flex;
  height: 3.6rem;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    height: 4.6rem;
  `}
`;

const F3Container = styled.div`
  align-items: center;
  display: flex;
  height: 2.4rem;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    height: 3rem;
  `}
`;

const BankAccountNumber = (props) => {
  const { onCreditCardTypeChanged, ...otherProps } = props;
  return (
    <F2Container>
      <InputBankAccountNumber
        type="text"
        inputMode="numeric"
        name="bank_account_number"
        placeholder="Type account here"
        autoCorrect="off"
        spellCheck="false"
        {...otherProps}
      />
    </F2Container>
  );
};

const BankRoutingNumber = (props) => {
  return (
    <F3Container>
      <InputBankRoutingNumber
        type="text"
        inputMode="numeric"
        name="bank_routing_number"
        placeholder="Routing Number"
        autoCorrect="off"
        spellCheck="false"
        {...props}
      />
    </F3Container>
  );
};

const BankAccountHolderName = (props) => {
  return (
    <F3Container>
      <InputBankAccountHolderName
        type="text"
        name="name"
        placeholder="Name"
        autoComplete="name"
        autoCorrect="off"
        spellCheck="false"
        size="14"
        {...props}
      />
    </F3Container>
  );
};

export class BankAccountDetails extends Component {
  static propTypes = {
    bank_account_number: PropTypes.string,
    bank_routing_number: PropTypes.string,
    bank_account_holder_name: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    bank_account_number: '',
    bank_routing_number: '',
    bank_account_holder_name: '',
    onChange: () => {},
  };

  render() {
    return (
      <GridContainer>
        <BankAccountNumber value={this.props.bank_account_number} readOnly />
        <FlexContainer>
          <BankRoutingNumber value={this.props.bank_routing_number} readOnly />
          <BankAccountHolderName value={this.props.bank_account_holder_name} readOnly />
        </FlexContainer>
      </GridContainer>
    );
  }
}

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 2rem;
  margin-bottom: 3.5rem;

  ${media.tabletLandscapeAndUp`
    grid-row-gap: 1.6rem;
    margin-bottom: 4.9rem;
  `}

  ${F2Container} {
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;
