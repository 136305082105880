import { InputTextSmall } from '@foyyay/flow-elements';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useThunkReducer } from '@shared/hooks/useThunkReducer';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { withPreventDefault, withStopPropagation } from '@shared/lib/events';
import { selectNameAttribute } from '@shared/reducers/user';
import React from 'react';
import { useSelector as useReduxSelector } from 'react-redux';
import styled from 'styled-components';
import { FlowStep } from '../../components/FlowStep';
import { SmartStepActionButtons } from '../../components/StepActionButtons';
import { setFirstName, setLastName, setMiddleName } from './actions/stepSmartNameActions';
import { init, selectMessage, stepSmartNameReducer } from './reducers/stepSmartNameReducer';

const CONFIG_NAME_PARTS_FALLBACK = deepFreeze({
  firstName: {
    active: true,
    required: true,
  },
  lastName: {
    active: true,
    required: true,
  },
  middleName: {
    active: false,
    required: true,
  },
});

export const StepSmartName = (props) => {
  const nameAttribute = useReduxSelector(selectNameAttribute);
  const initializerArg = {
    data: props.data,
    nameAttribute: nameAttribute,
  };
  const [state, dispatch, useSelector] = useThunkReducer(stepSmartNameReducer, {
    devToolsName: props.devToolsName,
    initializerArg: initializerArg,
    initializer: init,
  });

  const message = selectMessage(props.config, props.data);

  return (
    <FlowStep message={message}>
      <NameInput
        state={state}
        dispatch={dispatch}
        useSelector={useSelector}
        required={props.config.required}
        onComplete={props.completeStep}
        parts={props.config?.parts ?? CONFIG_NAME_PARTS_FALLBACK}
      />
    </FlowStep>
  );
};

const NameInput = (props) => {
  const { state, dispatch, onComplete } = props;

  const inputRef = useInputFocusOnceRef();

  const handleFirstNameChange = (value) => dispatch(setFirstName(value));
  const handleLastNameChange = (value) => dispatch(setLastName(value));
  const handleMiddleNameChange = (value) => dispatch(setMiddleName(value));

  const readyToSubmit = Object.keys(props.parts)
    .filter((key) => props.parts[key] === true || (props.parts[key].active && props.parts[key].required))
    .every((key) => state[key]?.length > 0);

  const submitName = () => {
    if (readyToSubmit !== true) {
      return;
    }
    onComplete({
      value: {
        firstName: state.firstName.trim(),
        lastName: state.lastName.trim(),
        middleName: state.middleName.trim(),
      },
    });
  };

  const handleSubmit = withPreventDefault(submitName);
  const handleEnter = withStopPropagation(submitName);
  const handleContinueClick = withStopPropagation(submitName);

  const showMiddleName = props.parts?.middleName?.active === true;

  return (
    <>
      <form style={{ marginBottom: '3.6rem' }} onSubmit={handleSubmit}>
        <Columns>
          <InputTextSmall
            name="fName"
            autoComplete="given-name"
            placeholder="First Name"
            value={state.firstName}
            onChange={handleFirstNameChange}
            onEnter={handleEnter}
            ref={inputRef}
          />
          <InputTextSmall
            name="lName"
            autoComplete="family-name"
            placeholder="Last Name"
            value={state.lastName}
            onChange={handleLastNameChange}
            onEnter={handleEnter}
          />
        </Columns>
        {showMiddleName && (
          <Columns>
            <InputTextSmall
              name="mName"
              autoComplete="additional-name"
              placeholder="Middle name"
              value={state.middleName}
              onChange={handleMiddleNameChange}
              onEnter={handleEnter}
              style={{ marginTop: '1rem' }}
            />
          </Columns>
        )}
      </form>
      <SmartStepActionButtons onContinueClick={handleContinueClick} readyToContinue={readyToSubmit} />
    </>
  );
};

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
`;
