import { useEffect, useState } from 'react';

export const useDelayedBool = (bool, delay = 500) => {
  const [state, setState] = useState(bool);

  useEffect(() => {
    if (bool === false) {
      setState(false);
      return;
    }

    const timer = setTimeout(() => setState(bool), delay);
    return () => clearTimeout(timer);
  }, [bool, delay]);

  return state;
};
