import { LabelStep } from '@foyyay/flow-elements';
import { Summary } from '@shared/components/Summary';
import { STEP_TYPE } from '@shared/constants';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useCurrencyFormatter } from '@shared/hooks/useCurrencyFormatter';
import { useScrollToRef } from '@shared/hooks/useScrollToStepRef';
import * as RebelPayApi from '@shared/lib/rebelpay';
import { selectMerchantByFlowId, selectStepByFlowIdAndStepType } from '@shared/reducers/flows/flowsByIdReducer';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FlowContext } from '..';
import { FlowStepContext } from '../components/FlowStep';
import { ModalDialogGivingFees } from '../components/ModalDialogGivingFees';

export const StepSummary = (props) => {
  const { expanded } = useContext(FlowStepContext);
  const ref = useScrollToRef(expanded);
  const convenienceFee = props.data?.value;
  const selectedAmount = props.sharedData?.amount;
  const selectedSourceType = props.sharedData?.paymentType?.id;
  const transactionError = props.sharedData?.transactionError;
  const isTransactionProcessing = props.sharedData?.isTransactionProcessing;
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  const { currentFlowId } = useContext(FlowContext);
  const merchant = useSelector((state) => selectMerchantByFlowId(state, currentFlowId));

  let value = convenienceFee;
  if (value?.amount !== selectedAmount || value?.source_type !== selectedSourceType) {
    value = undefined;
  }

  const formatCurrency = useCurrencyFormatter();

  useEffect(() => {
    async function getFee(amount, sourceType) {
      if (expanded === true) {
        const newFee = await RebelPayApi.getFee(merchant.rebelpay_merchant_id, sourceType, amount);
        props.completeStep({ value: newFee });
      }
    }
    getFee(selectedAmount, selectedSourceType);
    // This step will only be expanded when we need to get the fee
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const handleLearnMoreClick = useClickHandler(() => {
    if (isTransactionProcessing) {
      return;
    }
    //TODO: show learn more overlay
    setShowLearnMoreModal(true);
  });

  const paymentTypeStepId = useSelector((state) =>
    selectStepByFlowIdAndStepType(state, currentFlowId, STEP_TYPE.PAYMENT_TYPE)
  )?.id;

  const goToPaymentTypeStep = () => {
    setShowLearnMoreModal(false);
    props.goToStep(paymentTypeStepId);
  };

  const calculateFee = () => {
    return value.processorFee + value.processorExtra;
  };

  const calculateTotal = () => {
    const amount = selectedAmount;
    return amount + calculateFee();
  };

  var total = 0;
  var fee = 0;

  if (value !== undefined) {
    total = formatCurrency(calculateTotal());
    fee = formatCurrency(calculateFee());
  }

  if (!expanded && !props.allStepsCompleted) {
    return null;
  }

  return (
    <>
      <Summary
        total={total}
        fee={fee}
        onLearnMoreClick={handleLearnMoreClick}
        hasLoadedFee={value !== undefined}
        ref={ref}
      >
        {transactionError && <ErrorMessage error={transactionError} />}
      </Summary>
      <ModalDialogGivingFees
        show={showLearnMoreModal}
        onClose={() => setShowLearnMoreModal(false)}
        onUseBankAccount={goToPaymentTypeStep}
      />
    </>
  );
};

StepSummary.propTypes = {
  data: PropTypes.object,
  sharedData: PropTypes.object,
};

const ErrorMessage = styled(LabelStep)`
  > div {
    padding: 0 !important;
    margin-top: 2em;
    > span {
      letter-spacing: 0 !important;
      text-transform: none !important;
    }
  }
`;
