import { useEffect, useState } from 'react';

export default function useWindowSize(currentWindow) {
  if (currentWindow === undefined) {
    currentWindow = window;
  }

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: currentWindow.innerWidth,
        height: currentWindow.innerHeight,
      });
    }

    // Add event listener
    currentWindow.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => currentWindow.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
