import { partial as _partial } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { Info } from './Icons';
import { media } from './style-utils';
import { getThemeValue } from './Theme';

const getScopedThemeValue = _partial(getThemeValue, 'ButtonOptionCounter.Color');

const Icon = styled(Info)`
  box-shadow: 0 0 0 2px inset #ffffff;
  border-radius: 1.6rem;
`;

const CircleButton = styled.button`
  background: none;
  border-style: solid;
  border-color: transparent;
  border-top-width: 1rem;
  border-left-width: 1.6rem;
  border-right-width: 1.6rem;
  border-bottom-width: 1rem;
  box-sizing: content-box;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  user-select: none;
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ::before {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 99rem;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (hover: hover) {
    :hover ::before {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  /* Fixme: IOS mobile safari active state */
  :not([disabled]):active {
    transform: scale(0.94);
  }

  :disabled ::before {
    opacity: 0.4;
  }

  width: 3rem;
  height: 3rem;
  min-width: 3rem;

  ${Icon}, svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  ${media.tabletLandscapeAndUp`
    width: 3rem;
    height: 3rem;
  `}
`;

const ButtonCircleInfo = (props) => {
  return (
    <CircleButton {...props}>
      <Icon />
    </CircleButton>
  );
};

const RightButton = styled(ButtonCircleInfo)`
  position: absolute;
  top: -0.6rem;
  right: -1.2rem;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Container = styled.div`
  border-radius: 99rem;
  box-shadow: 0 0.2rem 1.4rem ${getScopedThemeValue('Default.Shadow')};
  color: ${getScopedThemeValue('Default.Foreground')};
  height: 3.8rem;
  margin: 0;
  padding: 0;
  position: relative;
  transition: width 200ms cubic-bezier(0.65, 0, 0.35, 1);
  width: 3.8rem;
  touch-action: manipulation;

  > ${Background} {
    background-color: ${getScopedThemeValue('Default.Background')};
    border-radius: 99rem;
  }

  ${RightButton} {
    transition: opacity 100ms cubic-bezier(0.65, 0, 0.35, 1);
    opacity: 1;
  }

  ${(props) => {
    if (props.open) {
      /* Open Animations */
      return css`
        border-radius: 10rem;
        width: 10rem;

        > ${Background} {
          border-radius: 10rem;
        }

        ${RightButton} {
          transition: opacity 200ms cubic-bezier(0.65, 0, 0.35, 1);
          opacity: 1;
        }
      `;
    }
  }}
`;

export const ButtonOptionMoreInfo = (props) => {
  return (
    <Container {...props}>
      <Background />
      <RightButton />
    </Container>
  );
};
