import { media, Share, SIZE, XRounded } from '@foyyay/flow-elements';
import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${SIZE[2]};
  margin-bottom: -${SIZE[2]};

  & > * {
    flex: 1 0 auto;
    margin-left: ${SIZE[2]};
    margin-bottom: ${SIZE[2]};
    max-width: 100%;

    &:first-child {
      flex: 1 0 60%;
    }
  }
`;

export const ButtonIconXRounded = (props) => {
  return <Button {...props} shape="circle" icon={<XRounded />} />;
};

const ArrowButton = styled(Button)`
  width: 3.5rem;
  height: 3.5rem;

  ${media.tabletLandscapeAndUp`
    width: 4rem;
    height: 4rem;
  `}
`;

export const ButtonShare = (props) => {
  return <ArrowButton {...props} />;
};

ButtonShare.defaultProps = {
  size: 'large',
  shape: 'circle',
  variant: 'primary-reverse',
  icon: <Share />,
};
