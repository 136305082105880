import { BodyStyle } from '@foyyay/flow-elements';
import { Header } from '@shared/components/Header';
import { Wrapper } from '@shared/components/Layout';
import { LoadingPage } from '@shared/components/LoadingPage';
import { Manifest } from '@shared/components/Manifest';
import { ThemeContext } from '@shared/components/ThemeContext';
import { ERROR_THEME } from '@shared/constants/color';
import { STATE_ERROR, STATE_LOADING } from '@shared/constants/state';
import { ScrollContext } from '@shared/context/ScrollContext';
import { useFlowConfig } from '@shared/hooks/useFlowConfig';
import { useScrollTop } from '@shared/hooks/useScrollTop';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { MemoryRouter, useLocation } from 'react-router-dom';
import { Routes } from './Routes';
import { useDispatch, useSelector } from 'react-redux';
import { selectSubmissionByFlowId } from '@shared/reducers/flows/flowsByIdReducer';
import { useEventListener } from '@shared/hooks/useEventListener';
import { claimSubmission } from '@shared/actions/flows';
import { getParentWindow } from '@shared/lib/getParentWindow';

export const FlowContext = React.createContext();

export const Flow = (props) => {
  const [config, state, reloadFlow] = useFlowConfig(props.shortcode, props.revision, props.linkParams);
  const location = useLocation();

  const context = {
    ...props.context,
    currentFlowId: config?.id,
    flow: {
      shortcode: props.shortcode,
    },
    linkParams: props.linkParams,
    reloadFlow: reloadFlow,
  };

  const [scrollTop, scrollTargetProps, resetScrollTop] = useScrollTop();

  const scrollContext = {
    scrollTop: scrollTop,
    scrollTargetProps: scrollTargetProps,
    resetScrollTop: resetScrollTop,
  };

  let theme = {
    color: config?.color_flow,
    mode: config?.color_mode,
    paletteVibrancy: config?.color_flow_palette_vibrancy,
  };

  if (state === STATE_ERROR) {
    theme = ERROR_THEME;
  }

  if (state === STATE_LOADING) {
    return <LoadingPage />;
  }

  if (config === undefined) {
    return null;
  }

  const title = config.show_title === true ? config.title : undefined;

  return (
    <FlowContext.Provider value={context}>
      <ScrollContext.Provider value={scrollContext}>
        <ThemeContext {...theme}>
          <HACK_CLAIM_SUBMISSION>
            {props.useManifest && <Manifest config={config} location={location} />}
            <MemoryRouter>
              <BodyStyle />
              <Wrapper>
                <Header title={title} />
                <Routes />
              </Wrapper>
            </MemoryRouter>
          </HACK_CLAIM_SUBMISSION>
        </ThemeContext>
      </ScrollContext.Provider>
    </FlowContext.Provider>
  );
};

Flow.propTypes = {
  context: PropTypes.any,
  linkParams: PropTypes.object,
  revision: PropTypes.string,
  shortcode: PropTypes.string,
};

/**
 * This is a hack to claim submissions when the user signs-in
 * This hack can be removed, when routing in the launcher has been refactored and fixed
 */
const HACK_CLAIM_SUBMISSION = (props) => {
  const { currentFlowId } = useContext(FlowContext);

  const dispatch = useDispatch();
  const submission = useSelector((state) => selectSubmissionByFlowId(state, currentFlowId));

  useEventListener(
    'NucleusUserSignedIn',
    useCallback(() => {
      if (submission === undefined) {
        return;
      }
      dispatch(claimSubmission(submission));
    }, [dispatch, submission]),
    getParentWindow()
  );

  return <>{props.children}</>;
};
