import styled from 'styled-components';
import { media } from './style-utils';
import { AnimatedSpinner } from './Icons';

export const ButtonIconCircle = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0;
  padding: 0;

  ${media.tabletLandscapeAndUp`
    width: 4.5rem;
    height: 4.5rem;
  `}

  svg {
    width: 100%;

    ${media.tabletLandscapeAndUp`
      width: 100%;
    `}
  }

  color: currentColor;
  background: none;
  border-radius: 99rem;
  border: none;
  outline: none;

  user-select: none;
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  cursor: pointer;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: currentColor;
    border-radius: 99rem;
    opacity: 0.2;
  }

  @media (hover: hover) {
    :hover {
      ::before {
        background-color: currentColor;
        opacity: 0.25;
      }
    }
  }

  /* Fixme: IOS mobile safari active state */
  :not([disabled]):active {
    transform: scale(0.94);
    ::before {
      opacity: 0.3;
    }
  }

  ${AnimatedSpinner} {
    width: 2.4rem;
    height: 2.4rem;

    ${media.tabletLandscapeAndUp`
      width: 3.2rem;
      height: 3.2rem;
    `}
  }
`;
