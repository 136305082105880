import { createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react-redux';
import { LauncherItem } from 'types/launcher';

export const addToRecentItems = createAction('springboard/recent/add', (item: LauncherItem) => ({
  payload: {
    item: item,
  },
}));

export const clearRecentItems = createAction('springboard/recent/clear');

export const clickItem = (item: LauncherItem, itemClickHandlerByTypeMap: any) => (dispatch: Dispatch): void => {
  dispatch(addToRecentItems(item));

  const itemClickHandler = itemClickHandlerByTypeMap[item.type];

  if (typeof itemClickHandler === 'function') {
    itemClickHandler(item);
  }
};
