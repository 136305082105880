import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { font, media } from './style-utils';

const InputF3 = styled(Input)`
  ${font(24, 'Bold', -0.5, 24)}
  color: ${({ isValid, theme }) => (isValid ? theme.Input.Color.Default.Foreground : theme.Input.Color.Error)};
  margin-right: 2.6rem;

  ${media.tabletLandscapeAndUp`
    ${font(30, 'Bold', -0.5, 30)};
    margin-right: 3rem;
  `}
  ::placeholder {
    color: ${({ theme }) => theme.Input.Color.Placeholder};
  }
`;

export class StepInput extends Component {
  static propTypes = {
    error: PropTypes.any,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    value: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    // this.focus();
  }

  focus = () => {
    this.inputRef.current.focus();
  };

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onEnter(e);
    }
  };

  render() {
    const { onChange, onEnter, value, error, ...otherProps } = this.props;
    const isValid = !error;

    return (
      <InputF3
        isValid={isValid}
        value={value}
        onChange={this.handleChange}
        onKeyPress={this.handleKeyPress}
        ref={this.inputRef}
        {...otherProps}
      />
    );
  }
}
