import { StepAlert } from '@foyyay/flow-elements';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { selectSelectedQuantity } from '../reducers/multipleChoiceReducer';
import { StepMultipleChoice } from '../StepMultipleChoice';
import { Actions } from './Actions';
import { AddCustomButton } from './AddCustomButton';
import { OptionsList } from './OptionsList';

export const SelectOptions = (props) => {
  return (
    <>
      <QuantityLimitInfoAlert />
      <div>
        <OptionsList />
        <AddCustomButton />
      </div>
      <Actions style={{ marginTop: '3rem' }} />
    </>
  );
};

const QuantityLimitInfoAlert = (props) => {
  const { config, useSelector } = useContext(StepMultipleChoice.Context);

  const haveStepLimits = config.limitMax !== undefined;
  const selectedQuantity = useSelector(selectSelectedQuantity);
  const message = formatAlertMessage(config.limitTotal, config.limitMaxOriginal, selectedQuantity);

  if (haveStepLimits === false) {
    return null;
  }

  if ((config.limitTotal ?? Infinity) < 1) {
    return (
      <Wrapper>
        <StepAlert variant="info" label="Just a heads up, there are no more items available">
          Please skip this step and continue to the next one
        </StepAlert>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <StepAlert variant="info" label="Just a heads up, quantities below are limited">
        {message}
      </StepAlert>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1.5rem 0 2rem;
`;

export const formatAlertMessage = (limitTotal, limitMax, quantitySelected) => {
  return `${formatRemaining(limitTotal, quantitySelected)} ${formatLimit(limitTotal, limitMax)}`.trim();
};

const formatRemaining = (limitTotal, quantitySelected = 0) => {
  if (limitTotal === undefined) {
    return '';
  }

  const available = limitTotal - quantitySelected;

  if (available < 1) {
    return 'None remaining';
  }

  return `${available} total remaining`;
};

const formatLimit = (limitTotal, limitMax) => {
  if (limitMax !== undefined && limitMax <= (limitTotal ?? Infinity)) {
    return `(You can choose up to ${limitMax})`;
  }

  return '';
};
