import { InputPhone } from '@foyyay/flow-elements';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import useInputFocusRef from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { withStopPropagation } from '@shared/lib/events';
import React from 'react';
import { SmartStepActionButtons } from '../../../components/StepActionButtons';
import { setCurrentStep, setPhone } from '../actions/stepSmartPhoneActions';
import { selectOptions, selectPhone } from '../reducers/stepSmartPhoneReducer';

export const StepSmartPhoneInput = (props) => {
  const { useSelector, dispatch, onComplete } = props;
  const inputRef = useInputFocusRef(props.id, props.currentStep);

  const phone = useSelector(selectPhone);
  const options = useSelector(selectOptions);

  const handleChange = (value) => dispatch(setPhone(value));

  const readyToContinue = phone.length >= 14; // "Fixme: (555) 555-5555" = 14 character string

  const handleComplete = () => {
    if (!readyToContinue) {
      return;
    }
    onComplete({
      value: phone,
    });
  };

  const handleSubmit = useSubmitHandler(handleComplete);
  const handleEnter = useSubmitHandler(handleComplete);
  const handleCancelClick = withStopPropagation(() => dispatch(setCurrentStep('select')));
  const handleSaveClick = useClickHandler(handleComplete);

  const handleSkipClick = useClickHandler(() => {
    onComplete({
      skipped: true,
      value: undefined,
    });
  });

  return (
    <>
      <form style={{ marginBottom: '3.6rem' }} onSubmit={handleSubmit}>
        <InputPhone
          name="phone"
          autoComplete="phone"
          value={phone}
          onChange={handleChange}
          onEnter={handleEnter}
          ref={inputRef}
        />
      </form>
      <SmartStepActionButtons
        onCancelClick={options.length > 0 ? handleCancelClick : undefined}
        onContinueClick={handleSaveClick}
        onSkipClick={handleSkipClick}
        readyToContinue={readyToContinue}
        required={props.required}
      />
    </>
  );
};
