import { getCurrentBaseFontSize, InputCardPlaceholder, LoadingSpinner, Theme } from '@foyyay/flow-elements';
import { SECURE_ELEMENTS_BASE_URL } from '@shared/constants/urls';
import { ControllerContext } from '@shared/context/ControllerContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ModalPromptChallenge } from '../ModalPromptChallenge';
import { SecureFrame } from './SecureFrame';

export const SecureElements = React.forwardRef((props, ref) => {
  const [challenge, setChallenge] = useState();
  const [loaded, setLoaded] = useState(false);

  const theme = useContext(Theme);

  const src = getSecureFrameSrc(props, theme);

  const { viewportSize } = useContext(ControllerContext);
  const currentBaseFontSize = getCurrentBaseFontSize(viewportSize?.width);

  useEffect(() => {
    if (loaded) {
      ref.current.setBaseFontSize(currentBaseFontSize);
    }
  }, [currentBaseFontSize, loaded, ref]);

  return (
    <SecureElementContainer>
      {!loaded && (
        <SecureElementSpinnerPosition>
          <LoadingSpinner />
        </SecureElementSpinnerPosition>
      )}
      <SecureElementPosition>
        <SecureFrame
          src={src}
          name="secureCardFrame"
          title="Secure card payment input frame"
          allowtransparency="true"
          frameborder="0"
          scrolling="no"
          methods={{
            onChallenge: setChallenge,
            onError: props.onError,
            onLoaded: setLoaded,
            onReadyToSubmit: props.onReadyToSubmit,
            onSubmit: props.onSubmit,
          }}
          ref={ref}
        />
        <ModalPromptChallenge
          challenge={challenge}
          challengeRetryFunction={props.onSubmit}
          processing={props.submitting}
        />
      </SecureElementPosition>
      <InputCardPlaceholder />
    </SecureElementContainer>
  );
});

SecureElements.propTypes = {
  accountType: PropTypes.string,
  merchant: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  onReadyToSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['card', 'bank']).isRequired,
};

const getSecureFrameSrc = (props, theme) => {
  const params = {
    element: props.type,
    colormode: theme.mode,
    themecolor: theme.palette.Vibrant1,
    merchantId: props.merchant.rebelpay_merchant_id,
  };

  if (props.type === 'card') {
    params.merchantCountry = props.merchant.country;
  }

  if (props.type === 'bank') {
    params.accountType = props.accountType;
  }

  const queryString = new URLSearchParams(params).toString();

  return encodeURI(`${SECURE_ELEMENTS_BASE_URL}?${queryString}`);
};

const SecureElementContainer = styled.div`
  position: relative;
`;

const SecureElementPosition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const SecureElementSpinnerPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
