import { useThunkReducer } from '@shared/hooks/useThunkReducer';
import React from 'react';
import { FlowStep } from '../../components/FlowStep';
import { StepPhysicalAddressConfirm } from './components/StepPhysicalAddressConfirm';
import { StepPhysicalAddressField } from './components/StepPhysicalAddressField';
import { StepPhysicalAddressSearch } from './components/StepPhysicalAddressSearch';
import {
  init,
  selectCurrentStep,
  selectLabel,
  selectMessage,
  stepPhysicalAddressReducer,
} from './reducers/stepPhysicalAddressReducer';

export const StepPhysicalAddress = (props) => {
  const [state, dispatch, useSelector] = useThunkReducer(stepPhysicalAddressReducer, {
    devToolsName: props.devToolsName,
    initializerArg: props.data,
    initializer: init,
  });

  const currentStep = useSelector(selectCurrentStep);
  const label = useSelector((state) => selectLabel(state, props.config));
  const message = useSelector((state) => selectMessage(state, props.config, props.data));

  return (
    <FlowStep key={currentStep} description={props.config.description} label={label} message={message}>
      {React.createElement(getStepBy(currentStep), {
        state: state,
        dispatch: dispatch,
        useSelector: useSelector,
        required: props.config.required,
        onComplete: props.completeStep,
        goBack: props.goBack,
      })}
    </FlowStep>
  );
};

const stepBy = {
  search: StepPhysicalAddressSearch,
  confirm: StepPhysicalAddressConfirm,
  field: StepPhysicalAddressField,
};

const getStepBy = (currentStep) => stepBy[currentStep] ?? stepBy.field;
