import { FocalPoint } from '@nucleus/types/media/image';

export function forEachObjectKey<T, U>(object: Record<string, T>, func: (value: T) => U): Record<string, U> {
  const newObject: Record<string, U> = {};
  if (typeof object !== 'object' || object === null) {
    return newObject;
  }
  Object.keys(object).forEach((key) => {
    newObject[key] = func(object[key]);
  });
  return newObject;
}

//Copy from webTheme/src/lib/utilities
export function convertFocalPointToBackgroundPositionCss(focalPoint?: FocalPoint): string {
  if (focalPoint === undefined) {
    return 'center';
  }
  let { x, y } = focalPoint;
  // check for undefined values and set fallbacks
  if (x === undefined) {
    x = 0.5;
  }
  if (y === undefined) {
    y = 0.5;
  }
  // if x and y are both between 0 and 1, return the percentages
  if (x >= 0 && y >= 0 && x <= 1 && y <= 1) {
    return `${x * 100}% ${y * 100}%`;
  }
  return 'center';
}
