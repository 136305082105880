import { unwrapResult } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { loadPrayerHub } from '@shared/actions/prayerhub';
import { STATE_ERROR, STATE_LOADED, STATE_LOADING } from '@shared/constants/state';
import { selectPrayerHubConfigByShortcode, selectPrayerHubConfigLoadedAtByShortcode } from '@shared/reducers/prayerhub';
import { Hub } from 'aws-amplify';
import { pick as _pick } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const usePrayerHubConfig = (prayerhubShortcode) => {
  const config = useSelector((state) => selectPrayerHubConfigByShortcode(state, prayerhubShortcode));
  const configLoadedAt = useSelector((state) => selectPrayerHubConfigLoadedAtByShortcode(state, prayerhubShortcode));
  const dispatch = useDispatch();

  const initialState = configLoadedAt < new Date() ? STATE_LOADED : STATE_LOADING;
  const [state, setState] = useState(initialState);

  const loadConfig = useCallback(async () => {
    try {
      await dispatch(
        loadPrayerHub({
          prayerhubShortcode: prayerhubShortcode,
        })
      ).then(unwrapResult);
    } catch (error) {
      setState(STATE_ERROR);
      return;
    }

    setState(STATE_LOADED);
  }, [dispatch, prayerhubShortcode]);

  const registerAuthListeners = useCallback(() => {
    const listener = buildAuthListener(loadConfig);
    Hub.listen('auth', listener);
    return () => Hub.remove('auth', listener);
  }, [loadConfig]);

  useEffect(() => {
    loadConfig();
    return registerAuthListeners();
  }, [loadConfig, registerAuthListeners]);

  const reloadPrayerHub = useCallback(async () => {
    setState(STATE_LOADING);
    await loadConfig();
  }, [loadConfig]);

  useEffect(() => {
    Sentry.setContext('prayerhub', {
      ..._pick(config, ['id', 'prayerflow_shortcode', 'prayerwall_id', 'title']),
      shortcode: prayerhubShortcode,
    });
    return () => Sentry.setContext('prayerhub', null);
  }, [config, prayerhubShortcode]);

  return [config, state, reloadPrayerHub];
};

const buildAuthListener = (loadConfig) => {
  const EVENT_MAP = {
    signIn: () => loadConfig(),
    signOut: () => loadConfig(),
    default: () => {},
  };
  return (data) => (EVENT_MAP[data.payload.event] ?? EVENT_MAP.default)();
};
