import { TIME_PERIODS } from '@shared/constants/datetime';
import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { ButtonOptionAlternate } from './ButtonOptionAlternate';
import { Input } from './Input';
import { media } from './style-utils';
import { Styles } from './Typography';

export class InputTime extends Component {
  static propTypes = {
    value: PropTypes.string,
    period: PropTypes.string,
    onChange: PropTypes.func,
    onPeriodChange: PropTypes.func,
    onEnter: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // This ref is set by Cleave
    this.inputRef = undefined;
  }

  focus = () => {
    this.inputRef.focus();
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.props.onEnter) {
      this.props.onEnter(e);
    }
  };

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  };

  handlePeriodChange = (e, period) => {
    e.preventDefault();
    this.props.onPeriodChange(period);
  };

  render() {
    const { value, period, onChange, onPeriodChange, onEnter, ...otherProps } = this.props;

    return (
      <InputContainer>
        <InputF2
          as={Cleave}
          options={{ time: true, timePattern: ['h', 'm'], timeFormat: '12' }}
          type="text"
          inputMode="decimal"
          autoComplete="off"
          placeholder="HH:MM"
          size="5"
          value={value}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          htmlRef={(ref) => (this.inputRef = ref)}
          {...otherProps}
        />
        <PeriodToggle>
          {TIME_PERIODS.map((timePeriod) => (
            <PeriodOption
              key={timePeriod}
              onClick={(e) => this.handlePeriodChange(e, timePeriod)}
              checked={period === timePeriod}
            >
              {timePeriod}
            </PeriodOption>
          ))}
        </PeriodToggle>
      </InputContainer>
    );
  }
}

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  height: 4.6rem;
  overflow: hidden;
  position: relative;

  ${media.tabletLandscapeAndUp`
    height: 6.4rem;
  `};
`;

const InputF2 = styled(Input)`
  ${Styles.F2};
  color: ${({ theme }) => theme.InputTime.Color.Default};
`;

const PeriodToggle = styled.div`
  display: flex;
`;

const PeriodOption = styled(ButtonOptionAlternate)`
  flex: 0 0 auto;
  width: 10rem;
  margin-left: 0.8rem;
`;
