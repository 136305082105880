import { ButtonOptionSecondary, ButtonPrimary, font, LoadingSpinner } from '@foyyay/flow-elements';
import { computeAndGoToNextStep } from '@shared/actions/flows';
import { initializeUserSession, signOut } from '@shared/actions/user';
import { STEP_TYPE } from '@shared/constants';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { formatPhoneNumber, formatValueWithPrefix } from '@shared/lib/format';
import { buildConfigValidator } from '@shared/lib/schema';
import { selectFlowById } from '@shared/reducers/flows/flowsByIdReducer';
import { selectFullName, selectPrimaryAttributes, selectPrimaryEmailAddress } from '@shared/reducers/user';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import { FlowContext } from '..';
import { FlowStep } from '../components/FlowStep';

export const StepProfile = (props) => {
  const name = useSelector(selectFullName);
  const email = useSelector(selectPrimaryEmailAddress);

  const message = useMemo(() => {
    if (props.data?.useMyProfile === true) {
      return formatValueWithPrefix(props.config.valuePrefixLabel, name ?? email, props.config.valuePrefixSeparator);
    }
    return formatValueWithPrefix(props.config.valuePrefixLabel, 'Review & Choose', props.config.valuePrefixSeparator);
  }, [email, name, props.data, props.config]);

  return (
    <FlowStep message={message}>
      <ProfileOptions steps={props.config.steps} onComplete={props.completeStep} />
    </FlowStep>
  );
};

const configSchema = {
  label: yup.string().min(3).required(),
  description: yup.string(),
  steps: yup
    .array()
    .of(yup.object())
    .test('is-active', 'At least one step is not active', (value, context) =>
      value?.some((step) => step.config?.active)
    )
    .required(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepProfile.isConfigValid = isConfigValid;

const PROFILE_INFO_BY = deepFreeze({
  [STEP_TYPE.SMART_NAME]: (primaryAttributes) => primaryAttributes.name,
  [STEP_TYPE.SMART_EMAIL_ADDRESS]: (primaryAttributes) => primaryAttributes.emailAddress,
  [STEP_TYPE.SMART_PHONE]: (primaryAttributes) => formatPhoneNumber(primaryAttributes.phoneAttribute?.value?.phone),
  [STEP_TYPE.SMART_PHYSICAL_ADDRESS]: (primaryAttributes) =>
    primaryAttributes.physicalAddressAttribute?.value?.street_address,
  [STEP_TYPE.SMART_BIRTHDAY]: (primaryAttributes) => primaryAttributes.birthdayAttribute?.value,
  [STEP_TYPE.SMART_GENDER]: (primaryAttributes) => primaryAttributes.genderAttribute?.value,
});

const ProfileOptions = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeUserSession());
  }, [dispatch]);

  const [loading, setLoading] = useState(false);

  const refreshUserProfile = useCallback(async () => {
    setLoading(true);
    await dispatch(initializeUserSession());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    refreshUserProfile();
  }, [refreshUserProfile]);

  const { onComplete, steps } = props;

  const primaryAttributes = useSelector(selectPrimaryAttributes);

  const handleUseMyProfileClick = useClickHandler(() => {
    onComplete({
      useMyProfile: true,
      value: 'Use My Profile Info',
    });
  }, [onComplete]);

  const handleReviewAndChooseClick = useClickHandler(() => {
    onComplete({
      useMyProfile: false,
      value: 'Review and choose',
    });
  }, [onComplete]);

  const { currentFlowId } = useContext(FlowContext);
  const flow = useSelector((state) => selectFlowById(state, currentFlowId));

  const handleSignOutClick = useClickHandler(() => {
    const callback = () => dispatch(computeAndGoToNextStep(flow));
    dispatch(signOut(callback));
  }, [dispatch, flow]);

  const profileAttributes = steps
    .filter((step) => step.config.active === true)
    .map((step) => step.type)
    .map((type) => PROFILE_INFO_BY[type](primaryAttributes))
    .filter((value) => value?.length > 0);

  return (
    <>
      <ProfileButton loading={loading} onClick={handleUseMyProfileClick}>
        <Profile>
          {profileAttributes.map((text, index) => {
            const separator = index > 0 ? ', ' : '';
            return (
              <Fragment key={text}>
                {separator}
                <Attribute key={text}>{text}</Attribute>
              </Fragment>
            );
          })}
        </Profile>
      </ProfileButton>
      <ButtonOptionSecondary onClick={handleReviewAndChooseClick}>or, Review and choose</ButtonOptionSecondary>
      <ButtonOptionSecondary onClick={handleSignOutClick}>Not you? Sign out.</ButtonOptionSecondary>
    </>
  );
};

const Profile = styled.div`
  text-align: center;
  opacity: 0.75;
`;

const Attribute = styled.span`
  white-space: nowrap;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProfileButton = (props) => {
  if (props.loading) {
    return (
      <ButtonPrimary large>
        <LoadingSpinner />
      </ButtonPrimary>
    );
  }
  return (
    <ButtonPrimary large onClick={props.onClick}>
      <Flex>
        <Label>Use My Profile Info</Label>
        <Text>{props.children}</Text>
      </Flex>
    </ButtonPrimary>
  );
};

const Label = styled.div`
  ${font(28, 'Bold', -0.5)}
  margin-bottom: 0.2rem;
`;

const Text = styled.div`
  ${font(14, 'Book', -0.25, 15)}
  max-width: 28.2rem;
  white-space: normal;
`;
