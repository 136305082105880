import { ButtonOptionPrimary, ButtonOptionSecondary } from '@foyyay/flow-elements';
import { withStopPropagation } from '@shared/lib/events';
import React, { useContext } from 'react';
import { FlowStep } from '../../../../components/FlowStep';
import { removeContact, setCurrentStep } from '../actions/stepContactActions';
import { selectCurrentContact, selectMessage } from '../reducers/stepMultipleContactReducer';
import { StepMultipleContactContext } from '../StepMultipleContact';

export const StepContactOptions = (props) => {
  const { dispatch, useSelector } = useContext(StepMultipleContactContext);

  const message = useSelector((state) => selectMessage(state, props.config, props.data));

  const contact = useSelector(selectCurrentContact);

  const handleEditClick = withStopPropagation(() => dispatch(setCurrentStep('card')));
  const handleRemoveClick = withStopPropagation(() => dispatch(removeContact(contact)));
  const handleGoBackClick = withStopPropagation(() => dispatch(setCurrentStep('list')));

  return (
    <FlowStep description="" label="What would you like to do?" message={message}>
      <ButtonOptionPrimary onClick={handleEditClick}>Edit their information</ButtonOptionPrimary>
      <ButtonOptionPrimary onClick={handleRemoveClick}>Remove from list (delete)</ButtonOptionPrimary>
      <ButtonOptionSecondary onClick={handleGoBackClick}>or, Back to summary</ButtonOptionSecondary>
    </FlowStep>
  );
};
