import { useEffect, useState } from 'react';
import { useScript } from './useScript';

const srcRegEx = /cdn\.plaid\.com/;
const originRegEx = /origin=null/;

const createPlaid = (options, theWindow) => {
  const state = {
    plaid: undefined,
    open: false,
    onExitCallback: undefined,
  };

  // If Plaid is not available, throw an Error
  if (theWindow === undefined || typeof theWindow === 'undefined' || theWindow.Plaid === undefined) {
    throw new Error('Plaid not loaded');
  }

  const fixOrigin = () => {
    // When using Plaid inside of a sourceless iframe, origin becomes null resulting in errors. We can hijack the origin by updating the frame src
    const frames = theWindow.document.querySelectorAll('iframe');
    for (const frame of frames) {
      if (srcRegEx.test(frame.src) !== true) {
        continue;
      }

      if (originRegEx.test(frame.src) !== true) {
        continue;
      }

      frame.src = frame.src.replace(originRegEx, 'origin=*');
    }
  };

  const createOptions = Object.assign({}, options, {
    onExit: (error, metadata) => {
      state.open = false;
      options.onExit !== undefined && options.onExit(error, metadata);
      state.onExitCallback !== undefined && state.onExitCallback();
    },
  });

  state.plaid = theWindow.Plaid.create(createOptions);
  fixOrigin();

  const open = () => {
    if (state.plaid === undefined) {
      return;
    }

    state.open = true;
    state.onExitCallback = undefined;
    state.plaid.open();
  };

  const exit = (exitOptions, callback) => {
    if (state.open !== true || state.plaid === undefined) {
      callback !== undefined && callback();
      return;
    }

    state.onExitCallback = callback;
    state.plaid.exit(exitOptions);

    if (exitOptions?.force === true) {
      state.open = false;
    }
  };

  const destroy = () => {
    if (state.plaid === undefined) {
      return;
    }

    state.plaid.destroy();
    state.plaid = undefined;
  };

  return {
    open: open,
    exit: exit,
    destroy: destroy,
  };
};

const PLAID_LINK_STABLE_URL = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

/**
 * Taken from the react-plaid-link module. Had to allow passing in the window.
 *
 * This hook loads Plaid script and manages the Plaid Link creation for you.
 * You get easy open & exit methods to call and loading & error states.
 *
 * This will destroy the Plaid UI on un-mounting so it's up to you to be
 * graceful to the user.
 *
 * A new Plaid instance is created every time the token and products options change.
 * It's up to you to prevent unnecessary re-creations on re-render.
 *
 * Params:
 *   onLoad: () => {},
 *   onSuccess: (public_token, metadata) => {},
 *   onExit: (err, metadata) => {},
 *   onEvent: (eventName, metadata) => {},
 *   token: 'GENERATED_LINK_TOKEN',
 *   // required for OAuth; if not using OAuth, set to null or omit:
 *   receivedRedirectUri: window.location.href,
 */

export const usePlaidLink = (options, theWindow = window) => {
  const [scriptLoaded, , scriptError] = useScript(PLAID_LINK_STABLE_URL, theWindow.document);
  const [plaid, setPlaid] = useState(undefined);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const products = (options.product || []).slice().sort().join(',');

  const doDestroyInstance = (instance) => {
    instance.exit({ force: true }, () => instance.destroy());
    setPlaid(undefined);
    setIframeLoaded(false);
  };

  useEffect(() => {
    if (scriptLoaded !== true) {
      return;
    }

    if (options.token === undefined) {
      return;
    }

    if (scriptError !== undefined || theWindow.Plaid === undefined) {
      console.error('Error loading Plaid', scriptError);
      return;
    }

    if (plaid !== undefined) {
      doDestroyInstance(plaid);
    }

    const createOptions = Object.assign({}, options, {
      onLoad: () => {
        setIframeLoaded(true);
        options.onLoad !== undefined && options.onLoad();
      },
    });
    const next = createPlaid(createOptions, theWindow);
    setPlaid(next);

    // Return a cleanup function to destroy the Plaid iframe.
    return () => doDestroyInstance(next);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.token, products, scriptLoaded, theWindow]);

  const ready = plaid !== undefined && iframeLoaded === true;
  const exitPlaid = plaid?.exit ?? (() => {});
  const openPlaid = plaid?.open ?? (() => {});

  return {
    error: scriptError,
    ready: ready,
    exit: exitPlaid,
    open: openPlaid,
  };
};
