import { TIME_HOURS, TIME_MINUTES, TIME_PERIODS } from '@shared/constants/datetime';
import { addLeadingZero } from '@shared/lib/format';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { ButtonOptionAlternate } from './ButtonOptionAlternate';
import { convertRemToPixels, media } from './style-utils';
import { useDragScrollRef } from './useDragScrollRef';
import { useWindowSize } from './useWindowSize';

export const TimePicker = (props) => {
  const handleChange = (value) => {
    props.onChange(value);
  };
  const handleHourChange = (hour) => {
    handleChange({ ...props.value, hour: hour });
  };
  const handleMinuteChange = (minute) => {
    handleChange({ ...props.value, minute: minute });
  };
  const handlePeriodChange = (period) => {
    handleChange({ ...props.value, period: period });
  };

  const windowSize = useWindowSize();
  const tabletLandscapeAndUp = windowSize.width > 900;
  const leftOffset = tabletLandscapeAndUp ? convertRemToPixels(3.6) : convertRemToPixels(2.2);

  return (
    <Wrapper className={props.className}>
      <SnapRow>
        <FlexContainer>
          {props.hours.map((hour) => (
            <OptionHour
              key={hour}
              value={hour}
              onChange={handleHourChange}
              checked={hour === props.value.hour}
              leftOffset={leftOffset}
            />
          ))}
        </FlexContainer>
      </SnapRow>
      <SnapRow>
        <FlexContainer>
          {props.minutes.map((minute) => (
            <OptionMinute
              key={minute}
              value={minute}
              onChange={handleMinuteChange}
              checked={minute === props.value.minute}
              leftOffset={leftOffset}
            />
          ))}
        </FlexContainer>
      </SnapRow>
      <SnapRow>
        <FlexContainer>
          {props.periods.map((period) => (
            <OptionPeriod
              key={period}
              value={period}
              onChange={handlePeriodChange}
              checked={period === props.value.period}
              leftOffset={leftOffset}
            />
          ))}
        </FlexContainer>
      </SnapRow>
    </Wrapper>
  );
};

TimePicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

TimePicker.defaultProps = {
  value: {
    hour: '',
    minute: '',
    period: '',
  },
  onChange: () => {},
  hours: TIME_HOURS,
  minutes: TIME_MINUTES,
  periods: TIME_PERIODS,
};

const Wrapper = styled.div``;

const SnapDiv = styled(animated.div)`
  overflow-x: auto;
  scroll-behavior: ${(props) => (props.isScrolling ? 'auto' : 'smooth')};
  margin: 0;

  /* Hide Scrollbars */
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  * {
    pointer-events: ${(props) => (props.isScrolling ? 'none' : 'auto')};
  }
`;

const ScrollContext = React.createContext({});

const SnapRow = (props) => {
  const [ref, onMouseDown, isScrolling] = useDragScrollRef();

  const [{ scroll }, set, pause] = useSpring(() => ({
    scroll: 0,
    immediate: false,
    reset: true,
    config: {
      friction: 20,
      mass: 1,
      tension: 1000,
      clamp: true,
    },
  }));

  const context = {
    set: set,
    ref: ref,
    pause: pause,
  };

  return (
    <ScrollContext.Provider value={context}>
      <SnapDiv {...props} scrollLeft={scroll} onMouseDown={onMouseDown} isScrolling={isScrolling} ref={ref} />
    </ScrollContext.Provider>
  );
};

const useScrollToRef = (checked, leftOffset = 0, currentDate) => {
  const ref = useRef(null);

  const { set, ref: containerRef } = useContext(ScrollContext);

  useEffect(() => {
    const container = containerRef.current;
    const element = ref.current;

    if (element === null || container === null) {
      return;
    }

    if (checked) {
      const offsetLeft =
        container.scrollLeft + element.getBoundingClientRect().left - container.getBoundingClientRect().left;
      const nextScrollLeft = Math.min(Math.max(offsetLeft - leftOffset, 0), container.scrollWidth);

      set({
        scroll: nextScrollLeft,
        from: { scroll: container.scrollLeft },
      });
    }
  }, [checked, leftOffset, currentDate, containerRef, set]);

  return ref;
};

const FlexContainer = styled.div`
  display: inline-flex;
  padding: 0 2.2rem;

  ${media.tabletLandscapeAndUp`
  padding: 0 3.6rem;
`}
`;

const SnapItem = styled.div`
  margin: 0 0.5rem;
`;

const OptionHour = (props) => {
  const ref = useScrollToRef(props.checked, props.leftOffset);
  const { value, ...otherProps } = props;

  const handleClick = (e) => {
    e.stopPropagation();
    props.onChange(value);
  };

  return (
    <SnapItem ref={ref}>
      <ButtonOptionAlternate style={{ margin: '0.8rem 0', width: '10rem' }} onClick={handleClick} {...otherProps}>
        {value}
      </ButtonOptionAlternate>
    </SnapItem>
  );
};

const OptionMinute = (props) => {
  const ref = useScrollToRef(props.checked, props.leftOffset);
  const { value, ...otherProps } = props;

  const handleClick = (e) => {
    e.stopPropagation();
    props.onChange(value);
  };

  return (
    <SnapItem ref={ref}>
      <ButtonOptionAlternate style={{ margin: '0.8rem 0', width: '8rem' }} onClick={handleClick} {...otherProps}>
        {':' + addLeadingZero(value)}
      </ButtonOptionAlternate>
    </SnapItem>
  );
};

const OptionPeriod = (props) => {
  const ref = useScrollToRef(props.checked, props.leftOffset);
  const { value, ...otherProps } = props;

  const handleClick = (e) => {
    e.stopPropagation();
    props.onChange(value);
  };

  return (
    <SnapItem ref={ref}>
      <ButtonOptionAlternate style={{ margin: '0.8rem 0', width: '24.5rem' }} onClick={handleClick} {...otherProps}>
        {value}
      </ButtonOptionAlternate>
    </SnapItem>
  );
};
