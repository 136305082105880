import { REDUX_STATE_KEY, REDUX_STATE_VERSION, REDUX_STATE_EXPIRES_AFTER } from '../constants/state';

// see upgrades/readme.md for upgrade information.
const UPGRADERS = Object.freeze({});

export const loadState = (storageProvider, namespace) => {
  const statekey = getStateKey(namespace);

  try {
    const serializedState = storageProvider.getItem(statekey);
    if (serializedState === null) {
      return undefined;
    }
    let parsed = JSON.parse(serializedState);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const previousVersion = parsed.version;

      const upgrader = UPGRADERS[previousVersion];

      if (upgrader === undefined) {
        break;
      }

      parsed = upgrader(parsed);

      if (parsed.version === previousVersion) {
        return undefined;
      }
    }

    if (Date.now() > parsed.expiresAt) {
      return undefined;
    }

    if (REDUX_STATE_VERSION !== parsed.version) {
      return undefined;
    }

    return parsed.data;
  } catch (err) {
    // continue without error
  }

  return undefined;
};

export const saveState = (storageProvider, state, namespace) => {
  const statekey = getStateKey(namespace);

  const toSerialize = {
    data: state,
    expiresAt: Date.now() + REDUX_STATE_EXPIRES_AFTER,
    version: REDUX_STATE_VERSION,
  };

  try {
    const serializedState = JSON.stringify(toSerialize);
    storageProvider.setItem(statekey, serializedState);
  } catch (err) {
    // continue without error
  }
};

const getStateKey = (namespace) => {
  let statekey = REDUX_STATE_KEY;

  if (namespace !== undefined) {
    statekey = `${REDUX_STATE_KEY}.${namespace}`;
  }

  return statekey;
};
