import styled from 'styled-components';

export const Fullscreen = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  color: ${({ color }) => (color ? color : undefined)};
  background-color: ${({ background }) => (background ? background : undefined)};
  position: absolute;
  top: 0;
  left: 0;
`;
