import { Footer as FooterComponent, media, ProgressButton } from '@foyyay/flow-elements';
import { FooterContainer, NucleusTerms, PoweredByNucleus } from '@shared/components/Footer';
import { selectSubmitButtonTextByFlowId } from '@shared/reducers/flows/flowsByIdReducer';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FlowContext } from '..';

export const FooterFlow = (props) => {
  const complete = props.value === props.max;
  const showMarketingLink = complete !== true;
  const renderProgress = props.showProgress !== false;

  const { currentFlowId } = useContext(FlowContext);
  const submitButtonText = useSelector((state) => selectSubmitButtonTextByFlowId(state, currentFlowId));
  const submitButtonDisabled = props.enabled !== true;

  return (
    <FooterContainer>
      <StyledFooterComponent show={props.show}>
        {renderProgress && (
          <FooterComponent.ProgressContainer>
            <StyledProgressButton
              onClick={props.handleSubmitClick}
              value={props.value}
              max={props.max}
              processing={props.processing}
              disabled={submitButtonDisabled}
            >
              {submitButtonText}
            </StyledProgressButton>
          </FooterComponent.ProgressContainer>
        )}
        <StyledTextContainer>
          <StyledNucleusTerms />
          <StyledPoweredByNucleus linkHome={showMarketingLink} />
        </StyledTextContainer>
      </StyledFooterComponent>
    </FooterContainer>
  );
};

FooterFlow.propTypes = {
  enabled: PropTypes.bool,
  handleSubmitClick: PropTypes.func,
  max: PropTypes.number,
  processing: PropTypes.bool,
  show: PropTypes.bool,
  showProgress: PropTypes.bool,
  value: PropTypes.number,
};

FooterFlow.defaultProps = {
  enabled: true,
  handleSubmitClick: (e) => {},
};

const StyledFooterComponent = styled(FooterComponent)`
  ${media.midgetScreenOnly`
    padding: 0;
  `}

  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? 'auto' : 'none')};
`;

const StyledProgressButton = styled(ProgressButton)`
  ${media.shortScreenOnly`
    height: 5rem;
  `}
`;

const StyledTextContainer = styled(FooterComponent.TextContainer)`
  ${media.shortScreenOnly`
    padding-top: 0;
    padding-bottom: 0;
  `}
`;

const StyledNucleusTerms = styled(NucleusTerms)`
  ${media.midgetScreenOnly`
    display: none !important;
  `}
`;

const StyledPoweredByNucleus = styled(PoweredByNucleus)`
  ${media.shortScreenOnly`
    display: none;
  `}
`;
