import { font, getThemeValue, media } from '@foyyay/flow-elements';
import { Button } from '@shared/components/Button';
import { partial as _partial } from 'lodash';
import styled from 'styled-components';

const getStepThemeValue = _partial(getThemeValue, 'Step.Color.Default');
const getStepInfoBannerThemeValue = _partial(getThemeValue, 'StepInfoBanner.Color.Default');

const StepInfoBannerText = styled.div`
  color: ${getStepInfoBannerThemeValue('Foreground')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${font(12, 'Book', -0.5, 12)}

  ${media.tabletLandscapeAndUp`
    ${font(16, 'Book', -0.75, 16)}
  `}
`;

const DesktopOnlySpan = styled.span`
  display: none;

  ${media.tabletLandscapeAndUp`
    display: inline;
  `}
`;

const StepInfoBannerTopline = styled.div`
  ${font(10, 'Book', -0.5)}
  opacity: 0.75;

  ${media.tabletLandscapeAndUp`
    ${font(12, 'Book', -0.5)}
  `}
`;

const StepInfoBannerContent = styled.div`
  flex: 1 1 auto;
`;

const StepInfoBannerButton = styled(Button)`
  flex: 0 0 auto;
`;

const StepInfoBannerPostline = styled.div`
  ${font(10, 'Book', -0.25)}
  color: ${getStepThemeValue('Foreground')};
  opacity: 0.35;
  text-align: center;

  ${media.tabletLandscapeAndUp`
  ${font(12, 'Book', -0.25)}
  `}
`;

export const StepInfoBanner = Object.assign(
  styled.div`
    background: ${getStepInfoBannerThemeValue('Background')};
    border-radius: 1rem;
    display: flex;
    align-items: center;
    padding: 1rem;

    ${media.tabletLandscapeAndUp`
      padding: 1.2rem 1.5rem 1.2rem 2rem;
    `}
  `,
  {
    Button: StepInfoBannerButton,
    Content: StepInfoBannerContent,
    DesktopOnlySpan: DesktopOnlySpan,
    Text: StepInfoBannerText,
    Topline: StepInfoBannerTopline,
    Postline: StepInfoBannerPostline,
  }
);
