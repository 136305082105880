import { createAction } from '@reduxjs/toolkit';
import { loadGoogleMapsApi } from '@shared/lib/googlemapsapi';
import { ADDRESS_COMPONENTS } from '../constants';

export const setCurrentStep = createAction('StepSmartPhysicalAddress/currentStep/set');
export const setAttribute = createAction('StepSmartPhysicalAddress/attribute/set');
export const setAddress = createAction('StepSmartPhysicalAddress/address/set');
export const clearAddress = createAction('StepSmartPhysicalAddress/address/clear');

export const addNewAddress = () => (dispatch, getState) => {
  dispatch(setAttribute(undefined));
  dispatch(clearAddress());
  dispatch(setCurrentStep('search'));
};

export const selectAddressOption = (option) => (dispatch, getState) => {
  dispatch(setAttribute(option.attribute));
  dispatch(setAddress(option.value));
  dispatch(setCurrentStep('search'));
};

export const getAddressFromOption = async (option, window) => {
  const placeResult = await getPlaceFromOption(option, window);
  const address = getAddressFromPlaceResult(placeResult);
  return address;
};

const getPlaceFromOption = async (option, window) => {
  await loadGoogleMapsApi(window);

  const map = new window.google.maps.Map(document.createElement('div'));
  const places = new window.google.maps.places.PlacesService(map);

  const request = {
    placeId: option.id,
    fields: ['address_components'],
  };

  return await new Promise((resolve, reject) => {
    places.getDetails(request, (placeResult, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        reject(status);
      }

      if (placeResult === null) {
        reject();
      }

      resolve(placeResult);
    });
  });
};

const getKeyFromType = (type) => Object.keys(ADDRESS_COMPONENTS).find((key) => ADDRESS_COMPONENTS[key].includes(type));

const getAddressFromPlaceResult = (placeResult) => {
  const address = placeResult.address_components
    .map((component) => [component.types[0], component.long_name])
    .map(([type, value]) => [getKeyFromType(type), value])
    .filter((pair) => !pair.includes(undefined))
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value,
      };
    }, {});

  if (address.street_number) {
    address.street_address = [address.street_number, address.street_address].join(' ');
    delete address.street_number;
  }

  return address;
};

const REQUIRED_ADDRESS_FIELDS = ['street_address', 'city', 'state', 'postal_code', 'country'];

export const isAddressValid = (address) => {
  return REQUIRED_ADDRESS_FIELDS.every((field) => !['', undefined].includes(address[field]));
};

export const getCityAndState = async (address, window) => {
  const geocoderResult = await getGeocoderResultFromAddress(address, window);
  const updates = getUpdatesFromGeocoderResult(address, geocoderResult);
  return updates;
};

const getGeocoderResultFromAddress = async (address, window) => {
  await loadGoogleMapsApi(window);

  const geocoder = new window.google.maps.Geocoder();

  const request = {
    address: [address.postal_code, address.country].join(','),
  };

  return await new Promise((resolve, reject) => {
    geocoder.geocode(request, function (geocoderResult, status) {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        reject(status);
      }

      if (status === 'ZERO_RESULTS' || geocoderResult?.length < 1) {
        reject(status);
      }

      resolve(geocoderResult);
    });
  });
};

const getUpdatesFromGeocoderResult = (address, geocoderResult) => {
  const addressComponents = geocoderResult[0]['address_components'];

  const city = addressComponents.find((el) => ADDRESS_COMPONENTS['city'].includes(el.types[0]))?.long_name;
  const state = addressComponents.find((el) => ADDRESS_COMPONENTS['state'].includes(el.types[0]))?.long_name;
  const country = addressComponents.find((el) => ADDRESS_COMPONENTS['country'].includes(el.types[0]))?.long_name;

  const updates = {
    country: country,
  };

  if (!address.city) {
    updates.city = city;
  }

  if (!address.state) {
    updates.state = state;
  }

  return updates;
};
