import { get as _get } from 'lodash';
import { ThemeContext } from 'styled-components';

export const Theme = ThemeContext;

export function getThemeValue(...paths) {
  return function (props) {
    return resolveObject(props.theme, ...paths);
  };
}

function resolveObject(object, ...paths) {
  for (const path of paths) {
    object = _get(object, path);
  }
  return object;
}
