import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { media } from './style-utils';
import { Styles } from './Typography';

const T = styled.span`
  ${Styles.L2}
  color: ${({ theme, error }) => (error ? theme.LabelStep.Color.Error : theme.LabelStep.Color.Default)};
  text-transform: uppercase;
`;

const OuterContainer = styled.div`
  pointer-events: none;
  position: relative;
`;

const InnerContainer = styled.div`
  left: 0;
  padding-bottom: 1.5rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  position: ${(props) => (props.absolute ? 'absolute' : undefined)};
  top: 0;

  ${media.tabletLandscapeAndUp`
      padding-left: 1.4rem;
  `};
`;

export const LabelStep = (props) => {
  return (
    <OuterContainer className={props.className}>
      <InnerContainer absolute={props.absolute}>
        <T error={props.error}>{props.error ? props.error : props.children}</T>
      </InnerContainer>
    </OuterContainer>
  );
};

LabelStep.propTypes = {
  absolute: PropTypes.bool,
  error: PropTypes.string,
};
