import {
  ButtonSecondary,
  CircleIconAngle,
  CircleIconContainer,
  font,
  getThemeValue,
  LoadingSpinner,
  media,
  SIZE,
  usePressable,
} from '@foyyay/flow-elements';
import { listPrayers } from '@shared/actions/prayerhub';
import { RenderBlocker } from '@shared/components/RenderBlocker';
import { StatusPageText } from '@shared/components/StatusPage/components/StatusPageText';
import { fromBase64 } from '@shared/lib/encoding';
import { prayersByHubIdSelector, prayerwallByIdSelector } from '@shared/reducers/prayerhub';
import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { animated, useSpring, useTransition } from 'react-spring';
import styled from 'styled-components';
import { PrayerHubContext } from '../context/PrayerHubContext';

const itemsToAnimate = 15;

export const PrayerList = (props) => {
  const { ownerId, currentPrayerHubId, currentPrayerWallId } = useContext(PrayerHubContext);
  const dispatch = useDispatch();
  const prayerwall = useSelector(prayerwallByIdSelector(currentPrayerHubId, currentPrayerWallId));
  const prayers = useSelector(prayersByHubIdSelector(currentPrayerHubId));

  const handleLoadWallPrayers = useCallback(() => {
    dispatch(
      listPrayers({
        ownerId: ownerId,
        prayerhubId: currentPrayerHubId,
        prayerwallId: currentPrayerWallId,
        cursor: prayerwall?.cursor,
      })
    );
  }, [ownerId, currentPrayerHubId, currentPrayerWallId, dispatch, prayerwall]);

  useEffect(() => {
    handleLoadWallPrayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const animatePrayerIds = prayerwall?.prayerIds?.slice(0, itemsToAnimate);
  const nonAnimatePrayerIds = prayerwall?.prayerIds?.slice(itemsToAnimate);

  const transitions = useTransition(animatePrayerIds, (key) => key, {
    from: {
      transform: 'translateY(-1.5rem)',
      opacity: 0,
    },
    enter: {
      transform: 'translateY(0rem)',
      opacity: 1,
    },
    leave: {
      transform: 'translateY(0rem)',
      opacity: 0,
    },
    trail: 100,
    config: {
      friction: 10,
      tension: 100,
    },
  });

  if (prayerwall === undefined) {
    return <LoadingSpinner />;
  }

  const showLoadMore = prayerwall?.cursor;
  const hasPrayerWallPrayers = prayerwall?.prayerIds?.length > 0;

  return (
    <RenderBlocker require={[hasPrayerWallPrayers]} fallback={<EmptyPrayerWall />}>
      {transitions.map(({ item: prayerId, props: style, key }) => {
        const prayer = prayers[prayerId];
        if (prayer === undefined) {
          return null;
        }
        return (
          <animated.div key={key} style={style}>
            <PrayerListItem {...prayer} />
          </animated.div>
        );
      })}

      {nonAnimatePrayerIds?.map((prayerId) => {
        const prayer = prayers[prayerId];

        if (prayer === undefined) {
          return null;
        }

        return <PrayerListItem key={prayer.id} {...prayer} />;
      })}

      {showLoadMore && <LoadMoreButton onClick={handleLoadWallPrayers}>Load More</LoadMoreButton>}
    </RenderBlocker>
  );
};

export const PrayerListItem = React.forwardRef((props, ref) => {
  const [{ scale }, set] = useSpring(() => ({
    scale: 1,
    config: {
      tension: 800,
      friction: 35,
    },
  }));

  const handlePressStart = () => set({ scale: 0.94 });
  const handlePressEnd = () => set({ scale: 1 });

  const bind = usePressable(handlePressStart, handlePressEnd);

  return (
    <Link to={`/prayer/${props.id}`}>
      <Wrapper
        className={props.className}
        onClick={props.onClick}
        ref={ref}
        style={{ transform: scale.interpolate((scale) => `scale(${scale})`) }}
      >
        <BoxShadow>
          <Container {...bind} variant={props.variant} highlightTitle={props.highlightTitle}>
            <InnerContainer>
              <TitleContainer>
                <PrayerBody>{fromBase64(props.body)}</PrayerBody>
                <CircleIconAngle direction="right" />
              </TitleContainer>
            </InnerContainer>
          </Container>
        </BoxShadow>
      </Wrapper>
    </Link>
  );
});

PrayerListItem.propTypes = {
  id: PropTypes.string,
  body: PropTypes.string,
};

const getScopedThemeValue = _partial(getThemeValue, 'LauncherItem');

function getThemeValueByStyle(path) {
  return function (props) {
    switch (props.variant) {
      case 'primary':
        return getScopedThemeValue('Primary', path)(props);
      case 'secondary':
      default:
        return getScopedThemeValue('Secondary', path)(props);
    }
  };
}

function generateBoxShadow(props) {
  const offsetX = '0';
  const offsetY = '0.1rem';
  const blurRadius = '3.1rem';
  const color = props.theme.Step.Color.Default.Shadow;

  return `${offsetX} ${offsetY} ${blurRadius} ${color}`;
}

const PRAYER_LIST_ITEM_BORDER_RADIUS_SMALL = '0.7rem';
const PRAYER_LIST_ITEM_BORDER_RADIUS_LARGE = '1.4rem';

const BoxShadow = styled.div`
  box-shadow: ${generateBoxShadow};
  border-radius: ${PRAYER_LIST_ITEM_BORDER_RADIUS_SMALL};
  position: relative;

  ${media.tabletLandscapeAndUp`
    border-radius: ${PRAYER_LIST_ITEM_BORDER_RADIUS_LARGE};
  `};
`;

const PrayerBody = styled.div`
  ${font(14, 'Book', -0.75)}

  ::first-line {
    ${font(18, 'Bold', -1)}
  }

  max-height: 3.6rem;

  ${media.tabletLandscapeAndUp`
    ${font(18, 'Book', -1)}

    ::first-line {
      ${font(22, 'Bold', -1.25)}
    }

    max-height: 4.5rem;
  `};

  flex: 1 1 auto;
  text-align: left;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Container = styled.div`
  background-color: ${getThemeValueByStyle('Default.Background')};
  border-radius: ${PRAYER_LIST_ITEM_BORDER_RADIUS_SMALL};

  position: relative;
  width: 100%;

  padding-bottom: 0;
  padding-top: 0;
  padding-left: ${SIZE[2]};
  padding-right: ${SIZE[2]};

  cursor: pointer;

  ${media.tabletLandscapeAndUp`
    border-radius: ${PRAYER_LIST_ITEM_BORDER_RADIUS_LARGE};
    padding-left: ${SIZE[4]};
    padding-right: ${SIZE[4]};
    margin-bottom: 1rem;
    margin-top: 1rem;
  `};

  ${PrayerBody} {
    color: ${(props) =>
      props.highlightTitle === true
        ? getThemeValueByStyle('Hover.Foreground')
        : getThemeValueByStyle('Default.Foreground')};
  }

  @media (hover: hover) {
    :hover {
      background-color: ${getThemeValueByStyle('Hover.Background')};

      ${PrayerBody} {
        color: ${getThemeValueByStyle('Hover.Foreground')};
      }
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  height: 6rem;
  margin-bottom: 0;
  margin-top: 0;

  ${media.tabletLandscapeAndUp`
    height: 8.4rem;
    margin-bottom: 0;
    margin-top: 0;
  `};
`;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;

  ${CircleIconContainer} {
    margin-left: ${SIZE[2]};

    ${media.tabletLandscapeAndUp`
      width: ${SIZE[6]} !important;
      height: ${SIZE[6]} !important;
      margin-left: ${SIZE[4]};
    `}
  }
`;

const Wrapper = styled(animated.div)`
  transform-style: preserve-3d;

  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  margin-left: auto;
  margin-right: auto;

  ${media.tabletLandscapeAndUp`
    margin-bottom: 1.2rem;
    margin-top: 1rem;
  `};
`;

const LoadMoreButton = styled(ButtonSecondary)`
  margin-top: ${SIZE[4]};
  border-radius: ${PRAYER_LIST_ITEM_BORDER_RADIUS_SMALL};

  ${media.tabletLandscapeAndUp`
    border-radius: ${PRAYER_LIST_ITEM_BORDER_RADIUS_LARGE};
  `};
`;

const EmptyPrayerWall = (props) => {
  return (
    <StatusPageText headline="Hmm… Nothing here right now! But…">
      You can help get things started by sharing a prayer request :) It’s easy — just hit the button below and tell us
      how we can pray for you.
    </StatusPageText>
  );
};
