import React from 'react';
import styled from 'styled-components';
import { ADDRESS_FIELDS } from '../constants';

const AUTOFILL_ADDRESS_FIELDS = ['street_address_2', 'city', 'state', 'postal_code', 'country'];

export const HiddenAutofillFields = (props) => {
  return (
    <HiddenElement>
      {AUTOFILL_ADDRESS_FIELDS.map((field) => (
        <HiddenInput
          key={field}
          type="text"
          name={ADDRESS_FIELDS[field].id}
          autoComplete={ADDRESS_FIELDS[field].autoComplete}
          onChange={(e) => props.onChange({ [field]: e.target.value })}
          value={props.address[field] || ''}
          placeholder={ADDRESS_FIELDS[field].label}
        />
      ))}
    </HiddenElement>
  );
};

const HiddenElement = styled.div`
  max-height: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
`;

const HiddenInput = styled.input``;
