import { getParentWindow } from '@shared/lib/getParentWindow';

export const AWS_REGION = process.env['REACT_APP_AWS_REGION'];
export const AWS_USER_POOL_ID = process.env['REACT_APP_AWS_USER_POOL_ID'];
export const AWS_USER_POOL_WEB_CLIENT_ID = process.env['REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID'];
export const AWS_COOKIE_DOMAIN = process.env['REACT_APP_AWS_COOKIE_DOMAIN'];

const window = getParentWindow();

export const AWS_AMPLIFY_CONFIG = Object.freeze({
  region: AWS_REGION,
  userPoolId: AWS_USER_POOL_ID,
  userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
  storage: window.localStorage,
});

export const LOGIN_EMAIL_STATUS = {
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  NOSIGNUP: 'Cannot Sign Up',
  PASSWORDRESET: 'Password Reset',
};
