import { Select } from '@shared/components/Select';
import { getIntervals } from '@shared/lib/interval';
import { buildConfigValidator } from '@shared/lib/schema';
import React from 'react';
import * as yup from 'yup';
import { FlowStep } from '../components/FlowStep';

export const StepPaymentScheduleInterval = (props) => {
  const defaultValue = {};
  const initialValue = props.data?.value ?? defaultValue;
  const intervals = getIntervals();
  const message = initialValue.label;

  return (
    <FlowStep message={message}>
      <MultipleChoiceOptions onComplete={props.completeStep} options={intervals} value={initialValue} />
    </FlowStep>
  );
};

const optionSchema = yup.object().shape({
  label: yup.string(),
  value: yup.string(),
});

const configSchema = {
  description: yup.string(),
  label: yup.string().min(3).required(),
  options: yup.array().of(optionSchema).min(1),
  required: yup.bool().required(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepPaymentScheduleInterval.isConfigValid = isConfigValid;

const MultipleChoiceOptions = (props) => {
  const handleChange = (interval) => {
    props.onComplete({ value: interval });
  };

  return <Select options={props.options} onChange={handleChange} />;
};
