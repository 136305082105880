import { LoadingSpinner, Theme } from '@foyyay/flow-elements';
import { initPrayerHub } from '@shared/actions/prayerhub';
import { Button } from '@shared/components/Button';
import { RenderBlocker } from '@shared/components/RenderBlocker';
import { StatusPage, StatusPageActions } from '@shared/components/StatusPage/StatusPage';
import { StatusPageAction } from '@shared/components/StatusPage/components/StatusPageAction';
import { StatusPageText } from '@shared/components/StatusPage/components/StatusPageText';
import {
  STATUS_PAGE_ACTION_PRIORITY_SECONDARY,
  STATUS_PAGE_ACTION_TYPE_BUTTON,
} from '@shared/components/StatusPage/constants';
import { usePreferredColorMode } from '@shared/components/ThemeContext';
import { COLOR_MODE_DARK } from '@shared/constants/color';
import { ControllerContext } from '@shared/context/ControllerContext';
import { withPreventDefault } from '@shared/lib/events';
import {
  prayerhubByIdSelector,
  prayerhubConfigByIdSelector,
  prayerhubConfigLoadedAtByIdSelector,
  prayerhubConfigPrayerwallStatusPageByIdSelector,
  prayerwallAuthorizationByHubIdSelector,
  prayerwallStatusPageByIdSelector,
} from '@shared/reducers/prayerhub';
import { selectIsAuthenticated } from '@shared/reducers/user';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Footer } from '../../components/Footer';
import { GetStartedButton } from '../../components/GetStartedButton';
import { PrayButton } from '../../components/PrayButton';
import { PrayerHubLayout } from '../../components/PrayerHubLayout';
import { PrayerList } from '../../components/PrayerList';
import { PrayerHubContext } from '../../context/PrayerHubContext';

export function PrayerWall(props) {
  const { currentPrayerHubId } = useContext(PrayerHubContext);
  return (
    <RenderBlocker require={[currentPrayerHubId]} fallback={<StatusPageError />}>
      <PrayerWallContent />
    </RenderBlocker>
  );
}

const StatusPageError = (props) => {
  const { reloadPrayerHub } = useContext(PrayerHubContext);
  const config = {
    status: 'error',
    statusPage: {
      headline: 'Whoops, there was an error loading this PrayerHub.',
      body: 'Please try reloading the page.',
      actions: [
        {
          label: 'Reload Now',
          priority: 'primary',
          type: 'button',
          value: (e) => {
            e.preventDefault();
            reloadPrayerHub();
          },
        },
      ],
    },
  };
  return <StatusPage status={config.status} {...config.statusPage} />;
};

const PrayerWallContent = (props) => {
  const { currentPrayerHubId } = useContext(PrayerHubContext);
  const dispatch = useDispatch();
  const prayerhub = useSelector(prayerhubByIdSelector(currentPrayerHubId));
  const config = useSelector(prayerhubConfigByIdSelector(currentPrayerHubId));
  const configLoadedAt = useSelector(prayerhubConfigLoadedAtByIdSelector(currentPrayerHubId));
  const { overrideLogoUrl } = useContext(ControllerContext);
  const colorMode = usePreferredColorMode(useContext(Theme).mode);
  const isPrayerHubLoaded = configLoadedAt !== undefined;

  useEffect(() => {
    dispatch(initPrayerHub(prayerhub));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configLoadedAt]);

  if (isPrayerHubLoaded !== true) {
    return <LoadingSpinner />;
  }

  const logoFileUrl = getLogoUrl(config, colorMode, overrideLogoUrl);

  return <Content logoFileUrl={logoFileUrl} title={config.title} showTitle={config.show_title} />;
};

const Content = (props) => {
  const { currentPrayerHubId, currentPrayerWallId } = useContext(PrayerHubContext);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const wallAuthorized = useSelector(prayerwallAuthorizationByHubIdSelector(currentPrayerHubId, currentPrayerWallId));

  if (isAuthenticated !== true) {
    return <PrayerWallUnauthenticated {...props} />;
  }

  if (wallAuthorized !== true) {
    return <PrayerWallUnauthorized {...props} />;
  }

  return <PrayerWallList {...props} />;
};

const PrayerWallUnauthenticated = (props) => {
  const { currentPrayerHubId } = useContext(PrayerHubContext);
  const statusPage = useSelector(prayerhubConfigPrayerwallStatusPageByIdSelector(currentPrayerHubId));

  return (
    <>
      <PrayerHubLayout {...props}>
        <div style={{ padding: '2rem 0 20rem' }}>
          <StatusPageText headline={statusPage.headline}>{statusPage.body}</StatusPageText>
        </div>
      </PrayerHubLayout>
      <Footer>
        <GetStartedButton />
      </Footer>
    </>
  );
};

const PrayerWallUnauthorized = (props) => {
  const { currentPrayerHubId, currentPrayerWallId } = useContext(PrayerHubContext);
  const history = useHistory();
  const { goToPrayerFlow } = useContext(PrayerHubContext);
  const handlePrayerRequestClick = withPreventDefault(goToPrayerFlow);
  const handleRequestClick = withPreventDefault(() => history.push('access'));
  const statusPage = useSelector(prayerwallStatusPageByIdSelector(currentPrayerHubId, currentPrayerWallId));

  return (
    <>
      <PrayerHubLayout {...props}>
        <div style={{ padding: '2rem 0 20rem' }}>
          <StatusPageText headline={statusPage.headline}>{statusPage.body}</StatusPageText>
          <StatusPageActions>
            <StatusPageAction
              type={STATUS_PAGE_ACTION_TYPE_BUTTON}
              priority={STATUS_PAGE_ACTION_PRIORITY_SECONDARY}
              label="Request Access"
              value={handleRequestClick}
            />
          </StatusPageActions>
        </div>
      </PrayerHubLayout>
      <Footer>
        <Button shape="rounded" size="large" style={{ width: '100%' }} onClick={handlePrayerRequestClick}>
          or, Share a prayer request
        </Button>
      </Footer>
    </>
  );
};

const PrayerWallList = (props) => {
  return (
    <>
      <PrayerHubLayout {...props} alignVertical="top">
        <div style={{ padding: '2rem 0 20rem' }}>
          <PrayerList />
        </div>
      </PrayerHubLayout>
      <Footer>
        <PrayButton />
      </Footer>
    </>
  );
};

function getLogoUrl(config, colorMode, overrideUrl) {
  if (overrideUrl !== undefined) {
    return overrideUrl;
  }

  if (colorMode === COLOR_MODE_DARK) {
    return config.logo_file_url_dark;
  }

  return config.logo_file_url;
}
