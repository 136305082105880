import { useContext, useEffect, useRef, useState } from 'react';
import { FrameContext } from 'react-frame-component';

export const useDragScrollRef = () => {
  const ref = useRef();

  const initialState = {
    isScrolling: false,
    isMouseDown: false,
    initialClientX: 0,
    clientX: 0,
    scrollX: 0,
  };

  const [state, setState] = useState(initialState);

  const onMouseDown = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      isMouseDown: true,
      initialClientX: e.clientX,
      clientX: e.clientX,
      scrollX: ref.current.scrollLeft,
    }));
  };

  const onMouseUp = (e) => {
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      isMouseDown: false,
      isScrolling: false,
    }));
  };

  const onMouseMove = (e) => {
    if (state.isMouseDown) {
      const next = state.scrollX - e.clientX + state.clientX;
      ref.current.scrollLeft = next;
      state.scrollX = next;
      state.clientX = e.clientX;

      if (state.isScrolling === false && Math.abs(state.initialClientX - e.clientX) > 10) {
        setState((prevState) => ({
          ...prevState,
          isScrolling: true,
        }));
      }
    }
  };

  const context = useContext(FrameContext);

  const win = context?.window ?? window;

  useEffect(() => {
    win.addEventListener('mouseup', onMouseUp);
    win.addEventListener('mousemove', onMouseMove);
    return () => {
      win.removeEventListener('mouseup', onMouseUp);
      win.removeEventListener('mousemove', onMouseMove);
    };
  });

  return [ref, onMouseDown, state.isScrolling];
};
