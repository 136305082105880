import { BodyStyle, LoadingSpinner, Theme } from '@foyyay/flow-elements';
import { Wrapper } from '@shared/components/Layout';
import { ThemeContext } from '@shared/components/ThemeContext';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

export const LoadingPage = (props) => {
  return (
    <ThemeContext>
      <Wrapper>
        <Head />
        <BodyStyle />
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      </Wrapper>
    </ThemeContext>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: 250ms opacity ease-out;
  color: ${(props) => props.theme.ProgressCircle.Color.Default.Foreground};
`;

const Head = (props) => {
  const theme = useContext(Theme);
  return (
    <Helmet>
      <meta name="theme-color" content={theme.Background.Color.Default.Background} />
    </Helmet>
  );
};
