import { createReducer } from '@reduxjs/toolkit';
import { createAlert, deleteAlert } from '@shared/actions/alert';
import { frozenArray } from '@shared/actions/helper';

export const selectAlerts = (state) => state.alerts;

const initialState = frozenArray;

export const alertsReducer = createReducer(initialState, {
  [createAlert]: (state, action) => state.concat([action.payload]),
  [deleteAlert]: (state, action) => state.filter((e) => e.id !== action.payload.id),
});
