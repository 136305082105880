import { createAction } from '@reduxjs/toolkit';

export const setCurrentStep = createAction('StepDateTime/currentStep/set');
export const goToNextStep = createAction('StepDateTime/currentStep/next');
export const goToPrevStep = createAction('StepDateTime/currentStep/prev');
export const setInputMode = createAction('StepDateTime/inputMode/set');
export const setDate = createAction('StepDateTime/date/set');
export const clearDate = createAction('StepDateTime/date/clear');
export const setTime = createAction('StepDateTime/time/set');
export const clearTime = createAction('StepDateTime/time/clear');

const REQUIRED_DATE_FIELDS = ['year', 'month', 'day'];
const REQUIRED_TIME_FIELDS = ['hour', 'minute', 'period'];

export const isDateValid = (address) => {
  return REQUIRED_DATE_FIELDS.every((field) => !['', undefined].includes(address[field]));
};

export const isTimeValid = (time) => {
  return REQUIRED_TIME_FIELDS.every((field) => !['', undefined].includes(time[field]));
};
