import { InputPhone, OptionalGoBackButton } from '@foyyay/flow-elements';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import useInputFocusRef from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { formatValueWithPrefix } from '@shared/lib/format';
import React, { useCallback, useMemo, useState } from 'react';
import { FlowStep } from '../components/FlowStep';
import { SkipOrContinueButton } from '../components/StepActionButtons';

export const StepPhone = (props) => {
  const defaultValue = '';
  const initialValue = props.data?.value ?? defaultValue;

  const skipped = props.data?.skipped === true;

  const message = useMemo(() => {
    if (skipped) {
      return `Skipped: "${props.config.label}"`;
    }

    return formatValueWithPrefix(props.config.valuePrefixLabel, initialValue.trim(), props.config.valuePrefixSeparator);
  }, [initialValue, props.config, skipped]);

  return (
    <FlowStep description={props.config.description} label={props.config.label} message={message}>
      <PhoneInput
        value={initialValue}
        required={props.config.required}
        onComplete={props.completeStep}
        goBack={props.goBack}
      />
    </FlowStep>
  );
};

const PhoneInput = (props) => {
  const { onComplete } = props;
  const inputRef = useInputFocusRef(props.id, props.currentStep);

  const initialState = props.value;
  const [value, setValue] = useState(initialState);

  const handleChange = useCallback((value) => setValue(value), [setValue]);

  const readyToContinue = value.length >= 14; // "Fixme: (555) 555-5555" = 14 character string

  const handleComplete = useCallback(() => {
    if (!readyToContinue) {
      return;
    }
    onComplete({
      value: value,
    });
  }, [value, readyToContinue, onComplete]);

  const handleSubmit = useSubmitHandler(handleComplete);
  const handleEnter = useSubmitHandler(handleComplete);
  const handleSaveClick = useClickHandler(handleComplete);

  const handleSkipClick = useClickHandler(() => {
    onComplete({
      skipped: true,
      value: undefined,
    });
  });

  return (
    <>
      <form style={{ marginBottom: '3.6rem' }} onSubmit={handleSubmit}>
        <InputPhone
          name="phone"
          autoComplete="phone"
          value={value}
          onChange={handleChange}
          onEnter={handleEnter}
          ref={inputRef}
        />
      </form>
      <OptionalGoBackButton onClick={props.goBack}>
        <SkipOrContinueButton
          onClick={handleSaveClick}
          onClickSkip={handleSkipClick}
          readyToContinue={readyToContinue}
          required={props.required}
        />
      </OptionalGoBackButton>
    </>
  );
};
