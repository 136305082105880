import { LoadingSpinner } from '@foyyay/flow-elements';
import { checkEmail } from '@shared/actions/user';
import { Footer } from '@shared/components/Footer';
import { LOGIN_EMAIL_STATUS } from '@shared/constants/amplify';
import { ControllerContext } from '@shared/context/ControllerContext';
import { selectFlowConfigById, selectSubmissionByFlowId } from '@shared/reducers/flows/flowsByIdReducer';
import { selectIsAuthenticated } from '@shared/reducers/user';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { Routes } from './Routes';
import { ThankYouLayout } from './components/Layout';
import { ThankYouController, useThankYouController } from '../../controllers/ThankYouController';
import { CustomThankYouMessage } from './routes/CustomThankYouMessage';
import { ExternalUrl } from './routes/ExternalUrl';
import { FlowContext } from '../..';

export const ThankYou = (props) => {
  return (
    <ThankYouController>
      <SwitchThankYouType />
    </ThankYouController>
  );
};

const SwitchThankYouType = () => {
  const { currentFlowId } = useContext(FlowContext);
  const config = useSelector((state) => selectFlowConfigById(state, currentFlowId));

  const type = config.message_thankyou_type ?? 'default';

  switch (type) {
    case 'advanced':
      return <Advanced />;
    case 'external':
      return <ExternalUrl />;
    case 'default':
    default:
      return <Default />;
  }
};

const Default = () => {
  const { currentFlowId } = useContext(FlowContext);
  const submission = useSelector((state) => selectSubmissionByFlowId(state, currentFlowId)) ?? {};

  const initialStep = useInitialStep(submission.receipt_email);

  return (
    <AnimatedLoadingSpinner loading={initialStep === undefined}>
      <ThankYouLayout>
        <div style={{ padding: '7rem 0 20rem' }}>
          <CurrentStep initialStep={initialStep} />
        </div>
      </ThankYouLayout>
      <Footer />
    </AnimatedLoadingSpinner>
  );
};

const Advanced = () => {
  return (
    <ThankYouLayout>
      <div style={{ padding: '7rem 0 20rem' }}>
        <CustomThankYouMessage />
      </div>
    </ThankYouLayout>
  );
};

const AnimatedLoadingSpinner = (props) => {
  const transitions = useTransition(props.loading, (key) => key, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      friction: 55,
      mass: 0.5,
      tension: 1000,
    },
  });

  return transitions.map(({ item: loading, props: style, key }) => (
    <animated.div key={key} style={style}>
      {loading ? <LoadingSpinner /> : props.children}
    </animated.div>
  ));
};

const CurrentStep = (props) => {
  const location = useLocation();
  const currentStep = location.state?.currentStep ?? props.initialStep;

  return <Routes currentStep={currentStep} />;
};

export const THANKYOU_STEP_BY = {
  [LOGIN_EMAIL_STATUS.PASSWORDRESET]: 'complete-account-setup',
  [LOGIN_EMAIL_STATUS.LOGIN]: 'enter-password',
  [LOGIN_EMAIL_STATUS.SIGNUP]: 'create-password',
  [LOGIN_EMAIL_STATUS.NOSIGNUP]: 'thank-you',
};

const useInitialStep = (emailAddress) => {
  const [initialStep, setInitialStep] = useState();

  const dispatch = useDispatch();
  const { setUser } = useThankYouController();

  const getStepFromEmail = async (emailAddress) => {
    let data;

    try {
      data = await dispatch(checkEmail(emailAddress));
    } catch (error) {
      setInitialStep('enter-email');
      return;
    }

    setUser((prevState) => ({
      ...prevState,
      userExists: data.exists,
      emailStatus: data.status,
    }));

    const nextStep = THANKYOU_STEP_BY[data.status] ?? 'thank-you';
    setInitialStep(nextStep);
  };

  const { allowSignIn } = useContext(ControllerContext);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const getInitialStep = async (emailAddress) => {
    if (isAuthenticated || allowSignIn === false) {
      setInitialStep('thank-you');
      return;
    }

    if (emailAddress === undefined) {
      setInitialStep('enter-email');
      return;
    }

    await getStepFromEmail(emailAddress);
  };

  useEffect(() => {
    getInitialStep(emailAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initialStep;
};
