import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { font, media } from './style-utils';
import { Styles } from './Typography';

const AffixCurrencySymbol = styled.div`
  align-items: center;
  display: flex;
  height: 4.6rem;
  overflow: hidden;
  padding-left: calc(2.14rem + 0.9rem);
  position: relative;

  ::before {
    ${font(36, 'Black')}
    color: ${({ theme }) => theme.InputCurrency.Color.Default};
    content: '$';
    left: 0;
    /* Fixme: transition */
    transition: opacity 100ms ease-in-out;
    opacity: ${({ isEmpty }) => (isEmpty ? 0.25 : 1)};
    position: absolute;
  }

  :focus-within ::before {
    opacity: 1;
  }

  ${media.tabletLandscapeAndUp`
    height: 6.4rem;
    padding-left: calc(3.09rem + 1.8rem);

    ::before {
      font-size: 5.2rem;
      line-height: 6.4rem;
    }
  `};
`;

const InputF1 = styled(Input)`
  ${Styles.F1};
  color: ${({ theme }) => theme.InputCurrency.Color.Default};
`;

export class InputCurrency extends Component {
  static propTypes = {
    value: PropTypes.string,
    currency: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // This ref is set by Cleave
    this.inputRef = undefined;
  }

  focus = () => {
    this.inputRef.focus();
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.props.onEnter) {
      this.props.onEnter(e);
    }
  };

  handleChange = (e) => {
    this.props.onChange(e.target.rawValue);
  };

  render() {
    const { value, currency, onChange, onEnter, placeholder, ...otherProps } = this.props;
    const isEmpty = value === '';

    return (
      <AffixCurrencySymbol isEmpty={isEmpty}>
        <InputF1
          as={Cleave}
          options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
          type="text"
          inputMode="decimal"
          autoComplete="off"
          placeholder={placeholder ?? '1.00'}
          size="10"
          value={value}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          htmlRef={(ref) => (this.inputRef = ref)}
          {...otherProps}
        />
      </AffixCurrencySymbol>
    );
  }
}
