import styled from 'styled-components';

export const Input = styled.input`
  background: none;
  border: 0;
  caret-color: inherit;
  color: inherit;
  display: inline-block;
  font-family: inherit;
  margin: 0;
  max-width: 100%;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
  width: 100%;

  /* Safari Line-height bug */
  line-height: normal !important;
  height: auto !important;

  :focus {
    outline: 0;
  }

  ::placeholder {
    color: inherit;
    font-family: inherit;
    opacity: 0.25;
  }
`;
