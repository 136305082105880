import { ButtonGroup, ButtonGroupColumn, font, getThemeValue, InputCurrency, SIZE } from '@foyyay/flow-elements';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useCurrencyFormatter } from '@shared/hooks/useCurrencyFormatter';
import useInputFocusRef from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { formatValueWithPrefix } from '@shared/lib/format';
import { buildConfigValidator } from '@shared/lib/schema';
import { partial as _partial } from 'lodash';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { FlowStep } from '../components/FlowStep';
import { ContinueButton, SkipButton } from '../components/StepActionButtons';
import { StepMultipleChoice } from './StepMultipleChoice/StepMultipleChoice';

export const PAYMENT_AMOUNT_TYPE_FIXED = 'fixed';
export const PAYMENT_AMOUNT_TYPE_LIST = 'list';
export const PAYMENT_AMOUNT_TYPE_OPEN = 'open';

export const PAYMENT_AMOUNT_TYPES = deepFreeze([
  PAYMENT_AMOUNT_TYPE_FIXED,
  PAYMENT_AMOUNT_TYPE_LIST,
  PAYMENT_AMOUNT_TYPE_OPEN,
]);

export const StepPaymentAmount = (props) => {
  const PaymentAmountStepByType = PAYMENT_AMOUNT_STEP_BY[props.config.amountType];
  return <PaymentAmountStepByType {...props} />;
};

export const StepPaymentAmountOpen = (props) => {
  const formatCurrency = useCurrencyFormatter();
  const initialValue = props.data?.value ?? props.config?.amountSuggested;
  const skipped = props.data?.skipped === true;
  const message = skipped
    ? `Skipped: "${props.config.label}"`
    : formatValueWithPrefix(
        props.config.valuePrefixLabel,
        formatCurrency(initialValue),
        props.config.valuePrefixSeparator
      );

  return (
    <FlowStep message={message}>
      <InputAmount
        config={props.config}
        currentStep={props.currentStep}
        onComplete={props.completeStep}
        placeholder={((props.config?.amountSuggested ?? 100) / 100).toFixed(2)}
        value={initialValue}
      />
    </FlowStep>
  );
};

const InputAmount = (props) => {
  const inputRef = useInputFocusRef(props.id, props.currentStep);
  const [inputAmount, setInputAmount] = useState(props.value ? String(props.value / 100) : '');

  const handleComplete = () => {
    let amount = Math.round(inputAmount * 100);
    if (amount < 100) {
      amount = 100;
      setInputAmount(String(amount / 100));
    }
    props.onComplete({
      value: amount,
    });
  };

  const handleSkip = () => {
    props.onComplete({
      skipped: true,
      value: undefined,
    });
  };

  const handleChange = useCallback((value) => setInputAmount(value), [setInputAmount]);
  const handleContinueClick = useClickHandler(handleComplete);
  const handleSkipClick = useClickHandler(handleSkip);
  const handleSubmit = useSubmitHandler(handleComplete);
  const handleEnter = useSubmitHandler(handleComplete);

  const amountIsValid = Math.round(inputAmount * 100) >= 100;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputCurrency
          id="amount"
          name="amount"
          autoComplete="transaction-amount"
          placeholder={props.placeholder}
          value={inputAmount}
          onChange={handleChange}
          onEnter={handleEnter}
          ref={inputRef}
        />
      </form>
      <ButtonGroup style={{ marginTop: '3rem' }}>
        <ButtonGroupColumn>
          <ContinueButton onClick={handleContinueClick} disabled={!amountIsValid}>
            Continue{props.config?.required !== true ? ' to payment info' : ''}
          </ContinueButton>
          {props.config?.required !== true && <SkipButton onClick={handleSkipClick} />}
        </ButtonGroupColumn>
      </ButtonGroup>
    </>
  );
};

export const StepPaymentAmountFixed = (props) => {
  const formatCurrency = useCurrencyFormatter();
  const skipped = props.data?.skipped === true;
  const message = skipped
    ? `Skipped: "${props.config.label}"`
    : formatValueWithPrefix(
        props.config.valuePrefixLabel,
        formatCurrency(props.config.amountFixed),
        props.config.valuePrefixSeparator
      );

  const handleComplete = () => {
    props.completeStep({ value: props.config.amountFixed });
  };

  const handleSkip = () => {
    props.completeStep({
      skipped: true,
      value: undefined,
    });
  };

  const handleContinueClick = useClickHandler(handleComplete);
  const handleSkipClick = useClickHandler(handleSkip);

  return (
    <FlowStep message={message}>
      <FixedAmountContainer>
        <FixedAmountCurrency>{formatCurrency(props.config.amountFixed)}</FixedAmountCurrency>
        <FixedAmountSubline>Payment due today</FixedAmountSubline>
      </FixedAmountContainer>
      <ButtonGroup style={{ marginTop: '3rem' }}>
        <ButtonGroupColumn>
          <ContinueButton onClick={handleContinueClick}>Continue to payment info</ContinueButton>
          {props.config.required !== true && <SkipButton onClick={handleSkipClick} />}
        </ButtonGroupColumn>
      </ButtonGroup>
    </FlowStep>
  );
};

const getScopedThemeValue = _partial(getThemeValue, 'ButtonPrimary.Color');

const FixedAmountContainer = styled.div`
  border: 3px solid ${getScopedThemeValue('Default.Background')};
  text-align: center;
  padding: ${SIZE[2]};
  border-radius: ${SIZE[4]};
  margin-bottom: ${SIZE[3]};
`;

const FixedAmountCurrency = styled.div`
  ${font(52, 'Bold', -0.75)}
  text-align: center;
`;

const FixedAmountSubline = styled.div`
  ${font(14, 'Book', -0.25, 15)}
  text-align: center;
`;

export const StepPaymentAmountList = (props) => {
  const formatCurrency = useCurrencyFormatter();
  const stepPaymentAmountListProps = {
    ...props,
    config: {
      ...props.config,
      options: parseAmountOptions(props.config.amountOptions, formatCurrency),
    },
  };
  return <StepMultipleChoice {...stepPaymentAmountListProps} />;
};

const PAYMENT_AMOUNT_STEP_BY = {
  [PAYMENT_AMOUNT_TYPE_OPEN]: StepPaymentAmountOpen,
  [PAYMENT_AMOUNT_TYPE_FIXED]: StepPaymentAmountFixed,
  [PAYMENT_AMOUNT_TYPE_LIST]: StepPaymentAmountList,
};

const configSchema = {
  description: yup.string(),
  label: yup.string().required(),
  required: yup.mixed().oneOf([true, false, 'conditional']).required(),
  valuePrefixLabel: yup.string(),
  // payment config
  amountType: yup.string().oneOf(PAYMENT_AMOUNT_TYPES).required(),
  // Require amounts when matching type, but allow empty values when not matching
  amountFixed: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === undefined ? null : value))
    .when('amountType', {
      is: PAYMENT_AMOUNT_TYPE_FIXED,
      then: yup.number().min(100).required(),
    }),
  amountSuggested: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === undefined ? null : value))
    .when('amountType', {
      is: PAYMENT_AMOUNT_TYPE_OPEN,
      then: yup.number().min(100),
    }),
  amountOptions: yup.array().when('amountType', {
    is: PAYMENT_AMOUNT_TYPE_LIST,
    then: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required(),
          label: yup.string(),
          value: yup.number().min(100).required(),
        })
      )
      .min(1)
      .required(),
  }),
};

const isConfigValid = buildConfigValidator(configSchema);

StepPaymentAmount.isConfigValid = isConfigValid;

function parseAmountOptions(options, formatCurrency) {
  return options
    .map((option) => {
      const hasValue = option.value !== undefined && option.value !== '';
      if (!hasValue) {
        return undefined;
      }

      const hasLabel = option.label !== undefined && option.label !== '';
      const formattedOption = {
        label: hasLabel ? option.label : formatCurrency(option.value),
        value: option.value,
        id: option.id,
        badge: hasLabel ? formatCurrency(option.value) : undefined,
      };
      return formattedOption;
    })
    .filter((option) => option !== undefined);
}
