import { ButtonOptionSecondary, H1, InputText, media, OptionalGoBackButton, Overlay } from '@foyyay/flow-elements';
import { Modal } from '@shared/components/Modal';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { fromBase64, toBase64 } from '@shared/lib/encoding';
import { formatValueWithPrefix } from '@shared/lib/format';
import { buildConfigValidator } from '@shared/lib/schema';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FlowStep } from '../components/FlowStep';
import { SkipOrContinueButton } from '../components/StepActionButtons';
import * as yup from 'yup';
import { MAP_STEP_TYPE_TO_VALUE_PREFIX_LABEL_FALLBACK, STEP_TYPE } from '@shared/constants';

export const StepContactText = (props) => {
  const parseValue = parser(props.config.isBase64Encoded);

  const defaultValue = '';
  const initialValue = parseValue(props.data?.value ?? defaultValue);

  const skipped = props.data?.skipped === true;

  // Add attributeLabel to the fallback for valuePrefixLabel
  const valuePrefixLabelFallback = MAP_STEP_TYPE_TO_VALUE_PREFIX_LABEL_FALLBACK[STEP_TYPE.CONTACT_TEXT];
  const valuePrefixLabel =
    props.config.valuePrefixLabel +
    (props.config.valuePrefixLabel === valuePrefixLabelFallback ? ` ${props.config.attributeLabel?.toLowerCase()}` : '');

  const message = useMemo(() => {
    if (skipped) {
      return `Skipped: "${props.config.label}"`;
    }

    return formatValueWithPrefix(valuePrefixLabel, initialValue.trim(), props.config.valuePrefixSeparator);
  }, [initialValue, props.config, skipped, valuePrefixLabel]);

  const limit = 500;
  const softLimit = true;

  return (
    <FlowStep description={props.config.description} label={props.config.label} message={message}>
      <TextInput
        config={props.config}
        limit={limit}
        softLimit={softLimit}
        suggestedLength={props.config.suggestedLength}
        value={initialValue}
        required={props.config.required}
        onComplete={props.completeStep}
        goBack={props.goBack}
      />
    </FlowStep>
  );
};

const TextInput = (props) => {
  const { config, onComplete } = props;
  const inputRef = useInputFocusOnceRef();
  const [value, setValue] = useState(props.value);
  const [showLongFormInput, setShowLongFormInput] = useState(false);
  const readyToContinue = value.trim().length > 0;
  const serializeValue = serializer(config.isBase64Encoded);

  const handleChange = useCallback(
    (value) => {
      setValue(serializeValue(value));

      if (value.length > props.limit) {
        setShowLongFormInput(true);
      }
    },
    [setValue, serializeValue, props.limit]
  );

  const handleComplete = useCallback(() => {
    if (!readyToContinue) {
      return;
    }
    onComplete({
      value: value,
    });
  }, [value, readyToContinue, onComplete]);
  const handleSubmit = useSubmitHandler(handleComplete);
  const handleSaveClick = useClickHandler(handleComplete);

  const handleSkipClick = useClickHandler(() => {
    onComplete({
      skipped: true,
      value: undefined,
    });
  });

  const handleOpenLongFormInputClick = useClickHandler(() => setShowLongFormInput(true));

  const showLongFormTrigger = value.length >= props.limit;
  const longFormTriggerText = value.length > props.limit ? 'View & edit all text' : 'Keep writing?';

  return (
    <>
      <form onSubmit={handleSubmit} style={{ marginBottom: '3.6rem' }}>
        <InputText
          placeholder="Type here"
          prompt={config.prompt}
          suggestedLength={props.suggestedLength}
          limit={props.limit}
          softLimit={props.softLimit}
          value={value}
          onChange={handleChange}
          ref={inputRef}
          textSize="small"
        />
        {showLongFormTrigger && (
          <ButtonOptionSecondary onClick={handleOpenLongFormInputClick}>{longFormTriggerText}</ButtonOptionSecondary>
        )}
      </form>
      <OptionalGoBackButton onClick={props.goBack}>
        <SkipOrContinueButton
          onClick={handleSaveClick}
          onClickSkip={handleSkipClick}
          readyToContinue={readyToContinue}
          required={props.required}
        />
      </OptionalGoBackButton>

      {showLongFormInput && (
        <Modal>
          <LongFormTextInputOverlay>
            <OverlayHeadline>{props.label}</OverlayHeadline>
            <OverlayContent>
              <form onSubmit={handleSubmit}>
                <InputText placeholder="Type here" value={value} onChange={handleChange} textSize="extra-small" />
              </form>
            </OverlayContent>
            <OverlayFooter>
              <OverlayActions>
                <SkipOrContinueButton
                  onClick={handleSaveClick}
                  onClickSkip={handleSkipClick}
                  readyToContinue={readyToContinue}
                  required={props.required}
                >
                  Save &amp; Continue
                </SkipOrContinueButton>
              </OverlayActions>
            </OverlayFooter>
          </LongFormTextInputOverlay>
        </Modal>
      )}
    </>
  );
};

const configSchema = {
  active: yup.boolean(),
  description: yup.string(),
  isBase64Encoded: yup.bool(),
  label: yup.string().min(1).required(),
  required: yup.mixed().oneOf([true, false, 'conditional']).required(),
  suggestedLength: yup.string().oneOf(['short', 'long']),
  valuePrefixLabel: yup.string(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepContactText.isConfigValid = isConfigValid;

const parser = (isBase64Encoded) => (value) => (isBase64Encoded === true ? fromBase64(value) : value);
const serializer = (isBase64Encoded) => (value) => (isBase64Encoded === true ? toBase64(value) : value);

const LongFormTextInputOverlay = styled(Overlay)`
  z-index: 2147483010;
`;

const OverlayFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  background: ${(props) => props.theme.Prompt.Color.Default.Background};

  :before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    width: 100%;
    height: 20vh;
    background: linear-gradient(
      ${(props) => props.theme.Prompt.Color.Default.Transparent},
      ${(props) => props.theme.Prompt.Color.Default.Background}
    );
    pointer-events: none;
  }
`;

const OverlayActions = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 40rem;
  padding-bottom: 3.2rem;

  ${media.tabletLandscapeAndUp`
    padding-bottom: 6.5rem;
  `}
`;

const OverlayContent = styled.div`
  min-height: 33vh;
  padding-bottom: 30vh;

  ${media.tabletLandscapeAndUp`
    min-height: 50vh;
  `}
`;

const OverlayHeadline = styled(H1)`
  max-width: 12em;
  margin: 0 auto 1em;
  line-height: 1em;
`;
