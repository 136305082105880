import { fetchInfoCardConfig } from '@shared/actions/launcher';
import { STATE_ERROR, STATE_LOADED, STATE_LOADING } from '@shared/constants/state';
import { infocardByIdSelector, selectLauncherShortcode } from '@shared/reducers/launcher';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCard } from 'types/launcher';

const ASYNC_STATUSES = {
  loading: STATE_LOADING,
  loaded: STATE_LOADED,
  error: STATE_ERROR,
};

export const useInfoCardConfig = (infocardId: string): [InfoCard | undefined, any, typeof ASYNC_STATUSES] => {
  const launcherShortcode = useSelector(selectLauncherShortcode);
  const infocard = useSelector(infocardByIdSelector(infocardId));
  // Return the infocard from state, but always fetch it again to guarantee it's fresh
  const [asyncStatus, setState] = useState(ASYNC_STATUSES.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (asyncStatus === ASYNC_STATUSES.loaded) {
      return;
    }

    async function fetchConfig() {
      try {
        await dispatch(fetchInfoCardConfig({ launcherShortcode: launcherShortcode, infocardId: infocardId }));
      } catch (error) {
        setState(ASYNC_STATUSES.error);
        return;
      }
      setState(ASYNC_STATUSES.loaded);
    }

    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, launcherShortcode]);

  return [infocard, asyncStatus, ASYNC_STATUSES];
};
