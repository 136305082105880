import { isEmpty as _isEmpty } from 'lodash';
import { deepFreeze } from '../lib/deepFreeze';
import { isSelectedStartDateValid } from '../lib/interval';
import { TOKEN_EXPIRATION_BUFFER } from './rebelpay';

export const BANK_ACCOUNT_TYPE_CHECKING = 'GL';
export const BANK_ACCOUNT_TYPE_SAVINGS = 'SAVINGS';

export const SOURCE_TYPE_ACH_DEBIT = 'ach_debit';
export const SOURCE_TYPE_CARD = 'card';
export const SOURCE_TYPE_CASH = 'cash';
export const SOURCE_TYPE_CHECK = 'check';
export const SOURCE_TYPE_OTHER = 'other';

export const SOURCE_TYPES = deepFreeze([
  SOURCE_TYPE_ACH_DEBIT,
  SOURCE_TYPE_CARD,
  SOURCE_TYPE_CASH,
  SOURCE_TYPE_CHECK,
  SOURCE_TYPE_OTHER,
]);

export const SOURCE_TYPES_ONLINE = deepFreeze([SOURCE_TYPE_CARD, SOURCE_TYPE_ACH_DEBIT]);

export const SOURCE_TYPES_OFFLINE = deepFreeze([SOURCE_TYPE_CASH, SOURCE_TYPE_CHECK, SOURCE_TYPE_OTHER]);

export const SOURCE_TYPE_LABELS = deepFreeze({
  [SOURCE_TYPE_ACH_DEBIT]: {
    label: 'Bank Account',
    label_short: 'Bank',
    label_new: 'bank',
  },
  [SOURCE_TYPE_CARD]: {
    label: 'Credit Card',
    label_short: 'Card',
    label_new: 'credit card',
  },
  [SOURCE_TYPE_CASH]: {
    label: 'Cash',
    label_short: 'Cash',
  },
  [SOURCE_TYPE_CHECK]: {
    label: 'Check',
    label_short: 'Check',
  },
  [SOURCE_TYPE_OTHER]: {
    label: 'Other',
    label_short: 'Other',
  },
});

export const EMAIL_REGEX = /^[A-Z0-9_%+-]+(?:\.[A-Z0-9_%+-]+)*@[A-Z0-9-]+(?:\.[A-Z0-9-]+)*\.[A-Z]{2,}$/i;

export const SELECTED_INTERVAL_EXPIRES_AFTER = 3 * 60 * 60 * 1000; // 3 hours

export const STEP_TYPE = deepFreeze({
  ADDITIONAL_CONTACT: 'ADDITIONAL_CONTACT',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  BIRTHDAY: 'GENERIC_BIRTHDAY',
  CONDITIONAL_PRESTEP: 'CONDITIONAL_PRESTEP',
  CONTACT: 'CONTACT',
  CONTACT_TEXT: 'CONTACT_TEXT',
  CREDIT_CARD: 'CREDIT_CARD',
  DATETIME: 'DATETIME',
  EMAIL_ADDRESS: 'GENERIC_EMAIL_ADDRESS',
  FILE_UPLOAD: 'FILE_UPLOAD',
  GENDER: 'GENERIC_GENDER',
  GIVING_AMOUNT: 'GIVING_AMOUNT',
  GIVING_DESIGNATION: 'GIVING_DESIGNATION',
  GIVING_SUMMARY: 'GIVING_SUMMARY',
  INFO: 'INFO',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  NAME: 'GENERIC_NAME',
  PAYMENT_AMOUNT_MULTIPLE_CHOICE: 'PAYMENT_AMOUNT_MULITPLE_CHOICE',
  PAYMENT_AMOUNT: 'PAYMENT_AMOUNT',
  PAYMENT_INFO: 'PAYMENT_INFO',
  PAYMENT_MEMO: 'PAYMENT_MEMO',
  PAYMENT_SCHEDULE_INTERVAL: 'PAYMENT_SCHEDULE_INTERVAL',
  PAYMENT_SCHEDULE_START_DATE: 'PAYMENT_SCHEDULE_START_DATE',
  PAYMENT_SUMMARY: 'PAYMENT_SUMMARY',
  PAYMENT_TYPE: 'PAYMENT_TYPE',
  PAYMENT: 'PAYMENT',
  PHONE: 'GENERIC_PHONE',
  PHYSICAL_ADDRESS: 'GENERIC_PHYSICAL_ADDRESS',
  PLAID: 'PLAID',
  POST_STEP_TEXT: 'POST_STEP_TEXT',
  PRAYER_PRIVACY: 'PRAYER_PRIVACY',
  PROFILE: 'PROFILE',
  SMART_BIRTHDAY: 'BIRTHDAY',
  SMART_CONTACT: 'SMART_CONTACT',
  SMART_EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  SMART_GENDER: 'SMART_GENDER',
  SMART_NAME: 'NAME',
  SMART_PHONE: 'PHONE',
  SMART_PHYSICAL_ADDRESS: 'PHYSICAL_ADDRESS',
  TEXT: 'TEXT',
});

// Map of Steps to validators
export const MAP_STEP_TYPE_TO_VALIDATOR = {
  [STEP_TYPE.PAYMENT_AMOUNT]: (stepData) => {
    if (_isEmpty(stepData)) {
      return false;
    }
    if (stepData?.skipped === true) {
      return true;
    }
    let value = stepData.value;
    if (Array.isArray(value)) {
      value = value.reduce((acc, curr) => acc + curr.value, 0);
    }
    return value >= 100;
  },
  [STEP_TYPE.PAYMENT_SCHEDULE_INTERVAL]: (stepData) => {
    if (stepData?.skipped === true) {
      return true;
    }
    return !_isEmpty(stepData?.value);
  },
  [STEP_TYPE.PAYMENT_SCHEDULE_START_DATE]: (stepData, sharedData) => {
    if (stepData?.skipped === true) {
      return true;
    }

    return !_isEmpty(stepData) && isSelectedStartDateValid(stepData.value, sharedData.interval);
  },
  [STEP_TYPE.PAYMENT_TYPE]: (stepData, sharedData, step, flow) => {
    if (stepData?.skipped === true) {
      return true;
    }
    return !_isEmpty(stepData.value) && flow.config?.merchant?.source_types?.includes(stepData.value.sourceType?.id);
  },
  [STEP_TYPE.PAYMENT_INFO]: (stepData, sharedData, step, flow) => {
    if (stepData?.skipped === true) {
      return true;
    }

    if (_isEmpty(stepData.value)) {
      return false;
    }
    return (
      !_isEmpty(stepData.value) &&
      flow.config?.merchant?.source_types?.includes(sharedData.paymentType?.id) &&
      stepData.value[sharedData.paymentType.id] !== undefined &&
      stepData.value[sharedData.paymentType.id].expires > Date.now() + TOKEN_EXPIRATION_BUFFER
    );
  },
  [STEP_TYPE.PAYMENT_SUMMARY]: (stepData, sharedData) => {
    if (stepData?.skipped === true) {
      return true;
    }

    return (
      !_isEmpty(stepData) &&
      sharedData.amount !== undefined &&
      sharedData.amount === stepData.value?.amount &&
      sharedData.paymentType.id === stepData.value?.source_type
    );
  },
  [STEP_TYPE.GIVING_AMOUNT]: (stepData) => !_isEmpty(stepData) && stepData.value >= 100,
  [STEP_TYPE.GIVING_DESIGNATION]: (stepData) => !_isEmpty(stepData),
  [STEP_TYPE.GIVING_SUMMARY]: (stepData, sharedData) =>
    !_isEmpty(stepData) &&
    sharedData.amount !== undefined &&
    sharedData.amount === stepData.value?.amount &&
    sharedData.paymentType.id === stepData.value?.source_type,
  [STEP_TYPE.MULTIPLE_CHOICE]: (stepData) => {
    if (stepData?.skipped === true) {
      return true;
    }
    return !_isEmpty(stepData?.value);
  },
  [STEP_TYPE.TEXT]: (stepData) => {
    if (stepData?.skipped === true) {
      return true;
    }
    return !_isEmpty(stepData?.value);
  },
  [STEP_TYPE.DATETIME]: (stepData) => {
    if (stepData?.skipped === true) {
      return true;
    }
    return !_isEmpty(stepData?.value);
  },
};

export const MAP_STEP_TYPE_TO_VALUE_PREFIX_LABEL_FALLBACK = {
  // Contact
  [STEP_TYPE.BIRTHDAY]: 'Their birthday is',
  [STEP_TYPE.EMAIL_ADDRESS]: 'Their email is',
  [STEP_TYPE.GENDER]: 'Their gender is',
  [STEP_TYPE.NAME]: 'Their name is',
  [STEP_TYPE.PHONE]: 'Their phone is',
  [STEP_TYPE.PHYSICAL_ADDRESS]: 'Their address is',
  [STEP_TYPE.CONTACT_TEXT]: 'Their',
  // Smart Contact
  [STEP_TYPE.PROFILE]: 'My profile',
  [STEP_TYPE.SMART_BIRTHDAY]: 'My birthday is',
  [STEP_TYPE.SMART_EMAIL_ADDRESS]: 'My email is',
  [STEP_TYPE.SMART_GENDER]: 'My gender is',
  [STEP_TYPE.SMART_NAME]: 'My name is',
  [STEP_TYPE.SMART_PHONE]: 'My phone is',
  [STEP_TYPE.SMART_PHYSICAL_ADDRESS]: 'My address is',
  // Standard
  [STEP_TYPE.TEXT]: 'Wrote',
  [STEP_TYPE.MULTIPLE_CHOICE]: 'Chose',
  [STEP_TYPE.CONDITIONAL_PRESTEP]: 'Chose',
  [STEP_TYPE.POST_STEP_TEXT]: 'Wrote',
  // Payment
  [STEP_TYPE.PAYMENT_AMOUNT]: `I'm paying`,
};

export const INTERVAL = deepFreeze({
  NONE: 'INTERVAL_NONE',
  WEEK: 'INTERVAL_WEEK',
  MONTH: 'INTERVAL_MONTH',
  DATES: 'INTERVAL_DATES',
});

/* INTERVALS: logic related to making transactions and giftschedules.
 * maxCharges: Optional. Sets 'duration' (number of times a schedule can be related
 *              to a successful transaction). Currently useful for scheduled one-time
 *              gift. Example: scheduled one-time gift would use INTERVAL_DATES type;
 *              with intervalDates (array having only date of startDate); startDate;
 *              and maxCharges of 1.
 * intervalNow: If true, will create an immediate charge on any scheduled gift - regardless
 *              of intervalDates or startDate. For Backwards compatibility, it is not
 *              required for one-time, INTERVAL_WEEK, or INTERVAL_MONTH gifts which
 *              start today by default. Because client timezone may differ from UTC,
 *              it is required with INTERVAL_DATES, for the client to use this value
 *              if today's date is in the intervalDates array. It is also the expectation
 *              of the person to be charged immediately if "today" is one of the interval dates.
 * startDate:   Js Timestamp. Sets the interval anchor to say: "no matter what, this
 *              is the 'interval_anchor' and 'next_charge_at' for the gift schedule".
 *              whereas the transaction creation will complete a transaction immediately
 *              when intervalNow is true; the giftschedule processing cron job will
 *              act on this startDate, if set. This allows for any custom schedule or interval,
 *              to charge on this date, and set the next interval based upon it.
 *              Currently, the server will accept a date up to three months future.
 *              [NOTE: startDate and intervalNow are not mutually exclusive,
 *              they could both be used at the same time to "make a gift now", then
 *              "make a gift on this startDate, and thereafter according to the
 *              schedule".] Also, note that developer will need to programmatically ensure
 *              the future startDate is on the desired interval, if the intervalType is
 *              INTERVAL_DATES - or, as otherwise desired, because this will schedule a
 *              charge as of the startDate. For INTERVAL_DATES, the developer needs to
 *              programmatically ensure that the future startDate is on a desired interval,
 *              only if the desired start date is beyond one of the next dates in the array).
 *              Don't pass a startDate value for today! - That would not give the immediate
 *              charge, as might be thought, it would rather charges it when the processing
 *              cron job ran - not a good user experience for charging today -
 *              see intervalNow and above comments.
 * intervalZone: new argument send with api request body that helps us communicate the dates
 *              appropriately to the person. also helps server set nextInterval "date number"
 *              correctly from the standpoint of the person who started this schedule. We are
 *              passing this as a value for each request, but it is only stored on the
 *              server when it is needed for GiftScheduling. In some email communication,
 *              we are still sending UTC times to Givers, but when it comes to formatting
 *              day of week or date of month, we can use this timezone string.
 */

export const SCHEDULE_START_DATE_MAXIMUM_FUTURE_MONTHS = 9;

export const INTERVAL_JUST_THIS_ONCE = 'Just this once';
export const INTERVAL_EVERY_FIRST_AND_FIFTEENTH = 'Every 1st and 15th';
export const INTERVAL_EVERY_FIFTH_AND_TWENTIETH = 'Every 5th and 20th';
export const INTERVAL_EVERY_MONTH = 'Every month';
export const INTERVAL_EVERY_TWO_WEEKS = 'Every two weeks';
export const INTERVAL_EVERY_WEEK = 'Every week';

export const QUICK_INTERVALS = deepFreeze([INTERVAL_EVERY_FIRST_AND_FIFTEENTH, INTERVAL_EVERY_FIFTH_AND_TWENTIETH]);

export const INTERVALS = deepFreeze([
  {
    id: 0,
    label: INTERVAL_EVERY_WEEK,
    intervalType: INTERVAL.WEEK,
    intervalCount: 1,
  },
  {
    id: 1,
    label: INTERVAL_EVERY_TWO_WEEKS,
    intervalType: INTERVAL.WEEK,
    intervalCount: 2,
  },
  {
    id: 2,
    label: INTERVAL_EVERY_MONTH,
    intervalType: INTERVAL.MONTH,
    intervalCount: 1,
  },
  {
    id: 3,
    label: INTERVAL_EVERY_FIRST_AND_FIFTEENTH,
    intervalType: INTERVAL.DATES,
    intervalDates: [1, 15],
  },
  {
    id: 4,
    label: INTERVAL_EVERY_FIFTH_AND_TWENTIETH,
    intervalType: INTERVAL.DATES,
    intervalDates: [5, 20],
  },
  {
    id: 5,
    label: INTERVAL_JUST_THIS_ONCE,
    intervalType: INTERVAL.NONE,
    style: 'secondary',
  },
]);
// group of intervals to offer "today, tomorrow" kind of schedule start
export const INTERVALS_SCHEDULED_BY_DATE = deepFreeze([INTERVAL.NONE, INTERVAL.WEEK, INTERVAL.MONTH]);
// group of intervals to offer "this month, next month" start options
export const INTERVALS_SCHEDULED_BY_DATES = deepFreeze([INTERVAL.DATES]);
export const SCHEDULE_CUSTOM = 'Custom';
export const SCHEDULE_TODAY = 'Today';
export const SCHEDULE_TOMORROW = 'Tomorrow';
export const SCHEDULE_THIS_FRIDAY = 'This Friday';
export const SCHEDULE_NEXT_FRIDAY = 'Next Friday';
export const SCHEDULE_THIS_SUNDAY = 'This Sunday';
export const SCHEDULE_NEXT_SUNDAY = 'Next Sunday';
export const SCHEDULE_THIS_MONTH = 'This month';
export const SCHEDULE_NEXT_MONTH = 'Next month';
// control center may pass "Friday" as the trigger start
export const SCHEDULE_FRIDAY = 'Friday';
// control center may also send "Next week" as a schedule
export const SCHEDULE_NEXT_WEEK = 'Next week';
export const SCHEDULES = deepFreeze([
  SCHEDULE_TODAY,
  SCHEDULE_TOMORROW,
  SCHEDULE_NEXT_FRIDAY,
  SCHEDULE_THIS_FRIDAY,
  SCHEDULE_THIS_SUNDAY,
  SCHEDULE_NEXT_SUNDAY,
  SCHEDULE_THIS_MONTH,
  SCHEDULE_NEXT_MONTH,
]);

export const SUNDAY = 'Sunday';
export const MONDAY = 'Monday';
export const TUESDAY = 'Tuesday';
export const WEDNESDAY = 'Wednesday';
export const THURSDAY = 'Thursday';
export const FRIDAY = 'Friday';
export const SATURDAY = 'Saturday';
export const DAYS_OF_WEEK = deepFreeze([SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY]);

export const MEMO_MAX_LENGTH = 140;
