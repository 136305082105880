import React from 'react';
import PropTypes from 'prop-types';
import { MemoryRouter, useRouteMatch } from 'react-router-dom';
import { Routes } from './Routes';

export const AccountContext = React.createContext();

export const Account = (props) => {
  const initialEntries = ['/sign-in'];
  const routeMatch = useRouteMatch();

  if (routeMatch.params.path !== undefined) {
    initialEntries.unshift(`/${routeMatch.params.path}`);
  }

  const context = {
    linkArgs: props.linkArgs,
    goBack: props.goBack,
  };

  return (
    <AccountContext.Provider value={context}>
      <MemoryRouter initialEntries={initialEntries}>
        <Routes />
      </MemoryRouter>
    </AccountContext.Provider>
  );
};

Account.propTypes = {
  linkArgs: PropTypes.any,
  goBack: PropTypes.func,
};
