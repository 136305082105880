import { media } from '@foyyay/flow-elements';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    & {
      font-size: 9px;

      ${media.smallPhoneAndUp`
        font-size: 10px;
      `}

      ${media.phoneAndUp`
        font-size: 11px;
      `}

      ${media.largePhoneAndUp`
        font-size: 12px;
      `}

      ${media.tabletLandscapeAndUp`
        font-size: 10px;
      `}

      ${media.largeDesktopAndUp`
        font-size: 11px;
      `}

      ${media.hugeDesktopAndUp`
        font-size: 12px;
      `}
    }
  }
`;
