import Color from 'color';
import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import { CircleExclamationmark } from './Icons';
import { booleanPropHelperFactory, conditionalHelperFactory, font, media, SIZE } from './style-utils';
import { TextMarqueeProvider, TextOverflowMarquee, useTextMarquee } from './TextOverflowMarquee';
import { getThemeValue } from './Theme';
import { usePressable } from './usePressable';

const getScopedThemeValue = _partial(getThemeValue, 'Step');
const isCollapsed = booleanPropHelperFactory('collapsed');

const Label = styled.span`
  ${font(18, 'Bold', -0.5)}
  color: ${getScopedThemeValue('Color.Default.Foreground')};
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;

  ${media.tabletLandscapeAndUp`
    ${font(24)}
  `};
`;

const propFactory = (props) => ({ tabIndex: -1 });

const Button = styled.button.attrs(propFactory)`
  appearance: none;
  background: none;
  border: none;
  color: ${getScopedThemeValue('Color.Default.Foreground')};
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.058rem;
  opacity: 0;
  outline: none;
  transition: opacity 100ms ease-in-out;
`;

const ErrorIcon = styled(CircleExclamationmark)`
  color: ${getScopedThemeValue('Color.Error.Background')};
  height: 3.6rem;
  width: 3.6rem;
`;

const PositionAbsoluteRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  > * {
    margin-left: 1.3rem;
  }
`;

const generateLinearGradient = (props) => {
  const angle = '';
  const sideOrCorner = 'right';
  const linearColorStopList = [
    /* Safari cannot render a transparent gradient */
    Color(props.theme.Step.Color.Default.Background).alpha(0).string(),
    props.theme.Step.Color.Default.Background,
  ];

  return `linear-gradient(${angle} to ${sideOrCorner}, ${linearColorStopList.join(', ')})`;
};

const LinearGradient = styled.div`
  background: ${generateLinearGradient};
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 100ms ease-in-out;
`;

const generateBoxShadow = (props) => {
  const offsetX = '0';
  const offsetY = '0.2rem';
  const blurRadius = props.collapsed ? '4.5rem' : '22.1rem';
  const color = props.theme.Step.Color.Default.Shadow;

  return `${offsetX} ${offsetY} ${blurRadius} ${color}`;
};

const BoxShadow = styled.div`
  box-shadow: ${generateBoxShadow};
  border-radius: 3.5rem;
  position: relative;
`;

const Card = styled.div`
  background-color: ${getScopedThemeValue('Color.Default.Background')};
  border-radius: 3.5rem;
  position: relative;
  width: 100%;

  ${media.tabletLandscapeAndUp`
    margin-bottom: 1rem;
    margin-top: 1rem;
  `};

  ${isCollapsed(css`
    @media (hover: hover) {
      :hover ${Button}, :hover ${LinearGradient} {
        opacity: 1;
      }
    }
  `)}
`;

const Body = styled.div`
  padding-left: 2.2rem;
  padding-right: ${(props) => (props.hasError && props.collapsed ? '0.6rem' : '2.2rem')};

  padding-top: 2.9rem;
  padding-bottom: 2.7rem;

  ${media.tabletLandscapeAndUp`
    padding-left: 3.6rem;
    padding-right: ${(props) => (props.hasError && props.collapsed ? '1.2rem' : '3.6rem')};
    padding-top: 3.6rem;
    padding-bottom: 3.2rem;
  `};

  ${isCollapsed(css`
    align-items: center;
    display: flex;
    height: 5rem;
    padding-bottom: 0;
    padding-top: 0;

    ${media.tabletLandscapeAndUp`
      height: 6rem;
      padding-bottom: 0;
      padding-top: 0;
    `}
  `)}
`;

const HeaderContainer = styled.div`
  padding: 1.8rem;

  & + ${Body} {
    padding-top: 0;
  }

  & > * + * {
    // add margin between children
    margin-top: ${SIZE[1]};
  }
`;

const PositionRelative = styled.div`
  position: relative;
  width: 100%;
`;

const is$Collapsed = conditionalHelperFactory((props) => props.$collapsed === true);

const Wrapper = styled(animated.div)`
  transform-style: preserve-3d;

  margin-bottom: 0.8rem;
  margin-top: 0.8rem;

  ${media.tabletLandscapeAndUp`
    margin-bottom: 1rem;
    margin-top: 1rem;
  `};

  ${is$Collapsed(css`
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.8;

    ${media.tabletPortraitAndUp`
      max-width: 36rem;
    `}

    ${media.tabletLandscapeAndUp`
      max-width: 48rem;
    `}
  `)}
`;

const PressableWrapper = React.forwardRef((props, ref) => {
  const [{ scale }, set] = useSpring(() => ({
    scale: 1,
    config: {
      tension: 800,
      friction: 35,
    },
  }));

  const handlePressStart = () => set({ scale: props.$collapsed ? 0.94 : 1 });
  const handlePressEnd = () => set({ scale: 1 });

  const bind = usePressable(handlePressStart, handlePressEnd);

  return (
    <Wrapper style={{ transform: scale.interpolate((scale) => `scale(${scale})`) }} ref={ref} {...props} {...bind} />
  );
});

const Header = (props) => {
  if (props.collapsed !== true && props.children !== undefined) {
    return <HeaderContainer>{props.children}</HeaderContainer>;
  }

  return null;
};

const Content = (props) => {
  if (props.collapsed) {
    return (
      <PositionRelative>
        <Label>
          <TextOverflowMarquee>{props.label}</TextOverflowMarquee>
        </Label>
        <LinearGradient />
        <PositionAbsoluteRight>
          <Button>CHANGE</Button>
          {props.hasError && <ErrorIcon />}
        </PositionAbsoluteRight>
      </PositionRelative>
    );
  }

  return props.children;
};

const noop = () => {};

export const Step = React.forwardRef((props, ref) => {
  const handleClick = props.collapsed ? props.onClick : noop;

  const [providerProps, targetProps] = useTextMarquee();

  return (
    <TextMarqueeProvider {...providerProps}>
      <PressableWrapper $collapsed={props.collapsed} {...targetProps} ref={ref}>
        <BoxShadow collapsed={props.collapsed}>
          <Card collapsed={props.collapsed} hasError={props.hasError} onClick={handleClick}>
            <Header collapsed={props.collapsed}>{props.header}</Header>
            <Body collapsed={props.collapsed} hasError={props.hasError}>
              <Content collapsed={props.collapsed} label={props.message} hasError={props.hasError}>
                {props.children}
              </Content>
            </Body>
          </Card>
        </BoxShadow>
      </PressableWrapper>
    </TextMarqueeProvider>
  );
});

Step.propTypes = {
  collapsed: PropTypes.bool,
  hasError: PropTypes.bool,
  header: PropTypes.node,
  message: PropTypes.string,
  onClick: PropTypes.func,
};
