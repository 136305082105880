import { ControllerContext } from '@shared/context/ControllerContext';
import { useHistoryStack } from '@shared/hooks/useHistoryStack';
import useWindowSize from '@shared/hooks/useWindowSize';
import { getParentWindow } from '@shared/lib/getParentWindow';
import { selectLauncher } from '@shared/reducers/launcher';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from './Routes';

export const Controller = (props) => {
  const { account } = useSelector(selectLauncher);
  const history = useHistory();
  const { push, pop, stack } = useHistoryStack(history);
  const window = getParentWindow();
  const viewportSize = useWindowSize(window);

  const goToLocation = useCallback((location) => push(location), [push]);
  const goToFlow = useCallback((flowShortcode) => goToLocation(`/flow/${flowShortcode}`), [goToLocation]);
  const goToSpringboard = useCallback(
    (launcherShortcode) => goToLocation(`/launcher/${launcherShortcode}`),
    [goToLocation]
  );
  const goToPrayerHub = useCallback(
    (prayerhubShortcode) => goToLocation(`/prayer/${prayerhubShortcode}`),
    [goToLocation]
  );
  const goToUrl = useCallback(
    (url, openInNewTab) => {
      if (openInNewTab) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    },
    [window]
  );

  const showBackButton = stack.length > 1;
  const goBack = useCallback(() => pop(), [pop]);
  const showSignInProfile = account.allowSignIn;

  const context = {
    goBack: goBack,
    goToFlow: goToFlow,
    goToLocation: goToLocation,
    goToSpringboard: goToSpringboard,
    goToPrayerHub: goToPrayerHub,
    goToUrl: goToUrl,
    showBackButton: showBackButton,
    showSignInProfile: showSignInProfile,
    viewportSize: viewportSize,
  };

  return (
    <ControllerContext.Provider value={context}>
      <Routes />
    </ControllerContext.Provider>
  );
};
