import { ButtonPrimary, ButtonSecondary } from '@foyyay/flow-elements';
import { FlowAction } from '@nucleus/types/flow/action';
import { NUCLEUS_PLATFORM_HOSTING_API_URL } from '@shared/constants/urls';
import { ControllerContext } from '@shared/context/ControllerContext';
import { getParentWindow } from '@shared/lib/getParentWindow';
import { isExternalLink } from '@shared/lib/url';
import React, { useContext } from 'react';

interface Props {
  action: FlowAction;
}

export const ActionButton = ({ action }: Props): JSX.Element => {
  const props = useActionButtonProps(action);

  switch (action.displayAs) {
    case 'primary':
      return <ButtonPrimary {...props}>{action.title}</ButtonPrimary>;
    case 'secondary':
    default:
      return <ButtonSecondary {...props}>{action.title}</ButtonSecondary>;
  }
};

const useActionButtonProps = (action: FlowAction) => {
  const { goToFlow, goToPrayerHub, goToUrl, goToSpringboard } = useContext(ControllerContext);

  switch (action.type) {
    case 'flow':
      return {
        onClick: () => goToFlow(action.destination),
      };
    case 'prayer':
      return {
        onClick: () => goToPrayerHub(action.destination),
      };
    case 'infoCard':
      return {
        onClick: () => goToSpringboard({ infocardIdToActivate: action.destination }),
      };
    case 'file':
      return {
        onClick: () => goToUrl(`${NUCLEUS_PLATFORM_HOSTING_API_URL}/${action.destination}`, true),
      };
    case 'url':
      return {
        onClick: () => {
          const openInNewTab = isExternalLink(action.destination, getParentWindow().location) || action.openInNewTab;
          goToUrl(action.destination, openInNewTab);
        },
      };
    case 'none':
    default:
      return {};
  }
};
