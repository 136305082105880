import { deepFreeze } from '@shared/lib/deepFreeze';

export const ADDRESS_FIELDS = deepFreeze({
  street_address: {
    id: 'street_address',
    label: 'Street Address',
    autoComplete: 'address-line1',
  },
  street_address_2: {
    id: 'street_address_2',
    label: 'Apt, Suite, Etc.',
    required: false,
    autoComplete: 'address-line2',
  },
  postal_code: {
    id: 'postal_code',
    label: 'Postal Code',
    autoComplete: 'postal-code',
  },
  city: {
    id: 'city',
    label: 'City',
    autoComplete: 'address-level2',
  },
  state: {
    id: 'state',
    label: 'State/Province',
    autoComplete: 'address-level1',
  },
  country: {
    id: 'country',
    label: 'Country',
    autoComplete: 'country',
  },
});

export const ADDRESS_COMPONENTS = deepFreeze({
  street_number: ['street_number'],
  street_address: ['street_address', 'route'],
  city: [
    'locality',
    'neighborhood',
    'sublocality',
    'sublocality_level_1',
    'sublocality_level_2',
    'sublocality_level_3',
    'sublocality_level_4',
  ],
  state: [
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'administrative_area_level_4',
    'administrative_area_level_5',
  ],
  postal_code: ['postal_code'],
  country: ['country'],
});
