import { usePrayerWallSubscription } from '@shared/actions/prayerwall';
import { LoadingPage } from '@shared/components/LoadingPage';
import { StatusPage } from '@shared/components/StatusPage/StatusPage';
import { prayerwallByIdSelector } from '@shared/reducers/prayerhub';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import { PrayerHubContext } from '../context/PrayerHubContext';

export const Notifications = (): JSX.Element[] => {
  const { currentPrayerHubId, currentPrayerWallId } = useContext<any>(PrayerHubContext);
  const prayerwall = useSelector(prayerwallByIdSelector(currentPrayerHubId, currentPrayerWallId));
  const { loading, updating, currentSubscriber, subscribe, unsubscribe } = usePrayerWallSubscription(
    prayerwall?.config
  );

  let state = currentSubscriber === undefined ? 'unsubscribed' : 'subscribed';
  if (loading) {
    state = 'loading';
  }

  const transitions = useTransition(state, (key) => JSON.stringify(key), {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 120,
      friction: 20,
    },
  });

  return transitions.map(({ item: state, props: style, key }) => {
    if (state === 'loading') {
      return (
        <animated.div key={key} style={style}>
          <LoadingPage />
        </animated.div>
      );
    }

    if (state === 'subscribed') {
      return (
        <animated.div key={key} style={style}>
          <StatusPage
            status="subscribed"
            headline="Manage new prayer request notifications"
            body={
              <>
                <strong>Currently: Active</strong>
                <br />
                <br />
                This means an email is sent to you each time a new prayer is posted. Don’t want to be notified anymore?
                Just hit the “Stop notifying me” button below to turn this off.
              </>
            }
            actions={[
              {
                label: 'Stop notifying me',
                priority: 'secondary',
                type: 'button',
                value: unsubscribe,
                props: { loading: updating },
              },
            ]}
          />
        </animated.div>
      );
    }

    return (
      <animated.div key={key} style={style}>
        <StatusPage
          status="unsubscribed"
          headline="Get notified of new prayer requests"
          body={
            <>
              Want to be notified as soon as someone shares a prayer? Use the button below to subscribe to email
              notifications! This will send you an email each time a prayer is posted to our church’s prayer wall, so
              you can click through to view and pray.
            </>
          }
          actions={[
            {
              label: 'Turn on Notifications',
              priority: 'primary',
              type: 'button',
              value: subscribe,
              props: { loading: updating },
            },
          ]}
        />
      </animated.div>
    );
  });
};
