const objectFromUrlSearchParams = (urlSearchParams) => {
  return Array.from(urlSearchParams).reduce((acc, [key, value]) => {
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);

    const newValue = [acc[decodedKey], decodedValue].filter((value) => value !== undefined && value.length > 0).flat(1);

    return {
      ...acc,
      [decodedKey]: newValue.length > 1 ? newValue : newValue[0],
    };
  }, {});
};

export const parseSearchParams = (search) => objectFromUrlSearchParams(new URLSearchParams(search));
