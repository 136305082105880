import { createReducer } from '@reduxjs/toolkit';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { formatPhoneNumber, formatValueWithPrefix } from '@shared/lib/format';
import { clearPhone, setCurrentStep, setPhone } from '../actions/stepSmartPhoneActions';

export const selectCurrentStep = (state) => state.currentStep;
export const selectPhone = (state) => state.phone;
export const selectOptions = (state) => state.options;

export const selectLabel = (state, config) => {
  if (state.currentStep === 'select') {
    return 'Choose a phone';
  }
  return config.label;
};

export const selectMessage = (state, config, data) => {
  const skipped = data?.skipped === true;

  if (skipped) {
    return `Skipped: "${config.label}"`;
  }
  return formatValueWithPrefix(config.valuePrefixLabel, data?.value.trim(), config.valuePrefixSeparator);
};

const initialState = deepFreeze({
  currentStep: 'input',
  phone: '',
  options: [],
});

export const init = (args) => ({
  ...initialState,
  phone: args.data?.value ?? initialState.phone,
  options: initOptions(args),
  currentStep: initCurrentStep(args),
});

const initOptions = (args) => {
  return args.phoneAttributes.map((phoneAttribute) => ({
    id: phoneAttribute.value.phone,
    label: formatPhoneNumber(phoneAttribute.value.phone),
    attribute: phoneAttribute,
    value: formatPhoneNumber(phoneAttribute.value.phone),
  }));
};

const initCurrentStep = (args) => {
  const { data, phoneAttributes } = args;

  if (data?.value === undefined && phoneAttributes.length > 0) {
    return 'select';
  }

  if (data?.attribute !== undefined) {
    return 'select';
  }

  return 'input';
};

export const stepSmartPhoneReducer = createReducer(initialState, {
  [setCurrentStep]: (state, action) => ({
    ...state,
    currentStep: action.payload,
  }),
  [setPhone]: (state, action) => ({
    ...state,
    phone: action.payload,
  }),
  [clearPhone]: (state, action) => ({
    ...state,
    phone: initialState.phone,
  }),
});
