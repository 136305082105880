import { buildConfigValidator } from '@shared/lib/schema';
import React from 'react';
import * as yup from 'yup';
import { StepMultipleContact } from './StepMultipleContact/StepMultipleContact';
import { StepSingleContact } from './StepSingleContact';

export const StepContact = (props) => {
  if (props.config.allowMultiple === true) {
    return <StepMultipleContact {...props} />;
  }
  return <StepSingleContact {...props} />;
};

const configSchema = {
  allowMultiple: yup.bool().required(),
  contactLabel: yup.string(),
  label: yup.string().min(3).required(),
  description: yup.string(),
  steps: yup
    .array()
    .of(yup.object())
    .test('is-active', 'At least one step is not active', (value, context) =>
      value?.some((step) => step.config?.active)
    )
    .required(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepContact.isConfigValid = isConfigValid;
