import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { requestFileUpload, putFile } from '@shared/lib/file';
import { BLACKLISTED_MIME_TYPES, DEFAULT_MAX_UPLOAD_FILE_SIZE } from '../../../constants';

const isFileAllowed = (file, acceptedMimeTypes) => {
  if (BLACKLISTED_MIME_TYPES.includes(file.type)) {
    return false;
  }

  if (acceptedMimeTypes === undefined) {
    return true;
  }

  if (acceptedMimeTypes.length < 1) {
    return true;
  }

  const acceptedMimeTypeExpressions = acceptedMimeTypes.map((mimeType) => new RegExp(mimeType));

  return acceptedMimeTypeExpressions.some((mimeTypeExpression) => mimeTypeExpression.test(file.type));
};

export const uploadFiles = createAsyncThunk('fileupload/upload', async (args, thunkAPI) => {
  const files = Array.from(args.files);

  if (files.length < 1) {
    const errorMessage = `You didn't select a file.`;
    throw new Error(errorMessage);
  }

  if (files.length > args.maxFiles) {
    const errorMessage = `You selected too many files. (Maximum ${args.maxFiles} files)`;
    throw new Error(errorMessage);
  }

  const maxSize = args.maxSize ?? DEFAULT_MAX_UPLOAD_FILE_SIZE;

  // PreFlight Check
  files.forEach((file) => {
    if (isFileAllowed(file, args.acceptedMimeTypes) !== true) {
      const errorMessage = 'Looks like you tried to upload an unsupported file.';
      throw new Error(errorMessage);
    }

    if (file.size > maxSize) {
      const maxSizeInMB = maxSize / 1024 / 1024;
      const errorMessage = `Looks like you're trying to upload a file larger than ${maxSizeInMB}MB.`;
      throw new Error(errorMessage);
    }
  });

  const filesProgress = files.map(() => 0);

  const calculateTotalProgress = (index, percentCompleted) => {
    filesProgress[index] = percentCompleted;
    return filesProgress.reduce((a, b) => a + b) / filesProgress.length;
  };

  return await Promise.all(
    files.map(async (file, index) => {
      const response = await requestFileUpload(file, args.authState, args.flowId);

      await putFile(file, response.urls.uploadUrl, (percentCompleted) => {
        const progress = calculateTotalProgress(index, percentCompleted);
        thunkAPI.dispatch(setProgress(progress));
      });

      return response.file;
    })
  );
});

export const clearError = createAction('fileupload/clear/error');
export const setProgress = createAction('fileupload/progress');
