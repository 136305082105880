import { SOURCE_TYPE_ACH_DEBIT, SOURCE_TYPE_CARD } from '@shared/constants';
import { buildConfigValidator } from '@shared/lib/schema';
import React from 'react';
import * as yup from 'yup';
import { StepPaymentSourceNewAch } from './StepPaymentSourceNewAch';
import { StepPaymentSourceNewCard } from './StepPaymentSourceNewCard';

const MAP_SOURCE_TYPE_TO_STEP = {
  [SOURCE_TYPE_ACH_DEBIT]: StepPaymentSourceNewAch,
  [SOURCE_TYPE_CARD]: StepPaymentSourceNewCard,
};

export const StepPaymentSourceNew = (props) => {
  if (props.sharedData.paymentType?.id === undefined) {
    return null;
  }

  const StepPaymentSourceNewByType = MAP_SOURCE_TYPE_TO_STEP[props.sharedData.paymentType?.id];

  if (StepPaymentSourceNewByType === undefined) {
    console.error('Unknown payment type');
    return null;
  }

  return <StepPaymentSourceNewByType {...props} />;
};

const configSchema = {
  description: yup.string(),
  label: yup.string().min(3).required(),
  required: yup.bool().required(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepPaymentSourceNew.isConfigValid = isConfigValid;
