import { font, getThemeValue, LoadingSpinner, media, RichText } from '@foyyay/flow-elements';
import { FocalPoint } from '@nucleus/types/media/image';
import { Button } from '@shared/components/Button';
import { RenderBlocker } from '@shared/components/RenderBlocker';
import { StatusPage, StatusPageProps } from '@shared/components/StatusPage/StatusPage';
import { useDimensions } from '@shared/hooks/useDimensions';
import { useInfoCardConfig } from '@shared/hooks/useInfoCardConfig';
import { selectPrimaryAttributes } from '@shared/reducers/user';
import Color from 'color';
import { partial as _partial, sortBy as _sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import { InfoCard as TInfoCard } from 'types/launcher';
import { convertFocalPointToBackgroundPositionCss } from '../../../shared/lib/utilities';
import { SpringboardContext } from '../../context/SpringboardContext';
import { ActionButton } from './components/ActionButton';
import { Footer } from './components/Footer';
import { Layout } from './components/Layout';
import { ShareButton } from './components/ShareButton';
import { InfoCardContext } from './context/InfoCardContext';

const getInfoCardThemeValue = _partial(getThemeValue, 'InfoCard.Color.Default');

const useTitleOverride = (title?: string) => {
  const { setTitleOverride } = useContext<any>(SpringboardContext);

  useEffect(() => {
    setTitleOverride(title);
    return () => {
      setTitleOverride();
    };
  }, [title, setTitleOverride]);
};

export const InfoCard = (): JSX.Element => {
  const currentInfoCardId = useRouteMatch<{ id: string }>().params.id;
  const [infocard, asyncStatus, asyncStatuses] = useInfoCardConfig(currentInfoCardId);
  useTitleOverride((infocard as TInfoCard | undefined)?.headline);

  if (asyncStatus === asyncStatuses.error || (asyncStatus === asyncStatuses.loaded && infocard === undefined)) {
    return <ErrorStatusPage />;
  }

  const context = {
    infocard: infocard,
  };

  return (
    <RenderBlocker require={[infocard]} fallback={<LoadingSpinner />}>
      <InfoCardContext.Provider value={context}>
        <Content />
        <Footer>
          <ShareButton />
          <ActionButton />
        </Footer>
      </InfoCardContext.Provider>
    </RenderBlocker>
  );
};

const Content = (): JSX.Element | null => {
  const attributes = useSelector(selectPrimaryAttributes);
  const { infocard } = useContext(InfoCardContext);

  if (infocard === undefined) {
    return null;
  }

  const headerProps = getHeaderProps(infocard);

  return (
    <Layout>
      <AnimatedCard key={infocard.id}>
        <CardHeader>
          {headerProps && <CardHeaderImage {...headerProps} />}
          <CardHeaderContent>
            {infocard.headline && <Headline>{infocard.headline}</Headline>}
            {infocard.byline && <Byline>{infocard.byline}</Byline>}
          </CardHeaderContent>
        </CardHeader>
        <CardBody>
          {infocard.body && (
            <BodyContent>
              <RichText
                nodes={infocard.body}
                parameters={{
                  name: attributes.name,
                  firstName: attributes.firstName,
                  lastName: attributes.lastName,
                }}
              />
            </BodyContent>
          )}
        </CardBody>
      </AnimatedCard>
    </Layout>
  );
};

const ErrorStatusPage = () => {
  const config = {
    headline: 'Hmm… this isn’t available right now.',
    body: 'It’s possible you’ve found something that isn’t quite ready yet, or you’ve stumbled upon an old link that’s no longer available.',
    actions: [{ label: 'Go back', priority: 'primary', type: 'launcher', value: '/' }],
  } as StatusPageProps;
  return <StatusPage {...config} />;
};
const getHeaderProps = (infocard: TInfoCard) => {
  if (infocard.headerFileUrl === undefined) {
    return undefined;
  }

  if (infocard.headerFileSrcSet === undefined || infocard.headerFileSrcSet.length < 1) {
    return { url: infocard.headerFileUrl, focalPoint: infocard.headerFocalPoint };
  }

  const sortedSources = _sortBy(infocard.headerFileSrcSet, (src) => src.dimensions.width).reverse();

  return {
    url: sortedSources[0].src,
    sources: sortedSources.map((source) => ({ width: source.dimensions.width, url: source.src })),
    focalPoint: infocard.headerFocalPoint,
  };
};

const Card = styled(animated.div)`
  background: ${getInfoCardThemeValue('Background')};
  box-shadow: 0 1px 24px 0 ${getInfoCardThemeValue('Shadow')};
  border-radius: 2.4rem;
  max-width: 100%;
  width: 48rem;
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${media.tabletLandscapeAndUp`
    border-radius: 4.5rem;
    width: 62rem;
  `}

  ${media.desktopAndUp`
    width: 76rem;
  `}
`;

const DELAY = 250;

const AnimatedCard = (props: any) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setShow(true);
    }, DELAY);
    return () => clearTimeout(id);
  }, []);

  const transitions = useTransition(show, null, {
    from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    config: { mass: 1, tension: 180, friction: 20 },
  });

  return (
    <>
      {transitions.map(
        ({ item: state, props: style, key }) =>
          state && (
            <Card key={key} style={style}>
              {props.children}
            </Card>
          )
      )}
    </>
  );
};

const CardHeader = styled.div`
  flex: 0 0 auto;
  color: ${getInfoCardThemeValue('Foreground')};

  &:last-child {
    padding-bottom: 2.5rem;
  }

  ${media.tabletLandscapeAndUp`
    &:last-child  {
      padding-bottom: 5rem;
    }
  `}
`;

const CardHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.4rem 1.5rem 0;
  padding-bottom: 0;

  &:first-child {
    padding-top: 2.5rem;
  }

  ${media.tabletLandscapeAndUp`
    padding: 4rem 4rem 0;

    &:first-child {
      padding-top: 5rem;
    }
  `}

  ${media.desktopAndUp`
    padding-left: 5rem;
    padding-right: 5rem;
  `}
`;

const CardBody = styled.div`
  flex: 1 1 auto;
  color: ${getInfoCardThemeValue('Foreground')};
  padding: 2rem 1.5rem 0;
  display: flex;
  flex-direction: column;

  :first-child {
    padding-top: 1.5rem;
  }

  :last-child {
    padding-bottom: 1.5rem;
  }

  ${media.tabletLandscapeAndUp`
    padding: 3rem 4rem 0;

    &:first-child {
      padding-top: 4rem;
    }

    &:last-child {
      padding-bottom: 4rem;
    }
  `}

  ${media.desktopAndUp`
    padding-left: 5rem;
    padding-right: 5rem;
  `}
`;

const CardHeaderImage = styled.div<{
  url: string;
  sources?: { width: number; url: string }[];
  focalPoint?: FocalPoint;
}>`
  background-color: ${getInfoCardThemeValue('MediaBackground')};
  background-image: url(${(props) => props.url});
  background-position: ${(props) => convertFocalPointToBackgroundPositionCss(props.focalPoint)};
  background-size: cover;
  width: 100%;
  padding-bottom: 45%;
  border-radius: 2.4rem 2.4rem 0 0;

  ${media.tabletLandscapeAndUp`
    border-radius: 4.5rem 4.5rem 0 0;
  `}

  ${(props) => {
    if (props.sources === undefined) {
      return '';
    }

    return _sortBy(props.sources, (src) => src.width)
      .reverse()
      .map(
        (source) => css`
          @media (max-width: ${source.width}px) {
            background-image: url(${source.url});
          }
        `
      );
  }}
`;

const Byline = styled.div`
  ${font(12, 'Book', -0.5, 12)}
  opacity: 0.35;
  margin-top: 0.4em;
  padding: 0 1.2rem;

  ${media.tabletLandscapeAndUp`
    ${font(16, 'Book', -0.75, 16)}
    padding: 0 1.5rem;
    max-width: 34em;
  `}
`;

const Headline = styled.div`
  ${font(24, 'Bold', -1, 24)}
  padding: 0 1.2rem;

  ${media.tabletLandscapeAndUp`
    ${font(32, 'Bold', -1.25, 32)}
    padding: 0 1.5rem;
    max-width: 17em;
  `}
`;

const BodyContentContainer = styled.div<{ expanded: boolean }>`
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  min-height: 14rem;
`;

const BodyContentText = styled.div<{ expanded: boolean }>`
  padding: 0 1.2rem;

  ${media.tabletLandscapeAndUp`
    padding: 0 1.5rem;
  `}

  ${(props) =>
    !props.expanded &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `}

  white-space: pre-line;
`;

const BodyContent = (props: { children: React.ReactNode }) => {
  const [expanded, setExpanded] = useState(true);
  const [textRef, textDimensions] = useDimensions();
  const [containerRef, containerDimensions] = useDimensions();

  const isBodyContentFullyVisible = (textDimensions.height ?? 0) <= (containerDimensions.height ?? 0);
  const showContinueReadingButton = !expanded && !isBodyContentFullyVisible;

  return (
    <BodyContentContainer ref={containerRef} expanded={expanded}>
      <BodyContentText ref={textRef} expanded={expanded}>
        {props.children}
      </BodyContentText>
      {showContinueReadingButton && (
        <ContinueReadingButtonContainer>
          <ContinueReadingButtonTextFade />
          <ContinueReadingButtonBackdrop>
            <ContinueReadingButton size="medium" shape="pill" variant="secondary" onClick={() => setExpanded(true)}>
              Continue Reading
            </ContinueReadingButton>
          </ContinueReadingButtonBackdrop>
        </ContinueReadingButtonContainer>
      )}
    </BodyContentContainer>
  );
};

const ContinueReadingButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const ContinueReadingButtonTextFade = styled.div`
  background: linear-gradient(
    to bottom,
    ${(props) => Color(props.theme.InfoCard.Color.Default.Background).alpha(0).string()} 0%,
    ${getInfoCardThemeValue('Background')} 100%
  );
  height: 14rem;
  width: 100%;
`;

const ContinueReadingButtonBackdrop = styled.div`
  background: ${getInfoCardThemeValue('Background')};
`;

const ContinueReadingButton = styled(Button)`
  width: 100%;
`;
