import { Footer as FooterComponent, L3, T2 } from '@foyyay/flow-elements';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { MARKETING_SITE_BASE_URL } from '../constants/urls';

export const Footer = (props) => {
  return (
    <FooterContainer>
      <FooterComponent>
        <FooterComponent.TextContainer>
          <NucleusTerms />
          <PoweredByNucleus />
        </FooterComponent.TextContainer>
      </FooterComponent>
    </FooterContainer>
  );
};

export const FooterContainer = styled.div`
  position: relative;
  z-index: 2147483004;
`;

export const NucleusTerms = (props) => {
  return (
    <TermsText className={props.className}>
      <span>Secure &amp; encrypted. By using you agree to</span>{' '}
      <a href={MARKETING_SITE_BASE_URL + '/legal'} target="_blank" rel="noreferrer noopener">
        our terms
      </a>
      <span>.</span>
    </TermsText>
  );
};

export const PoweredByNucleus = (props) => {
  return (
    <PoweredText className={props.className}>
      {props.linkHome === true && <PoweredLink />}
      {props.linkHome !== true && <PoweredName />}
    </PoweredText>
  );
};

PoweredByNucleus.propTypes = {
  linkHome: PropTypes.bool,
};

PoweredByNucleus.defaultProps = {
  linkHome: true,
};

const PoweredLink = () => (
  <a href={MARKETING_SITE_BASE_URL} target="_blank" rel="noreferrer noopener">
    Powered by Nucleus
  </a>
);

const PoweredName = () => <span>Powered by Nucleus</span>;

const TermsText = styled(T2)`
  margin-top: 0;
  margin-bottom: 0;
`;

const PoweredText = styled(L3)`
  margin-top: 0.25em;
`;
