/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createReducer } from '@reduxjs/toolkit';
import {
  applyAccountSettings,
  applyCalloutSettings,
  applyDiscoverySettings,
  applyFeaturedItemCalloutSettings,
  applyOverlaySettings,
  applyThemeSettings,
  applyTitleSettings,
  applyTriggerSettings,
  closeCallout,
  closeDiscoveryMessage,
  closeFeaturedItemCallout,
  closeFullscreen,
  closeOverlay,
  dismissBannerByType,
  fetchBanners,
  fetchInfoCardConfig,
  fetchLauncherConfig,
  openCallout,
  openDiscoveryMessage,
  openFeaturedItemCallout,
  openFullscreen,
  openOverlay,
  refreshActiveBannersByType,
  setForceFullscreen,
  setLauncherConfigFlow,
  toggleCallout,
  toggleDiscoveryMessage,
  toggleFeaturedItemCallout,
  toggleFullscreen,
  toggleOverlay,
} from '@shared/actions/launcher';
import { COOKIE_BANNER_DISMISSED, COOKIE_LAUNCHER_DISCOVERED, PREFER_LARGER } from '@shared/constants/launcher';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { LocalStorage } from '@shared/lib/localstorage';
import { forEachObjectKey } from '@shared/lib/utilities';
import { PublicBanners } from 'types/banners';
import { InfoCard } from 'types/launcher';
import { LauncherConfig } from 'types/launcherconfig';
import { applyModeSettings } from '../actions/launcher';
import { STATE_CLOSED, STATE_OPENED } from '../constants/state';

interface RootState {
  launcher: LauncherState;
}

export const selectLauncher = (state: RootState) => state.launcher;
export const selectLauncherConfig = (state: RootState) => state.launcher.config;
export const selectLauncherShortcode = (state: any) => state.launcher.config?.shortcode;

export const selectItems = (state: RootState) => state.launcher.config?.items ?? [];
export const selectFeaturedItems = (state: RootState) => state.launcher.config?.featured_items ?? [];

export const itemByIdSelector = (id: string) => (state: RootState) => selectItems(state).find((item) => item.id === id);

export const selectInfoCards = (state: RootState) => state.launcher.infocards ?? [];
export const infocardByIdSelector = (id: string) => (state: RootState) =>
  selectInfoCards(state).find((item) => item.id === id);

export const selectBanners = (state: RootState) => state.launcher.config?.banners;
export const selectActiveBanners = (state: RootState) => state.launcher.banners;

export const bannersByTypeSelector = (type: string) => (state: RootState) => state.launcher.config?.banners[type] ?? [];
export const activeBannersByTypeSelector = (type: string) => (state: RootState) => state.launcher.banners[type] ?? [];

export const selectCallout = (state: RootState) => state.launcher.calloutMessage;
export const selectCalloutState = (state: RootState) => state.launcher.calloutMessage.state;

export const selectOverlay = (state: RootState) => state.launcher.overlay;
export const selectOverlayState = (state: RootState) => state.launcher.overlay.state;

export const selectTrigger = (state: RootState) => state.launcher.trigger;

export const selectSeeMoreLabel = (state: RootState) => state.launcher.config.see_more_label;

export function filterActiveBanners(banners: any[]) {
  return banners.filter((banner) => {
    const previouslyDismissed =
      LocalStorage.getItem(`${COOKIE_BANNER_DISMISSED}_${banner.type}_${banner.id}`) !== undefined;
    if (previouslyDismissed === true) return false;

    if (banner.schedule?.type === 'OneTime') {
      const now = new Date();
      // parse iso8601 start and end dates
      const startDate = banner.schedule?.startDate ? new Date(banner.schedule.startDate) : undefined;
      const endDate = banner.schedule?.endDate ? new Date(banner.schedule.endDate) : undefined;

      if (startDate && startDate > now) return false;
      if (endDate && endDate < now) return false;
    }

    return true;
  });
}

const initialState = deepFreeze<LauncherState>({
  account: {
    allowSignIn: true,
    allowSignUp: true,
  },
  banners: {},
  calloutMessage: {
    enabled: false,
    state: STATE_CLOSED,
    title: '',
    description: '',
  },
  config: undefined as any,
  discovered: Boolean(LocalStorage.getItem(COOKIE_LAUNCHER_DISCOVERED)),
  discoveryMessage: {
    enabled: true,
    state: STATE_CLOSED,
    title: undefined,
    description: undefined,
  },
  featuredItemCallout: {
    enabled: false,
    state: STATE_CLOSED,
  },
  infocards: [],
  overlay: {
    allowResize: true,
    fullscreen: false,
    state: STATE_CLOSED,
  },
  title: undefined,
  titleSearch: undefined,
  theme: {
    color: undefined,
    mode: undefined,
    paletteVibrancy: undefined,
  },
  trigger: {
    enabled: true,
    icon: undefined,
    iconResize: true,
    label: undefined,
    color: undefined,
    paletteVibrancy: undefined,
    position: 'right',
    shape: 'circle',
  },
});

interface LauncherState {
  account: {
    allowSignIn: boolean;
    allowSignUp: boolean;
  };
  banners: PublicBanners;
  calloutMessage: {
    enabled: false;
    state: string;
    title: string;
    description: string;
  };
  config: LauncherConfig;
  discovered: boolean;
  discoveryMessage: {
    enabled: boolean;
    state: string;
    title?: unknown;
    description?: unknown;
  };
  featuredItemCallout: {
    enabled: boolean;
    state: string;
  };
  infocards: Array<InfoCard>;
  overlay: {
    allowResize: boolean;
    fullscreen: boolean;
    forceFullscreen?: boolean;
    state: string;
  };
  title?: unknown;
  titleSearch?: unknown;
  theme: {
    color?: unknown;
    mode?: unknown;
    paletteVibrancy?: unknown;
  };
  trigger: {
    enabled: boolean;
    icon?: unknown;
    iconResize?: unknown;
    label?: unknown;
    color?: unknown;
    paletteVibrancy?: unknown;
    position: string;
    offsetBottom?: string;
    offsetSide?: string;
    zIndex?: string;
    shape?: string;
  };
}

export const launcherReducer = createReducer<LauncherState>(initialState, {
  [fetchLauncherConfig.fulfilled.type]: (
    state,
    action: ReturnType<typeof fetchLauncherConfig.fulfilled>
  ): LauncherState => ({
    ...state,
    account: {
      allowSignIn: action.payload.allowSignIn ?? state.account.allowSignIn,
      allowSignUp: action.payload.allowSignUp ?? state.account.allowSignUp,
    },
    config: action.payload,
    title: state.title || action.payload.title,
    titleSearch: state.title || action.payload.title_search,
    theme: {
      color: state.theme.color || action.payload.color_flow,
      paletteVibrancy: state.theme.paletteVibrancy || action?.payload?.color_flow_palette_vibrancy,
      mode: state.theme.mode || action.payload.color_mode,
    },
    discoveryMessage: {
      ...state.discoveryMessage,
      title: state.discoveryMessage.title || action.payload.discovery_message_title,
      description: state.discoveryMessage.description || action.payload.discovery_message_byline,
    },
    featuredItemCallout: {
      ...state.featuredItemCallout,
      enabled: state.featuredItemCallout.enabled || action.payload.featured_item_callout.enabled,
    },
    overlay: {
      ...state.overlay,
      fullscreen: action.payload.preferred_size === PREFER_LARGER,
    },
    trigger: {
      ...state.trigger,
      shape: action.payload.trigger_shape ?? state.trigger.shape,
      label: action.payload.trigger_label,
      icon:
        action.payload.trigger_icon_custom_enabled === true && action.payload.trigger_icon_file_url !== undefined
          ? action.payload.trigger_icon_file_url
          : action.payload.trigger_icon,
      iconResize: action.payload.trigger_icon_resize ?? state.trigger.iconResize,
      color: action.payload.color_trigger,
      paletteVibrancy: state.trigger.paletteVibrancy || action?.payload?.color_trigger_palette_vibrancy,
      position: action.payload.trigger_position,
      offsetBottom: action.payload.trigger_offset_bottom,
      offsetSide: action.payload.trigger_offset_side,
      zIndex: action.payload.trigger_zindex,
    },
    banners: {
      ...state.banners,
      ...forEachObjectKey(action.payload.banners, filterActiveBanners),
    },
    infocards: [...state.infocards, ...(action.payload?.infocards ?? [])],
  }),
  [fetchInfoCardConfig.fulfilled.type]: (
    state,
    action: ReturnType<typeof fetchInfoCardConfig.fulfilled>
  ): LauncherState => {
    const infocards = state.infocards.filter((infocard) => infocard.id !== action.payload.infocard.id);
    return {
      ...state,
      infocards: [...infocards, action.payload.infocard],
    };
  },
  [fetchInfoCardConfig.rejected.type]: (
    state,
    action: ReturnType<typeof fetchInfoCardConfig.rejected>
  ): LauncherState => {
    const infocards = state.infocards.filter((infocard) => infocard.id !== action.meta.arg.infocardId);
    return {
      ...state,
      infocards: [...infocards],
    };
  },
  [setLauncherConfigFlow.type]: (state, action: ReturnType<typeof setLauncherConfigFlow>): LauncherState => ({
    ...state,
    config: {
      ...action.payload,
    },
    theme: {
      color: action.payload.color_flow,
      paletteVibrancy: action.payload.color_flow_palette_vibrancy,
      mode: action.payload.color_mode,
    },
    discoveryMessage: {
      ...state.discoveryMessage,
      enabled: false,
    },
    overlay: {
      ...state.overlay,
      allowResize: false,
      fullscreen: true,
    },
    trigger: {
      ...state.trigger,
      shape: action.payload.trigger_shape,
      label: action.payload.trigger_label,
      icon:
        action.payload.trigger_icon_custom_enabled === true
          ? action.payload.trigger_icon_file_url
          : action.payload.trigger_icon,
      iconResize: action.payload.trigger_icon_resize ?? true,
      color: action.payload.color_trigger,
      paletteVibrancy: action.payload.color_trigger_palette_vibrancy,
      position: action.payload.trigger_position,
      offsetBottom: action.payload.trigger_offset_bottom,
      offsetSide: action.payload.trigger_offset_side,
      zIndex: action.payload.trigger_zindex,
    },
  }),
  [applyAccountSettings.type]: (state, action: ReturnType<typeof applyAccountSettings>): LauncherState => ({
    ...state,
    account: {
      ...state.account,
      ...action.payload,
    },
  }),
  [applyCalloutSettings.type]: (state, action: ReturnType<typeof applyCalloutSettings>): LauncherState => ({
    ...state,
    calloutMessage: {
      ...state.calloutMessage,
      ...action.payload,
    },
  }),
  [applyDiscoverySettings.type]: (state, action: ReturnType<typeof applyDiscoverySettings>): LauncherState => ({
    ...state,
    discoveryMessage: {
      ...state.discoveryMessage,
      ...action.payload,
    },
  }),
  [applyFeaturedItemCalloutSettings.type]: (
    state,
    action: ReturnType<typeof applyFeaturedItemCalloutSettings>
  ): LauncherState => ({
    ...state,
    featuredItemCallout: {
      ...state.featuredItemCallout,
      ...action.payload,
    },
  }),
  [applyModeSettings.type]: (state, action: ReturnType<typeof applyModeSettings>): LauncherState => ({
    ...state,
    mode: action.payload,
  }),
  [applyOverlaySettings.type]: (state, action: ReturnType<typeof applyOverlaySettings>): LauncherState => ({
    ...state,
    overlay: {
      ...state.overlay,
      ...action.payload,
    },
  }),
  [applyTitleSettings.type]: (state, action: ReturnType<typeof applyTitleSettings>): LauncherState => ({
    ...state,
    title: action.payload,
  }),
  [applyTriggerSettings.type]: (state, action: ReturnType<typeof applyTriggerSettings>): LauncherState => ({
    ...state,
    trigger: {
      ...state.trigger,
      ...action.payload,
    },
  }),
  [applyThemeSettings.type]: (state, action: ReturnType<typeof applyThemeSettings>): LauncherState => {
    return {
      ...state,
      theme: {
        ...state.theme,
        ...action.payload,
      },
    };
  },
  [openOverlay.type]: (state, action: ReturnType<typeof openOverlay>): LauncherState => ({
    ...state,
    overlay: {
      ...state.overlay,
      state: STATE_OPENED,
    },
    discoveryMessage: {
      ...state.discoveryMessage,
      state: STATE_CLOSED,
    },
    discovered: true,
  }),
  [closeOverlay.type]: (state, action: ReturnType<typeof closeOverlay>): LauncherState => ({
    ...state,
    overlay: {
      ...state.overlay,
      state: STATE_CLOSED,
    },
  }),
  [toggleOverlay.type]: (state, action: ReturnType<typeof toggleOverlay>): LauncherState => ({
    ...state,
    overlay: {
      ...state.overlay,
      state: state.overlay.state === STATE_CLOSED ? STATE_OPENED : STATE_CLOSED,
    },
    discoveryMessage: {
      ...state.discoveryMessage,
      state: STATE_CLOSED,
    },
    discovered: true,
  }),
  [openFullscreen.type]: (state, action: ReturnType<typeof openFullscreen>): LauncherState => ({
    ...state,
    overlay: {
      ...state.overlay,
      fullscreen: true,
    },
  }),
  [closeFullscreen.type]: (state, action: ReturnType<typeof closeFullscreen>): LauncherState => ({
    ...state,
    overlay: {
      ...state.overlay,
      fullscreen: false,
    },
  }),
  [toggleFullscreen.type]: (state, action: ReturnType<typeof toggleFullscreen>): LauncherState => ({
    ...state,
    overlay: {
      ...state.overlay,
      fullscreen: !state.overlay.fullscreen,
    },
  }),
  [setForceFullscreen.type]: (state, action: ReturnType<typeof setForceFullscreen>): LauncherState => ({
    ...state,
    overlay: {
      ...state.overlay,
      forceFullscreen: action.payload ?? true,
    },
  }),
  [fetchBanners.fulfilled.type]: (state, action: ReturnType<typeof fetchBanners.fulfilled>): LauncherState => {
    return {
      ...state,
      config: {
        ...state.config,
        banners: action.payload.banners,
      },
      banners: forEachObjectKey(action.payload.banners, filterActiveBanners) as PublicBanners,
    };
  },
  [refreshActiveBannersByType.type]: (state, action: ReturnType<typeof refreshActiveBannersByType>): LauncherState => ({
    ...state,
    banners: {
      ...state.banners,
      [action.payload.type]: filterActiveBanners(state.config.banners[action.payload.type]),
    },
  }),
  [dismissBannerByType.type]: (draft, action) => {
    const bannersByType = draft.banners[action.payload.type];
    const index = bannersByType.findIndex((banner) => banner.id === action.payload.id);
    if (index !== -1) {
      bannersByType.splice(index, 1);
    }
  },
  [openCallout.type]: (state): LauncherState => ({
    ...state,
    calloutMessage: {
      ...state.calloutMessage,
      state: STATE_OPENED,
    },
  }),
  [closeCallout.type]: (state): LauncherState => ({
    ...state,
    calloutMessage: {
      ...state.calloutMessage,
      state: STATE_CLOSED,
    },
  }),
  [toggleCallout.type]: (state): LauncherState => ({
    ...state,
    calloutMessage: {
      ...state.calloutMessage,
      state: state.calloutMessage.state === STATE_CLOSED ? STATE_OPENED : STATE_CLOSED,
    },
  }),
  [openDiscoveryMessage.type]: (state): LauncherState => ({
    ...state,
    discoveryMessage: {
      ...state.discoveryMessage,
      state: STATE_OPENED,
    },
    discovered: true,
  }),
  [closeDiscoveryMessage.type]: (state): LauncherState => ({
    ...state,
    discoveryMessage: {
      ...state.discoveryMessage,
      state: STATE_CLOSED,
    },
  }),
  [toggleDiscoveryMessage.type]: (state): LauncherState => ({
    ...state,
    discoveryMessage: {
      ...state.discoveryMessage,
      state: state.discoveryMessage.state === STATE_CLOSED ? STATE_OPENED : STATE_CLOSED,
    },
  }),
  [openFeaturedItemCallout.type]: (state): LauncherState => ({
    ...state,
    featuredItemCallout: {
      ...state.featuredItemCallout,
      state: STATE_OPENED,
    },
  }),
  [closeFeaturedItemCallout.type]: (state): LauncherState => ({
    ...state,
    featuredItemCallout: {
      ...state.featuredItemCallout,
      state: STATE_CLOSED,
    },
  }),
  [toggleFeaturedItemCallout.type]: (state): LauncherState => ({
    ...state,
    featuredItemCallout: {
      ...state.featuredItemCallout,
      state: state.featuredItemCallout.state === STATE_CLOSED ? STATE_OPENED : STATE_CLOSED,
    },
  }),
});
