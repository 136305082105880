import { Alert } from '@foyyay/flow-elements';
import { deleteAlert } from '@shared/actions/alert';
import { selectAlerts } from '@shared/reducers/alertsReducer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTransition } from 'react-spring';
import styled from 'styled-components';

export const Alerts = (props) => {
  const alerts = useSelector(selectAlerts);
  const dispatch = useDispatch();

  useEffect(() => {
    const timers = alerts.map((alert) => {
      if (alert.deleteAt) {
        return setTimeout(() => dispatch(deleteAlert(alert)), alert.deleteAt - Date.now());
      }
      return undefined;
    });

    return () => timers.forEach((timer) => clearTimeout(timer));
  }, [alerts, dispatch]);

  const transitions = useTransition(alerts, (alert) => alert.id, {
    from: {
      opacity: 0,
      transform: 'translateY(40px)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(40px)',
    },
    config: {
      friction: 13,
      mass: 0.4,
      tension: 250,
      precision: 0.001,
    },
  });

  return (
    <AlertsContainer {...props}>
      {transitions.map(({ item, props, key }) => (
        <Alert
          key={key}
          style={props}
          onClickDismiss={() => dispatch(deleteAlert(item))}
          variant={item.type}
          {...item}
        />
      ))}
    </AlertsContainer>
  );
};

const AlertsContainer = styled.ul`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
  padding: 0 2rem 2rem;
  margin: 0 auto;
  width: 100%;
  max-width: 127.6rem;
  z-index: 2147483010;

  ${Alert} {
    flex: 0 0 auto;
    position: relative;
  }
`;
