import { Header as HeaderElement } from '@foyyay/flow-elements';
import { sleep } from '@nucleus/lib-function';
import { selectIsAuthenticated, selectUserProfile } from '@shared/reducers/user';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

export const SignInProfile = (props) => {
  const [open, setOpen] = useState(true);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    const timer = setTimeout(() => setOpen(false), 3000);
    return () => clearTimeout(timer);
  });

  const handleMouseOver = () => {
    setOpen(true);
  };

  const items = isAuthenticated ? [{ key: 'profile', component: Profile }] : [{ key: 'signin', component: SignIn }];

  const transitions = useTransition(items, (item) => item.key, {
    from: {
      opacity: 0,
    },
    enter: (item) => async (next, cancel) => {
      await sleep(300);
      await next({ opacity: 1 });
    },
    leave: {
      opacity: 0,
    },
    config: {
      tension: 150,
      friction: 20,
      duration: 300,
      ease: 'cubic',
    },
  });

  return (
    <div style={{ pointerEvents: props.processing ? 'none' : 'auto' }}>
      {transitions.map((t) => (
        <animated.div key={t.key} style={t.props}>
          {React.createElement(t.item.component, {
            ...props,
            isAuthenticated: isAuthenticated,
            onMouseOver: handleMouseOver,
            open: open,
          })}
        </animated.div>
      ))}
    </div>
  );
};

SignInProfile.propTypes = {
  processing: PropTypes.bool,
};

const Profile = (props) => {
  const profile = useSelector(selectUserProfile);
  const ref = useRef(profile);

  useEffect(() => {
    ref.current = profile;
  }, [profile]);

  // Prevent flashing when logging out
  props = props.isAuthenticated ? props : ref.current;

  return (
    <PositionedUserProfile>
      <Link to="/account/my-account">
        <HeaderElement.UserProfile
          name={profile.name}
          email={profile.email}
          profileFileUrl={profile.profileFileUrl}
          isVisible={props.open}
          onMouseOver={props.onMouseOver}
        />
      </Link>
    </PositionedUserProfile>
  );
};

const PositionedUserProfile = styled.div`
  margin: 0 1.5rem;
  pointer-events: auto;
`;

const SignIn = (props) => (
  <PositionedSignInLink>
    <HeaderElement.Button as={Link} to="/account/sign-in">
      Sign In
    </HeaderElement.Button>
  </PositionedSignInLink>
);

const PositionedSignInLink = styled.div`
  margin: 0 1.5rem;
`;
