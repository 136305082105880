import React from 'react';
import styled from 'styled-components';
import { X } from './Icons';

const CloseContainer = styled.div.attrs((props) => ({ role: 'button' }))`
  display: inline-block;
  cursor: pointer;
  opacity: 0.5;

  @media (hover: hover) {
    :hover {
      opacity: 1;
    }
  }
`;

const _StyledX = styled(X)`
  color: ${({ theme }) => theme.ButtonClose.Color.Default};
  width: 1.3rem;
`;

const StyledX = _StyledX;

export const ButtonClose = (props) => {
  return (
    <CloseContainer {...props}>
      <StyledX />
    </CloseContainer>
  );
};
