import { ButtonOptionAlternate, ButtonOptionCounter, useButtonOptionCounterState } from '@foyyay/flow-elements';
import { useDelayedBool } from '@shared/hooks/useDelayedBool';
import { withStopPropagation } from '@shared/lib/events';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { deselectOption, selectOption, setOptionQuantity, setSelectedOptions } from '../actions/multipleChoiceActions';
import { formatLimitLabel, haveQuantityRemaining } from '../lib/option';
import { selectSelectedOptions } from '../reducers/multipleChoiceReducer';
import { StepMultipleChoice } from '../StepMultipleChoice';

export const CheckBoxOption = (props) => {
  const { config } = useContext(StepMultipleChoice.Context);

  if (config.allowQuantity === true) {
    return <CheckBoxOptionWithQuantity {...props} />;
  }

  return <CheckBoxOptionWithoutQuantity {...props} />;
};

CheckBoxOption.propTypes = {
  option: PropTypes.object.isRequired,
};

const CheckBoxOptionWithQuantity = (props) => {
  const { dispatch, useSelector } = useContext(StepMultipleChoice.Context);

  const selectedOptions = useSelector(selectSelectedOptions);
  const selectedOption = selectedOptions.find((option) => option.id === props.option.id);

  const [quantityButtonProps, openWithTimeout] = useButtonOptionCounterState(selectedOption?.quantity);
  const clickable = useDelayedBool(!quantityButtonProps.open);

  const checked = selectedOption !== undefined;

  const toggleOption = () => {
    if (checked) {
      dispatch(deselectOption(props.option));
      return;
    }

    dispatch(selectOption({ ...props.option, quantity: 1 }));
  };

  const handleOptionClick = withStopPropagation(() => {
    openWithTimeout();
    toggleOption();
  });

  const handleQuantityClick = withStopPropagation(() => {
    toggleOption();
  });

  const handleQuantityChange = (value) => {
    dispatch(setOptionQuantity(props.option, value));
  };

  const disabled =
    (haveQuantityRemaining(props.option) === false && checked !== true) || (props.maxTotal < 1 && checked !== true);

  const subText = formatLimitLabel(props.option.limitTotal, props.option.limitMaxOriginal, selectedOption?.quantity);

  const limitMax = Math.min(props.option.limitMax ?? Infinity, (selectedOption?.quantity ?? 0) + props.maxTotal);

  return (
    <ButtonOptionAlternate
      alignText={props.alignText}
      checked={checked}
      clickable={clickable && selectedOption?.quantity === undefined}
      disabled={disabled}
      onClick={handleOptionClick}
      subtext={subText}
      right={
        !disabled && (
          <ButtonOptionCounter
            value={selectedOption?.quantity}
            max={limitMax}
            onClick={handleQuantityClick}
            onChange={handleQuantityChange}
            {...quantityButtonProps}
          />
        )
      }
    >
      {props.option.label}
    </ButtonOptionAlternate>
  );
};

const CheckBoxOptionWithoutQuantity = (props) => {
  const { dispatch, useSelector } = useContext(StepMultipleChoice.Context);

  const selectedOptions = useSelector(selectSelectedOptions);
  const selectedOption = selectedOptions.find((option) => option.id === props.option.id);

  const checked = selectedOption !== undefined;

  const handleOptionClick = withStopPropagation(() => {
    if (checked) {
      dispatch(deselectOption(props.option));
      return;
    }

    const next = [...selectedOptions, props.option];
    dispatch(setSelectedOptions(next));
  });

  const disabled = haveQuantityRemaining(props.option) === false || (props.maxTotal < 1) & (checked !== true);

  const subText = formatLimitLabel(props.option.limitTotal, undefined, selectedOption?.quantity);

  return (
    <ButtonOptionAlternate
      alignText={props.alignText}
      disabled={disabled}
      checked={checked}
      subtext={subText}
      onClick={handleOptionClick}
    >
      {props.option.label}
    </ButtonOptionAlternate>
  );
};
