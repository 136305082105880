import { createReducer, createSelector } from '@reduxjs/toolkit';
import { clearPrayerHub, listPrayers, loadPrayerHub, setCurrentPrayer } from '@shared/actions/prayerhub';
import { userSignedOut } from '@shared/actions/user';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { combineReducers } from '@shared/reducers/combineReducers';
import { pick as _pick } from 'lodash';
import { WallPrayer } from 'types/wallprayer';

interface RootState {
  prayerhub: {
    byId: PrayerHubState;
  };
}

export const selectAllPrayerHubs = (state: RootState): PrayerHubState => state.prayerhub.byId;
export const selectAllPrayerHubsShortcodes = createSelector([selectAllPrayerHubs], (prayerhubById) => {
  return Object.keys(prayerhubById).map((id) => prayerhubById[id].config.shortcode);
});

export const selectPrayerHubByShortcode = (state: RootState, prayerhubShortcode: string): PrayerHub | undefined => {
  return Object.values(state.prayerhub.byId).find((prayerhub) => prayerhub.config?.shortcode === prayerhubShortcode);
};
export const selectPrayerHubConfigByShortcode = (state: RootState, prayerhubShortcode: string): PrayerHub['config'] => {
  return selectPrayerHubByShortcode(state, prayerhubShortcode)?.config;
};
export const selectPrayerHubConfigLoadedAtByShortcode = (
  state: RootState,
  prayerhubShortcode: string
): Date | undefined => {
  return selectPrayerHubByShortcode(state, prayerhubShortcode)?.configLoadedAt;
};

export const prayerhubByIdSelector =
  (id: string) =>
  (state: RootState): PrayerHub =>
    state.prayerhub.byId[id];
export const prayerhubConfigByIdSelector =
  (id: string) =>
  (state: RootState): PrayerHub['config'] =>
    state.prayerhub.byId[id]?.config;
export const prayerhubConfigLoadedAtByIdSelector =
  (id: string) =>
  (state: RootState): Date | undefined =>
    state.prayerhub.byId[id]?.configLoadedAt;

export const prayerhubConfigPrayerwallStatusPageByIdSelector =
  (id: string) =>
  (state: RootState): any =>
    state.prayerhub.byId[id]?.config?.prayerwall?.statusPage ?? {};

export const prayerwallByIdSelector =
  (prayerhubId: string, prayerwallId: string) =>
  (
    state: RootState
  ):
    | {
        authorized: boolean;
        config: any;
        cursor: string;
        loadedAt: Date;
        prayerIds: string[];
      }
    | undefined =>
    state.prayerhub.byId[prayerhubId]?.prayerwall?.byId[prayerwallId];

export const accessRequestFlowShortcodeSelector =
  (prayerhubId: string, prayerwallId: string) =>
  (state: RootState): any =>
    state.prayerhub.byId[prayerhubId]?.prayerwall?.byId[prayerwallId]?.config?.accessRequestFlowShortcode;

export const prayerwallStatusPageByIdSelector =
  (prayerhubId: string, prayerwallId: string) =>
  (state: RootState): any =>
    state.prayerhub.byId[prayerhubId]?.prayerwall?.byId[prayerwallId]?.config?.statusPage ?? {};

export const prayerwallAuthorizationByHubIdSelector =
  (prayerhubId: string, prayerwallId: string) =>
  (state: RootState): boolean =>
    state.prayerhub.byId[prayerhubId]?.prayerwall?.byId[prayerwallId]?.authorized ?? true;
export const prayersByHubIdSelector =
  (prayerhubId: string) =>
  (
    state: RootState
  ): {
    [prayerId: string]: WallPrayer;
  } =>
    state.prayerhub.byId[prayerhubId]?.prayers ?? {};

export const prayerByIdSelector =
  (prayerhubId: string, prayerId: string) =>
  (state: RootState): WallPrayer =>
    state.prayerhub.byId[prayerhubId]?.prayers[prayerId];

export const nextPrayerSelector =
  (prayerhubId: string, prayerWallId: string, prayerId: string) =>
  (state: RootState): string | undefined => {
    const prayerwall = prayerwallByIdSelector(prayerhubId, prayerWallId)(state);
    const index = prayerwall?.prayerIds?.findIndex((id) => id === prayerId) ?? -1;
    if (index > -1) {
      return prayerwall?.prayerIds[index + 1];
    }
  };

export const previousPrayerSelector =
  (prayerhubId: string, prayerWallId: string, prayerId: string) =>
  (state: RootState): string | undefined => {
    const prayerwall = prayerwallByIdSelector(prayerhubId, prayerWallId)(state);
    const index = prayerwall?.prayerIds?.findIndex((id) => id === prayerId) ?? -1;
    if (index > 0) {
      return prayerwall?.prayerIds[index - 1];
    }
  };

interface PrayerHub {
  config: any;
  configLoadedAt?: Date;
  prayers: {
    [prayerId: string]: WallPrayer;
  };
  prayerwall?: {
    byId: {
      [prayerwallId: string]: {
        authorized: boolean;
        config: any;
        cursor: string;
        loadedAt: Date;
        prayerIds: string[];
      };
    };
  };
}

interface PrayerHubState {
  [prayerhubId: string]: PrayerHub;
}

const initialPrayerHubState = deepFreeze({
  config: {},
  configLoadedAt: undefined,
  errors: [],
});

const reducer = createReducer<PrayerHubState>({} as PrayerHubState, {
  [clearPrayerHub.type]: (state, action): PrayerHubState => ({
    ...state,
    [action.payload.prayerhub.config.id]: {
      ...initialPrayerHubState,
      ..._pick(state[action.payload.prayerhub.config.id], ['config', 'configLoadedAt']),
    },
  }),
  [loadPrayerHub.fulfilled.type]: (state, action): PrayerHubState => {
    return {
      ...state,
      [action.payload.id]: {
        ...initialPrayerHubState,
        ...state[action.payload.id],
        config: action.payload,
        configLoadedAt: new Date(),
      },
    };
  },
  [listPrayers.fulfilled.type]: (state, action): PrayerHubState => {
    const { prayerhubId, prayerwallId } = action.meta.arg;

    const prayerIds = action.payload.prayers.map((prayer: WallPrayer) => prayer.id);
    const prayersById = action.payload.prayers.reduce((acc: { [prayerId: string]: WallPrayer }, prayer: WallPrayer) => {
      acc[prayer.id] = prayer;
      return acc;
    }, {});

    return {
      ...state,
      [prayerhubId]: {
        ...state[prayerhubId],
        prayerwall: {
          ...state[prayerhubId]?.prayerwall,
          byId: {
            ...state[prayerhubId]?.prayerwall?.byId,
            [prayerwallId]: {
              authorized: true,
              config: action.payload.config,
              cursor: action.payload.cursor,
              loadedAt: new Date(),
              prayerIds: prayerIds,
            },
          },
        },
        prayers: {
          ...state[prayerhubId]?.prayers,
          ...prayersById,
        },
      },
    };
  },
  [listPrayers.rejected.type]: (state, action): PrayerHubState => {
    const { prayerhubId, prayerwallId } = action.meta.arg;

    return {
      ...state,
      [prayerhubId]: {
        ...state[prayerhubId],
        prayerwall: {
          ...state[prayerhubId]?.prayerwall,
          byId: {
            ...state[prayerhubId]?.prayerwall?.byId,
            [prayerwallId]: {
              authorized: false,
              config: action.payload.config,
              loadedAt: new Date(),
            },
          },
        },
      },
    };
  },
  [setCurrentPrayer.type]: (state, action): PrayerHubState => {
    return {
      ...state,
      [action.payload.prayerhubId]: {
        ...state[action.payload.prayerhubId],
        prayerwall: {
          ...state[action.payload.prayerhubId]?.prayerwall,
          byId: {
            ...state[action.payload.prayerhubId]?.prayerwall?.byId,
            [action.payload.prayerwallId]: {
              ...state[action.payload.prayerhubId]?.prayerwall?.byId[action.payload.prayerwallId],
              currentPrayer: action.payload.prayerId,
            },
          },
        },
      },
    };
  },
  [userSignedOut.type]: (state, action): PrayerHubState => {
    return Object.keys(state).reduce((acc, prayerhubId) => {
      return {
        ...acc,
        [prayerhubId]: {
          ...state[prayerhubId],
          ...clearPrayerHubData(),
        },
      };
    }, {});
  },
});

const clearPrayerHubData = () => {
  return {
    config: {},
    configLoadedAt: undefined,
  };
};

const PERSISTENT_KEYS: string[] = [];

const prayerhubByIdReducer = Object.assign(reducer, {
  getPersistentState: (state: PrayerHubState) => {
    const persistent: any = {};
    Object.keys(state).forEach((key) => {
      persistent[key] = _pick(state[key], PERSISTENT_KEYS);
    });
    return persistent;
  },
});

export const prayerhubReducer = combineReducers({
  byId: prayerhubByIdReducer,
});
