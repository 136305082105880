export const COLOR_MODE_LIGHT = 'COLOR_MODE_LIGHT';
export const COLOR_MODE_DARK = 'COLOR_MODE_DARK';
export const COLOR_MODES = Object.freeze([COLOR_MODE_LIGHT, COLOR_MODE_DARK]);
export const MODE = { LIGHT: COLOR_MODE_LIGHT, DARK: COLOR_MODE_DARK };

export const TRIGGER_ICON_NUCLEUS = 'nucleus';
export const TRIGGER_ICON_NEXT_STEPS = 'next-steps';
export const TRIGGER_ICON_FOOT_PRINTS = 'foot-prints';
export const TRIGGER_ICON_PLUS = 'plus';
export const TRIGGER_ICON_GIVE = 'give';
export const TRIGGER_ICON_REBELGIVE = 'rebelgive';
export const TRIGGER_ICON_GIVING_HAND = 'giving-hand';
export const TRIGGER_ICON_GIVING_HEART = 'giving-heart';
