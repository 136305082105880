import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Arrow, Spinner } from './Icons';
import { colorStyles, media, rotate } from './style-utils';

const InlineBlock = styled.div`
  display: inline-block;
`;

const Button = ({ theme, loading, success, ...otherProps }) => <button {...otherProps} />;

const Circle = styled(Button)`
  ${({ theme }) => colorStyles(theme.ButtonCircleArrow.Color)}
  align-items: center;
  border: none;
  border-radius: 50%;
  display: flex;
  height: 5rem;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  width: 5rem;
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1);

  ${media.tabletLandscapeAndUp`
    height: 7rem;
    width: 7rem;
  `};

  :not([disabled]):active {
    transform: scale(0.94);
  }

  & > svg {
    width: 2.1rem;

    ${media.tabletLandscapeAndUp`
      width: 3.6rem;
    `};
  }

  ${({ loading }) => {
    if (loading) {
      return css`
        & > svg {
          width: 3.21rem;
          animation: ${rotate} 800ms linear infinite;
        }

        ${media.tabletLandscapeAndUp`
    & > svg {
    width: 4.5rem;
    }
  `}
      `;
    }
  }}
`;

export const ButtonCircleArrow = (props) => {
  return (
    <InlineBlock>
      <Circle {...props}>
        {!props.loading && <Arrow />}
        {props.loading && <Spinner />}
      </Circle>
    </InlineBlock>
  );
};

ButtonCircleArrow.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
};

ButtonCircleArrow.defaultProps = {
  loading: false,
  success: false,
};
