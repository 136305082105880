import React from 'react';
import styled from 'styled-components';
import { PlusRounded } from './Icons';
import { media } from './style-utils';

const Icon = styled(PlusRounded)``;

const CircleButton = styled.button`
  background: none;
  border-radius: 99rem;
  border: none;
  color: ${({ theme }) => theme.ButtonCirclePlus.Color.Default.Foreground};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  user-select: none;
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  cursor: pointer;

  ::before {
    background-color: ${({ theme }) => theme.ButtonCirclePlus.Color.Default.Background};
    border-radius: 99rem;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (hover: hover) {
    :hover {
      ::before {
        background-color: ${({ theme }) => theme.ButtonCirclePlus.Color.Hover.Background};
      }
    }
  }

  /* Fixme: IOS mobile safari active state */
  :not([disabled]):active {
    transform: scale(0.94);
  }

  :disabled ::before {
    opacity: 0.4;
  }

  width: 3.5rem;
  height: 3.5rem;

  ${Icon} {
    width: 100%;
    height: 100%;
  }

  ${media.tabletLandscapeAndUp`
    width: 4.5rem;
    height: 4.5rem;
  `}
`;

export const ButtonCirclePlus = (props) => {
  return (
    <CircleButton {...props}>
      <Icon />
    </CircleButton>
  );
};
