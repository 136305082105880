import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { SOURCE_TYPES_OFFLINE, STEP_TYPE } from '@shared/constants';
import { FLOW_TYPE_GIVING } from '@shared/constants/flow';
import { TOKEN_EXPIRATION_BUFFER } from '@shared/constants/rebelpay';
import { STATE_AUTHENTICATED, STATE_UNAUTHENTICATED } from '@shared/constants/state';
import { SUBMITTED_AT_WINDOW_MINUTES } from '@shared/constants/submission';
import { NUCLEUS_PLATFORM_API_URL } from '@shared/constants/urls';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { getIntervals, getStartDateExpiresAt, getStartDateOptionsForInterval } from '@shared/lib/interval';
import { richFetch } from '@shared/lib/richFetch';
import { validate } from '@shared/lib/schema';
import {
  selectCompletedStepsByFlowId,
  selectComputedSteps,
  selectDataByFlowIdAndStepId,
  selectFlowByShortcode,
  selectStepByFlowIdAndStepType,
  selectSubmissionData,
  selectTransactionData,
} from '@shared/reducers/flows/flowsByIdReducer';
import { selectAuthState, selectPrimaryAttributes, selectSavedPaymentSources } from '@shared/reducers/user';
import base64url from 'base64url';
import * as dateFns from 'date-fns';
import * as iso8601 from 'iso8601-support';
import * as yup from 'yup';
import { createAlertError } from './alert';
import { getAuthState, initializeUserSession } from './user';

const MAP_AUTH_STATE_TO_CONFIG_PATH = deepFreeze({
  [STATE_AUTHENTICATED]: '/flow/config-authenticated',
  [STATE_UNAUTHENTICATED]: '/flow/config',
});

export const fetchFlowConfig = createAsyncThunk('flow/config/fetch', async (arg, thunkAPI) => {
  const authState = await getAuthState();

  let path = MAP_AUTH_STATE_TO_CONFIG_PATH[authState] + `/${arg.flowShortcode}`;
  if (arg.revision !== undefined) {
    path = [path, `?revision=${arg.revision}`].join('');
  }
  const response = await richFetch('GET', NUCLEUS_PLATFORM_API_URL, path);
  const flowConfig = response.flowConfig;

  // Set preview flag if we're getting the latest revision because we're in preview mode
  const isPreview = arg.revision === 'latest';

  return {
    config: flowConfig,
    isPreview: isPreview,
  };
});

export const initFlow = (flow) => (dispatch) => {
  dispatch(initializeSteps(flow));
  dispatch(initializePostSteps(flow));
  dispatch(clearExpiredPaymentSources(flow));
  dispatch(checkForSavedPaymentSources(flow));
  dispatch(computeAndGoToNextStep(flow));
};

const INIT_ACTION_BY = {
  [STEP_TYPE.PRAYER_PRIVACY]: (flow, step) => (dispatch) => {
    if (step.config.options.length <= 1) {
      dispatch(saveStepData(flow, step, { value: step.config.options[0] }));
      dispatch(completeStep(flow, step));
      dispatch(hideStep(flow, step));
    }
  },
  default: () => () => {},
};

const getStepInit = (step) => INIT_ACTION_BY[step.type] ?? INIT_ACTION_BY.default;

const initializeSteps = (flow) => (dispatch, getState) => {
  const steps = selectComputedSteps(getState(), flow.config.id);
  steps.forEach((step) => dispatch(getStepInit(step)(flow, step)));
};

const INIT_POST_STEP_BY = {
  default: (flow, step) => (dispatch, getState) => {
    // check if post step should be shown based on parent step data
    const parentStepData = selectDataByFlowIdAndStepId(getState(), flow.config.id, step.config.parentStepId);
    if (
      parentStepData?.value !== undefined &&
      parentStepData.value.some((option) => option.id === step.config.parentOptionId)
    ) {
      dispatch(showStep(flow, step));
      return;
    }

    dispatch(hideStep(flow, step));
  },
};

const getPostStepInit = (step) => INIT_POST_STEP_BY[step.type] ?? INIT_POST_STEP_BY.default;

const POST_STEPS = deepFreeze([STEP_TYPE.POST_STEP_TEXT]);

const isPostStep = (step) => POST_STEPS.includes(step.type) === true;

const initializePostSteps = (flow) => (dispatch, getState) => {
  const state = getState();
  const steps = selectComputedSteps(state, flow.config.id);
  steps.filter(isPostStep).forEach((step) => dispatch(getPostStepInit(step)(flow, step)));
};

export const getPostSteps = (step) => {
  if (step.type !== STEP_TYPE.MULTIPLE_CHOICE) {
    return undefined;
  }

  return step.config.options
    .filter((option) => option.postStep?.config?.active === true)
    .map((option) => option.postStep);
};

export const clearAllFlows = createAction('flows/clear');

export const clearFlow = createAction('flow/clear', (flow) => ({
  payload: {
    flow: flow,
  },
}));

/** Clear saved payment sources, if any are expired. Returns the cleared paymentSource */
export const clearExpiredPaymentSources = (flow) => (dispatch, getState) => {
  const steps = selectComputedSteps(getState(), flow.config.id);
  const paymentInfoStep = steps.find((step) => step.type === STEP_TYPE.PAYMENT_INFO);

  if (paymentInfoStep === undefined) {
    return;
  }

  const stepData = selectDataByFlowIdAndStepId(getState(), flow.config.id, paymentInfoStep.id);

  if (stepData.value === undefined) {
    return;
  }

  for (const key of Object.keys(stepData.value)) {
    const paymentSource = stepData.value[key];
    if (paymentSource === undefined) {
      return;
    }

    if (paymentSource.expires <= Date.now() - TOKEN_EXPIRATION_BUFFER) {
      dispatch(
        setStepError(
          flow,
          paymentInfoStep,
          buildStepError(paymentInfoStep, 'Please re-enter your payment info.', {
            label: 'For security, your payment source has expired.',
          })
        )
      );
      dispatch(saveStepData(flow, paymentInfoStep, undefined));
      return paymentSource;
    }
  }
};

// If the user has saved payment sources, and hasn't yet completed payment info step, redirect to payment type step
const checkForSavedPaymentSources = (flow) => (dispatch, getState) => {
  const steps = selectComputedSteps(getState(), flow.config.id);
  const completedSteps = selectCompletedStepsByFlowId(getState(), flow.config.id);

  const paymentInfoStep = steps.find((step) => step.type === STEP_TYPE.PAYMENT_INFO);

  if (paymentInfoStep === undefined) {
    return;
  }

  if (completedSteps.includes(paymentInfoStep.id)) {
    return;
  }

  const paymentTypeStep = steps.find((step) => step.type === STEP_TYPE.PAYMENT_TYPE);

  if (selectSavedPaymentSources(getState()).length > 0) {
    dispatch(saveStepData(flow, paymentTypeStep, undefined));
    dispatch(setStepIncomplete(flow, paymentTypeStep));
  }
};

const COMPLETE_ACTION_BY = {
  [STEP_TYPE.PROFILE]: (flow, step, data) => (dispatch) => {
    dispatch(saveStepData(flow, step, data));
    dispatch(completeStep(flow, step));
    dispatch(getProfileAction(data)(flow, step));
    dispatch(computeAndGoToNextStep(flow));
  },
  [STEP_TYPE.CONTACT]: (flow, step, data) => (dispatch) => {
    dispatch(saveStepData(flow, step, data));
    dispatch(completeStep(flow, step));
    if (step.config.allowMultiple !== true) {
      dispatch(getContactAction(data)(flow, step));
    }
    dispatch(computeAndGoToNextStep(flow));
  },
  [STEP_TYPE.CONDITIONAL_PRESTEP]: (flow, step, data) => (dispatch) => {
    const conditionalStep = flow.config.steps.find((s) => s.id === step.config.conditionalStepId);
    dispatch(saveStepData(flow, step, data));
    dispatch(completeStep(flow, step));

    if (conditionalStep !== undefined) {
      // if answered no, skip the conditional step
      if (data?.value[0]?.value === 'false') {
        const data = {
          skipped: true,
          value: undefined,
        };
        dispatch(saveStepData(flow, conditionalStep, data));
        dispatch(completeStep(flow, conditionalStep));
        dispatch(hideStep(flow, conditionalStep));

        // if conditional step has substeps, skip them
        if (conditionalStep.config.steps?.length > 0) {
          const data = {
            skipped: true,
            value: undefined,
          };
          // if additional contact substep, add special skip attribute
          if (conditionalStep.type === STEP_TYPE.ADDITIONAL_CONTACT) {
            data.skippedByContact = true;
          }
          conditionalStep.config.steps
            .filter((substep) => substep.config.active !== false)
            .forEach((substep) => {
              dispatch(saveStepData(flow, substep, data));
              dispatch(completeStep(flow, substep));
              dispatch(hideStep(flow, substep));
            });
        }

        // if conditional step has post steps, skip them
        const postSteps = getPostSteps(conditionalStep);
        if (postSteps?.length > 0) {
          const data = {
            skipped: true,
            value: undefined,
          };

          postSteps.forEach((postStep) => {
            dispatch(saveStepData(flow, postStep, data));
            dispatch(completeStep(flow, postStep));
            dispatch(hideStep(flow, postStep));
          });
        }
      } else {
        // answered yes, show conditional step
        dispatch(saveStepData(flow, conditionalStep, undefined));
        dispatch(setStepIncomplete(flow, conditionalStep));
        dispatch(showStep(flow, conditionalStep));

        // if conditional step has substeps, show them
        if (conditionalStep.config.steps?.length > 0) {
          conditionalStep.config.steps
            .filter((substep) => substep.config.active !== false)
            .forEach((substep) => {
              dispatch(saveStepData(flow, substep, undefined));
              dispatch(setStepIncomplete(flow, substep));
              dispatch(showStep(flow, substep));
            });
        }

        // if conditional step has post steps, reset them
        if (conditionalStep.type === STEP_TYPE.MULTIPLE_CHOICE) {
          const postSteps = getPostSteps(conditionalStep);
          if (postSteps?.length > 0) {
            postSteps.forEach((postStep) => {
              dispatch(saveStepData(flow, postStep, undefined));
              dispatch(setStepIncomplete(flow, postStep));
              dispatch(showStep(flow, postStep));
            });
          }
        }
      }
    }
    dispatch(computeAndGoToNextStep(flow));
  },
  [STEP_TYPE.PAYMENT_AMOUNT]: (flow, step, data) => (dispatch) => {
    dispatch(saveStepData(flow, step, data));
    dispatch(completeStep(flow, step));

    const paymentSteps = flow.config.steps
      .find((step) => step.type === STEP_TYPE.PAYMENT)
      .config.steps.filter((step) => step.type !== STEP_TYPE.PAYMENT_AMOUNT);

    if (data.skipped === true) {
      paymentSteps.forEach((step) => {
        const data = {
          skipped: true,
          value: undefined,
        };
        dispatch(saveStepData(flow, step, data));
        dispatch(completeStep(flow, step));
        dispatch(hideStep(flow, step));
      });
    } else {
      paymentSteps.forEach((step) => {
        dispatch(saveStepData(flow, step, undefined));
        dispatch(setStepIncomplete(flow, step));
        dispatch(showStep(flow, step));
      });
    }

    dispatch(computeAndGoToNextStep(flow));
  },
  [STEP_TYPE.PAYMENT_TYPE]: (flow, step, data) => (dispatch, getState) => {
    dispatch(saveStepData(flow, step, data));
    dispatch(completeStep(flow, step));

    const steps = selectComputedSteps(getState(), flow.config.id);
    const paymentInfoStep = steps.find((step) => step.type === STEP_TYPE.PAYMENT_INFO);

    const savedPaymentSourceSelected = data.value.source !== undefined;
    const offlineSourceSelected = SOURCE_TYPES_OFFLINE.includes(data.value.sourceType.id);

    if (savedPaymentSourceSelected || offlineSourceSelected) {
      const data = {
        skipped: true,
        value: undefined,
      };
      dispatch(saveStepData(flow, paymentInfoStep, data));
      dispatch(completeStep(flow, paymentInfoStep));
      dispatch(hideStep(flow, paymentInfoStep));
    } else {
      dispatch(saveStepData(flow, paymentInfoStep, undefined));
      dispatch(setStepIncomplete(flow, paymentInfoStep));
      dispatch(showStep(flow, paymentInfoStep));
    }

    dispatch(computeAndGoToNextStep(flow));
  },
  [STEP_TYPE.MULTIPLE_CHOICE]: (flow, step, data) => (dispatch, getState) => {
    dispatch(saveStepData(flow, step, data));
    dispatch(completeStep(flow, step));

    // handle any post steps
    const postStepIds = step.config.options
      .filter((choice) => choice.postStep?.config?.active === true)
      .map((choice) => choice.postStep.id);

    if (postStepIds?.length > 0) {
      const postStepIdsToShow =
        data.value?.filter((choice) => choice.postStep?.id !== undefined)?.map((choice) => choice.postStep.id) ?? [];
      const postStepIdsToHide = postStepIds.filter((postStepId) => !postStepIdsToShow.includes(postStepId));
      const steps = selectComputedSteps(getState(), flow.config.id);

      postStepIdsToHide.forEach((postStepId) => {
        const postStep = steps.find((step) => step.id === postStepId);
        if (postStep !== undefined) {
          dispatch(saveStepData(flow, postStep, undefined));
          dispatch(completeStep(flow, postStep));
          dispatch(hideStep(flow, postStep));
        }
      });

      postStepIdsToShow.forEach((postStepId) => {
        const postStep = steps.find((step) => step.id === postStepId);
        if (postStep !== undefined) {
          dispatch(setStepIncomplete(flow, postStep));
          dispatch(showStep(flow, postStep));
        }
      });
    }

    dispatch(computeAndGoToNextStep(flow));
  },
  default: (flow, step, data) => (dispatch) => {
    dispatch(saveStepData(flow, step, data));
    dispatch(completeStep(flow, step));
    dispatch(computeAndGoToNextStep(flow));
  },
};

const getSaveAndCompleteStepAction = (step) => COMPLETE_ACTION_BY[step.type] ?? COMPLETE_ACTION_BY.default;

export const saveAndCompleteStep = (flow, step, data) => getSaveAndCompleteStepAction(step)(flow, step, data);

export const completeStep = createAction('flow/step/set/complete', (flow, step) => ({
  payload: {
    flow: flow,
    step: step,
  },
}));

export const setStepIncomplete = createAction('flow/step/set/incomplete', (flow, step) => ({
  payload: {
    flow: flow,
    step: step,
  },
}));

export const showStep = createAction('flow/step/show', (flow, step) => ({
  payload: {
    flow: flow,
    step: step,
  },
}));

export const hideStep = createAction('flow/step/hide', (flow, step) => ({
  payload: {
    flow: flow,
    step: step,
  },
}));

export const saveStepData = createAction('flow/step/data/save', (flow, step, data) => ({
  payload: {
    flow: flow,
    step: step,
    data: data,
  },
}));

export const computeAndGoToNextStep = (flow) => (dispatch, getState) => {
  const flowId = flow.config.id;
  const state = getState();
  const steps = selectComputedSteps(state, flowId);
  dispatch(goToNextStep(flow, steps));
};

export const goToNextStep = createAction('flow/step/next', (flow, steps) => ({
  payload: {
    flow: flow,
    steps: steps,
  },
}));

export const goToStep = createAction('flow/step/go', (flow, step) => ({
  payload: {
    flow: flow,
    step: step,
  },
}));

export const setStepError = createAction('flow/step/set/error', (flow, step, error) => ({
  payload: {
    flow: flow,
    step: step,
    error: error,
  },
}));

export const buildStepError = (step, message, args) => ({
  id: step.id,
  type: step.type,
  label: step.config.label,
  message: message,
  ...args,
});

const STEP_DATA_BY = {
  [STEP_TYPE.SMART_NAME]: (primaryAttributes) => {
    const nameIsComplete = validate(
      yup.object({
        nameAttribute: yup.object().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        middleName: yup.string(),
      }),
      primaryAttributes
    );

    if (nameIsComplete === false) {
      return;
    }

    return {
      attribute: primaryAttributes.nameAttribute,
      value: {
        firstName: primaryAttributes.firstName,
        lastName: primaryAttributes.lastName,
        middleName: primaryAttributes.middleName,
      },
    };
  },
  [STEP_TYPE.SMART_EMAIL_ADDRESS]: (primaryAttributes) => {
    if (primaryAttributes.emailAddress === undefined) {
      return;
    }

    return {
      attribute: primaryAttributes.emailAddress,
      value: primaryAttributes.emailAddress,
    };
  },
  [STEP_TYPE.SMART_PHONE]: (primaryAttributes) => {
    if (primaryAttributes.phoneAttribute === undefined) {
      return;
    }

    return {
      attribute: primaryAttributes.phoneAttribute,
      value: primaryAttributes.phoneAttribute?.value?.phone,
    };
  },
  [STEP_TYPE.SMART_PHYSICAL_ADDRESS]: (primaryAttributes) => {
    if (primaryAttributes.physicalAddressAttribute === undefined) {
      return;
    }

    const addressIsComplete = validate(
      yup.object({
        value: yup.object({
          street_address: yup.string().required(),
          street_address_2: yup.string(),
          city: yup.string().required(),
          state: yup.string().required(),
          postal_code: yup.string().required(),
          country: yup.string().required(),
        }),
      }),

      primaryAttributes.physicalAddressAttribute
    );

    if (addressIsComplete === false) {
      return;
    }

    return {
      attribute: primaryAttributes.physicalAddressAttribute,
      value: primaryAttributes.physicalAddressAttribute.value,
    };
  },
  [STEP_TYPE.SMART_BIRTHDAY]: (primaryAttributes) => {
    if (primaryAttributes.birthdayAttribute === undefined) {
      return;
    }

    return {
      attribute: primaryAttributes.birthdayAttribute,
      value: primaryAttributes.birthdayAttribute.value.date,
    };
  },
  [STEP_TYPE.SMART_GENDER]: (primaryAttributes) => {
    if (primaryAttributes.genderAttribute === undefined) {
      return;
    }

    return {
      attribute: primaryAttributes.genderAttribute,
      value: primaryAttributes.genderAttribute.value.choices[0],
    };
  },
};

const getStepData = (step, primaryAttributes) => STEP_DATA_BY[step.type](primaryAttributes);

const useMyProfile = (flow, step) => (dispatch, getState) => {
  const primaryAttributes = selectPrimaryAttributes(getState());

  step.config.steps
    .filter((step) => step.config.active === true)
    .forEach((step) => {
      const data = getStepData(step, primaryAttributes);

      if (data === undefined) {
        return;
      }

      dispatch(saveStepData(flow, step, data));
      dispatch(completeStep(flow, step));
      dispatch(hideStep(flow, step));
    });
};

const reviewAndChoose = (flow, step) => (dispatch, getState) => {
  step.config.steps
    .filter((step) => step.config.active === true)
    .forEach((step) => {
      dispatch(setStepIncomplete(flow, step));
      dispatch(showStep(flow, step));
    });
};

const PROFILE_ACTION_BY = {
  true: useMyProfile,
  false: reviewAndChoose,
};

const getProfileAction = (data) => PROFILE_ACTION_BY[data.useMyProfile] ?? PROFILE_ACTION_BY.false;

const addTheirInformation = (flow, step) => (dispatch) => {
  step.config.steps
    .filter((step) => step.config.active === true)
    .forEach((step) => {
      dispatch(setStepIncomplete(flow, step));
      dispatch(showStep(flow, step));
    });
};
const skipAndContinue = (flow, step) => (dispatch) => {
  step.config.steps
    .filter((step) => step.config.active === true)
    .forEach((step) => {
      const data = {
        skippedByContact: true,
        skipped: true,
        value: undefined,
      };
      dispatch(saveStepData(flow, step, data));
      dispatch(completeStep(flow, step));
      dispatch(hideStep(flow, step));
    });
};

const CONTACT_ACTION_BY = {
  true: addTheirInformation,
  false: skipAndContinue,
};

const getContactAction = (data) => CONTACT_ACTION_BY[data.addTheirInformation] ?? CONTACT_ACTION_BY.false;

export const submitFlow = createAsyncThunk('flow/submit', async ({ flow, history }, thunkAPI) => {
  const { dispatch } = thunkAPI;

  const paymentSource = dispatch(clearExpiredPaymentSources(flow));

  if (paymentSource !== undefined) {
    dispatch(computeAndGoToNextStep(flow));
    return;
  }

  await dispatch(sendFlowSubmission(flow)).then(unwrapResult);

  history.replace('/thank-you');

  // Wait until the route has transitioned
  setTimeout(() => {
    dispatch(clearFlow(flow));
    dispatch(initializeUserSession());
  }, 400);
});

const MAP_AUTH_TO_SUBMISSION_PATH = {
  [STATE_AUTHENTICATED]: '/flow/submit/authenticated',
  [STATE_UNAUTHENTICATED]: '/flow/submit',
};

export const sendFlowSubmission = createAsyncThunk('flow/submission/post', async (flow, thunkAPI) => {
  const state = thunkAPI.getState();

  const authState = selectAuthState(state);
  const path = MAP_AUTH_TO_SUBMISSION_PATH[authState];

  const flowId = flow.config.id;
  const submissionData = selectSubmissionData(state, flowId);

  const body = {
    flow_id: flowId,
    attributes: {
      data: submissionData,
      revision_key: flow.config.revision_key,
      steps: flow.config.steps,
      submitted_at_local: getSubmittedAtISOString(),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };

  if (flow.isPreview === true) {
    body.preview = true;
  }

  const isGivingFlow = flow.config.flow_type === FLOW_TYPE_GIVING;

  if (isGivingFlow === true) {
    body.attributes.transaction = selectTransactionData(state, flowId);
  }

  let response;

  try {
    response = await richFetch('POST', NUCLEUS_PLATFORM_API_URL, path, body);
  } catch (error) {
    const { body, status } = error;

    if (/4\d\d/.test(status) && body.error !== undefined) {
      const rejectedValue = thunkAPI.dispatch(handleErrorResponse(body));
      return thunkAPI.rejectWithValue(rejectedValue);
    }

    thunkAPI.dispatch(handleFetchError(flow));

    throw error;
  }

  return {
    config: response.config,
    submission: response.submission,
  };
});

const STEP_ERROR_BY = {
  [STEP_TYPE.MULTIPLE_CHOICE]: (step) => {
    const reason = (limitMax) => {
      if (limitMax < 1) {
        return `quantity unavailable`;
      }

      return `Max allowed = ${limitMax}`;
    };

    return {
      title: step.label,
      items: step.options?.map((option) => {
        return `Chose: (${option.selected}) ${option.label} — (Reason: ${reason(option.limitMax)})`;
      }),
    };
  },
  [STEP_TYPE.PAYMENT_AMOUNT]: (step) => {
    return {
      title: step.label,
    };
  },
  default: (step) => {
    return {
      title: step.label,
    };
  },
};

const getStepError = (step) => (STEP_ERROR_BY[step.type] ?? STEP_ERROR_BY.default)(step);

const HANDLE_ERROR_BY = {
  schedule: (response) => (dispatch) => {
    const getDetail = (data) => {
      if (data.closed_at) {
        return `Flow closed ${dateFns.format(data.closed_at, `M/dd/yyyy 'at' hh:mmaaa`)}`;
      }

      if (data.opened_at) {
        return `Flow opens at ${dateFns.format(data.opened_at, `M/dd/yyyy 'at' hh:mmaaa`)}`;
      }
    };

    const detail = getDetail(response.error.data);
    dispatch(createAlertError(response.error.message, true, { detail: detail }));

    return {
      config: response.config,
    };
  },
  quantity: (response) => (dispatch) => {
    const getDetail = () => {
      if (response.error.data?.steps !== undefined && response.error.data.steps.length > 0) {
        return `STEP: ${response.error.data.steps[0].config.label} — Quantity Limit Reached`;
      }
    };
    const props = {
      detail: getDetail(),
    };
    dispatch(createAlertError(response.error.message, true, props));

    return {
      config: response.config,
    };
  },
  'Submission Data Error': (response) => (dispatch) => {
    dispatch(
      createAlertError(response.error.message, true, {
        detail: 'You’ll need to review the steps and limits below',
        lists: response.error.data.steps.reduce((acc, step) => [...acc, getStepError(step)], []),
      })
    );

    return {
      config: response.config,
      errors: response.error.data.steps,
    };
  },
  default: (response) => (dispatch) => {
    if (response.error.message !== undefined) {
      dispatch(createAlertError(response.error.message, true));
    }

    return {
      config: response.config,
    };
  },
};

const handleErrorResponse = (response) => (dispatch) => {
  const handler = HANDLE_ERROR_BY[response.error.type] ?? HANDLE_ERROR_BY.default;
  return dispatch(handler(response));
};

const FetchErrorMap = {
  [STATE_AUTHENTICATED]: {
    [FLOW_TYPE_GIVING]:
      'Sorry, there was an unknown error, please check your account or email inbox to determine if your gift was successful, before trying to give again.',
    default:
      'Sorry, there was an unknown error, please check your account or email inbox to determine if your submission was successful, before trying again',
  },
  [STATE_UNAUTHENTICATED]: {
    [FLOW_TYPE_GIVING]:
      'Sorry, there was an unknown error, please contact the church to determine if your gift was successful, before trying to give again.',
    default:
      'Sorry, there was an unknown error, please contact the church to determine if your submission was successful, before trying again',
  },
};

const handleFetchError = (flow) => (dispatch, getState) => {
  const authState = selectAuthState(getState());
  const message = FetchErrorMap[authState][flow.config.flow_type] ?? FetchErrorMap[authState]['default'];
  dispatch(createAlertError(message, true));
};

const getSubmittedAtISOString = (submittedAt) => {
  const now = new Date();
  const windowOpenedAt = dateFns.subMinutes(now, SUBMITTED_AT_WINDOW_MINUTES);

  submittedAt = submittedAt || now;
  submittedAt = new Date(submittedAt);

  // if `now` is outside the window of the given at date and the end of the window, just use now.
  if (submittedAt < windowOpenedAt || submittedAt > now) {
    submittedAt = now;
  }

  return iso8601.toISOStringWithOffset(submittedAt);
};

export function setFlow() {
  throw new Error('setFlow is deprecated!');
}

export const requestReceipt = (flow, submission, emailAddress, name) => async (dispatch) => {
  const body = {
    emailAddress: emailAddress,
    flow: {
      id: flow.id,
    },
    name: name,
    submission: submission,
  };

  await richFetch('POST', NUCLEUS_PLATFORM_API_URL, '/flow/requestreceipt', body);
};

export const claimSubmission = (submission) => async (dispatch) => {
  const body = {
    submission: submission,
  };

  await richFetch('POST', NUCLEUS_PLATFORM_API_URL, '/flow/claimsubmission', body);
};

const applyFlowData = (flow, data) => (dispatch, getState) => {
  flow.config.steps.forEach((step) => {
    const state = getState();
    const stepData = data.find((e) => e.id === step.id)?.value;
    const validStepData = convertParamDataToValidStepData(step, flow.config, stepData, state);
    if (validStepData !== undefined) {
      dispatch(saveStepData(flow, step, validStepData));
      dispatch(completeStep(flow, step));
    }
  });
};

const PARAM_TO_STEP_DATA_BY_TYPE = {
  [STEP_TYPE.GIVING_DESIGNATION]: (step, flowConfig, stepData) => {
    const option = step.config?.options?.find((option) => stepData === option.id);
    if (option === undefined) {
      return;
    }
    return [option]; // MultipleChoice step expects array
  },
  [STEP_TYPE.PAYMENT_SCHEDULE_INTERVAL]: (step, flowConfig, stepData) => {
    const options = getIntervals();
    const option = options?.find((option) => stepData === option.label);
    if (option === undefined) {
      return;
    }
    return option;
  },
  [STEP_TYPE.PAYMENT_SCHEDULE_START_DATE]: (step, flowConfig, stepData, state) => {
    const intervalStep = selectStepByFlowIdAndStepType(state, flowConfig.id, STEP_TYPE.PAYMENT_SCHEDULE_INTERVAL);
    const intervalStepData = selectDataByFlowIdAndStepId(state, flowConfig.id, intervalStep.id);
    if (intervalStepData?.value === undefined) {
      return;
    }
    const options = getStartDateOptionsForInterval(intervalStepData.value);
    const option = options?.find((option) => stepData === option.label);
    if (option === undefined) {
      return;
    }
    return {
      ...option,
      expiresAt: getStartDateExpiresAt(),
    };
  },
};

const convertParamDataToValidStepData = (step, flowConfig, stepData, state) => {
  if (stepData === undefined) {
    return;
  }
  const func = PARAM_TO_STEP_DATA_BY_TYPE[step.type];
  if (func) {
    const value = func(step, flowConfig, stepData, state);
    if (value === undefined) {
      return;
    }
    return {
      value: value,
    };
  }

  return {
    value: stepData,
  };
};

export function applyLinkParametersToFlow(flowShortcode, linkParams) {
  return function (dispatch, getState) {
    const flow = selectFlowByShortcode(getState(), flowShortcode);
    if (linkParams?.nldata === undefined) {
      return;
    }

    const parsedData = JSON.parse(base64url.decode(linkParams.nldata));

    dispatch(applyFlowData(flow, parsedData));
  };
}
