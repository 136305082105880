import { font, getThemeValue, media } from '@foyyay/flow-elements';
import { Button } from '@shared/components/Button';
import { RenderBlocker } from '@shared/components/RenderBlocker';
import { StatusPage } from '@shared/components/StatusPage/StatusPage';
import { useDimensions } from '@shared/hooks/useDimensions';
import { fromBase64 } from '@shared/lib/encoding';
import { prayerByIdSelector } from '@shared/reducers/prayerhub';
import Color from 'color';
import * as dateFns from 'date-fns';
import { partial as _partial } from 'lodash';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Footer } from '../../components/Footer';
import { PrayButton } from '../../components/PrayButton';
import { PrayCardNoticeReminder, PrayCardNoticeUpdated } from '../../components/PrayerCardNotices';
import { PrayerDetailLayout } from '../../components/PrayerDetailLayout';
import { PrevNextPrayerButtons } from '../../components/PrevNextPrayerButtons';
import { CUSTOM_LOCALE } from '../../constants/locale';
import { PrayerDetailContext } from '../../context/PrayerDetailContext';
import { PrayerHubContext } from '../../context/PrayerHubContext';

const getPrayerCardThemeValue = _partial(getThemeValue, 'PrayerCard.Color.Default');

export function PrayerDetail(props) {
  const { currentPrayerHubId } = useContext(PrayerHubContext);
  const currentPrayerId = useRouteMatch().params.id;
  const history = useHistory();
  const prayer = useSelector(prayerByIdSelector(currentPrayerHubId, currentPrayerId));

  const context = {
    prayer: prayer,
    goToPrayer: (id) => history.replace(`/prayer/${id}`),
  };

  return (
    <PrayerDetailContext.Provider value={context}>
      <RenderBlocker require={[currentPrayerHubId, prayer]} fallback={<StatusPageError />}>
        <PrayerDetailContent />
        <Footer>
          <PrayButton />
          <PrevNextPrayerButtons />
        </Footer>
      </RenderBlocker>
    </PrayerDetailContext.Provider>
  );
}

const StatusPageError = (props) => {
  const { reloadPrayerHub } = useContext(PrayerHubContext);
  const config = {
    status: 'error',
    statusPage: {
      headline: 'Whoops, there was an error loading this Prayer.',
      body: 'Please try reloading the page.',
      actions: [
        {
          label: 'Reload Now',
          priority: 'primary',
          type: 'button',
          value: (e) => {
            e.preventDefault();
            reloadPrayerHub();
          },
        },
      ],
    },
  };
  return <StatusPage status={config.status} {...config.statusPage} />;
};

const PrayerDetailContent = (props) => {
  const { prayer } = useContext(PrayerDetailContext);

  const prayerSubmittedAt = new Date(prayer.submittedAtLocal);
  const prayerSubmittedAtRelative = dateFns.formatRelative(prayerSubmittedAt, Date.now(), { locale: CUSTOM_LOCALE });
  const prayerSubmittedAtFull = dateFns.format(prayerSubmittedAt, "eeee, MMMM d, yyyy 'at' h:mm a");

  const isPrayerUpdated = prayer.bodyUpdatedAt !== undefined && prayer.bodyUpdatedAt !== prayer.created_at;
  const isRemindDateInFuture = prayer.remindAt !== undefined && new Date(prayer.remindAt) > Date.now();

  const showHeader = isPrayerUpdated;
  const showFooter = isRemindDateInFuture;

  const personName =
    prayer?.smartContactData?.name !== undefined && prayer.smartContactData.name.skipped !== true
      ? prayer.smartContactData.name.value.firstName + ' ' + prayer.smartContactData.name.value.lastName
      : undefined;

  return (
    <PrayerDetailLayout>
      <PrayerCard key={prayer.id}>
        {showHeader && (
          <PrayerHeader>
            <PrayCardNoticeUpdated />
          </PrayerHeader>
        )}
        <PrayerBody>
          <PrayerByline>
            <strong title={prayerSubmittedAtFull}>{prayerSubmittedAtRelative}</strong>{' '}
            {personName && `from ${personName}`}
          </PrayerByline>
          <PrayerRequest>{fromBase64(prayer.body)}</PrayerRequest>
        </PrayerBody>
        {showFooter && (
          <PrayerFooter>
            <PrayCardNoticeReminder />
          </PrayerFooter>
        )}
      </PrayerCard>
    </PrayerDetailLayout>
  );
};

const PrayerCard = styled.div`
  background: ${getPrayerCardThemeValue('Background')};
  box-shadow: 0 1px 24px 0 ${getPrayerCardThemeValue('Shadow')};
  border-radius: 1.4rem;
  max-width: 100%;
  width: 56rem;
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PrayerByline = styled.div`
  ${font(10, 'Book', -0.5)}
  opacity: 0.35;

  ${media.tabletLandscapeAndUp`
    ${font(14, 'Book', -0.75)}
  `}
`;

const PrayerBody = styled.div`
  flex: 1 1 auto;
  color: ${getPrayerCardThemeValue('Foreground')};
  padding: 0 2rem;
  display: flex;
  flex-direction: column;

  :first-child {
    padding-top: 2rem;
  }

  :last-child {
    padding-bottom: 2rem;
  }

  ${media.tabletLandscapeAndUp`
    padding: 0 4rem;

    :first-child {
      padding-top: 3rem;
    }

    :last-child {
      padding-bottom: 3rem;
    }
  `}
`;

const PrayerRequestContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 1rem;
  overflow: hidden;
  min-height: 14rem;
`;

const PrayerRequestText = styled.div`
  ${(props) =>
    !props.expanded &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `}

  white-space: pre-line;

  ${font(16, 'Book', -0.75, 20)}

  ::first-line {
    ${font(20, 'Bold', -1, 28)}
  }

  ${media.tabletLandscapeAndUp`
    ${font(20, 'Book', -1, 24)}

    ::first-line {
      ${font(24, 'Bold', -1.25, 32)}
    }
  `}
`;

const PrayerRequest = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [textRef, textDimensions] = useDimensions();
  const [containerRef, containerDimensions] = useDimensions();

  const isPrayerRequestFullyVisible = textDimensions.height <= containerDimensions.height;
  const showContinueReadingButton = !expanded && !isPrayerRequestFullyVisible;

  return (
    <PrayerRequestContainer ref={containerRef} expanded={expanded}>
      <PrayerRequestText ref={textRef} expanded={expanded}>
        {props.children}
      </PrayerRequestText>
      {showContinueReadingButton && (
        <ContinueReadingButtonContainer>
          <ContinueReadingButtonTextFade />
          <ContinueReadingButtonBackdrop>
            <ContinueReadingButton size="medium" shape="pill" variant="secondary" onClick={() => setExpanded(true)}>
              Continue Reading
            </ContinueReadingButton>
          </ContinueReadingButtonBackdrop>
        </ContinueReadingButtonContainer>
      )}
    </PrayerRequestContainer>
  );
};

const ContinueReadingButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const ContinueReadingButtonTextFade = styled.div`
  background: linear-gradient(
    to bottom,
    ${(props) => Color(props.theme.PrayerCard.Color.Default.Background).alpha(0).string()} 0%,
    ${getPrayerCardThemeValue('Background')} 100%
  );
  height: 14rem;
  width: 100%;
`;

const ContinueReadingButtonBackdrop = styled.div`
  background: ${getPrayerCardThemeValue('Background')};
`;

const ContinueReadingButton = styled(Button)`
  width: 100%;
`;

const PrayerHeader = styled.div`
  flex: 0 0 auto;
  padding: 1.2rem 1rem;

  ${media.tabletLandscapeAndUp`
    padding: 2rem;
  `}
`;

const PrayerFooter = styled.div`
  flex: 0 0 auto;
  padding: 1rem 2rem 1.5rem;

  ${media.tabletLandscapeAndUp`
    padding: 1rem 4rem 2.5rem;
  `}
`;
