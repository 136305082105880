import React from 'react';
import styled from 'styled-components';
import { media } from './style-utils';

export const InputCardPlaceholder = (props) => {
  return (
    <GridContainer style={props.style} className={props.className}>
      <CardNumber />
      <FlexContainer>
        <ExpireDate />
        <CardCVV />
        <CardPostalCode />
      </FlexContainer>
    </GridContainer>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 2rem;
  margin-bottom: 3.5rem;

  ${media.tabletLandscapeAndUp`
    grid-row-gap: 1.6rem;
    margin-bottom: 4.9rem;
  `}
`;

const FlexContainer = styled.div`
  display: flex;
`;

const F2Container = styled.div`
  align-items: center;
  display: flex;
  height: 3.6rem;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    height: 4.6rem;
  `}
`;

const F3Container = styled.div`
  align-items: center;
  display: flex;
  height: 2.4rem;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    height: 3rem;
  `}
`;

const CardNumber = (props) => {
  return <F2Container></F2Container>;
};

const ExpireDate = (props) => {
  return <F3Container></F3Container>;
};

const CardCVV = (props) => {
  return <F3Container></F3Container>;
};

const CardPostalCode = (props) => {
  return <F3Container></F3Container>;
};
