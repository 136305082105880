import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Profile } from './Profile';
import { buttonTransitions, font, media } from './style-utils';
import { getThemeValue } from './Theme';

const getScopedThemeValue = _partial(getThemeValue, 'Header.Color');
const getVariantThemeValue =
  (...paths) =>
  (props) =>
    props.variant ? getScopedThemeValue(props.variant, ...paths)(props) : getScopedThemeValue(...paths)(props);

const CloseContainer = styled.div`
  display: inline-block;
  padding: 2.8rem 1rem;
  position: absolute;
  top: 0;
  left: 0;

  ${media.tabletLandscapeAndUp`
    padding: 3rem 1.7rem;
  `}
`;

const StyledButton = styled.span`
  ${font(16, 'Bold', -0.5)}
  background: ${getVariantThemeValue('Default.ButtonBackground')};
  color: ${getVariantThemeValue('Default.Foreground')};
  user-select: none;
  padding: 0 1.5rem;
  height: 4rem;
  opacity: 0.65;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      opacity: 1;
    }
  }

  ${buttonTransitions(['opacity'])}

  ${media.tabletLandscapeAndUp`
    ${font(18, 'Bold', -0.5)}
    align-items: center;
    display: inline-flex;
    border-radius: 200rem;
    height: 5rem;
    padding-left: 2.3rem;
    padding-right: 2.3rem;
  `}
`;

const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

const _Pill = styled.div`
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: 0;
  background: ${getScopedThemeValue('Default.Background')};
  box-shadow: 0 0.2rem 22.1rem ${getScopedThemeValue('Default.Shadow')};
  border-radius: 200rem;
  justify-content: flex-end;

  height: 4rem;
  padding: 0.4rem;
  padding-left: 1.6rem;
  padding-right: calc(3rem + 0.4rem);

  /* Fade out on Mobile Only */
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 0.2s cubic-bezier(0, 0, 1, 1);

  ${media.tabletLandscapeAndUp`
    background: none;
    box-shadow: none;

    height: 5rem;
    padding: 0.4rem;
    padding-left: 2rem;
    padding-right: calc(4.2rem + 0.4rem);

    opacity: 1;
  `}
`;

const Pill = _Pill;

const UserContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
  max-width: 12.5rem;

  > span {
    /* Override lineheight to ensure descenders are not cut off */
    line-height: 1.2em;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${media.tabletLandscapeAndUp`
    margin-right: 0.7rem;
    opacity: 0.8;
  `}
`;

const _Name = styled.span`
  ${font(12, 'Bold', -0.5, 12)}
  color: ${getScopedThemeValue('Default.Foreground')};

  ${media.tabletLandscapeAndUp`
    ${font(14, 'Bold', -0.25, 14)};
  `}
`;

const Name = _Name;

const _Email = styled.span`
  ${font(10, 'Book', -0.5, 10)}
  color: ${getScopedThemeValue('Default.Foreground')};

  ${media.tabletLandscapeAndUp`
    ${font(11, 'Book', -0.25, 10)}
  `}
`;

const Email = _Email;

const _ProfileContainer = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: flex-end;
  padding: 0.4rem;
  position: relative;

  ${media.tabletLandscapeAndUp`
    @media (hover: hover) {
      :hover {
        ${UserContainer} {
          opacity: 1;
        }

        ${_Pill} {
          background: ${getScopedThemeValue('Default.Background')};
          box-shadow: 0 0.2rem 22.1rem ${getScopedThemeValue('Default.Shadow')};
        }
      }
    }
  `}
`;

const ProfileContainer = _ProfileContainer;

const UserProfile = (props) => {
  const { name, email, profileFileUrl, ...otherProps } = props;
  return (
    <ProfileContainer {...otherProps}>
      <Pill isVisible={props.isVisible}>
        <UserContainer>
          <Name>{props.name ?? 'Signed In'}</Name>
          <Email>{props.email}</Email>
        </UserContainer>
      </Pill>
      <Profile src={props.profileFileUrl} />
    </ProfileContainer>
  );
};

UserProfile.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  profileFileUrl: PropTypes.string,
  isVisible: PropTypes.bool,
};

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2147483005;
  min-width: 320px;
`;

Header.Button = Button;
Header.CloseContainer = CloseContainer;
Header.UserProfile = UserProfile;
