import { LauncherItem, LauncherSearchbar, media } from '@foyyay/flow-elements';
import { Footer } from '@shared/components/Footer';
import { FlowLayout } from '@shared/components/Layout';
import { selectItems } from '@shared/reducers/launcher';
import { selectMoreItems } from '@shared/reducers/springboard';
import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SpringboardContext } from '../../context/SpringboardContext';

export const Search = (): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const allItems = useSelector(selectItems);
  const moreItems = useSelector(selectMoreItems);

  const { handleClickLauncherItem } = useContext<any>(SpringboardContext);

  // TODO: More robust search filtering
  const searchItems = useMemo(() => {
    if (search.length > 0) {
      return allItems.filter((e) => e.label.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
    return moreItems;
  }, [allItems, moreItems, search]);

  return (
    <>
      <FlowLayout contentPadding={'2.2rem 0 6rem'} showLogo={false}>
        <div>
          <SearchbarContainer>
            <LauncherSearchbar name="search" autoComplete="off" value={search} onChange={setSearch} />
          </SearchbarContainer>
          {searchItems.map((item) => (
            <LauncherItem
              key={item.id}
              variant="secondary"
              onClick={(e) => handleClickLauncherItem(e, item)}
              {...item}
            />
          ))}
        </div>
      </FlowLayout>
      <Footer />
    </>
  );
};

const SearchbarContainer = styled.div`
  margin-bottom: 1.6rem;

  ${media.tabletLandscapeAndUp`
    margin-bottom: 2.4rem;
  `}
`;
