import { STEP_TYPE } from '@shared/constants';
import { PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE, PROFILE_ATTRIBUTE_ENUM_GENDER_MALE } from '@shared/constants/profile';
import { isoStringToAmerican } from '@shared/lib/datetime';
import { partial as _partial } from 'lodash';

const parseName = (steps, contact) => {
  const step = steps.find((step) => step.type === STEP_TYPE.NAME);
  const data = contact.data[step?.id];
  return [data?.value?.firstName, data?.value?.lastName].join(' ');
};

const parseEmailAddress = (steps, contact) => {
  const step = steps.find((step) => step.type === STEP_TYPE.EMAIL_ADDRESS);
  const data = contact.data[step?.id];
  return data?.value;
};

const parsePhone = (steps, contact) => {
  const step = steps.find((step) => step.type === STEP_TYPE.PHONE);
  const data = contact.data[step?.id];
  return data?.value;
};

const parsePhysicalAddress = (steps, contact) => {
  const step = steps.find((step) => step.type === STEP_TYPE.PHYSICAL_ADDRESS);
  const data = contact.data[step?.id];
  return data?.value?.street_address;
};

const parseBirthday = (steps, contact) => {
  const step = steps.find((step) => step.type === STEP_TYPE.BIRTHDAY);
  const data = contact.data[step?.id];
  if (data?.value === undefined) {
    return;
  }
  return isoStringToAmerican(data.value);
};

const GENDER_LABEL_BY = {
  [PROFILE_ATTRIBUTE_ENUM_GENDER_MALE]: 'Male',
  [PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE]: 'Female',
};

const parseGender = (steps, contact) => {
  const step = steps.find((step) => step.type === STEP_TYPE.GENDER);
  const data = contact.data[step?.id];
  return GENDER_LABEL_BY[data?.value];
};

const parse = (steps, contact) => {
  return {
    name: parseName(steps, contact),
    emailAddress: parseEmailAddress(steps, contact),
    phone: parsePhone(steps, contact),
    physicalAddress: parsePhysicalAddress(steps, contact),
    birthday: parseBirthday(steps, contact),
    gender: parseGender(steps, contact),
  };
};

export const buildContactDataParser = (steps) => ({
  parse: _partial(parse, steps),
  parseName: _partial(parseName, steps),
  parseEmailAddress: _partial(parseEmailAddress, steps),
  parsePhone: _partial(parsePhone, steps),
  parsePhysicalAddress: _partial(parsePhysicalAddress, steps),
  parseBirthday: _partial(parseBirthday, steps),
  parseGender: _partial(parseGender, steps),
});
