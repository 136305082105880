import React from 'react';

type ControllerContext = {
  allowSignIn?: boolean;
  allowSignUp?: boolean;
  colorMode?: string;
  forceFullscreen?: (value: any) => void;
  goBack: () => void;
  goToFlow: (flowShortcode: any) => void;
  goToLocation?: (location: any) => void;
  goToPrayerHub: (prayerhubShortcode: any) => void;
  goToSpringboard: () => void;
  goToUrl: (url: any, openInNewTab: any) => void;
  handleClickCloseOverlay?: (e: any, ...args: any[]) => void;
  handleClickFullscreen?: (e: any, ...args: any[]) => void;
  isFullscreen?: boolean | undefined;
  isMobile?: boolean;
  overrideLogoUrl?: string | undefined;
  showBackButton: boolean;
  showCloseButton?: boolean;
  showFullscreenButton?: boolean;
  showSignInProfile?: boolean;
  viewportSize: {
    width: undefined;
    height: undefined;
  };
};

export const ControllerContext = React.createContext<ControllerContext>({} as ControllerContext);
export const useController = (): ControllerContext => React.useContext(ControllerContext);
