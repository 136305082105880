import * as dateFns from 'date-fns';
import React, { useContext } from 'react';
import { CUSTOM_LOCALE } from '../constants/locale';
import { PrayerDetailContext } from '../context/PrayerDetailContext';
import { PrayCardNotice } from './PrayerCardNotice';

export const PrayCardNoticeUpdated = (props) => {
  const { prayer } = useContext(PrayerDetailContext);
  if (prayer?.bodyUpdatedAt === undefined) {
    return null;
  }
  const prayerUpdatedAt = new Date(prayer.bodyUpdatedAt);
  const prayerUpdatedAtRelative = dateFns.formatRelative(prayerUpdatedAt, Date.now(), { locale: CUSTOM_LOCALE });
  const prayerUpdatedAtTime = dateFns.format(prayerUpdatedAt, "'at' h:mmaaa");
  const prayerUpdatedAtFull = dateFns.format(prayerUpdatedAt, "eeee, MMMM d, yyyy 'at' h:mm a");

  return (
    <PrayCardNotice>
      <PrayCardNotice.Text>
        <strong>Updated</strong>{' '}
        <span title={prayerUpdatedAtFull}>
          {prayerUpdatedAtRelative} {prayerUpdatedAtTime}
        </span>
      </PrayCardNotice.Text>
    </PrayCardNotice>
  );
};

export const PrayCardNoticeReminder = (props) => {
  const { prayer } = useContext(PrayerDetailContext);
  const prayerRemindAt = new Date(prayer.remind_at);
  const prayerRemindAtDayOfWeek = dateFns.format(prayerRemindAt, 'eeee,');
  const prayerRemindAtFull = dateFns.format(prayerRemindAt, "MMMM d, yyyy 'at' h:mmaaa");

  return (
    <>
      <PrayCardNotice style={{ marginBottom: '0.5rem' }}>
        <PrayCardNotice.Content>
          <PrayCardNotice.Topline>Requested date and time for prayer</PrayCardNotice.Topline>
          <PrayCardNotice.Text>
            <strong>
              <PrayCardNotice.DesktopOnlySpan>{prayerRemindAtDayOfWeek} </PrayCardNotice.DesktopOnlySpan>
              {prayerRemindAtFull}
            </strong>
          </PrayCardNotice.Text>
        </PrayCardNotice.Content>
        <PrayCardNotice.Button size="small" shape="pill" variant="primary">
          Remind me
        </PrayCardNotice.Button>
      </PrayCardNotice>
      <PrayCardNotice.Postline>One reminder email will be sent.</PrayCardNotice.Postline>
    </>
  );
};
