import Color from 'color';
import ColorCast from 'colorcast';
import { cloneDeep as _cloneDeep } from 'lodash';

const PaletteColors = ['Vibrant2', 'Vibrant1', 'Light', 'Dark1', 'Dark2'] as const;
export type PaletteColor = typeof PaletteColors[number];

export type Palette = Record<PaletteColor, string>;

interface ConfigColor {
  saturation: number;
  value: number;
}

type ConfigHue = Record<PaletteColor, ConfigColor>;
type ConfigPalette = Record<number, ConfigHue>;

const COLOR_CONFIG: ConfigPalette = {
  24: {
    Vibrant2: { saturation: 90, value: 95 },
    Vibrant1: { saturation: 92, value: 100 },
    Light: { saturation: 14, value: 100 },
    Dark1: { saturation: 90, value: 84 },
    Dark2: { saturation: 90, value: 76 },
  },
  60: {
    Vibrant2: { saturation: 95, value: 80 },
    Vibrant1: { saturation: 95, value: 90 },
    Light: { saturation: 10, value: 100 },
    Dark1: { saturation: 95, value: 68 },
    Dark2: { saturation: 95, value: 65 },
  },
  100: {
    Vibrant2: { saturation: 75, value: 75 },
    Vibrant1: { saturation: 75, value: 82 },
    Light: { saturation: 14, value: 100 },
    Dark1: { saturation: 68, value: 66 },
    Dark2: { saturation: 68, value: 59 },
  },
  150: {
    Vibrant2: { saturation: 95, value: 75 },
    Vibrant1: { saturation: 95, value: 85 },
    Light: { saturation: 12, value: 100 },
    Dark1: { saturation: 95, value: 58 },
    Dark2: { saturation: 95, value: 50 },
  },
  192: {
    Vibrant2: { saturation: 95, value: 80 },
    Vibrant1: { saturation: 95, value: 90 },
    Light: { saturation: 10, value: 100 },
    Dark1: { saturation: 95, value: 60 },
    Dark2: { saturation: 95, value: 52 },
  },
  222: {
    Vibrant2: { saturation: 72, value: 90 },
    Vibrant1: { saturation: 72, value: 100 },
    Light: { saturation: 10, value: 100 },
    Dark1: { saturation: 72, value: 78 },
    Dark2: { saturation: 72, value: 68 },
  },
  265: {
    Vibrant2: { saturation: 68, value: 88 },
    Vibrant1: { saturation: 68, value: 100 },
    Light: { saturation: 10, value: 100 },
    Dark1: { saturation: 64, value: 66 },
    Dark2: { saturation: 64, value: 58 },
  },
  310: {
    Vibrant2: { saturation: 78, value: 75 },
    Vibrant1: { saturation: 78, value: 85 },
    Light: { saturation: 12, value: 100 },
    Dark1: { saturation: 68, value: 58 },
    Dark2: { saturation: 68, value: 50 },
  },
  359: {
    Vibrant2: { saturation: 88, value: 82 },
    Vibrant1: { saturation: 88, value: 90 },
    Light: { saturation: 12, value: 100 },
    Dark1: { saturation: 82, value: 70 },
    Dark2: { saturation: 82, value: 62 },
  },
};

const BLACK_WHITE_GREY_CONFIG: ConfigPalette = {
  0: {
    Vibrant2: { saturation: 0, value: 30 },
    Vibrant1: { saturation: 0, value: 40 },
    Light: { saturation: 0, value: 97 },
    Dark1: { saturation: 0, value: 22 },
    Dark2: { saturation: 0, value: 17 },
  },
};

export function createPalette(hexValue: string, paletteVibrancy?: number): Palette {
  const config = isSaturated(hexValue) ? COLOR_CONFIG : BLACK_WHITE_GREY_CONFIG;

  const cast = new ColorCast(config);
  const castPalette = cast.fromColor(hexValue) as Palette;

  return applyPaletteVibrancy(castPalette, paletteVibrancy ?? 1);
}

export function isSaturated(color: string): boolean {
  // This helps us pick out black, white, and shades of grey
  const model = Color(color);
  const saturation = model.saturationl();
  const brightness = model.lightness();
  return saturation > 0 && brightness > 0 && brightness < 100;
}

export const applyPaletteVibrancy = (palette: Palette, paletteVibrancy: number): Palette => {
  const result = _cloneDeep(palette);

  // paletteVibrancy is a 0 - 1 value. paletteDarkness is a 1 - 0 value which is inverted from vibrancy.
  const paletteDarkness = 1 - paletteVibrancy;

  // Arbitrary value that gave good results by eye.
  const BlackenMultiplier = 4;

  PaletteColors.forEach((colorKey) => {
    const color = Color(palette[colorKey]);

    // Low saturation colors will blacken less.
    const colorSaturationFactor = color.saturationl() * 0.01;
    // Low value colors (already dark) will blacken less.
    const colorValueFactor = color.value() * 0.01;
    // The less similar the saturation and value are the
    const closenessFactor = 1 - Math.abs(colorSaturationFactor - colorValueFactor);
    // Low chroma colors (pastels and whites) will blacken less.
    const colorChromaFactor = color.chroma() * 0.01;

    // Multiplied together we get a value by which we will blacken the color.
    const blackenFactor =
      paletteDarkness *
      BlackenMultiplier *
      colorSaturationFactor *
      colorValueFactor *
      closenessFactor *
      colorChromaFactor;
    const colorBlackened = color.blacken(blackenFactor);

    // Use an exponential curve to reduce the saturation more and more the lower the value of paletteVibrancy.
    const saturationFactor = 1 - Math.pow(paletteDarkness, 2);
    const colorDeSaturated = colorBlackened.saturationl(colorBlackened.saturationl() * saturationFactor);

    result[colorKey] = colorDeSaturated.hex();
  });

  return result;
};
