import Color from 'color';
import React from 'react';
import styled from 'styled-components';
import { Person } from './Icons';
import { media } from './style-utils';

const StyledPerson = styled(Person)`
  width: 100%;
  max-height: 100%;
  color: ${({ theme }) => theme.Profile.Color.Default.Foreground};
  position: relative;
  transform: translateY(20%);
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.Profile.Color.Default.Background};
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  border-radius: 99rem;
  border: solid 0.2rem ${({ theme }) => theme.Profile.Color.Default.Foreground};
  border-color: ${({ theme }) => Color(theme.Profile.Color.Default.Foreground).string()};
  height: 3rem;
  position: relative;
  width: 3rem;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    height: 4.2rem;
    width: 4.2rem;
  `}
`;

export const Profile = (props) => {
  return <Circle {...props}>{!props.src && <StyledPerson {...props} />}</Circle>;
};
