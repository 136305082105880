import * as yup from 'yup';

export const yupPrefixedKeySchema = yup
  .string()
  .matches(/^[a-z0-9]+$/)
  .required();

export const yupKeyStringSchema = yup.string().matches(/^[a-z0-9]+_[0-9a-f]{0,32}$/, { excludeEmptyString: true });

export const yupPrefixedKeyStringSchema = (prefix: string): yup.StringSchema => {
  yupPrefixedKeySchema.validateSync(prefix);

  return yup.string().matches(RegExp(`^${prefix}_[0-9a-f]{0,32}$`), { excludeEmptyString: true });
};
