import { ScrollContext } from '@shared/context/ScrollContext';
import { useScrollTopReset } from '@shared/hooks/useScrollTop';
import { getParentWindow } from '@shared/lib/getParentWindow';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { FrameContext } from 'react-frame-component';
import { animated, SetUpdateFn, useSpring } from 'react-spring';
import styled from 'styled-components';

type Context = {
  keyboardOpen: boolean;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  set: SetUpdateFn<any>;
};

type ScrollContext = {
  scrollTop: number;
  scrollTargetProps: any;
};

const Context = React.createContext<Context>({} as Context);

export const AnimatedScrollContainer = (props: any): JSX.Element => {
  const { scrollTargetProps } = useContext(ScrollContext) as ScrollContext;
  useScrollTopReset();

  const ref = useRef<HTMLDivElement | null>(null);

  const [{ scroll }, set, pause] = useSpring(() => ({
    scroll: 0,
    immediate: false,
    reset: true,
    config: {
      friction: 60,
      mass: 1,
      tension: 400,
    },
  })) as any;

  const keyboardOpen = useWindowEvents(pause);

  const context = {
    keyboardOpen: keyboardOpen,
    ref: ref,
    set: set,
  };

  return (
    <Context.Provider value={context}>
      <Container {...props} {...scrollTargetProps} scrollTop={scroll} ref={ref}>
        {props.children}
      </Container>
    </Context.Provider>
  );
};

const useWindowEvents = (pause: (arg: boolean) => void) => {
  const parentWindow = getParentWindow();
  const { window } = useContext(FrameContext);

  const keyboardOpenRef = useRef<boolean>(false);

  useEffect(() => {
    const cancelScroll = () => pause(true);

    // If we detect that Safari has scrolled the parent window in order to open the keyboard, we cancel our scroll.
    const handleDocumentScroll = () => {
      if (parentWindow.scrollY > 0) {
        keyboardOpenRef.current = true;
        cancelScroll();
      }

      if (parentWindow.scrollY === 0) {
        keyboardOpenRef.current = false;
      }
    };

    parentWindow.document.addEventListener('scroll', handleDocumentScroll);
    window?.addEventListener('wheel', cancelScroll);
    window?.addEventListener('touchstart', cancelScroll);

    return () => {
      parentWindow.document.removeEventListener('scroll', handleDocumentScroll);
      window?.removeEventListener('wheel', cancelScroll);
      window?.removeEventListener('touchstart', cancelScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentWindow, pause, window]);

  return keyboardOpenRef.current;
};

export const useScrollToElement = (): ((element: HTMLElement | null) => void) => {
  const { window, document } = useContext(FrameContext);
  const { set, ref, keyboardOpen } = useContext(Context);

  return useCallback(
    (element: HTMLElement | null) => {
      if (element === null || ref.current === null) return;

      const { scrollTop, clientHeight, scrollHeight } = ref.current;
      const { top, height } = element.getBoundingClientRect();

      const viewportHeight = window?.innerHeight ?? document?.documentElement.clientHeight ?? 0;

      let topScalar = 0.5;
      let offset = 0;

      // Check if element fits within the viewport with 16px of padding on top and bottom
      if (height >= viewportHeight - 160) {
        // Align to top
        topScalar = 0;
        offset = 80;
      }

      const offsetTop = scrollTop + top;
      const alignTop = topScalar * height - topScalar * clientHeight;
      const scrollPosition = Math.min(Math.max(offsetTop + alignTop - offset, 0), scrollHeight);

      set({
        scroll: scrollPosition,
        delay: keyboardOpen === true ? 50 : 0,
        from: { scroll: scrollTop },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [set]
  );
};

const Container = styled(animated.div)`
  z-index: 2147483002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
