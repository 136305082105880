import {
  ButtonGroup,
  ButtonGroupCircleX,
  ButtonOptionSecondary,
  InputTime,
  media,
  TimePicker,
} from '@foyyay/flow-elements';
import { TIME_MINUTES, TIME_PERIOD_AM } from '@shared/constants/datetime';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { withStopPropagation } from '@shared/lib/events';
import { convertTime12to24, convertTime24to12 } from '@shared/lib/format';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { DateTimeStepActionButtons } from '../../../components/DateTimeStepActionButtons';
import { SkipOrContinueButton } from '../../../components/StepActionButtons';
import {
  goToNextStep,
  goToPrevStep,
  isTimeValid,
  setCurrentStep,
  setInputMode,
  setTime,
} from '../actions/stepDateTimeActions';
import { INPUT_MODE_PICKER, INPUT_MODE_TEXT } from '../constants';
import { selectDate, selectFirstStep, selectLastStep, selectTime } from '../reducers/stepDateTimeReducer';

export const StepTime = (props) => {
  const StepTimeInput = MAP_INPUT_MODE_TO_COMPONENT[INPUT_MODE_PICKER];
  return <StepTimeInput {...props} />;
};

const StepTimeText = (props) => {
  const { useSelector, dispatch } = props;
  const inputRef = useInputFocusOnceRef();

  const date = useSelector(selectDate);
  const time = useSelector(selectTime);
  const [initialTime, initialTimePeriod] = time ? convertTime24to12(time, true) : ['', TIME_PERIOD_AM];
  const [timeInput, setTimeInput] = useState(initialTime);
  const [timePeriod, setTimePeriod] = useState(initialTimePeriod);

  const isValidTime = timeInput.length === 5;

  const handleChange = (value) => {
    setTimeInput(value);
  };

  const handlePeriodChange = (value) => {
    setTimePeriod(value);
  };

  const handleComplete = () => {
    if (!isValidTime) {
      return;
    }
    const time24hr = convertTime12to24(timeInput, timePeriod);
    dispatch(setTime(time24hr));
    props.onComplete({
      value: {
        date: date,
        time: time24hr,
      },
    });
  };

  const handleCancel = () => {
    dispatch(setCurrentStep('date'));
  };

  const handleChangeInputMode = withStopPropagation(() => dispatch(setInputMode(INPUT_MODE_PICKER)));
  const handleSubmit = useSubmitHandler(handleComplete);
  const handleEnter = useSubmitHandler(handleComplete);
  const handleContinueClick = withStopPropagation(handleComplete);
  const handleCancelClick = withStopPropagation(handleCancel);
  const handleSkipClick = withStopPropagation(() => {
    props.onComplete({
      skipped: true,
      value: undefined,
    });
  });

  return (
    <>
      <form onSubmit={handleSubmit} style={{ marginBottom: '3rem' }}>
        <InputTime
          value={timeInput}
          period={timePeriod}
          onChange={handleChange}
          onPeriodChange={handlePeriodChange}
          onEnter={handleEnter}
          ref={inputRef}
        />
      </form>
      <ButtonOptionSecondary onClick={handleChangeInputMode}>Switch to time picker</ButtonOptionSecondary>
      <ButtonGroup>
        <ButtonGroupCircleX onClick={handleCancelClick} />
        <SkipOrContinueButton
          onClick={handleContinueClick}
          onClickSkip={handleSkipClick}
          readyToContinue={isValidTime}
          required={props.required}
        />
      </ButtonGroup>
    </>
  );
};

const StepTimePicker = (props) => {
  const { useSelector, dispatch } = props;

  const time = useSelector(selectTime);
  const date = useSelector(selectDate);
  const initialTime = time ? stringToTimeObj(time) : { hour: '', minute: '', period: TIME_PERIOD_AM };
  const [timeInput, setTimeInput] = useState(initialTime);

  const isValidTime = timeObjToString(timeInput).length === 5; // HH:MM = 5 character string

  const firstStep = useSelector(selectFirstStep);
  const isFirstStep = props.step === firstStep;
  const lastStep = useSelector(selectLastStep);
  const isLastStep = props.step === lastStep;
  const required = isFirstStep === false || props.required === true;
  const renderBackButton = isFirstStep === false;

  const handleChange = (value) => {
    setTimeInput(value);
  };

  const handleComplete = () => {
    if (!isValidTime) {
      return;
    }
    const time24hr = timeObjToString(timeInput);
    dispatch(setTime(time24hr));
    if (isLastStep) {
      props.onComplete({
        value: {
          time: time24hr,
          date: date,
        },
      });
    } else {
      dispatch(goToNextStep());
    }
  };

  const handleCancel = () => {
    dispatch(goToPrevStep());
  };

  // const handleChangeInputMode = withStopPropagation(() => dispatch(setInputMode(INPUT_MODE_TEXT)));
  const handleContinueClick = withStopPropagation(handleComplete);
  const handleCancelClick = withStopPropagation(handleCancel);
  const handleSkipClick = withStopPropagation(() =>
    props.onComplete({
      skipped: true,
      value: undefined,
    })
  );

  const minuteOptions = useMemo(
    () =>
      props.config.timeLimitType === 'increment' || props.config.timeLimitType === 'increments'
        ? TIME_MINUTES.filter((x) => x % parseInt(props.config.timeIncrement) === 0)
        : TIME_MINUTES,
    [props.config]
  );

  return (
    <>
      <StyledTimePicker value={timeInput} minutes={minuteOptions} onChange={handleChange} />
      {/* <ButtonOptionSecondary onClick={handleChangeInputMode}>Type it in</ButtonOptionSecondary> */}

      <DateTimeStepActionButtons
        onClick={handleContinueClick}
        onClickSkip={handleSkipClick}
        onClickCancel={handleCancelClick}
        readyToContinue={isValidTime}
        required={required}
        renderBackButton={renderBackButton}
      />
    </>
  );
};

const StyledTimePicker = styled(TimePicker)`
  margin-left: -2.2rem;
  margin-right: -2.2rem;
  margin-bottom: 3rem;

  ${media.tabletLandscapeAndUp`
    margin-left: -3.6rem;
    margin-right: -3.6rem;
  `}
`;

const MAP_INPUT_MODE_TO_COMPONENT = {
  [INPUT_MODE_PICKER]: StepTimePicker,
  [INPUT_MODE_TEXT]: StepTimeText,
};

function stringToTimeObj(string) {
  const [time, period] = convertTime24to12(string);
  const timeArr = time.split(':');
  return {
    hour: parseInt(timeArr[0]),
    minute: parseInt(timeArr[1]),
    period: period,
  };
}

function timeObjToString(time) {
  if (!isTimeValid(time)) {
    return '';
  }
  return convertTime12to24(time.hour + ':' + time.minute, time.period);
}
