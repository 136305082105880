export const KeyPrefixAccessList = 'accesslist';
export const KeyPrefixAccessRequest = 'accessrequest';
export const KeyPrefixActionitem = 'actionitem';
export const KeyPrefixAuditLog = 'auditlog';
export const KeyPrefixBanner = 'banner';
export const KeyPrefixBond = 'bond';
export const KeyPrefixBondEvent = 'bondevent';
export const KeyPrefixCampaign = 'campaign';
export const KeyPrefixChurch = 'church';
export const KeyPrefixChurchperson = 'churchperson';
export const KeyPrefixCodesnippet = 'codesnippet';
export const KeyPrefixCollection = 'collection';
export const KeyPrefixColorPalette = 'colorpalette';
export const KeyPrefixContent = 'content';
export const KeyPrefixDeposit = 'deposit';
export const KeyPrefixDesignation = 'designation';
export const KeyPrefixDomain = 'domain';
export const KeyPrefixEmailMessage = 'emailmessage';
export const KeyPrefixEmailToken = 'emailtoken';
export const KeyPrefixFile = 'file';
export const KeyPrefixFlow = 'flow';
export const KeyPrefixFlowUrl = 'flowurl';
export const KeyPrefixFontPack = 'fontpack';
export const KeyPrefixGiftschedule = 'giftschedule';
export const KeyPrefixInfocard = 'infocard';
export const KeyPrefixInvoice = 'invoice';
export const KeyPrefixItem = 'item';
export const KeyPrefixJob = 'job';
export const KeyPrefixLauncher = 'launcher';
export const KeyPrefixLauncherItem = 'launcheritem';
export const KeyPrefixLauncherurl = 'launcherurl';
export const KeyPrefixLeader = 'leader';
export const KeyPrefixList = 'list';
export const KeyPrefixLogincognito = 'logincognito';
export const KeyPrefixMarketingCampaign = 'marketingcampaign';
export const KeyPrefixMediapack = 'mediapack';
export const KeyPrefixMediapost = 'mediapost';
export const KeyPrefixMemorandum = 'memorandum';
export const KeyPrefixMerchant = 'merchant';
export const KeyPrefixMessage = 'message';
export const KeyPrefixNavigationitem = 'navigationitem';
export const KeyPrefixNotification = 'notification';
export const KeyPrefixOrganization = 'organization';
export const KeyPrefixPayment = 'payment';
export const KeyPrefixPerson = 'person';
export const KeyPrefixPrayer = 'prayer';
export const KeyPrefixPrayerhub = 'prayerhub';
export const KeyPrefixPrayerInbox = 'prayerinbox';
export const KeyPrefixPrayerWall = 'prayerwall';
export const KeyPrefixProduct = 'product';
export const KeyPrefixProductgiving = 'productgiving';
export const KeyPrefixRebelPayToken = 'rptoken';
export const KeyPrefixRedirect = 'redirect';
export const KeyPrefixRelation = 'relation';
export const KeyPrefixSection = 'section';
export const KeyPrefixSet = 'set';
export const KeyPrefixSource = 'source';
export const KeyPrefixStatement = 'statement';
export const KeyPrefixStep = 'step';
export const KeyPrefixSubmission = 'submission';
export const KeyPrefixSubscriber = 'subscriber';
export const KeyPrefixSubscription = 'subscription';
export const KeyPrefixTestimony = 'testimony';
export const KeyPrefixTransaction = 'transaction';
export const KeyPrefixTransfer = 'transfer';
