import { StepAlert } from '@foyyay/flow-elements';
import { useThunkReducer } from '@shared/hooks/useThunkReducer';
import { buildConfigValidator } from '@shared/lib/schema';
import React from 'react';
import { FlowStep } from '../../components/FlowStep';
import { AddCustomOption } from './components/AddCustomOption';
import { SelectOptions } from './components/SelectOptions';
import {
  configSchema,
  init,
  multipleChoiceReducer,
  selectCurrentStep,
  selectLabel,
  selectMessage,
} from './reducers/multipleChoiceReducer';

const Context = React.createContext({});

export const StepMultipleChoice = (props) => {
  const initializerArg = { data: props.data };
  const [state, dispatch, useSelector] = useThunkReducer(multipleChoiceReducer, {
    devToolsName: props.devToolsName,
    initializerArg: initializerArg,
    initializer: init,
  });

  const currentStep = useSelector(selectCurrentStep);
  const label = useSelector((state) => selectLabel(state, props.config));
  const message = useSelector((state) => selectMessage(state, props.config, props.data));

  const context = {
    state: state,
    dispatch: dispatch,
    useSelector: useSelector,
    config: props.config,
    error: props.error,
    onComplete: props.completeStep,
  };

  return (
    <FlowStep header={<ErrorAlert error={props.error} />} label={label} message={message}>
      <Context.Provider value={context}>{React.createElement(getStepBy(currentStep, props.config))}</Context.Provider>
    </FlowStep>
  );
};

StepMultipleChoice.Context = Context;

const isConfigValid = buildConfigValidator(configSchema);

StepMultipleChoice.isConfigValid = isConfigValid;

const stepBy = {
  add: AddCustomOption,
  select: SelectOptions,
};

const getStepBy = (currentStep, config) => stepBy[currentStep];

const ErrorAlert = (props) => {
  if (props.error === undefined) {
    return null;
  }

  let message = '';

  if (props.error.message === 'Over limit') {
    message = 'Some choices are no longer available (limit reached)';
  }

  if (props.error.message === 'Step required') {
    message = 'This step is required';
  }

  if (props.error.message === 'Amount invalid') {
    message = 'Some choices are no longer available';
  }

  return (
    <StepAlert label={message} variant="error">
      Please review and modify your choices below to continue.
    </StepAlert>
  );
};
