import { partial as _partial } from 'lodash';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { LauncherItem as TLauncherItem } from 'types/launcher';
import { font, media } from './style-utils';
import { getThemeValue } from './Theme';
import { usePressable } from './usePressable';

const getScopedThemeValue = _partial(getThemeValue, 'LauncherItem');

function getThemeValueByStyle(path: string) {
  return function (props: any) {
    switch (props.variant) {
      case 'primary':
        return getScopedThemeValue('Primary', path)(props);
      case 'secondary':
      default:
        return getScopedThemeValue('Secondary', path)(props);
    }
  };
}

const LAUNCHER_ITEM_BORDER_RADIUS_SMALL = '2.1rem';
const LAUNCHER_ITEM_BORDER_RADIUS_LARGE = '2.1rem';

type Props = {
  className?: string;
  highlightTitle: boolean;
  variant: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
} & Pick<TLauncherItem, 'label' | 'description' | 'emoji'>;

export const LauncherItemLarge = Object.assign(
  React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const [{ scale }, set] = useSpring(() => ({
      scale: 1,
      config: {
        tension: 800,
        friction: 35,
      },
    }));

    const handlePressStart = () => set({ scale: 0.94 });
    const handlePressEnd = () => set({ scale: 1 });

    const bind = usePressable(handlePressStart, handlePressEnd);

    let description = props.description;
    if (description !== undefined && description.length > 90) {
      description = description.slice(0, 100); // trigger line-clamp
    }

    const ariaLabel = [props.emoji ? `${props.emoji} emoji` : undefined, props.label, props.description]
      .filter(Boolean)
      .join('. ');

    return (
      <Wrapper
        className={props.className}
        onClick={props.onClick}
        ref={ref}
        style={{ transform: scale.interpolate((scale) => `scale(${scale})`) }}
        emoji={props.emoji}
      >
        <BoxShadow>
          <Container
            {...bind}
            role="button"
            aria-label={ariaLabel}
            variant={props.variant}
            highlightTitle={props.highlightTitle}
          >
            <InnerContainer>
              <TitleContainer>
                <Title>{props.label}</Title>
                {description && <Description>{description}</Description>}
              </TitleContainer>
            </InnerContainer>
            {props.emoji && <Emoji>{props.emoji}</Emoji>}
          </Container>
        </BoxShadow>
      </Wrapper>
    );
  }),
  {
    defaultProps: {
      highlightTitle: false,
    },
  }
);

function generateBoxShadow(props: any) {
  const offsetX = '0';
  const offsetY = '0.1rem';
  const blurRadius = '3.1rem';
  const color = props.theme.Step.Color.Default.Shadow;

  return `${offsetX} ${offsetY} ${blurRadius} ${color}`;
}

const SIZE_SMALL = '4.8rem';
const SIZE_LARGE = '6rem';

const Emoji = styled.div`
  font-size: 2.7rem;
  line-height: 1em;
  height: ${SIZE_SMALL};
  width: ${SIZE_SMALL};
  border-radius: 50%;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 50%;
    width: 100%;
    border-radius: ${SIZE_LARGE} ${SIZE_LARGE} 0 0;
    z-index: -1;
  }

  ${media.tabletLandscapeAndUp`
    font-size: 3.4rem;
    height: ${SIZE_LARGE};
    width: ${SIZE_LARGE};
  `};
`;

const BoxShadow = styled.div`
  box-shadow: ${generateBoxShadow};
  border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_SMALL};
  position: relative;

  ${media.tabletLandscapeAndUp`
    border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_LARGE};
  `};
`;

const Title = styled.div`
  ${font(24, 'Bold', -1)}

  ${media.tabletLandscapeAndUp`
    ${font(30)}
  `};
`;

const Description = styled.div`
  ${font(14, 'Book', -0.75, 16)}
  margin: 0.5rem auto 0;
  max-width: 33rem;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    ${font(18)}
  `};
`;

const Container = styled.div<{ highlightTitle: boolean; variant: string }>`
  background-color: ${getThemeValueByStyle('Default.Background')};
  border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_SMALL};
  position: relative;
  width: 100%;
  padding: 2.6rem;
  cursor: pointer;

  ${media.tabletLandscapeAndUp`
    border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_LARGE};
    padding: 3.6rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  `};

  ${Title} {
    color: ${(props) =>
      props.highlightTitle === true
        ? getThemeValueByStyle('Hover.Foreground')(props)
        : getThemeValueByStyle('Default.Foreground')(props)};
  }

  ${Description} {
    opacity: 0.65;
  }

  ${Emoji} {
    &:before {
      background-color: ${getThemeValueByStyle('Default.Background')};
    }
  }

  @media (hover: hover) {
    :hover {
      background-color: ${getThemeValueByStyle('Hover.Background')};

      ${Emoji} {
        &:before {
          background-color: ${getThemeValueByStyle('Hover.Background')};
        }
      }

      ${Title} {
        color: ${getThemeValueByStyle('Hover.Foreground')};
      }

      ${Description} {
        color: ${getThemeValueByStyle('Hover.Foreground')};
        opacity: 1;
      }
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  /* height: 5rem; */
  margin-bottom: 0;
  margin-top: 0;

  ${media.tabletLandscapeAndUp`
    /* height: 7.5rem; */
    margin-bottom: 0;
    margin-top: 0;
  `};
`;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  ${Title} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }
`;

const Wrapper = styled(animated.div)<{ emoji?: string }>`
  transform-style: preserve-3d;

  margin-top: ${(props) => (props.emoji ? '2.4rem;' : '0.8rem')};
  margin-bottom: 0.8rem;

  max-width: 32.5rem;
  margin-left: auto;
  margin-right: auto;

  ${media.tabletPortraitAndUp`
    max-width: 40rem;
  `}

  ${media.tabletLandscapeAndUp`
    margin-top:  ${(props: any) => (props.emoji ? '3.6rem;' : '1.2rem')};
    margin-bottom: 1.2rem;
    max-width: 56rem;
  `};
`;
