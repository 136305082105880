import { useScrollToElement } from '@shared/components/AnimatedScrollContainer';
import { ControllerContext } from '@shared/context/ControllerContext';
import { useContext, useEffect, useRef } from 'react';

const isDev = process.env['NODE_ENV'] === 'development';

// @deprecated
export default function useScrollToStepRef(step, currentStep) {
  const ref = useRef(null);

  const scrollToElement = useScrollToElement();

  useEffect(() => {
    if (step === currentStep) {
      scrollToElement(ref.current);
    }

    if (isDev) {
      console.warn('warning: use of deprecated useScrollToStepRef');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, currentStep]);

  return ref;
}

export function useScrollToRef(condition) {
  const ref = useRef(null);

  const scrollToElement = useScrollToElement();

  const { isFullscreen } = useContext(ControllerContext);

  useEffect(() => {
    if (condition && ref.current !== null) {
      scrollToElement(ref.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, isFullscreen]);

  return ref;
}
