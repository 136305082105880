import { getParentWindow } from '@shared/lib/getParentWindow';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { loadGoogleMapsApi } from '../lib/googlemapsapi.js';

export function useAddressPredictions(input) {
  const [predictions, setPredictions] = useState([]);
  const serviceRef = useRef();

  const mountedRef = useRef(true);
  useEffect(() => () => (mountedRef.current = false), []);

  const getPredictions = useCallback(async (input) => {
    const window = getParentWindow();

    const request = {
      input: input,
      types: ['address'],
    };

    try {
      const autocompletePredictions = await getPlacePredictions(request, window, serviceRef);
      mountedRef.current && setPredictions(autocompletePredictions);
    } catch (error) {
      mountedRef.current && setPredictions([]);
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetPredictions = useCallback(debounce(getPredictions, 500), [getPredictions]);

  useEffect(() => {
    if (input) {
      debouncedGetPredictions(input);
    }
  }, [debouncedGetPredictions, input]);

  return predictions;
}

const getPlacePredictions = async (request, window, serviceRef) => {
  await loadGoogleMapsApi(window);

  if (serviceRef.current === undefined) {
    serviceRef.current = new window.google.maps.places.AutocompleteService();
  }

  return await new Promise((resolve, reject) => {
    serviceRef.current.getPlacePredictions(request, (autocompletePredictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        reject(status);
      }

      if (autocompletePredictions === null) {
        reject();
      }

      resolve(autocompletePredictions);
    });
  });
};
