import { ButtonOptionSecondary, ButtonPrimary } from '@foyyay/flow-elements';
import { STEP_TYPE } from '@shared/constants';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { withStopPropagation } from '@shared/lib/events';
import { validateStep } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { FlowStep } from '../../../../components/FlowStep';
import { editContactField, setCurrentStep } from '../actions/stepContactActions';
import { buildContactDataParser } from '../lib/ContactData';
import { selectCurrentContact, selectMessage } from '../reducers/stepMultipleContactReducer';
import { StepMultipleContactContext } from '../StepMultipleContact';

export const StepContactCard = (props) => {
  const { dispatch, useSelector } = useContext(StepMultipleContactContext);

  const message = useSelector((state) => selectMessage(state, props.config, props.data));

  const contact = useSelector(selectCurrentContact);

  const steps = props.config.steps?.filter((step) => validateStep(step) === true && step.config.active === true);

  const parser = buildContactDataParser(steps);
  const parsedContact = parser.parse(contact);

  const handleSave = withStopPropagation(() => dispatch(setCurrentStep('list')));

  return (
    <FlowStep description="" label="Review &amp; edit this person's information?" message={message}>
      {steps.map((step) => (
        <ContactField key={step.id} step={step} contact={contact} parsedContact={parsedContact} dispatch={dispatch} />
      ))}
      <ButtonPrimary style={{ marginTop: '2.5rem' }} onClick={handleSave}>
        or, Save &amp; Continue
      </ButtonPrimary>
    </FlowStep>
  );
};

const ContactField = (props) => {
  const { dispatch } = props;

  const field = getField(props.step);
  const fieldValue = props.parsedContact[field.key] ?? getCustomContactData(props.step, props.contact)?.value;
  const skipped = fieldValue === undefined;
  const badge = skipped ? '+ Add' : field.label;

  const handleClick = withStopPropagation(() => dispatch(editContactField(props.step)));

  return (
    <Field badge={badge} onClick={handleClick}>
      {fieldValue ?? <Placeholder>{field.placeholder}</Placeholder>}
    </Field>
  );
};

const Field = styled(ButtonOptionSecondary)`
  text-align: left;
`;

const Placeholder = styled.span`
  opacity: 0.6;
`;

const FIELD_BY = deepFreeze({
  [STEP_TYPE.NAME]: (step) => ({
    key: 'name',
    label: 'Name',
    placeholder: 'Name (Skipped)',
  }),
  [STEP_TYPE.EMAIL_ADDRESS]: (step) => ({
    key: 'emailAddress',
    label: 'Email',
    placeholder: 'Email (Skipped)',
  }),
  [STEP_TYPE.PHONE]: (step) => ({
    key: 'phone',
    label: 'Phone',
    placeholder: 'Phone (Skipped)',
  }),
  [STEP_TYPE.PHYSICAL_ADDRESS]: (step) => ({
    key: 'physicalAddress',
    label: 'Address',
    placeholder: 'Address (Skipped)',
  }),
  [STEP_TYPE.BIRTHDAY]: (step) => ({
    key: 'birthday',
    label: 'Birthday',
    placeholder: 'Birthday (Skipped)',
  }),
  [STEP_TYPE.GENDER]: (step) => ({
    key: 'gender',
    label: 'Gender',
    placeholder: 'Gender (Skipped)',
  }),
  [STEP_TYPE.CONTACT_TEXT]: (step) => ({
    key: step.id,
    label: step.config.attributeLabel,
    placeholder: `${step.config.attributeLabel} (Skipped)`,
  }),
});

const getField = (step) => FIELD_BY[step.type](step);
const getCustomContactData = (step, contact) => contact.data[step?.id];
