import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import { ObjectShape } from 'yup/lib/object';
import Reference from 'yup/lib/Reference';

export const nullableFields = <T extends yup.ObjectSchema<ObjectShape>>(
  schema: T,
  fieldNames?: Array<keyof T['fields']>
): T => {
  return modifySchemaFields(schema, (schema, fieldName) => {
    if (fieldNames !== undefined && fieldNames.includes(fieldName) !== true) {
      return schema;
    }
    // To allow a field to be nullable still pass validation it must become optional.

    schema = schema.optional().nullable();
    if (schema.type === 'object') {
      schema = schema.default(undefined);
    }
    return schema;
  });
};

export const optionalFields = <T extends yup.ObjectSchema<ObjectShape>>(
  schema: T,
  fieldNames?: Array<keyof T['fields']>
): T => {
  return modifySchemaFields(schema, (schema, fieldName) => {
    if (fieldNames !== undefined && fieldNames.includes(fieldName) !== true) {
      return schema;
    }
    schema = schema.optional();
    if (schema.type === 'object') {
      schema = schema.default(undefined);
    }
    return schema;
  });
};

export const requiredFields = <T extends yup.ObjectSchema<ObjectShape>>(
  schema: T,
  fieldNames?: Array<keyof T['fields']>
): T => {
  return modifySchemaFields(schema, (schema, fieldName) => {
    if (fieldNames !== undefined && fieldNames.includes(fieldName) !== true) {
      return schema;
    }
    return schema.required();
  });
};

export const stripFields = <T extends yup.ObjectSchema<ObjectShape>>(
  schema: T,
  fieldNames: Array<keyof T['fields']>
): T => {
  return modifySchemaFields(schema, (schema, fieldName) => {
    if (fieldNames.includes(fieldName) !== true) {
      return schema;
    }

    // To allow a field to be stripped out and still pass validation it must become optional.
    schema = schema.optional().strip();
    if (schema.type === 'object') {
      schema = schema.default(undefined);
    }
    return schema;
  });
};

export const modifySchemaFields = <T extends yup.ObjectSchema<ObjectShape>>(
  schema: T,
  modifier: <S extends yup.AnySchema>(schema: S, fieldName: string, fieldIndex: number) => S | undefined
): T => {
  const newShape: typeof schema.fields = {};
  const fieldNames = Object.keys(schema.fields);
  fieldNames.forEach((fieldName, fieldIndex) => {
    const keyedSchema = schema.fields[fieldName];

    if (Reference.isRef(keyedSchema)) {
      newShape[fieldName] = keyedSchema;
      return;
    }

    if (isLazy(keyedSchema)) {
      newShape[fieldName] = keyedSchema;
      return;
    }

    const result = modifier(keyedSchema, fieldName, fieldIndex);
    if (result === undefined) {
      return;
    }

    newShape[fieldName] = result;
  });

  return schema.shape(newShape) as T;
};

const isLazy = (field: yup.AnySchema | Lazy<any>): field is Lazy<any> => {
  return field.type === 'lazy';
};

/**
 * Returns a new yup schema as a result of the intersection of keys between schemaA and schemaB.
 * Values are taken from schemaA.
 * */
export const pickIntersectingSchemaFields = <
  T extends yup.ObjectSchema<ObjectShape>,
  U extends yup.ObjectSchema<ObjectShape>,
>(
  schemaA: T,
  schemaB: U
): yup.ObjectSchema<any> => {
  const shapeA = schemaA.fields;
  const shapeB = schemaB.fields;

  const intersectedShape: Record<string, any> = {};

  for (const key in shapeB) {
    if (Object.hasOwn(shapeA, key)) {
      intersectedShape[key] = shapeA[key];
    }
  }

  return yup.object().shape(intersectedShape);
};
