import { media, T3 } from '@foyyay/flow-elements';
import { Footer } from '@shared/components/Footer';
import React from 'react';
import styled from 'styled-components';
import { StatusPageAction } from './components/StatusPageAction';
import { StatusPageLayout } from './components/StatusPageLayout';
import { StatusPageText } from './components/StatusPageText';
import {
  StatusPageActionPriority,
  StatusPageActionType,
  STATUS_PAGE_ACTION_PRIORITY_PRIMARY,
  STATUS_PAGE_ACTION_PRIORITY_SECONDARY,
  STATUS_PAGE_ACTION_PRIORITY_TERTIARY,
} from './constants';

interface Action {
  label: string;
  priority: StatusPageActionPriority;
  type: StatusPageActionType;
  value: any;
  props?: any;
}

export interface StatusPageProps {
  actions?: Action[];
  status: string;
  overline?: React.ReactNode;
  headline: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
}

export const StatusPage = (props: StatusPageProps): JSX.Element => {
  return (
    <>
      <StatusPageLayout>
        {props.overline}
        <StatusPageText headline={props.headline}>{props.body}</StatusPageText>
        {props.actions !== undefined && props.actions.length > 0 && (
          <StatusPageActions>
            {statusPageActionsForStatus(props.status)}
            {statusPageActionsByPriority(props.actions, STATUS_PAGE_ACTION_PRIORITY_PRIMARY)}
            {statusPageActionsByPriority(props.actions, STATUS_PAGE_ACTION_PRIORITY_SECONDARY)}
          </StatusPageActions>
        )}
        <StatusPageFooter>
          {props.footer !== undefined && <T3>{props.footer}</T3>}
          {props.actions !== undefined && props.actions.length > 0 && (
            <T3>{statusPageActionsByPriority(props.actions, STATUS_PAGE_ACTION_PRIORITY_TERTIARY)}</T3>
          )}
        </StatusPageFooter>
      </StatusPageLayout>
      <Footer />
    </>
  );
};

const statusPageActionsByPriority = (actions: Action[], priority: string) => {
  return actions.map((action, index) => {
    if (action.priority !== priority) {
      return null;
    }
    return <StatusPageAction key={index} {...action} />;
  });
};

const MAP_STATUS_PAGE_ACTIONS_TO_STATUS: Record<string, Action[]> = {
  unauthenticated: [
    {
      label: 'Sign in',
      priority: 'primary',
      type: 'launcher',
      value: '/account/sign-in?redirect',
    },
  ],
};

const statusPageActionsForStatus = (status: string) => {
  return MAP_STATUS_PAGE_ACTIONS_TO_STATUS[status]?.map((action, index) => {
    return <StatusPageAction key={index} {...action} />;
  });
};

export const StatusPageActions = styled.div`
  max-width: 44rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;

  ${media.tabletPortraitAndUp`
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  `}
`;

export const StatusPageFooter = styled.div`
  width: 100%;
  text-align: center;

  span {
    opacity: 0.65;
  }

  ${T3} {
    margin-left: auto;
    margin-right: auto;

    &:first-child {
      max-width: 12em;
    }
  }
`;
