export function buildQueryStringParams(params = {}) {
  let queryStringParameters = '';
  const keys = Object.keys(params);
  keys.forEach((value, index) => {
    if (params[value] !== undefined) {
      const start = queryStringParameters.length === 0 ? '?' : '&';
      if (Array.isArray(params[value])) {
        let paramArray = '';
        params[value].forEach((v) => {
          paramArray += `"${v}",`;
        });
        queryStringParameters = `${queryStringParameters}${start}${value}=[${paramArray.slice(0, -1)}]`;
      } else {
        queryStringParameters = `${queryStringParameters}${start}${value}=${params[value]}`;
      }
    }
  });
  return encodeURI(queryStringParameters);
}

export default buildQueryStringParams;
