import { ButtonCircleX, ProgressCircle, font, media } from '@foyyay/flow-elements';
import { useCountDown } from '@shared/hooks/useCountDown';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { Button } from './Button';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@shared/reducers/user';

interface Props {
  headline?: React.ReactNode;
  statement?: React.ReactNode;
}

export const AccountPrompt = (props: Props): JSX.Element | null => {
  const history = useHistory();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isDismissed, setIsDismissed] = useState(false);
  const progress = useCountDownProgress(15 * 1000); // 15 seconds count down

  const isVisible = progress !== 0 && isDismissed === false && isAuthenticated === false;

  return (
    <AnimatedOverlay isVisible={isVisible}>
      <Card>
        <ButtonClose onClick={() => setIsDismissed(true)} />
        <Headline>{props.headline}</Headline>
        <Statement>{props.statement}</Statement>
        <StyledButton
          shape="pill"
          size="small"
          variant="primary"
          iconLeft={<StyledProgress progress={progress} />}
          onClick={() => history.push('/account/sign-in')}
        >
          Continue to Sign In (or Sign Up)
        </StyledButton>
      </Card>
    </AnimatedOverlay>
  );
};

/** Slide up and fade in */
const AnimatedContainer = (props: { children?: React.ReactNode; isVisible: boolean }): JSX.Element => {
  const transitions = useTransition<boolean, any>(props.isVisible, null, {
    from: { opacity: 0, y: 40 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 40 },
    config: { mass: 1, tension: 180, friction: 20 },
  });

  return (
    <>
      {transitions.map(({ item, key, props: { opacity, y } }) => {
        return (
          item && (
            <animated.div
              {...props}
              key={key}
              style={{
                opacity: opacity,
                transform: y.interpolate((y) => `translateY(${y}px)`),
              }}
            />
          )
        );
      })}
    </>
  );
};

const AnimatedOverlay = styled(AnimatedContainer)`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 2rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 2147483030;
`;

const Card = styled.div`
  position: relative;
  background: ${({ theme }) => theme.AccountPrompt.Color.Background};
  box-shadow: 0 2px 221px 0 ${({ theme }) => theme.AccountPrompt.Color.Shadow};
  border-radius: 21px;
  width: 100%;
  max-width: 45rem;
  padding: 3rem 6.6rem 2.1rem 2.5rem;
`;

const ButtonClose = styled(ButtonCircleX)`
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  margin: 2rem;
`;

const Headline = styled.h3`
  ${font(28, 'Bold', -0.5, 28)}
  margin: 0 0 1rem;
  padding: 0;
`;

const Statement = styled.p`
  ${font(18, 'Book', -0.75)}
  margin: 0 0 1.8rem;
  padding: 0;
`;

const StyledButton = styled(Button)`
  > span:first-of-type {
    padding: 0 !important;
  }
`;

const StyledProgress = styled(ProgressCircle)`
  margin: 0.3rem;
  height: 1.8rem;
  width: 1.8rem;

  ${media.tabletLandscapeAndUp`
    margin: 0.4rem;
    height: 2.8rem;
    width: 2.8rem;
  `}
`;

const useCountDownProgress = (duration: number) => {
  return (useCountDown(duration) / duration) * 100;
};
