import { partial as _partial } from 'lodash';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { Button } from './Button';
import { buttonTransitions, font, media } from './style-utils';
import { getThemeValue } from './Theme';
import { usePressable } from './usePressable';

const getScopedThemeValue = _partial(getThemeValue, 'RecentItem.Primary');

const LAUNCHER_ITEM_BORDER_RADIUS_SMALL = '10rem';
const LAUNCHER_ITEM_BORDER_RADIUS_LARGE = '10rem';

interface Props {
  className?: string;
  label: string;
  variant: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const RecentItem = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [{ scale }, set] = useSpring(() => ({
    scale: 1,
    config: {
      tension: 800,
      friction: 35,
    },
  }));

  const handlePressStart = () => set({ scale: 0.94 });
  const handlePressEnd = () => set({ scale: 1 });

  const bind = usePressable(handlePressStart, handlePressEnd);

  return (
    <Wrapper
      className={props.className}
      onClick={props.onClick}
      ref={ref}
      style={{ transform: scale.interpolate((scale) => `scale(${scale})`) }}
    >
      <Container {...bind}>
        <InnerContainer>
          <TitleContainer>
            <Title>{props.label}</Title>
          </TitleContainer>
        </InnerContainer>
      </Container>
    </Wrapper>
  );
});

const Title = styled.span`
  ${font(20, 'Medium', -1)}

  ${media.tabletLandscapeAndUp`
    ${font(26)}
  `};
`;

const Container = styled(Button)`
  background-color: ${getScopedThemeValue('Default.Background')};
  border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_SMALL};

  position: relative;
  width: 100%;

  padding-bottom: 0;
  padding-top: 0;
  padding-left: 2.2rem;
  padding-right: 2.2rem;

  cursor: pointer;

  ${media.tabletLandscapeAndUp`
    border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_LARGE};
    padding-left: 3.6rem;
    padding-right: 3.6rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  `};

  ${buttonTransitions(['background-color'])}

  ${Title} {
    color: ${getScopedThemeValue('Default.Foreground')};
    ${buttonTransitions(['color'])}
  }

  @media (hover: hover) {
    :hover {
      background-color: ${getScopedThemeValue('Hover.Background')};

      ${Title} {
        color: ${getScopedThemeValue('Hover.Foreground')};
      }
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  height: 4.5rem;
  margin-bottom: 0;
  margin-top: 0;

  ${media.tabletLandscapeAndUp`
    height: 6rem;
    margin-bottom: 0;
    margin-top: 0;
  `};
`;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  ${Title} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }
`;

const Wrapper = styled(animated.div)`
  transform-style: preserve-3d;

  margin-top: 0.4rem;
  margin-bottom: 0.4rem;

  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;

  ${media.tabletPortraitAndUp`
    max-width: 36rem;
  `}

  ${media.tabletLandscapeAndUp`
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    max-width: 50rem;
  `};
`;
