import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ButtonCircleX } from './ButtonCircleX';
import { LoadingSpinner } from './LoadingSpinner';
import { colorStyles, font, media } from './style-utils';
import { IconPlaid } from './Icons';

const FatButton = styled.button`
  ${({ theme }) => colorStyles(theme.ButtonPlaid.Color)}
  ${font(22, 'Bold', -0.54)}
  align-items: center;
  border: none;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0;
  outline: none;
  overflow: hidden;
  padding: 0 3rem;
  user-select: none;
  width: 100%;
  height: 10rem;
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1);

  ${media.tabletPortraitAndUp`
    ${font(28, 'Bold', -0.54)}
  `}
  :not([disabled]):active {
    transform: scale(0.94);
  }
`;

const FatContainer = styled.div`
  ${font(28, 'Bold', -0.54)}
  align-items: center;
  background: ${({ theme }) => theme.ButtonPlaid.Color.Linked.Background};
  border-radius: 0.8rem;
  border: 0.3rem solid ${({ theme }) => theme.ButtonPlaid.Color.Linked.Foreground};
  color: ${({ theme }) => theme.ButtonPlaid.Color.Linked.Foreground};
  display: flex;
  flex-direction: column;
  height: 10.8rem;
  justify-content: center;
  margin: 1rem 0;
  outline: none;
  overflow: hidden;
  padding: 0 3rem;
  position: relative;
  user-select: none;
  width: 100%;
`;

const StyledIconPlaid = styled(IconPlaid)`
  position: absolute;
  left: 1.6rem;

  ${media.tabletLandscapeAndUp`
    left: 2.4rem;
  `}
`;

const StyledButtonCircleX = styled(ButtonCircleX)`
  position: absolute;
  left: 1.6rem;

  ${media.tabletLandscapeAndUp`
    left: 2.4rem;
  `}
`;

const T = styled.span`
  ${font(14, 'Book', -0.25, 15)}
  display: block;
`;

export const ButtonPlaid = (props) => {
  const { linked, loading, isAuthenticated, onClick, onClickDisconnect, available, ...otherProps } = props;
  const baseFatButtonStyle = { fontSize: '2.2rem', position: 'relative' };
  return (
    <Fragment>
      {!available && (
        <FatButton onClick={onClick} {...otherProps} style={{ ...baseFatButtonStyle, opacity: '0.3' }}>
          <StyledIconPlaid />
          {!loading && <span>Plaid is currently unavailable</span>}
          {loading && <LoadingSpinner />}
        </FatButton>
      )}
      {available && !linked && (
        <FatButton onClick={onClick} {...otherProps} style={baseFatButtonStyle}>
          <StyledIconPlaid />
          {!loading && <span>Link with Plaid</span>}
          {isAuthenticated === false && <T>(Requires Sign In)</T>}
          {loading && <LoadingSpinner />}
        </FatButton>
      )}
      {available && linked && (
        <FatContainer {...otherProps} style={baseFatButtonStyle}>
          <StyledButtonCircleX onClick={onClickDisconnect} />
          <span>Account Linked!</span>
          <T>{props.children}</T>
        </FatContainer>
      )}
    </Fragment>
  );
};

ButtonPlaid.propTypes = {
  available: PropTypes.bool,
  linked: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onClickDisconnect: PropTypes.func,
};
