import { StatusPage } from '@shared/components/StatusPage/StatusPage';
import { ControllerContext } from '@shared/context/ControllerContext';
import { useTimeout } from '@shared/hooks/useTimeout';
import { selectFlowConfigById } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FlowContext } from '../../..';
import { ProgressCircle } from '@foyyay/flow-elements';
import { useCountDown } from '@shared/hooks/useCountDown';

export const ExternalUrl = () => {
  const { currentFlowId } = useContext(FlowContext);
  const {
    message_thankyou_external_url_title: title,
    message_thankyou_external_url_body: body,
    message_thankyou_external_url: url,
    message_thankyou_external_url_open_in_new_tab: openInNewTab,
  } = useSelector((state) => selectFlowConfigById(state, currentFlowId));
  const { goToUrl } = useContext(ControllerContext);

  const redirectAfterMs = 3000;

  const progress = useCountDownProgress(redirectAfterMs);

  useTimeout(() => goToUrl(url, openInNewTab ?? false), redirectAfterMs);

  return (
    <StatusPage
      overline={
        <ProgressContainer>
          <ProgressCircle progress={progress} />
        </ProgressContainer>
      }
      headline={title}
      body={
        <>
          {body}

          <p>
            <TextLink href={url} target="_blank">
              {url}
            </TextLink>
          </p>
        </>
      }
    />
  );
};

const useCountDownProgress = (duration) => (useCountDown(duration) / duration) * 100;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em;
  line-height: 0;
`;

const TextLink = styled.a`
  text-decoration: underline;
  transition: opacity 150ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
