import React, { useCallback, useContext, useState } from 'react';
import { FlowContext } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { selectSubmissionByFlowId } from '@shared/reducers/flows/flowsByIdReducer';
import { useHistory } from 'react-router-dom';
import { signOut } from '@shared/actions/user';
import invariant from 'invariant';

interface ThankYouControllerContext {
  setUser: React.Dispatch<
    React.SetStateAction<{
      email: any;
      password: string;
      userExists: boolean;
    }>
  >;
  signOut: () => void;
  submission: any;
  user: {
    email: any;
    password: string;
    userExists: boolean;
  };
}

const Context = React.createContext<ThankYouControllerContext>(null!);
Context.displayName = 'ThankYouControllerContext';

export const ThankYouController = (props: { children?: React.ReactNode }) => {
  return <Context.Provider value={useController()}>{props.children}</Context.Provider>;
};

export const useThankYouController = () => {
  invariant(
    useContext(Context) !== null,
    'useThankYouController() may be used only in the context of a <ThankYouController> object'
  );
  return useContext(Context);
};

const useController = (): ThankYouControllerContext => {
  const { currentFlowId } = useContext(FlowContext);
  const submission = useSelector((state) => selectSubmissionByFlowId(state, currentFlowId)) ?? {};

  const initialState = {
    email: submission.receipt_email ?? '',
    password: '',
    userExists: false,
  };

  const [user, setUser] = useState(initialState);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignOut = useCallback(() => {
    const callback = () => history.replace('/');
    dispatch(signOut(callback));
  }, [dispatch, history]);

  return {
    setUser: setUser,
    signOut: handleSignOut,
    submission: submission,
    user: user,
  };
};
