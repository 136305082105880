import { createPrefixedKey as prefixedKey } from '@nucleus/ncu-key-prefix';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { useThunkReducer } from '@shared/hooks/useThunkReducer';
import { validateStep } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext } from 'react';
import { getStep } from '../../../index';
import { StepMultipleContactContext } from '../StepMultipleContact';
import { saveAndAddContact, setCurrentStep } from '../actions/stepContactActions';

export const StepAddContactFlow = (props) => {
  const { dispatch } = useContext(StepMultipleContactContext);

  const steps = props.config.steps?.filter((step) => validateStep(step) === true && step.config.active === true);

  const handleComplete = (contact) => {
    dispatch(saveAndAddContact(contact, props.saveStep));
    dispatch(setCurrentStep('list'));
  };

  const handleGoBack = () => {
    dispatch(setCurrentStep('list'));
  };

  return <ContactFlow goBack={handleGoBack} onComplete={handleComplete} steps={steps} />;
};

const ContactFlow = (props) => {
  const [, dispatch, useSelector] = useThunkReducer(contactFlowReducer, {
    devToolsName: props.devToolsName,
    initializerArg: props.steps,
    initializer: init,
  });

  const currentStepId = useSelector(selectCurrentStepId);

  const step = props.steps.find((step) => step.id === currentStepId);
  const data = useSelector(selectData);

  const handleComplete = (data) => dispatch(saveAndCompleteStep(props.steps, step, data, props.onComplete));
  const handleGoBack = () => dispatch(goBack(props.steps, step, props.goBack));

  return React.createElement(getStep(step), {
    key: step.id,
    id: step.id,
    type: step.type,
    config: step.config,
    data: data[step.id],
    completeStep: handleComplete,
    goBack: handleGoBack,
  });
};

const isFirstStep = (steps, stepId) => steps.findIndex((step) => step.id === stepId) === 0;
const isLastStep = (steps, stepId) => steps.findIndex((step) => step.id === stepId) === steps.length - 1;

const saveAndCompleteStep = (steps, step, data, onComplete) => async (dispatch, getState) => {
  await dispatch(saveStepData(step, data));
  if (isLastStep(steps, step.id)) {
    return await onComplete({
      data: getState().data,
      id: prefixedKey('contact'),
    });
  }
  await dispatch(goToNextStep(steps));
};

const goBack = (steps, step, onBack) => async (dispatch) => {
  if (isFirstStep(steps, step.id)) {
    return onBack();
  }
  dispatch(goToPreviousStep(steps));
};

const goToNextStep = createAction('ContactFlow/currentStep/next');
const goToPreviousStep = createAction('ContactFlow/currentStep/previous');

const saveStepData = createAction('ContactFlow/step/data/save', (step, data) => ({
  payload: {
    step: step,
    data: data,
  },
}));

const selectCurrentStepId = (state) => state.currentStepId;
const selectData = (state) => state.data;

const initialState = {
  currentStepId: undefined,
  data: {},
};

const init = (steps) => ({
  ...initialState,
  currentStepId: steps[0].id,
});

const contactFlowReducer = createReducer(initialState, {
  [goToNextStep]: (state, action) => {
    const steps = action.payload;
    const nextStep = steps[steps.findIndex((step) => step.id === state.currentStepId) + 1];
    return {
      ...state,
      currentStepId: nextStep.id,
    };
  },
  [goToPreviousStep]: (state, action) => {
    const steps = action.payload;
    const nextStep = steps[steps.findIndex((step) => step.id === state.currentStepId) - 1];
    return {
      ...state,
      currentStepId: nextStep.id,
    };
  },
  [saveStepData]: (state, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        [action.payload.step.id]: action.payload.data,
      },
    };
  },
});
