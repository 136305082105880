import { buildConfigValidator } from '@shared/lib/schema';
import * as yup from 'yup';

const configSchema = {
  label: yup.string().min(3).required(),
  description: yup.string(),
  steps: yup
    .array()
    .of(yup.object())
    .test('is-active', 'At least one step is not active', (value, context) =>
      value?.some((step) => step.config?.active)
    )
    .required(),
};

const isConfigValid = buildConfigValidator(configSchema);

export const StepSmartContact = {};

StepSmartContact.isConfigValid = isConfigValid;
