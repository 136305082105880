import { createReducer, createSelector } from '@reduxjs/toolkit';
import { clearCurrentUser, setCurrentUser, setSavedSources, userSignedOut } from '@shared/actions/user';
import {
  PROFILE_ATTRIBUTE_DATE_BIRTHDATE,
  PROFILE_ATTRIBUTE_ENUM_GENDER,
  PROFILE_ATTRIBUTE_TYPE_ADDRESS,
  PROFILE_ATTRIBUTE_TYPE_DATE,
  PROFILE_ATTRIBUTE_TYPE_ENUM,
  PROFILE_ATTRIBUTE_TYPE_FILE,
  PROFILE_ATTRIBUTE_TYPE_NAME,
  PROFILE_ATTRIBUTE_TYPE_PHONE,
} from '@shared/constants/profile';
import { STATE_AUTHENTICATED, STATE_UNAUTHENTICATED } from '@shared/constants/state';
import { deepFreeze } from '@shared/lib/deepFreeze';

export const selectCurrentUser = (state) => state.user.currentUser;

export const selectIsAuthenticated = createSelector(selectCurrentUser, (currentUser) => currentUser !== undefined);

export const selectAuthState = createSelector(selectIsAuthenticated, (isAuthenticated) => {
  return isAuthenticated ? STATE_AUTHENTICATED : STATE_UNAUTHENTICATED;
});

export const selectSavedPaymentSources = (state) => state.user.savedPaymentSources;

export const selectLoginEmail = (state) => state.user.currentUser?.login;

export const selectEmails = (state) => selectCurrentUser(state)?.emails ?? [];

export const selectAttributes = (state) => selectCurrentUser(state)?.attributes ?? [];

export const selectNameAttribute = createSelector(selectAttributes, (attributes) => {
  return attributes.find((attribute) => attribute.type === PROFILE_ATTRIBUTE_TYPE_NAME);
});

export const selectFirstName = createSelector(selectNameAttribute, (name) => name?.value?.first_name);
export const selectMiddleName = createSelector(selectNameAttribute, (name) => name?.value?.middle_name);
export const selectLastName = createSelector(selectNameAttribute, (name) => name?.value?.last_name);

export const selectFullName = createSelector([selectFirstName, selectLastName], (firstName, lastName) => {
  if (firstName || lastName) {
    return [firstName, lastName].filter(Boolean).join(' ');
  }
});

export const selectProfileFileUrl = createSelector(selectAttributes, (attributes) => {
  return attributes.find((attribute) => attribute.type === PROFILE_ATTRIBUTE_TYPE_FILE)?.value?.profile_file_url;
});

export const selectPrimaryEmailAddress = createSelector([selectEmails, selectLoginEmail], (emails, loginEmail) => {
  const primaryEmail = emails.find((email) => email.primary === true && email.verified === true);
  return primaryEmail?.address ?? loginEmail;
});

export const selectEmailAddresses = createSelector(selectEmails, (emails) => emails.map((email) => email.address));

export const selectPhoneAttributes = createSelector(selectAttributes, (attributes) => {
  return attributes.filter((attribute) => attribute.type === PROFILE_ATTRIBUTE_TYPE_PHONE);
});

export const selectPrimaryPhoneAttribute = createSelector(selectPhoneAttributes, (phones) => {
  return phones.find((attribute) => attribute.primary === true) ?? phones[0];
});

export const selectPhysicalAddresseAttributes = createSelector(selectAttributes, (attributes) => {
  return attributes.filter((attribute) => attribute.type === PROFILE_ATTRIBUTE_TYPE_ADDRESS);
});

export const selectPrimaryPhysicalAddressAttribute = createSelector(
  selectPhysicalAddresseAttributes,
  (physicalAddresses) => {
    return physicalAddresses.find((attribute) => attribute.primary === true) ?? physicalAddresses[0];
  }
);

export const selectBirthdayAttribute = createSelector(selectAttributes, (attributes) => {
  return attributes.find(
    (attribute) =>
      attribute.type === PROFILE_ATTRIBUTE_TYPE_DATE && attribute.value?.purpose === PROFILE_ATTRIBUTE_DATE_BIRTHDATE
  );
});

export const selectGenderAttribute = createSelector(selectAttributes, (attributes) => {
  return attributes.find(
    (attribute) =>
      attribute.type === PROFILE_ATTRIBUTE_TYPE_ENUM && attribute.value?.purpose === PROFILE_ATTRIBUTE_ENUM_GENDER
  );
});

export const selectPrimaryAttributes = createSelector(
  [
    selectNameAttribute,
    selectFullName,
    selectFirstName,
    selectLastName,
    selectMiddleName,
    selectPrimaryEmailAddress,
    selectPrimaryPhoneAttribute,
    selectPrimaryPhysicalAddressAttribute,
    selectBirthdayAttribute,
    selectGenderAttribute,
  ],
  (
    nameAttribute,
    name,
    firstName,
    lastName,
    middleName,
    primaryEmailAddress,
    primaryPhoneAttribute,
    primaryPhysicalAddressAttribute,
    birthdayAttribute,
    genderAttribute
  ) => {
    return {
      name: name,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      nameAttribute: nameAttribute,
      emailAddress: primaryEmailAddress,
      phoneAttribute: primaryPhoneAttribute,
      physicalAddressAttribute: primaryPhysicalAddressAttribute,
      birthdayAttribute: birthdayAttribute,
      genderAttribute: genderAttribute,
    };
  }
);

export const selectUserProfile = createSelector(
  [selectFullName, selectLoginEmail, selectProfileFileUrl],
  (name, email, profileFileUrl) => {
    return {
      name: name,
      email: email,
      profileFileUrl: profileFileUrl,
    };
  }
);

const initialState = deepFreeze({
  currentUser: undefined,
  savedPaymentSources: [],
});

export const userReducer = createReducer(initialState, {
  [setCurrentUser]: (state, action) => ({
    ...state,
    currentUser: action.payload,
  }),
  [setSavedSources]: (state, action) => ({
    ...state,
    savedPaymentSources: action.payload,
  }),
  [clearCurrentUser]: (state, action) => initialState,
  [userSignedOut]: (state, action) => initialState,
});
