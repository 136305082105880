import { media } from '@foyyay/flow-elements';
import { StaggeredFade } from '@shared/components/StaggeredFade';
import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

export const PrayerTimeLayout = (props) => {
  return (
    <Body>
      <ScrollContainer>
        <Content>
          <StaggeredFade>
            <LogoPlaceholder />
            <CenterColumn>
              <Container>{props.children}</Container>
            </CenterColumn>
          </StaggeredFade>
        </Content>
      </ScrollContainer>
    </Body>
  );
};

PrayerTimeLayout.propTypes = {};

PrayerTimeLayout.defaultProps = {};

const Body = styled.div`
  position: relative;
  flex: 1;
  text-align: left;
`;

const ScrollContainer = styled.div`
  z-index: 2147483002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  min-width: 320px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  padding-top: max(7rem, 10vh);

  ${media.tabletLandscapeAndUp`
    padding-top: max(7rem, 12vh);
  `}
`;

const CenterColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
`;

const HORIZ_PAD = '3rem';

const Container = styled.div`
  width: 100%;
  max-width: calc(44rem + ${HORIZ_PAD} * 2);
  padding-left: ${HORIZ_PAD};
  padding-right: ${HORIZ_PAD};
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 14rem; // Leave room for the footer

  ${media.tabletLandscapeAndUp`
    max-width: calc(60rem + ${HORIZ_PAD} * 2);
    padding-bottom: 20rem; // Leave room for the footer
  `}
`;

const LogoPlaceholder = styled.div`
  margin-top: 2rem;
`;
