import { partial as _partial } from 'lodash';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { LauncherItem as TLauncherItem } from 'types/launcher';
import { Button } from './Button';
import { buttonTransitions, font, media } from './style-utils';
import { getThemeValue } from './Theme';
import { usePressable } from './usePressable';

const getScopedThemeValue = _partial(getThemeValue, 'LauncherItem');

function getThemeValueByStyle(path: string) {
  return function (props: any) {
    switch (props.variant) {
      case 'primary':
        return getScopedThemeValue('Primary', path)(props);
      case 'secondary':
      default:
        return getScopedThemeValue('Secondary', path)(props);
    }
  };
}

const LAUNCHER_ITEM_BORDER_RADIUS_SMALL = '1.4rem';
const LAUNCHER_ITEM_BORDER_RADIUS_LARGE = '2.1rem';

type Props = {
  className?: string;
  highlightTitle: boolean;
  variant: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
} & Pick<TLauncherItem, 'label'>;

export const LauncherItem = Object.assign(
  React.forwardRef<HTMLDivElement, Props>((props, ref): JSX.Element => {
    const [{ scale }, set] = useSpring(() => ({
      scale: 1,
      config: {
        tension: 800,
        friction: 35,
      },
    }));

    const handlePressStart = () => set({ scale: 0.94 });
    const handlePressEnd = () => set({ scale: 1 });

    const bind = usePressable(handlePressStart, handlePressEnd);

    return (
      <Wrapper
        className={props.className}
        onClick={props.onClick}
        ref={ref}
        style={{ transform: scale.interpolate((scale) => `scale(${scale})`) }}
      >
        <BoxShadow>
          <Container {...bind} variant={props.variant} highlightTitle={props.highlightTitle}>
            <InnerContainer>
              <TitleContainer>
                <Title>{props.label}</Title>
              </TitleContainer>
            </InnerContainer>
          </Container>
        </BoxShadow>
      </Wrapper>
    );
  }),
  {
    defaultProps: {
      highlightTitle: false,
    },
  }
);

function generateBoxShadow(props: any) {
  const offsetX = '0';
  const offsetY = '0.1rem';
  const blurRadius = '3.1rem';
  const color = props.theme.Step.Color.Default.Shadow;

  return `${offsetX} ${offsetY} ${blurRadius} ${color}`;
}

const BoxShadow = styled.div`
  box-shadow: ${generateBoxShadow};
  border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_SMALL};
  position: relative;

  ${media.tabletLandscapeAndUp`
    border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_LARGE};
  `};
`;

const Title = styled.span`
  ${font(22, 'Medium', -1)}

  ${media.tabletLandscapeAndUp`
    ${font(28)}
  `};
`;

const Container = styled(Button)<{ highlightTitle: boolean; variant: string }>`
  background-color: ${getThemeValueByStyle('Default.Background')};
  border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_SMALL};

  position: relative;
  width: 100%;

  padding-bottom: 0;
  padding-top: 0;
  padding-left: 2.2rem;
  padding-right: 2.2rem;

  cursor: pointer;

  ${media.tabletLandscapeAndUp`
    border-radius: ${LAUNCHER_ITEM_BORDER_RADIUS_LARGE};
    padding-left: 3.6rem;
    padding-right: 3.6rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  `};

  ${Title} {
    color: ${(props) =>
      props.highlightTitle === true
        ? getThemeValueByStyle('Hover.Foreground')
        : getThemeValueByStyle('Default.Foreground')};

    ${buttonTransitions(['color'])}
  }

  ${buttonTransitions(['background-color'])}

  @media (hover: hover) {
    :hover {
      background-color: ${getThemeValueByStyle('Hover.Background')};

      ${Title} {
        color: ${getThemeValueByStyle('Hover.Foreground')};
      }
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  height: 5.5rem;
  margin-bottom: 0;
  margin-top: 0;

  ${media.tabletLandscapeAndUp`
    height: 7.5rem;
    margin-bottom: 0;
    margin-top: 0;
  `};
`;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  ${Title} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }
`;

const Wrapper = styled(animated.div)`
  transform-style: preserve-3d;

  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;

  ${media.tabletPortraitAndUp`
    max-width: 36rem;
  `}

  ${media.tabletLandscapeAndUp`
    margin-bottom: 1.2rem;
    margin-top: 1rem;
    max-width: 50rem;
  `};
`;
