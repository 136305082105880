import { ButtonOptionSecondary } from '@foyyay/flow-elements';
import { Select } from '@shared/components/Select';
import { withStopPropagation } from '@shared/lib/events';
import React from 'react';
import { SmartStepActionButtons } from '../../../components/StepActionButtons';
import { clearPhone, setCurrentStep } from '../actions/stepSmartPhoneActions';
import { selectOptions } from '../reducers/stepSmartPhoneReducer';

export const StepSmartPhoneSelect = (props) => {
  const { dispatch, useSelector } = props;

  const options = useSelector(selectOptions);

  const handleChange = (option) => {
    props.onComplete({
      attribute: option.attribute,
      value: option.value,
    });
  };

  const handleClick = withStopPropagation(() => {
    dispatch(clearPhone());
    dispatch(setCurrentStep('input'));
  });

  const handleSkipClick = withStopPropagation(() => {
    props.onComplete({
      skipped: true,
      value: undefined,
    });
  });

  return (
    <>
      <Select options={options} onChange={handleChange} />
      <ButtonOptionSecondary onClick={handleClick}>+ Add new phone</ButtonOptionSecondary>
      <SmartStepActionButtons onSkipClick={handleSkipClick} required={props.required} style={{ marginTop: '3rem' }} />
    </>
  );
};
