import { applyLinkParameters } from '@shared/actions/launcher';
import { initializeUserSession } from '@shared/actions/user';
import { STATE_ERROR, STATE_LOADED, STATE_LOADING } from '@shared/constants/state';
import { parseUrlParams } from '@shared/lib/parseUrlParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';

export const Session = (props) => {
  const [state, setState] = useState(STATE_LOADING);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      const shouldApplyLinkParameters = matchPath(history.location.pathname, { path: '/launcher/:launcherShortcode' });
      try {
        await Promise.all([
          dispatch(initializeUserSession()),
          shouldApplyLinkParameters && dispatch(applyLinkParameters(history, parseUrlParams(window.location))),
        ]);
      } catch (error) {
        console.error(error);
        setState(STATE_ERROR);
        return;
      }
      setState(STATE_LOADED);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state !== STATE_LOADED) {
    return null;
  }

  return props.children;
};
