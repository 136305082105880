import { createReducer, createSelector } from '@reduxjs/toolkit';
import { addToRecentItems, clearRecentItems } from '@shared/actions/springboard';
import {
  LAUNCHER_HOME_MAX_ITEMS,
  LAUNCHER_HOME_RECENT_MAX_ITEMS,
  LAUNCHER_ITEM_TYPE,
  PREFER_LARGER,
} from '@shared/constants/launcher';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { uniqBy as _uniqBy } from 'lodash';
import { LauncherItem } from 'types/launcher';
import { selectAllInProgressFlowsShortcodes } from './flows/flowsByIdReducer';
import { selectItems, selectFeaturedItems } from './launcher';
import { LAUNCHER_ACTION_TYPE } from '@nucleus/launcher';

interface RootState {
  springboard: SpringboardState;
}

export const selectSpringboard = (state: RootState): SpringboardState => state.springboard;

export const selectRecentItems = (state: RootState): LauncherItem[] => selectSpringboard(state).recentItems ?? [];

export const selectVisibleRecentItems = createSelector(
  [selectRecentItems, selectAllInProgressFlowsShortcodes],
  (recentItems, allInProgressFlowsShortcodes) => {
    return recentItems
      .filter(
        (item) =>
          item.type === LAUNCHER_ITEM_TYPE.Button &&
          item.action.type === LAUNCHER_ACTION_TYPE.Flow &&
          allInProgressFlowsShortcodes.includes(item.action.destination)
      )
      .slice(0, LAUNCHER_HOME_RECENT_MAX_ITEMS);
  }
);

export const selectUserConnectionType = (): string => 'CONNECTION_TYPE_NONE';

export const selectFeaturedItem = createSelector(
  [selectItems, selectFeaturedItems, selectUserConnectionType],
  (actions, featuredItems, currentConnectionType) => {
    const featuredItemConfig = featuredItems?.find((e) => e.connection_type === currentConnectionType);
    if (!actions || featuredItemConfig === undefined) {
      return undefined;
    }
    const featuredItem = actions.find((e) => e.id === featuredItemConfig.launcher_item_id);
    if (featuredItem === undefined) {
      return undefined;
    }
    return {
      ...featuredItem,
      itemStyle: featuredItemConfig.preferred_style === PREFER_LARGER ? 'feature' : 'highlight',
    };
  }
);

const selectFilteredItems = createSelector(
  [selectItems, selectFeaturedItem, selectVisibleRecentItems],
  (actions, featuredItem, recentItems) => {
    const recentItemIds = recentItems.map((item) => item.id);
    return actions.filter((item) => {
      // Filter out anything that's in the recent items list
      if (recentItemIds.includes(item.id)) {
        return false;
      }
      // Don't return featured item if there's no recent items
      // since it's already being shown in the featured spot
      if (recentItemIds.length === 0) {
        return item.id !== featuredItem?.id;
      }
      return true;
    });
  }
);

export const selectHomeItems = createSelector(
  [selectFilteredItems, selectFeaturedItem, selectVisibleRecentItems],
  (filteredItems, featuredItem, recentItems) => {
    let homeItemsCount = LAUNCHER_HOME_MAX_ITEMS;

    // If we have recent items, subtract from items count
    if (recentItems.length > 0) {
      homeItemsCount -= recentItems.length;

      // else if we have a primary item
    } else if (featuredItem) {
      // If the primary item style is highlight, subtract 1
      if (featuredItem?.itemStyle === 'highlight') {
        homeItemsCount -= 1;
        // Else subtract 2
      } else {
        homeItemsCount -= 2;
      }
    }

    // If we have more items, subtract 1
    if (filteredItems.length > homeItemsCount) {
      homeItemsCount -= 1;
    }

    return filteredItems.slice(0, homeItemsCount);
  }
);

export const selectMoreItems = createSelector([selectFilteredItems, selectHomeItems], (filteredItems, homeActions) => {
  return filteredItems.slice(homeActions.length);
});

const initialState = deepFreeze({
  recentItems: [],
});

interface SpringboardState {
  recentItems: LauncherItem[];
}

const reducer = createReducer<SpringboardState>(initialState, {
  [addToRecentItems.type]: (state, action: ReturnType<typeof addToRecentItems>) => ({
    ...state,
    recentItems: _uniqBy([action.payload.item, ...state.recentItems], 'id'),
  }),
  [clearRecentItems.type]: (state) => ({
    ...state,
    recentItems: initialState.recentItems,
  }),
});

export const springboardReducer = Object.assign(reducer, {
  getPersistentState: (state: SpringboardState) => state,
});
