import { omit as _omit, pick as _pick } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/** Returns last location state, clears location state after read */
export const useTransientLocationState = (locationStateKeys) => {
  const history = useHistory();
  const initialState = _pick(history.location.state, locationStateKeys);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (initialState !== undefined) {
      setState(initialState);
      history.replace({
        pathname: history.location.pathname,
        state: _omit(history.location.state, locationStateKeys),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialState)]);

  return state;
};
