import { useState, useEffect } from 'react';

export const useScript = (src, document = window.document) => {
  const [state, setState] = useState({
    loaded: false,
    loading: true,
    error: undefined,
  });

  const loadScript = (document, src) => {
    const existing = document.querySelector('script[src="' + src + '"]');
    if (existing) {
      const didError = existing.getAttribute('data-status') === 'error';
      const isLoading = existing.getAttribute('data-status') === 'loading';

      if (didError === true) {
        setState({
          loaded: false,
          loading: isLoading,
          error: 'error',
        });
      } else {
        setState({
          loaded: true,
          loading: isLoading,
          error: undefined,
        });
      }
      return;
    }

    const scriptEl = document.createElement('script');

    scriptEl.setAttribute('src', src);
    scriptEl.setAttribute('data-status', 'loading');

    const handleLoad = function () {
      scriptEl.setAttribute('data-status', 'ready');
      setState({
        ...state,
        loaded: true,
        loading: false,
      });
    };

    const handleError = function (error) {
      scriptEl.setAttribute('data-status', 'error');
      setState({
        ...state,
        error: error,
      });
    };

    scriptEl.addEventListener('load', handleLoad);
    scriptEl.addEventListener('error', handleError);

    document.body.appendChild(scriptEl);

    return function () {
      scriptEl.removeEventListener('load', handleLoad);
      scriptEl.removeEventListener('error', handleError);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadScript(document, src), [document, src]);

  return [state.loaded, state.loading, state.error];
};
