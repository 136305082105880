import { ButtonOptionSecondary } from '@foyyay/flow-elements';
import { Select } from '@shared/components/Select';
import { withStopPropagation } from '@shared/lib/events';
import { validate } from '@shared/lib/schema';
import React from 'react';
import { SmartStepActionButtons } from '../../../components/StepActionButtons';
import * as yup from 'yup';
import { clearAddress } from '../../StepPhysicalAddress/actions/stepPhysicalAddressActions';
import { addNewAddress, selectAddressOption } from '../actions/stepSmartPhysicalAddressActions';
import { selectOptions } from '../reducers/stepSmartPhysicalAddressReducer';

export const StepSmartPhysicalAddressSelect = (props) => {
  const { dispatch, useSelector } = props;

  const options = useSelector(selectOptions);

  const handleChange = (option) => {
    const addressIsComplete = validate(
      yup.object({
        street_address: yup.string().required(),
        street_address_2: yup.string(),
        city: yup.string().required(),
        state: yup.string().required(),
        postal_code: yup.string().required(),
        country: yup.string().required(),
      }),
      option.value
    );

    if (addressIsComplete === false) {
      dispatch(selectAddressOption(option));
      return;
    }

    props.onComplete({
      attribute: option.attribute,
      value: option.value,
    });
  };

  const handleClick = withStopPropagation(() => {
    dispatch(clearAddress());
    dispatch(addNewAddress());
  });

  const handleSkipClick = withStopPropagation(() => {
    props.onComplete({
      skipped: true,
      value: undefined,
    });
  });

  return (
    <>
      <Select options={options} onChange={handleChange} />
      <ButtonOptionSecondary onClick={handleClick}>+ Add new address</ButtonOptionSecondary>
      <SmartStepActionButtons onSkipClick={handleSkipClick} required={props.required} style={{ marginTop: '3rem' }} />
    </>
  );
};
