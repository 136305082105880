import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { colorStyles, font } from './style-utils';

export const TriggerTextButton = forwardRef((props, ref) => {
  return (
    <TriggerButton {...props}>
      <ButtonLabel ref={ref}>{props.label}</ButtonLabel>
    </TriggerButton>
  );
});

const TriggerButton = styled.button`
  ${({ theme }) => colorStyles(theme.Trigger.Color)}
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  ${(props) =>
    props.triggered &&
    css`
      ${({ theme }) => colorStyles(theme.Trigger.Color.Triggered)}
    `}
`;

const ButtonLabel = styled.span`
  ${font(22, 'Bold', -0.75, 22)};
  text-align: center;
  padding: 0 3.6rem;
  width: auto;
`;
