import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { NUCLEUS_PLATFORM_API_URL } from '@shared/constants/urls';
import buildQueryStringParams from '@shared/lib/queryStringParameters';
import { richFetch } from '@shared/lib/richFetch';

export const loadPrayerHub = createAsyncThunk('prayerhub/load', async (args, thunkAPI) => {
  const response = await fetchConfig(args.prayerhubShortcode);
  return response.config;
});

export const listPrayers = createAsyncThunk('prayerhub/prayers/list', async (args, thunkAPI) => {
  try {
    return await fetchWallPrayers(args.ownerId, args.prayerwallId, args.cursor);
  } catch (error) {
    const { body, status } = error;

    if (/4\d\d/.test(status) && body !== undefined) {
      return thunkAPI.rejectWithValue(error.body);
    }

    throw error;
  }
});

export const readPrayer = createAsyncThunk('prayerhub/prayer/read', async (args, thunkAPI) => {
  const response = await richFetch(
    'GET',
    NUCLEUS_PLATFORM_API_URL,
    `prayerhub/${args.ownerId}/prayers/wall/${args.wallId}/${args.submittedAt}/${args.prayerId}`
  );

  return response.prayer;
});

export const initPrayerHub = (prayerhub) => (dispatch) => {
  //
};

export const clearPrayerHub = createAction('prayerhub/clear', (prayerhub) => ({
  payload: {
    prayerhub: prayerhub,
  },
}));

export const setCurrentPrayer = createAction('prayerhub/currentprayer/set', (prayerhubId, prayerwallId, prayerId) => ({
  payload: {
    prayerhubId: prayerhubId,
    prayerwallId: prayerwallId,
    prayerId: prayerId,
  },
}));

const fetchConfig = async (prayerhubShortcode) => {
  return await richFetch('GET', NUCLEUS_PLATFORM_API_URL, `/prayerhub/config/${prayerhubShortcode}`);
};

const fetchWallPrayers = async (ownerId, prayerwallId, cursor) => {
  const params = {
    cursor: cursor,
  };
  const queryStringParameters = buildQueryStringParams(params);
  return await richFetch(
    'GET',
    NUCLEUS_PLATFORM_API_URL,
    `/prayerhub/${ownerId}/prayers/wall/${prayerwallId}${queryStringParameters}`
  );
};
