import { ButtonOptionPrimary, ButtonOptionSecondary } from '@foyyay/flow-elements';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  StatusPageActionPriority,
  StatusPageActionType,
  STATUS_PAGE_ACTION_PRIORITY_PRIMARY,
  STATUS_PAGE_ACTION_PRIORITY_SECONDARY,
  STATUS_PAGE_ACTION_PRIORITY_TERTIARY,
  STATUS_PAGE_ACTION_TYPE_BUTTON,
  STATUS_PAGE_ACTION_TYPE_FLOW,
  STATUS_PAGE_ACTION_TYPE_LAUNCHER,
  STATUS_PAGE_ACTION_TYPE_URL,
} from '../constants';

interface Props {
  label: React.ReactNode;
  onClick?: () => void;
  openInNewTab?: boolean;
  priority: StatusPageActionPriority;
  type: StatusPageActionType;
  value: any;
  props?: any;
}

export const StatusPageAction = (props: Props): JSX.Element | null => {
  const ActionType = MAP_TYPE_TO_STATUS_PAGE_ACTION_COMPONENT[props.type];
  if (ActionType === undefined || !props.label || !props.value) {
    return null;
  }
  return <ActionType {...props} onClick={props.onClick} />;
};

const StatusPageActionUrl = (props: Props) => {
  const Action = MAP_PRIORITY_TO_ACTION_COMPONENT[props.priority];
  return (
    <Action as="a" href={props.value} target={props.openInNewTab === true ? '_blank' : '_parent'}>
      {props.label}
    </Action>
  );
};

StatusPageActionUrl.defaultProps = {
  openInNewTab: false,
};

const StatusPageActionLauncher = (props: Props) => {
  const Action = MAP_PRIORITY_TO_ACTION_COMPONENT[props.priority];
  return (
    <Action as={Link} to={props.value}>
      {props.label}
    </Action>
  );
};

const StatusPageActionFlow = (props: Props) => {
  const Action = MAP_PRIORITY_TO_ACTION_COMPONENT[props.priority];
  return (
    <Action as={Link} to={props.value}>
      {props.label}
    </Action>
  );
};

const StatusPageActionButton = (props: Props) => {
  const Action = MAP_PRIORITY_TO_ACTION_COMPONENT[props.priority];
  return (
    <Action {...props.props} onClick={props.value}>
      {props.label}
    </Action>
  );
};

const TextLink = styled.span`
  text-decoration: underline;
  transition: opacity 150ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const MAP_TYPE_TO_STATUS_PAGE_ACTION_COMPONENT: Partial<Record<StatusPageActionType, (props: any) => JSX.Element>> = {
  [STATUS_PAGE_ACTION_TYPE_URL]: StatusPageActionUrl,
  [STATUS_PAGE_ACTION_TYPE_LAUNCHER]: StatusPageActionLauncher,
  [STATUS_PAGE_ACTION_TYPE_FLOW]: StatusPageActionFlow,
  [STATUS_PAGE_ACTION_TYPE_BUTTON]: StatusPageActionButton,
};

const MAP_PRIORITY_TO_ACTION_COMPONENT: Record<StatusPageActionPriority, React.ElementType<any>> = {
  [STATUS_PAGE_ACTION_PRIORITY_PRIMARY]: ButtonOptionPrimary,
  [STATUS_PAGE_ACTION_PRIORITY_SECONDARY]: ButtonOptionSecondary,
  [STATUS_PAGE_ACTION_PRIORITY_TERTIARY]: TextLink,
};
