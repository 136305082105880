import React from 'react';
import styled from 'styled-components';
import { Circle, VerticalArrow } from './Icons';
import { media } from './style-utils';

const StyledCircle = styled(Circle)`
  right: -2rem;
  bottom: -1.6rem;
  color: ${({ theme }) => theme.ButtonCircleDownArrow.Color.Default.Background};
  height: 9.4rem;
  position: absolute;
  width: 9.4rem;
  opacity: 0.4;
  transition: opacity 250ms ease-out;

  ${media.tabletLandscapeAndUp`
    right: -2.6rem;
    bottom: -1.4rem;
  `}
`;

const StyledArrow = styled(VerticalArrow)`
  width: 1.8rem;
  /* height: 2.2rem; */
  opacity: 0.8;
  z-index: 10;
  position: relative;
  transition: opacity 250ms ease-out;
`;

const Container = styled.div`
  color: ${({ theme }) => theme.ButtonCircleDownArrow.Color.Default.Foreground};
  position: relative;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    :hover {
      > ${StyledCircle} {
        opacity: 0.55;
      }
      > ${StyledArrow} {
        opacity: 1;
      }
    }
  }
  /* FixMe: Mobile Active button state changes background opacity */
  :active {
    > ${StyledCircle} {
      opacity: 0.7;
    }
    > ${StyledArrow} {
      opacity: 1;
    }
  }
`;

const Button = (props) => {
  return (
    <Container {...props}>
      <StyledCircle />
      <StyledArrow />
    </Container>
  );
};

export const ButtonCircleDownArrow = Button;
