import { BodyStyle } from '@foyyay/flow-elements';
import { Header } from '@shared/components/Header';
import { Wrapper } from '@shared/components/Layout';
import { LoadingPage } from '@shared/components/LoadingPage';
import { Manifest } from '@shared/components/Manifest';
import { ThemeContext } from '@shared/components/ThemeContext';
import { ERROR_THEME } from '@shared/constants/color';
import { STATE_ERROR, STATE_LOADING } from '@shared/constants/state';
import { ControllerContext } from '@shared/context/ControllerContext';
import { ScrollContext } from '@shared/context/ScrollContext';
import { usePrayerHubConfig } from '@shared/hooks/usePrayerHubConfig';
import { useScrollTop } from '@shared/hooks/useScrollTop';
import { accessRequestFlowShortcodeSelector } from '@shared/reducers/prayerhub';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { MemoryRouter, useLocation } from 'react-router-dom';
import { PrayerHubContext } from './context/PrayerHubContext';
import { Routes } from './Routes';

export const PrayerHub = (props) => {
  const [config, state, reloadPrayerHub] = usePrayerHubConfig(props.shortcode);
  const prayerhubId = config?.id;
  const prayerwallId = config?.prayerwall_id;
  const { goToFlow } = useContext(ControllerContext);
  const location = useLocation();

  const goToPrayerFlow = useCallback(() => {
    goToFlow(config?.prayerflow_shortcode);
  }, [goToFlow, config]);

  const accessRequestFlowShortcode = useSelector(accessRequestFlowShortcodeSelector(prayerhubId, prayerwallId));

  const goToAccessRequestFlow = useCallback(() => {
    goToFlow(accessRequestFlowShortcode);
  }, [accessRequestFlowShortcode, goToFlow]);

  const context = {
    ownerId: config?.ownerId,
    prayerhub: {
      shortcode: props.shortcode,
    },
    currentPrayerHubId: prayerhubId,
    currentPrayerWallId: prayerwallId,
    reloadPrayerHub: reloadPrayerHub,
    goToPrayerFlow: goToPrayerFlow,
    goToAccessRequestFlow: goToAccessRequestFlow,
  };

  const [scrollTop, scrollTargetProps, resetScrollTop] = useScrollTop();

  const scrollContext = {
    scrollTop: scrollTop,
    scrollTargetProps: scrollTargetProps,
    resetScrollTop: resetScrollTop,
  };

  let theme = {
    color: config?.color_flow,
    mode: config?.color_mode,
    paletteVibrancy: config?.color_flow_palette_vibrancy,
  };

  if (state === STATE_ERROR) {
    theme = ERROR_THEME;
  }

  if (state === STATE_LOADING) {
    return <LoadingPage />;
  }

  if (config === undefined) {
    return null;
  }

  const title = config.show_title === true ? config.title : undefined;

  return (
    <PrayerHubContext.Provider value={context}>
      <ScrollContext.Provider value={scrollContext}>
        <ThemeContext {...theme}>
          {props.useManifest && <Manifest config={config} location={location} />}
          <MemoryRouter>
            <BodyStyle />
            <Wrapper>
              <Header title={title} />
              <Routes />
            </Wrapper>
          </MemoryRouter>
        </ThemeContext>
      </ScrollContext.Provider>
    </PrayerHubContext.Provider>
  );
};

PrayerHub.propTypes = {
  shortcode: PropTypes.string,
};
