import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { media } from './style-utils';
import { Styles } from './Typography';

const InputF2 = styled(Input)`
  ${Styles.F2};
  color: ${({ theme }) => theme.InputCard.Color.Default};
  /* Fixme: Should auto shrink on mobile */
`;

const InputF3 = styled(Input)`
  ${Styles.F3};
  color: ${({ theme }) => theme.InputCard.Color.Default};
  width: 10.5rem;
`;

const F2Container = styled.div`
  align-items: center;
  display: flex;
  height: 3.6rem;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    height: 4.6rem;
  `}
`;

const F3Container = styled.div`
  align-items: center;
  display: flex;
  height: 2.4rem;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    height: 3rem;
  `}
`;

const CardNumber = (props) => {
  return (
    <F2Container>
      <InputF2
        type="text"
        inputMode="numeric"
        // Fixme: verify that autocomplete works
        name="card_account_number"
        placeholder="4141 0192 0103 1930"
        autoComplete="cc-number"
        autoCorrect="off"
        spellCheck="false"
        readOnly
        {...props}
      />
    </F2Container>
  );
};

const ExpireDate = (props) => {
  return (
    <F3Container>
      <InputF3
        type="text"
        inputMode="numeric"
        name="date"
        placeholder="MM/YY"
        autoComplete="cc-exp"
        autoCorrect="off"
        spellCheck="false"
        readOnly
        {...props}
      />
    </F3Container>
  );
};

const CardCVV = (props) => {
  return (
    <F3Container>
      <InputF3
        options={{ numericOnly: true, blocks: [4] }}
        type="text"
        inputMode="numeric"
        name="card_cvv"
        placeholder="CVV"
        autoComplete="cc-csc"
        autoCorrect="off"
        spellCheck="false"
        size="4"
        readOnly
        {...props}
      />
    </F3Container>
  );
};

export class CreditCardDetails extends Component {
  static propTypes = {
    card_account_number: PropTypes.string,
    card_cvv: PropTypes.string,
    card_expire_month: PropTypes.string,
    card_expire_year: PropTypes.string,
  };

  static defaultProps = {
    card_account_number: '',
    card_cvv: '',
    card_expire_month: '',
    card_expire_year: '',
  };

  combinedMonthYear = () => {
    const { card_expire_month, card_expire_year } = this.props;
    return card_expire_month + '/' + card_expire_year;
  };

  render() {
    return (
      <GridContainer>
        <CardNumber value={this.props.card_account_number} />
        <FlexContainer>
          <ExpireDate value={this.combinedMonthYear()} />
          <CardCVV value={this.props.card_cvv} />
        </FlexContainer>
      </GridContainer>
    );
  }
}

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 2rem;
  margin-bottom: 3.5rem;

  ${media.tabletLandscapeAndUp`
    grid-row-gap: 1.6rem;
    margin-bottom: 4.9rem;
  `}

  ${F2Container} {
  }
`;

const FlexContainer = styled.div`
  display: flex;
  margin-left: -4rem;
  > * {
    padding-left: 4rem;
  }
`;
