import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { pick as _pick } from 'lodash';

export async function richFetch(method, root, path, body, options, tryUserAuthorization = true) {
  let authorization;

  if (tryUserAuthorization === true) {
    try {
      const token = await getSessionAuthorization();
      authorization = { Authorization: token };
    } catch (error) {
      // Note: User not signed in, attempt the fetch anyway
    }
  }

  options = {
    method: method,
    ...options,
    headers: {
      ...authorization,
      ...options?.headers,
    },
  };

  const url = [root, path].join('');

  if (body !== undefined) {
    options.body = JSON.stringify(body);
    options.headers['Content-Type'] = 'application/json';
  }

  const response = await fetch(url, options);

  const content = await parseFetchResponse(response);

  if (!response.ok) {
    if (/5\d\d/.test(response.status)) {
      sendFetchExceptionToSentry(response);
    }
    throwFetchError(response, content);
  }

  return content;
}

export async function getSessionAuthorization() {
  const currentSession = await Auth.currentSession();
  return currentSession.idToken.jwtToken;
}

async function parseFetchResponse(response) {
  const contentType = (response.headers.get('Content-Type') || '').toLowerCase();

  if (contentType.includes('application/json')) {
    return await response.json();
  }

  return await response.text();
}

function parseHeaders(response) {
  return Array.from(response.headers.entries()).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {}
  );
}

const ERROR_MESSAGE_ATTRIBUTES = Object.freeze(['status', 'statusText', 'message']);

function sendFetchExceptionToSentry(response) {
  const data = _pick(response, ERROR_MESSAGE_ATTRIBUTES);
  const headers = parseHeaders(response);
  const sendToSentry = JSON.stringify({ ...data, headers: headers });

  Sentry.captureException(new Error(sendToSentry));
}

function throwFetchError(response, content) {
  const errorMessage = ERROR_MESSAGE_ATTRIBUTES.reduce((acc, next) => response[next] || acc, '');
  const data = _pick(response, ERROR_MESSAGE_ATTRIBUTES);
  data.body = content;
  const error = Object.assign(new Error(errorMessage), data);
  throw error;
}
