import { ButtonCircleArrow, font, LabelStep, LayoutStepRowSingle, Step, StepInput } from '@foyyay/flow-elements';
import { signUp } from '@shared/actions/user';
import { LOGIN_EMAIL_STATUS } from '@shared/constants/amplify';
import { selectFlowConfigById } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FlowContext } from '../../..';
import { useThankYouController } from '../../../controllers/ThankYouController';
import { SectionText } from '../components/SectionText';
import { THANKYOU_STEP_BY } from '../ThankYou';

export const CreatePassword = (props) => {
  const { currentFlowId } = useContext(FlowContext);
  const config = useSelector((state) => selectFlowConfigById(state, currentFlowId));

  const { user, setUser } = useThankYouController();

  const initialState = {
    processing: false,
    error: undefined,
  };

  const [state, setState] = useState(initialState);

  const dispatch = useDispatch();
  const history = useHistory();

  const handlePasswordChange = (value) => {
    setUser((prevState) => ({
      ...prevState,
      password: value,
    }));
  };

  const handleSignUp = async () => {
    setState({ processing: true });

    try {
      await dispatch(signUp(user.email, user.password));
    } catch (error) {
      const passwordIsInvalid = error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException';
      const errorMessage = passwordIsInvalid ? 'Must be at least 8 characters' : 'Sorry, could not sign up';
      setState({
        processing: false,
        error: errorMessage,
      });
      return;
    }

    history.replace('/thank-you', { currentStep: 'welcome' });
  };

  const handleSkipEmail = () => {
    const nextStep = THANKYOU_STEP_BY[LOGIN_EMAIL_STATUS.NOSIGNUP];
    history.push('/thank-you', { currentStep: nextStep });
  };

  return (
    <>
      <SectionText title={config.message_thankyou_title}>
        {config.message_thankyou_body} Want to go faster next time? Create an account for quick access to your records
        and profile.
      </SectionText>
      <SectionEmail>
        <Step>
          <LabelStep absolute error={state.error}>
            Create A Password
          </LabelStep>
          <LayoutStepRowSingle>
            <form onSubmit={handleSignUp}>
              <HiddenInput id="email" name="email" type="email" autoComplete="username" value={user.email} readOnly />
              <StepInput
                id="password"
                type="password"
                autoComplete="new-password"
                placeholder="password"
                value={user.password}
                onChange={handlePasswordChange}
                onEnter={handleSignUp}
              />
            </form>
            <ButtonCircleArrow onClick={handleSignUp} loading={state.processing} />
          </LayoutStepRowSingle>
        </Step>
        <SkipTextLinkContainer>
          <SkipTextLink>
            Don't want an account?{' '}
            <UnderlineText as="span" onClick={handleSkipEmail}>
              Skip &amp; Continue
            </UnderlineText>
          </SkipTextLink>
        </SkipTextLinkContainer>
      </SectionEmail>
    </>
  );
};

const SectionEmail = styled.div`
  max-width: 58rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.2rem;
`;

const HiddenInput = styled.input`
  display: none;
`;

const SkipTextLinkContainer = styled.div`
  text-align: center;
  margin-top: 6rem;
`;

const SkipTextLink = styled.p`
  ${font(16, 'Book', -0.3, 16)}
  opacity: 0.5;
  text-align: center;
  margin: 0 auto;
  transition: 250ms opacity ease-out;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;

const UnderlineText = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  white-space: no-wrap;
`;
