import React from 'react';
import { SectionText } from '../components/SectionText';

export const ResetPassword = (props) => {
  return (
    <SectionText title="Reset link sent! Please check your&nbsp;email">
      We’ve sent a password reset link to your email address. Click the button in that email to finish resetting your
      password.
    </SectionText>
  );
};
