import * as Sentry from '@sentry/react';
import { SENTRY_ENVIRONMENT, SENTRY_IO_DSN, SENTRY_RELEASE } from '../constants/sentry';

const DEVELOPMENT = 'development';
const isDevelopment = SENTRY_ENVIRONMENT === DEVELOPMENT;

const REJECTED_WITH_STACK = Object.freeze([/chrome-extension:/i, /google-maps-script-loader/i]);
const REJECTED_WITH_MESSAGE = Object.freeze([/ceCurrentVideo.currentTime/i]);

export const configureSentry = (options) => {
  if (SENTRY_IO_DSN === undefined) {
    return;
  }

  Sentry.init({
    ...options,
    dsn: SENTRY_IO_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: SENTRY_RELEASE,
    integrations: [Sentry.browserTracingIntegration()],
    debug: isDevelopment,

    beforeSend: (event, hint) => {
      const error = hint.originalException;

      if (error === undefined) {
        return event;
      }

      if (error.stack !== undefined) {
        for (const exp in REJECTED_WITH_STACK) {
          if (error.stack.match(exp)) {
            return null;
          }
        }
      }

      if (error.message !== undefined) {
        for (const exp in REJECTED_WITH_MESSAGE) {
          if (error.message.match(exp)) {
            return null;
          }
        }

        if (error.message.match(/loading.+chunk/i)) {
          event.fingerprint = ['chunk-load-error'];
        }
      }

      if (isDevelopment) {
        console.debug('SENTRY EVENT:', event);
        return null;
      }

      return event;
    },
  });
};
