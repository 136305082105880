import { ButtonCircleX, InputAddress } from '@foyyay/flow-elements';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ContinueButton } from '../../../components/StepActionButtons';
import { setAddress, setCurrentStep } from '../actions/stepPhysicalAddressActions';
import { ADDRESS_FIELDS } from '../constants';
import { selectAddress, selectCurrentStep } from '../reducers/stepPhysicalAddressReducer';

export const StepPhysicalAddressField = (props) => {
  const { dispatch, useSelector } = props;

  const field = useSelector(selectCurrentStep);
  const address = useSelector(selectAddress);

  const inputRef = useInputFocusOnceRef();

  const initialState = address[field] || '';
  const [value, setValue] = useState(initialState);

  const isValid = value.length > 1;

  const handleComplete = useCallback(() => {
    if (isValid) {
      dispatch(setAddress({ [field]: value }));
      dispatch(setCurrentStep('confirm'));
    }
  }, [dispatch, field, isValid, value]);

  const handleCancel = useCallback(() => {
    dispatch(setCurrentStep('confirm'));
  }, [dispatch]);

  const handleSubmit = useSubmitHandler(handleComplete);
  const handleContinueClick = useClickHandler(handleComplete);
  const handleCancelClick = useClickHandler(handleCancel);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputAddress
          placeholder={`Enter ${ADDRESS_FIELDS[field].label}`}
          name={ADDRESS_FIELDS[field].id}
          value={value}
          onChange={setValue}
          onEnter={handleSubmit}
          ref={inputRef}
          autoComplete={ADDRESS_FIELDS[field].autoComplete}
        />
      </form>
      <ButtonGroup style={{ marginTop: '3rem' }}>
        <ButtonGroupCircleX onClick={handleCancelClick} />
        <ContinueButton disabled={!isValid} onClick={handleContinueClick} />
      </ButtonGroup>
    </>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
`;

const ButtonGroupCircleX = styled(ButtonCircleX)`
  flex: 0 0 auto;
  height: 6rem;
  width: 6rem;
  margin-right: 1.6rem;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
