export const formatLimitLabel = (limitTotal, limitMax, quantitySelected) => {
  return `${formatRemaining(limitTotal, quantitySelected)} ${formatLimit(limitTotal, limitMax)}`.trim();
};

const formatRemaining = (limitTotal, quantitySelected = 0) => {
  if (limitTotal === undefined) {
    return '';
  }

  const available = limitTotal - quantitySelected;

  if (available < 1) {
    return 'NONE REMAINING';
  }

  return `${available} REMAINING`;
};

const formatLimit = (limitTotal, limitMax) => {
  if (limitMax !== undefined && limitMax <= (limitTotal ?? Infinity)) {
    return `(Limit ${limitMax})`;
  }

  return '';
};

export const haveQuantityRemaining = (option) => option.limitMax === undefined || option.limitMax > 0;
