import { usePrayerWallSubscription } from '@shared/actions/prayerwall';
import { Button } from '@shared/components/Button';
import { withPreventDefault } from '@shared/lib/events';
import { prayerwallByIdSelector } from '@shared/reducers/prayerhub';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { PrayerHubContext } from '../context/PrayerHubContext';
import { Sheet } from './Sheet';

export const PrayButton = (props) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleClick = () => {
    setIsSheetOpen(true);
  };

  const handleCancelClick = () => {
    setIsSheetOpen(false);
  };

  return (
    <>
      <StyledButton onClick={handleClick} {...props}>
        Pray with us
      </StyledButton>
      <PrayWithUsSheet show={isSheetOpen} onCancelClick={handleCancelClick} />
    </>
  );
};

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

StyledButton.defaultProps = {
  size: 'large',
  shape: 'pill',
  variant: 'primary',
};

const PrayWithUsSheet = (props) => {
  const { currentPrayerHubId, currentPrayerWallId, goToPrayerFlow } = useContext(PrayerHubContext);
  const prayerwall = useSelector(prayerwallByIdSelector(currentPrayerHubId, currentPrayerWallId));
  const hasPrayerWallPrayers = prayerwall?.prayerIds?.length > 0;
  const showPrayNow = hasPrayerWallPrayers === true;

  const currentPrayerId = useRouteMatch().params.id;

  const handlePrayerRequestClick = withPreventDefault(goToPrayerFlow);

  return (
    <Sheet
      show={props.show}
      onClose={props.onCancelClick}
      footer={<StyledButton onClick={props.onCancelClick}>Cancel</StyledButton>}
    >
      <ButtonSubscription />
      {showPrayNow && (
        <Sheet.Button to={`/prayertime/${currentPrayerId ?? prayerwall.prayerIds[0]}`}>
          Pray now (focus mode)
        </Sheet.Button>
      )}
      <Sheet.Button onClick={handlePrayerRequestClick}>Start a prayer request</Sheet.Button>
    </Sheet>
  );
};

const ButtonSubscription = () => {
  const { currentPrayerHubId, currentPrayerWallId } = useContext(PrayerHubContext);
  const prayerwall = useSelector(prayerwallByIdSelector(currentPrayerHubId, currentPrayerWallId));
  const { loading, currentSubscriber } = usePrayerWallSubscription(prayerwall?.config);

  if (loading) {
    return <Sheet.Button loading />;
  }

  if (currentSubscriber !== undefined) {
    return <Sheet.Button to="/notifications">Manage Notifications</Sheet.Button>;
  }

  return <Sheet.Button to="/notifications">Get notified of new prayers</Sheet.Button>;
};
