export const DATE_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const DATE_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DATE_MAX_PAST_YEARS = 100;
export const DATE_MAX_FUTURE_YEARS = 100;

export const TIME_HOURS = Array.from(Array(12).keys(), (x) => x + 1);
export const TIME_MINUTES = Array.from(Array(60).keys(), (x) => x);

export const TIME_PERIOD_AM = 'AM';
export const TIME_PERIOD_PM = 'PM';
export const TIME_PERIODS = [TIME_PERIOD_AM, TIME_PERIOD_PM];
