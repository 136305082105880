import {
  ButtonGroup,
  ButtonGroupCircleX,
  ButtonPrimary,
  DatePicker,
  getClosestValidDate,
  LabelStep,
  media,
} from '@foyyay/flow-elements';
import { Select } from '@shared/components/Select';
import { INTERVAL, SCHEDULE_CUSTOM, SCHEDULE_START_DATE_MAXIMUM_FUTURE_MONTHS } from '@shared/constants';
import { dateToIsoString, isoStringToDate } from '@shared/lib/datetime';
import { getStartDateExpiresAt, getStartDateOptionsForInterval } from '@shared/lib/interval';
import { buildConfigValidator } from '@shared/lib/schema';
import * as dateFns from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { FlowStep } from '../components/FlowStep';

export const StepPaymentScheduleStartDate = (props) => {
  const defaultValue = {};
  const initialValue = props.data?.value ?? defaultValue;
  const selectedInterval = props.sharedData?.interval;

  const message = getSummaryMessage(initialValue, selectedInterval);

  return (
    <FlowStep label={''} message={message}>
      <InputScheduleStartDate
        onComplete={props.completeStep}
        value={initialValue}
        selectedInterval={selectedInterval}
      />
    </FlowStep>
  );
};

const optionSchema = yup.object().shape({
  label: yup.string(),
  value: yup.string(),
});

const configSchema = {
  description: yup.string(),
  label: yup.string().min(3).required(),
  options: yup.array().of(optionSchema).min(1),
  required: yup.bool().required(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepPaymentScheduleStartDate.isConfigValid = isConfigValid;

const InputScheduleStartDate = (props) => {
  const initialState = props.value.label === SCHEDULE_CUSTOM ? 'custom' : 'options';
  const [state, setState] = useState(initialState);

  const setOptions = () => setState('options');
  const setCustom = () => setState('custom');

  switch (state) {
    case 'custom':
      return <InputCustomStartDate setOptions={setOptions} {...props} />;
    case 'options':
    default:
      return <SelectStartDate setCustom={setCustom} {...props} />;
  }
};

const OPTION_CUSTOM = { id: 'OPTION_CUSTOM', label: 'or, Choose a custom date', style: 'secondary' };

const SelectStartDate = (props) => {
  const scheduleStartOptions = getStartDateOptionsForInterval(props.selectedInterval);
  const handleScheduleStartOptionChange = (value) => {
    if (value.id === 'OPTION_CUSTOM') {
      return props.setCustom();
    }

    props.onComplete({
      value: {
        ...value,
        expiresAt: getStartDateExpiresAt(),
      },
    });
  };

  const options = [...scheduleStartOptions, OPTION_CUSTOM];

  let scheduleStartOptionMessage = 'Starting when?';
  if (props.selectedInterval.type === INTERVAL.NONE) {
    scheduleStartOptionMessage = 'When?';
  }

  return (
    <>
      <LabelStep>{scheduleStartOptionMessage}</LabelStep>
      <Select options={options} onChange={handleScheduleStartOptionChange} />
    </>
  );
};

function validateDate(date, range, daysOfMonth) {
  if (!(date instanceof Date && isFinite(date))) {
    return false;
  }

  if (!dateFns.isWithinInterval(date, range)) {
    return false;
  }

  if (daysOfMonth?.length > 0 && !daysOfMonth.some((day) => parseInt(day) === dateFns.getDate(date))) {
    return false;
  }

  return true;
}

const initDatePickerState = (date, validator, now, startDate, endDate) => {
  const value = date ? isoStringToDate(date) : now;
  return getClosestValidDate(value, startDate, endDate, validator);
};

const InputCustomStartDate = (props) => {
  const now = useMemo(() => new Date(), []);
  const startDate = now;
  const endDate = dateFns.addMonths(now, SCHEDULE_START_DATE_MAXIMUM_FUTURE_MONTHS);

  const validator = useCallback(
    (date) => {
      return validateDate(date, { start: startDate, end: endDate }, props.selectedInterval?.intervalDates);
    },
    [startDate, endDate, props.selectedInterval]
  );

  const selectedDate = props.value.label === SCHEDULE_CUSTOM ? props.value.date : undefined;
  const [inputDate, setInputDate] = useState(initDatePickerState(selectedDate, validator, now, startDate, endDate));

  const handleChange = (value) => setInputDate(value);

  const handleBackClick = (e) => {
    e.stopPropagation();
    props.setOptions();
  };

  const handleContinueClick = (e) => {
    e.stopPropagation();

    props.onComplete({
      value: {
        label: SCHEDULE_CUSTOM,
        date: dateToIsoString(inputDate),
        expiresAt: getStartDateExpiresAt(),
      },
    });
  };

  let scheduleStartOptionMessage = 'OK, choose a start date';
  if (props.selectedInterval.type === INTERVAL.NONE) {
    scheduleStartOptionMessage = 'OK, choose a date';
  }

  return (
    <>
      <LabelStep>{scheduleStartOptionMessage}</LabelStep>
      <Wrapper>
        <DatePicker
          value={inputDate}
          onChange={handleChange}
          startDate={startDate}
          endDate={endDate}
          validator={validator}
        />
      </Wrapper>
      <ButtonGroup>
        <ButtonGroupCircleX onClick={handleBackClick} />
        <ButtonPrimary onClick={handleContinueClick} processing={props.isTokenProcessing}>
          Continue
        </ButtonPrimary>
      </ButtonGroup>
    </>
  );
};

const Wrapper = styled.div`
  margin-left: -2.2rem;
  margin-right: -2.2rem;

  ${media.tabletLandscapeAndUp`
    margin-left: -3.6rem;
    margin-right: -3.6rem;
  `}
`;

function getSummaryMessage(initialValue, selectedInterval) {
  if (selectedInterval.type === INTERVAL.NONE) {
    if (initialValue.label === SCHEDULE_CUSTOM) {
      return 'on ' + dateFns.format(isoStringToDate(initialValue.date), 'eeee, MMMM d, yyyy');
    }

    return initialValue.label;
  }

  if (initialValue.label === SCHEDULE_CUSTOM) {
    const formattedDate = dateFns.format(isoStringToDate(initialValue.date), 'eeee, MMMM d, yyyy');
    return `starting ${formattedDate}`;
  }

  return `starting ${initialValue.label}`;
}
