import Color from 'color';
import { MODE } from './constants';

const MAP_MODE_TO_THEME = {
  [MODE.LIGHT]: 'light',
  [MODE.DARK]: 'dark',
};

// http://chir.ag/projects/name-that-color/
const Cadillac = '#B2515D';
const VividTangerine = '#FF8080';

export function createTheme(palette, mode) {
  const _ = (map) => map[MAP_MODE_TO_THEME[mode]];
  const { Dark1, Dark2, Light, Vibrant1, Vibrant2 } = palette;

  const error = _({
    light: Cadillac,
    dark: VividTangerine,
  });

  return {
    palette: palette,
    mode: mode,
    Alert: {
      Color: {
        Error: {
          Background: Cadillac,
          Foreground: '#fff',
        },
        Info: {
          Background: Light,
          Foreground: Dark1,
        },
        Success: {
          Background: Vibrant1,
          Foreground: '#fff',
        },
      },
    },
    AccountPrompt: {
      Color: {
        Background: _({
          light: '#fff',
          dark: Dark1,
        }),
        Shadow: _({
          light: Color(Dark1).alpha(0.25).string(),
          dark: Color(Dark2).alpha(0.25).string(),
        }),
      },
    },
    Background: {
      Color: {
        Default: {
          Background: _({
            light: Light,
            dark: Dark2,
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    ButtonCircleLogo: {
      Color: {
        Default: {
          Background: _({
            light: Vibrant1,
            dark: Vibrant2,
          }),
          Foreground: '#fff',
        },
        Hover: {
          Background: _({
            light: Vibrant2,
            dark: Vibrant1,
          }),
          Foreground: '#fff',
        },
      },
    },
    ButtonCircleArrow: {
      Color: {
        Default: {
          Background: _({
            light: Vibrant1,
            dark: Vibrant1,
          }),
          Foreground: '#fff',
        },
        Hover: {
          Background: _({
            light: Color(Vibrant1).lighten(0.05).string(),
            dark: Color(Vibrant1).lighten(0.05).string(),
          }),
          Foreground: '#fff',
        },
        Disabled: {
          Background: _({
            light: Color(Vibrant1).alpha(0.2).string(),
            dark: Color(Vibrant2).alpha(0.2).string(),
          }),
          Foreground: '#fff',
        },
      },
    },
    ButtonCircleDownArrow: {
      Color: {
        Default: {
          Background: _({
            light: Vibrant1,
            dark: Vibrant2,
          }),
          Foreground: '#fff',
        },
      },
    },
    ButtonCircleX: {
      Color: {
        Default: {
          Background: _({
            light: Color(Vibrant1).alpha(0.2).string(),
            dark: Color(Vibrant2).alpha(0.5).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
        Hover: {
          Background: _({
            light: Color(Vibrant1).alpha(0.3).string(),
            dark: Color(Vibrant2).alpha(0.6).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    ButtonCircleSpinner: {
      Color: {
        Default: {
          Background: _({
            light: Color(Vibrant1).alpha(0.2).string(),
            dark: Color(Vibrant2).alpha(0.5).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
        Hover: {
          Background: _({
            light: Color(Vibrant1).alpha(0.3).string(),
            dark: Color(Vibrant2).alpha(0.6).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    ButtonCirclePlus: {
      Color: {
        Default: {
          Background: _({
            light: Color(Vibrant1).alpha(0.2).string(),
            dark: Color(Vibrant2).alpha(0.5).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
        Hover: {
          Background: _({
            light: Color(Vibrant1).alpha(0.3).string(),
            dark: Color(Vibrant2).alpha(0.6).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
      Secondary: {
        Default: {
          Background: _({
            light: Color(Light).alpha(0.75).string(),
            dark: Color(Dark1).alpha(0.75).string(),
          }),
          Foreground: _({
            light: Color(Vibrant1).alpha(0.75).string(),
            dark: Color('#fff').alpha(0.75).string(),
          }),
        },
        Hover: {
          Background: _({
            light: Light,
            dark: Dark1,
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    Banner: {
      Color: {
        Default: {
          Background: _({
            light: Light,
            dark: Dark2,
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
          MediaBackground: _({
            light: Color(Vibrant1).alpha(0.2).string(),
            dark: Color(Vibrant2).alpha(0.4).string(),
          }),
        },
      },
    },
    ButtonClose: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: '#fff',
        }),
      },
    },
    ButtonFile: {
      Color: {
        Upload: {
          Default: {
            Background: _({
              light: Vibrant1,
              dark: Vibrant2,
            }),
            Foreground: '#fff',
          },
          Hover: {
            Background: _({
              light: Vibrant2,
              dark: Vibrant1,
            }),
            Foreground: '#fff',
          },
        },
        Uploading: {
          Default: {
            Background: _({
              light: Vibrant1,
              dark: Vibrant2,
            }),
            Foreground: '#fff',
          },
          Hover: {
            Background: _({
              light: Vibrant2,
              dark: Vibrant1,
            }),
            Foreground: '#fff',
          },
        },
        Success: {
          Default: {
            Background: _({
              light: '#fff',
              dark: Dark1,
            }),
            Foreground: _({
              light: Vibrant1,
              dark: Light,
            }),
          },
        },
        Error: {
          Default: {
            Background: error,
            Foreground: '#fff',
          },
        },
      },
    },
    ButtonOptionAlternate: {
      Color: {
        Default: {
          Background: 'transparent',
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
          Border: _({
            light: Vibrant1,
            dark: Vibrant2,
          }),
        },
        Hover: {
          Background: _({
            light: Color(Vibrant1).alpha(0.08).string(),
            dark: Color(Vibrant2).alpha(0.08).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
          Border: _({
            light: Vibrant1,
            dark: Vibrant2,
          }),
        },
        Checked: {
          Default: {
            Background: _({
              light: Vibrant1,
              dark: Vibrant2,
            }),
            Foreground: '#fff',
            Border: _({
              light: Vibrant1,
              dark: Vibrant2,
            }),
          },
          Hover: {
            Background: _({
              light: Vibrant2,
              dark: Vibrant1,
            }),
            Foreground: '#fff',
            Border: _({
              light: Vibrant2,
              dark: Vibrant1,
            }),
          },
        },
      },
    },
    ButtonOption: {
      Color: {
        primary: {
          Default: {
            Background: _({
              light: Vibrant1,
              dark: Vibrant1,
            }),
            Foreground: '#fff',
          },
          Hover: {
            Background: _({
              light: Color(Vibrant1).lighten(0.05).string(),
              dark: Color(Vibrant1).lighten(0.05).string(),
            }),
            Foreground: '#fff',
          },
        },
        secondary: {
          Default: {
            Background: _({
              light: Color(Vibrant1).alpha(0.2).string(),
              dark: Color(Vibrant2).alpha(0.4).string(),
            }),
            Foreground: _({
              light: Vibrant1,
              dark: '#fff',
            }),
          },
          Hover: {
            Background: _({
              light: Color(Vibrant1).alpha(0.3).string(),
              dark: Color(Vibrant2).alpha(0.6).string(),
            }),
            Foreground: _({
              light: Vibrant1,
              dark: '#fff',
            }),
          },
        },
      },
    },
    ButtonOptionBadge: {
      Color: {
        primary: {
          Default: {
            Background: Color('#fff').alpha(0.3).string(),
            Foreground: '#fff',
          },
        },
        secondary: {
          Default: {
            Background: _({
              light: Color(Vibrant1).alpha(0.2).string(),
              dark: Color(Vibrant2).alpha(0.4).string(),
            }),
            Foreground: _({
              light: Vibrant1,
              dark: '#fff',
            }),
          },
        },
      },
    },
    ButtonOptionCounter: {
      Color: {
        Default: {
          Background: _({
            light: Vibrant1,
            dark: Vibrant1,
          }),
          Error: Cadillac,
          Foreground: '#fff',
          Shadow: Color(Dark2).alpha(0.5).string(),
        },
      },
    },
    ButtonPlaid: {
      Color: {
        Default: {
          Background: _({
            light: Vibrant1,
            dark: Vibrant1,
          }),
          Foreground: '#fff',
        },
        Hover: {
          Background: _({
            light: Color(Vibrant1).lighten(0.05).string(),
            dark: Color(Vibrant1).lighten(0.05).string(),
          }),
          Foreground: '#fff',
        },
        Linked: {
          Background: undefined,
          Foreground: _({
            light: Vibrant1,
            dark: Light,
          }),
        },
      },
    },
    ButtonPrimary: {
      Color: {
        Default: {
          Background: _({
            light: Vibrant1,
            dark: Vibrant1,
          }),
          Foreground: '#ffffff',
        },
        Hover: {
          Background: _({
            light: Color(Vibrant1).lighten(0.05).string(),
            dark: Color(Vibrant1).lighten(0.05).string(),
          }),
          Foreground: undefined,
        },
        Error: {
          Background: error,
          Foreground: undefined,
        },
      },
    },
    ButtonPrimaryReverse: {
      Color: {
        Default: {
          Background: _({
            light: Color('#fff').alpha(0.75).string(),
            dark: Color(Vibrant2).alpha(0.75).string(),
          }),
          Foreground: _({
            light: Color(Vibrant1).alpha(0.75).string(),
            dark: Color('#fff').alpha(0.75).string(),
          }),
        },
        Hover: {
          Background: _({
            light: '#fff',
            dark: Vibrant2,
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    ButtonSecondary: {
      Color: {
        Default: {
          Background: _({
            light: Color(Vibrant1).alpha(0.2).string(),
            dark: Color(Vibrant2).alpha(0.4).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
        Hover: {
          Background: _({
            light: Color(Vibrant1).alpha(0.3).string(),
            dark: Color(Vibrant2).alpha(0.6).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
        Error: {
          Background: error,
          Foreground: undefined,
        },
      },
    },
    DiscoveryMessage: {
      Color: {
        Default: {
          Background: '#fff',
          Foreground: Vibrant1,
        },
      },
    },
    FeaturedItemCallout: {
      Color: {
        Default: {
          Background: _({
            light: '#fff',
            dark: Dark2,
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    FlowLayout: {
      Title: {
        Color: {
          Default: {
            Foreground: _({
              light: Vibrant1,
              dark: Color('#fff').alpha(0.75).string(),
            }),
          },
        },
      },
    },
    Footer: {
      Color: {
        Default: {
          Background: _({
            light: Light,
            dark: Dark2,
          }),
        },
      },
    },
    Header: {
      Color: {
        primary: {
          Default: {
            ButtonBackground: _({
              light: Vibrant1,
              dark: Vibrant1,
            }),
            Foreground: '#fff',
          },
          Hover: {
            ButtonBackground: _({
              light: Color(Vibrant1).lighten(0.05).string(),
              dark: Color(Vibrant1).lighten(0.05).string(),
            }),
            Foreground: '#fff',
          },
        },
        Default: {
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
          ButtonBackground: _({
            light: Color(Vibrant1).alpha(0.15).string(),
            dark: Dark1,
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000000').alpha(0.25).string(),
          }),
        },
      },
    },
    HeaderBar: {
      Color: {
        Default: {
          Background: _({
            light: Light,
            dark: Dark2,
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
          Shadow: _({
            light: Color(Dark1).alpha(0.2).string(),
            dark: Color('#000000').alpha(0.25).string(),
          }),
        },
      },
    },
    Input: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InputAddress: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InputCard: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InputCurrency: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InputDate: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InputEmail: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InputPhone: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InputText: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Counter: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InputTime: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Counter: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InputLine: {
      Color: {
        Default: _({
          light: Vibrant1,
          dark: Light,
        }),
        Error: error,
      },
    },
    Label: {},
    LabelStep: {
      Color: {
        Default: _({
          light: '#000',
          dark: '#fff',
        }),
        Error: error,
      },
    },
    LauncherItem: {
      Primary: {
        Default: {
          Foreground: '#fff',
          Background: _({
            light: Vibrant1,
            dark: Vibrant1,
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
        Hover: {
          Foreground: '#fff',
          Background: _({
            light: Color(Vibrant1).lighten(0.05).string(),
            dark: Color(Vibrant1).lighten(0.05).string(),
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
      },
      Secondary: {
        Default: {
          Foreground: _({
            light: Color(Vibrant1).alpha(0.75).string(),
            dark: Color(Light).alpha(0.75).string(),
          }),
          Background: _({
            light: Color('#fff').alpha(0.75).string(),
            dark: Color(Dark1).alpha(0.75).string(),
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
        Hover: {
          Foreground: _({
            light: Vibrant1,
            dark: Light,
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
      },
    },
    LauncherSearchbar: {
      Primary: {
        Default: {
          Foreground: _({
            light: Vibrant1,
            dark: Light,
          }),
          Background: _({
            light: Color(Vibrant1).alpha(0.1).string(),
            dark: Color(Dark1).alpha(0.5).string(),
          }),
          ClearBackground: _({
            light: Color(Vibrant1).alpha(0.1).string(),
            dark: Color(Dark1).alpha(0.1).string(),
          }),
        },
        Focus: {
          Foreground: _({
            light: Vibrant1,
            dark: Light,
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
        },
      },
    },
    DescriptionStep: {
      Color: {
        Default: _({
          light: Dark1,
          dark: Light,
        }),
        Error: error,
      },
    },
    InfoText: {
      Color: {
        Default: _({
          light: '#000',
          dark: '#fff',
        }),
      },
    },
    InfoItemCard: {
      Color: {
        Default: {
          Foreground: _({
            light: '#000',
            dark: '#fff',
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
          MediaBackground: _({
            light: Color(Vibrant1).alpha(0.35).string(),
            dark: Dark2,
          }),
        },
      },
    },
    InfoCard: {
      Color: {
        Default: {
          Foreground: _({
            light: '#000',
            dark: '#fff',
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
          MediaBackground: _({
            light: Color(Vibrant1).alpha(0.35).string(),
            dark: Dark2,
          }),
          Link: _({
            light: Vibrant1,
            dark: Vibrant1,
          }),
        },
      },
    },
    PrayerCard: {
      Color: {
        Default: {
          Foreground: _({
            light: '#000',
            dark: '#fff',
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
      },
    },
    PrayCardNotice: {
      Color: {
        Default: {
          Background: _({
            light: Color(Vibrant1).alpha(0.2).string(),
            dark: Color(Vibrant2).alpha(0.4).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    PrayerTime: {
      Color: {
        Default: {
          Background: _({
            light: Light,
            dark: Dark2,
          }),
          Highlight: _({
            light: Color(Light).lighten(0.05).string(),
            dark: Color(Dark2).lighten(0.15).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    PrevNextButtons: {
      Color: {
        Default: {
          Track: _({
            light: Color(Vibrant1).alpha(0.2).string(),
            dark: Color(Vibrant2).alpha(0.5).string(),
          }),
        },
      },
    },
    Profile: {
      Color: {
        Default: {
          Background: _({
            light: Light,
            dark: Dark1,
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    Progress: {
      Color: {
        Default: {
          Background: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color(Vibrant2).alpha(0.25).string(),
          }),
          Foreground: Vibrant1,
        },
      },
    },
    ProgressCircle: {
      Color: {
        Default: {
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    Prompt: {
      Color: {
        Default: {
          Foreground: _({
            light: '#000',
            dark: '#fff',
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
          Transparent: _({
            light: 'rgba(255, 255, 255, 0)',
            dark: Color(Dark1).alpha(0).string(),
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
      },
    },
    Sheet: {
      Color: {
        Default: {
          Foreground: _({
            light: '#000',
            dark: '#fff',
          }),
          Background: _({
            light: Light,
            dark: Dark2,
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
      },
    },
    SheetButton: {
      Color: {
        Default: {
          Foreground: _({
            light: Color(Vibrant1).alpha(0.75).string(),
            dark: Color(Light).alpha(0.75).string(),
          }),
          Background: _({
            light: Color('#fff').alpha(0.75).string(),
            dark: Color(Dark1).alpha(0.75).string(),
          }),
        },
        Hover: {
          Foreground: _({
            light: Vibrant1,
            dark: Light,
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
        },
        Active: {
          Foreground: _({
            light: Vibrant1,
            dark: Light,
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
        },
        Activated: {
          Foreground: _({
            light: Vibrant1,
            dark: Light,
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
        },
      },
    },
    Step: {
      Color: {
        Default: {
          Foreground: _({
            light: Vibrant1,
            dark: Light,
          }),
          Background: _({
            light: '#fff',
            dark: Dark1,
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
        Error: {
          Foreground: '#fff',
          Background: Cadillac,
        },
      },
    },
    StepInfoBanner: {
      Color: {
        Default: {
          Background: _({
            light: Color(Vibrant1).alpha(0.2).string(),
            dark: Color(Vibrant2).alpha(0.4).string(),
          }),
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
        },
      },
    },
    StepAlert: {
      Color: {
        info: {
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
          Background: _({
            light: Light,
            dark: Color(Vibrant2).alpha(0.7).string(),
          }),
          Icon: Vibrant1,
        },
        error: {
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
          Background: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Vibrant1,
          }),
          Icon: Cadillac,
        },
      },
    },
    Summary: {
      Color: {
        Default: {
          Background: Color(Vibrant1).alpha(0.1).string(),
        },
      },
    },
    Trigger: {
      Color: {
        Default: {
          Background: _({
            light: Vibrant1,
            dark: Vibrant2,
          }),
          Foreground: '#fff',
        },
        Hover: {
          Background: _({
            light: Color(Vibrant1).lighten(0.16).string(),
            dark: Color(Vibrant2).lighten(0.16).string(),
          }),
          Foreground: '#fff',
        },
        Triggered: {
          Default: {
            Background: _({
              light: Light,
              dark: Dark2,
            }),
            Foreground: _({
              light: Color(Vibrant1).alpha(0.85).string(),
              dark: Color('#fff').alpha(0.85).string(),
            }),
          },
          Hover: {
            Background: _({
              light: Color(Light).lighten(0.02).string(),
              dark: Color(Dark2).lighten(0.16).string(),
            }),
            Foreground: _({
              light: Vibrant1,
              dark: '#fff',
            }),
          },
        },
      },
    },
    Typography: {},
    PlaceholderLogo: {
      Color: {
        Default: {
          Foreground: _({
            light: '#000',
            dark: '#fff',
          }),
        },
      },
    },
    RecentItem: {
      Primary: {
        Default: {
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
          Background: _({
            light: Color(Vibrant1).alpha(0.1).string(),
            dark: Color(Dark1).alpha(0.65).string(),
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
        Hover: {
          Foreground: _({
            light: Vibrant1,
            dark: '#fff',
          }),
          Background: _({
            light: Color(Vibrant1).alpha(0.15).string(),
            dark: Dark1,
          }),
          Shadow: _({
            light: Color(Vibrant1).alpha(0.25).string(),
            dark: Color('#000').alpha(0.25).string(),
          }),
        },
      },
    },
  };
}
