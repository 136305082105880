import React from 'react';
import { Route, Switch } from 'react-router';
import { ThankYouLayout } from './components/Layout';
import { CompleteAccountSetup } from './routes/CompleteAccountSetup';
import { CreatePassword } from './routes/CreatePassword';
import { EnterEmail } from './routes/EnterEmail';
import { EnterPassword } from './routes/EnterPassword';
import { ResetPassword } from './routes/ResetPassword';
import { ThankYouMessage } from './routes/ThankYouMessage';
import { Welcome } from './routes/Welcome';

export const Routes = (props) => {
  const location = {
    key: props.currentStep,
    pathname: props.currentStep,
  };

  return (
    <Switch location={location}>
      <Route path="complete-account-setup">
        <CompleteAccountSetup />
      </Route>
      <Route>
        <ThankYouLayout.Container>
          <Switch location={location}>
            <Route path="enter-email">
              <EnterEmail />
            </Route>
            <Route path="enter-password">
              <EnterPassword />
            </Route>
            <Route path="create-password">
              <CreatePassword />
            </Route>
            <Route path="reset-password">
              <ResetPassword />
            </Route>
            <Route path="welcome">
              <Welcome />
            </Route>
            <Route path="thank-you">
              <ThankYouMessage />
            </Route>
          </Switch>
        </ThankYouLayout.Container>
      </Route>
    </Switch>
  );
};
