import { media, SIZE } from '@foyyay/flow-elements';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { FrameContext } from 'react-frame-component';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

export const Modal = (props) => {
  const { document } = useContext(FrameContext);

  const el = useMemo(() => document.createElement('div'), [document]);
  const modalRoot = useMemo(() => document.getElementById('modal-root'), [document]);

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => modalRoot.removeChild(el);
  }, [el, modalRoot]);

  return ReactDOM.createPortal(props.children, el);
};

export const ModalOverlay = styled((props) => {
  const transitions = useTransition(props.show, (show) => show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      friction: 55,
      mass: 0.5,
      tension: 1000,
    },
  });

  return (
    <Modal>
      {transitions.map(({ item: show, props: style, key }) => (
        <React.Fragment key={key}>
          {show && (
            <Wrapper className={props.className}>
              <Backdrop style={style} />
              <Container>
                <Content style={style} contentWidth={props.contentWidth}>
                  {props.children}
                </Content>
              </Container>
            </Wrapper>
          )}
        </React.Fragment>
      ))}
    </Modal>
  );
})``;

ModalOverlay.propTypes = {
  contentWidth: PropTypes.string,
  onClickClose: PropTypes.func,
  show: PropTypes.bool,
};

ModalOverlay.defaultProps = {
  contentWidth: '55rem',
  show: false,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  z-index: 2147483010;

  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 100%;
    vertical-align: middle;
    margin-right: -1px;
  }
`;

const Backdrop = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: ${(props) => props.theme.Prompt.Color.Default.Background};
  ${media.tabletLandscapeAndUp`
    background: ${(props) => props.theme.Background.Color.Default.Background};
  `}
`;

const Container = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  text-align: left;
  width: 100%;
  padding: 4rem 0;
  white-space: normal;
`;

const Content = styled(animated.div)`
  width: ${(props) => props.contentWidth};
  max-width: 100%;
  min-width: 0;
  margin-left: ${SIZE[1]};
  margin-right: ${SIZE[1]};

  ${media.largePhoneAndUp`
    margin-left: ${SIZE[2]};
    margin-right: ${SIZE[2]};
  `}
`;
