import { createReducer } from '@reduxjs/toolkit';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { formatValueWithPrefix } from '@shared/lib/format';
import { clearAddress, isAddressValid, setAddress, setCurrentStep } from '../actions/stepPhysicalAddressActions';
import { ADDRESS_FIELDS } from '../constants';

export const selectCurrentStep = (state) => state.currentStep;

export const selectAddress = (state) => state.address;

export const selectLabel = (state, config) => {
  if (!['confirm', 'search'].includes(state.currentStep)) {
    return `What ${ADDRESS_FIELDS[state.currentStep]?.label}?`;
  }

  return config.label;
};

export const selectMessage = (state, config, data) => {
  const skipped = data?.skipped === true;

  if (skipped) {
    return `Skipped: "${config.label}"`;
  }

  return formatValueWithPrefix(config.valuePrefixLabel, data?.value?.street_address, config.valuePrefixSeparator);
};

const initialState = deepFreeze({
  currentStep: 'search',
  address: {
    street_address: '',
    street_address_2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  },
});

const initCurrentStep = (data) => {
  if (data?.value === undefined) {
    return 'search';
  }

  if (!isAddressValid(data.value)) {
    return 'search';
  }

  return 'confirm';
};

export const init = (data) => ({
  currentStep: initCurrentStep(data),
  address: data?.value ?? initialState.address,
});

export const stepPhysicalAddressReducer = createReducer(initialState, {
  [setCurrentStep]: (state, action) => ({
    ...state,
    currentStep: action.payload,
  }),
  [setAddress]: (state, action) => ({
    ...state,
    address: {
      ...state.address,
      ...action.payload,
    },
  }),
  [clearAddress]: (state, action) => ({
    ...state,
    address: initialState.address,
  }),
});
