import { deepFreeze } from '@shared/lib/deepFreeze';
import PropTypes from 'prop-types';

const BLOCK_VALUES = deepFreeze([
  // These values in requires will cause the fallback item to render.
  undefined,
  false,
]);

export const RenderBlocker = (props) => {
  const require = Array.isArray(props.require) === true ? props.require : [props.require];

  if (require.some((item) => BLOCK_VALUES.includes(item))) {
    return props.fallback;
  }
  return props.children;
};

RenderBlocker.propTypes = {
  require: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  fallback: PropTypes.node,
  children: PropTypes.node,
};

RenderBlocker.defaultProps = {
  require: [],
  fallback: null,
};
