import { useThunkReducer } from '@shared/hooks/useThunkReducer';
import { selectEmails } from '@shared/reducers/user';
import React from 'react';
import { useSelector as useReduxSelector } from 'react-redux';
import { FlowStep } from '../../components/FlowStep';
import { StepSmartEmailAddressInput } from './components/StepSmartEmailAddressInput';
import { StepSmartEmailAddressSelect } from './components/StepSmartEmailAddressSelect';
import {
  init,
  selectCurrentStep,
  selectLabel,
  selectMessage,
  stepSmartEmailAddressReducer,
} from './reducers/stepSmartEmailAddressReducer';

export const StepSmartEmailAddress = (props) => {
  const emails = useReduxSelector(selectEmails);
  const initializerArg = {
    data: props.data,
    emails: emails,
  };
  const [state, dispatch, useSelector] = useThunkReducer(stepSmartEmailAddressReducer, {
    devToolsName: props.devToolsName,
    initializerArg: initializerArg,
    initializer: init,
  });

  const currentStep = useSelector(selectCurrentStep);
  const label = useSelector((state) => selectLabel(state, props.config));
  const message = useSelector((state) => selectMessage(state, props.config, props.data));

  return (
    <FlowStep label={label} message={message}>
      {React.createElement(getStepBy(currentStep), {
        state: state,
        dispatch: dispatch,
        useSelector: useSelector,
        required: props.config.required,
        onComplete: props.completeStep,
      })}
    </FlowStep>
  );
};

const stepBy = {
  input: StepSmartEmailAddressInput,
  select: StepSmartEmailAddressSelect,
};

const getStepBy = (currentStep) => stepBy[currentStep] ?? stepBy.input;
