import { PostMessage } from '@shared/lib/postMessage';
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FrameContext } from 'react-frame-component';
import styled from 'styled-components';

export const SecureFrame = React.forwardRef((props, ref) => {
  const { childOrigin, methods, onError, ...iframeProps } = props;

  const [height, setHeight] = useState();

  const { window } = useContext(FrameContext);

  const frameRef = useRef();
  const conRef = useRef({});

  useImperativeHandle(ref, () => conRef.current);

  useEffect(() => {
    const connection = PostMessage.connectToChild({
      childOrigin: childOrigin,
      iframe: frameRef.current,
      methods: {
        ...props.methods,
        onHeightChange: setHeight,
      },
      window: window,
    });

    connection.promise.then(async (child) => {
      conRef.current = child;
      setHeight(await child.getHeight());
    });

    connection.promise.catch((error) => {
      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    });

    return () => {
      connection.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <StyledFrame height={height} {...iframeProps} ref={frameRef} />;
});

SecureFrame.defaultProps = {
  methods: {},
};

const StyledFrame = styled.iframe`
  border: 0;
  height: ${(props) => props.height};
  width: 100% !important;
  border: none !important;
  margin: 0px !important;
  padding: 0px !important;
  min-width: 100% !important;
  overflow: hidden !important;
  display: block !important;
  user-select: none !important;
  will-change: transform !important;
`;
