import React from 'react';
import styled from 'styled-components';
import { media } from './style-utils';
import { H2, T1 } from './Typography';

const Container = styled.div`
  background-color: ${({ theme }) => theme.Prompt.Color.Default.Background};
  color: ${({ theme }) => theme.Prompt.Color.Default.Foreground};
  padding: 6rem 1.7rem 6rem;
  text-align: center;
  white-space: normal;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;
  text-align: center;
  border-radius: 2.4rem;

  & > ${H2} {
    max-width: 12em;
    margin: 0 auto 1em;
    line-height: 1em;
  }

  & > ${T1} {
    max-width: 24em;
    margin: 1em auto 0;

    ${media.tabletLandscapeAndUp`
      max-width: 28em;
    `}
  }

  ${media.tabletLandscapeAndUp`
    border-radius: 3.5rem;
    height: auto;
    padding: 6rem;
    box-shadow: 0 0.2rem 22.1rem ${({ theme }) => theme.Prompt.Color.Default.Shadow};
  `}
`;

export const Prompt = (props) => {
  const { children, ...otherProps } = props;
  return <Container {...otherProps}>{props.children}</Container>;
};

export const PromptActions = styled.div`
  margin: 3.6rem auto 0;
  max-width: 40rem;
`;
