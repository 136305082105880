import { ButtonOptionPrimary, ButtonOptionSecondary, font, H1, media, T1 } from '@foyyay/flow-elements';
import { signOut } from '@shared/actions/user';
import { Footer } from '@shared/components/Footer';
import { FlowLayout } from '@shared/components/Layout';
import { NUCLEUS_DASHBOARD_BASE_URL } from '@shared/constants/urls';
import { get as _get } from 'lodash';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { AccountContext } from '../../index';

const Account = (props) => {
  if (!props.isAuthenticated) {
    return <Redirect to="sign-in" />;
  }

  return (
    <>
      <FlowLayout contentPadding={'5rem 0 0'} showLogo={false}>
        <div>
          <SectionText title={`Hi ${props.greeting},`}>
            To edit your profile, view your records, and manage all other content and settings – head over to
            your&nbsp;account.
          </SectionText>
          <AccountButtons />
          <SectionReturn>
            <BespokeText1>You're signed in as:</BespokeText1>
            <BespokeText2>{props.name && props.name}</BespokeText2>
            <BespokeText3>{props.email && props.email}</BespokeText3>
          </SectionReturn>
          <SectionReturn>
            <T1>
              <span>If you're on a shared device, you can</span>{' '}
              <TextLink role="button" onClick={props.signOut}>
                sign out
              </TextLink>
            </T1>
          </SectionReturn>
        </div>
      </FlowLayout>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  const firstName = _get(state, 'user.currentUser.profile.first_name');
  const lastName = _get(state, 'user.currentUser.profile.last_name');

  let name;

  if (firstName || lastName) {
    name = [firstName, lastName].filter((el) => el).join(' ');
  }

  return {
    isAuthenticated: state.user.currentUser !== undefined,
    greeting: _get(state, 'user.currentUser.profile.first_name', 'there'),
    name: name,
    email: _get(state, 'user.currentUser.login'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (callBack) => dispatch(signOut(callBack)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);

const SectionText = (props) => {
  return (
    <StyledSectionText>
      <H1>{props.title}</H1>
      <T1>{props.children}</T1>
    </StyledSectionText>
  );
};

const AccountButtons = (props) => {
  const context = useContext(AccountContext);
  return (
    <SectionButtons>
      <a href={`${NUCLEUS_DASHBOARD_BASE_URL}/my`} target="_blank" rel="noopener noreferrer">
        <ButtonOptionPrimary>Go to my account</ButtonOptionPrimary>
      </a>
      <ButtonOptionSecondary onClick={context.goBack}>or, Get started</ButtonOptionSecondary>
    </SectionButtons>
  );
};

const BespokeText1 = styled.p`
  ${font(18, 'Book', -0.5, 15)}
  margin: 1.4rem 0;
  opacity: 0.65;
`;

const BespokeText2 = styled.p`
  ${font(16, 'Bold', -0.5, 14)}
  margin: 0.5rem 0;
`;

const BespokeText3 = styled.p`
  ${font(14, 'Book', -0.25, 10)}
  margin: 0.5rem 0;
  opacity: 0.85;
`;

const SectionReturn = styled.div`
  width: 100%;
  text-align: center;
  span {
    opacity: 0.85;
  }

  margin-top: 4.8rem;
  margin-bottom: 4.8rem;

  ${media.tabletPortraitAndUp`
      margin-top: 5.6rem;
      margin-bottom: 5.6rem;
  `}
`;

const TextLink = styled.span`
  text-decoration: underline;
  transition: opacity 150ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const SectionButtons = styled.div`
  max-width: 35rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;

  ${media.tabletPortraitAndUp`
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  `}
`;

const StyledSectionText = styled.div`
  max-width: 47.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.2rem;
  text-align: center;

  ${media.tabletPortraitAndUp`
    margin-bottom: 3.2rem;
  `}
`;
