import { NUCLEUS_PLATFORM_API_URL } from '@shared/constants/urls';
import { richFetch } from '@shared/lib/richFetch';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAlertSuccess } from './alert';

interface PrayerWall {
  id: string;
  ownerId: string;
}

export interface PrayerWallSubscriber {
  prayerwallId: string;
  personId: string;
  subscribedAt: string;
  createdAt: string;
  updatedAt: string;
}

export const prayerWallSubscribe = async (prayerwall: PrayerWall): Promise<{ subscriber: PrayerWallSubscriber }> => {
  const { id, ownerId } = prayerwall;
  return await richFetch('POST', NUCLEUS_PLATFORM_API_URL, `/prayerwall/${ownerId}/${id}/actions/subscribe`);
};

export const prayerWallUnubscribe = async (prayerwall: PrayerWall): Promise<{ subscriber: PrayerWallSubscriber }> => {
  const { id, ownerId } = prayerwall;
  return await richFetch('POST', NUCLEUS_PLATFORM_API_URL, `/prayerwall/${ownerId}/${id}/actions/unsubscribe`);
};

/**
 * Get a Prayer Wall Subscription, or will throw 404 if not subscribed
 */
export const prayerWallSubscription = async (prayerwall: PrayerWall): Promise<{ subscriber: PrayerWallSubscriber }> => {
  const { id, ownerId } = prayerwall;
  return await richFetch('GET', NUCLEUS_PLATFORM_API_URL, `/prayerwall/${ownerId}/${id}/subscription`);
};

interface State {
  loading: boolean;
  updating: boolean;
  subscriber?: PrayerWallSubscriber;
}

export const usePrayerWallSubscription = (
  prayerwall: PrayerWall
): {
  currentSubscriber: PrayerWallSubscriber | undefined;
  loading: boolean;
  updating: boolean;
  subscribe: () => Promise<void>;
  unsubscribe: () => Promise<void>;
} => {
  const dispatch = useDispatch();
  const [state, setState] = useState<State>({ loading: false, updating: false });

  const setLoading = (value: boolean) => setState((prevState) => ({ ...prevState, loading: value }));
  const setUpdating = (value: boolean) => setState((prevState) => ({ ...prevState, updating: value }));

  const handleRead = useCallback(async () => {
    setLoading(true);
    try {
      const response = await prayerWallSubscription(prayerwall);
      setState({ loading: false, updating: false, subscriber: response.subscriber });
    } catch (error) {
      setState({ loading: false, updating: false });
    }
  }, [prayerwall]);

  const handleSubscribe = useCallback(async () => {
    setUpdating(true);
    const response = await prayerWallSubscribe(prayerwall);
    setState({
      loading: false,
      updating: false,
      subscriber: response.subscriber,
    });
    dispatch(createAlertSuccess('Successfully Subscribed! 🙏'));
  }, [dispatch, prayerwall]);

  const handleUnsubscribe = useCallback(async () => {
    setUpdating(true);
    await prayerWallUnubscribe(prayerwall);
    setState({ loading: false, updating: false });
    dispatch(createAlertSuccess('Successfully Unsubscribed! 👋'));
  }, [dispatch, prayerwall]);

  useEffect(() => {
    handleRead();
  }, [handleRead]);

  return {
    currentSubscriber: state.subscriber,
    loading: state.loading,
    updating: state.updating,
    subscribe: handleSubscribe,
    unsubscribe: handleUnsubscribe,
  };
};
