import { useThunkReducer } from '@shared/hooks/useThunkReducer';
import { selectBirthdayAttribute } from '@shared/reducers/user';
import React from 'react';
import { useSelector as useReduxSelector } from 'react-redux';
import { FlowStep } from '../../components/FlowStep';
import { StepSmartBirthdayInput } from './components/StepSmartBirthdayInput';
import { StepSmartBirthdayPicker } from './components/StepSmartBirthdayPicker';
import {
  init,
  selectCurrentStep,
  selectLabel,
  selectMessage,
  stepSmartBirthdayReducer
} from './reducers/stepSmartBirthdayReducer';

export const StepSmartBirthday = (props) => {
  const birthdayAttribute = useReduxSelector(selectBirthdayAttribute);
  const initializerArg = {
    data: props.data,
    birthdayAttribute: birthdayAttribute,
  };
  const [state, dispatch, useSelector] = useThunkReducer(stepSmartBirthdayReducer, {
    devToolsName: props.devToolsName,
    initializerArg: initializerArg,
    initializer: init,
  });

  const currentStep = useSelector(selectCurrentStep);
  const label = useSelector((state) => selectLabel(state, props.config));
  const message = useSelector((state) => selectMessage(state, props.config, props.data));

  return (
    <FlowStep label={label} message={message}>
      {React.createElement(getStepBy(currentStep), {
        state: state,
        dispatch: dispatch,
        useSelector: useSelector,
        required: props.config.required,
        onComplete: props.completeStep,
      })}
    </FlowStep>
  );
};

const stepBy = {
  input: StepSmartBirthdayInput,
  picker: StepSmartBirthdayPicker,
};

const getStepBy = (currentStep) => stepBy[currentStep] ?? stepBy.input;
