import { createReducer } from '@reduxjs/toolkit';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { formatDate, formatTime, formatValueWithPrefix } from '@shared/lib/format';
import {
  clearDate,
  clearTime,
  goToNextStep,
  goToPrevStep,
  setCurrentStep,
  setDate,
  setInputMode,
  setTime,
} from '../actions/stepDateTimeActions';
import { DATETIME_STEP_LABELS, INPUT_MODE_PICKER } from '../constants';

export const selectSteps = (state) => state.steps;
export const selectFirstStep = (state) => state.steps.slice(0)[0];
export const selectLastStep = (state) => state.steps.slice(-1)[0];
export const selectCurrentStep = (state) => state.currentStep;

export const selectDate = (state) => state.date;
export const selectTime = (state) => state.time;
export const selectInputMode = (state) => state.inputMode;

export const selectLabel = (state, config) => {
  if (selectFirstStep(state) === state.currentStep) {
    return config.label;
  }

  return DATETIME_STEP_LABELS[state.currentStep];
};

export const selectMessage = (state, config, data) => {
  const skipped = data?.skipped === true;

  if (skipped) {
    return `Skipped: "${config.label}"`;
  }

  let message = state?.date ? `On ${formatDate(state.date)}` : '';
  message += state?.time ? ` ${message !== '' ? 'at' : 'At'} ${formatTime(state.time)}` : '';

  return formatValueWithPrefix(config.valuePrefixLabel, message);
};

const initialValue = deepFreeze({
  date: '',
  time: '',
});

const MAP_DATATYPE_TO_STEPS = {
  datetime: ['date', 'time'],
  date: ['date'],
  time: ['time'],
};

const initSteps = (dataType) => {
  return MAP_DATATYPE_TO_STEPS[dataType];
};

export const init = ({ data, config }) => {
  const steps = initSteps(config.dataType);
  return {
    steps: steps,
    currentStep: steps[0],
    inputMode: INPUT_MODE_PICKER,
    date: steps.includes('date') ? data?.value?.date : initialValue.date,
    time: steps.includes('time') ? data?.value?.time : initialValue.time,
  };
};

export const stepDateTimeReducer = createReducer(undefined, {
  [setCurrentStep]: (state, action) => ({
    ...state,
    currentStep: action.payload,
  }),
  [goToNextStep]: (state, action) => {
    const nextStep = state.steps[state.steps.indexOf(state.currentStep) + 1];
    return {
      ...state,
      currentStep: nextStep,
    };
  },
  [goToPrevStep]: (state, action) => {
    const nextStep = state.steps[state.steps.indexOf(state.currentStep) - 1];
    return {
      ...state,
      currentStep: nextStep,
    };
  },
  [setInputMode]: (state, action) => ({
    ...state,
    inputMode: action.payload,
  }),
  [setDate]: (state, action) => ({
    ...state,
    date: action.payload,
  }),
  [clearDate]: (state, action) => ({
    ...state,
    date: initialValue.date,
  }),
  [setTime]: (state, action) => ({
    ...state,
    time: action.payload,
  }),
  [clearTime]: (state, action) => ({
    ...state,
    time: initialValue.time,
  }),
});
