import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { partial as _partial } from 'lodash';
import { getThemeValue } from './Theme';
import { font } from './style-utils';
import { CircleExclamationmark, CircleInfo } from './Icons';

const getScopedThemeValue = _partial(getThemeValue, 'StepAlert');

const Container = styled.div`
  color: ${(props) => getScopedThemeValue('Color', props.variant, 'Foreground')(props)};
  background: ${(props) => getScopedThemeValue('Color', props.variant, 'Background')(props)};
  border-radius: 1.4rem;
  padding: 0.9rem;
  display: flex;
  justify-content: flex-start;
`;

const AlertIcon = styled(CircleExclamationmark)`
  color: ${getScopedThemeValue('Color.error.Icon')};
  height: 3.6rem;
  width: 3.6rem;
`;

const InfoIcon = styled(CircleInfo)`
  color: ${getScopedThemeValue('Color.info.Icon')};
  height: 3.6rem;
  width: 3.6rem;
`;

const TextContainer = styled.div`
  margin-left: 0.9rem;
`;

const Label = styled.h4`
  ${font(18, 'Bold', -0.75, 20)}
  margin: 0;
`;

const Body = styled.p`
  ${font(12, 'Book', -0.5, 14)}
  margin: 0.05rem 0 0;
`;

const ICON_BY = {
  error: AlertIcon,
  info: InfoIcon,
};

export const StepAlert = (props) => {
  const Icon = ICON_BY[props.variant];
  return (
    <Container variant={props.variant}>
      <Icon />
      <TextContainer>
        <Label>{props.label}</Label>
        <Body>{props.children}</Body>
      </TextContainer>
    </Container>
  );
};

StepAlert.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'info']),
};
