import { Button } from '@shared/components/Button';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { SpringboardContext } from '../../../context/SpringboardContext';
import { InfoCardContext } from '../context/InfoCardContext';

export const ActionButton = (props) => {
  const { infocard } = useContext(InfoCardContext);
  const { handleAction } = useContext(SpringboardContext);

  const handleClick = () => {
    handleAction(infocard.action);
  };

  if (infocard.action === undefined) {
    return <DefaultActionButton />;
  }

  return (
    <StyledButton onClick={handleClick} {...props}>
      {infocard.action.label}
    </StyledButton>
  );
};

const DefaultActionButton = (props) => {
  const handleClick = () => {
    // TODO: navigate back to launcher home
  };

  return (
    <StyledButton onClick={handleClick} {...props}>
      Back to home
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

StyledButton.defaultProps = {
  size: 'large',
  shape: 'pill',
  variant: 'primary',
};
