import React, { Fragment } from 'react';

export const AutoLineBreak = ({ text }) => {
  if (text === undefined) {
    return null;
  }
  return text.split('\n').map((substring, index) => {
    return (
      <Fragment key={index}>
        {substring}
        <br />
      </Fragment>
    );
  });
};
