import { T1 } from '@foyyay/flow-elements';
import { FLOW_SUBMISSION_LABEL_BY } from '@shared/constants/flow';
import { selectFlowConfigById } from '@shared/reducers/flows/flowsByIdReducer';
import { selectCurrentUser } from '@shared/reducers/user';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FlowContext } from '../../..';
import { useThankYouController } from '../../../controllers/ThankYouController';
import { AccountButtons } from '../components/AccountButtons';
import { SectionText } from '../components/SectionText';

export const Welcome = (props) => {
  const { user } = useThankYouController();

  if (user.userExists !== true) {
    return <WelcomeNewUser />;
  }

  return <WelcomeExistingUser />;
};

const WelcomeExistingUser = (props) => {
  const { signOut } = useThankYouController();
  const { currentFlowId } = useContext(FlowContext);
  const flowConfig = useSelector((state) => selectFlowConfigById(state, currentFlowId));
  const currentUser = useSelector(selectCurrentUser);
  const message = `Hi ${currentUser?.profile?.first_name ? currentUser?.profile?.first_name : 'there'},`;

  return (
    <>
      <SectionText title={message}>
        Your {FLOW_SUBMISSION_LABEL_BY[flowConfig.flow_type]} has been saved in your account. You can view or manage
        it&nbsp;anytime.
      </SectionText>
      <AccountButtons />
      <SectionReturn>
        <T1>
          <span>If you're on a shared device, you can</span>{' '}
          <TextLink role="button" onClick={signOut}>
            sign out
          </TextLink>
        </T1>
      </SectionReturn>
    </>
  );
};

const WelcomeNewUser = (props) => {
  const { signOut } = useThankYouController();
  const { currentFlowId } = useContext(FlowContext);
  const flowConfig = useSelector((state) => selectFlowConfigById(state, currentFlowId));

  return (
    <>
      <SectionText title="Welcome!">
        Your {FLOW_SUBMISSION_LABEL_BY[flowConfig.flow_type]} has been saved in your new Nucleus account. Now you can
        easily manage your profile or take more next steps&nbsp;anytime.
      </SectionText>
      <AccountButtons />
      <SectionReturn>
        <T1>
          <span>If you're on a shared device, you can</span>{' '}
          <TextLink role="button" onClick={signOut}>
            sign out
          </TextLink>
        </T1>
      </SectionReturn>
    </>
  );
};

const SectionReturn = styled.div`
  width: 100%;
  text-align: center;
  span {
    opacity: 0.65;
  }
`;

const TextLink = styled.span`
  text-decoration: underline;
  transition: opacity 150ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
