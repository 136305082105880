import { ButtonOption, ButtonOptionSecondary } from '@foyyay/flow-elements';
import { withStopPropagation } from '@shared/lib/events';
import { buildConfigValidator } from '@shared/lib/schema';
import React, { useState } from 'react';
import * as yup from 'yup';
import { FlowStep } from '../../components/FlowStep';

export const StepPrayerPrivacy = (props) => {
  const [showPrivate, setShowPrivate] = useState(false);

  const label = showPrivate ? 'Now, choose a private inbox' : props.config.label;
  const description = showPrivate ? '(Your prayer won’t be accessible to anyone else)' : props.config.message;

  const message = getMessage(props.data);

  return (
    <FlowStep hasError={props.error !== undefined} label={label} description={description} message={message}>
      <OptionsList showPrivate={showPrivate} options={props.config.options} onComplete={props.completeStep} />
      <SecondaryAction showPrivate={showPrivate} setShowPrivate={setShowPrivate} options={props.config.options} />
    </FlowStep>
  );
};

const optionSchema = yup.object().shape({
  id: yup.string(),
  label: yup.string(),
  value: yup.string(),
  peopleCount: yup.number(),
  privacy: yup.string(),
});

const configSchema = {
  description: yup.string(),
  label: yup.string().min(3).required(),
  options: yup.array().of(optionSchema).min(1).required(),
  required: yup.bool().required(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepPrayerPrivacy.isConfigValid = isConfigValid;

const MESSAGE_BY = {
  public: () => 'Share on the Prayer Wall',
  protected: () => 'Share with Prayer Team and Staff',
  private: (label) => ['Keep confidential to:', label].join(' '),
};

const getMessage = (data) => {
  if (data === undefined) {
    return '';
  }
  return MESSAGE_BY[data.value.privacy](data.value.label);
};

const OptionsList = (props) => {
  return props.options
    .filter((option) => (props.showPrivate ? option.privacy === 'private' : option.privacy !== 'private'))
    .map((option) => <Option key={option.id} option={option} onComplete={props.onComplete} />);
};

const Option = (props) => {
  const handleClick = withStopPropagation(() => props.onComplete({ value: props.option }));
  return (
    <ButtonOption badge={getPeopleBadge(props.option.peopleCount)} onClick={handleClick}>
      {props.option.label}
    </ButtonOption>
  );
};

const getPeopleBadge = (count) => {
  if (count === undefined) return;
  if (count === 1) return `${count} Person`;
  return `${count} People`;
};

const SecondaryAction = (props) => {
  return props.showPrivate ? <BackToStandardOptions {...props} /> : <MarkAsConfidential {...props} />;
};

const MarkAsConfidential = (props) => {
  const havePrivateOptions = props.options.filter((option) => option.privacy === 'private').length > 0;
  const handleClick = withStopPropagation(() => props.setShowPrivate(true));

  if (havePrivateOptions === false) {
    return null;
  }

  return <ButtonOptionSecondary onClick={handleClick}>or, Mark as confidential</ButtonOptionSecondary>;
};

const BackToStandardOptions = (props) => {
  const handleClick = () => props.setShowPrivate(false);
  return <ButtonOptionSecondary onClick={handleClick}>or, Back to standard options</ButtonOptionSecondary>;
};
