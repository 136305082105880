import { useThunkReducer } from '@shared/hooks/useThunkReducer';
import React from 'react';
import { StepAddContactFlow } from './components/StepAddContactFlow';
import { StepContactCard } from './components/StepContactCard';
import { StepContactField } from './components/StepContactField';
import { StepContactList } from './components/StepContactList';
import { StepContactOptions } from './components/StepContactOptions';
import { init, selectCurrentStep, stepMultipleContactReducer } from './reducers/stepMultipleContactReducer';

export const StepMultipleContactContext = React.createContext();

export const StepMultipleContact = (props) => {
  const initializerArg = { data: props.data };
  const [state, dispatch, useSelector] = useThunkReducer(stepMultipleContactReducer, {
    devToolsName: props.devToolsName,
    initializerArg: initializerArg,
    initializer: init,
  });

  const context = {
    state: state,
    dispatch: dispatch,
    useSelector: useSelector,
  };

  const currentStep = useSelector(selectCurrentStep);

  return (
    <StepMultipleContactContext.Provider value={context}>
      {React.createElement(getStepBy(currentStep), props)}
    </StepMultipleContactContext.Provider>
  );
};

const stepBy = {
  add: StepAddContactFlow,
  card: StepContactCard,
  field: StepContactField,
  list: StepContactList,
  options: StepContactOptions,
};

const getStepBy = (currentStep) => {
  return stepBy[currentStep] ?? stepBy.field;
};
