import { pick as _pick } from 'lodash';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { applyLinkParametersToFlow, fetchFlowConfig } from '@shared/actions/flows';
import { STATE_ERROR, STATE_LOADED, STATE_LOADING } from '@shared/constants/state';
import {
  selectFlowConfigByShortcode,
  selectFlowConfigLoadedAtByShortcode,
} from '@shared/reducers/flows/flowsByIdReducer';
import { Hub } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useFlowConfig = (flowShortcode, revision, linkParams) => {
  const config = useSelector((state) => selectFlowConfigByShortcode(state, flowShortcode));
  const configLoadedAt = useSelector((state) => selectFlowConfigLoadedAtByShortcode(state, flowShortcode));
  const dispatch = useDispatch();

  const initialState = configLoadedAt < new Date() ? STATE_LOADED : STATE_LOADING;
  const [state, setState] = useState(initialState);

  const fetchConfig = useCallback(async () => {
    try {
      await dispatch(fetchFlowConfig({ flowShortcode: flowShortcode, revision: revision })).then(unwrapResult);
    } catch (error) {
      setState(STATE_ERROR);
      return;
    }

    setState(STATE_LOADED);
  }, [dispatch, flowShortcode, revision]);

  const registerAuthListeners = useCallback(() => {
    const listener = buildAuthListener(fetchConfig);
    Hub.listen('auth', listener);
    return () => Hub.remove('auth', listener);
  }, [fetchConfig]);

  useEffect(() => {
    fetchConfig();
    return registerAuthListeners();
  }, [fetchConfig, registerAuthListeners]);

  useEffect(() => {
    if (state === STATE_LOADED) {
      dispatch(applyLinkParametersToFlow(flowShortcode, linkParams));
    }
  }, [dispatch, flowShortcode, linkParams, state]);

  const reloadFlow = useCallback(async () => {
    setState(STATE_LOADING);
    await fetchConfig();
  }, [fetchConfig]);

  useEffect(() => {
    Sentry.setContext('flow', {
      ..._pick(config, ['id', 'name', 'title', 'revision_key', 'shortcode']),
      shortcode: flowShortcode,
    });
    return () => Sentry.setContext('flow', null);
  }, [config, flowShortcode]);

  return [config, state, reloadFlow];
};

const buildAuthListener = (fetchConfig) => {
  const EVENT_MAP = {
    signIn: () => fetchConfig(),
    signOut: () => fetchConfig(),
    default: () => {},
  };
  return (data) => (EVENT_MAP[data.payload.event] ?? EVENT_MAP.default)();
};
