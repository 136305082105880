import { Container, H1, T1 } from '@foyyay/flow-elements';
import React from 'react';

export const CompleteAccountSetup = (props) => {
  return (
    <div style={{ paddingTop: '6rem' }}>
      <Container maxWidth="80rem" style={{ textAlign: 'center' }}>
        <H1>Please check your&nbsp;email!</H1>
      </Container>
      <Container maxWidth="51.9rem" style={{ textAlign: 'center' }}>
        <T1>
          You never finished setting up your account, so you’ll need to verify your email, and then create a password.
          To do this, simply click the “Create a password” button in the email we just sent you, and we’ll get you to
          the right place.
        </T1>
      </Container>
    </div>
  );
};
