import PropTypes from 'prop-types';
import React, { useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TextArea } from './TextArea';
import { Styles } from './Typography';
import { font, media } from './style-utils';

export const InputText = React.forwardRef((props, ref) => {
  const textAreaRef = useRef();
  useImperativeHandle(ref, () => textAreaRef.current);

  const { style, className, limit, onChange, onEnter, placeholder, softLimit, value, ...otherProps } = props;

  const haveLimit = limit !== undefined;
  const atLimit = props.value.length >= props.limit;
  const overLimit = props.value.length > props.limit;

  const initialState = {
    textAreaHeight: 'auto',
    parentHeight: 'auto',
  };

  const [state, setState] = useState(initialState);

  useLayoutEffect(() => {
    setState({
      parentHeight: `${textAreaRef.current.scrollHeight}px`,
      textAreaHeight: `${textAreaRef.current.scrollHeight}px`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, textAreaRef.current]);

  const handleChange = (e) => {
    if (atLimit) {
      return;
    }

    setState({
      textAreaHeight: 'auto',
      parentHeight: `${textAreaRef.current.scrollHeight}px`,
    });

    props.onChange(unmask(props.prompt, props.value, e.target.value));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && typeof props.onEnter === 'function') {
      props.onEnter(e);
    }
  };

  let inputValue = props.value;

  if (haveLimit) {
    inputValue = inputValue.slice(0, props.limit);
  }

  if (overLimit) {
    inputValue = [inputValue.trim(), '…'].join('');
  }

  return (
    <Container style={props.style} className={props.className}>
      <Parent style={{ height: state.parentHeight }}>
        <StyledTextArea
          style={{ height: state.textAreaHeight }}
          type="text"
          rows="1"
          placeholder={props.placeholder}
          value={mask(props.prompt, inputValue)}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          disabled={overLimit}
          {...otherProps}
          ref={textAreaRef}
        />
      </Parent>
      {props.limit && (
        <Counter>
          {Math.min(props.value.length, props.limit) + '/' + props.limit}
          {props.softLimit && '+'}
        </Counter>
      )}
    </Container>
  );
});

InputText.propType = {
  limit: PropTypes.number,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  prompt: PropTypes.string,
  softLimit: PropTypes.bool,
  value: PropTypes.string,
};

InputText.defaultProps = {
  placeholder: '',
};

const fontStyleBy = {
  'extra-small': Styles.F5,
  small: Styles.F4,
  normal: Styles.F3,
  large: Styles.F2,
  'extra-large': Styles.F1,
};

const textStyles = (textSize) => fontStyleBy[textSize] ?? fontStyleBy.normal;

const StyledTextArea = styled(TextArea)`
  ${({ textSize }) => textStyles(textSize)};
  color: ${({ theme }) => theme.InputText.Color.Default};
  min-height: ${({ suggestedLength }) => suggestedLength === 'long' && '12rem'};
  padding-bottom: 3rem;
  resize: none;
  white-space: pre-wrap;

  ${media.tabletLandscapeAndUp`
    min-height: ${({ suggestedLength }) => suggestedLength === 'long' && '15rem'};
  `}
`;

const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

const Parent = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const Counter = styled.div`
  ${font(18, 'Bold', -0.4)};
  bottom: 0rem;
  color: ${({ theme }) => theme.InputText.Color.Counter};
  opacity: 0.5;
  position: absolute;
  right: 2.5rem;
  user-select: none;
`;

const mask = (prefix, value) => {
  if (prefix === undefined) {
    return value;
  }

  return prefix + value;
};

const unmask = (prefix, previous, value) => {
  if (prefix === undefined) {
    return value;
  }

  if (value === '') {
    return '';
  }

  if (value.indexOf(prefix) >= 0) {
    return value.slice(0, value.indexOf(prefix)) + value.slice(value.indexOf(prefix) + prefix.length);
  }

  if (value.indexOf(prefix.slice(0, -1)) >= 0) {
    return '';
  }

  if (value.slice(0, prefix.length) !== prefix) {
    return previous;
  }

  return value;
};
