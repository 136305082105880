import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { IconAmex, IconDiscover, IconMasterCard, IconVisa } from './Icons';
import { Input } from './Input';
import { font } from './style-utils';

const InputCardNumber = styled(Input)`
  ${font(31, 'Bold', -0.75)}

  @media (min-width: 480px) {
    font-size: 4.6rem;
  }

  color: ${({ theme }) => theme.InputCard.Color.Default};
`;

const InputStyles = css`
  ${font(24, 'Book', -0.5)}

  @media (min-width: 480px) {
    font-size: 3rem;
  }
`;

const InputExpirationDate = styled(Input)`
  ${InputStyles};
  color: ${({ theme }) => theme.InputCard.Color.Default};
  width: 4em;
`;

const InputCvv = styled(Input)`
  ${InputStyles};
  color: ${({ theme }) => theme.InputCard.Color.Default};
  width: 3em;
  padding-left: 0.5em;

  @media (min-width: 360px) {
    padding-left: 1em;
  }
`;

const InputPostalCode = styled(Input)`
  ${InputStyles};
  color: ${({ theme }) => theme.InputCard.Color.Default};
  width: 7em;
  padding-left: 0.75em;

  @media (min-width: 360px) {
    padding-left: 1.5em;
  }
`;

const F2Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 3.6rem;
  overflow: hidden;

  @media (min-width: 480px) {
    height: 4.6rem;
  }
`;

const F3Container = styled.div`
  align-items: center;
  display: flex;
  height: 2.4rem;
  overflow: hidden;

  @media (min-width: 480px) {
    height: 3rem;
  }
`;

const CardNumber = (props) => {
  const { onCreditCardTypeChanged, ...otherProps } = props;
  return (
    <F2Container>
      <InputCardNumber
        as={Cleave}
        options={{
          creditCard: true,
          onCreditCardTypeChanged: (type) => {
            onCreditCardTypeChanged(type);
          },
        }}
        type="text"
        inputMode="numeric"
        // Fixme: verify that autocomplete works
        name="cardAccountNumber"
        placeholder="4141 0192 0103 1930"
        autoComplete="cc-number"
        autoCorrect="off"
        spellCheck="false"
        {...otherProps}
      />
      <CardTypeIndicator value={props.value} />
    </F2Container>
  );
};

const CardTypeIndicator = (props) => {
  const cardType = detectCardType(props.value);

  switch (cardType) {
    case 'Visa':
      return (
        <CardLogo>
          <IconVisa />
        </CardLogo>
      );
    case 'MasterCard':
      return (
        <CardLogo>
          <IconMasterCard />
        </CardLogo>
      );
    case 'Amex':
      return (
        <CardLogo>
          <IconAmex />
        </CardLogo>
      );
    case 'Discover':
      return (
        <CardLogo>
          <IconDiscover />
        </CardLogo>
      );
    default:
      return null;
  }
};

const ExpireDate = (props) => {
  return (
    <F3Container>
      <InputExpirationDate
        as={Cleave}
        options={{
          date: true,
          datePattern: ['m', 'y'],
        }}
        type="text"
        inputMode="numeric"
        name="date"
        placeholder="MM/YY"
        autoComplete="cc-exp"
        autoCorrect="off"
        spellCheck="false"
        {...props}
      />
    </F3Container>
  );
};

const CardCVV = (props) => {
  return (
    <F3Container>
      <InputCvv
        as={Cleave}
        options={{ numericOnly: true, blocks: [4] }}
        type="text"
        inputMode="numeric"
        name="cardCvv"
        placeholder="CVV"
        autoComplete="cc-csc"
        autoCorrect="off"
        spellCheck="false"
        size="4"
        {...props}
      />
    </F3Container>
  );
};

const CardPostalCode = (props) => {
  return (
    <F3Container>
      <InputPostalCode
        as={Cleave}
        options={{ blocks: [10] }}
        type="text"
        name="postal-code"
        placeholder={props.placeholder || 'Postal Code'}
        autoComplete="postal-code"
        autoCorrect="off"
        spellCheck="false"
        size="10"
        {...props}
      />
    </F3Container>
  );
};

const CARD_TYPES_LENGTHS = {
  visa: 16,
  amex: 15,
};

export class InputCard extends Component {
  static propTypes = {
    cardAccountNumber: PropTypes.string,
    cardCvv: PropTypes.string,
    cardExpireMonth: PropTypes.string,
    cardExpireYear: PropTypes.string,
    cardPostalCode: PropTypes.string,
    cardType: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
  };

  static defaultProps = {
    cardAccountNumber: '',
    cardCvv: '',
    cardExpireMonth: '',
    cardExpireYear: '',
    cardPostalCode: '',
    cardType: '',
    onChange: () => {},
    onEnter: () => {},
  };

  constructor(props) {
    super(props);
    this.inputCardRef = React.createRef();
    this.inputDateRef = React.createRef();
    this.inputCvvRef = React.createRef();
    this.inputPostalCode = React.createRef();

    this.state = {
      cardType: '',
    };
  }

  combinedMonthYear = () => {
    const { cardExpireMonth, cardExpireYear } = this.props;
    return cardExpireMonth + cardExpireYear;
  };

  focus = () => {
    this.inputCardRef.focus();
  };

  handleCardPostalCodeChange = (e) => {
    this.onChangeCallback({ cardPostalCode: e.target.value });
  };

  handleCardCvvChange = (e) => {
    const maxLength = this.state.cardType === 'amex' ? 4 : 3;
    this.onChangeCallback({ cardCvv: e.target.value });
    if (e.target.rawValue.length === maxLength) {
      this.inputPostalCodeRef.focus();
    }
  };

  handleDateChange = (e) => {
    const date = e.target.value;
    const words = date.split('/');
    this.onChangeCallback({ cardExpireMonth: words.shift(), cardExpireYear: words.shift() });
    if (e.target.rawValue.length === 4) {
      this.inputCvvRef.focus();
    }
  };

  handleCardAccountNumberChange = (e) => {
    const maxLength = CARD_TYPES_LENGTHS[this.state.cardType] || 16;
    this.onChangeCallback({ cardAccountNumber: e.target.rawValue });
    if (e.target.rawValue.length === maxLength) {
      this.inputDateRef.focus();
    }
  };

  handleCardTypeChange = (type) => {
    if (this.state.cardType !== type) {
      this.setState({ cardType: type });
    }
    this.onChangeCallback({ cardType: type });
  };

  onChangeCallback = (value) => {
    return this.props.onChange({
      ...this.props.card,
      ...value,
    });
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.props.onEnter) {
      this.props.onEnter(e);
    }
  };

  render() {
    return (
      <GridContainer style={this.props.style} className={this.props.className}>
        <CardNumber
          value={this.props.cardAccountNumber}
          onChange={this.handleCardAccountNumberChange}
          onKeyPress={this.handleKeyPress}
          onCreditCardTypeChanged={this.handleCardTypeChange}
          htmlRef={(ref) => (this.inputCardRef = ref)}
        />
        <FlexContainer>
          <ExpireDate
            value={this.combinedMonthYear()}
            onChange={this.handleDateChange}
            onKeyPress={this.handleKeyPress}
            htmlRef={(ref) => (this.inputDateRef = ref)}
          />
          <CardCVV
            value={this.props.cardCvv}
            onChange={this.handleCardCvvChange}
            onKeyPress={this.handleKeyPress}
            htmlRef={(ref) => (this.inputCvvRef = ref)}
          />
          <CardPostalCode
            value={this.props.cardPostalCode}
            onChange={this.handleCardPostalCodeChange}
            onKeyPress={this.handleKeyPress}
            htmlRef={(ref) => (this.inputPostalCodeRef = ref)}
            placeholder={this.props.postalCodePlaceholder}
          />
        </FlexContainer>
      </GridContainer>
    );
  }
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-row-gap: 2rem;
  margin-bottom: 3.5rem;

  @media (min-width: 480px) {
    grid-row-gap: 1.6rem;
    margin-bottom: 4.9rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;

const CardLogo = styled.div`
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.InputCard.Color.Default};
  box-shadow: 0 0.2rem 0.4rem 0 ${({ theme }) => theme.Step.Color.Default.Shadow};
  border-radius: 5px;
  width: 3.716rem;
  height: 2.4rem;
  overflow: hidden;

  > svg,
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const detectCardType = (value) => {
  const cardTypes = [
    {
      type: 'Visa',
      // Begins with 4
      regex: /^4[0-9]{0,15}/,
    },
    {
      type: 'MasterCard',
      // Begins with 51-55, 2221–2720
      regex: /^(5[1-5][0-9]{0,14}|2(2[2-9][0-9]{0,12}|[3-6][0-9]{0,13}|7[0-1][0-9]{0,12}|720[0-9]{0,12}))/,
    },
    {
      type: 'Amex',
      // Begins with 34 or 37
      regex: /^3[47][0-9]{0,13}/,
    },
    {
      type: 'Discover',
      // Begins with 6011, 622126-622925, 644-649, 65
      regex:
        /^(6011[0-9]{0,12}|622(12[6-9][0-9]{0,11}|[2-8][0-9]{0,12}|9[0-2][0-9]{0,11}|925[0-9]{0,11})|64[4-9][0-9]{0,13}|65[0-9]{0,14})/,
    },
  ];

  for (const cardType of cardTypes) {
    if (value.match(cardType.regex)) {
      return cardType.type;
    }
  }

  return 'Unknown';
};
