const connectToChild = ({ childOrigin = '*', iframe, methods = {}, window }) => {
  const callbacks = [];
  const destroy = () => callbacks.forEach((callback) => callback());

  const serializedMethods = serializeMethods(methods);

  const handleSynMessage = (event) => {
    if (childOrigin !== '*' && event.origin !== childOrigin) {
      return;
    }

    const synAckMessage = {
      nucleus: 'synAck',
      methodNames: Object.keys(serializedMethods),
    };

    event.source.postMessage(synAckMessage, '*');
  };

  let destroyCallReceiver;
  let receiverMethodNames;
  const callSender = {};

  const handleAckMessage = (event) => {
    if (childOrigin !== '*' && event.origin !== childOrigin) {
      return;
    }

    const info = {
      local: window,
      remote: event.source,
      originForSending: '*',
      originForReceiving: childOrigin,
    };

    if (destroyCallReceiver) {
      destroyCallReceiver();
    }

    destroyCallReceiver = connectCallReceiver(info, serializedMethods);
    callbacks.push(destroyCallReceiver);

    if (receiverMethodNames) {
      receiverMethodNames.forEach((receiverMethodName) => {
        delete callSender[receiverMethodName];
      });
    }

    receiverMethodNames = event.data.methodNames;

    const destroyCallSender = connectCallSender(callSender, info, receiverMethodNames, destroy);

    callbacks.push(destroyCallSender);

    return callSender;
  };

  const promise = new Promise((resolve, reject) => {
    const handleMessage = (event) => {
      if (event.source !== iframe.contentWindow || !event.data) {
        return;
      }

      if (event.data.nucleus === 'syn') {
        handleSynMessage(event);
        return;
      }

      if (event.data.nucleus === 'ack') {
        const callSender = handleAckMessage(event);
        if (callSender) {
          resolve(callSender);
        }
        return;
      }
    };

    window.addEventListener('message', handleMessage);
    callbacks.push(() => window.removeEventListener('message', handleMessage));
  });

  return {
    destroy: destroy,
    promise: promise,
  };
};

const connectToParent = ({ parentOrigin = '*', methods = {} }) => {
  const callbacks = [];
  const destroy = () => callbacks.forEach((callback) => callback());
  const serializedMethods = serializeMethods(methods);

  const handleSynAckMessage = (event) => {
    if (!(parentOrigin === '*' || parentOrigin === event.origin)) {
      return;
    }

    const ackMessage = {
      nucleus: 'ack',
      methodNames: Object.keys(serializedMethods),
    };

    window.parent.postMessage(ackMessage, '*');

    const info = {
      local: window,
      remote: window.parent,
      originForSending: '*',
      originForReceiving: event.origin,
    };

    const destroyCallReceiver = connectCallReceiver(info, serializedMethods);

    callbacks.push(destroyCallReceiver);

    const callSender = {};
    const destroyCallSender = connectCallSender(callSender, info, event.data.methodNames, destroy);
    callbacks.push(destroyCallSender);

    return callSender;
  };

  const sendSynMessage = () => {
    window.parent.postMessage({ nucleus: 'syn' }, '*');
  };

  const promise = new Promise((resolve, reject) => {
    const handleMessage = (event) => {
      if (!event.data) {
        return;
      }

      if (event.data.nucleus === 'synAck') {
        const callSender = handleSynAckMessage(event);

        if (callSender) {
          window.removeEventListener('message', handleMessage);
          resolve(callSender);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    sendSynMessage();

    callbacks.push(() => window.removeEventListener('message', handleMessage));
  });

  return {
    destroy: destroy,
    promise: promise,
  };
};

export const PostMessage = {
  connectToChild: connectToChild,
  connectToParent: connectToParent,
};

const connectCallSender = (callSender, info, methodKeyPaths, destroyConnection) => {
  const { local, remote, originForSending, originForReceiving } = info;
  let destroyed = false;
  const createMethodProxy = (methodName) => {
    return (...args) => {
      let iframeRemoved;
      try {
        if (remote.closed) {
          iframeRemoved = true;
        }
      } catch (e) {
        iframeRemoved = true;
      }

      if (iframeRemoved) {
        destroyConnection();
      }

      if (destroyed) {
        const error = new Error(`Unable to send ${methodName}() call due to destroyed connection`);

        error.code = 'ConnectionDestroyed';
        throw error;
      }

      return new Promise((resolve, reject) => {
        const id = generateId();
        const handleMessageEvent = (event) => {
          if (event.source !== remote || event.data.nucleus !== 'reply' || event.data.id !== id) {
            return;
          }

          if (originForReceiving !== '*' && event.origin !== originForReceiving) {
            return;
          }

          const replyMessage = event.data;

          local.removeEventListener('message', handleMessageEvent);

          let returnValue = replyMessage.returnValue;

          if (replyMessage.returnValueIsError) {
            returnValue = deserializeError(returnValue);
          }

          (replyMessage.resolution === 'fulfilled' ? resolve : reject)(returnValue);
        };

        local.addEventListener('message', handleMessageEvent);
        const callMessage = {
          nucleus: 'call',
          id: id,
          methodName: methodName,
          args: args,
        };
        remote.postMessage(callMessage, originForSending);
      });
    };
  };

  // Wrap each method in a proxy which sends it to the corresponding receiver.
  const flattenedMethods = methodKeyPaths.reduce((api, name) => {
    api[name] = createMethodProxy(name);
    return api;
  }, {});

  // Unpack the structure of the provided methods object onto the CallSender, exposing
  // the methods in the same shape they were provided.
  Object.assign(callSender, deserializeMethods(flattenedMethods));

  return () => {
    destroyed = true;
  };
};

const connectCallReceiver = (info, serializedMethods) => {
  const { local, remote, originForSending, originForReceiving } = info;
  let destroyed = false;

  const handleMessageEvent = (event) => {
    if (event.data.nucleus !== 'call') {
      return;
    }

    if (originForReceiving !== '*' && event.origin !== originForReceiving) {
      return;
    }

    const callMessage = event.data;
    const { methodName, args, id } = callMessage;

    const createPromiseHandler = (resolution) => {
      return (returnValue) => {
        if (destroyed) {
          return;
        }

        const message = {
          nucleus: 'reply',
          id: id,
          resolution: resolution,
          returnValue: returnValue,
        };

        if (resolution === 'rejected' && returnValue instanceof Error) {
          message.returnValue = serializeError(returnValue);
          message.returnValueIsError = true;
        }

        try {
          remote.postMessage(message, originForSending);
        } catch (err) {
          if (err.name === 'DataCloneError') {
            const errorReplyMessage = {
              nucleus: 'reply',
              id: id,
              resolution: 'rejected',
              returnValue: serializeError(err),
              returnValueIsError: true,
            };
            remote.postMessage(errorReplyMessage, originForSending);
          }

          throw err;
        }
      };
    };

    new Promise((resolve) => resolve(serializedMethods[methodName].apply(serializedMethods, args))).then(
      createPromiseHandler('fulfilled'),
      createPromiseHandler('rejected')
    );
  };

  local.addEventListener('message', handleMessageEvent);

  return () => {
    destroyed = true;
    local.removeEventListener('message', handleMessageEvent);
  };
};

let id = 0;
const generateId = () => ++id;

const KEY_PATH_DELIMITER = '.';

const keyPathToSegments = (keyPath) => (keyPath ? keyPath.split(KEY_PATH_DELIMITER) : []);
const segmentsToKeyPath = (segments) => segments.join(KEY_PATH_DELIMITER);

const createKeyPath = (key, prefix) => {
  const segments = keyPathToSegments(prefix || '');
  segments.push(key);
  return segmentsToKeyPath(segments);
};

const setAtKeyPath = (subject, keyPath, value) => {
  const segments = keyPathToSegments(keyPath);

  segments.reduce((prevSubject, key, idx) => {
    if (typeof prevSubject[key] === 'undefined') {
      prevSubject[key] = {};
    }

    if (idx === segments.length - 1) {
      prevSubject[key] = value;
    }

    return prevSubject[key];
  }, subject);

  return subject;
};

const serializeMethods = (methods, prefix) => {
  const flattenedMethods = {};

  Object.keys(methods).forEach((key) => {
    const value = methods[key];
    const keyPath = createKeyPath(key, prefix);

    if (typeof value === 'object') {
      // Recurse into any nested children.
      Object.assign(flattenedMethods, serializeMethods(value, keyPath));
    }

    if (typeof value === 'function') {
      // If we've found a method, expose it.
      flattenedMethods[keyPath] = value;
    }
  });

  return flattenedMethods;
};

const deserializeMethods = (flattenedMethods) => {
  const methods = {};

  for (const keyPath in flattenedMethods) {
    setAtKeyPath(methods, keyPath, flattenedMethods[keyPath]);
  }

  return methods;
};

const serializeError = ({ name, message, stack }) => ({
  name: name,
  message: message,
  stack: stack,
});

const deserializeError = (obj) => {
  const deserializedError = new Error();
  Object.keys(obj).forEach((key) => (deserializedError[key] = obj[key]));
  return deserializedError;
};
