import { colorStyles, font, LoadingSpinner, LOADING_SPINNER_SIZES, media, SIZE } from '@foyyay/flow-elements';
import { omit as _omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Label = styled.span`
  display: inline-block;
  white-space: nowrap;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Icon = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;

  & > * {
    width: 100%;
  }
`;

const IconRight = styled.span`
  flex-shrink: 0;
`;

const IconLeft = styled.span``;

const ButtonStyles = {
  base: css`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: auto;
    max-width: 100%;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    text-decoration: none !important;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1), background-color 0.1s cubic-bezier(0, 0, 1, 1);

    @media (hover: hover) {
      :hover {
        transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1), background-color 0s cubic-bezier(0, 0, 1, 1);
      }
    }

    :active:not([disabled]) {
      transform: scale(0.97);
      transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1), background-color 0s cubic-bezier(0, 0, 1, 1);
    }

    position: relative;
    /* :after {
      content: '';
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: ${(props) => props.theme.Button.Color.ActiveHighlight};
      pointer-events: none;
      transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);
      opacity: 0;
    }

    :active:not([disabled]),
    :focus-visible {
      :after {
        opacity: 1;
        transition: opacity 0s cubic-bezier(0, 0, 1, 1);
      }
    } */

    :disabled {
      cursor: default;
      opacity: 0.35;
    }

    ${(props) => {
      if (props.disabled === true) {
        return css`
          pointer-events: none;
          opacity: 0.35;
        `;
      }
    }}
  `,
};

const ButtonSize = {
  small: css`
    height: 2.4rem;

    :active:not([disabled]) {
      transform: scale(0.96);
    }

    ${Label} {
      ${font(12, 'Bold', -0.5)}
      padding-left: ${SIZE[1]};
      padding-right: ${SIZE[1]};

      &:first-child {
        padding-left: ${SIZE[3]};
      }

      &:last-child {
        padding-right: ${SIZE[3]};
      }
    }

    ${IconRight}, ${IconLeft} {
      padding: ${SIZE[1]};
      max-height: 100%;
    }

    ${media.tabletLandscapeAndUp`
      height: 3.6rem;

      ${Label} {
        ${font(16, 'Bold', -0.75)}

        &:first-child {
          padding-left: ${SIZE[4]};
        }

        &:last-child {
          padding-right: ${SIZE[4]};
        }
      }
    `}
  `,
  medium: css`
    height: 3rem;

    :active:not([disabled]) {
      transform: scale(0.97);
    }

    ${Label} {
      ${font(14, 'Bold', -0.5, 16)}

      &:first-child {
        padding-left: ${SIZE[2]};
      }

      &:last-child {
        padding-right: ${SIZE[2]};
      }
    }

    ${IconRight}, ${IconLeft} {
      padding: ${SIZE[2]};
    }

    ${media.tabletLandscapeAndUp`
      height: 4rem;

      ${Label} {
        ${font(20, 'Bold', -0.5, 24)}

        &:first-child {
          padding-left: ${SIZE[4]};
        }

        &:last-child {
          padding-right: ${SIZE[4]};
        }
      }
    `}
  `,
  large: css`
    height: 4.5rem;

    :active:not([disabled]) {
      transform: scale(0.98);
    }

    ${Label} {
      ${font(20, 'Medium', -0.75, 24)}

      &:first-child {
        padding-left: ${SIZE[3]};
      }

      &:last-child {
        padding-right: ${SIZE[3]};
      }
    }

    ${IconRight}, ${IconLeft} {
      padding: ${SIZE[2]};
    }

    ${media.tabletLandscapeAndUp`
      height: 5rem;

      ${Label} {
        ${font(22, 'Medium', -1, 26)}

        &:first-child {
          padding-left: ${SIZE[4]};
        }

        &:last-child {
          padding-right: ${SIZE[4]};
        }
      }
    `}
  `,
  xlarge: css`
    height: 5.5rem;

    :active:not([disabled]) {
      transform: scale(0.98);
    }

    ${Label} {
      ${font(22, 'Medium', -1, 24)}

      &:first-child {
        padding-left: ${SIZE[3]};
      }

      &:last-child {
        padding-right: ${SIZE[3]};
      }
    }

    ${IconRight}, ${IconLeft} {
      padding: ${SIZE[2]};
    }

    ${media.tabletLandscapeAndUp`
    height: 7.5rem;

    ${Label} {
      ${font(28, 'Medium', -1.25, 30)}

      &:first-child {
        padding-left: ${SIZE[4]};
      }

      &:last-child {
        padding-right: ${SIZE[4]};
      }
    }
  `}
  `,
};

const ButtonShape = {
  circle: css`
    ${(props) => {
      switch (props.size) {
        case 'small':
          return css`
            width: ${SIZE[6]};
            border-radius: ${SIZE[6]};
          `;
        case 'large':
        case 'xlarge':
          return css`
            width: ${SIZE[10]};
            border-radius: ${SIZE[10]};
          `;
        case 'medium':
        default:
          return css`
            width: ${SIZE[8]};
            border-radius: ${SIZE[8]};
          `;
      }
    }}
  `,
  pill: css`
    ${(props) => {
      switch (props.size) {
        case 'small':
          return css`
            border-radius: ${SIZE[6]};
          `;
        case 'large':
        case 'xlarge':
          return css`
            border-radius: ${SIZE[10]};
          `;
        case 'medium':
        default:
          return css`
            border-radius: ${SIZE[8]};
          `;
      }
    }}
  `,
  rounded: css`
    ${(props) => {
      switch (props.size) {
        case 'small':
          return css`
            border-radius: ${SIZE[1]};
          `;
        case 'large':
          return css`
            border-radius: 1.4rem;

            ${media.tabletLandscapeAndUp`
              border-radius: 1.8rem;
            `}
          `;
        case 'xlarge':
          return css`
            border-radius: 1.4rem;

            ${media.tabletLandscapeAndUp`
              border-radius: 2.1rem;
            `}
          `;
        case 'medium':
        default:
          return css`
            border-radius: ${SIZE[2]};
          `;
      }
    }}
  `,
  square: css`
    border-radius: none;
  `,
};

const ButtonVariant = [
  { variant: 'primary', themeKey: 'ButtonPrimary' },
  { variant: 'primary-reverse', themeKey: 'ButtonPrimaryReverse' },
  { variant: 'secondary', themeKey: 'ButtonSecondary' },
].reduce(
  (acc, { variant, themeKey }) => ({
    ...acc,
    [variant]: css`
      ${(props) => colorStyles(props.theme[themeKey].Color)}

      &.activated {
        ${(props) => colorStyles(props.theme[themeKey].Color.Activated)}
      }

      ${(props) =>
        props.error &&
        css`
          background: ${props.theme[themeKey].Color.Error.Background};
          color: ${props.theme[themeKey].Color.Error.Background};

          &:hover:not([disabled]),
          &:active:not([disabled]) {
            background: ${props.theme[themeKey].Color.Error.Background};
            color: ${props.theme[themeKey].Color.Error.Background};
          }
        `}

      ${LoadingSpinner} {
        color: currentColor;
      }
    `,
  }),
  {}
);

const StyledButton = styled.button
  .withConfig({
    // Requires styled-components@^5.1
    shouldForwardProp: (prop, defaultValidatorFn) => {
      return !['shape'].includes(prop) && defaultValidatorFn(prop);
    },
  })
  .attrs(() => ({
    onTouchStart: () => true,
  }))`
  ${ButtonStyles.base}
  ${(props) => ButtonSize[props.size]}
  ${(props) => ButtonShape[props.shape]}
  ${(props) => ButtonVariant[props.variant]}
  width: ${(props) => props.width};
`;

export const Button = styled(
  React.forwardRef((props, ref) => {
    const showText = props.loading !== true;
    const showSpinner = props.loading === true;
    const showIcon = props.icon !== undefined;
    const showIconLeft = props.iconLeft !== undefined;
    const showIconRight = props.iconRight !== undefined;

    const buttonProps = _omit(props, ['icon', 'iconLeft', 'iconRight', 'children', 'loading']);

    if (buttonProps.to !== undefined) {
      buttonProps.as = Link;
    }

    const loadingSpinnerSize = LOADING_SPINNER_SIZES[props.size];

    return (
      <StyledButton {...buttonProps} ref={ref}>
        {showIcon && <Icon>{props.icon}</Icon>}
        {showIconLeft && <IconLeft>{props.iconLeft}</IconLeft>}
        {props.children && <Label visible={showText}>{props.children}</Label>}
        {showIconRight && <IconRight>{props.iconRight}</IconRight>}
        {showSpinner && (
          <LoadingSpinner width={loadingSpinnerSize.width} strokeWidth={loadingSpinnerSize.strokeWidth} />
        )}
      </StyledButton>
    );
  })
)``;

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  loading: PropTypes.bool,
  shape: PropTypes.oneOf(['circle', 'pill', 'rounded', 'square']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  style: PropTypes.object,
  variant: PropTypes.oneOf(['primary', 'secondary', 'primary-reverse']),
  width: PropTypes.string,
};

Button.defaultProps = {
  shape: 'rounded',
  size: 'medium',
  variant: 'primary',
};
