import { useEffect, useRef } from 'react';

const isDev = process.env['NODE_ENV'] === 'development';

// @deprecated
export default function useInputFocusRef(step, currentStep) {
  const ref = useRef(null);

  useEffect(() => {
    if (step === currentStep) {
      try {
        ref.current.focus();
      } catch (error) {
        // continue without error
      }
    }

    if (isDev) {
      console.warn('warning: use of deprecated useInputFocusRef');
    }
  }, [step, currentStep]);

  return ref;
}

export function useInputFocusOnceRef() {
  const ref = useRef(null);

  useEffect(() => {
    try {
      ref.current.focus();
    } catch (error) {
      // continue without error
    }
  }, []);

  return ref;
}
