import { createAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { AWS_USER_POOL_WEB_CLIENT_ID } from '@shared/constants/amplify';
import { STATE_AUTHENTICATED, STATE_UNAUTHENTICATED } from '@shared/constants/state';
import { getParentWindow } from '@shared/lib/getParentWindow';
import { richFetch } from '@shared/lib/richFetch';
import { Auth } from 'aws-amplify';
import uuidv4 from 'uuid/v4';
import { EMAIL_REGEX } from '../constants';
import { GIVING_API_BASE_URL, PLATFORM_API_BASE_URL } from '../constants/urls';

export const userSignedIn = createAction('user/signedin');
export const userSignedOut = createAction('user/signedout');

export const setCurrentUser = createAction('user/set');
export const clearCurrentUser = createAction('user/clear');
export const setSavedSources = createAction('user/sources/set');

export const getAuthState = async () =>
  await Auth.currentSession()
    .then(() => STATE_AUTHENTICATED)
    .catch(() => STATE_UNAUTHENTICATED);

export function initializeUserSession(settings) {
  return async function (dispatch) {
    if (settings?.account?.allowSignIn === false) {
      Sentry.setUser(null);
      return dispatch(clearCurrentUser());
    }

    try {
      await Auth.currentSession();
    } catch (error) {
      Sentry.setUser(null);
      return dispatch(clearCurrentUser());
    }

    const currentUser = await richFetch('GET', PLATFORM_API_BASE_URL, '/me');
    Sentry.setUser({ id: currentUser.id });

    dispatch(setCurrentUser(currentUser));

    const savedPaymentSources = await dispatch(loadSavedSources());

    return {
      currentUser: currentUser,
      savedPaymentSources: savedPaymentSources,
    };
  };
}

export function checkEmail(email) {
  return async function (dispatch) {
    if (EMAIL_REGEX.test(email) === false) {
      throw new Error('Not a valid email.');
    }

    const options = {
      headers: {
        'X-Email-Address': email,
        'X-Web-Client-Id': AWS_USER_POOL_WEB_CLIENT_ID,
      },
    };

    return richFetch('GET', PLATFORM_API_BASE_URL, '/email/check', undefined, options);
  };
}

export function signIn(email, password) {
  return async function (dispatch, getState) {
    email = String(email).toLowerCase();
    await Auth.signIn(email, password);
    leaveBreadCrumb('Signed In');
    const { currentUser } = await dispatch(initializeUserSession());
    dispatch(userSignedIn());
    getParentWindow().dispatchEvent(new CustomEvent('NucleusUserSignedIn', { detail: {} }));
    return currentUser;
  };
}

export function signOut(callBack) {
  return async function (dispatch) {
    await Auth.signOut();
    leaveBreadCrumb('Signed Out');
    dispatch(userSignedOut());
    getParentWindow().dispatchEvent(new CustomEvent('NucleusUserSignedOut', { detail: {} }));
    Sentry.setUser(null);
    if (typeof callBack === 'function') {
      callBack();
    }
  };
}

export function signUp(email, password) {
  return async function (dispatch, getState) {
    email = String(email).toLowerCase();
    await Auth.signUp({
      username: uuidv4(),
      password: password,
      attributes: { email: email },
    }).catch(logSignUpError);
    leaveBreadCrumb('Signed Up');
    await dispatch(signIn(email, password));
  };
}

export function loadSavedSources() {
  return async function (dispatch) {
    try {
      await Auth.currentSession();
    } catch (error) {
      Sentry.setUser(null);
      return dispatch(clearCurrentUser());
    }

    let savedPaymentSources = [];
    let response = richFetch('GET', GIVING_API_BASE_URL, '/source/list');

    try {
      response = await response;
    } catch (error) {
      console.error('Error fetching saved payment sources', error);
      return savedPaymentSources;
    }

    savedPaymentSources = response.map((item) => ({
      ...item,
      name: `${item.account_name} ending in ${item.account_last_few}`,
    }));

    dispatch(setSavedSources(savedPaymentSources));

    return savedPaymentSources;
  };
}
// args is church, launcher, or flow
export const linkWithChurch = (args) => async () => {
  return await richFetch('POST', PLATFORM_API_BASE_URL, '/person/link', args);
};

const leaveBreadCrumb = (message) =>
  Sentry.addBreadcrumb({
    category: 'auth',
    message: message,
    level: 'info',
  });

const ignoredErrors = ['InvalidPasswordException', 'InvalidParameterException'];

const logSignUpError = (error) => {
  if (ignoredErrors.includes(error?.code) === false) {
    Sentry.captureException(error);
  }

  throw error;
};
