import { isOverflowingWidth } from '@shared/hooks/useDimensions';
import React, { useContext, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import { booleanPropHelperFactory } from './style-utils';

const TextMarqueeContext = React.createContext(false);

export const TextOverflowMarquee = (props) => {
  const textRef = useRef();
  const pointerHovering = useContext(TextMarqueeContext);

  const marqueeActive = pointerHovering && isOverflowingWidth(textRef.current);
  const marqueeWidth = textRef.current?.scrollWidth + 20; // width + spacing
  const marqueeDuration = (marqueeWidth / 50) * 1000; // total width / pixels per second

  const { x } = useSpring({
    from: { x: 0 },
    to: { x: -100 },
    cancel: !marqueeActive,
    reset: marqueeActive,
    config: { duration: marqueeDuration ?? 0 },
  });

  return (
    <MarqueeContainer>
      <Text ref={textRef} marqueeActive={marqueeActive}>
        {props.children}
      </Text>
      {marqueeActive && (
        <MarqueeText style={{ transform: x.interpolate((x) => `translateX(${x}%)`) }}>
          {props.children}
          <MarqueeTextCopy>{props.children}</MarqueeTextCopy>
        </MarqueeText>
      )}
    </MarqueeContainer>
  );
};

export const TextMarqueeProvider = TextMarqueeContext.Provider;

export const useTextMarquee = () => {
  const [pointerHovering, setPointerHovering] = useState(false);

  let setHoveringAfterDelay;

  const handleMouseEnter = () => {
    setHoveringAfterDelay = setTimeout(() => setPointerHovering(true), 1000);
  };

  const handleMouseLeave = () => {
    clearTimeout(setHoveringAfterDelay);
    setPointerHovering(false);
  };

  const providerProps = {
    value: pointerHovering,
  };

  const targetProps = {
    onMouseEnter: handleMouseEnter,
    onTouchStart: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    onTouchEnd: handleMouseLeave,
  };

  return [providerProps, targetProps];
};

const MarqueeContainer = styled.span`
  display: inline-flex;
  position: relative;
  max-width: 100%;
`;

const isMarqueeActive = booleanPropHelperFactory('marqueeActive');

const Text = styled.span`
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${isMarqueeActive(css`
    opacity: 0;
  `)}
`;

const MarqueeText = styled(animated.span)`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  white-space: nowrap;
`;

const MarqueeTextCopy = styled.span`
  margin-left: 20px;
  white-space: nowrap;
`;
