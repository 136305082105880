import { withStopPropagation } from '@shared/lib/events';
import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { MinusRounded, PlusRoundedForCounter } from './Icons';
import { font, media } from './style-utils';
import { getThemeValue } from './Theme';

const getScopedThemeValue = _partial(getThemeValue, 'ButtonOptionCounter.Color');

const Icon = styled(PlusRoundedForCounter)`
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  transform-origin: center;
`;

const CircleButton = styled.button`
  background: none;
  border-style: solid;
  border-color: transparent;
  border-top-width: 1rem;
  border-left-width: 1.6rem;
  border-right-width: 1.6rem;
  border-bottom-width: 1rem;
  box-sizing: content-box;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  user-select: none;
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ::before {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 99rem;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (hover: hover) {
    :hover ::before {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  /* Fixme: IOS mobile safari active state */
  :not([disabled]):active {
    transform: scale(0.94);
  }

  :disabled ::before {
    opacity: 0.4;
  }

  width: 3rem;
  height: 3rem;
  min-width: 3rem;

  ${Icon}, svg {
    width: 100%;
    height: 100%;
  }

  ${media.tabletLandscapeAndUp`
    width: 3rem;
    height: 3rem;
  `}
`;

const ButtonCirclePlus = (props) => {
  return (
    <CircleButton {...props}>
      <Icon />
    </CircleButton>
  );
};

const ButtonCircleX = (props) => {
  return (
    <CircleButton {...props}>
      <Icon rotate="45" />
    </CircleButton>
  );
};

const ButtonCircleMinus = (props) => {
  return (
    <CircleButton {...props}>
      <MinusRounded />
    </CircleButton>
  );
};

const LeftButton = styled((props) => {
  if (props.value > 1) {
    return <ButtonCircleMinus {...props} />;
  }
  return <ButtonCircleX {...props} />;
})``;

const Left = styled.div`
  position: absolute;
  top: -0.6rem;
  left: -1.2rem;
`;

const RightButton = styled(ButtonCirclePlus)`
  position: absolute;
  top: -0.6rem;
  right: -1.2rem;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  width: 0;
  height: 0;

  & > * {
    transform: translate(-50%, -50%);
  }
`;

const StyledSpan = styled.span`
  ${font(14, 'Bold', -0.5)}
  display: block;
  white-space: nowrap;
  position: absolute;
  text-align: center;

  ${media.tabletLandscapeAndUp`
    ${font(16)}
  `};
`;

const StyledCircleButton = styled(CircleButton)`
  ${(props) => {
    // Closeing the pill
    if (props.showBackground) {
      return css`
        ::before {
          background-color: rgba(255, 255, 255, 0.2);
          transition: background-color 100ms cubic-bezier(0.65, 0, 0.35, 1) 50ms;
        }
      `;
    } else {
      // Opening the pill
      return css`
        ::before {
          background-color: transparent;
          transition: background-color 75ms cubic-bezier(0.65, 0, 0.35, 1);
        }
      `;
    }
  }}
`;

const Counter = (props) => {
  return (
    <StyledCircleButton showBackground={props.showBackground}>
      <StyledSpan>{props.children}</StyledSpan>
    </StyledCircleButton>
  );
};

const Container = styled.div`
  border-radius: 99rem;
  box-shadow: 0 0.2rem 1.4rem ${getScopedThemeValue('Default.Shadow')};
  color: ${getScopedThemeValue('Default.Foreground')};
  height: 3.8rem;
  margin: 0;
  padding: 0;
  position: relative;
  transition: width 200ms cubic-bezier(0.65, 0, 0.35, 1);
  width: 3.8rem;
  touch-action: manipulation;

  > ${Background} {
    background-color: ${(props) =>
      props.hasError ? getScopedThemeValue('Default.Error')(props) : getScopedThemeValue('Default.Background')(props)};
    border-radius: 99rem;
  }

  /* Close Animations */
  ${Left}, ${Center}, ${RightButton} {
    transition: opacity 100ms cubic-bezier(0.65, 0, 0.35, 1);
    opacity: 0;
  }

  ${(props) => {
    if (props.showRight) {
      return css`
        ${RightButton} {
          transition: opacity 100ms cubic-bezier(0.65, 0, 0.35, 1);
          opacity: 1;
        }
      `;
    } else {
      return css`
        ${Center} {
          transition: opacity 100ms cubic-bezier(0.65, 0, 0.35, 1);
          opacity: 1;
        }
      `;
    }
  }}

  ${(props) => {
    if (props.open) {
      /* Open Animations */
      return css`
        border-radius: 10rem;
        width: 10rem;

        > ${Background} {
          border-radius: 10rem;
        }

        ${Center}, ${RightButton} {
          transition: opacity 200ms cubic-bezier(0.65, 0, 0.35, 1);
          opacity: 1;
        }

        ${Left} {
          transition: opacity 150ms cubic-bezier(0.65, 0, 0.35, 1) 50ms;
          opacity: 1;
        }
      `;
    }
  }}
`;

export const ButtonOptionCounter = (props) => {
  const { onChange, onClick, open, value, max, ...otherProps } = props;

  const increment = () => onChange(Number(value) + 1);
  const decrement = () => onChange(Number(value) - 1);

  const haveValue = value !== undefined;

  const handleLeftClick = withStopPropagation(() => {
    if (haveValue) {
      decrement();
    }
  });

  const handleRightClick = (e) => {
    if (haveValue) {
      e.stopPropagation();
      if (value < max) {
        increment();
      }
    } else {
      onClick(e);
    }
  };

  const overLimit = value > max;

  return (
    <Container open={props.open} showRight={!haveValue} hasError={overLimit} {...otherProps}>
      <Background />
      <Left>
        <LeftButton value={value} onClick={handleLeftClick} />
      </Left>
      <Center>
        <Counter showBackground={!props.open && haveValue}>{value}</Counter>
      </Center>
      <RightButton onClick={handleRightClick} />
    </Container>
  );
};

ButtonOptionCounter.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.number,
  max: PropTypes.number,
};

ButtonOptionCounter.defaultProps = {
  onChange: () => {},
  onClick: () => {},
  max: Infinity,
};

export const useButtonOptionCounterState = (value) => {
  const timerRef = useRef();
  const [state, setState] = useState(false);

  const openWithTimeout = (timeout = 2000) => {
    clearTimeout(timerRef.current);
    setState(true);
    timerRef.current = setTimeout(() => setState(false), timeout);
  };

  const handleMouseEnter = (e) => {
    clearTimeout(timerRef.current);
    setState(true);
  };

  const handleMouseLeave = (e) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => setState(false), 1000);
  };

  const open = state && value !== undefined;

  const props = {
    open: open,
    onMouseEnter: handleMouseEnter,
    onTouchStart: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    onTouchEnd: handleMouseLeave,
  };

  return [props, openWithTimeout];
};
