import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { media } from './style-utils';
import { Styles } from './Typography';

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  height: 4.6rem;
  overflow: hidden;
  position: relative;

  ${media.tabletLandscapeAndUp`
    height: 6.4rem;
  `};
`;

const InputF2 = styled(Input)`
  ${Styles.F2};
  color: ${({ theme }) => theme.InputDate.Color.Default};
`;

export class InputDate extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // This ref is set by Cleave
    this.inputRef = undefined;
  }

  focus = () => {
    this.inputRef.focus();
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.props.onEnter) {
      this.props.onEnter(e);
    }
  };

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { value, onChange, onEnter, ...otherProps } = this.props;

    return (
      <InputContainer>
        <InputF2
          as={Cleave}
          options={{ date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] }}
          type="text"
          inputMode="decimal"
          autoComplete="off"
          placeholder="MM/DD/YYYY"
          size="10"
          value={value}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          htmlRef={(ref) => (this.inputRef = ref)}
          {...otherProps}
        />
      </InputContainer>
    );
  }
}
