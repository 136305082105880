import { getCurrencyFormater } from '@shared/lib/format';
import { selectMerchantByFlowId } from '@shared/reducers/flows/flowsByIdReducer';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FlowContext } from '../../Flow';

export const useCurrencyFormatter = () => {
  const { currentFlowId } = useContext(FlowContext);
  const merchant = useSelector((state) => selectMerchantByFlowId(state, currentFlowId));

  return getCurrencyFormater(merchant.bank_currency_code);
};
