import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { media } from './style-utils';
import { Styles } from './Typography';

const T = styled.span`
  ${Styles.P2}
  color: ${({ theme }) => theme.DescriptionStep.Color.Default};
  opacity: 0.8;
`;

const OuterContainer = styled.div`
  pointer-events: none;
  position: relative;
`;

const InnerContainer = styled.div`
  left: 0;
  margin-bottom: 3rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  position: ${(props) => (props.absolute ? 'absolute' : undefined)};
  top: 0;

  ${media.tabletLandscapeAndUp`
      padding-left: 1.4rem;
  `};
`;

export const DescriptionStep = (props) => {
  return (
    <OuterContainer className={props.className}>
      <InnerContainer absolute={props.absolute}>
        <T>{props.children}</T>
      </InnerContainer>
    </OuterContainer>
  );
};

DescriptionStep.propTypes = {
  error: PropTypes.string,
};
