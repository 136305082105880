import { createAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_TYPE_ERROR = 'error';
export const ALERT_TYPE_INFO = 'info';

export const createAlert = createAction('alert/create', (alert) => ({
  payload: {
    ...alert,
    id: uuidv4(),
    deleteAt: Date.now() + alert.duration,
  },
}));

export const createAlertSuccess = (message = 'Success!', persist = false, props) =>
  createAlert({
    ...props,
    type: ALERT_TYPE_SUCCESS,
    message: message,
    duration: persist ? undefined : 3000,
  });

export const createAlertError = (message = 'Something went wrong.', persist = false, props) =>
  createAlert({
    ...props,
    type: ALERT_TYPE_ERROR,
    message: message,
    duration: persist ? undefined : 3000,
  });

export const createAlertInfo = (message, persist = false, props) =>
  createAlert({
    ...props,
    type: ALERT_TYPE_INFO,
    message: message,
    duration: persist ? undefined : 6000,
  });

export const deleteAlert = createAction('alert/delete', (alert) => ({
  payload: {
    id: alert.id,
  },
}));
