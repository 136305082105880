import {
  BankAccountDetails,
  ButtonGroup,
  ButtonGroupCircleX,
  ButtonOptionSecondary,
  ButtonPrimary,
  LabelStep,
  StepAlert,
} from '@foyyay/flow-elements';
import { Select } from '@shared/components/Select';
import { BANK_ACCOUNT_TYPE_CHECKING, BANK_ACCOUNT_TYPE_SAVINGS, SOURCE_TYPE_ACH_DEBIT } from '@shared/constants';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { selectMerchantByFlowId } from '@shared/reducers/flows/flowsByIdReducer';
import { noop } from 'lodash';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlowContext } from '..';
import { FlowStep } from '../components/FlowStep';
import { SecureElements } from '../components/SecureElements/SecureElements';

const BankAccountOptions = [
  { id: BANK_ACCOUNT_TYPE_CHECKING, name: 'Checking Account' },
  { id: BANK_ACCOUNT_TYPE_SAVINGS, name: 'Savings Account' },
];

export const StepPaymentSourceNewAchBank = (props) => {
  const defaultValue = {};
  const initialValue = props.data?.value?.[SOURCE_TYPE_ACH_DEBIT] ?? defaultValue;

  const message = `ending in ${initialValue.account_last_few}`;

  return (
    <FlowStep label={''} message={message} header={<StepError error={props.error} />}>
      <StepPaymentSourceBankEntry
        config={props.config}
        onComplete={props.completeStep}
        onIncomplete={props.incompleteStep}
        onSave={props.saveStep}
        onBacktoPlaidClick={props.onBacktoPlaidClick}
        value={initialValue}
        data={props.data}
      />
    </FlowStep>
  );
};

const StepPaymentSourceBankEntry = (props) => {
  const [bankAccountType, setBankAccountType] = useState(undefined);

  const handleBankAccountTypeChange = (value) => setBankAccountType(value);

  if (bankAccountType === undefined && props.value?.id === undefined) {
    return <SelectAccountType onChange={handleBankAccountTypeChange} onBacktoPlaidClick={props.onBacktoPlaidClick} />;
  }

  if (bankAccountType !== undefined && props.value?.id === undefined) {
    return (
      <InputBankInfo bankAccountType={bankAccountType} setBankAccountType={handleBankAccountTypeChange} {...props} />
    );
  }
  return <DisplayBankInfo {...props} />;
};

const SelectAccountType = (props) => {
  const handleBankAccountChange = (value) => props.onChange(value.id);

  return (
    <>
      <LabelStep>What type of bank account is this?</LabelStep>
      <Select options={BankAccountOptions} onChange={handleBankAccountChange} />
      <ButtonOptionSecondary style={{ marginTop: '1.6rem' }} onClick={props.onBacktoPlaidClick}>
        Nevermind, go back
      </ButtonOptionSecondary>
    </>
  );
};

const InputBankInfo = (props) => {
  const { setBankAccountType } = props;

  const ref = useRef();

  const { currentFlowId } = useContext(FlowContext);
  const merchant = useSelector((state) => selectMerchantByFlowId(state, currentFlowId));

  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [bankError, setBankError] = useState();

  const handleSubmit = async (options) => {
    if (submitting === true) {
      return;
    }

    setSubmitting(true);

    let response = ref.current.submit(options);

    try {
      response = await response;
      if (response !== undefined) {
        props.onComplete({
          value: {
            ...(props.data?.value ?? {}),
            [SOURCE_TYPE_ACH_DEBIT]: response,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }

    setSubmitting(false);
  };

  const handleSubmitClick = (e) => {
    e.stopPropagation();
    handleSubmit();
  };

  const handleClearClick = useClickHandler(() => setBankAccountType(undefined), [setBankAccountType]);

  return (
    <>
      <LabelStep error={bankError}>OK, Let's use a bank account</LabelStep>
      <SecureElements
        accountType={props.bankAccountType}
        merchant={merchant}
        onError={setBankError}
        onReadyToSubmit={setReadyToSubmit}
        onSubmit={handleSubmit}
        submitting={submitting}
        type="bank"
        ref={ref}
      />
      <ButtonGroup>
        <ButtonGroupCircleX onClick={handleClearClick} />
        <ButtonPrimary onClick={handleSubmitClick} disabled={!readyToSubmit} processing={submitting}>
          {bankError ? bankError : 'Continue'}
        </ButtonPrimary>
      </ButtonGroup>
    </>
  );
};

const DisplayBankInfo = (props) => {
  const handleContinueClick = (e) => {
    e.stopPropagation();
    props.onComplete({
      value: {
        ...(props.data?.value ?? {}),
        [SOURCE_TYPE_ACH_DEBIT]: props.value,
      },
    });
  };

  const clearBank = useCallback(() => {
    // incompleteStep
    props.onIncomplete();
    // clearNewCardSource
    props.onSave({
      value: {
        ...props.value,
        [SOURCE_TYPE_ACH_DEBIT]: undefined,
      },
    });
  }, [props]);

  const handleClearClick = (e) => {
    e.stopPropagation();
    clearBank();
  };

  const bankAccountHolderName = props.value.holder_name;
  const bankAccountNumber = ['•••••', props.value.account_last_few].join('');
  const bankRoutingNumber = props.value.routing_number;

  return (
    <>
      <form onSubmit={noop}>
        <LabelStep>OK, Using your bank account</LabelStep>
        <BankAccountDetails
          bank_account_holder_name={bankAccountHolderName}
          bank_account_number={bankAccountNumber}
          bank_routing_number={bankRoutingNumber}
        />
      </form>
      <ButtonGroup>
        <ButtonGroupCircleX onClick={handleClearClick} />
        <ButtonPrimary onClick={handleContinueClick} processing={props.isTokenProcessing}>
          Continue
        </ButtonPrimary>
      </ButtonGroup>
    </>
  );
};

const StepError = (props) => {
  if (props.error === undefined) {
    return null;
  }

  return (
    <StepAlert label={props.error.label} variant="error">
      {props.error.message}
    </StepAlert>
  );
};
