import { STEP_TYPE } from '@shared/constants';
import { FLOW_TYPE_CUSTOM, FLOW_TYPE_GIVING } from '@shared/constants/flow';
import { deepFreeze } from '@shared/lib/deepFreeze';

export function calculateSharedData(flow, steps, data) {
  const calculateFunc = MAP_FLOW_TYPE_TO_SHARED_DATA_FUNCTION[flow.config?.flow_type];

  if (calculateFunc === undefined) {
    return {};
  }

  return calculateFunc(steps, data);
}

const MAP_FLOW_TYPE_TO_SHARED_DATA_FUNCTION = deepFreeze({
  [FLOW_TYPE_CUSTOM]: calculateSharedDataForCustomFlow,
  [FLOW_TYPE_GIVING]: calculateSharedDataForGivingFlow,
});

function calculateSharedDataForCustomFlow(steps, data) {
  const paymentStep = steps.find((step) => step.type === STEP_TYPE.PAYMENT);
  if (paymentStep === undefined) {
    return {};
  }
  const paymentAmount = getPaymentAmount(paymentStep.config.steps, data);
  const paymentSummary = getPaymentSummary(steps, data);
  const paymentSource = getPaymentSource(paymentStep.config.steps, data);
  const paymentToken = getPaymentToken(paymentStep.config.steps, data, paymentSource);
  const sharedPaymentData = {
    amount: paymentAmount,
    paymentType: paymentSource.sourceType,
    paymentSource: paymentSource.source,
    paymentToken: paymentToken,
    paymentSummary: paymentSummary,
  };
  return sharedPaymentData;
}

function calculateSharedDataForGivingFlow(steps, data) {
  const givingAmount = getGivingAmount(steps, data);
  const givingDesignation = getGivingDesignation(steps, data);
  const givingSummary = getGivingSummary(steps, data);
  const paymentInterval = getPaymentInterval(steps, data);
  const paymentStartDate = getPaymentStartDate(steps, data);
  const paymentSource = getPaymentSource(steps, data);
  const paymentToken = getPaymentToken(steps, data, paymentSource);
  const paymentMemo = getPaymentMemo(steps, data);
  return {
    amount: givingAmount,
    designation: givingDesignation,
    interval: paymentInterval,
    startDate: paymentStartDate,
    paymentType: paymentSource.sourceType,
    paymentSource: paymentSource.source,
    paymentToken: paymentToken,
    givingSummary: givingSummary,
    memo: paymentMemo,
  };
}

function getStepDataByType(stepType, steps, data) {
  const step = steps.find((step) => step.type === stepType);
  if (step === undefined) {
    return undefined;
  }
  return data[step.id];
}

function getPaymentSource(steps, data) {
  return getStepDataByType(STEP_TYPE.PAYMENT_TYPE, steps, data)?.value ?? {};
}

function getPaymentToken(steps, data, paymentSource) {
  if (paymentSource.source !== undefined) {
    return undefined;
  }
  const stepPaymentSourceNewDataValue = getStepDataByType(STEP_TYPE.PAYMENT_INFO, steps, data)?.value ?? {};
  return stepPaymentSourceNewDataValue[paymentSource.sourceType?.id];
}

function getGivingAmount(steps, data) {
  return getStepDataByType(STEP_TYPE.GIVING_AMOUNT, steps, data)?.value;
}

function getPaymentAmount(steps, data) {
  const stepPaymentAmountData = getStepDataByType(STEP_TYPE.PAYMENT_AMOUNT, steps, data);
  if (stepPaymentAmountData?.skipped === true) {
    return undefined;
  }
  let value = stepPaymentAmountData?.value;
  if (Array.isArray(value)) {
    value = value.reduce((acc, curr) => acc + curr.value, 0);
  }
  return value;
}

function getGivingDesignation(steps, data) {
  return getStepDataByType(STEP_TYPE.GIVING_DESIGNATION, steps, data)?.value?.[0] ?? {};
}

function getGivingSummary(steps, data) {
  return getStepDataByType(STEP_TYPE.GIVING_SUMMARY, steps, data)?.value ?? {};
}

function getPaymentSummary(steps, data) {
  return getStepDataByType(STEP_TYPE.PAYMENT_SUMMARY, steps, data)?.value ?? {};
}

function getPaymentInterval(steps, data) {
  return getStepDataByType(STEP_TYPE.PAYMENT_SCHEDULE_INTERVAL, steps, data)?.value ?? {};
}

function getPaymentStartDate(steps, data) {
  return getStepDataByType(STEP_TYPE.PAYMENT_SCHEDULE_START_DATE, steps, data)?.value?.date ?? {};
}

function getPaymentMemo(steps, data) {
  return getStepDataByType(STEP_TYPE.PAYMENT_MEMO, steps, data)?.value;
}
