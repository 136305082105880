import * as dateFns from 'date-fns';
import { TIME_PERIOD_AM, TIME_PERIOD_PM } from '../constants/datetime';
import { isoStringToDate } from './datetime';

export function addLeadingZero(num) {
  return num.toString().padStart(2, '0');
}

export const formatNumberAsUsd = (value) => {
  const lang = navigator.language ?? 'en-US';
  return new Intl.NumberFormat(lang, { style: 'currency', currency: 'USD' }).format(value / 100).replace(/\D00$/, '');
};

export const formatNumberAsCad = (value) => {
  const lang = navigator.language ?? 'en-CA';
  return new Intl.NumberFormat(lang, { style: 'currency', currency: 'CAD' }).format(value / 100).replace(/\D00$/, '');
};

const FORMATTER_BY = {
  CAD: formatNumberAsCad,
  USD: formatNumberAsUsd,
};

export const getCurrencyFormater = (currencyCode) => {
  return FORMATTER_BY[currencyCode] ?? FORMATTER_BY.USD;
};

export const formatAddress = (address) => {
  let formatted = '';
  formatted += address.street_address ? address.street_address.trim() : '';
  formatted += address.street_address_2 ? ` ${address.street_address_2.trim()},` : ',';
  formatted += address.city ? ` ${address.city.trim()},` : '';
  formatted += address.state ? ` ${address.state.trim()}` : '';
  formatted += address.postal_code ? ` ${address.postal_code.trim()}` : ',';
  formatted += address.country ? ` ${address.country.trim()}` : '';

  return formatted;
};

export const convertTime24to12 = (time, withLeadingZero) => {
  let [hours, minutes] = time.split(':');
  const period = hours >= 12 ? TIME_PERIOD_PM : TIME_PERIOD_AM;
  hours = hours % 12 || 12;
  if (withLeadingZero && hours < 10) {
    hours = `0${hours}`;
  }
  return [hours + ':' + minutes, period];
};

export const convertTime12to24 = (time, period) => {
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (period === TIME_PERIOD_PM) {
    hours = parseInt(hours, 10) + 12;
  }

  return `${addLeadingZero(hours)}:${addLeadingZero(minutes)}`;
};

export const formatTime = (time24) => {
  const [time, period] = convertTime24to12(time24);
  return time + ' ' + period;
};

export const formatDate = (value) => {
  return dateFns.format(isoStringToDate(value), 'MMMM d, yyyy');
};

export function interpolateValues(template, values = {}) {
  let max = 10000;
  const replacements = Object.keys(values);
  while (max > 0) {
    const before = template;
    for (let kI = 0; kI < replacements.length; kI++) {
      const rKey = replacements[kI];
      template = template.replace(new RegExp('\\{' + rKey + '\\}', 'g'), values[rKey]);
    }
    if (before === template) {
      break;
    }
    max--;
  }
  return template;
}

export const formatPhoneNumber = (str) => {
  const cleaned = String(str).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return str;
};

export const formatValueWithPrefix = (prefix, value, separator = ':') => {
  if (prefix === undefined || prefix.trim() === '') {
    return value;
  }

  prefix = prefix.trim();

  if (prefix.slice(-1) !== ':') {
    return `${prefix}${separator} ${value}`;
  }

  return `${prefix} ${value}`;
};
