import { ScrollContext } from '@shared/context/ScrollContext';
import { getParentWindow } from '@shared/lib/getParentWindow';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

export const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const window = getParentWindow();
  const timeout = useRef();

  const handleScroll = (e) => {
    e.persist();

    if (timeout.current) {
      window.cancelAnimationFrame(timeout.current);
    }

    timeout.current = window.requestAnimationFrame(() => {
      setScrollTop(e.target?.scrollTop ?? 0);
    });
  };

  const reset = useCallback(() => {
    setScrollTop(0);
  }, []);

  const targetProps = {
    onScroll: handleScroll,
  };

  return [scrollTop, targetProps, reset];
};

export const useScrollTopReset = (context) => {
  const { resetScrollTop } = useContext(context ?? ScrollContext);

  useEffect(() => {
    resetScrollTop();
  }, [resetScrollTop]);
};
