import { ButtonOptionPrimary, ButtonOptionSecondary, media } from '@foyyay/flow-elements';
import { FLOW_TYPE_ACCESS_REQUEST, FLOW_TYPE_CUSTOM, FLOW_TYPE_GIVING, FLOW_TYPE_PRAYER } from '@shared/constants/flow';
import { NUCLEUS_DASHBOARD_BASE_URL } from '@shared/constants/urls';
import { selectFlowConfigById } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlowContext } from '../../..';

export const AccountButtons = (props) => {
  const { currentFlowId } = useContext(FlowContext);
  const config = useSelector((state) => selectFlowConfigById(state, currentFlowId));

  return React.createElement(getAccountButtonsBy(config.flow_type), props);
};

const FlowAccountButtons = (props) => {
  return (
    <Section>
      <a href={`${NUCLEUS_DASHBOARD_BASE_URL}/my/submissions`} target="_blank" rel="noopener noreferrer">
        <ButtonOptionPrimary>View my submissions</ButtonOptionPrimary>
      </a>
      <Link to="/" replace={true}>
        <ButtonOptionSecondary>or, Start again</ButtonOptionSecondary>
      </Link>
    </Section>
  );
};

const AccessRequestFlowAccountButtons = (props) => {
  const { goToPrayerHub, goToPrayerFlow } = useContext(FlowContext);
  return (
    <Section>
      <ButtonOptionPrimary onClick={goToPrayerFlow}>Share a prayer</ButtonOptionPrimary>
      <ButtonOptionSecondary onClick={goToPrayerHub}>Back to Prayer Hub</ButtonOptionSecondary>
    </Section>
  );
};

const GivingAccountButtons = (props) => {
  return (
    <Section>
      <a href={`${NUCLEUS_DASHBOARD_BASE_URL}/my/giving`} target="_blank" rel="noopener noreferrer">
        <ButtonOptionPrimary>View my giving</ButtonOptionPrimary>
      </a>
      <Link to="/" replace={true}>
        <ButtonOptionSecondary>or, Start again</ButtonOptionSecondary>
      </Link>
    </Section>
  );
};

const PrayerAccountButtons = (props) => {
  return (
    <Section>
      <a href={`${NUCLEUS_DASHBOARD_BASE_URL}/my/prayers`} target="_blank" rel="noopener noreferrer">
        <ButtonOptionPrimary>View my prayers</ButtonOptionPrimary>
      </a>
      <Link to="/" replace={true}>
        <ButtonOptionSecondary>or, Start again</ButtonOptionSecondary>
      </Link>
    </Section>
  );
};

const Section = styled.div`
  max-width: 35rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;

  ${media.tabletPortraitAndUp`
    margin-top: 5.8rem;
    margin-bottom: 8.4rem;
  `}
`;

const FLOW_ACCOUNT_BUTTONS_BY = {
  [FLOW_TYPE_ACCESS_REQUEST]: AccessRequestFlowAccountButtons,
  [FLOW_TYPE_CUSTOM]: FlowAccountButtons,
  [FLOW_TYPE_GIVING]: GivingAccountButtons,
  [FLOW_TYPE_PRAYER]: PrayerAccountButtons,
  default: FlowAccountButtons,
};

const getAccountButtonsBy = (flowType) => FLOW_ACCOUNT_BUTTONS_BY[flowType] ?? FLOW_ACCOUNT_BUTTONS_BY.default;
