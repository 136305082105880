/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { getParentWindow } from '@shared/lib/getParentWindow';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import uuidv4 from 'uuid/v4';

const window = getParentWindow();

const isDevelopment = process.env.NODE_ENV === 'development';
const withDevTools = isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__;

const stores = {};
const subscribers = {};

/**
 * A hook that returns a react reducer with support for thunks, logging, and redux dev tools middleware.
 *
 * @param {*} reducer
 * @param {*} options { initializerArg, initializer, thunkExtraArgument, devToolsName}
 * @returns [state, dispatchWithThunk, useSelector]
 */
export function useThunkReducer(reducer, options = {}) {
  const usingDevTools = withDevTools;
  const instance = useRef(uuidv4());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reducerWithDevTools = useMemo(() => (usingDevTools ? devToolReducer(reducer) : reducer), [reducer]);

  const [state, dispatch] = useReducer(reducerWithDevTools, options.initializerArg, options.initializer);

  const stateRef = useRef(state);
  stateRef.current = state;

  const thunkExtraArgumentRef = useRef(options.thunkExtraArgument);
  thunkExtraArgumentRef.current = options.thunkExtraArgument;

  // Used for logging only
  const actionsRef = useRef([]);

  const dispatchWithThunk = useCallback((action) => {
    if (typeof action === 'function') {
      return action(dispatchWithThunk, () => stateRef.current, thunkExtraArgumentRef.current);
    }

    const actionType = typeof action === 'object' ? action.type : action;
    actionsRef.current.push({ type: actionType, action: action });

    dispatch(action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useSelector = useCallback((selector) => selector(state), [state]);

  const devTools = useReduxDevTools(
    usingDevTools,
    instance.current,
    options.devToolsName,
    initialState(options.initializerArg, options.initializer),
    dispatch
  );

  // Logging
  useMemo(() => {
    actionsRef.current.forEach((action) => {
      Sentry.addBreadcrumb({
        category: `${namespaced(options.devToolsName)}.action`,
        message: `dispatching ${action.type}`,
        level: 'info',
      });
    });

    if (usingDevTools && devTools) {
      actionsRef.current.forEach((action) => {
        devTools.send(action, stateRef.current);
      });
    }

    if (isDevelopment) {
      actionsRef.current.forEach((action) => {
        console.groupCollapsed(action.type);
        console.debug('dispatching ', action.action);
        console.debug('next state ', stateRef.current);
        console.groupEnd();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // Clear actions after logging to console
  actionsRef.current = [];

  return [state, dispatchWithThunk, useSelector];
}

const namespaced = (name) => ['useThunkReducer', name].filter(Boolean).join('_');

const initialState = (initializerArg, initializer) => (initializer ? initializer(initializerArg) : initializerArg);

const devToolReducer = (reducer) => (state, action) => {
  switch (action.type) {
    case 'JUMP_TO_ACTION':
    case 'JUMP_TO_STATE':
      return JSON.parse(action.payload);
    default:
      return reducer(state, action);
  }
};

const useReduxDevTools = (usingDevTools, id, name, initialState, dispatch) => {
  useEffect(() => {
    if (usingDevTools) {
      stores[id] = window.__REDUX_DEVTOOLS_EXTENSION__.connect({ name: namespaced(name) });
      stores[id].init(initialState);

      subscribers[id] = stores[id].subscribe((message) => {
        if (message.type === 'DISPATCH') {
          dispatch({
            type: message.payload.type,
            payload: message.state,
          });
        }
      });

      return () => {
        try {
          subscribers[id]();
        } catch (error) {
          //
        }
        subscribers[id] = undefined;
        stores[id] = undefined;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return stores[id];
};
