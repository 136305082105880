import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { ButtonCircleX } from './ButtonCircleX';
import { Input } from './Input';
import { font, media } from './style-utils';
import { getThemeValue } from './Theme';

export class LauncherSearchbar extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    // this.focus();
  }

  focus = () => {
    this.inputRef.current.focus();
  };

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.props.onEnter) {
      this.props.onEnter(e);
    }
  };

  handleClickClearSearch = (e) => {
    this.props.onChange('');
    this.inputRef.current.blur();
  };

  render() {
    const { value, onChange, ...props } = this.props;

    const showClear = value !== '';

    return (
      <Wrapper>
        <Container {...props}>
          <InnerContainer>
            <SearchInput
              type="text"
              placeholder="Type here to search"
              value={value}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              ref={this.inputRef}
            />
            {showClear && (
              <SearchInputOverlay>
                <ButtonCircleX onClick={this.handleClickClearSearch} />
              </SearchInputOverlay>
            )}
          </InnerContainer>
        </Container>
      </Wrapper>
    );
  }
}

const LAUNCHER_SEARCHBAR_BORDER_RADIUS_SMALL = '2.8rem';
const LAUNCHER_SEARCHBAR_BORDER_RADIUS_LARGE = '4.2rem';

const getScopedThemeValue = _partial(getThemeValue, 'LauncherSearchbar.Primary');

const SearchInputOverlay = styled.div`
  position: absolute;
  right: 0;
  margin-right: 1rem;
  margin-left: 1rem;

  ${media.tabletLandscapeAndUp`
    margin-right: 1.6rem;
    margin-left: 1.6rem;
  `}
`;

const SearchInput = styled(Input)`
  width: 100%;
  height: 100% !important;
  text-align: center;
  color: ${getScopedThemeValue('Default.Foreground')};
  background-color: ${getScopedThemeValue('Default.Background')};
  border: 2px solid transparent;
  padding-left: 4.2rem;
  padding-right: 4.2rem;
  border-radius: ${LAUNCHER_SEARCHBAR_BORDER_RADIUS_SMALL};

  ${font(22, 'Medium', -1)}

  &:focus {
    background-color: ${getScopedThemeValue('Focus.Background')};
    border-color: ${getScopedThemeValue('Focus.Foreground')};
  }

  ${media.tabletLandscapeAndUp`
    ${font(28)}
    border-radius: ${LAUNCHER_SEARCHBAR_BORDER_RADIUS_LARGE};
    padding-left: 6rem;
    padding-right: 6rem;
  `};
`;

const Container = styled.div`
  position: relative;
  width: 100%;

  padding-bottom: 0;
  padding-top: 0;
  border-radius: ${LAUNCHER_SEARCHBAR_BORDER_RADIUS_SMALL};

  cursor: pointer;

  ${media.tabletLandscapeAndUp`
    border-radius: ${LAUNCHER_SEARCHBAR_BORDER_RADIUS_LARGE};
  `};

  ${SearchInput} {
    color: ${getScopedThemeValue('Default.Foreground')};
  }

  @media (hover: hover) {
    :hover {
      background-color: ${getScopedThemeValue('Hover.Background')};

      ${SearchInput} {
        color: ${getScopedThemeValue('Hover.Foreground')};
      }
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  height: 5.5rem;

  ${media.tabletLandscapeAndUp`
    height: 7.5rem;
  `};
`;

const Wrapper = styled(animated.div)`
  transform-style: preserve-3d;

  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;

  ${media.tabletPortraitAndUp`
    max-width: 36rem;
  `}

  ${media.tabletLandscapeAndUp`
    margin-bottom: 1.2rem;
    margin-top: 1rem;
    max-width: 50rem;
  `};
`;
