import { ButtonPrimary, ButtonSecondary, H1, H2, InfoText, media, Overlay, T1 } from '@foyyay/flow-elements';
import { AutoLineBreak } from '@shared/components/AutoLineBreak';
import { Modal } from '@shared/components/Modal';
import { buildConfigValidator } from '@shared/lib/schema';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { FlowStep } from '../components/FlowStep';
import { ContinueButton, SkipOrContinueButton } from '../components/StepActionButtons';

export const StepInfo = (props) => {
  const [viewed, setViewed] = useState(props.data?.viewed_at !== undefined);

  const handleViewed = () => setViewed(true);

  const additionalInfoType = props.config?.additionalInfoType ?? 'none';
  const skipped = props.data?.skipped === true;
  const message = buildMessage(props.config?.label, skipped, props.data?.agreed_at);

  return (
    <FlowStep label={null} message={message}>
      <InfoText>
        <H2>{props.config.headline}</H2>
        <T1>
          <AutoLineBreak text={props.config.content} />
        </T1>
      </InfoText>
      {React.createElement(getStepContentBy(additionalInfoType), {
        config: props.config,
        onComplete: props.completeStep,
        onViewed: handleViewed,
        viewed: viewed,
      })}
    </FlowStep>
  );
};

const configSchema = {
  content: yup.string().min(1).required(),
  headline: yup.string().min(1).required(),
  label: yup.string().min(1).required(),
};

const isConfigValid = buildConfigValidator(configSchema);

StepInfo.isConfigValid = isConfigValid;

function buildMessage(label, skipped, agreed) {
  if (skipped) {
    return `Skipped: "${label}"`;
  }
  if (agreed) {
    return `Agreed to: ${label}`;
  }
  return `Viewed: ${label}`;
}

const StepContentDefault = (props) => {
  const requireAgreement = (props.config?.requireAgreement ?? props.config?.requireAdditionalInfo) === true;

  const handleComplete = () => {
    const now = Date.now();
    props.onComplete({
      value: 'Viewed' + (requireAgreement ? ' & Agreed' : ''),
      viewed_at: now,
      agreed_at: requireAgreement ? now : undefined,
    });
  };

  let continueLabel = 'Continue';
  if (requireAgreement === true) {
    continueLabel = 'Agree & Continue';
  }

  return <ContinueButton onClick={handleComplete}>{continueLabel}</ContinueButton>;
};

const StepContentAdditionalInfoText = (props) => {
  const [renderAdditionalInfoTextOverlay, setRenderAdditionalInfoTextOverlay] = useState(false);

  const requireViewingAdditionalInfo = props.config?.requireAdditionalInfo === true;
  const requireAgreement = (props.config?.requireAgreement ?? props.config?.requireAdditionalInfo) === true;

  const handleComplete = () => {
    const now = Date.now();
    props.onComplete({
      value: 'Viewed' + (requireAgreement ? ' & Agreed' : ''),
      viewed_at: now,
      agreed_at: requireAgreement ? now : undefined,
    });
  };

  // Fixme:
  // const handleSkip = () => {};

  const handleDoneReading = () => {
    props.onViewed();
    setRenderAdditionalInfoTextOverlay(false);
  };

  const readyToContinue = requireViewingAdditionalInfo === false || props.viewed === true;
  const AdditionalInfoButton = readyToContinue ? ButtonSecondary : ButtonPrimary;

  let continueLabel = 'Continue';
  if (requireAgreement === true) {
    continueLabel = 'Agree & Continue';
  }

  return (
    <>
      <AdditionalInfoButton onClick={() => setRenderAdditionalInfoTextOverlay(true)}>
        {props.config?.additionalInfoLabel || (props.viewed ? 'Read Again' : 'Start Reading')}
      </AdditionalInfoButton>
      <SkipOrContinueButton readyToContinue={readyToContinue} onClick={handleComplete}>
        {continueLabel}
      </SkipOrContinueButton>
      {renderAdditionalInfoTextOverlay && (
        <Modal>
          <LongFormTextInputOverlay>
            <OverlayHeadline>{props.config?.additionalInfoHeadline}</OverlayHeadline>
            <OverlayContent>
              <OverlayText>
                <AutoLineBreak text={props.config?.additionalInfoContent} />
              </OverlayText>
            </OverlayContent>
            <OverlayFooter>
              <OverlayActions>
                <ContinueButton onClick={handleDoneReading}>Done Reading</ContinueButton>
              </OverlayActions>
            </OverlayFooter>
          </LongFormTextInputOverlay>
        </Modal>
      )}
    </>
  );
};

const StepContentAdditionalInfoUrl = (props) => {
  const requireViewingAdditionalInfo = props.config?.requireAdditionalInfo === true;
  const requireAgreement = (props.config?.requireAgreement ?? props.config?.requireAdditionalInfo) === true;

  const handleComplete = () => {
    const now = Date.now();
    props.onComplete({
      value: 'Viewed' + (requireAgreement ? ' & Agreed' : ''),
      viewed_at: Date.now(),
      agreed_at: requireAgreement ? now : undefined,
    });
  };

  const handleClick = () => props.onViewed();

  const readyToContinue = requireViewingAdditionalInfo === false || props.viewed === true;
  const AdditionalInfoButton = readyToContinue ? ButtonSecondary : ButtonPrimary;

  let continueLabel = 'Continue';
  if (requireAgreement === true) {
    continueLabel = 'Agree & Continue';
  }

  return (
    <>
      <AdditionalInfoButton as="a" target="_blank" href={props.config.additionalInfoUrl} onClick={handleClick}>
        {props.config?.additionalInfoLabel || 'View Linked Info'}
      </AdditionalInfoButton>
      <SkipOrContinueButton readyToContinue={readyToContinue} onClick={handleComplete}>
        {continueLabel}
      </SkipOrContinueButton>
    </>
  );
};

const LongFormTextInputOverlay = styled(Overlay)`
  z-index: 2147483010;
`;

const OverlayFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  background: ${(props) => props.theme.Prompt.Color.Default.Background};

  :before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    width: 100%;
    height: 20vh;
    background: linear-gradient(
      ${(props) => props.theme.Prompt.Color.Default.Transparent},
      ${(props) => props.theme.Prompt.Color.Default.Background}
    );
    pointer-events: none;
  }
`;

const OverlayActions = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 40rem;
  padding-bottom: 3.2rem;

  ${media.tabletLandscapeAndUp`
    padding-bottom: 6.5rem;
  `}
`;

const OverlayContent = styled.div`
  min-height: 33vh;
  padding-bottom: 30vh;

  ${media.tabletLandscapeAndUp`
    min-height: 50vh;
  `}
`;

const OverlayHeadline = styled(H1)`
  max-width: 12em;
  margin: 0 auto 1em;
  line-height: 1em;
`;

const OverlayText = styled(T1)`
  text-align: left;
`;

const stepContentBy = {
  none: StepContentDefault,
  text: StepContentAdditionalInfoText,
  url: StepContentAdditionalInfoUrl,
};

const getStepContentBy = (currentStep) => stepContentBy[currentStep] ?? stepContentBy.date;
