import { useEffect } from 'react';

export const usePressable = (onPressStart, onPressEnd, documentElements) => {
  // Fixme: handle one or more document elements
  useEffect(() => {
    const events = ['mouseenter', 'mouseup', 'touchend'];
    events.forEach((e) => document.documentElement.addEventListener(e, onPressEnd));
    return () => events.forEach((e) => document.documentElement.removeEventListener(e, onPressEnd));
  }, [onPressEnd]);

  return {
    onMouseDown: onPressStart,
    onTouchStart: onPressStart,
    onMouseUp: onPressEnd,
    onTouchEnd: onPressEnd,
  };
};
