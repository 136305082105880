import { AnimatedSwitch } from '@shared/components/AnimatedSwitch';
import React, { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { Account } from '../Account';
import { PrayerHubContext } from './context/PrayerHubContext';
import { Notifications } from './routes/Notifications';
import { PrayerDetail } from './routes/PrayerDetail/PrayerDetail';
import { PrayerFlow } from './routes/PrayerFlow';
import { PrayerTime } from './routes/PrayerTime/PrayerTime';
import { PrayerWall } from './routes/PrayerWall/PrayerWall';
import { RequestAccessFlow } from './routes/RequestAccessFlow';

export const Routes = (props) => {
  const history = useHistory();
  const context = useContext(PrayerHubContext);

  return (
    <AnimatedSwitch>
      <Route exact path="/">
        <PrayerWall />
      </Route>
      <Route path="/share">
        <PrayerFlow />
      </Route>
      <Route path="/access">
        <RequestAccessFlow />
      </Route>
      <Route path="/prayer/:id">
        <PrayerDetail />
      </Route>
      <Route path="/prayertime/:id">
        <PrayerTime />
      </Route>
      <Route path="/notifications">
        <Notifications />
      </Route>
      <Route path="/account/:path">
        <Account goBack={history.goBack} linkArgs={{ prayerhub: context.prayerhub }} />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </AnimatedSwitch>
  );
};
