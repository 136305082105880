import { useState } from 'react';
import { useInterval } from './useInterval';

export const useCountDown = (durationMs: number, intervalMs = 100): number => {
  const [remainingMs, setRemainingMs] = useState(durationMs);

  let delay: number | null = intervalMs;

  if (remainingMs === 0) {
    // stop the clock!
    delay = null;
  }

  useInterval(() => {
    setRemainingMs((prev) => Math.max(prev - intervalMs, 0));
  }, delay);

  return remainingMs;
};
