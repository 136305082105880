import { createReducer } from '@reduxjs/toolkit';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { formatValueWithPrefix } from '@shared/lib/format';
import { pick as _pick } from 'lodash';
import {
  clearAddress,
  isAddressValid,
  setAddress,
  setAttribute,
  setCurrentStep,
} from '../actions/stepSmartPhysicalAddressActions';
import { ADDRESS_ATTRIBUTE_KEYS, ADDRESS_FIELDS } from '../constants';

export const selectCurrentStep = (state) => state.currentStep;

export const selectAddress = (state) => state.address;
export const selectAttribute = (state) => state.attribute;
export const selectOptions = (state) => state.options;

export const selectLabel = (state, config) => {
  if (state.currentStep === 'select') {
    return 'Choose an address';
  }

  if (!['confirm', 'search'].includes(state.currentStep)) {
    return `What ${ADDRESS_FIELDS[state.currentStep]?.label}?`;
  }

  return config.label;
};

export const selectMessage = (state, config, data) => {
  const skipped = data?.skipped === true;

  if (skipped) {
    return `Skipped: "${config.label}"`;
  }

  return formatValueWithPrefix(config.valuePrefixLabel, data?.value?.street_address, config.valuePrefixSeparator);
};

const initialState = deepFreeze({
  attribute: undefined,
  currentStep: 'search',
  address: {
    street_address: '',
    street_address_2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  },
  options: [],
});

export const init = (args) => ({
  ...initialState,
  currentStep: initCurrentStep(args),
  address: args.data?.value ?? initialState.address,
  options: initOptions(args),
});

const initOptions = (args) => {
  return args.physicalAddressAttributes.map((physicalAddressAttribute) => {
    const streetAddress =
      physicalAddressAttribute?.value?.street_address ?? physicalAddressAttribute?.value?.address_mailing ?? '';
    return {
      id: streetAddress,
      label: streetAddress,
      attribute: physicalAddressAttribute,
      value: {
        ..._pick(physicalAddressAttribute.value, ADDRESS_ATTRIBUTE_KEYS),
        street_address: streetAddress,
      },
    };
  });
};

const initCurrentStep = (args) => {
  const { data, physicalAddressAttributes } = args;

  if (data?.value === undefined && physicalAddressAttributes.length > 0) {
    return 'select';
  }

  if (data?.attribute !== undefined) {
    return 'select';
  }

  if (data?.value === undefined) {
    return 'search';
  }

  if (!isAddressValid(data.value)) {
    return 'search';
  }

  return 'confirm';
};

export const stepSmartPhysicalAddressReducer = createReducer(initialState, {
  [setCurrentStep]: (state, action) => ({
    ...state,
    currentStep: action.payload,
  }),
  [setAttribute]: (state, action) => ({
    ...state,
    attribute: action.payload,
  }),
  [setAddress]: (state, action) => ({
    ...state,
    address: {
      ...state.address,
      ...action.payload,
    },
  }),
  [clearAddress]: (state, action) => ({
    ...state,
    address: initialState.address,
  }),
});
