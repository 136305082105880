import { ButtonOptionSecondary, DatePicker, media } from '@foyyay/flow-elements';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { dateToIsoString, isoStringToDate } from '@shared/lib/datetime';
import * as dateFns from 'date-fns';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SmartStepActionButtons } from '../../../components/StepActionButtons';
import { setBirthday, setCurrentStep } from '../actions/stepSmartBirthdayActions';
import { selectBirthday } from '../reducers/stepSmartBirthdayReducer';

export const StepSmartBirthdayPicker = (props) => {
  const { state, dispatch, useSelector, onComplete } = props;

  const now = useMemo(() => new Date(), []);

  const birthday = useSelector(selectBirthday);
  const value = isoStringToDate(birthday ?? now.toISOString());

  const startDate = dateFns.subYears(now, 100);
  const endDate = now;

  const handleChange = (value) => dispatch(setBirthday(dateToIsoString(value)));

  const readyToContinue = state.modified;

  const handleTypeClick = () => dispatch(setCurrentStep('input'));

  const handleSaveClick = useClickHandler(() => {
    if (!readyToContinue) {
      return;
    }
    onComplete({
      value: birthday,
    });
  });

  const handleSkipClick = useClickHandler(() => {
    onComplete({
      skipped: true,
      value: undefined,
    });
  });

  return (
    <>
      <InputWrapper>
        <StyledDatePicker value={value} onChange={handleChange} startDate={startDate} endDate={endDate} />
        <ButtonOptionSecondary onClick={handleTypeClick}>Type it in</ButtonOptionSecondary>
      </InputWrapper>
      <SmartStepActionButtons
        onContinueClick={handleSaveClick}
        onSkipClick={handleSkipClick}
        readyToContinue={readyToContinue}
        required={props.required}
      />
    </>
  );
};

const InputWrapper = styled.div`
  margin-bottom: 3rem;
`;

const StyledDatePicker = styled(DatePicker)`
  margin-left: -2.2rem;
  margin-right: -2.2rem;
  margin-bottom: 3rem;

  ${media.tabletLandscapeAndUp`
    margin-left: -3.6rem;
    margin-right: -3.6rem;
  `}
`;
