import type { SrcSet } from '@nucleus/types/media/image';
import { pick as _pick, sortBy as _sortBy } from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { font, media } from './style-utils';

interface CustomText {
  text: string;
  bold?: boolean;
  code?: boolean;
  italic?: boolean;
  underline?: boolean;
  strike?: boolean;
}

interface ImageElement {
  type: 'image';
  alt?: string;
  src: string;
  srcSet?: SrcSet[];
  href?: string;
  fileId?: string;
  bondId?: string;
  size?: 'small' | 'medium' | 'large';
  children: Array<CustomText | Element>;
}

interface LinkElement {
  type: 'link';
  href: string;
  children: Array<CustomText | Element>;
}

interface ParameterElement {
  type: 'parameter';
  parameter: 'firstName' | 'lastName' | 'name';
  fallback: string;
  children: Array<CustomText | Element>;
}

interface CustomElement {
  type:
    | 'div'
    | 'paragraph'
    | 'block-quote'
    | 'bulleted-list'
    | 'numbered-list'
    | 'list-item'
    | 'heading-one'
    | 'heading-two'
    | 'heading-three'
    | 'heading-four';
  children: Array<CustomText | Element>;
}

type Element = ImageElement | LinkElement | ParameterElement | CustomElement;

type Node = Element | CustomText;

const isElement = (node: Node): node is Element => (node as Element).type !== undefined;

interface Props {
  nodes: Node[];
  parameters?: {
    firstName?: string;
    lastName?: string;
    name?: string;
  };
}

interface Context {
  parameters?: {
    firstName?: string;
    lastName?: string;
    name?: string;
  };
}

const Context = React.createContext({} as Context);

export const RichText = (props: Props): JSX.Element => {
  const context: Context = {
    parameters: props.parameters,
  };

  return (
    <Context.Provider value={context}>
      <RenderNodes nodes={props.nodes} />
    </Context.Provider>
  );
};

const RenderNodes = (props: { nodes: Node[] }): JSX.Element => {
  return (
    <>
      {props.nodes.map((node) => {
        if (isElement(node)) {
          return <Element node={node} />;
        }

        return <Leaf node={node} />;
      })}
    </>
  );
};

const Element = ({ node: element }: { node: Element }): JSX.Element | null => {
  switch (element.type) {
    case 'heading-one':
      return (
        <Heading>
          <RenderNodes nodes={element.children} />
        </Heading>
      );
    case 'paragraph':
      if (isNewLine(element)) {
        return <br />;
      }
      return (
        <Paragraph>
          <RenderNodes nodes={element.children} />
        </Paragraph>
      );
    case 'link':
      return (
        <Link href={element.href} target="_blank">
          <RenderNodes nodes={element.children} />
        </Link>
      );
    case 'image': {
      return <Image {...getImageProps(element)} />;
    }
    case 'bulleted-list': {
      return (
        <BulletedList>
          <RenderNodes nodes={element.children} />
        </BulletedList>
      );
    }
    case 'numbered-list': {
      return (
        <NumberedList>
          <RenderNodes nodes={element.children} />
        </NumberedList>
      );
    }
    case 'list-item': {
      return (
        <ListItem>
          <RenderNodes nodes={element.children} />
        </ListItem>
      );
    }
    case 'parameter':
      return <Parameter node={element} />;
    default:
      return null;
  }
};

const getImageProps = (element: ImageElement) => {
  if (element.srcSet === undefined || element.srcSet.length < 1) {
    return _pick(element, 'src', 'alt');
  }

  const sortedSources = _sortBy(element.srcSet, (src) => src.dimensions.width);

  return {
    alt: element.alt,
    src: sortedSources[0].src,
    sizes: sortedSources
      .map<string>((source, index) => {
        if (sortedSources.length === index + 1) {
          return `${source.dimensions.width}px`;
        }
        return `(max-width: ${source.dimensions.width}px) ${source.dimensions.width}px`;
      })
      .join(', '),
    srcSet: sortedSources.map<string>((source) => `${source.src} ${source.dimensions.width}w`).join(', '),
  };
};

const Leaf = ({ node: leaf }: { node: CustomText }): JSX.Element => {
  let textElement = <>{leaf.text}</>;

  if (leaf.bold === true) {
    textElement = <b>{textElement}</b>;
  }

  if (leaf.italic === true) {
    textElement = <i>{textElement}</i>;
  }

  if (leaf.underline === true) {
    textElement = <u>{textElement}</u>;
  }

  if (leaf.strike === true) {
    textElement = <s>{textElement}</s>;
  }

  return textElement;
};

const Parameter = (props: { node: ParameterElement }): JSX.Element => {
  const { parameters } = useContext(Context);
  return <>{parameters?.[props.node.parameter] ?? props.node.fallback}</>;
};

const isNewLine = (node: Element): boolean => {
  return node.children.length === 1 && (node.children[0] as CustomText).text === '';
};

const Heading = styled.div`
  ${font(24, 'Bold', -1, 24)}
  padding: 0;

  ${media.tabletLandscapeAndUp`
    ${font(32, 'Bold', -1.25, 32)}
    max-width: 17em;
  `}
`;

export const Paragraph = styled.p`
  ${font(18, 'Book', -1, 25)}
  margin: 0;
  padding: 0;

  &:last-child {
    padding-bottom: 0.6rem;
  }

  ${media.tabletLandscapeAndUp`
    ${font(23, 'Book', -1.25, 32)}
  `}
`;

const Image = styled.img`
  display: block;
  width: 100%;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  border: none;
  border-radius: 1.4rem;
  overflow: hidden;
  /* Fix weird Safari bug breaking rounded corners */
  mask-image: -webkit-radial-gradient(white, black);

  ${media.tabletLandscapeAndUp`
    margin-bottom: 1.5rem;
  `}

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Link = styled.a`
  text-decoration: underline;
`;

const BulletedList = styled.ul`
  ${font(18, 'Book', -1, 25)}
  list-style-position: inside;
  padding-left: 1em;
  margin: 0;

  p + & {
    margin-top: 0.5em;
  }
`;

const NumberedList = styled.ol`
  ${font(18, 'Book', -1, 25)}
  list-style-position: inside;
  padding-left: 1em;
  margin: 0;

  p + & {
    margin-top: 0.5em;
  }
`;

const ListItem = styled.li`
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }
`;
