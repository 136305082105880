import { media } from '@foyyay/flow-elements';
import { StaggeredFade } from '@shared/components/StaggeredFade';
import { ScrollContext } from '@shared/context/ScrollContext';
import { useScrollTopReset } from '@shared/hooks/useScrollTop';
import React, { useContext } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

export const Layout = (props: Props): JSX.Element => {
  const { scrollTargetProps } = useContext<any>(ScrollContext);
  useScrollTopReset();

  return (
    <Body>
      <ScrollContainer {...scrollTargetProps}>
        <Content>
          <StaggeredFade>
            <LogoPlaceholder />
            <CenterColumn>
              <Container>{props.children}</Container>
            </CenterColumn>
          </StaggeredFade>
        </Content>
      </ScrollContainer>
    </Body>
  );
};

export const Wrapper = styled.div`
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

const Body = styled.div`
  position: relative;
  flex: 1;
`;

const ScrollContainer = styled.div`
  z-index: 2147483002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  min-width: 320px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  padding-top: 5rem;
`;

const CenterColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
`;

const Container = styled.div`
  width: 100%;
  min-height: 40vh;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  padding-bottom: 14rem; // Leave room for the footer

  ${media.tabletLandscapeAndUp`
    min-height: 37vh;
    padding: 0 3rem;
    padding-bottom: 16rem; // Leave room for the footer
  `}
`;

const LogoPlaceholder = styled.div`
  margin-top: 2rem;
`;
