import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Input } from './Input';
import { font, media } from './style-utils';

const InputBankAccountNumber = styled(Input)`
  ${font(34, 'Bold', -0.75)}

  @media (min-width: 480px) {
    font-size: 4.8rem;
  }

  color: ${(props) => props.theme.InputCard.Color.Default};
`;

const InputStyles = css`
  ${font(24, 'Book', -0.5)}

  @media (min-width: 480px) {
    font-size: 3rem;
  }
`;

const InputBankRoutingNumber = styled(Input)`
  ${InputStyles};
  color: ${(props) => props.theme.InputCard.Color.Default};
  width: 5.5em;
`;

const InputBankAccountHolderName = styled(Input)`
  ${InputStyles};
  color: ${(props) => props.theme.InputCard.Color.Default};
  width: 9em;
  padding-left: 0.5em;

  @media (min-width: 360px) {
    padding-left: 1em;
  }
`;

const F2Container = styled.div`
  align-items: center;
  display: flex;
  height: 3.6rem;
  overflow: hidden;

  @media (min-width: 480px) {
    height: 4.6rem;
  }
`;

const F3Container = styled.div`
  align-items: center;
  display: flex;
  height: 2.4rem;
  overflow: hidden;

  @media (min-width: 480px) {
    height: 3rem;
  }
`;

const BankAccountNumber = (props) => {
  const { onCreditCardTypeChanged, ...otherProps } = props;
  return (
    <F2Container>
      <InputBankAccountNumber
        as={Cleave}
        options={{
          numericOnly: true,
        }}
        type="tel"
        name="bankAccountNumber"
        placeholder="Type account here"
        autoCorrect="off"
        spellCheck="false"
        {...otherProps}
      />
    </F2Container>
  );
};

const BankRoutingNumber = (props) => {
  return (
    <F3Container>
      <InputBankRoutingNumber
        as={Cleave}
        options={{
          blocks: [9],
          numericOnly: true,
        }}
        type="tel"
        name="bankRoutingNumber"
        placeholder="Routing"
        autoCorrect="off"
        spellCheck="false"
        {...props}
      />
    </F3Container>
  );
};

const BankAccountHolderName = (props) => {
  return (
    <F3Container>
      <InputBankAccountHolderName
        type="text"
        name="name"
        placeholder="Your Full Name"
        autoComplete="name"
        autoCorrect="off"
        spellCheck="false"
        size="14"
        {...props}
      />
    </F3Container>
  );
};

export class InputBank extends Component {
  static propTypes = {
    bankAccountNumber: PropTypes.string,
    bankRoutingNumber: PropTypes.string,
    bankAccountHolderName: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    bankAccountNumber: '',
    bankRoutingNumber: '',
    bankAccountHolderName: '',
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    // These refs are set by Cleave
    this.inputBankAccountNumberRef = undefined;
    this.inputBankRoutingNumberRef = undefined;
    this.inputBankAccountHolderNameRef = undefined;
  }

  focus = () => {
    this.inputBankAccountNumberRef.focus();
  };

  handleBankAccountNumberChange = (e) => {
    this.onChangeCallback({ bankAccountNumber: e.target.value });
  };

  handleBankRoutingNumberChange = (e) => {
    this.onChangeCallback({ bankRoutingNumber: e.target.value });
  };

  handleBankAccountHolderNameChange = (e) => {
    this.onChangeCallback({ bankAccountHolderName: e.target.value });
  };

  onChangeCallback = (value) => {
    return this.props.onChange({
      ...value,
    });
  };

  render() {
    return (
      <GridContainer>
        <BankAccountNumber
          value={this.props.bankAccountNumber}
          onChange={this.handleBankAccountNumberChange}
          htmlRef={(ref) => (this.inputBankAccountNumberRef = ref)}
        />
        <FlexContainer>
          <BankRoutingNumber
            value={this.props.bankRoutingNumber}
            onChange={this.handleBankRoutingNumberChange}
            htmlRef={(ref) => (this.inputBankRoutingNumberRef = ref)}
          />
          <BankAccountHolderName
            value={this.props.bankAccountHolderName}
            onChange={this.handleBankAccountHolderNameChange}
            htmlRef={(ref) => (this.inputBankAccountHolderNameRef = ref)}
          />
        </FlexContainer>
      </GridContainer>
    );
  }
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-row-gap: 2rem;
  margin-bottom: 3.5rem;

  ${media.tabletLandscapeAndUp`
    grid-row-gap: 1.6rem;
    margin-bottom: 4.9rem;
  `}

  ${F2Container} {
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;
