export const FLOW_TYPE_ACCESS_REQUEST = 'FLOW_TYPE_ACCESS_REQUEST';
export const FLOW_TYPE_CUSTOM = 'FLOW_TYPE_CUSTOM';
export const FLOW_TYPE_GIVING = 'FLOW_TYPE_GIVING';
export const FLOW_TYPE_PRAYER = 'FLOW_TYPE_PRAYER';

export const FLOW_SUBMISSION_LABEL_ACCESS_REQUEST = 'request';
export const FLOW_SUBMISSION_LABEL_SUBMISSION = 'submission';
export const FLOW_SUBMISSION_LABEL_GIFT = 'gift';
export const FLOW_SUBMISSION_LABEL_PRAYER = 'prayer';

export const FLOW_SUBMISSION_LABEL_ACCESS_REQUEST_PLURAL = 'requests';
export const FLOW_SUBMISSION_LABEL_SUBMISSION_PLURAL = 'submissions';
export const FLOW_SUBMISSION_LABEL_GIFT_PLURAL = 'gifts';
export const FLOW_SUBMISSION_LABEL_PRAYER_PLURAL = 'prayers';

export const FLOW_SUBMISSION_LABEL_BY = {
  [FLOW_TYPE_ACCESS_REQUEST]: FLOW_SUBMISSION_LABEL_ACCESS_REQUEST,
  [FLOW_TYPE_CUSTOM]: FLOW_SUBMISSION_LABEL_SUBMISSION,
  [FLOW_TYPE_GIVING]: FLOW_SUBMISSION_LABEL_GIFT,
  [FLOW_TYPE_PRAYER]: FLOW_SUBMISSION_LABEL_PRAYER,
};

export const FLOW_SUBMISSION_LABEL_PLURAL_BY = {
  [FLOW_TYPE_ACCESS_REQUEST]: FLOW_SUBMISSION_LABEL_ACCESS_REQUEST_PLURAL,
  [FLOW_TYPE_CUSTOM]: FLOW_SUBMISSION_LABEL_SUBMISSION_PLURAL,
  [FLOW_TYPE_GIVING]: FLOW_SUBMISSION_LABEL_GIFT_PLURAL,
  [FLOW_TYPE_PRAYER]: FLOW_SUBMISSION_LABEL_PRAYER_PLURAL,
};
