import { BodyStyle } from '@foyyay/flow-elements';
import { clickItem } from '@shared/actions/springboard';
import { Header } from '@shared/components/Header';
import { Wrapper } from '@shared/components/Layout';
import { ThemeContext } from '@shared/components/ThemeContext';
import { LAUNCHER_ITEM_TYPE } from '@shared/constants/launcher';
import { ControllerContext } from '@shared/context/ControllerContext';
import { ScrollContext } from '@shared/context/ScrollContext';
import { useLauncherConfig } from '@shared/hooks/useLauncherConfig';
import { useScrollTop } from '@shared/hooks/useScrollTop';
import { useTransientLocationState } from '@shared/hooks/useTransientLocationState';
import { getParentWindow } from '@shared/lib/getParentWindow';
import { isExternalLink } from '@shared/lib/url';
import { selectItems, selectLauncherConfig } from '@shared/reducers/launcher';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MemoryRouter, useHistory } from 'react-router-dom';
import { Routes } from './Routes';
import { SpringboardContext } from './context/SpringboardContext';
import { toggleOverlay } from '@shared/actions/launcher';
import { LAUNCHER_ACTION_TYPE, INFOCARD_ACTION_TYPE } from '@nucleus/launcher';

export const Springboard = () => {
  const config = useSelector(selectLauncherConfig);
  const [titleOverride, setTitleOverride] = useState();
  const { itemIdToActivate, infocardIdToActivate, initialPath } = useTransientLocationState([
    'itemIdToActivate',
    'infocardIdToActivate',
    'initialPath',
  ]);
  const title = titleOverride || config?.title;

  const initialEntries = ['/'];

  if (initialPath) {
    initialEntries.push(initialPath);
  }

  return (
    <MemoryRouter initialEntries={initialEntries} initialIndex={initialEntries.length}>
      <SpringboardController
        itemIdToActivate={itemIdToActivate}
        infocardIdToActivate={infocardIdToActivate}
        setTitleOverride={setTitleOverride}
      >
        <BodyStyle />
        <Wrapper>
          <Header title={title} />
          <Routes />
        </Wrapper>
      </SpringboardController>
    </MemoryRouter>
  );
};

Springboard.propTypes = {
  shortcode: PropTypes.string,
};

const useActivateValue = (value, doActivate) => {
  useEffect(() => {
    if (value === undefined) return;
    doActivate(value);
  }, [value, doActivate]);
};

const SpringboardController = (props) => {
  const [config] = useLauncherConfig(props.shortcode);
  const dispatch = useDispatch();
  const history = useHistory();
  const scrollContainerRef = useRef();
  const items = useSelector(selectItems);

  const { goToFlow, goToPrayerHub, goToUrl } = useContext(ControllerContext);
  const goToInfoCard = useCallback(
    (infocardId) => {
      history.push(`/info/${infocardId}`);
    },
    [history]
  );

  const actionHandlerByTypeMap = useMemo(() => {
    return {
      [LAUNCHER_ACTION_TYPE.Flow]: (action) => goToFlow(action.destination),
      [LAUNCHER_ACTION_TYPE.PrayerHub]: (action) => goToPrayerHub(action.destination),
      [LAUNCHER_ACTION_TYPE.Url]: (action) => {
        const openInNewTab = isExternalLink(action.destination, getParentWindow().location) || action.openInNewTab;
        goToUrl(action.destination, openInNewTab);
      },
      [LAUNCHER_ACTION_TYPE.InfoCard]: (action) => goToInfoCard(action.destination),
      [LAUNCHER_ACTION_TYPE.Dismiss]: (action) => {
        history.goBack();
        dispatch(toggleOverlay(false));
      },
      [INFOCARD_ACTION_TYPE.Email]: (action) => window.open(action.destination),
    };
  }, [dispatch, history, goToFlow, goToUrl, goToPrayerHub, goToInfoCard]);

  const handleAction = useCallback(
    (action) => {
      const actionHandler = actionHandlerByTypeMap[action.type];
      if (typeof actionHandler !== 'function') return;
      actionHandler(action);
    },
    [actionHandlerByTypeMap]
  );

  const itemClickHandlerByTypeMap = useMemo(() => {
    return {
      [LAUNCHER_ITEM_TYPE.Button]: (item) => handleAction(item.action),
    };
  }, [handleAction]);

  const activateLauncherItem = useCallback(
    (item) => {
      dispatch(clickItem(item, itemClickHandlerByTypeMap));
    },
    [dispatch, itemClickHandlerByTypeMap]
  );

  const handleClickLauncherItem = useCallback(
    (e, item) => {
      e.stopPropagation();
      activateLauncherItem(item);
    },
    [activateLauncherItem]
  );

  const doActivateLauncherItem = useCallback(
    (itemToActivateId) => {
      const itemToActivate = items.find((item) => item.id === itemToActivateId);
      if (itemToActivate === undefined) return;
      activateLauncherItem(itemToActivate);
    },
    [items, activateLauncherItem]
  );

  const doActivateInfoCard = useCallback(
    (infocardId) => {
      goToInfoCard(infocardId);
    },
    [goToInfoCard]
  );

  useActivateValue(props.itemIdToActivate, doActivateLauncherItem);
  useActivateValue(props.infocardIdToActivate, doActivateInfoCard);

  const context = {
    launcher: {
      shortcode: config.shortcode,
    },
    scrollContainerRef: scrollContainerRef,
    handleClickLauncherItem: handleClickLauncherItem,
    handleAction: handleAction,
    setTitleOverride: props.setTitleOverride,
  };

  const [scrollTop, scrollTargetProps, resetScrollTop] = useScrollTop();

  const scrollContext = {
    scrollTop: scrollTop,
    scrollTargetProps: scrollTargetProps,
    resetScrollTop: resetScrollTop,
  };

  if (config === undefined) {
    return null;
  }

  return (
    <SpringboardContext.Provider value={context}>
      <ScrollContext.Provider value={scrollContext}>
        <ThemeContext
          color={config.color_flow}
          mode={config.color_mode}
          paletteVibrancy={config.color_flow_palette_vibrancy}
        >
          {props.children}
        </ThemeContext>
      </ScrollContext.Provider>
    </SpringboardContext.Provider>
  );
};
