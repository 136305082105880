import PropTypes from 'prop-types';
import { partial as _partial } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { font, media } from './style-utils';
import { getThemeValue } from './Theme';

const getScopedThemeValue = _partial(getThemeValue, 'ButtonOptionBadge.Color');
const getVariantThemeValue =
  (...paths) =>
  (props) =>
    getScopedThemeValue(props.variant, ...paths)(props);

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

const Foreground = styled.div`
  width: auto;
  padding: 0 1.4rem;
`;

const Text = styled.span`
  ${font(14, 'Bold', -0.5)}
  display: block;
  white-space: nowrap;

  /* Vertical alignment adjustment */
  transform: translateY(-0.1rem);

  ${media.tabletLandscapeAndUp`
    ${font(16)}
  `};
`;

const StyledBadge = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 2.5rem;
  color: ${getVariantThemeValue('Default.Foreground')};
  height: 3rem;
  position: relative;

  /* Horizontal alignment adjustment */
  transform: translateX(1rem);

  > ${Background} {
    border-radius: 2.5rem;
    background-color: ${getVariantThemeValue('Default.Background')};
  }
`;

export const ButtonOptionBadge = (props) => {
  const { children, variant, ...otherProps } = props;

  return (
    <StyledBadge variant={variant} {...otherProps}>
      <Background />
      <Foreground>
        <Text>{children}</Text>
      </Foreground>
    </StyledBadge>
  );
};

ButtonOptionBadge.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

ButtonOptionBadge.defaultProps = {
  variant: 'primary',
};
