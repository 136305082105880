import { createGlobalStyle } from 'styled-components';
import linetoCircularBlackEot from '../fonts/lineto-circular-black.eot';
import linetoCircularBlackWoff from '../fonts/lineto-circular-black.woff';
import linetoCircularBlackWoff2 from '../fonts/lineto-circular-black.woff2';
import linetoCircularBlackItalicEot from '../fonts/lineto-circular-blackItalic.eot';
import linetoCircularBlackItalicWoff from '../fonts/lineto-circular-blackItalic.woff';
import linetoCircularBlackitalicWoff2 from '../fonts/lineto-circular-blackitalic.woff2';
import linetoCircularBoldEot from '../fonts/lineto-circular-bold.eot';
import linetoCircularBoldWoff from '../fonts/lineto-circular-bold.woff';
import linetoCircularBoldWoff2 from '../fonts/lineto-circular-bold.woff2';
import linetoCircularBoldItalicEot from '../fonts/lineto-circular-boldItalic.eot';
import linetoCircularBoldItalicWoff from '../fonts/lineto-circular-boldItalic.woff';
import linetoCircularBolditalicWoff2 from '../fonts/lineto-circular-bolditalic.woff2';
import linetoCircularBookEot from '../fonts/lineto-circular-book.eot';
import linetoCircularBookWoff from '../fonts/lineto-circular-book.woff';
import linetoCircularBookWoff2 from '../fonts/lineto-circular-book.woff2';
import linetoCircularBookItalicEot from '../fonts/lineto-circular-bookItalic.eot';
import linetoCircularBookItalicWoff from '../fonts/lineto-circular-bookItalic.woff';
import linetoCircularBookitalicWoff2 from '../fonts/lineto-circular-bookitalic.woff2';
import linetoCircularMediumEot from '../fonts/lineto-circular-medium.eot';
import linetoCircularMediumWoff from '../fonts/lineto-circular-medium.woff';
import linetoCircularMediumWoff2 from '../fonts/lineto-circular-medium.woff2';
import linetoCircularMediumItalicEot from '../fonts/lineto-circular-mediumItalic.eot';
import linetoCircularMediumItalicWoff from '../fonts/lineto-circular-mediumItalic.woff';
import linetoCircularMediumitalicWoff2 from '../fonts/lineto-circular-mediumitalic.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: 'LL Circular';
    src: url('${linetoCircularBlackEot}');
    src: url('${linetoCircularBlackEot}?#iefix') format('embedded-opentype'),
      url('${linetoCircularBlackWoff2}') format('woff2'),
      url('${linetoCircularBlackWoff}') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'LL Circular';
    src: url('${linetoCircularBlackItalicEot}');
    src: url('${linetoCircularBlackItalicEot}?#iefix') format('embedded-opentype'),
      url('${linetoCircularBlackitalicWoff2}') format('woff2'),
      url('${linetoCircularBlackItalicWoff}') format('woff');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'LL Circular';
    src: url('${linetoCircularBoldEot}');
    src: url('${linetoCircularBoldEot}?#iefix') format('embedded-opentype'),
      url('${linetoCircularBoldWoff2}') format('woff2'), url('${linetoCircularBoldWoff}') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'LL Circular';
    src: url('${linetoCircularBoldItalicEot}');
    src: url('${linetoCircularBoldItalicEot}?#iefix') format('embedded-opentype'),
      url('${linetoCircularBolditalicWoff2}') format('woff2'),
      url('${linetoCircularBoldItalicWoff}') format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'LL Circular';
    src: url('${linetoCircularBookEot}');
    src: url('${linetoCircularBookEot}?#iefix') format('embedded-opentype'),
      url('${linetoCircularBookWoff2}') format('woff2'), url('${linetoCircularBookWoff}') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'LL Circular';
    src: url('${linetoCircularBookItalicEot}');
    src: url('${linetoCircularBookItalicEot}?#iefix') format('embedded-opentype'),
      url('${linetoCircularBookitalicWoff2}') format('woff2'),
      url('${linetoCircularBookItalicWoff}') format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'LL Circular';
    src: url('${linetoCircularMediumEot}');
    src: url('${linetoCircularMediumEot}?#iefix') format('embedded-opentype'),
      url('${linetoCircularMediumWoff2}') format('woff2'),
      url('${linetoCircularMediumWoff}') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'LL Circular';
    src: url('${linetoCircularMediumItalicEot}');
    src: url('${linetoCircularMediumItalicEot}?#iefix') format('embedded-opentype'),
      url('${linetoCircularMediumitalicWoff2}') format('woff2'),
      url('${linetoCircularMediumItalicWoff}') format('woff');
    font-weight: 500;
    font-style: italic;
  }
`;
