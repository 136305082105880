import { ButtonOption } from '@foyyay/flow-elements';
import { withStopPropagation } from '@shared/lib/events';
import PropTypes from 'prop-types';
import React from 'react';

export const Select = (props) => {
  const handleClick = withStopPropagation((option) => props.onChange(option));

  return (
    <div className={props.className} style={props.style}>
      {props.options.map((option) => {
        return (
          <ButtonOption
            key={option.id}
            badge={option.badge}
            variant={option.style ?? props.variant}
            onClick={(e) => handleClick(e, option)}
          >
            {option.label || option.name}
          </ButtonOption>
        );
      })}
    </div>
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

Select.defaultProps = {
  variant: 'primary',
};

export const SelectSecondary = (props) => {
  return <Select {...props} variant="secondary" />;
};

SelectSecondary.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
};
