import { ButtonSecondary, H1, InputText, media, Overlay } from '@foyyay/flow-elements';
import { Modal } from '@shared/components/Modal';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { fromBase64, toBase64 } from '@shared/lib/encoding';
import { formatValueWithPrefix } from '@shared/lib/format';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { FlowStep } from '../components/FlowStep';
import { SkipOrContinueButton } from '../components/StepActionButtons';

export const StepText = (props) => {
  const parseValue = parser(props.config.isBase64Encoded);

  const defaultValue = '';
  const initialValue = parseValue(props.data?.value ?? defaultValue);

  const skipped = props.data?.skipped === true;
  const message = skipped
    ? `Skipped: "${props.config.label}"`
    : formatValueWithPrefix(props.config.valuePrefixLabel, initialValue.trim(), props.config.valuePrefixSeparator);

  const limit = 500;
  const softLimit = true;

  return (
    <FlowStep message={message}>
      <TextInput
        config={props.config}
        label={props.config.label}
        limit={limit}
        onComplete={props.completeStep}
        onIncomplete={props.incompleteStep}
        onSave={props.saveStep}
        required={props.config.required}
        softLimit={softLimit}
        suggestedLength={props.config.suggestedLength}
        value={initialValue}
      />
    </FlowStep>
  );
};

const TextInput = (props) => {
  const { config, onComplete, onSave, onIncomplete } = props;
  const inputRef = useInputFocusOnceRef();

  const [showLongFormInput, setShowLongFormInput] = useState(false);

  const readyToContinue = props.value.trim().length > 0;

  const serializeValue = serializer(config.isBase64Encoded);

  const handleChange = (value) => {
    onSave({ value: serializeValue(value) });
    if (!value.trim().length) {
      onIncomplete();
    }
    if (value.length > props.limit) {
      setShowLongFormInput(true);
    }
  };

  const handleComplete = useCallback(() => {
    if (!readyToContinue) {
      return;
    }

    const value = serializeValue(props.value.trim());
    onComplete({ value: value });
  }, [props.value, serializeValue, onComplete, readyToContinue]);

  const handleSaveClick = useClickHandler(handleComplete);
  const handleSubmit = useSubmitHandler(handleComplete);

  const handleSkipClick = useClickHandler(() => {
    onComplete({
      skipped: true,
      value: undefined,
    });
  }, [onComplete]);

  const handleOpenLongFormInputClick = useClickHandler(() => setShowLongFormInput(true));

  const showLongFormTrigger = props.value.length >= props.limit;
  const longFormTriggerText = props.value.length > props.limit ? 'View & edit all text' : 'Keep writing?';

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputText
          placeholder="Type here"
          prompt={config.prompt}
          suggestedLength={props.suggestedLength}
          limit={props.limit}
          softLimit={props.softLimit}
          value={props.value}
          onChange={handleChange}
          ref={inputRef}
          textSize="small"
        />
      </form>
      {showLongFormTrigger && (
        <ButtonSecondary onClick={handleOpenLongFormInputClick}>{longFormTriggerText}</ButtonSecondary>
      )}
      <SkipOrContinueButton
        onClick={handleSaveClick}
        onClickSkip={handleSkipClick}
        readyToContinue={readyToContinue}
        required={props.required}
      />
      {showLongFormInput && (
        <Modal>
          <LongFormTextInputOverlay>
            <OverlayHeadline>{props.label}</OverlayHeadline>
            <OverlayContent>
              <form onSubmit={handleSubmit}>
                <InputText placeholder="Type here" value={props.value} onChange={handleChange} textSize="extra-small" />
              </form>
            </OverlayContent>
            <OverlayFooter>
              <OverlayActions>
                <SkipOrContinueButton
                  onClick={handleSaveClick}
                  onClickSkip={handleSkipClick}
                  readyToContinue={readyToContinue}
                  required={props.required}
                >
                  Save &amp; Continue
                </SkipOrContinueButton>
              </OverlayActions>
            </OverlayFooter>
          </LongFormTextInputOverlay>
        </Modal>
      )}
    </>
  );
};

const parser = (isBase64Encoded) => (value) => (isBase64Encoded === true ? fromBase64(value) : value);
const serializer = (isBase64Encoded) => (value) => (isBase64Encoded === true ? toBase64(value) : value);

const LongFormTextInputOverlay = styled(Overlay)`
  z-index: 2147483010;
`;

const OverlayFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  background: ${(props) => props.theme.Prompt.Color.Default.Background};

  :before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    width: 100%;
    height: 20vh;
    background: linear-gradient(
      ${(props) => props.theme.Prompt.Color.Default.Transparent},
      ${(props) => props.theme.Prompt.Color.Default.Background}
    );
    pointer-events: none;
  }
`;

const OverlayActions = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 40rem;
  padding-bottom: 3.2rem;

  ${media.tabletLandscapeAndUp`
    padding-bottom: 6.5rem;
  `}
`;

const OverlayContent = styled.div`
  min-height: 33vh;
  padding-bottom: 30vh;

  ${media.tabletLandscapeAndUp`
    min-height: 50vh;
  `}
`;

const OverlayHeadline = styled(H1)`
  max-width: 12em;
  margin: 0 auto 1em;
  line-height: 1em;
`;
