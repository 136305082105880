import { Button } from '@shared/components/Button';
import { withPreventDefault } from '@shared/lib/events';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { PrayerHubContext } from '../context/PrayerHubContext';
import { Sheet } from './Sheet';

export const GetStartedButton = (props) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleClick = () => {
    setIsSheetOpen(true);
  };

  const handleCancelClick = () => {
    setIsSheetOpen(false);
  };

  return (
    <>
      <StyledButton onClick={handleClick} {...props}>
        Get Started
      </StyledButton>
      <GetStartedSheet show={isSheetOpen} onCancelClick={handleCancelClick} />
    </>
  );
};

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

StyledButton.defaultProps = {
  size: 'large',
  shape: 'pill',
  variant: 'primary',
};

const GetStartedSheet = (props) => {
  const { goToPrayerFlow } = useContext(PrayerHubContext);
  const handlePrayerRequestClick = withPreventDefault(goToPrayerFlow);

  return (
    <Sheet
      show={props.show}
      onClose={props.onCancelClick}
      footer={<StyledButton onClick={props.onCancelClick}>Close</StyledButton>}
    >
      <Sheet.Button to="/account/sign-in">Get notifications (Sign in)</Sheet.Button>
      <Sheet.Button to="/account/sign-in?redirect">View prayer wall (Sign in)</Sheet.Button>
      <Sheet.Button onClick={handlePrayerRequestClick}>Start a prayer request</Sheet.Button>
    </Sheet>
  );
};
