import { DATE_DAYS, DATE_MONTHS } from '@shared/constants/datetime';
import * as dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { ButtonOptionAlternate } from './ButtonOptionAlternate';
import { convertRemToPixels, font, media } from './style-utils';
import { useDragScrollRef } from './useDragScrollRef';
import { useWindowSize } from './useWindowSize';

export const getClosestValidDate = (originalDate, startDate, endDate, validator) => {
  if (validator(originalDate, startDate, endDate)) {
    return originalDate;
  }

  let newDate = originalDate;
  if (dateFns.isBefore(originalDate, startDate)) {
    newDate = startDate;
  }

  if (dateFns.isAfter(originalDate, endDate)) {
    newDate = endDate;
  }

  const nearbyDates = dateFns.eachDayOfInterval({
    start: dateFns.subDays(newDate, 14),
    end: dateFns.addDays(newDate, 14),
  });

  let closestValidDates = nearbyDates.filter((date) => {
    return dateFns.isSameMonth(date, newDate) && validator(date, startDate, endDate);
  });

  // if no date in same month as chosen date, get any close date
  if (closestValidDates.length === 0) {
    closestValidDates = nearbyDates.filter((date) => validator(date, startDate, endDate));
  }

  const closestValidDate = dateFns.closestTo(newDate, closestValidDates);
  return closestValidDate;
};

export const DatePicker = ({
  value,
  startDate,
  validator = (date, startDate, endDate) => dateFns.isWithinInterval(date, { start: startDate, end: endDate }),
  endDate,
  onChange,
  ...props
}) => {
  const optionsYears = useMemo(() => {
    const yearRange = dateFns.eachYearOfInterval({ start: startDate, end: endDate });
    const currentMonth = value.getMonth();
    const currentDay = value.getDate();

    return yearRange.map((yearDate) => {
      let date = dateFns.setMonth(yearDate, currentMonth);
      date = dateFns.setDate(date, currentDay);
      return dateFns.clamp(date, { start: startDate, end: endDate });
    });
  }, [value, startDate, endDate]);

  const optionsMonths = useMemo(() => {
    let start = dateFns.startOfYear(value);
    start = dateFns.max([startDate, start]);

    let end = dateFns.endOfYear(value);
    end = dateFns.min([endDate, end]);

    const monthRange = dateFns.eachMonthOfInterval({ start: start, end: end });
    const currentDate = value.getDate();

    return monthRange.map((monthDate) => {
      let date = dateFns.min([dateFns.setDate(monthDate, currentDate), dateFns.endOfMonth(monthDate)]);
      return dateFns.clamp(date, { start: startDate, end: endDate });
    });
  }, [value, startDate, endDate]);

  const optionsDays = useMemo(() => {
    let start = dateFns.startOfMonth(value);
    start = dateFns.max([startDate, start]);

    let end = dateFns.endOfMonth(value);
    end = dateFns.min([endDate, end]);

    return dateFns
      .eachDayOfInterval({ start: start, end: end })
      .map((dayDate) => {
        let date = dateFns.setDate(value, dateFns.getDate(dayDate));
        return dateFns.clamp(date, { start: startDate, end: endDate });
      })
      .filter((date) => validator(date, start, end));
  }, [value, startDate, endDate, validator]);

  const handleChange = useCallback(
    (newDate) => onChange(getClosestValidDate(newDate, startDate, endDate, validator)),
    [onChange, validator, endDate, startDate]
  );

  const windowSize = useWindowSize();
  const tabletLandscapeAndUp = windowSize.width > 900;
  const leftOffset = tabletLandscapeAndUp ? convertRemToPixels(3.6) : convertRemToPixels(2.2);

  return (
    <Wrapper className={props.className} style={props.style}>
      <SnapRow>
        <FlexContainer>
          {optionsMonths.map((date) => (
            <OptionMonth
              key={date.getMonth()}
              date={date}
              onChange={handleChange}
              checked={date.getMonth() === value.getMonth()}
              leftOffset={leftOffset}
              currentDate={value}
            />
          ))}
        </FlexContainer>
      </SnapRow>
      <SnapRow>
        <FlexContainer>
          {optionsDays.map((date) => (
            <OptionDay
              key={date.getDate() + DATE_DAYS[dateFns.getDay(date)]}
              date={date}
              onChange={handleChange}
              checked={date.getDate() === value.getDate()}
              leftOffset={leftOffset}
              currentDate={value}
            />
          ))}
        </FlexContainer>
      </SnapRow>
      <SnapRow>
        <FlexContainer>
          {optionsYears.map((date) => (
            <OptionYear
              key={date.getFullYear()}
              date={date}
              onChange={handleChange}
              checked={date.getFullYear() === value.getFullYear()}
              leftOffset={leftOffset}
              currentDate={value}
            />
          ))}
        </FlexContainer>
      </SnapRow>
    </Wrapper>
  );
};

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  validator: PropTypes.func,
};

DatePicker.defaultProps = {
  value: new Date(),
  onChange: () => {},
  startDate: new Date(),
  endDate: dateFns.addYears(new Date(), 1),
};

const Wrapper = styled.div``;

const SnapDiv = styled(animated.div)`
  overflow-x: auto;
  scroll-behavior: ${(props) => (props.isScrolling ? 'auto' : 'smooth')};
  margin: 0;

  /* Hide Scrollbars */
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  * {
    pointer-events: ${(props) => (props.isScrolling ? 'none' : 'auto')};
  }
`;

const ScrollContext = React.createContext({});

const SnapRow = (props) => {
  const [ref, onMouseDown, isScrolling] = useDragScrollRef();

  const [{ scroll }, set, pause] = useSpring(() => ({
    scroll: 0,
    immediate: false,
    reset: true,
    config: {
      friction: 20,
      mass: 1,
      tension: 1000,
      clamp: true,
    },
  }));

  const context = {
    set: set,
    ref: ref,
    pause: pause,
  };

  return (
    <ScrollContext.Provider value={context}>
      <SnapDiv {...props} scrollLeft={scroll} onMouseDown={onMouseDown} isScrolling={isScrolling} ref={ref} />
    </ScrollContext.Provider>
  );
};

const useScrollToRef = (checked, leftOffset = 0, currentDate) => {
  const ref = useRef(null);

  const { set, ref: containerRef } = useContext(ScrollContext);

  useEffect(() => {
    const container = containerRef.current;
    const element = ref.current;

    if (element === null || container === null) {
      return;
    }

    if (checked) {
      const offsetLeft =
        container.scrollLeft + element.getBoundingClientRect().left - container.getBoundingClientRect().left;
      const nextScrollLeft = Math.min(Math.max(offsetLeft - leftOffset, 0), container.scrollWidth);

      set({
        scroll: nextScrollLeft,
        from: { scroll: container.scrollLeft },
      });
    }
  }, [checked, leftOffset, currentDate, containerRef, set]);

  return ref;
};

const FlexContainer = styled.div`
  display: inline-flex;
  padding: 0 2.2rem;

  ${media.tabletLandscapeAndUp`
  padding: 0 3.6rem;
`}
`;

const SnapItem = styled.div`
  margin: 0 0.5rem;
`;

const OptionYear = (props) => {
  const ref = useScrollToRef(props.checked, props.leftOffset, props.currentDate);
  const { date, ...otherProps } = props;

  const year = props.date.getFullYear();

  const handleClick = (e) => {
    e.stopPropagation();
    props.onChange(props.date);
  };

  return (
    <SnapItem ref={ref}>
      <ButtonOptionAlternate style={{ margin: '0.8rem 0', width: '24.5rem' }} onClick={handleClick} {...otherProps}>
        {year}
      </ButtonOptionAlternate>
    </SnapItem>
  );
};

const OptionMonth = (props) => {
  const ref = useScrollToRef(props.checked, props.leftOffset, props.currentDate);
  const { date, ...otherProps } = props;

  const month = DATE_MONTHS[dateFns.getMonth(props.date)];

  const handleClick = (e) => {
    e.stopPropagation();
    props.onChange(props.date);
  };

  return (
    <SnapItem ref={ref}>
      <ButtonOptionAlternate style={{ margin: '0.8rem 0', width: '16.5rem' }} onClick={handleClick} {...otherProps}>
        {month}
      </ButtonOptionAlternate>
    </SnapItem>
  );
};

const OptionDay = (props) => {
  const ref = useScrollToRef(props.checked, props.leftOffset, props.currentDate);
  const { date, ...otherProps } = props;

  const dateNumber = dateFns.getDate(props.date);
  const day = DATE_DAYS[dateFns.getDay(props.date)];

  const handleClick = (e) => {
    e.stopPropagation();
    props.onChange(props.date);
  };

  return (
    <SnapItem ref={ref}>
      <ButtonOptionAlternate style={{ margin: '0.8rem 0', width: '9.3rem' }} onClick={handleClick} {...otherProps}>
        <Stack>
          <StyledDate>{dateNumber}</StyledDate>
          <Day>{day}</Day>
        </Stack>
      </ButtonOptionAlternate>
    </SnapItem>
  );
};

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDate = styled.span`
  ${font(24, 'Medium', -0.5, 24)}
`;

const Day = styled.span`
  ${font(10, 'Medium', -0.25, 10)}
`;
