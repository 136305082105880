import { ButtonOptionPrimary, ButtonOptionSecondary, ButtonPrimary, InputText, LabelStep } from '@foyyay/flow-elements';
import { MEMO_MAX_LENGTH } from '@shared/constants';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import React, { useState } from 'react';
import { FlowStep } from '../components/FlowStep';

export const StepPaymentMemo = (props) => {
  const defaultValue = '';
  const initialValue = props.data?.value ?? defaultValue;

  const message = initialValue !== '' ? 'With a note' : 'Without a note';

  const softLimit = false;

  return (
    <FlowStep label={''} message={message}>
      <Memo
        label={props.config.label}
        limit={MEMO_MAX_LENGTH}
        onComplete={props.completeStep}
        onIncomplete={props.incompleteStep}
        onSave={props.saveStep}
        required={props.config.required}
        softLimit={softLimit}
        suggestedLength={props.config.suggestedLength}
        value={initialValue}
      />
    </FlowStep>
  );
};

const Memo = (props) => {
  const [isMemoEntry, setIsMemoEntry] = useState(false);

  const handleAddANoteClick = (e) => {
    e.stopPropagation();
    setIsMemoEntry(true);
  };

  if (isMemoEntry === true || props.value !== '') {
    return <InputMemo {...props} />;
  }

  return <SelectMemoOption handleAddANoteClick={handleAddANoteClick} {...props} />;
};

const SelectMemoOption = (props) => {
  const handleContinueClick = (e) => {
    e.stopPropagation();
    props.onComplete({ value: '' });
  };

  return (
    <>
      <LabelStep>{props.label}</LabelStep>
      <ButtonOptionSecondary onClick={props.handleAddANoteClick}>Add a note</ButtonOptionSecondary>
      <ButtonOptionPrimary onClick={handleContinueClick}>Continue</ButtonOptionPrimary>
    </>
  );
};

const InputMemo = (props) => {
  const ref = useInputFocusOnceRef();

  const [inputMemo, setInputMemo] = useState(props.value || '');
  const handleChange = (value) => setInputMemo(value);

  const submit = () => props.onComplete({ value: inputMemo.trim() });
  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };
  const handleContinueClick = (e) => {
    e.stopPropagation();
    submit();
  };

  return (
    <>
      <LabelStep>Ok, let's add a note.</LabelStep>
      <form onSubmit={handleSubmit}>
        <InputText
          placeholder="Type note here"
          limit={props.limit}
          softLimit={props.softLimit}
          value={inputMemo}
          onChange={handleChange}
          ref={ref}
        />
      </form>
      <ButtonPrimary onClick={handleContinueClick}>Continue</ButtonPrimary>
    </>
  );
};
