import React, { useState } from 'react';
import { StepPaymentSourceNewAchBank } from './StepPaymentSourceNewAchBank';
import { StepPaymentSourceNewAchPlaid } from './StepPaymentSourceNewAchPlaid';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { SOURCE_TYPE_ACH_DEBIT } from '@shared/constants';

export const StepPaymentSourceNewAch = (props) => {
  const hasManualEntrySource =
    props.data?.value?.[SOURCE_TYPE_ACH_DEBIT] && props.data?.value?.[SOURCE_TYPE_ACH_DEBIT].isPlaid !== true;
  const [useManualEntry, setUseManualEntry] = useState(hasManualEntrySource);
  const handleBankUseManualEntryClick = useClickHandler(() => setUseManualEntry(true), [setUseManualEntry]);
  const handleBackToPlaidClick = useClickHandler(() => setUseManualEntry(false), [setUseManualEntry]);

  if (useManualEntry === true) {
    return <StepPaymentSourceNewAchBank {...props} onBacktoPlaidClick={handleBackToPlaidClick} />;
  }

  return <StepPaymentSourceNewAchPlaid {...props} onBankUseManualEntryClick={handleBankUseManualEntryClick} />;
};
