import { media } from '@foyyay/flow-elements';
import { ScrollContext } from '@shared/context/ScrollContext';
import { useScrollTopReset } from '@shared/hooks/useScrollTop';
import React, { useContext } from 'react';
import styled from 'styled-components';

export const StatusPageLayout = (props) => {
  const { scrollTargetProps } = useContext(ScrollContext);
  useScrollTopReset();

  return (
    <Body>
      <ScrollContainer {...scrollTargetProps}>
        <Content>
          <CenterColumn>
            <Container>{props.children}</Container>
          </CenterColumn>
        </Content>
      </ScrollContainer>
    </Body>
  );
};

const Body = styled.div`
  position: relative;
  flex: 1;
`;

const ScrollContainer = styled.div`
  z-index: 2147483002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
`;

const CenterColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  padding-top: 8rem;
  padding-bottom: 14rem;
`;

const HORIZ_PAD = '1.2rem';

const Container = styled.div`
  width: 100%;
  max-width: calc(49rem + ${HORIZ_PAD} * 2);
  padding-left: ${HORIZ_PAD};
  padding-right: ${HORIZ_PAD};
  min-height: 32vh;

  ${media.tabletLandscapeAndUp`
    max-width: calc(68rem + ${HORIZ_PAD} * 2);
    min-height: 34vh;
  `}
`;
