import { BodyStyle } from '@foyyay/flow-elements';
import { LoadingPage } from '@shared/components/LoadingPage';
import { Manifest } from '@shared/components/Manifest';
import { ThemeContext, usePreferredColorMode } from '@shared/components/ThemeContext';
import { STATE_LOADED } from '@shared/constants/state';
import { MARKETING_SITE_BASE_URL } from '@shared/constants/urls';
import { ControllerContext } from '@shared/context/ControllerContext';
import { useLauncherConfig } from '@shared/hooks/useLauncherConfig';
import { parseSearchParams } from '@shared/lib/parseSearchParams';
import { selectLauncher } from '@shared/reducers/launcher';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Flow } from '../Flow';
import { PrayerHub } from '../PrayerHub';
import { Springboard } from '../Springboard/Springboard';

export const Routes = () => {
  return (
    <Switch>
      <Route
        path="/launcher/:shortcode"
        render={({ match }) => (
          <LauncherRoute key={match.params.shortcode} launcherShortcode={match.params.shortcode} />
        )}
      />
      <Route
        path="/flow/:shortcode/preview"
        exact
        render={({ match, location }) => (
          <Flow
            key={match.params.shortcode}
            shortcode={match.params.shortcode}
            linkParams={parseSearchParams(location.search)}
            revision="latest"
          />
        )}
      />
      <Route
        path="/flow/:shortcode"
        exact
        render={({ match, location }) => (
          <Flow
            key={match.params.shortcode}
            shortcode={match.params.shortcode}
            linkParams={parseSearchParams(location.search)}
            useManifest={true}
          />
        )}
      />
      <Route
        path="/prayer/:shortcode"
        render={({ match }) => (
          <PrayerHub key={match.params.shortcode} shortcode={match.params.shortcode} useManifest={true} />
        )}
      />
      <Route>
        <RedirectToMarketingSite />
      </Route>
    </Switch>
  );
};

const LauncherRoute = (props) => {
  const controllerContext = useContext(ControllerContext);
  const [config, launcherLoaded] = useLauncherConfig(props.launcherShortcode);
  const { theme } = useSelector(selectLauncher);
  const colorMode = usePreferredColorMode(theme.mode);
  const location = useLocation();

  if (STATE_LOADED !== launcherLoaded) {
    return <LoadingPage />;
  }

  const goToFlow = (flowShortcode) =>
    controllerContext.goToLocation(`/launcher/${props.launcherShortcode}/flow/${flowShortcode}`);

  const goToPrayerHub = (prayerhubShortcode) =>
    controllerContext.goToLocation(`/launcher/${props.launcherShortcode}/prayer/${prayerhubShortcode}`);

  const augmentedControllerContext = {
    ...controllerContext,
    goToFlow: goToFlow,
    goToPrayerHub: goToPrayerHub,
    showBackButton: false,
  };

  return (
    <ThemeContext color={theme.color} mode={colorMode} paletteVibrancy={theme.paletteVibrancy}>
      <BodyStyle />
      <Manifest config={config} location={location} />
      <Switch>
        <Route
          path="/launcher/:launcherShortcode/flow/:flowShortcode"
          render={({ match }) => (
            <LauncherFlowRoute
              launcherShortcode={match.params.launcherShortcode}
              flowShortcode={match.params.flowShortcode}
            />
          )}
        />
        <Route
          path="/launcher/:launcherShortcode/prayer/:prayerhubShortcode/flow/:flowShortcode"
          render={({ match }) => (
            <LauncherPrayerhubFlowRoute
              launcherShortcode={match.params.launcherShortcode}
              prayerhubShortcode={match.params.prayerhubShortcode}
              flowShortcode={match.params.flowShortcode}
            />
          )}
        />
        <Route
          path="/launcher/:launcherShortcode/prayer/:prayerhubShortcode"
          render={({ match }) => (
            <LauncherPrayerHubRoute
              launcherShortcode={match.params.launcherShortcode}
              prayerhubShortcode={match.params.prayerhubShortcode}
            />
          )}
        />

        <Route>
          <ControllerContext.Provider value={augmentedControllerContext}>
            <Springboard key={props.launcherShortcode} shortcode={props.launcherShortcode} />
          </ControllerContext.Provider>
        </Route>
      </Switch>
    </ThemeContext>
  );
};

LauncherRoute.propsTypes = {
  launcherShortcode: PropTypes.string.isRequired,
};

const LauncherFlowRoute = (props) => {
  const routeMatch = useRouteMatch();
  const controllerContext = useContext(ControllerContext);

  const goBack = () => controllerContext.goToSpringboard(props.launcherShortcode);

  const augmentedControllerContext = {
    ...controllerContext,
    goToSpringboard: () => controllerContext.goToSpringboard(props.launcherShortcode),
    goBack: goBack,
    showBackButton: true,
  };

  return (
    <Switch>
      <Route exact path={routeMatch.path}>
        <ControllerContext.Provider value={augmentedControllerContext}>
          <Flow key={props.flowShortcode} shortcode={props.flowShortcode} />
        </ControllerContext.Provider>
      </Route>
      <Route>
        <Redirect to={routeMatch.url} />
      </Route>
    </Switch>
  );
};

LauncherFlowRoute.propsTypes = {
  launcherShortcode: PropTypes.string.isRequired,
  flowShortcode: PropTypes.string.isRequired,
};

const LauncherPrayerHubRoute = (props) => {
  const routeMatch = useRouteMatch();
  const controllerContext = useContext(ControllerContext);

  const goBack = () => controllerContext.goToSpringboard(props.launcherShortcode);
  const goToFlow = (flowShortcode) =>
    controllerContext.goToLocation(
      `/launcher/${props.launcherShortcode}/prayer/${props.prayerhubShortcode}/flow/${flowShortcode}`
    );

  const augmentedControllerContext = {
    ...controllerContext,
    goToSpringboard: (state) => controllerContext.goToSpringboard(props.launcherShortcode, state),
    goBack: goBack,
    goToFlow: goToFlow,
    showBackButton: true,
  };

  return (
    <Switch>
      <Route exact path={routeMatch.path}>
        <ControllerContext.Provider value={augmentedControllerContext}>
          <PrayerHub key={props.prayerhubShortcode} shortcode={props.prayerhubShortcode} />
        </ControllerContext.Provider>
      </Route>
      <Route>
        <Redirect to={routeMatch.url} />
      </Route>
    </Switch>
  );
};

LauncherPrayerHubRoute.propsTypes = {
  launcherShortcode: PropTypes.string.isRequired,
  flowShortcode: PropTypes.string.isRequired,
};

const LauncherPrayerhubFlowRoute = (props) => {
  const routeMatch = useRouteMatch();
  const controllerContext = useContext(ControllerContext);

  const goBack = () =>
    controllerContext.goToLocation(`/launcher/${props.launcherShortcode}/prayer/${props.prayerhubShortcode}`);

  const augmentedControllerContext = {
    ...controllerContext,
    goBack: goBack,
    showBackButton: true,
  };

  return (
    <Switch>
      <Route exact path={routeMatch.path}>
        <ControllerContext.Provider value={augmentedControllerContext}>
          <Flow key={props.flowShortcode} shortcode={props.flowShortcode} />
        </ControllerContext.Provider>
      </Route>
      <Route>
        <Redirect to={routeMatch.url} />
      </Route>
    </Switch>
  );
};

LauncherPrayerhubFlowRoute.propsTypes = {
  launcherShortcode: PropTypes.string.isRequired,
  prayerhubShortcode: PropTypes.string.isRequired,
  flowShortcode: PropTypes.string.isRequired,
};

const RedirectToMarketingSite = () => {
  useEffect(() => {
    window.location.href = `${MARKETING_SITE_BASE_URL}/launcher`;
  }, []);

  return null;
};
