export const REDUX_STATE_KEY = '__nucleuslauncher_redux_state';
export const REDUX_STATE_VERSION = 1; // Advance me when required to invalidate bad state
export const REDUX_STATE_EXPIRES_AFTER = 12 * 60 * 60 * 1000; // 12 hours

export const STATE_DEFAULT = 'default';
export const STATE_EDITING = 'editing';
export const STATE_ERROR = 'error';
export const STATE_LOADED = 'loaded';
export const STATE_LOADING = 'loading';
export const STATE_SUCCESS = 'success';

export const STATE_OPENED = 'opened';
export const STATE_CLOSED = 'closed';

export const STATE_AUTHENTICATED = 'authenticated';
export const STATE_UNAUTHENTICATED = 'unauthenticated';

export const STATE_IDLE = 'idle';
export const STATE_PENDING = 'pending';
export const STATE_FULFILLED = 'fulfilled';
export const STATE_REJECTED = 'rejected';

export const STATE_PROCESSING = 'processing';
