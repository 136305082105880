import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { Styles } from './Typography';

const InputF3 = styled(Input)`
  ${Styles.F3};
  color: ${({ theme }) => theme.InputEmail.Color};
  height: 3rem;
`;

export class InputEmail extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    // this.focus();
  }

  focus = () => {
    this.inputRef.current.focus();
  };

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.props.onEnter) {
      this.props.onEnter(e);
    }
  };

  render() {
    const { value, onChange, ...otherProps } = this.props;

    return (
      <InputF3
        type="email"
        placeholder="email@example.com"
        value={value}
        onChange={this.handleChange}
        onKeyPress={this.handleKeyPress}
        ref={this.inputRef}
        {...otherProps}
      />
    );
  }
}
