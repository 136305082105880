import { Select } from '@shared/components/Select';
import { PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE, PROFILE_ATTRIBUTE_ENUM_GENDER_MALE } from '@shared/constants/profile';
import { formatValueWithPrefix } from '@shared/lib/format';
import React, { useMemo } from 'react';
import { FlowStep } from '../components/FlowStep';
import { StepActionButtons } from '../components/StepActionButtons';

const GENDER_LABEL_BY = {
  [PROFILE_ATTRIBUTE_ENUM_GENDER_MALE]: 'Male',
  [PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE]: 'Female',
};

export const StepGender = (props) => {
  const message = useMemo(() => {
    if (props.data?.skipped === true) {
      return `Skipped: "${props.config.label}"`;
    }
    return formatValueWithPrefix(
      props.config.valuePrefixLabel,
      GENDER_LABEL_BY[props.data?.value],
      props.config.valuePrefixSeparator
    );
  }, [props.config.label, props.data, props.config.valuePrefixLabel, props.config.valuePrefixSeparator]);

  return (
    <FlowStep description={props.config.description} label={props.config.label} message={message}>
      <SelectGender required={props.config.required} onComplete={props.completeStep} goBack={props.goBack} />
    </FlowStep>
  );
};

const options = [
  {
    id: 'male',
    label: 'Male',
    value: PROFILE_ATTRIBUTE_ENUM_GENDER_MALE,
  },
  {
    id: 'female',
    label: 'Female',
    value: PROFILE_ATTRIBUTE_ENUM_GENDER_FEMALE,
  },
];

const SelectGender = (props) => {
  const handleChange = (option) => {
    props.onComplete({
      value: option.value,
    });
  };

  const handleSkipClick = () => {
    props.onComplete({
      skipped: true,
      value: undefined,
    });
  };

  return (
    <>
      <Select options={options} onChange={handleChange} />
      <StepActionButtons
        onCancelClick={props.goBack}
        required={props.required}
        onSkipClick={handleSkipClick}
        style={{ marginTop: '3rem' }}
      />
    </>
  );
};
