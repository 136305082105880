// style-utils.js
import { css, keyframes } from 'styled-components';

export const breakpoint = {
  // Height
  midgetScreenOnly: 399,
  midgetScreenAndUp: 400,
  shortScreenOnly: 549,
  shortScreenAndUp: 550,
  // Width
  tinyAndUp: 0,
  tinyOnly: 349,
  smallPhoneAndUp: 350,
  phoneAndUp: 414,
  largePhoneAndUp: 450,
  phoneOnly: 599,
  tabletPortraitAndUp: 600,
  tabletLandscapeAndUp: 800,
  largeTabletAndUp: 950,
  smallDesktopAndUp: 1020,
  desktopAndUp: 1200,
  mediumDesktopAndUp: 1400,
  largeDesktopAndUp: 1800,
  hugeDesktopAndUp: 2400,
};

export const media = {
  midgetScreenOnly: (...args) => css`
    @media (max-height: ${breakpoint.midgetScreenOnly}px) {
      ${css(...args)}
    }
  `,
  midgetScreenAndUp: (...args) => css`
    @media (min-height: ${breakpoint.midgetScreenAndUp}px) {
      ${css(...args)}
    }
  `,
  shortScreenOnly: (...args) => css`
    @media (max-height: ${breakpoint.shortScreenOnly}px) {
      ${css(...args)}
    }
  `,
  shortScreenAndUp: (...args) => css`
    @media (min-height: ${breakpoint.shortScreenAndUp}px) {
      ${css(...args)}
    }
  `,
  tinyAndUp: (...args) => css`
    @media (min-width: ${breakpoint.tinyAndUp}px) {
      ${css(...args)};
    }
  `,
  tinyOnly: (...args) => css`
    @media (max-width: ${breakpoint.tinyOnly}px) {
      ${css(...args)};
    }
  `,
  smallPhoneAndUp: (...args) => css`
    @media (min-width: ${breakpoint.smallPhoneAndUp}px) {
      ${css(...args)};
    }
  `,
  phoneAndUp: (...args) => css`
    @media (min-width: ${breakpoint.phoneAndUp}px) {
      ${css(...args)};
    }
  `,
  largePhoneAndUp: (...args) => css`
    @media (min-width: ${breakpoint.largePhoneAndUp}px) {
      ${css(...args)};
    }
  `,
  phoneOnly: (...args) => css`
    @media (max-width: ${breakpoint.phoneOnly}px) {
      ${css(...args)};
    }
  `,
  tabletPortraitAndUp: (...args) => css`
    @media (min-width: ${breakpoint.tabletPortraitAndUp}px) {
      ${css(...args)};
    }
  `,
  tabletLandscapeAndUp: (...args) => css`
    @media (min-width: ${breakpoint.tabletLandscapeAndUp}px) {
      ${css(...args)};
    }
  `,
  largeTabletAndUp: (...args) => css`
    @media (min-width: ${breakpoint.largeTabletAndUp}px) {
      ${css(...args)};
    }
  `,
  smallDesktopAndUp: (...args) => css`
    @media (min-width: ${breakpoint.smallDesktopAndUp}px) {
      ${css(...args)};
    }
  `,
  desktopAndUp: (...args) => css`
    @media (min-width: ${breakpoint.desktopAndUp}px) {
      ${css(...args)};
    }
  `,
  mediumDesktopAndUp: (...args) => css`
    @media (min-width: ${breakpoint.mediumDesktopAndUp}px) {
      ${css(...args)};
    }
  `,
  largeDesktopAndUp: (...args) => css`
    @media (min-width: ${breakpoint.largeDesktopAndUp}px) {
      ${css(...args)};
    }
  `,
  hugeDesktopAndUp: (...args) => css`
    @media (min-width: ${breakpoint.hugeDesktopAndUp}px) {
      ${css(...args)};
    }
  `,
  print: (...args) => css`
    @media print {
      ${css(...args)};
    }
  `,
};

/**
 * Builds a styled helper function that returns css if truthy prop[key] is truthy
 * @param {*} key [string]
 * @returns [function]
 */
 export const booleanPropHelperFactory = (key) => (rules, fallbackRules) =>
 css`
   ${(props) => {
     if (props[key]) {
       return rules;
     } else {
       return fallbackRules;
     }
   }}
 `;

/**
* Builds a styled helper function that return css if the result of the function argument is truthy
* @param {*} key [string]
* @returns [function]
*/
export const conditionalHelperFactory = (func) => (rules, fallbackRules) =>
 css`
   ${(props) => {
     if (func(props)) {
       return rules;
     } else {
       return fallbackRules;
     }
   }}
 `;

const BASE_FONT_SIZE = 10;
const BASE_SIZE_UNIT = 6;
const SIZE_MULTIPLIERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 18, 20, 22];

const toRem = (value) => value / BASE_FONT_SIZE;
const toEm = (value, fontSize) => value / fontSize;

const sizes = [];
SIZE_MULTIPLIERS.forEach((multiplier) => (sizes[multiplier] = `${toRem(BASE_SIZE_UNIT * multiplier)}rem`));

export const SIZE = Object.freeze(sizes);

export const getCurrentBaseFontSize = (windowWidth) => {
  if (windowWidth >= breakpoint.hugeDesktopAndUp) {
    return '12px';
  }

  if (windowWidth >= breakpoint.largeDesktopAndUp) {
    return '11px';
  }

  if (windowWidth >= breakpoint.tabletLandscapeAndUp) {
    return '10px';
  }

  if (windowWidth >= breakpoint.largePhoneAndUp) {
    return '12px';
  }

  if (windowWidth >= breakpoint.phoneAndUp) {
    return '11px';
  }

  if (windowWidth >= breakpoint.smallPhoneAndUp) {
    return '10px';
  }

  return '9px';
};

export const font = (fontSize, fontWeight, letterSpacing, lineHeight) => css`
  font-size: ${fontSize ? toRem(fontSize) + 'rem' : '1em'};
  font-weight: ${fontWeight ? WEIGHTS[fontWeight] : undefined};
  letter-spacing: ${letterSpacing ? toEm(letterSpacing, fontSize) + 'em' : undefined};
  line-height: ${lineHeight ? toEm(lineHeight, fontSize) + 'em' : undefined};
`;

export const convertRemToPixels = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

const WEIGHTS = {
  'Extra Light': 100,
  'Ultra Light': 100,
  Light: 200,
  Thin: 200,
  Book: 300,
  Demi: 300,
  Normal: 400,
  Regular: 400,
  Medium: 500,
  Semibold: 600,
  Demibold: 600,
  Bold: 700,
  Black: 800,
  'Extra Bold': 800,
  Heavy: 800,
  'Extra Black': 900,
  Fat: 900,
  Poster: 900,
  'Ultra Black': 900,
};

const ORDERED_COLOR_CLASSES = ['Default', 'Link', 'Visited', 'Hover', 'Focus', 'Active', 'Error', 'Disabled'];
export const colorStyles = (ColorObj) => {
  if (ColorObj === undefined) {
    return;
  }
  return ORDERED_COLOR_CLASSES.map((key) => {
    if (ColorObj[key] === undefined) {
      return undefined;
    }
    let begin = ':' + key.toLowerCase() + '{';
    let end = '}';

    if (key === 'Default') {
      begin = '';
      end = '';
    }

    if (key === 'Active' || key === 'Hover') {
      begin = ':' + key.toLowerCase() + ':not([disabled]) {';
    }

    return css`
      ${begin}
      color: ${ColorObj[key].Foreground};
      background-color: ${ColorObj[key].Background};
      ${end}
    `;
  }).filter((e) => e !== undefined);
};

// https://easings.net/

export const easings = {
  linear: undefined,
  easeInSine: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
  easeOutSine: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
  easeInOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  easeInQuad: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
  easeOutQuad: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  easeInOutQuad: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
  easeInCubic: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
  easeOutCubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  easeInQuart: 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
  easeOutQuart: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  easeInOutQuart: 'cubic-bezier(0.77, 0, 0.175, 1)',
  easeInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
  easeOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
  easeInOutQuint: 'cubic-bezier(0.86, 0, 0.07, 1)',
  easeInExpo: 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
  easeOutExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
  easeInOutExpo: 'cubic-bezier(1, 0, 0, 1)',
  easeInCirc: 'cubic-bezier(0.6, 0.04, 0.98, 0.335)',
  easeOutCirc: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
  easeInOutCirc: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
  easeInBack: 'cubic-bezier(0.6, -0.28, 0.735, 0.045)',
  easeOutBack: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  easeInOutBack: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
  easeInElastic: undefined,
  easeOutElastic: undefined,
  easeInOutElastic: undefined,
  easeInBounce: undefined,
  easeOutBounce: undefined,
  easeInOutBounce: undefined,
};

export const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const BUTTON_TRANSITIONS = {
  default: '0.1s cubic-bezier(0, 0, 1, 1)',
  hover: '0s cubic-bezier(0, 0, 1, 1)',
  active: '0s cubic-bezier(0, 0, 1, 1)',
};

function generateButtonTransition(properties, transitionType) {
  return properties.map((property) => `${property} ${BUTTON_TRANSITIONS[transitionType]}`).join(', ');
}

export function buttonTransitions(properties = ['all']) {
  const defaultTransition = generateButtonTransition(properties, 'default');
  const hoverTransition = generateButtonTransition(properties, 'hover');
  const activeTransition = generateButtonTransition(properties, 'active');
  return css`
    transition: ${defaultTransition};

    @media (hover: hover) {
      :hover {
        transition: ${hoverTransition};
      }
    }

    :active:not([disabled]) {
      transition: ${activeTransition};
    }
  `;
}
