import React from 'react';
import Fonts from './fonts';
import Html from './html';
import Main from './main';
import Normalize from './normalize';

const CSS = (props) => (
  <>
    <Normalize />
    <Fonts />
    <Main />
    <Html />
  </>
);

export default CSS;
