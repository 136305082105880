import { createReducer, createSelector } from '@reduxjs/toolkit';
import {
  clearAllFlows,
  clearFlow,
  completeStep,
  fetchFlowConfig,
  getPostSteps,
  goToNextStep,
  goToStep,
  hideStep,
  saveStepData,
  sendFlowSubmission,
  setStepError,
  setStepIncomplete,
  showStep,
  submitFlow,
} from '@shared/actions/flows';
import { userSignedOut } from '@shared/actions/user';
import { INTERVAL, MAP_STEP_TYPE_TO_VALIDATOR, SOURCE_TYPES_ONLINE, STEP_TYPE } from '@shared/constants';
import { FLOW_TYPE_ACCESS_REQUEST, FLOW_TYPE_CUSTOM, FLOW_TYPE_GIVING } from '@shared/constants/flow';
import { STATE_ERROR, STATE_IDLE, STATE_PROCESSING } from '@shared/constants/state';
import { dateToIsoString, isoStringToDate } from '@shared/lib/datetime';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { buildConfigValidator } from '@shared/lib/schema';
import { calculateSharedData } from '@shared/reducers/flows/calculateSharedData';
import * as dateFns from 'date-fns';
import { pick as _pick, sortBy as _sortBy, uniq as _uniq } from 'lodash';
import * as yup from 'yup';
import { getStep } from '../../../Flow/steps/index';
import { selectIsAuthenticated } from '../user';

export const selectAllFlows = (state) => state.flows.byId;

export const selectAllFlowsShortcodes = createSelector([selectAllFlows], (flowsById) => {
  return Object.keys(flowsById).map((id) => flowsById[id].config.shortcode);
});

export const selectAllInProgressFlowsShortcodes = createSelector([selectAllFlows], (flowsById) => {
  return Object.keys(flowsById)
    .filter((id) => flowsById[id].completedStepIds.length > 0)
    .map((id) => flowsById[id].config.shortcode);
});

export const selectFlowById = (state, id) => state.flows.byId[id];

export const selectFlowByShortcode = (state, flowShortcode) => {
  return Object.values(state.flows.byId).find((flow) => flow.config?.shortcode === flowShortcode);
};

export const selectFlowConfigById = (state, id) => selectFlowById(state, id)?.config;
export const selectFlowConfigByShortcode = (state, flowShortcode) =>
  selectFlowByShortcode(state, flowShortcode)?.config;
export const selectFlowConfigLoadedAtById = (state, flowId) => {
  return selectFlowById(state, flowId)?.configLoadedAt;
};
export const selectFlowConfigLoadedAtByShortcode = (state, flowShortcode) => {
  return selectFlowByShortcode(state, flowShortcode)?.configLoadedAt;
};

export const selectStepsByFlowId = (state, flowId) => selectFlowConfigById(state, flowId)?.steps ?? [];
export const selectCurrentStepByFlowId = (state, flowId) => selectFlowById(state, flowId)?.currentStepId;
export const selectCompletedStepsByFlowId = (state, flowId) => selectFlowById(state, flowId)?.completedStepIds ?? [];
export const selectHiddenStepsByFlowId = (state, flowId) => selectFlowById(state, flowId)?.hiddenStepIds ?? [];
export const selectStepByFlowIdAndStepType = (state, flowId, stepType) =>
  selectComputedSteps(state, flowId)?.find((step) => step.type === stepType) ?? {};

export const selectDataByFlowId = (state, flowId) => selectFlowById(state, flowId)?.data;
export const selectDataByFlowIdAndStepId = (state, flowId, stepId) => selectDataByFlowId(state, flowId)?.[stepId] ?? {};
export const selectErrorsByFlowId = (state, flowId) => selectFlowById(state, flowId)?.errors ?? [];

export const selectDesignationsByFlowId = (state, flowId) => selectFlowConfigById(state, flowId)?.designations ?? [];
export const selectMerchantByFlowId = (state, flowId) => selectFlowConfigById(state, flowId)?.merchant ?? {};
export const selectSourceTypesByFlowId = (state, flowId) => selectMerchantByFlowId(state, flowId)?.source_types ?? [];

// export const selectSharedDataByFlowId = createSelector([selectFlowById, selectStepsByFlowId, selectDataByFlowId], (flow, steps, data) => {
//   return calculateSharedData(flow, steps, data);
// });

export const selectSharedDataByFlowId = (state, flowId) => {
  const flow = selectFlowById(state, flowId);
  const steps = selectStepsByFlowId(state, flowId);
  const data = selectDataByFlowId(state, flowId);
  return calculateSharedData(flow, steps, data);
};

const isoStringDateIsToday = (date) => {
  return date !== undefined && date === dateToIsoString(new Date());
};

export const selectSubmitButtonTextByFlowId = (state, flowId) => {
  const sharedData = selectSharedDataByFlowId(state, flowId);
  const config = selectFlowConfigById(state, flowId);
  if (config.flow_type === FLOW_TYPE_ACCESS_REQUEST) {
    return 'Submit Request';
  }
  if (config.flow_type === FLOW_TYPE_GIVING) {
    if (isoStringDateIsToday(sharedData.startDate)) {
      return 'Give Now';
    }
    return 'Schedule Gift';
  }
  if (sharedData.amount !== undefined && SOURCE_TYPES_ONLINE.includes(sharedData.paymentType?.id)) {
    return 'Submit & Pay Now';
  }
  return 'Submit';
};

const defaultSchema = {
  description: yup.string(),
  label: yup.string().min(3).required(),
  required: yup.mixed().oneOf([true, false, 'conditional']).required(),
};

const defaultConfigValidator = buildConfigValidator(defaultSchema);

export const validateStep = (step) => (getStep(step)?.isConfigValid ?? defaultConfigValidator)(step.config);

const COMPUTE_STEPS_BY = {
  [STEP_TYPE.SMART_CONTACT]: (step, args) => {
    if (!args?.isAuthenticated) {
      return step.config.steps;
    }

    const profileStep = {
      ...step,
      type: STEP_TYPE.PROFILE,
    };

    return [profileStep, ...step.config.steps];
  },
  [STEP_TYPE.ADDITIONAL_CONTACT]: (step, args) => {
    const contactStep = {
      ...step,
      type: STEP_TYPE.CONTACT,
    };

    if (step.config.allowMultiple === true) {
      return [contactStep];
    }

    const validSteps = step.config.steps?.filter((step) => validateStep(step) === true);

    return [contactStep, ...validSteps];
  },
  [STEP_TYPE.PAYMENT]: (step, args) => {
    const paymentSteps = [
      {
        ...step.config.steps[0],
        parentId: step.id, // needed for conditional logic to find the original payment step
      },
      ...step.config.steps.slice(1, -1),
      {
        ...step.config.steps[step.config.steps.length - 1],
        sortPriority: 1, // moves the summary step to the end of the flow
      },
    ];

    return paymentSteps;
  },
  default: (step) => {
    if (step.config.active === false) {
      return;
    }

    let computedStep = step;
    let result = computedStep;
    let preStep;
    let postSteps = [];

    // check if step is conditionally required
    if (computedStep.config.required === 'conditional') {
      preStep = buildConditionalPreStep(computedStep);
      if (preStep === undefined) {
        // if conditional config is not valid,
        // fallback to set main step to required
        computedStep = {
          ...computedStep,
          config: {
            ...computedStep.config,
            required: false,
          },
        };
      }
      // set main step to required
      computedStep = {
        ...computedStep,
        config: {
          ...computedStep.config,
          required: true,
        },
      };

      // apply main step to result
      result = [computedStep];
    }

    // check if step has post steps
    if (step.alreadyComputedPostSteps !== true) {
      postSteps = getPostSteps(step);
    }

    // apply post steps to result
    if (postSteps?.length > 0) {
      computedStep = { ...computedStep, alreadyComputedPostSteps: true };
      result = [computedStep, ...postSteps];
    }

    // apply pre step to result
    if (preStep !== undefined && Array.isArray(result)) {
      result = [preStep, ...result];
    }

    return result;
  },
};

const computeStep = (step, args) => (COMPUTE_STEPS_BY[step.type] ?? COMPUTE_STEPS_BY.default)(step, args);

const computeSteps = (steps, args) => {
  return _sortBy(
    steps.reduce((acc, step) => {
      if (validateStep(step) !== true) {
        console.warn(`Warning: Step ${step.type} is invalid and will be ignored.`);
        return [...acc];
      }

      const next = computeStep(step, args);

      if (next === undefined) {
        return acc;
      }

      if (!Array.isArray(next)) {
        return [...acc, next];
      }

      return [...acc, ...computeSteps(next, args)];
    }, []),
    (step) => step.sortPriority ?? 0
  );
};

export const selectComputedSteps = createSelector(
  [selectStepsByFlowId, selectIsAuthenticated],
  (steps, isAuthenticated) => {
    const computedSteps = computeSteps(steps, { isAuthenticated: isAuthenticated });
    return computedSteps;
  }
);

export const selectVisibleStepsByFlowId = createSelector(
  [selectComputedSteps, selectCurrentStepByFlowId, selectCompletedStepsByFlowId, selectHiddenStepsByFlowId],
  (computedSteps, currentStepId, completedStepIds, hiddenStepIds) => {
    return computedSteps.filter((step) => {
      const isCurrentStep = currentStepId === step.id;
      const isCompleted = completedStepIds.includes(step.id);
      const isNotHidden = hiddenStepIds.includes(step.id) === false;
      return (isCurrentStep || isCompleted) && isNotHidden;
    });
  }
);

export const selectProgressByFlowId = createSelector(
  [selectComputedSteps, selectCurrentStepByFlowId, selectCompletedStepsByFlowId],
  (computedSteps, currentStepId, completedStepIds) => {
    const visibleCompletedSteps = computedSteps.filter((step) => completedStepIds.includes(step.id));

    const value = visibleCompletedSteps.length;
    const max = computedSteps.length;

    const allStepsAreCompleted = value === max;
    const aStepIsOpen = currentStepId !== undefined;

    if (allStepsAreCompleted && aStepIsOpen) {
      return [value - 1, max];
    }

    return [value, max];
  }
);

export const selectSubmissionData = createSelector([selectComputedSteps, selectDataByFlowId], (steps, data) => {
  return steps.map((step) => {
    return {
      data: data[step.id],
      step_id: step.id,
      step_type: step.type,
    };
  });
});

const calculateTotal = (totalData) => {
  return totalData.amount + totalData.processorFee + totalData.processorExtra;
};

const dateWithCurrentTime = (date) => {
  const now = new Date();
  date = dateFns.setHours(date, now.getHours());
  date = dateFns.setMinutes(date, now.getMinutes());
  date = dateFns.setSeconds(date, now.getSeconds());
  return dateFns.setMilliseconds(date, now.getMilliseconds());
};

const buildConditionalPreStep = (step) => {
  // Validate conditional config
  if (step.config.conditionalConfig === undefined || step.config.conditionalConfig.label === undefined) {
    return undefined;
  }
  return {
    id: step.id + '_pre',
    type: STEP_TYPE.CONDITIONAL_PRESTEP,
    config: {
      allowCustom: false,
      allowMultiple: false,
      allowQuantity: false,
      conditionalStepId: step.parentId ?? step.id,
      description: step.config.conditionalConfig.description,
      label: step.config.conditionalConfig.label ?? '',
      options: [
        {
          label: step.config.conditionalConfig.labelYes ?? 'Yes',
          value: 'true',
          id: '1',
        },
        {
          label: step.config.conditionalConfig.labelNo ?? 'No',
          value: 'false',
          id: '2',
        },
      ],
      required: true,
      valuePrefixLabel: step.config.conditionalConfig.valuePrefixLabel ?? '',
    },
  };
};

const TRANSACTION_DATA_BY = {
  [FLOW_TYPE_GIVING]: (flowConfig, sharedData) => {
    const startDate = dateWithCurrentTime(isoStringToDate(sharedData.startDate));
    const startDateIsToday = isoStringDateIsToday(sharedData.startDate);
    // Base transaction
    const transactionData = {
      merchant: {
        id: flowConfig.merchant?.id,
      },
      amount: calculateTotal(sharedData.givingSummary),
      designation: sharedData.designation,
      paymentId: sharedData.paymentSource?.id ?? sharedData?.paymentToken?.id,
      memo: sharedData.memo,
    };
    // One-time scheduled transaction
    if (sharedData.interval.intervalType === INTERVAL.NONE && !startDateIsToday) {
      transactionData.intervalType = INTERVAL.DATES;
      transactionData.intervalDates = [startDate.getDate()];
      transactionData.startDate = startDate.getTime();
      transactionData.maxCharges = 1;
    }
    // Recurring scheduled transaction
    if (sharedData.interval.intervalType !== INTERVAL.NONE) {
      transactionData.intervalType = sharedData.interval.intervalType;
      transactionData.intervalDates = sharedData.interval?.intervalDates;
      transactionData.intervalCount = sharedData.interval?.intervalCount;
      transactionData.intervalNow = startDateIsToday;
      transactionData.startDate = startDateIsToday ? undefined : startDate.getTime();
    }
    return transactionData;
  },
  [FLOW_TYPE_CUSTOM]: (flowConfig, sharedData) => {
    // if no payment amount, return undefined
    if (sharedData.amount === undefined) {
      return undefined;
    }

    const transactionData = {
      merchant: {
        id: flowConfig.merchant?.id,
      },
      amount: calculateTotal(sharedData.paymentSummary),
      paymentId: sharedData.paymentSource?.id ?? sharedData?.paymentToken?.id,
    };
    return transactionData;
  },
  default: (flowConfig, sharedData) => undefined,
};

export const selectTransactionData = createSelector(
  [selectFlowConfigById, selectSharedDataByFlowId],
  (flowConfig, sharedData) =>
    (TRANSACTION_DATA_BY[flowConfig.flow_type] ?? TRANSACTION_DATA_BY.default)(flowConfig, sharedData)
);

export const selectSubmissionByFlowId = (state, flowId) => selectFlowById(state, flowId)?.submission;

export const selectIsSubmittingByFlowId = (state, flowId) =>
  selectFlowById(state, flowId)?.async.submission.state === STATE_PROCESSING;

const initialState = deepFreeze({});

const initialFlowState = deepFreeze({
  async: {
    submission: {
      state: STATE_IDLE,
      error: undefined,
    },
  },
  completedStepIds: [],
  config: {},
  configLoadedAt: undefined,
  currentStepId: undefined,
  data: {},
  errors: [],
  hiddenStepIds: [],
  submission: undefined,
});

export const flowsByIdReducer = createReducer(initialState, {
  [clearAllFlows]: (state, action) => initialState,
  [clearFlow]: (state, action) => ({
    ...state,
    [action.payload.flow.config.id]: {
      ...initialFlowState,
      ..._pick(state[action.payload.flow.config.id], ['config', 'submission', 'configLoadedAt', 'isPreview']),
    },
  }),
  [fetchFlowConfig.fulfilled]: (state, action) => {
    const previousRevisionKey = state[action.payload.config.id]?.config?.revision_key;
    const dataNeedsReset =
      previousRevisionKey !== undefined && previousRevisionKey !== action.payload.config.revision_key;
    return {
      ...state,
      [action.payload.config.id]: {
        ...initialFlowState,
        ...state[action.payload.config.id],
        config: action.payload.config,
        configLoadedAt: new Date(),
        dataNeedsReset: dataNeedsReset,
        isPreview: action.payload.isPreview,
      },
    };
  },
  [submitFlow.pending]: (state, action) => ({
    ...state,
    [action.meta.arg.flow.config.id]: {
      ...state[action.meta.arg.flow.config.id],
      async: {
        ...state[action.meta.arg.flow.config.id].async,
        submission: {
          state: STATE_PROCESSING,
          error: undefined,
        },
      },
    },
  }),
  [submitFlow.fulfilled]: (state, action) => ({
    ...state,
    [action.meta.arg.flow.config.id]: {
      ...state[action.meta.arg.flow.config.id],
      async: {
        ...state[action.meta.arg.flow.config.id].async,
        submission: {
          state: STATE_IDLE,
          error: undefined,
        },
      },
    },
  }),
  [submitFlow.rejected]: (state, action) => ({
    ...state,
    [action.meta.arg.flow.config.id]: {
      ...state[action.meta.arg.flow.config.id],
      async: {
        ...state[action.meta.arg.flow.config.id].async,
        submission: {
          state: STATE_ERROR,
          error: action.error,
        },
      },
    },
  }),
  [sendFlowSubmission.fulfilled]: (state, action) => {
    const flowId = action.meta.arg.config.id;

    const next = {
      submission: action.payload.submission,
    };

    if (action.payload.errors) {
      next.errors = [...action.payload.errors];
    }

    if (action.payload.config !== undefined) {
      next.config = action.payload.config;
      next.configLoadedAt = new Date();
    }

    return {
      ...state,
      [flowId]: {
        ...state[flowId],
        ...next,
      },
    };
  },
  [sendFlowSubmission.rejected]: (state, action) => {
    const flowId = action.meta.arg.config.id;

    const next = {};

    if (action.payload.errors) {
      next.errors = [...action.payload.errors];
    }

    if (action.payload.config !== undefined) {
      next.config = action.payload.config;
      next.configLoadedAt = new Date();
    }

    return {
      ...state,
      [flowId]: {
        ...state[flowId],
        ...next,
      },
    };
  },
  [goToNextStep]: (state, action) => {
    let completedStepIds = [...state[action.payload.flow.config.id].completedStepIds];
    let nextStep;

    const flow = state[action.payload.flow.config.id];
    const flowData = flow?.data ?? {};
    const sharedData = calculateSharedData(flow, flow.config.steps ?? [], flowData);
    for (const step of action.payload.steps) {
      const validator = MAP_STEP_TYPE_TO_VALIDATOR[step.type];
      const stepData = flowData[step.id] ?? {};

      // Some steps have validators, that return a boolean
      if (typeof validator === 'function') {
        if (validator(stepData, sharedData, step, flow) !== true) {
          completedStepIds = completedStepIds.filter((completedStep) => completedStep !== step.id);
          nextStep = step.id;
          break;
        }
      } else {
        // Not all steps have validators, if it isn't already completed, thats the next step
        if (flow.completedStepIds.includes(step.id) === false) {
          nextStep = step.id;
          break;
        }
      }

      // If we made it this far, the current step was already completed, next loop
      completedStepIds = _uniq([...completedStepIds, step.id]);
    }

    return {
      ...state,
      [action.payload.flow.config.id]: {
        ...flow,
        completedStepIds: completedStepIds,
        currentStepId: nextStep,
      },
    };
  },
  [goToStep]: (state, action) => {
    return {
      ...state,
      [action.payload.flow.config.id]: {
        ...state[action.payload.flow.config.id],
        currentStepId: action.payload.step.id,
      },
    };
  },
  [saveStepData]: (state, action) => {
    return {
      ...state,
      [action.payload.flow.config.id]: {
        ...state[action.payload.flow.config.id],
        data: {
          ...state[action.payload.flow.config.id].data,
          [action.payload.step.id]: action.payload.data,
        },
      },
    };
  },
  [completeStep]: (state, action) => ({
    ...state,
    [action.payload.flow.config.id]: {
      ...state[action.payload.flow.config.id],
      completedStepIds: _uniq([...state[action.payload.flow.config.id].completedStepIds, action.payload.step.id]),
      errors: state[action.payload.flow.config.id]?.errors?.filter((error) => error.id !== action.payload.step.id),
    },
  }),
  [setStepIncomplete]: (state, action) => ({
    ...state,
    [action.payload.flow.config.id]: {
      ...state[action.payload.flow.config.id],
      completedStepIds: state[action.payload.flow.config.id].completedStepIds.filter(
        (stepId) => stepId !== action.payload.step.id
      ),
    },
  }),
  [showStep]: (state, action) => ({
    ...state,
    [action.payload.flow.config.id]: {
      ...state[action.payload.flow.config.id],
      hiddenStepIds: state[action.payload.flow.config.id].hiddenStepIds.filter(
        (stepId) => stepId !== action.payload.step.id
      ),
    },
  }),
  [hideStep]: (state, action) => ({
    ...state,
    [action.payload.flow.config.id]: {
      ...state[action.payload.flow.config.id],
      hiddenStepIds: _uniq([...state[action.payload.flow.config.id].hiddenStepIds, action.payload.step.id]),
    },
  }),
  [setStepError]: (state, action) => ({
    ...state,
    [action.payload.flow.config.id]: {
      ...state[action.payload.flow.config.id],
      errors: [
        ...(state[action.payload.flow.config.id]?.errors?.filter((error) => error.id !== action.payload.step.id) ?? {}),
        action.payload.error,
      ],
    },
  }),
  [userSignedOut]: (state, action) => {
    return Object.keys(state).reduce((acc, flowId) => {
      return {
        ...acc,
        [flowId]: {
          ...state[flowId],
          ...clearFlowUserData(state[flowId]),
        },
      };
    }, {});
  },
});

const STEPS_TO_CLEAR_ON_SIGNOUT = [
  STEP_TYPE.SMART_PROFILE,
  STEP_TYPE.SMART_NAME,
  STEP_TYPE.SMART_EMAIL_ADDRESS,
  STEP_TYPE.SMART_PHONE,
  STEP_TYPE.SMART_PHYSICAL_ADDRESS,
  STEP_TYPE.PAYMENT_TYPE,
  STEP_TYPE.PAYMENT_INFO,
];

const clearFlowUserData = (flow) => {
  const steps = flow?.config?.steps;

  if (steps === undefined) {
    // Possible that we have loaded a flow from localStorage but haven't read it's config yet
    return flow;
  }

  const computedSteps = computeSteps(flow.config.steps, { isAuthenticated: false });

  let data = { ...flow.data };
  let completedStepIds = [...flow.completedStepIds];
  let hiddenStepIds = [...flow.hiddenStepIds];

  computedSteps.forEach((step) => {
    if (STEPS_TO_CLEAR_ON_SIGNOUT.includes(step.type)) {
      data = Object.keys(data)
        .filter((stepId) => stepId !== step.id)
        .reduce((acc, stepId) => {
          return {
            ...acc,
            [stepId]: data[stepId],
          };
        }, {});
      completedStepIds = completedStepIds.filter((stepId) => stepId !== step.id);
      hiddenStepIds = hiddenStepIds.filter((stepId) => stepId !== step.id);
    }
  });

  return {
    completedStepIds: completedStepIds,
    data: data,
    hiddenStepIds: hiddenStepIds,
  };
};

const PERSISTENT_KEYS = ['completedStepIds', 'currentStepId', 'config', 'data', 'hiddenStepIds'];

flowsByIdReducer.getPersistentState = (state) => {
  const persistent = {};
  Object.keys(state).forEach((key) => {
    persistent[key] = _pick(state[key], PERSISTENT_KEYS);
  });
  return persistent;
};
