import { ButtonGroup, ButtonGroupCircleX } from '@foyyay/flow-elements';
import PropTypes from 'prop-types';
import React from 'react';
import { ContinueButton, SkipButton } from './StepActionButtons';

export const DateTimeStepActionButtons = React.forwardRef((props, ref) => {
  const skippable = props.required === false;
  const continueEnabled = props.readyToContinue !== false;
  const renderBackButton = props.renderBackButton === true && typeof props.onClickCancel === 'function';

  return (
    <>
      <ButtonGroup>
        {renderBackButton && <ButtonGroupCircleX onClick={props.onClickCancel} />}
        <ContinueButton
          style={props.style}
          size={props.size}
          className={props.className}
          disabled={!continueEnabled}
          onClick={props.onClick}
          ref={ref}
        >
          {props.children}
        </ContinueButton>
      </ButtonGroup>
      {skippable && (
        <SkipButton
          style={props.style}
          size={props.size}
          className={props.className}
          onClick={props.onClickSkip}
          ref={ref}
        />
      )}
    </>
  );
});

DateTimeStepActionButtons.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  onClickSkip: PropTypes.func,
  onClickCancel: PropTypes.func,
  readyToContinue: PropTypes.bool,
  required: PropTypes.bool,
  renderBackButton: PropTypes.bool,
};
