import { LoadingSpinner, Theme } from '@foyyay/flow-elements';
import {
  clearFlow,
  goToStep,
  initFlow,
  saveAndCompleteStep,
  saveStepData,
  setStepIncomplete,
  submitFlow,
} from '@shared/actions/flows';
import { FlowLayout } from '@shared/components/Layout';
import { RenderBlocker } from '@shared/components/RenderBlocker';
import { StatusPage } from '@shared/components/StatusPage/StatusPage';
import { usePreferredColorMode } from '@shared/components/ThemeContext';
import { MAP_STEP_TYPE_TO_VALUE_PREFIX_LABEL_FALLBACK } from '@shared/constants';
import { COLOR_MODE_DARK } from '@shared/constants/color';
import { ControllerContext } from '@shared/context/ControllerContext';
import { getParentWindow } from '@shared/lib/getParentWindow';
import {
  selectCompletedStepsByFlowId,
  selectCurrentStepByFlowId,
  selectDataByFlowId,
  selectErrorsByFlowId,
  selectFlowById,
  selectFlowConfigById,
  selectFlowConfigLoadedAtById,
  selectIsSubmittingByFlowId,
  selectProgressByFlowId,
  selectSharedDataByFlowId,
  selectVisibleStepsByFlowId,
} from '@shared/reducers/flows/flowsByIdReducer';
import { selectIsAuthenticated } from '@shared/reducers/user';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlowStep } from '../../components/FlowStep';
import { FooterFlow } from '../../components/FooterFlow';
import { FlowContext } from '../../index';
import { getStep } from '../../steps/';

export function Flow(props) {
  const { currentFlowId } = useContext(FlowContext);
  return (
    <RenderBlocker require={[currentFlowId]} fallback={<StatusPageError />}>
      <FlowContent />
    </RenderBlocker>
  );
}

const StatusPageError = (props) => {
  const { reloadFlow } = useContext(FlowContext);
  const config = {
    status: 'error',
    statusPage: {
      headline: 'Whoops, there was an error loading this Flow.',
      body: 'Please try reloading the page.',
      actions: [
        {
          label: 'Reload Now',
          priority: 'primary',
          type: 'button',
          value: (e) => {
            e.preventDefault();
            reloadFlow();
          },
        },
      ],
    },
  };
  return <StatusPage status={config.status} {...config.statusPage} />;
};

const FlowContent = (props) => {
  const { currentFlowId } = useContext(FlowContext);
  const dispatch = useDispatch();
  const [footerShown, setFooterShown] = useState(true);

  const flow = useSelector((state) => selectFlowById(state, currentFlowId));
  const steps = useSelector((state) => selectVisibleStepsByFlowId(state, currentFlowId));
  const config = useSelector((state) => selectFlowConfigById(state, currentFlowId));
  const configLoadedAt = useSelector((state) => selectFlowConfigLoadedAtById(state, currentFlowId));

  const { isFullscreen, overrideLogoUrl } = useContext(ControllerContext);
  const colorMode = usePreferredColorMode(useContext(Theme).mode);

  useEffect(() => {
    dispatch(initFlow(flow));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configLoadedAt]);

  useEffect(() => {
    const window = getParentWindow();
    const onScroll = () => setFooterShown(isFullscreen !== true || window.scrollY === 0);
    window.document.addEventListener('scroll', onScroll);
    return () => window.document.removeEventListener('scroll', onScroll);
  }, [isFullscreen]);

  if (configLoadedAt === undefined) {
    return <LoadingSpinner />;
  }

  if (flow.dataNeedsReset === true) {
    dispatch(clearFlow(flow));
  }

  if (config.statusPage !== undefined) {
    return <StatusPage status={config.status} {...config.statusPage} />;
  }

  const logoFileUrl = getLogoUrl(config, colorMode, overrideLogoUrl);

  return (
    <>
      <FlowLayout
        contentAlignV="center"
        contentPadding={'2.2rem 0 20rem'}
        logoFileUrl={logoFileUrl}
        title={config.title}
        showTitle={config.show_title}
      >
        <div>
          {steps.map((step, index) => (
            <FlowStepController key={step.id} flow={flow} step={step} index={index} length={steps.length} />
          ))}
        </div>
      </FlowLayout>
      <Footer show={footerShown} />
    </>
  );
};

const FlowStepController = (props) => {
  const { currentFlowId } = useContext(FlowContext);
  const dispatch = useDispatch();

  const completedStepIds = useSelector((state) => selectCompletedStepsByFlowId(state, currentFlowId));
  const data = useSelector((state) => selectDataByFlowId(state, currentFlowId));
  const errors = useSelector((state) => selectErrorsByFlowId(state, currentFlowId));
  const sharedData = useSelector((state) => selectSharedDataByFlowId(state, currentFlowId));
  const currentStepId = useSelector((state) => selectCurrentStepByFlowId(state, currentFlowId));
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const isCurrentStep = props.step.id === currentStepId;
  const isCompleted = completedStepIds.includes(props.step.id);
  const isFirstStep = props.index === 0;
  const scrollToStep = isCurrentStep && (isCompleted === true || isFirstStep === false);

  const isConfigValuePrefixLabelValid =
    props.step.config?.valuePrefixLabel !== undefined && props.step.config?.valuePrefixLabel.trim() !== '';
  const valuePrefixLabel = isConfigValuePrefixLabelValid
    ? props.step.config.valuePrefixLabel
    : MAP_STEP_TYPE_TO_VALUE_PREFIX_LABEL_FALLBACK[props.step.type];

  const stepConfig = {
    ...props.step.config,
    valuePrefixLabel: valuePrefixLabel,
  };

  const context = {
    description: props.step?.config?.description,
    error: errors.find((error) => error.id === props.step.id),
    expanded: isCurrentStep === true,
    label: props.step?.config?.label,
    labelPosition: props.index === 0 ? 'outside' : 'inside',
    onClick: () => dispatch(goToStep(props.flow, props.step)),
    scrollToStep: scrollToStep,
  };

  const hasParentOption = props.step.config?.parentOptionId !== undefined;

  if (hasParentOption === true) {
    context.parentOptionLabel = getParentOptionLabel(props.step.config, props.flow);
  }

  return (
    <FlowStep.Context.Provider value={context}>
      {React.createElement(getStep(props.step), {
        key: props.step.id,
        id: props.step.id,
        type: props.step.type,
        config: stepConfig,
        data: data[props.step.id],
        error: errors.find((error) => error.id === props.step.id),
        sharedData: sharedData,
        isAuthenticated: isAuthenticated,
        allStepsCompleted: currentStepId === undefined,
        completeStep: (data) => dispatch(saveAndCompleteStep(props.flow, props.step, data)),
        incompleteStep: () => dispatch(setStepIncomplete(props.flow, props.step)),
        saveStep: (data) => dispatch(saveStepData(props.flow, props.step, data)),
        goToStep: (stepId) => dispatch(goToStep(props.flow, { id: stepId })),
        devToolsName: [props.step.type, props.step.id].join('_'),
      })}
    </FlowStep.Context.Provider>
  );
};

FlowStepController.propTypes = {
  flow: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const Footer = (props) => {
  const { currentFlowId } = useContext(FlowContext);
  const [value, max] = useSelector((state) => selectProgressByFlowId(state, currentFlowId));
  const processing = useSelector((state) => selectIsSubmittingByFlowId(state, currentFlowId));

  const dispatch = useDispatch();
  const history = useHistory();

  const flow = useSelector((state) => selectFlowById(state, currentFlowId));
  const args = { flow: flow, history: history };
  const doSubmit = () => dispatch(submitFlow(args, history));

  const guardSubmit = () => {
    if (processing === true) {
      return;
    }
    if (value !== max) {
      return;
    }

    doSubmit();
  };

  const onSubmitClicked = (e) => {
    e.stopPropagation();
    e.preventDefault();
    guardSubmit();
  };

  const submitEnabled = processing !== true && value === max;

  return (
    <FooterFlow
      enabled={submitEnabled}
      handleSubmitClick={onSubmitClicked}
      max={max}
      processing={processing}
      show={props.show}
      value={value}
    />
  );
};

const getLogoUrl = (config, colorMode, overrideUrl) => {
  if (overrideUrl !== undefined) {
    return overrideUrl;
  }

  if (colorMode === COLOR_MODE_DARK) {
    return config.logo_file_url_dark;
  }

  return config.logo_file_url;
};

const getParentOptionLabel = (stepConfig, flow) => {
  const parentStep = flow?.config?.steps?.find((step) => step.id === stepConfig.parentStepId);
  if (parentStep === undefined) {
    return;
  }
  const parentOption = parentStep.config.options?.find((option) => option.id === stepConfig.parentOptionId);
  return parentOption?.label;
};
