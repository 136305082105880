import { T1 } from '@foyyay/flow-elements';
import { ControllerContext } from '@shared/context/ControllerContext';
import { selectFlowConfigById } from '@shared/reducers/flows/flowsByIdReducer';
import { selectIsAuthenticated } from '@shared/reducers/user';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FlowContext } from '../../..';
import { useThankYouController } from '../../../controllers/ThankYouController';
import { AccountButtons } from '../components/AccountButtons';
import { SectionText } from '../components/SectionText';

export const ThankYouMessage = (props) => {
  const { currentFlowId } = useContext(FlowContext);
  const config = useSelector((state) => selectFlowConfigById(state, currentFlowId));
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { allowSignIn } = useContext(ControllerContext);
  const { signOut } = useThankYouController();

  return (
    <>
      <SectionText title={config.message_thankyou_title}>{config.message_thankyou_body}</SectionText>
      {allowSignIn !== false && (
        <>
          <AccountButtons />
          <SectionReturn>
            {isAuthenticated && (
              <T1>
                If you're on a shared device, you can{' '}
                <TextLink role="button" onClick={signOut}>
                  sign out
                </TextLink>
              </T1>
            )}
          </SectionReturn>
        </>
      )}
    </>
  );
};

const SectionReturn = styled.div`
  width: 100%;
  text-align: center;
  span {
    opacity: 0.65;
  }
`;

const TextLink = styled.span`
  text-decoration: underline;
  transition: opacity 150ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
