import React from 'react';
import styled from 'styled-components';
import { media } from './style-utils';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 7.4rem;
  justify-content: space-between;
  transform: translateY(-0.1rem);

  ${media.tabletLandscapeAndUp`
    height: 9.2rem;
  `}
`;

export const LayoutStepRowSingle = (props) => {
  return <Wrapper>{props.children}</Wrapper>;
};
