import React from 'react';
import { animated, useTrail } from 'react-spring';

export const StaggeredFade = ({ children, ...props }) => {
  const trail = useTrail(React.Children.count(children), {
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 1, tension: 280, friction: 60, clamp: true },
  });

  return React.Children.map(children, (child, index) => {
    if (child !== null) {
      return React.cloneElement(child, { style: trail[index], as: animated.div });
    }
  });
};
