import { deepFreeze } from '@shared/lib/deepFreeze';

export const DEFAULT_MAX_UPLOAD_FILE_SIZE = 1024 * 1024 * 25; // 25MB.
export const BLACKLISTED_MIME_TYPES = deepFreeze(['application/octet-stream']);

const ACCEPTED_MIME_TYPES_BY = deepFreeze({
  archive: ['application/vnd.rar', 'application/x-7z-compressed', 'application/zip'],
  audio: ['audio/*'],
  document: [
    'application/msword',
    'application/pdf',
    'application/vnd.apple.keynote',
    'application/vnd.apple.numbers',
    'application/vnd.apple.pages',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'text/rtf',
  ],
  image: ['image/*'],
  pdf: ['application/pdf'],
  video: ['video/*'],
});

const getMimeTypesBy = (key) => ACCEPTED_MIME_TYPES_BY[key] ?? [];

export const getAcceptedMimeTypes = (acceptedFileTypes) =>
  acceptedFileTypes.reduce((acc, next) => [...acc, ...getMimeTypesBy(next)], []);
