// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
export function deepFreeze<Type extends object>(object: Type): Type {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object) as (keyof Type)[];
  // Freeze properties before freezing self
  for (const name of propNames) {
    const value: unknown = object[name];
    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }
  return Object.freeze(object);
}
