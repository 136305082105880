import { ButtonOption } from '@foyyay/flow-elements';
import { withStopPropagation } from '@shared/lib/events';
import React, { useContext } from 'react';
import { setCurrentStep } from '../actions/multipleChoiceActions';
import { selectReadyToContinue, selectSelectedQuantity } from '../reducers/multipleChoiceReducer';
import { StepMultipleChoice } from '../StepMultipleChoice';

export const AddCustomButton = (props) => {
  const { dispatch, useSelector, config } = useContext(StepMultipleChoice.Context);

  const selectedQuantity = useSelector(selectSelectedQuantity);
  const available = (config.limitTotal ?? Infinity) - selectedQuantity;

  const readyToContinue = useSelector((state) => selectReadyToContinue(state, config));

  const handleClickAdd = withStopPropagation(() => dispatch(setCurrentStep('add')));

  if (config.allowCustom !== true) {
    return null;
  }

  const disabled =
    (config.allowMultiple === false && config.allowQuantity === true && readyToContinue) || available < 1;

  return (
    <ButtonOption variant="secondary" disabled={disabled} onClick={handleClickAdd}>
      + Add your own
    </ButtonOption>
  );
};
