import {
  ButtonGroup,
  ButtonGroupCircleX,
  ButtonPrimary,
  CreditCardDetails,
  LabelStep,
  StepAlert,
} from '@foyyay/flow-elements';
import { SOURCE_TYPE_CARD } from '@shared/constants';
import { selectMerchantByFlowId } from '@shared/reducers/flows/flowsByIdReducer';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlowContext } from '..';
import { FlowStep } from '../components/FlowStep';
import { SecureElements } from '../components/SecureElements/SecureElements';

export const StepPaymentSourceNewCard = (props) => {
  const defaultValue = {};
  const initialValue = props.data?.value?.[SOURCE_TYPE_CARD] ?? defaultValue;

  const message = `${initialValue.account_name && 'a ' + initialValue.account_name} ending in ${
    initialValue.account_last_few ? initialValue.account_last_few : ''
  }`;

  const [cardError, setCardError] = useState();
  const error = cardError || props.error;

  return (
    <FlowStep
      label={''}
      message={message}
      hasError={error !== undefined}
      header={
        <>
          <StepError error={props.error} />
          <CardError error={cardError} />
        </>
      }
    >
      <StepPaymentSourceCardEntry
        config={props.config}
        data={props.data}
        onComplete={props.completeStep}
        onError={setCardError}
        onIncomplete={props.incompleteStep}
        onSave={props.saveStep}
        value={initialValue}
      />
    </FlowStep>
  );
};

const StepPaymentSourceCardEntry = (props) => {
  return props.value?.id === undefined ? <InputCardInfo {...props} /> : <DisplayCardInfo {...props} />;
};

const InputCardInfo = (props) => {
  const ref = useRef();

  const { currentFlowId } = useContext(FlowContext);
  const merchant = useSelector((state) => selectMerchantByFlowId(state, currentFlowId));

  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (options) => {
    if (submitting === true) {
      return;
    }

    props.onError(undefined);
    setSubmitting(true);

    let response = ref.current.submit(options);

    try {
      response = await response;
      if (response !== undefined) {
        props.onComplete({
          value: {
            ...(props.data?.value ?? {}),
            [SOURCE_TYPE_CARD]: response,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }

    setSubmitting(false);
  };

  const handleSubmitClick = (e) => {
    e.stopPropagation();
    handleSubmit();
  };

  return (
    <>
      <LabelStep>OK, Let's use a credit card</LabelStep>
      <SecureElements
        merchant={merchant}
        onError={props.onError}
        onReadyToSubmit={setReadyToSubmit}
        onSubmit={handleSubmit}
        submitting={submitting}
        type="card"
        ref={ref}
      />
      <ButtonPrimary onClick={handleSubmitClick} processing={submitting} disabled={!readyToSubmit}>
        Continue
      </ButtonPrimary>
    </>
  );
};

const DisplayCardInfo = (props) => {
  const handleContinueClick = (e) => {
    e.stopPropagation();
    props.onComplete({
      value: {
        ...(props.data?.value ?? {}),
        [SOURCE_TYPE_CARD]: props.value,
      },
    });
  };

  const clearCreditCard = useCallback(() => {
    // incompleteStep
    props.onIncomplete();
    // clearNewCardSource
    props.onSave({
      value: {
        ...props.value,
        [SOURCE_TYPE_CARD]: undefined,
      },
    });
  }, [props]);

  const handleClearCreditCardClick = (e) => {
    e.stopPropagation();
    clearCreditCard();
  };

  const cardAccountNumber = ['••••••••••••', props.value.account_last_few].join('');
  const cardCvv = `•••`;
  const cardExpireMonth = `••`;
  const cardExpireYear = `••`;
  const cardType = props.value.account_name;

  return (
    <>
      <LabelStep>OK, Using your credit card</LabelStep>
      <CreditCardDetails
        card_account_number={cardAccountNumber}
        card_cvv={cardCvv}
        card_expire_month={cardExpireMonth}
        card_expire_year={cardExpireYear}
        card_type={cardType}
      />
      <ButtonGroup>
        <ButtonGroupCircleX onClick={handleClearCreditCardClick} />
        <ButtonPrimary onClick={handleContinueClick}>Continue</ButtonPrimary>
      </ButtonGroup>
    </>
  );
};

const StepError = (props) => {
  if (props.error === undefined) {
    return null;
  }

  return (
    <StepAlert label={props.error.label} variant="error">
      {props.error.message}
    </StepAlert>
  );
};

const CardError = (props) => {
  if (props.error === undefined) {
    return null;
  }

  return (
    <StepAlert label="There was a problem with your payment method" variant="error">
      {props.error}
    </StepAlert>
  );
};
