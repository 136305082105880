import React from 'react';
import styled, { css } from 'styled-components';
import { TriggerIconGivingHand, TriggerIconGivingHeart, TriggerIconRebelGiveLogo } from '.';
import {
  TRIGGER_ICON_FOOT_PRINTS,
  TRIGGER_ICON_GIVE,
  TRIGGER_ICON_GIVING_HAND,
  TRIGGER_ICON_GIVING_HEART,
  TRIGGER_ICON_NEXT_STEPS,
  TRIGGER_ICON_NUCLEUS,
  TRIGGER_ICON_PLUS,
  TRIGGER_ICON_REBELGIVE,
} from './constants';
import {
  TriggerIconFootprints,
  TriggerIconGive,
  TriggerIconNextSteps,
  TriggerIconNucleusLogo,
  TriggerIconPlus,
  X,
} from './Icons';
import { colorStyles } from './style-utils';

export const TriggerIconButton = (props) => {
  return (
    <TriggerButton aria-label="Next Steps Launcher" {...props}>
      <StyledTriggerIcon icon={props.icon} iconResize={props.iconResize} />
      <StyledTriggeredIcon />
    </TriggerButton>
  );
};

const StyledTriggeredIcon = styled(X)`
  width: 30%;
`;

const MAP_TRIGGER_ICON_TO_COMPONENT = {
  [TRIGGER_ICON_NUCLEUS]: TriggerIconNucleusLogo,
  [TRIGGER_ICON_NEXT_STEPS]: TriggerIconNextSteps,
  [TRIGGER_ICON_PLUS]: TriggerIconPlus,
  [TRIGGER_ICON_FOOT_PRINTS]: TriggerIconFootprints,
  [TRIGGER_ICON_GIVE]: TriggerIconGive,
  [TRIGGER_ICON_REBELGIVE]: TriggerIconRebelGiveLogo,
  [TRIGGER_ICON_GIVING_HAND]: TriggerIconGivingHand,
  [TRIGGER_ICON_GIVING_HEART]: TriggerIconGivingHeart,
};

const CustomIcon = (props) => {
  const styleProps = props.iconResize === true ? { ...props.style, height: '60vh', width: '60vw' } : props.style;
  return <img src={props.icon} alt="custom-launcher-trigger-icon" style={styleProps} className={props.className} />;
};

const TriggerIcon = ({ iconResize, ...props }) => {
  const iconId = props.icon || TRIGGER_ICON_NEXT_STEPS;
  const Icon = MAP_TRIGGER_ICON_TO_COMPONENT[iconId];

  if (Icon === undefined) {
    return <CustomIcon {...props} iconResize={iconResize} />;
  }

  return <Icon {...props} />;
};

const StyledTriggerIcon = styled(TriggerIcon)`
  width: 100%;
  height: 100%;
`;

const _TriggerButton = styled.button`
  ${({ theme }) => colorStyles(theme.Trigger.Color)}
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  ${(props) =>
    props.triggered &&
    css`
      ${({ theme }) => colorStyles(theme.Trigger.Color.Triggered)}
    `}

  ${StyledTriggerIcon}, ${StyledTriggeredIcon} {
    position: absolute;
    transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
  }

  ${StyledTriggerIcon} {
    transform: rotate(0) scale(1);

    ${(props) =>
      props.triggered &&
      css`
        transform: rotate(30deg) scale(0);
      `}
  }

  ${StyledTriggeredIcon} {
    opacity: 0;
    transform: rotate(-60deg);

    ${(props) =>
      props.triggered &&
      css`
        opacity: 1;
        transform: rotate(0deg);
      `}
  }
`;
const TriggerButton = _TriggerButton;
