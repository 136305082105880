import React from 'react';
import styled from 'styled-components';
import { TRIGGER_ICON_GIVE } from './constants';
import { TriggerIconGive, TriggerIconRebelGiveLogo } from './Icons';
import { colorStyles } from './style-utils';

const InlineBlock = styled.div`
  display: inline-block;
`;

const _Circle = styled.button`
  ${({ theme }) => colorStyles(theme.ButtonCircleArrow.Color)}
  align-items: center;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  width: 80px;
  height: 80px;
`;

const Circle = _Circle;

export const ButtonCircleLogo = (props) => {
  return (
    <InlineBlock>
      <Circle {...props}>{props.triggerIcon === TRIGGER_ICON_GIVE ? <TriggerIconGive /> : <TriggerIconRebelGiveLogo />}</Circle>
    </InlineBlock>
  );
};
