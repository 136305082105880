import { getThemeValue, media } from '@foyyay/flow-elements';
import { createAlertSuccess } from '@shared/actions/alert';
import { ButtonShare } from '@shared/components/Buttons';
import { getParentWindow } from '@shared/lib/getParentWindow';
import copy from 'copy-to-clipboard';
import { partial as _partial } from 'lodash';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { InfoCardContext } from '../context/InfoCardContext';

export const ShareButton = () => {
  const { infocard } = useContext(InfoCardContext);
  const dispatch = useDispatch();
  const parentWindow = getParentWindow();

  const handleShareClick = () => {
    // TODO: copy info item link to clipboard
    const shareLink = createShareLink(infocard, parentWindow);
    copy(shareLink);
    dispatch(createAlertSuccess('Link copied!'));
  };
  return (
    <ShareButtonContainer>
      <ButtonShare onClick={handleShareClick} />
    </ShareButtonContainer>
  );
};

const getPrevNextButtonsThemeValue = _partial(getThemeValue, 'PrevNextButtons.Color.Default');

const createShareLink = (infocard, window) => {
  const url = new URL(window.location.href);
  url.search = '';
  url.searchParams.set('nucleuslauncher', 'open');
  url.searchParams.set('nlinfocard', infocard.id);
  return url.href;
};

const ShareButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${getPrevNextButtonsThemeValue('Track')};
  border-radius: 2.5rem;
  height: 4.5rem;
  padding: 0 0.5rem;

  ${media.tabletLandscapeAndUp`
    height: 5rem;
  `}
`;
