import { prayerhubReducer } from '@shared/reducers/prayerhub';
import { CombinedState } from 'redux';
import { alertsReducer } from './alertsReducer';
import { combineReducers, ReducerWithPersistentState } from './combineReducers';
import { flowsReducer } from './flows/flowsReducer';
import { launcherReducer } from './launcher';
import { springboardReducer } from './springboard';
import { userReducer } from './user';

const rootReducer = combineReducers({
  alerts: alertsReducer,
  flows: flowsReducer,
  prayerhub: prayerhubReducer,
  launcher: launcherReducer,
  springboard: springboardReducer,
  user: userReducer,
});

export default rootReducer;

export type RootState = typeof rootReducer extends ReducerWithPersistentState<CombinedState<infer S>, any> ? S : never;
