import { LauncherItem, LauncherItemLarge, media, RecentItem, RecentItemClearButton } from '@foyyay/flow-elements';
import { clearRecentItems } from '@shared/actions/springboard';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { selectSeeMoreLabel } from '@shared/reducers/launcher';
import {
  selectHomeItems,
  selectMoreItems,
  selectFeaturedItem,
  selectVisibleRecentItems,
} from '@shared/reducers/springboard';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { LauncherItem as TLauncherItem } from 'types/launcher';
import { SpringboardContext } from '../context/SpringboardContext';

export const LauncherItems = (): JSX.Element => {
  const recentItems = useSelector(selectVisibleRecentItems);
  const featuredItem = useSelector(selectFeaturedItem);
  const homeItems = useSelector(selectHomeItems);
  const moreItems = useSelector(selectMoreItems);

  const seeMoreLabel = useSelector(selectSeeMoreLabel);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickClearRecent = useClickHandler(() => dispatch(clearRecentItems()));
  const handleClickSeeMore = useClickHandler(() => history.push('/search'));
  const { handleClickLauncherItem } = useContext<any>(SpringboardContext);

  const showRecentItems = recentItems.length > 0;
  const showfeaturedItem = !showRecentItems && featuredItem !== undefined;
  const showSeeMore = moreItems.length > 0;

  return (
    <>
      {showRecentItems && (
        <RecentItemsContainer>
          <RecentItemClearButton onClick={handleClickClearRecent}>Clear recent</RecentItemClearButton>
          {recentItems.map((item) => (
            <RecentItem variant="primary" {...item} key={item.id} onClick={(e) => handleClickLauncherItem(e, item)} />
          ))}
        </RecentItemsContainer>
      )}
      {showfeaturedItem && (
        <FeaturedItemContainer>
          <FeaturedItem
            itemStyle={featuredItem?.itemStyle}
            item={featuredItem}
            onClick={(e) => handleClickLauncherItem(e, featuredItem)}
          />
        </FeaturedItemContainer>
      )}
      {homeItems.map((item) => (
        <LauncherItem variant="secondary" {...item} key={item.id} onClick={(e) => handleClickLauncherItem(e, item)} />
      ))}
      {showSeeMore && (
        <LauncherItem
          onClick={handleClickSeeMore}
          label={[undefined, ''].includes(seeMoreLabel) ? 'or, See more next steps' : seeMoreLabel}
        />
      )}
    </>
  );
};

const MAP_STYLE_TO_PROPS: { [key: string]: { size: string; variant: string } } = {
  default: { size: 'default', variant: 'secondary' },
  feature: { size: 'large', variant: 'secondary' },
  highlight: { size: 'default', variant: 'primary' },
};

const FeaturedItem = (props: {
  item: TLauncherItem;
  itemStyle?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const { size, variant } = MAP_STYLE_TO_PROPS[props.itemStyle ?? 'default'] ?? MAP_STYLE_TO_PROPS.default;
  const LauncherItemComponent = size === 'large' ? LauncherItemLarge : LauncherItem;
  return <LauncherItemComponent variant={variant} {...props.item} onClick={props.onClick} highlightTitle={true} />;
};

const FeaturedItemContainer = styled.div`
  margin-bottom: 1.6rem;

  ${media.tabletLandscapeAndUp`
    margin-bottom: 2.4rem;
  `}
`;

const RecentItemsContainer = styled.div`
  margin-bottom: 1.6rem;

  ${media.tabletLandscapeAndUp`
    margin-bottom: 2.4rem;
  `}
`;
