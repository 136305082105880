/**
 * BaseNucleusError
 * @description Base error class for Nucleus errors. This is based on the pattern used by python exceptions.
 * https://docs.python.org/3/library/exceptions.html#BaseException
 *
 * It's not meant to be used directly. Unless there is a good reason, use NucleusError instead.
 */
export class NucleusBaseError extends Error {
  override name = 'NucleusBaseError';
}

/**
 * NucleusError
 * @description Error class for Nucleus errors
 */
export class NucleusError extends NucleusBaseError {
  override name = 'NucleusError';
  data: any = {};

  toData(): any {
    return this.data;
  }

  toHttpBody(): any {
    return {};
  }
}
