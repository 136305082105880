import { CircleIconContainer, font, getThemeValue, media, XRounded } from '@foyyay/flow-elements';
import { setForceFullscreen } from '@shared/actions/launcher';
import { Button } from '@shared/components/Button';
import { Modal } from '@shared/components/Modal';
import { RenderBlocker } from '@shared/components/RenderBlocker';
import { StatusPage } from '@shared/components/StatusPage/StatusPage';
import { fromBase64 } from '@shared/lib/encoding';
import { withPreventDefault } from '@shared/lib/events';
import { prayerByIdSelector } from '@shared/reducers/prayerhub';
import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { PrayerTimeFooter } from '../../components/PrayerTimeFooter';
import { PrayerTimeHeader } from '../../components/PrayerTimeHeader';
import { PrayerTimeLayout } from '../../components/PrayerTimeLayout';
import { PrayerTimePrevNextButtons } from '../../components/PrayerTimePrevNextButtons';
import { PrayerDetailContext } from '../../context/PrayerDetailContext';
import { PrayerHubContext } from '../../context/PrayerHubContext';

const getPrayerTimeThemeValue = _partial(getThemeValue, 'PrayerTime.Color.Default');

export function PrayerTime(props) {
  const { currentPrayerHubId } = useContext(PrayerHubContext);
  const currentPrayerId = useRouteMatch().params.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const prayer = useSelector(prayerByIdSelector(currentPrayerHubId, currentPrayerId));
  const [showPrayerTime, setShowPrayerTime] = useState(true);

  const handleCloseClick = withPreventDefault(() => {
    setShowPrayerTime(false);
    dispatch(setForceFullscreen(false));
    history.goBack();
  });

  useEffect(() => {
    dispatch(setForceFullscreen(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = {
    prayer: prayer,
    goToPrayer: (id) => history.replace(`/prayertime/${id}`),
  };

  return (
    <PrayerDetailContext.Provider value={context}>
      <PrayerTimeModal show={showPrayerTime}>
        <RenderBlocker require={[currentPrayerHubId, prayer]} fallback={<StatusPageError />}>
          <PrayerTimeHeader />
          <PrayerTimeContent key={prayer.id} />
          <PrayerTimeFooter>
            <CloseButton onClick={handleCloseClick} />
            <PrayerTimePrevNextButtons />
          </PrayerTimeFooter>
        </RenderBlocker>
      </PrayerTimeModal>
    </PrayerDetailContext.Provider>
  );
}

const XButton = styled(Button)`
  width: 4rem;
  height: 4rem;

  ${media.tabletLandscapeAndUp`
    width: 5rem;
    height: 5rem;
  `}

  ${CircleIconContainer} {
    &:before {
      display: none;
    }
  }
`;

export const CloseButton = (props) => {
  const disabled = props.onClick === undefined && props.to === undefined;

  return <XButton {...props} disabled={disabled} />;
};

CloseButton.defaultProps = {
  size: 'large',
  shape: 'circle',
  variant: 'secondary',
  icon: <XRounded width="100%" />,
};

const StatusPageError = (props) => {
  const { reloadPrayerHub } = useContext(PrayerHubContext);
  const config = {
    status: 'error',
    statusPage: {
      headline: 'Whoops, there was an error loading this Prayer.',
      body: 'Please try reloading the page.',
      actions: [
        {
          label: 'Reload Now',
          priority: 'primary',
          type: 'button',
          value: (e) => {
            e.preventDefault();
            reloadPrayerHub();
          },
        },
      ],
    },
  };
  return <StatusPage status={config.status} {...config.statusPage} />;
};

const PrayerTimeContent = (props) => {
  const { prayer } = useContext(PrayerDetailContext);

  return (
    <PrayerTimeLayout>
      <PrayerBodyRadialGradient />
      <PrayerBody key={prayer.id}>
        <PrayerRequest>{fromBase64(prayer.body)}</PrayerRequest>
      </PrayerBody>
    </PrayerTimeLayout>
  );
};

const PrayerBody = styled.div`
  color: ${getPrayerTimeThemeValue('Foreground')};
  display: flex;
  flex-direction: column;
`;

const PrayerBodyRadialGradient = styled.div`
  position: fixed;
  top: 34%;
  left: 50%;
  width: 80rem;
  height: 80rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    50% 50%,
    ${(props) => getPrayerTimeThemeValue('Highlight')} 0%,
    ${getPrayerTimeThemeValue('Background')} 100%
  );

  ${media.tabletLandscapeAndUp`
    width: 112rem;
    height: 112rem;
  `}
`;

const PrayerRequestContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 1rem;
  overflow: hidden;
`;

const PrayerRequestText = styled.div`
  white-space: pre-line;

  ${font(18, 'Book', -1, 22)}

  ::first-line {
    ${font(22, 'Bold', -1.25, 30)}
  }

  ${media.tabletLandscapeAndUp`
    ${font(28, 'Book', -1.25, 34)}

    ::first-line {
      ${font(36, 'Bold', -1.5, 46)}
    }
  `}
`;

const PrayerRequest = (props) => {
  return (
    <PrayerRequestContainer>
      <PrayerRequestText>{props.children}</PrayerRequestText>
    </PrayerRequestContainer>
  );
};

const PrayerTimeModal = styled((props) => {
  const transitions = useTransition(props.show, (show) => show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      friction: 55,
      mass: 0.5,
      tension: 1000,
    },
  });

  return (
    <Modal>
      {transitions.map(({ item: show, props: style, key }) => (
        <React.Fragment key={key}>
          {show && (
            <Wrapper style={style} className={props.className}>
              <Backdrop />
              {props.children}
            </Wrapper>
          )}
        </React.Fragment>
      ))}
    </Modal>
  );
})``;

PrayerTimeModal.propTypes = {
  contentWidth: PropTypes.string,
  onClickClose: PropTypes.func,
  show: PropTypes.bool,
};

PrayerTimeModal.defaultProps = {
  show: false,
};

const Wrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  z-index: 2147483010;

  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 100%;
    vertical-align: middle;
    margin-right: -1px;
  }
`;

const Backdrop = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: ${(props) => props.theme.Background.Color.Default.Background};
`;
