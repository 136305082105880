import { getParentWindow } from '@shared/lib/getParentWindow';
import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import monitorReducersEnhancer from '../enhancers/monitorReducers';
import loggerMiddleware from '../middleware/logger';
import sentryMiddleware from '../middleware/sentry';
import rootReducer from '../reducers/reducers';

const window = getParentWindow();

// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/npm-package/developmentOnly.js
const composeWithDevTools =
  process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : function () {
        if (arguments.length === 0) return undefined;
        if (typeof arguments[0] === 'object') return compose;
        return compose.apply(null, arguments);
      };

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware, sentryMiddleware];

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(loggerMiddleware);
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  if (process.env.NODE_ENV !== 'production') {
    enhancers.push(monitorReducersEnhancer);
  }

  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers/reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
