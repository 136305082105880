import { ButtonGroup, ButtonGroupCircleX, InputAddress } from '@foyyay/flow-elements';
import { createPrefixedKey as prefixedKey } from '@nucleus/ncu-key-prefix';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import React, { useContext, useState } from 'react';
import { ContinueButton } from '../../../components/StepActionButtons';
import { StepMultipleChoice } from '../StepMultipleChoice';
import { addCustomOption, setCurrentStep, setSelectedOptions } from '../actions/multipleChoiceActions';
import { selectCustomOptions, selectSelectedOptions } from '../reducers/multipleChoiceReducer';

export const AddCustomOption = (props) => {
  const { dispatch, useSelector, config, onComplete } = useContext(StepMultipleChoice.Context);

  const inputRef = useInputFocusOnceRef();

  const [value, setValue] = useState('');

  const readyToContinue = value.trim().length > 0;
  const notReadyToContinue = readyToContinue === false;

  const selectedOptions = useSelector(selectSelectedOptions);
  const customOptions = useSelector(selectCustomOptions);

  const handleComplete = () => {
    if (notReadyToContinue) {
      return;
    }

    const option = {
      id: prefixedKey('customoption'),
      value: value,
      label: value,
    };

    dispatch(addCustomOption(option));

    const next = { ...option };

    if (config.allowQuantity === true) {
      next.quantity = 1;
    }

    dispatch(setSelectedOptions([...selectedOptions, next]));

    if (config.allowMultiple !== true && config.allowQuantity !== true) {
      onComplete({
        customOptions: [...customOptions, option],
        value: [next],
      });
    }
  };

  const handleSubmit = useSubmitHandler(handleComplete);
  const handleEnter = useSubmitHandler(handleComplete);
  const handleClickContinue = useClickHandler(handleComplete);

  const handleClickCancel = useClickHandler(() => dispatch(setCurrentStep('select')));

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputAddress placeholder="Type here" value={value} onChange={setValue} onEnter={handleEnter} ref={inputRef} />
      </form>
      <ButtonGroup style={{ marginTop: '3rem' }}>
        <ButtonGroupCircleX onClick={handleClickCancel} />
        <ContinueButton disabled={notReadyToContinue} onClick={handleClickContinue} />
      </ButtonGroup>
    </>
  );
};
