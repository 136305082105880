import { InputAddress, OptionalGoBackButton } from '@foyyay/flow-elements';
import { Select } from '@shared/components/Select';
import { useAddressPredictions } from '@shared/hooks/useAddressPredictions';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { getParentWindow } from '@shared/lib/getParentWindow';
import React, { useCallback } from 'react';
import { SkipOrContinueButton } from '../../../components/StepActionButtons';
import { getAddressFromOption, setAddress, setCurrentStep } from '../actions/stepPhysicalAddressActions';
import { selectAddress } from '../reducers/stepPhysicalAddressReducer';
import { HiddenAutofillFields } from './HiddenAutofillFields';

export const StepPhysicalAddressSearch = (props) => {
  const { useSelector, dispatch } = props;

  const address = useSelector(selectAddress);

  const inputRef = useInputFocusOnceRef();

  const handleChangeSearch = useCallback(
    (value) => {
      dispatch(setAddress({ street_address: value }));
    },
    [dispatch]
  );

  const handleChangeAddress = useCallback(
    (value) => {
      dispatch(setAddress(value));
      dispatch(setCurrentStep('confirm'));
    },
    [dispatch]
  );

  const handleContinueClick = useClickHandler(() => dispatch(setCurrentStep('confirm')));
  const handleSkipClick = useClickHandler(() => {
    props.onComplete({
      skipped: true,
      value: undefined,
    });
  });
  const readyToContinue = (address.street_address ?? '').length > 0;

  const handleChangeAddressField = useCallback(
    (value) => {
      dispatch(setAddress(value));
      dispatch(setCurrentStep('confirm'));
    },
    [dispatch]
  );

  const handleSubmit = useSubmitHandler(() => {}, []);

  const predictions = useAddressPredictions(address.street_address);

  const suggestedAddressOptions = predictions.map((item) => ({
    id: item.place_id,
    label: item.description,
  }));

  return (
    <>
      <form style={{ marginBottom: '3.6rem' }} onSubmit={handleSubmit}>
        <InputAddress
          name="street_address"
          autoComplete="street_address"
          placeholder="1234 Street"
          value={address.street_address || ''}
          onChange={handleChangeSearch}
          ref={inputRef}
        />
        <Suggestions style={{ marginTop: '2rem' }} options={suggestedAddressOptions} onChange={handleChangeAddress} />
        <HiddenAutofillFields address={address} onChange={handleChangeAddressField} />
      </form>
      <OptionalGoBackButton onClick={props.goBack}>
        <SkipOrContinueButton
          onClick={handleContinueClick}
          onClickSkip={handleSkipClick}
          readyToContinue={readyToContinue}
          required={props.required}
        />
      </OptionalGoBackButton>
    </>
  );
};

const Suggestions = (props) => {
  const handleChange = async (option) => {
    const window = getParentWindow();

    let address;
    try {
      address = await getAddressFromOption(option, window);
    } catch (error) {
      return;
    }
    props.onChange(address);
  };

  return <Select style={props.style} options={props.options} onChange={handleChange} />;
};
