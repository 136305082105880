import styled from 'styled-components';

const HORIZONTAL_PAD = '1.7rem';

export const Container = styled.div`
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : undefined)};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 'auto')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 'auto')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : undefined)};
  max-width: ${({ maxWidth }) => (maxWidth ? 'calc(' + maxWidth + ' + ' + HORIZONTAL_PAD + ' * 2)' : '120rem')};
  padding-left: ${HORIZONTAL_PAD};
  padding-right: ${HORIZONTAL_PAD};
  width: ${({ width }) => (width ? width : '100%')};
`;
