import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { selectAvailableQuantity, selectCustomOptions, selectSelectedOptions } from '../reducers/multipleChoiceReducer';
import { StepMultipleChoice } from '../StepMultipleChoice';
import { CheckBoxOption } from './CheckBoxOption';
import { RadioOption } from './RadioOption';

export const OptionsList = (props) => {
  const { useSelector, config } = useContext(StepMultipleChoice.Context);

  const selectedOptions = useSelector(selectSelectedOptions);

  const visibleOptions = config.options.filter(
    (option) =>
      option.visible !== false ||
      selectedOptions.find((selectedOption) => selectedOption.id === option.id) !== undefined
  );
  const customOptions = useSelector(selectCustomOptions);
  const options = [...visibleOptions, ...customOptions];

  const totalAvailableQuantity = useSelector((state) => selectAvailableQuantity(state, config.limitMax));

  const alignTextLeft = options.some(
    (option) => option.description !== undefined || option.badge !== undefined || config.allowQuantity === true
  );
  const alignText = alignTextLeft === true ? 'left' : 'center';

  return options.map((option) => (
    <Option key={option.id} option={option} maxTotal={totalAvailableQuantity} alignText={alignText} />
  ));
};

const Option = (props) => {
  const { config } = useContext(StepMultipleChoice.Context);

  if (config.allowMultiple === true) {
    return <CheckBoxOption {...props} />;
  }

  return <RadioOption {...props} />;
};

Option.propTypes = {
  alignText: PropTypes.oneOf(['center', 'left']),
  option: PropTypes.object.isRequired,
  maxTotal: PropTypes.number,
};
