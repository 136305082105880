import { useThunkReducer } from '@shared/hooks/useThunkReducer';
import React from 'react';
import { FlowStep } from '../../components/FlowStep';
import { StepBirthdayInput } from './components/StepBirthdayInput';
import { StepBirthdayPicker } from './components/StepBirthdayPicker';
import {
  init,
  selectCurrentStep,
  selectLabel,
  selectMessage,
  stepBirthdayReducer,
} from './reducers/stepBirthdayReducer';

export const StepBirthday = (props) => {
  const initializerArg = { data: props.data };
  const [state, dispatch, useSelector] = useThunkReducer(stepBirthdayReducer, {
    devToolsName: props.devToolsName,
    initializerArg: initializerArg,
    initializer: init,
  });

  const currentStep = useSelector(selectCurrentStep);
  const label = useSelector((state) => selectLabel(state, props.config));
  const message = useSelector((state) => selectMessage(state, props.config, props.data));

  return (
    <FlowStep description={props.config.description} label={label} message={message}>
      {React.createElement(getStepBy(currentStep), {
        state: state,
        dispatch: dispatch,
        useSelector: useSelector,
        required: props.config.required,
        onComplete: props.completeStep,
        goBack: props.goBack,
      })}
    </FlowStep>
  );
};

const stepBy = {
  input: StepBirthdayInput,
  picker: StepBirthdayPicker,
};

const getStepBy = (currentStep) => stepBy[currentStep] ?? stepBy.input;
