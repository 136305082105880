import { H3, LoadingSpinner, media, T3 } from '@foyyay/flow-elements';
import React from 'react';
import styled from 'styled-components';

const SummaryContainer = styled.div`
  box-sizing: border-box;
  margin-top: 2.2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 34rem;
  padding: 2.6rem;
  width: 100%;
  position: relative;
  background: ${(props) => props.theme.Summary.Color.Default.Background};
  border-radius: 2.1rem;

  ${media.tabletLandscapeAndUp`
    padding-left: 3.6rem;
    padding-right: 3.6rem;
    margin-top: 3.6rem;
    max-width: 48rem;
  `};
`;

const Total = styled(H3)`
  margin: 0;
`;

const Description = styled(T3)`
  margin: 0.2em 0 0 0;
`;

const LearnMore = styled.a`
  white-space: nowrap;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 250ms ease-out;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;

const SpinnerContainer = styled.div`
  opacity: ${(props) => (props.hasLoadedFee ? 0 : 1)};
  transition: opacity 100ms ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

const SummaryContent = styled.div`
  opacity: ${(props) => (props.hasLoadedFee ? 1 : 0)};
  transition: opacity 200ms ease-out;
  position: relative;
  z-index: 10;
`;

export const PaymentSummary = React.forwardRef((props, ref) => {
  return (
    <SummaryContainer ref={ref}>
      <SummaryContent hasLoadedFee={props.hasLoadedFee}>
        <Total>
          {props.label} {props.total}
        </Total>
        {props.feeRaw > 0 && (
          <Description>
            <LearnMore onClick={props.onLearnMoreClick}>
              <span>Includes {props.fee} convenience fee &mdash;&nbsp;Learn More</span>
            </LearnMore>
          </Description>
        )}
        {props.children}
      </SummaryContent>
      <SpinnerContainer hasLoadedFee={props.hasLoadedFee}>
        <LoadingSpinner />
      </SpinnerContainer>
    </SummaryContainer>
  );
});
