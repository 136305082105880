import {
  ButtonGroup,
  ButtonGroupCircleX,
  ButtonOptionSecondary,
  DatePicker,
  getClosestValidDate,
  InputDate,
  media,
} from '@foyyay/flow-elements';
import { DATE_MAX_FUTURE_YEARS, DATE_MAX_PAST_YEARS } from '@shared/constants/datetime';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import {
  americanDateStringExp,
  americanStringToIsoString,
  dateToIsoString,
  isoStringToAmerican,
  isoStringToDate,
} from '@shared/lib/datetime';
import { withStopPropagation } from '@shared/lib/events';
import * as dateFns from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DateTimeStepActionButtons } from '../../../components/DateTimeStepActionButtons';
import { SkipOrContinueButton } from '../../../components/StepActionButtons';
import { goToNextStep, goToPrevStep, setDate, setInputMode } from '../actions/stepDateTimeActions';
import { INPUT_MODE_PICKER, INPUT_MODE_TEXT } from '../constants';
import {
  selectDate,
  selectFirstStep,
  selectInputMode,
  selectLastStep,
  selectTime,
} from '../reducers/stepDateTimeReducer';

export const StepDate = (props) => {
  const { useSelector } = props;
  const inputMode = useSelector(selectInputMode);
  const StepDateInput = MAP_INPUT_MODE_TO_COMPONENT[inputMode];
  return React.createElement(StepDateInput, { ...props });
};

const StepDateText = (props) => {
  const { useSelector, dispatch } = props;
  const inputRef = useInputFocusOnceRef();
  const date = useSelector(selectDate);
  const time = useSelector(selectTime);
  const initialDateInput = date ? isoStringToAmerican(date) : '';
  const [dateInput, setDateInput] = useState(initialDateInput);

  const now = useMemo(() => new Date(), []);
  const startDate = useMemo(
    () =>
      props.config?.dateLimitType === 'future'
        ? dateFns.startOfDay(now)
        : dateFns.subYears(dateFns.startOfDay(now), DATE_MAX_PAST_YEARS),
    [props.config, now]
  );
  const endDate = useMemo(
    () =>
      props.config?.dateLimitType === 'past'
        ? dateFns.endOfDay(now)
        : dateFns.addYears(dateFns.endOfDay(now), DATE_MAX_FUTURE_YEARS),
    [props.config, now]
  );

  const isValidDate = isValidDateString(dateInput, { start: startDate, end: endDate });
  const firstStep = useSelector(selectFirstStep);
  const isFirstStep = props.step === firstStep;
  const lastStep = useSelector(selectLastStep);
  const isLastStep = props.step === lastStep;
  const required = isFirstStep === false || props.required === true;
  const renderBackButton = isFirstStep === false;

  const handleChange = (value) => {
    setDateInput(value);
  };

  const handleComplete = () => {
    const dateIso = americanStringToIsoString(dateInput);
    dispatch(setDate(dateIso));
    if (isLastStep) {
      props.onComplete({
        value: {
          time: time,
          date: dateIso,
        },
      });
    } else {
      dispatch(goToNextStep());
    }
  };

  const handleCancel = () => {
    dispatch(goToPrevStep());
  };

  const handleChangeInputMode = withStopPropagation(() => dispatch(setInputMode(INPUT_MODE_PICKER)));
  const handleSubmit = useSubmitHandler(handleComplete);
  const handleEnter = useSubmitHandler(handleComplete);
  const handleContinueClick = withStopPropagation(handleComplete);
  const handleCancelClick = withStopPropagation(handleCancel);
  const handleSkipClick = withStopPropagation(() =>
    props.onComplete({
      skipped: true,
      value: undefined,
    })
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputWrapper>
          <InputDate value={dateInput} onChange={handleChange} onEnter={handleEnter} ref={inputRef} />
          <ButtonOptionSecondary onClick={handleChangeInputMode} role="button">
            Switch to date picker
          </ButtonOptionSecondary>
        </InputWrapper>
      </form>

      <ButtonGroup>
        {renderBackButton && <ButtonGroupCircleX onClick={handleCancelClick} />}
        <SkipOrContinueButton
          onClick={handleContinueClick}
          onClickSkip={handleSkipClick}
          readyToContinue={isValidDate}
          required={required}
        />
      </ButtonGroup>
    </>
  );
};

function validateDate(date, range, daysOfWeek) {
  if (!(date instanceof Date && isFinite(date))) {
    return false;
  }

  if (!dateFns.isWithinInterval(date, range)) {
    return false;
  }

  if (daysOfWeek?.length > 0 && !daysOfWeek.some((day) => parseInt(day) === dateFns.getDay(date))) {
    return false;
  }

  return true;
}

const isValidDateString = (value, range) => {
  if (americanDateStringExp.test(value) === false) {
    return false;
  }

  const date = new Date(value);

  return validateDate(date, range);
};

const initDatePickerState = (date, validator, now, startDate, endDate) => {
  const value = date ? isoStringToDate(date) : now;
  return getClosestValidDate(value, startDate, endDate, validator);
};

const StepDatePicker = (props) => {
  const { useSelector, dispatch } = props;
  const date = useSelector(selectDate);
  const time = useSelector(selectTime);
  const now = useMemo(() => new Date(), []);

  const startDate = useMemo(
    () =>
      props.config?.dateLimitType === 'future'
        ? dateFns.startOfDay(now)
        : dateFns.subYears(dateFns.startOfDay(now), DATE_MAX_PAST_YEARS),
    [props.config, now]
  );
  const endDate = useMemo(
    () =>
      props.config?.dateLimitType === 'past'
        ? dateFns.endOfDay(now)
        : dateFns.addYears(dateFns.endOfDay(now), DATE_MAX_FUTURE_YEARS),
    [props.config, now]
  );

  const validator = useCallback(
    (date) => {
      return validateDate(date, { start: startDate, end: endDate }, props.config.dateLimitDaysOfWeek);
    },
    [startDate, endDate, props.config.dateLimitDaysOfWeek]
  );

  const [dateInput, setDateInput] = useState(initDatePickerState(date, validator, now, startDate, endDate));

  const firstStep = useSelector(selectFirstStep);
  const isFirstStep = props.step === firstStep;
  const lastStep = useSelector(selectLastStep);
  const isLastStep = props.step === lastStep;
  const required = isFirstStep === false || props.required === true;
  const renderBackButton = isFirstStep === false;
  const manualEntryAllowed = props.config.dateLimitDaysOfWeek === undefined;

  const handleComplete = () => {
    const dateIso = dateToIsoString(dateInput);
    dispatch(setDate(dateIso));
    if (isLastStep) {
      props.onComplete({
        value: {
          time: time,
          date: dateIso,
        },
      });
    } else {
      dispatch(goToNextStep());
    }
  };

  const handleCancel = () => {
    dispatch(goToPrevStep());
  };

  const handleChangeInputMode = withStopPropagation(() => dispatch(setInputMode(INPUT_MODE_TEXT)));
  const handleContinueClick = withStopPropagation(handleComplete);
  const handleCancelClick = withStopPropagation(handleCancel);
  const handleSkipClick = withStopPropagation(() =>
    props.onComplete({
      skipped: true,
      value: undefined,
    })
  );

  return (
    <>
      <InputWrapper>
        <StyledDatePicker
          value={dateInput}
          onChange={setDateInput}
          startDate={startDate}
          endDate={endDate}
          validator={validator}
        />
        {manualEntryAllowed && (
          <ButtonOptionSecondary onClick={handleChangeInputMode}>Type it in</ButtonOptionSecondary>
        )}
      </InputWrapper>

      <DateTimeStepActionButtons
        onClick={handleContinueClick}
        onClickSkip={handleSkipClick}
        onClickCancel={handleCancelClick}
        required={required}
        renderBackButton={renderBackButton}
      />
    </>
  );
};

const InputWrapper = styled.div`
  margin-bottom: 3rem;
`;
const StyledDatePicker = styled(DatePicker)`
  margin-left: -2.2rem;
  margin-right: -2.2rem;

  ${media.tabletLandscapeAndUp`
    margin-left: -3.6rem;
    margin-right: -3.6rem;
  `}
`;

const MAP_INPUT_MODE_TO_COMPONENT = {
  [INPUT_MODE_PICKER]: StepDatePicker,
  [INPUT_MODE_TEXT]: StepDateText,
};
