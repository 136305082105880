import React, { Component } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { Styles } from './Typography';

const InputF2 = styled(Input)`
  ${Styles.F2};
  color: ${({ theme }) => theme.InputLine.Color.Default};
  /* Fixme: Should auto shrink on mobile */
`;

const F2Container = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
`;

export class InputLine extends Component {
  render() {
    return (
      <F2Container>
        <InputF2 autoCorrect="off" spellCheck="false" {...this.props} />
      </F2Container>
    );
  }
}
