import { CircleIconAngle, CircleIconContainer, media } from '@foyyay/flow-elements';
import { Button } from '@shared/components/Button';
import { nextPrayerSelector, previousPrayerSelector } from '@shared/reducers/prayerhub';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PrayerDetailContext } from '../context/PrayerDetailContext';
import { PrayerHubContext } from '../context/PrayerHubContext';

export const PrayerTimePrevNextButtons = (props) => {
  const { currentPrayerHubId, currentPrayerWallId } = useContext(PrayerHubContext);
  const { goToPrayer, prayer } = useContext(PrayerDetailContext);
  const prevPrayerId = useSelector(previousPrayerSelector(currentPrayerHubId, currentPrayerWallId, prayer.id));
  const nextPrayerId = useSelector(nextPrayerSelector(currentPrayerHubId, currentPrayerWallId, prayer.id));
  const prevButtonOnClick = prevPrayerId ? () => goToPrayer(prevPrayerId) : undefined;
  const nextButtonOnClick = nextPrayerId ? () => goToPrayer(nextPrayerId) : undefined;

  return (
    <PrevNextButtonContainer>
      <PrevButton onClick={prevButtonOnClick} />
      <NextButton onClick={nextButtonOnClick} />
    </PrevNextButtonContainer>
  );
};

const ArrowButton = styled(Button)`
  width: 4rem;
  height: 4rem;

  ${media.tabletLandscapeAndUp`
    width: 5rem;
    height: 5rem;
  `}

  ${CircleIconContainer} {
    &:before {
      display: none;
    }
  }
`;

export const PrevButton = (props) => {
  const disabled = props.onClick === undefined && props.to === undefined;

  return <ArrowButton {...props} disabled={disabled} />;
};

PrevButton.defaultProps = {
  size: 'large',
  shape: 'circle',
  variant: 'secondary',
  icon: <CircleIconAngle direction="left" width="100%" />,
};

export const NextButton = styled((props) => {
  const disabled = props.onClick === undefined && props.to === undefined;

  return (
    <Button {...props} disabled={disabled}>
      Next
    </Button>
  );
})`
  ${CircleIconContainer} {
    width: 4rem;
    height: 4rem;
    margin: 0 -1.2rem 0 -1.6rem;

    ${media.tabletLandscapeAndUp`
      width: 5rem;
      height: 5rem;
    `}

    &:before {
      display: none;
    }
  }
`;

NextButton.defaultProps = {
  size: 'large',
  shape: 'pill',
  variant: 'secondary',
  iconRight: <CircleIconAngle direction="right" />,
};

const PrevNextButtonContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 2rem;

    ${media.tabletLandscapeAndUp`
      margin-right: 2.5rem;
  `}
  }
`;
