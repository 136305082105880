import * as dateFns from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { ISO8601 } from 'types/date';
import { deepFreeze } from './deepFreeze';

export const americanDateStringExp = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20|21)\d\d/;

export const isoStringToDate = (isoString: ISO8601): Date => dateFns.parseISO(isoString);
export const dateToIsoString = (date: Date): ISO8601 => dateFns.format(date, 'yyyy-MM-dd');

export const isoStringToAmerican = (isoString: ISO8601): string => dateFns.format(isoStringToDate(isoString), 'MM/dd/yyyy');
export const americanStringToIsoString = (americanString: string): ISO8601 => {
  const parts = americanString.split('/');
  return `${parts[2]}-${parts[0]}-${parts[1]}`;
};

const formatRelativeLocale = deepFreeze({
  lastWeek: "'Last' eeee 'at' hh:mmaaaaa'm'",
  yesterday: "'Yesterday' 'at' hh:mmaaaaa'm'",
  today: "'Today' 'at' hh:mmaaaaa'm'",
  tomorrow: "'Tomorrow' 'at' hh:mmaaaaa'm'",
  nextWeek: "'Next' eeee 'at' hh:mmaaaaa'm'",
  other: "M/d/yyyy 'at' hh:mmaaaaa'm'",
});

const CUSTOM_RELATIVE_LOCALE = deepFreeze({
  ...enUS,
  formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
});

export const formatIsoDateRelativeNow = (date: ISO8601): string => {
  if (date === undefined) return '';
  return dateFns.formatRelative(dateFns.parseISO(date), new Date(), {
    locale: CUSTOM_RELATIVE_LOCALE,
  });
};
