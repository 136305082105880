import { InputEmail, OptionalGoBackButton } from '@foyyay/flow-elements';
import { EMAIL_REGEX } from '@shared/constants';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { withPreventDefault, withStopPropagation } from '@shared/lib/events';
import { formatValueWithPrefix } from '@shared/lib/format';
import React, { useCallback, useMemo, useState } from 'react';
import { FlowStep } from '../components/FlowStep';
import { SkipOrContinueButton } from '../components/StepActionButtons';

export const StepEmail = (props) => {
  const defaultValue = '';
  const initialValue = props.data?.value ?? defaultValue;

  const skipped = props.data?.skipped === true;

  const message = useMemo(() => {
    if (skipped) {
      return `Skipped: "${props.config.label}"`;
    }

    return formatValueWithPrefix(props.config.valuePrefixLabel, initialValue.trim(), props.config.valuePrefixSeparator);
  }, [initialValue, props.config, skipped]);

  return (
    <FlowStep description={props.config.description} label={props.config.label} message={message}>
      <EmailAddressInput
        value={initialValue}
        required={props.config.required}
        onComplete={props.completeStep}
        goBack={props.goBack}
      />
    </FlowStep>
  );
};

const EmailAddressInput = (props) => {
  const { onComplete } = props;
  const inputRef = useInputFocusOnceRef();

  const initialState = props.value;
  const [inputEmail, setInputEmail] = useState(initialState);

  const handleEmailChange = useCallback((value) => setInputEmail(value), [setInputEmail]);

  const readyToSubmit = ![inputEmail].includes('') && EMAIL_REGEX.test(inputEmail);

  const submitEmail = useCallback(() => {
    if (readyToSubmit !== true) {
      return;
    }

    onComplete({ value: inputEmail.trim() });
  }, [readyToSubmit, onComplete, inputEmail]);

  const handleSkip = () => {
    onComplete({
      skipped: true,
      value: undefined,
    });
  };

  const handleSubmit = withPreventDefault(submitEmail);
  const handleContinueClick = withStopPropagation(submitEmail);
  const handleSkipClick = withStopPropagation(handleSkip);

  return (
    <>
      <form style={{ marginBottom: '3.6rem' }} onSubmit={handleSubmit}>
        <InputEmail
          name="email"
          autoComplete="email"
          placeholder="name@example.com"
          value={inputEmail}
          onChange={handleEmailChange}
          ref={inputRef}
        />
      </form>
      <OptionalGoBackButton onClick={props.goBack}>
        <SkipOrContinueButton
          onClick={handleContinueClick}
          onClickSkip={handleSkipClick}
          readyToContinue={readyToSubmit}
          required={props.required}
        />
      </OptionalGoBackButton>
    </>
  );
};
