import { createGlobalStyle } from 'styled-components';
import { media } from './style-utils';

export const BodyStyle = createGlobalStyle`
  html {
    & {
      font-size: 9px;

      ${media.smallPhoneAndUp`
        font-size: 10px !important;
      `}
    }
  }

  body {
    font-family: 'LL Circular';
    color: ${({ theme }) => theme.Background.Color.Default.Foreground};
    background: ${({ theme }) => theme.Background.Color.Default.Background};
    min-width: 320px;
    overflow: hidden;
    overscroll-behavior: none;
    transition: 0.25s background-color ease-in-out;
  }

  strong, b {
    font-weight: 700;
  }
`;
