import { InputTextSmall } from '@foyyay/flow-elements';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useInputFocusOnceRef } from '@shared/hooks/useInputFocusRef';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { formatValueWithPrefix } from '@shared/lib/format';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { FlowStep } from '../components/FlowStep';
import { StepActionButtons } from '../components/StepActionButtons';

const CONFIG_NAME_PARTS_FALLBACK = deepFreeze({
  firstName: {
    active: true,
    required: true,
  },
  lastName: {
    active: true,
    required: true,
  },
  middleName: {
    active: true,
    required: false,
  },
});

export const StepName = (props) => {
  const defaultValue = { firstName: '', lastName: '', middleName: '' };
  const initialValue = props.data?.value ?? defaultValue;

  const name = [initialValue.firstName, initialValue.middleName, initialValue.lastName].join(' ').trim();
  const message = formatValueWithPrefix(props.config.valuePrefixLabel, name, props.config.valuePrefixSeparator);

  return (
    <FlowStep description={props.config.description} label={props.config.label} message={message}>
      <NameInput
        value={initialValue}
        required={props.config.required}
        onComplete={props.completeStep}
        goBack={props.goBack}
        parts={props.config?.parts ?? CONFIG_NAME_PARTS_FALLBACK}
      />
    </FlowStep>
  );
};

const NameInput = (props) => {
  const { onComplete } = props;
  const inputRef = useInputFocusOnceRef();

  const initialState = props.value;
  const [form, setForm] = useState(initialState);

  const handleFirstNameChange = useCallback((value) => setForm({ ...form, firstName: value }), [form]);
  const handleLastNameChange = useCallback((value) => setForm({ ...form, lastName: value }), [form]);
  const handleMiddleNameChange = useCallback((value) => setForm({ ...form, middleName: value }), [form]);

  const readyToSubmit = Object.keys(props.parts)
    .filter((key) => props.parts[key] === true || (props.parts[key].active && props.parts[key].required))
    .every((key) => form[key]?.length > 0);

  const submitName = useCallback(() => {
    if (readyToSubmit !== true) {
      return;
    }

    const value = {
      firstName: form.firstName.trim(),
      lastName: form.lastName.trim(),
      middleName: form.middleName.trim(),
    };

    onComplete({
      value: value,
    });
  }, [form, onComplete, readyToSubmit]);

  const handleSubmit = useSubmitHandler(submitName, [submitName]);
  const handleEnter = useClickHandler(submitName, [submitName]);
  const handleContinueClick = useClickHandler(submitName, [submitName]);

  const showMiddleName = props.parts?.middleName?.active === true;

  return (
    <>
      <form style={{ marginBottom: '3.6rem' }} onSubmit={handleSubmit}>
        <Columns>
          <InputTextSmall
            name="fName"
            autoComplete="given-name"
            placeholder="First name"
            value={form.firstName}
            onChange={handleFirstNameChange}
            onEnter={handleEnter}
            ref={inputRef}
          />
          <InputTextSmall
            name="lName"
            autoComplete="family-name"
            placeholder="Last name"
            value={form.lastName}
            onChange={handleLastNameChange}
            onEnter={handleEnter}
          />
        </Columns>
        {showMiddleName && (
          <Columns>
            <InputTextSmall
              name="mName"
              autoComplete="additional-name"
              placeholder="Middle name"
              value={form.middleName}
              onChange={handleMiddleNameChange}
              onEnter={handleEnter}
              style={{ marginTop: '1rem' }}
            />
          </Columns>
        )}
      </form>
      <StepActionButtons
        onContinueClick={handleContinueClick}
        onCancelClick={props.goBack}
        readyToContinue={readyToSubmit}
      />
    </>
  );
};

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
`;
