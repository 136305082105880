import * as yup from 'yup';
import { createReducer } from '@reduxjs/toolkit';
import { deepFreeze } from '@shared/lib/deepFreeze';
import {
  addCustomOption,
  deselectOption,
  selectOption,
  setCurrentStep,
  setOptionQuantity,
  setSelectedOptions,
} from '../actions/multipleChoiceActions';
import { formatValueWithPrefix } from '@shared/lib/format';
import { isNumeric } from '@shared/lib/number';

export const selectCurrentStep = (state) => state.currentStep;

export const selectSelectedOptions = (state) => state.selectedOptions;
export const selectCustomOptions = (state) => state.customOptions;

export const selectSelectedQuantity = (state) => {
  return state.selectedOptions.reduce((total, option) => {
    const selectedQuantity = parseOptionQuantity(option);
    return total + selectedQuantity;
  }, 0);
};

const parseOptionQuantity = (option) => (isNumeric(option.quantity) ? Number(option.quantity) : 1);

export const selectAvailableQuantity = (state, limitMax) => {
  const selectedQuantity = selectSelectedQuantity(state);
  return (limitMax ?? Infinity) - selectedQuantity;
};

const isWithinLimits = (state, config) => {
  return state.selectedOptions.every((selectedOption) => {
    const selectedQuantity = selectedOption.quantity ?? 1;
    const limit = config.options.find((option) => option.id === selectedOption.id)?.limitMax ?? Infinity;
    return selectedQuantity <= limit;
  });
};

export const selectReadyToContinue = (state, config) => {
  const withinLimits = isWithinLimits(state, config);
  return state.selectedOptions.length > 0 && withinLimits;
};

export const selectLabel = (state, config) => config.label;

export const selectMessage = (state, config, data) => {
  const skipped = data?.skipped === true;

  if (skipped) {
    return `Skipped: "${config.label}"`;
  }

  return formatValueWithPrefix(
    config.valuePrefixLabel,
    data?.value?.map(joinOptionQuantityLabel).join(', '),
    config.valuePrefixSeparator
  );
};

const joinOptionQuantityLabel = (option) => {
  if (option.quantity !== undefined) {
    return `(${option.quantity}) ${option.label}`;
  }

  if (option.badge !== undefined && option.badge !== '') {
    return `${option.badge} for ${option.label}`;
  }

  return option.label;
};

const optionQuantityLimitSchema = {
  limitMax: yup.number(),
  limitTotal: yup.number(),
};

const optionSchema = yup.object().shape({
  id: yup.string(),
  label: yup.string(),
  value: yup.string(),
  ...optionQuantityLimitSchema,
});

export const configSchema = {
  allowCustom: yup.bool().required(),
  allowMultiple: yup.bool().required(),
  allowQuantity: yup.bool(),
  description: yup.string(),
  label: yup.string().min(3).required(),
  options: yup.array().of(optionSchema).min(1).required(),
  required: yup.mixed().oneOf([true, false, 'conditional']).required(),
};

const initialState = deepFreeze({
  currentStep: 'select',
  customOptions: [],
  selectedOptions: [],
});

export const init = (args) => ({
  ...initialState,
  currentStep: 'select',
  customOptions: args.data?.customOptions ?? initialState.customOptions,
  selectedOptions: args.data?.value ?? initialState.selectedOptions,
});

export const multipleChoiceReducer = createReducer(initialState, {
  [setCurrentStep]: (state, action) => ({
    ...state,
    currentStep: action.payload,
  }),
  [setSelectedOptions]: (state, action) => ({
    ...state,
    selectedOptions: action.payload,
  }),
  [selectOption]: (state, action) => ({
    ...state,
    selectedOptions: state.selectedOptions.concat([action.payload]),
  }),
  [deselectOption]: (state, action) => ({
    ...state,
    selectedOptions: state.selectedOptions.filter((option) => option.id !== action.payload.id),
  }),
  [setOptionQuantity]: (state, action) => {
    if (action.payload.quantity < 1) {
      return {
        ...state,
        selectedOptions: state.selectedOptions.filter((option) => option.id !== action.payload.option.id),
      };
    }

    const index = state.selectedOptions.findIndex((option) => option.id === action.payload.option.id);

    if (index < 0) {
      return state;
    }

    return {
      ...state,
      selectedOptions: [
        ...state.selectedOptions.slice(0, index),
        {
          ...action.payload.option,
          quantity: action.payload.quantity,
        },
        ...state.selectedOptions.slice(index + 1),
      ],
    };
  },
  [addCustomOption]: (state, action) => ({
    ...state,
    currentStep: 'select',
    customOptions: [...state.customOptions, action.payload],
  }),
});
