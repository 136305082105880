import * as Sentry from '@sentry/react';
import { fetchLauncherConfig } from '@shared/actions/launcher';
import { STATE_ERROR, STATE_LOADED, STATE_LOADING } from '@shared/constants/state';
import { selectLauncherConfig } from '@shared/reducers/launcher';
import { pick as _pick } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useLauncherConfig = (launcherShortcode) => {
  const config = useSelector((state) => selectLauncherConfig(state));
  const initialState = config === undefined ? STATE_LOADING : STATE_LOADED;

  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state === STATE_LOADED) {
      return;
    }

    async function fetchConfig() {
      try {
        await dispatch(fetchLauncherConfig(launcherShortcode));
      } catch (error) {
        setState(STATE_ERROR);
        return;
      }
      setState(STATE_LOADED);
    }

    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, launcherShortcode]);

  useEffect(() => {
    Sentry.setContext('launcher', {
      ..._pick(config, ['id']),
      shortcode: launcherShortcode,
    });
    return () => Sentry.setContext('launcher', null);
  }, [config, launcherShortcode]);

  return [config, state, { STATE_LOADING: STATE_LOADING, STATE_LOADED: STATE_LOADED, STATE_ERROR: STATE_ERROR }];
};
