import { Action, AnyAction, CombinedState, combineReducers as reduxCombineReducers, Reducer } from 'redux';

export interface ReducerWithPersistentState<S = any, A extends Action = AnyAction> extends Reducer<S, A> {
  getPersistentState?: (state: S) => Partial<S>;
}

export type ReducersMapObject<S = any, A extends Action = Action> = {
  [K in keyof S]: ReducerWithPersistentState<S[K], A>;
};

export function combineReducers<S>(reducers: ReducersMapObject<S, any>): ReducerWithPersistentState<CombinedState<S>> {
  const reducer = reduxCombineReducers(reducers) as ReducerWithPersistentState<CombinedState<S>, AnyAction>;

  reducer.getPersistentState = (state) => {
    const persistent: any = {};
    (Object.keys(reducers) as (keyof S)[]).forEach((key) => {
      const getPersistentState = reducers[key].getPersistentState;
      if (typeof getPersistentState === 'function') {
        persistent[key] = getPersistentState(state[key]);
      }
    });

    return persistent;
  };

  return reducer;
}
