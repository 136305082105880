import Color from 'color';
import PropTypes from 'prop-types';
import React from 'react';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import { ButtonCircleX } from './ButtonCircleX';
import { font, media, SIZE } from './style-utils';

const Headline = styled.h4`
  ${font(16, 'Black', 1)}
  text-transform: uppercase;
  margin: 0;
`;

const Detail = styled.p`
  ${font(14, 'Book', 0, 16)}
  margin: 0.1rem 0;

  :last-child {
    margin-bottom: ${SIZE[1]};
  }
`;

const ContentItem = styled.div`
  margin-top: 1.3rem;

  ${Detail} {
    ${font(14, 'Black', 0, 16)}
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  :last-child {
    margin-bottom: ${SIZE[1]};
  }
`;

const ListItem = styled.li`
  ${font(14, 'Book', 0, 16)}

  ::before {
    content: '\\2022';
    margin-right: 5px;
  }
`;

const TextContainer = styled.span`
  padding: 2rem 2.4rem;
  margin-right: 4rem;
`;

const DismissButton = styled(ButtonCircleX)`
  position: absolute;
  top: 1.8rem;
  right: 0;
  height: 4.2rem;
  width: 4.2rem;
  margin: 0 1.8rem 0 0;

  &:hover,
  &:active {
    opacity: 1;
  }
`;

const JUSTIFY_CONTENT_BY = {
  left: 'flex-start',
  center: 'center',
};

const AlertStyles = {
  base: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    width: 100%;
    min-height: 8.2rem;
    border-radius: 1.2rem;

    & + & {
      margin-top: 0.6rem;
    }

    box-shadow: 0rem 0.7rem 1.4rem rgba(0, 0, 0, 0.25);

    ${media.tabletPortraitAndUp`
      justify-content: ${(props) => JUSTIFY_CONTENT_BY[props.align]};
      text-align: ${(props) => props.align};
    `}
  `,
};

const AlertVariant = {
  error: css`
    background: ${(props) => props.theme.Alert.Color.Error.Background};
    color: ${(props) => props.theme.Alert.Color.Error.Foreground};

    ${DismissButton} {
      color: ${(props) => props.theme.Alert.Color.Error.Foreground} !important;
      :before {
        background: ${(props) => Color(props.theme.Alert.Color.Error.Foreground).alpha(0.15).string()} !important;
      }
    }
  `,
  info: css`
    background: ${(props) => props.theme.Alert.Color.Info.Background};
    color: ${(props) => props.theme.Alert.Color.Info.Foreground};

    ${DismissButton} {
      color: ${(props) => props.theme.Alert.Color.Info.Background} !important;
      background: ${(props) => props.theme.Alert.Color.Info.Foreground} !important;
    }
  `,
  success: css`
    background: ${(props) => props.theme.Alert.Color.Success.Background};
    color: ${(props) => props.theme.Alert.Color.Success.Foreground};

    ${DismissButton} {
      color: ${(props) => props.theme.Alert.Color.Success.Background} !important;
      background: ${(props) => props.theme.Alert.Color.Success.Foreground} !important;
    }
  `,
};

const StyledAnimatedLi = styled(animated.li)`
  ${AlertStyles.base};
  ${(props) => AlertVariant[props.variant]};
`;

export const Alert = styled(
  React.forwardRef((props, ref) => {
    const { loading, message, detail, content, onClickDismiss, variant, ...otherProps } = props;
    const align = haveItems(props.lists) ? 'left' : 'center';

    return (
      <StyledAnimatedLi align={align} variant={variant} {...otherProps} ref={ref}>
        <TextContainer>
          <Headline>{props.message}</Headline>
          {props.detail && <Detail>{props.detail}</Detail>}
          {haveItems(props.lists) &&
            props.lists.map(({ title, items }) => (
              <ContentItem key={title}>
                <Detail>{title}</Detail>
                {haveItems(items) && (
                  <List>
                    {items.map((item) => (
                      <ListItem key={item}>{item}</ListItem>
                    ))}
                  </List>
                )}
              </ContentItem>
            ))}
        </TextContainer>
        <DismissButton onClick={onClickDismiss} />
      </StyledAnimatedLi>
    );
  })
)``;

Alert.propTypes = {
  message: PropTypes.string,
  detail: PropTypes.string,
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  loading: PropTypes.bool,
  onClickDismiss: PropTypes.func,
  variant: PropTypes.string,
};

Alert.defaultProps = {
  variant: 'primary',
};

const haveItems = (array) => array !== undefined && array.length > 0;
