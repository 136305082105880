import * as Sentry from '@sentry/react';

/* eslint-disable no-console */
const sentry = (store) => (next) => (action) => {
  Sentry.addBreadcrumb({
    category: 'redux.action',
    message: `dispatching ${action.type}`,
    level: 'info',
  });
  return next(action);
};

export default sentry;
