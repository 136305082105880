import { decodeURIValue } from './encoding';

export const parseUrlParams = (url) => {
  try {
    url = new URL(url);
  } catch (error) {
    return;
  }

  return Array.from(url.searchParams.entries())
    .map(([key, value]) => [key, decodeURIValue(value)])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};
