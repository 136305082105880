import { ButtonCircleArrow, InputCurrency, LayoutStepRowSingle } from '@foyyay/flow-elements';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useCurrencyFormatter } from '@shared/hooks/useCurrencyFormatter';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import React, { useState } from 'react';
import { FlowStep } from '../components/FlowStep';

export const StepAmount = (props) => {
  const formatCurrency = useCurrencyFormatter();
  const defaultValue = '';
  const initialValue = props.data?.value ?? defaultValue;

  const message = `I'd like to give ${formatCurrency(initialValue)}`;

  const min = 100; // $1.00

  return (
    <FlowStep message={message}>
      <AmountInput
        value={initialValue}
        label={props.config.label}
        onComplete={props.completeStep}
        onIncomplete={props.incompleteStep}
        onSave={props.saveStep}
        required={props.config.required}
        suggestedLength={props.config.suggestedLength}
        min={min}
      />
    </FlowStep>
  );
};

const AmountInput = (props) => {
  const initialState = props.value ? String(props.value / 100) : '';
  const [inputAmount, setInputAmount] = useState(initialState);

  const handleComplete = () => {
    let amount = Math.round(inputAmount * 100);

    if (amount < props.min) {
      amount = props.min;
      setInputAmount(String(amount / 100));
    }

    props.onComplete({
      value: amount,
    });
  };

  const handleChange = (value) => setInputAmount(value);
  const handleClick = useClickHandler(handleComplete);
  const handleSubmit = useSubmitHandler(handleComplete);
  const handleEnter = useSubmitHandler(handleComplete);

  const amountIsValid = Math.round(inputAmount * 100) >= 100;

  return (
    <LayoutStepRowSingle>
      <form onSubmit={handleSubmit}>
        <InputCurrency
          id="amount"
          name="amount"
          autoComplete="transaction-amount"
          value={inputAmount}
          onChange={handleChange}
          onEnter={handleEnter}
        />
      </form>
      <div>{amountIsValid && <ButtonCircleArrow onClick={handleClick} />}</div>
    </LayoutStepRowSingle>
  );
};
