import { createReducer } from '@reduxjs/toolkit';
import { STATE_IDLE, STATE_PENDING, STATE_REJECTED, STATE_SUCCESS } from '@shared/constants/state';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { clearError, setProgress, uploadFiles } from '../actions/FileUploadButtonActions';

const defaultState = deepFreeze({
  asyncState: STATE_IDLE,
  errorMessage: undefined,
  progress: undefined,
});

export const init = (value) => ({
  ...defaultState,
  asyncState: value === undefined ? STATE_IDLE : STATE_SUCCESS,
});

export const fileUploadButtonReducer = createReducer(defaultState, {
  [uploadFiles.pending]: (state, action) => ({
    ...state,
    asyncState: STATE_PENDING,
    errorMessage: undefined,
    progress: 0,
  }),
  [uploadFiles.fulfilled]: (state, action) => ({
    ...state,
    asyncState: STATE_SUCCESS,
    errorMessage: undefined,
    progress: 100,
  }),
  [uploadFiles.rejected]: (state, action) => ({
    ...state,
    asyncState: STATE_REJECTED,
    errorMessage: action.error.message,
  }),
  [clearError]: (state, action) => ({
    ...state,
    asyncState: STATE_IDLE,
    errorMessage: undefined,
  }),
  [setProgress]: (state, action) => ({
    ...state,
    progress: action.payload,
  }),
});
