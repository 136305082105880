import { ButtonFile } from '@foyyay/flow-elements';
import { unwrapResult } from '@reduxjs/toolkit';
import { STATE_AUTHENTICATED, STATE_PENDING, STATE_REJECTED, STATE_UNAUTHENTICATED } from '@shared/constants/state';
import { useClickHandler } from '@shared/hooks/useClickHandler';
import { useSubmitHandler } from '@shared/hooks/useSubmitHandler';
import { useThunkReducer } from '@shared/hooks/useThunkReducer';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { clearError, uploadFiles } from './actions/FileUploadButtonActions';
import { fileUploadButtonReducer, init } from './reducers/FileUploadButtonReducer';

export const FileUploadButton = (props) => {
  const [state, dispatch] = useThunkReducer(fileUploadButtonReducer, {
    devToolsName: props.devToolsName,
    initializerArg: props.value,
    initializer: init,
  });

  if (state.asyncState === STATE_PENDING) {
    return <FileUploading progress={state.progress} />;
  }

  if (state.asyncState === STATE_REJECTED) {
    return <FileError errorMessage={state.errorMessage} dispatch={dispatch} />;
  }

  return (
    <FileBrowse
      acceptedMimeTypes={props.acceptedMimeTypes}
      allowMultiple={props.allowMultiple}
      authState={props.authState}
      dispatch={dispatch}
      flowId={props.flowId}
      maxFiles={props.maxFiles}
      onUpload={props.onUpload}
    />
  );
};

FileUploadButton.propTypes = {
  acceptedMimeTypes: PropTypes.array,
  allowMultiple: PropTypes.bool,
  authState: PropTypes.oneOf([STATE_AUTHENTICATED, STATE_UNAUTHENTICATED]),
  flowId: PropTypes.string,
  maxFiles: PropTypes.number,
  onUpload: PropTypes.func,
};

const FileBrowse = (props) => {
  const inputRef = useRef();

  const handleClick = useClickHandler(() => inputRef.current.click());

  const handleChange = async (e) => {
    const files = e.target.files || e.dataTransfer.files;

    const args = {
      acceptedMimeTypes: props.acceptedMimeTypes,
      allowMultiple: props.allowMultiple,
      authState: props.authState,
      files: files,
      flowId: props.flowId,
      maxFiles: props.maxFiles,
    };

    props
      .dispatch(uploadFiles(args))
      .then(unwrapResult)
      .then((originalPromiseResult) => props.onUpload(originalPromiseResult))
      .catch((rejectedValueOrSerializedError) => {});
  };

  const handleSubmit = useSubmitHandler(handleChange);

  return (
    <>
      <ButtonFile.Upload onClick={handleClick} onDrop={handleChange}>
        Choose a file
      </ButtonFile.Upload>
      <HiddenForm onSubmit={handleSubmit}>
        <input
          type="file"
          accept={props.acceptedMimeTypes}
          multiple={props.allowMultiple}
          onChange={handleChange}
          ref={inputRef}
        />
      </HiddenForm>
    </>
  );
};

const HiddenForm = styled.form`
  display: none;
`;

const FileUploading = (props) => {
  return <ButtonFile.Uploading progress={props.progress}>Uploading...</ButtonFile.Uploading>;
};

const FileError = (props) => {
  const handleClearErrorClick = useClickHandler(() => props.dispatch(clearError()));

  return (
    <ButtonFile.Error
      errorMessage={props.errorMessage}
      onClick={handleClearErrorClick}
    >{`Upload Failed :(`}</ButtonFile.Error>
  );
};

export const FileButton = (props) => {
  return (
    <ButtonFile.Success filename={props.filename} onClick={props.onClick}>
      File Uploaded!
    </ButtonFile.Success>
  );
};

FileButton.propTypes = {
  filename: PropTypes.string,
  onClick: PropTypes.func,
};
