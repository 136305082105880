import { font, getThemeValue, media } from '@foyyay/flow-elements';
import Color from 'color';
import { partial as _partial } from 'lodash';
import React from 'react';
import styled from 'styled-components';

export const PrayerTimeHeader = React.forwardRef((props, ref) => {
  return (
    <HeaderElement>
      <HeaderTitle>PrayerTime</HeaderTitle>
      <HeaderFade />
    </HeaderElement>
  );
});

const getPrayerTimeThemeValue = _partial(getThemeValue, 'PrayerTime.Color.Default');

const HeaderElement = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2147483005;
  min-width: 320px;
  padding-top: 3.2rem;
  background: ${getPrayerTimeThemeValue('Background')};
  pointer-events: none;

  ${media.tabletLandscapeAndUp`
    padding-top: 2.7rem;
  `}
`;

const HeaderTitle = styled.h1`
  ${font(14, 'Bold', -0.5, 14)}
  margin: 0;
  text-align: center;
  opacity: 0.5;

  ${media.tabletLandscapeAndUp`
    ${font(16, 'Bold', -0.75, 16)}
  `}
`;

const HeaderFade = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to top,
    ${(props) => Color(props.theme.PrayerTime.Color.Default.Background).alpha(0).string()} 0%,
    ${getPrayerTimeThemeValue('Background')} 95%
  );
  width: 100%;
  height: 6rem;
  transform: translateY(99%);
`;
