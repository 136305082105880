import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { Styles } from './Typography';

export class InputPhone extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // This ref is set by Cleave
    this.inputRef = undefined;
  }

  focus = () => {
    this.inputRef.focus();
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.props.onEnter) {
      this.props.onEnter(e);
    }
  };

  handleChange = (e) => {
    this.props.onChange(e.target.value.trim());
  };

  render() {
    const { value, onChange, onEnter, ...otherProps } = this.props;

    return (
      <InputF3
        as={Cleave}
        options={{
          numericOnly: true,
          blocks: [0, 3, 0, 3, 4, 50],
          delimiters: ['(', ')', ' ', '-', ' '],
        }}
        type="text"
        inputMode="decimal"
        autoComplete="off"
        placeholder="(555) 555-5555"
        value={value}
        onChange={this.handleChange}
        onKeyPress={this.handleKeyPress}
        htmlRef={(ref) => (this.inputRef = ref)}
        {...otherProps}
      />
    );
  }
}

const InputF3 = styled(Input)`
  ${Styles.F3};
  color: ${({ theme }) => theme.InputPhone.Color.Default};
  height: 3rem;
`;
