import parseISO from 'date-fns/parseISO';
import { getParentWindow } from './getParentWindow';

interface Item {
  value: any;
  expiresAt?: string;
}

const getItem = <T = any>(key: string): T | undefined => {
  const window = getParentWindow();
  const item = window.localStorage.getItem(key);
  if (item === null) {
    return;
  }

  try {
    const parsed: Item = JSON.parse(item);
    if (isExpired(parsed.expiresAt)) {
      window.localStorage.removeItem(key);
      return;
    }

    return parsed.value;
  } catch (error) {
    return;
  }
};

const setItem = (key: string, value: any, expiresAt?: string): void => {
  const window = getParentWindow();
  window.localStorage.setItem(
    key,
    JSON.stringify({
      value: value,
      expiresAt: expiresAt,
    })
  );
};

const isExpired = (expiresAt?: string): boolean => {
  if (expiresAt === undefined) {
    return false;
  }

  try {
    if (parseISO(expiresAt).getTime() < Date.now()) {
      return true;
    }
  } catch (error) {
    return true;
  }

  return false;
};

export const LocalStorage = {
  getItem: getItem,
  setItem: setItem,
};
