import { getThemeValue, L4, media } from '@foyyay/flow-elements';
import { ControllerContext } from '@shared/context/ControllerContext';
import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { AnimatedScrollContainer } from './AnimatedScrollContainer';
import { StaggeredFade } from './StaggeredFade';

const getScopedThemeValue = _partial(getThemeValue, 'FlowLayout');

export const Wrapper = styled.div`
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

export const FlowLayout = (props) => {
  const { scrollContainerRef } = useContext(ControllerContext);
  const showTitle = props.showTitle === true && props.title !== undefined && props.title !== '';
  const showLogo = props.showLogo === true && props.logoFileUrl !== undefined;
  const showLogoPlaceholder = props.showLogo === true && props.logoFileUrl === undefined;

  return (
    <Body>
      <AnimatedScrollContainer ref={scrollContainerRef}>
        <Content>
          <StaggeredFade>
            {showLogo && (
              <LogoContainer>
                <Logo src={props.logoFileUrl} alt={props.logoAlt} />
              </LogoContainer>
            )}
            {showLogoPlaceholder && <LogoPlaceholder />}
            {showTitle && <Title>{props.title}</Title>}
            <CenterColumn $hAlign={props.contentAlignH} $vAlign={props.contentAlignV} $padding={props.contentPadding}>
              <Container>{props.children}</Container>
            </CenterColumn>
          </StaggeredFade>
        </Content>
      </AnimatedScrollContainer>
    </Body>
  );
};

FlowLayout.propTypes = {
  children: PropTypes.node,
  logoAlt: PropTypes.string,
  logoFileUrl: PropTypes.string,
  showLogo: PropTypes.bool,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  contentPadding: PropTypes.string,
  contentAlignH: PropTypes.string,
  contentAlignV: PropTypes.string,
};

FlowLayout.defaultProps = {
  logoAlt: 'Church Logo',
  showLogo: true,
  showTitle: true,
  contentAlignV: 'flex-start',
  contentAlignH: 'center',
};

const Body = styled.div`
  position: relative;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  padding-top: max(7rem, 8vh);

  ${media.tabletLandscapeAndUp`
    padding-top: max(7rem, 10vh);
  `}
`;

const LogoPlaceholder = styled.div`
  margin-top: 2rem;
`;

const LogoContainer = styled.div`
  text-align: center;
  margin: 0.4rem auto 0;
  width: 14rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  flex: 0 0 auto;

  ${media.tabletLandscapeAndUp`
    width: 20rem;
    height: 9rem;
  `}
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Title = styled(L4)`
  color: ${getScopedThemeValue('Title.Color.Default.Foreground')};
  text-align: center;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.$vAlign ?? 'center'};
  align-items: ${(props) => props.$hAlign ?? 'center'};
  flex: 1 0 auto;
  padding: ${(props) => props.$padding ?? ''};
`;

const HORIZ_PAD = '1.2rem';

const Container = styled.div`
  width: 100%;
  max-width: calc(44rem + ${HORIZ_PAD} * 2);
  padding-left: ${HORIZ_PAD};
  padding-right: ${HORIZ_PAD};
  min-height: 40vh;

  ${media.tabletLandscapeAndUp`
    max-width: calc(58rem + ${HORIZ_PAD} * 2);
    min-height: 37vh;
  `}
`;
