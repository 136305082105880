import { ButtonCircleArrow, LabelStep, LayoutStepRowSingle, Step, StepInput } from '@foyyay/flow-elements';
import { signIn } from '@shared/actions/user';
import { FLOW_SUBMISSION_LABEL_BY } from '@shared/constants/flow';
import { selectFlowConfigById } from '@shared/reducers/flows/flowsByIdReducer';
import { Auth } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FlowContext } from '../../..';
import { useThankYouController } from '../../../controllers/ThankYouController';
import { SectionText } from '../components/SectionText';

export const EnterPassword = (props) => {
  const { user, setUser } = useThankYouController();
  const { currentFlowId } = useContext(FlowContext);
  const flowConfig = useSelector((state) => selectFlowConfigById(state, currentFlowId));

  const initialState = {
    processing: false,
    error: undefined,
  };

  const [state, setState] = useState(initialState);

  const dispatch = useDispatch();
  const history = useHistory();

  const handlePasswordChange = (value) => {
    setUser((prevState) => ({
      ...prevState,
      password: value,
    }));
  };

  const handleSignIn = async () => {
    setState({ processing: true });

    try {
      await dispatch(signIn(user.email, user.password));
    } catch (error) {
      setState({
        processing: false,
        error: 'Sorry, wrong password',
      });
      return;
    }

    history.replace('/thank-you', { currentStep: 'welcome' });
  };

  const handleResetPasswordClick = async () => {
    try {
      Auth.forgotPassword(user.email);
      history.replace('/thank-you', { currentStep: 'reset-password' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SectionText title="Welcome Back!">
        Simply enter your password to save this {FLOW_SUBMISSION_LABEL_BY[flowConfig.flow_type]} to your account. Can’t
        remember it? No worries, just{' '}
        <TextLink onClick={handleResetPasswordClick}>request a reset&nbsp;email.</TextLink>
      </SectionText>
      <SectionEmail>
        <Step>
          <LabelStep absolute error={state.error}>
            Enter Your Password
          </LabelStep>
          <LayoutStepRowSingle>
            <form onSubmit={handleSignIn}>
              <HiddenInput id="email" name="email" type="email" autoComplete="username" value={user.email} readOnly />
              <StepInput
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="password"
                value={user.password}
                onChange={handlePasswordChange}
                onEnter={handleSignIn}
                isValid={!state.error}
                error={state.error}
              />
            </form>
            <ButtonCircleArrow onClick={handleSignIn} loading={state.processing} />
          </LayoutStepRowSingle>
        </Step>
      </SectionEmail>
    </>
  );
};

const SectionEmail = styled.div`
  max-width: 58rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.2rem;
`;

const HiddenInput = styled.input`
  display: none;
`;

const TextLink = styled.span`
  text-decoration: underline;
  transition: opacity 150ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
