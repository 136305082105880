import React from 'react';
import styled from 'styled-components';
import { media } from './style-utils';

const F2Container = styled.div`
  align-items: center;
  display: flex;
  height: 3.6rem;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    height: 4.6rem;
  `}
`;

const F3Container = styled.div`
  align-items: center;
  display: flex;
  height: 2.4rem;
  overflow: hidden;

  ${media.tabletLandscapeAndUp`
    height: 3rem;
  `}
`;

const BankAccountNumber = (props) => {
  return <F2Container></F2Container>;
};

const BankRoutingNumber = (props) => {
  return <F3Container></F3Container>;
};

const BankAccountHolderName = (props) => {
  return <F3Container></F3Container>;
};

export const InputBankPlaceholder = (props) => {
  return (
    <GridContainer>
      <BankAccountNumber />
      <FlexContainer>
        <BankRoutingNumber />
        <BankAccountHolderName />
      </FlexContainer>
    </GridContainer>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 2rem;
  margin-bottom: 3.5rem;

  ${media.tabletLandscapeAndUp`
    grid-row-gap: 1.6rem;
    margin-bottom: 4.9rem;
  `}

  ${F2Container} {
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;
