import { AnimatedSwitch } from '@shared/components/AnimatedSwitch';
import React, { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { Account } from '../Account';
import { SpringboardContext } from './context/SpringboardContext';
import { Home } from './routes/Home/Home';
import { InfoCard } from './routes/InfoCard/InfoCard';
import { Search } from './routes/Search/Search';

export const Routes = (): JSX.Element => {
  const history = useHistory();
  const context = useContext<any>(SpringboardContext);
  return (
    <AnimatedSwitch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/search">
        <Search />
      </Route>
      <Route exact path="/info/:id">
        <InfoCard />
      </Route>
      <Route path="/account/:path">
        <Account goBack={history.goBack} linkArgs={{ launcher: context.launcher }} />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </AnimatedSwitch>
  );
};
