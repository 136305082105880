export function withStopPropagation(func) {
  return function (e, ...args) {
    e.stopPropagation();
    func(...args);
  };
}

export function withPreventDefault(func) {
  return function (e, ...args) {
    e.preventDefault();
    func(...args);
  };
}
