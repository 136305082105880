import { createAction } from '@reduxjs/toolkit';

export const setCurrentStep = createAction('StepMultipleChoice/currentStep/set');
export const setSelectedOptions = createAction('StepMultipleChoice/selectedOptions/set');
export const addCustomOption = createAction('StepMultipleChoice/customOption/add');
export const selectOption = createAction('StepMultipleChoice/option/select');
export const deselectOption = createAction('StepMultipleChoice/option/deselect');
export const setOptionQuantity = createAction('StepMultipleChoice/option/quantity/set', (option, quantity) => ({
  payload: {
    option: option,
    quantity: quantity,
  },
}));
