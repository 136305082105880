import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from './Button';
import { ButtonOptionBadge } from './ButtonOptionBadge';
import { booleanPropHelperFactory, conditionalHelperFactory, font, media } from './style-utils';
import { TextMarqueeProvider, TextOverflowMarquee, useTextMarquee } from './TextOverflowMarquee';
import { getThemeValue } from './Theme';

const getScopedThemeValue = _partial(getThemeValue, 'ButtonOptionAlternate.Color');

const getButtonThemeValue = (path) => (props) => {
  if (props.checked) {
    return getScopedThemeValue('Checked', path)(props);
  }
  return getScopedThemeValue(path)(props);
};

export const ButtonOptionAlternate = (props) => {
  const { alignText, children, clickable, badge, subtext, checked, right, ...otherProps } = props;

  // Align based on group if provided
  const textAlignment = alignText || (badge || right ? 'left' : 'center');

  const variant = props.checked ? 'primary' : 'secondary';

  const [providerProps, targetProps] = useTextMarquee();

  return (
    <TextMarqueeProvider {...providerProps}>
      <OverlayRight clickable={props.clickable} node={props.right}>
        <CheckableButton checked={checked} {...otherProps} {...targetProps}>
          <Background />
          <Fill />
          <Foreground>
            <ButtonContent alignText={textAlignment} badge={badge} subtext={subtext} variant={variant} right={right}>
              {children}
            </ButtonContent>
          </Foreground>
        </CheckableButton>
      </OverlayRight>
    </TextMarqueeProvider>
  );
};

ButtonOptionAlternate.propTypes = {
  alignText: PropTypes.oneOf(['center', 'left']),
  badge: PropTypes.node,
  checked: PropTypes.bool,
  children: PropTypes.node,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  right: PropTypes.node,
  subtext: PropTypes.node,
};

ButtonOptionAlternate.defaultProps = {
  checked: false,
  clickable: true,
};

const ButtonContent = (props) => {
  const hasRightOverlay = props.right !== undefined;
  const hasBadge = Boolean(props.badge);
  const hasSubtext = Boolean(props.subtext);

  return (
    <ButtonContentContainer alignText={props.alignText} hasRightOverlay={hasRightOverlay}>
      <ButtonLabel alignText={props.alignText}>
        <TextOverflowMarquee>{props.children}</TextOverflowMarquee>
        {hasSubtext && (
          <Subtext>
            <TextOverflowMarquee>{props.subtext}</TextOverflowMarquee>
          </Subtext>
        )}
      </ButtonLabel>
      {hasBadge && <ButtonOptionBadge variant={props.variant}>{props.badge}</ButtonOptionBadge>}
    </ButtonContentContainer>
  );
};

const hasRightOverlay = booleanPropHelperFactory('hasRightOverlay');
const isLeftAligned = conditionalHelperFactory((props) => props.alignText === 'left');

const ButtonContentContainer = styled.div`
  ${isLeftAligned(css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.5rem;
    text-align: left;
  `)};

  ${hasRightOverlay(css`
    padding-right: 2.5rem;
  `)};

  & > *:first-child {
    flex: 0 1 auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & + * {
    z-index: 1;
  }
`;

const Fill = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0.6rem;

  & + * {
    z-index: 1;
  }
`;

const Foreground = styled.div`
  width: 100%;
`;

const ALIGN_BY = {
  center: 'center',
  left: 'flex-start',
};

const ButtonLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => ALIGN_BY[props.alignText]};

  ${font(22, 'Medium', -0.5)}

  ${media.tabletLandscapeAndUp`
    ${font(24, 'Medium', -0.5)}
  `};
`;

const Subtext = styled.span`
  ${font(11, 'Medium', -0.28)}
  margin-top: -0.2rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CheckableButton = styled(Button)`
  border-radius: 2.5rem;
  color: ${getButtonThemeValue('Default.Foreground')};
  height: 5rem;
  position: relative;

  > ${Background} {
    border-radius: 2.5rem;
    border: 3px solid ${getButtonThemeValue('Default.Border')};
    background-color: transparent;
  }

  > ${Fill} {
    border-radius: 2.5rem;
    background-color: ${getButtonThemeValue('Default.Background')};
  }

  :not([disabled]) {
    :active {
      transform: scale(0.94);
    }

    :focus > ${Background} {
      border-color: ${getButtonThemeValue('Hover.Border')};
    }

    :focus > ${Fill} {
      background-color: ${getButtonThemeValue('Hover.Background')};
    }

    @media (hover: hover) {
      :hover {
        color: ${getButtonThemeValue('Hover.Foreground')};

        > ${Background} {
          border-color: ${getButtonThemeValue('Hover.Border')};
        }

        > ${Fill} {
          background-color: ${getButtonThemeValue('Hover.Background')};
        }
      }
    }
  }

  :disabled {
    opacity: 0.25;
  }
`;

const OverlayRight = (props) => {
  const { node, ...otherProps } = props;

  if (props.node === undefined || props.node === null) {
    return props.children;
  }

  return (
    <PositionContainer {...otherProps}>
      {props.children}
      <PositionRight>{props.node}</PositionRight>
    </PositionContainer>
  );
};

const PositionRight = styled.div`
  position: absolute;
  right: 0.6rem;
  z-index: 10;
`;

const PositionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 1rem 0;

  ${CheckableButton} {
    margin: 0;
  }

  > * {
    pointer-events: ${(props) => (props.clickable ? 'auto' : 'none')};
  }

  ${PositionRight} {
    pointer-events: auto;
  }
`;
