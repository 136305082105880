import { ControllerContext } from '@shared/context/ControllerContext';
import { prayerhubByIdSelector } from '@shared/reducers/prayerhub';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Flow } from '../../Flow';
import { PrayerHubContext } from '../context/PrayerHubContext';

export const PrayerFlow = (props) => {
  const { currentPrayerHubId } = useContext(PrayerHubContext);
  const shortcode = useSelector(prayerhubByIdSelector(currentPrayerHubId))?.config?.prayerflow_shortcode;

  if (shortcode === undefined) {
    return null;
  }

  return (
    <Controller>
      <Flow shortcode={shortcode} />
    </Controller>
  );
};

const Controller = (props) => {
  const history = useHistory();
  const parent = useContext(ControllerContext);
  const context = {
    ...parent,
    goBack: () => history.goBack(),
  };
  return <ControllerContext.Provider value={context}>{props.children}</ControllerContext.Provider>;
};
