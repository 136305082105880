import { ButtonOptionSecondary, InputDate } from '@foyyay/flow-elements';
import useInputFocusRef from '@shared/hooks/useInputFocusRef';
import { americanDateStringExp, americanStringToIsoString, isoStringToAmerican } from '@shared/lib/datetime';
import { withPreventDefault, withStopPropagation } from '@shared/lib/events';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SmartStepActionButtons } from '../../../components/StepActionButtons';
import { setBirthday, setCurrentStep } from '../actions/stepSmartBirthdayActions';
import { selectBirthday } from '../reducers/stepSmartBirthdayReducer';

const init = (birthday) => {
  if (birthday === undefined) {
    return '';
  }
  return isoStringToAmerican(birthday);
};

const isValidDate = (value) => {
  const now = new Date();
  if (americanDateStringExp.test(value) === false) {
    return false;
  }
  const date = new Date(value);
  if (date > now) {
    return false;
  }
  return true;
};

export const StepSmartBirthdayInput = (props) => {
  const { useSelector, dispatch, onComplete } = props;

  const inputRef = useInputFocusRef(props.id, props.currentStep);

  const birthday = useSelector(selectBirthday);
  const initialState = init(birthday);
  const [input, setInput] = useState(initialState);

  const handleChange = (value) => setInput(value);

  const readyToContinue = isValidDate(input);

  const handleComplete = () => {
    if (!readyToContinue) {
      return;
    }
    const isoString = americanStringToIsoString(input);
    dispatch(setBirthday(isoString));
    onComplete({
      value: isoString,
    });
  };

  const handleTypeClick = () => dispatch(setCurrentStep('picker'));
  const handleSubmit = withPreventDefault(handleComplete);
  const handleEnter = withPreventDefault(handleComplete);
  const handleSaveClick = withStopPropagation(handleComplete);
  const handleSkipClick = withStopPropagation(() => {
    onComplete({
      skipped: true,
      value: undefined,
    });
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputWrapper>
          <InputDate value={input} onChange={handleChange} onEnter={handleEnter} ref={inputRef} />
          <ButtonOptionSecondary onClick={handleTypeClick} role="button">
            Switch to date picker
          </ButtonOptionSecondary>
        </InputWrapper>
      </form>
      <SmartStepActionButtons
        onContinueClick={handleSaveClick}
        onSkipClick={handleSkipClick}
        readyToContinue={readyToContinue}
        required={props.required}
      />
    </>
  );
};

const InputWrapper = styled.div`
  margin-bottom: 3rem;
`;
