import { ButtonOptionPrimary, ButtonOptionSecondary, H2, Prompt, PromptActions, T1 } from '@foyyay/flow-elements';
import { ModalOverlay } from '@shared/components/Modal';
import React from 'react';

export const ModalDialogStepOptionMoreInfo = (props) => {
  return (
    <ModalOverlay show={props.open}>
      <Prompt>
        <H2>{props.option.headline ?? props.option.label}</H2>
        <T1>{props.option.description}</T1>
        <PromptActions>
          <ButtonOptionPrimary onClick={props.onContinueClick}>Select & Continue</ButtonOptionPrimary>
          <ButtonOptionSecondary onClick={props.onCloseClick}>Or, pick another option</ButtonOptionSecondary>
        </PromptActions>
      </Prompt>
    </ModalOverlay>
  );
};
