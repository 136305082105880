import { ButtonOptionPrimary, ButtonOptionSecondary } from '@foyyay/flow-elements';
import { withStopPropagation } from '@shared/lib/events';
import React, { useMemo } from 'react';
import { FlowStep } from '../../components/FlowStep';

export const StepSingleContact = (props) => {
  const message = useMemo(() => {
    if (props.data?.skipped === true) {
      return ['Skipped: ', props.config.label].join(' ').trim();
    }
    return props.config.label;
  }, [props.config.label, props.data]);

  const handleSaveClick = withStopPropagation(() => {
    props.completeStep({
      addTheirInformation: true,
      value: 'Add information',
    });
  });

  const handleSkipClick = withStopPropagation(() => {
    props.completeStep({
      addTheirInformation: false,
      skipped: true,
      value: undefined,
    });
  });

  return (
    <FlowStep message={message}>
      <ButtonOptionPrimary onClick={handleSaveClick}>Add information</ButtonOptionPrimary>
      {props.config.required !== true && (
        <ButtonOptionSecondary onClick={handleSkipClick}>or, Skip &amp; Continue</ButtonOptionSecondary>
      )}
    </FlowStep>
  );
};
