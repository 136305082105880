import * as dateFns from 'date-fns';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  DAYS_OF_WEEK,
  INTERVAL,
  INTERVALS,
  INTERVALS_SCHEDULED_BY_DATE,
  INTERVALS_SCHEDULED_BY_DATES,
  INTERVAL_EVERY_FIFTH_AND_TWENTIETH,
  INTERVAL_EVERY_FIRST_AND_FIFTEENTH,
  QUICK_INTERVALS,
  SCHEDULE_CUSTOM,
  SCHEDULE_NEXT_FRIDAY,
  SCHEDULE_NEXT_MONTH,
  SCHEDULE_THIS_FRIDAY,
  SCHEDULE_THIS_MONTH,
  SCHEDULE_THIS_SUNDAY,
  SCHEDULE_TODAY,
  SCHEDULE_TOMORROW,
  SELECTED_INTERVAL_EXPIRES_AFTER,
} from '../constants';
import { dateToIsoString, isoStringToDate } from './datetime';

export const getIntervals = (quickIntervals) => {
  let intervals = _cloneDeep(INTERVALS);
  // get the choices from the Flow, or just use "Every First and fifteenth"
  let quickIntervalChoices = [INTERVAL_EVERY_FIRST_AND_FIFTEENTH, INTERVAL_EVERY_FIFTH_AND_TWENTIETH]; // allow default?

  if (quickIntervals !== undefined) {
    quickIntervalChoices = quickIntervals;
  }

  // map overs the two quick intervals and exclude ones not chosen (this will allow the default)
  intervals = intervals.filter((interval) => {
    if (QUICK_INTERVALS.includes(interval.label)) {
      return quickIntervalChoices.includes(interval.label);
    }
    return true;
  });

  return intervals;
};

export const getStartDateOptionsForInterval = (interval) => {
  const MAP_INTERVALS_TO_GET_SCHEDULE = [
    { intervals: INTERVALS_SCHEDULED_BY_DATE, getStartDate: getStartDateOptionsForIntervalRegular },
    { intervals: INTERVALS_SCHEDULED_BY_DATES, getStartDate: getStartDateOptionsForIntervalDates },
  ];
  const match = MAP_INTERVALS_TO_GET_SCHEDULE.find((o) => o.intervals.includes(interval.intervalType));

  if (match === undefined) {
    return [];
  }

  return match.getStartDate(interval);
};

export const isSelectedStartDateValid = (startDate, interval) => {
  if (startDate === undefined) {
    return false;
  }

  if (startDate.expiresAt === undefined) {
    return false;
  }

  if (startDate.expiresAt < Date.now()) {
    return false;
  }

  if (!isStartDateValidForInterval(startDate, interval)) {
    return false;
  }

  return true;
};

export const isStartDateValidForInterval = (startDate, interval) => {
  if (interval.intervalType === INTERVAL.DATES) {
    if (!interval.intervalDates.includes(isoStringToDate(startDate.date).getDate())) {
      return false;
    }
  }
  if (startDate.label === SCHEDULE_CUSTOM) {
    return true;
  }
  const startDateOptions = getStartDateOptionsForInterval(interval);
  return startDateOptions.some((option) => option.date === startDate.date);
};

const getStartDateOptionsForIntervalRegular = () => {
  const dateToday = new Date();
  const dayToday = dateFns.getDay(dateToday);
  const dateTomorrow = dateFns.addDays(dateToday, 1);

  const startDateOptions = [
    {
      label: SCHEDULE_TODAY,
      date: dateToIsoString(dateToday),
    },
    {
      label: SCHEDULE_TOMORROW,
      date: dateToIsoString(dateTomorrow),
    },
  ];

  if (!dateFns.isFriday(dateToday) && !dateFns.isFriday(dateTomorrow)) {
    const dayFriday = 5;
    const isSaturday = dateFns.isSaturday(dateToday);
    const daysToFriday = daysUntilWeekday(dayFriday, dayToday);

    const nameFriday = isSaturday ? SCHEDULE_NEXT_FRIDAY : SCHEDULE_THIS_FRIDAY;
    const dateFriday = dateFns.addDays(dateToday, daysToFriday);

    // Friday Option
    const fridayOption = {
      label: nameFriday,
      date: dateToIsoString(dateFriday),
    };

    startDateOptions.push(fridayOption);
  }

  if (!dateFns.isSunday(dateToday) && !dateFns.isSunday(dateTomorrow)) {
    const daySunday = 0;
    const daysToSunday = daysUntilWeekday(daySunday, dayToday);

    const dateSunday = dateFns.addDays(dateToday, daysToSunday);

    // Sunday Option
    const sundayOption = {
      label: SCHEDULE_THIS_SUNDAY,
      date: dateToIsoString(dateSunday),
    };

    startDateOptions.push(sundayOption);
  }

  const nameNextWeek = `Next ${DAYS_OF_WEEK[dayToday]}`;
  const dateNextWeek = dateFns.addWeeks(dateToday, 1);

  // Same Day Next Week Option
  const nextWeekOption = {
    label: nameNextWeek,
    date: dateToIsoString(dateNextWeek),
  };

  startDateOptions.push(nextWeekOption);

  startDateOptions.forEach((option, index) => (option.id = index));

  return startDateOptions;
};

const getStartDateOptionsForIntervalDates = (interval) => {
  const dateToday = new Date();
  const nextMonth = dateFns.addMonths(dateToday, 1);
  const dateNextMonth = dateFns.setDate(nextMonth, Math.min(...interval.intervalDates));
  const showTodayOption = interval.intervalDates.includes(dateToday.getDate());

  const startDateOptions = [];

  if (showTodayOption === true) {
    const todayOption = {
      label: SCHEDULE_TODAY,
      date: dateToIsoString(dateToday),
    };

    startDateOptions.push(todayOption);
  }

  if (showTodayOption === false) {
    const remainingDates = interval.intervalDates.filter((i) => Number(i) > dateToday.getDate());
    const showThisMonthOption = remainingDates.length > 0;

    if (showThisMonthOption === true) {
      const dateThisMonth = dateFns.setDate(dateToday, Math.min(...remainingDates));

      const thisMonthOption = {
        label: SCHEDULE_THIS_MONTH,
        date: dateToIsoString(dateThisMonth),
      };

      startDateOptions.push(thisMonthOption);
    }
  }

  const nextMonthOption = {
    label: SCHEDULE_NEXT_MONTH,
    date: dateToIsoString(dateNextMonth),
  };

  startDateOptions.push(nextMonthOption);

  startDateOptions.forEach((option, index) => (option.id = index));

  return startDateOptions;
};

export const getStartDateExpiresAt = () => {
  const now = Date.now();
  const endOfDay = dateFns.endOfDay(now).getTime();
  const expiresAt = now + SELECTED_INTERVAL_EXPIRES_AFTER;

  return Math.min(endOfDay, expiresAt);
};

const daysUntilWeekday = (weekDay, dayToday) => (weekDay - dayToday + 7) % 7;
