import { DescriptionStep, L1, LabelStep, Step, StepAlert } from '@foyyay/flow-elements';
import { useScrollToRef } from '@shared/hooks/useScrollToStepRef';
import { withStopPropagation } from '@shared/lib/events';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { StepInfoBanner } from './StepInfoBanner';

export const FlowStepContext = React.createContext({});

export const FlowStep = (props) => {
  const stepContext = useContext(FlowStepContext);

  const description = props.description ?? stepContext.description;
  const label = props.label === null ? undefined : props.label ?? stepContext.label;
  const labelPosition = props.labelPosition ?? stepContext.labelPosition;

  const collapsed = stepContext.expanded !== true;
  const showOuterLabel = !collapsed && label !== undefined && label !== '' && labelPosition === 'outside';
  const showInnerLabel = label !== undefined && label !== '' && labelPosition === 'inside';
  const showDescription = description !== undefined && description !== '' && description.length > 0;

  const ref = useScrollToRef(stepContext.scrollToStep);

  const handleClick = withStopPropagation(stepContext.onClick);

  const hasError = props.hasError || stepContext.error !== undefined;

  const header = [props.header ?? <StepError error={props.error} />];

  if (stepContext.parentOptionLabel !== undefined) {
    header.push(<StepParentOptionLabelBanner label={stepContext.parentOptionLabel} />);
  }

  return (
    <div ref={ref}>
      {showOuterLabel && <L1 style={{ textAlign: 'center' }}>{label}</L1>}
      <Step header={header} collapsed={collapsed} hasError={hasError} message={props.message} onClick={handleClick}>
        {showInnerLabel && <LabelStep>{label}</LabelStep>}
        {showDescription && <DescriptionStep>{description}</DescriptionStep>}
        {props.children}
      </Step>
    </div>
  );
};

FlowStep.Context = FlowStepContext;

FlowStep.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  hasError: PropTypes.bool,
  header: PropTypes.node,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['outside', 'inside']),
  message: PropTypes.string,
};

FlowStep.defaultProps = {
  hasError: false,
};

const StepError = (props) => {
  if (props.error === undefined) {
    return null;
  }

  if (props.error.message === undefined) {
    return null;
  }

  let message = '';

  if (props.error.message === 'Step required') {
    message = 'This step is required';
  }

  return (
    <StepAlert label={message} variant="error">
      Please review and modify your choices below to continue.
    </StepAlert>
  );
};

const StepParentOptionLabelBanner = (props) => (
  <StepInfoBanner>
    <StepInfoBanner.Text>
      <strong>Because you chose:</strong> {props.label}
    </StepInfoBanner.Text>
  </StepInfoBanner>
);
