import { Auth } from 'aws-amplify';
import { AWS_AMPLIFY_CONFIG } from '@shared/constants/amplify';
import CSS from '@shared/css';
import { getParentWindow } from '@shared/lib/getParentWindow';
import { configureSentry } from '@shared/lib/sentry';
import rootReducer from '@shared/reducers/reducers';
import configureStore from '@shared/store/configureStore';
import { loadState, saveState } from '@shared/store/persistentStorage';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { App } from './App';

configureSentry({
  initialScope: {
    contexts: {
      app: {
        name: 'Launcher',
        context: 'Hosted',
      },
    },
    tags: {
      app: 'Launcher',
      context: 'Hosted',
    },
  },
});

Auth.configure(AWS_AMPLIFY_CONFIG);

const window = getParentWindow();
const storageProvider = window.localStorage;

const namespace = window.location.pathname;

let persistentState = {};
try {
  persistentState = loadState(storageProvider, namespace);
} catch (error) {
  //
}

const store = configureStore(persistentState);

store.subscribe(() => {
  const state = store.getState();
  const persistentState = rootReducer.getPersistentState(state);
  saveState(storageProvider, persistentState, namespace);
});

const renderApp = () => {
  ReactDOM.render(
    <>
      <CSS />
      <Provider store={store}>
        <App />
      </Provider>
    </>,
    document.getElementById('root')
  );
};
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();

/* Only register a service worker if it's supported */
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/service-worker.js');
  });
}
