import PropTypes from 'prop-types';
import { withStopPropagation } from '@shared/lib/events';
import React, { useContext } from 'react';
import { SkipButton, SkipOrContinueButton } from '../../../components/StepActionButtons';
import { selectCustomOptions, selectReadyToContinue, selectSelectedOptions } from '../reducers/multipleChoiceReducer';
import { StepMultipleChoice } from '../StepMultipleChoice';

export const Actions = (props) => {
  const { useSelector, config, onComplete } = useContext(StepMultipleChoice.Context);

  const selectedOptions = useSelector(selectSelectedOptions);
  const customOptions = useSelector(selectCustomOptions);

  const handleClickSave = withStopPropagation(() => {
    onComplete({
      customOptions: config.allowCustom ? customOptions : undefined,
      value: selectedOptions,
    });
  });

  const handleClickSkip = withStopPropagation(() => {
    onComplete({
      customOptions: config.allowCustom ? customOptions : undefined,
      skipped: true,
      value: undefined,
    });
  });

  const readyToContinue = useSelector((state) => selectReadyToContinue(state, config));

  const showButtons = config.allowMultiple || config.allowQuantity;

  if (showButtons) {
    return (
      <SkipOrContinueButton
        onClick={handleClickSave}
        onClickSkip={handleClickSkip}
        readyToContinue={readyToContinue}
        required={config.required}
        {...props}
      >
        Save &amp; Continue
      </SkipOrContinueButton>
    );
  }

  const showSkipButton = config.required === false;

  if (showSkipButton) {
    return <SkipButton onClick={handleClickSkip} {...props} />;
  }

  return null;
};

Actions.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};
