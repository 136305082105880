import { ButtonPlaid } from '@foyyay/flow-elements';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPlaidLinkToken } from '../lib/rebelpay';
import * as dateFns from 'date-fns';
import { usePlaidLink } from '@shared/hooks/usePlaidLink';
import { FrameContext } from 'react-frame-component';
import { selectIsAuthenticated } from '@shared/reducers/user';
import { ModalDialogPlaidSignIn } from '../../Flow/components/ModalDialogPlaidSignIn';

export const PlaidLink = (props) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [linkToken, linkLoading, linkError] = useLinkToken();

  if (isAuthenticated === false) {
    return <PlaidUnauthenticated />;
  }

  if (props.linked === true) {
    return <PlaidLinked message={props.message} onDisconnect={props.onDisconnect} />;
  }

  if (linkLoading === true) {
    return <PlaidLoading />;
  }

  if (linkError !== undefined) {
    return <PlaidUnavailable />;
  }

  return (
    <PlaidUnlinked
      linkToken={linkToken}
      linked={props.linked}
      message={props.message}
      onDisconnect={props.onDisconnect}
      onEvent={props.onEvent}
      onExit={props.onExit}
      onLoad={props.onLoad}
      onSuccess={props.onSuccess}
      processing={props.processing}
    />
  );
};

PlaidLink.propTypes = {
  linked: PropTypes.bool,
  message: PropTypes.string,
  onDisconnect: PropTypes.func,
  onEvent: PropTypes.func,
  onExit: PropTypes.func,
  onLoad: PropTypes.func,
  onSuccess: PropTypes.func,
  processing: PropTypes.bool,
};

PlaidLink.defaultProps = {
  linked: false,
  onDisconnect: () => {},
  onEvent: (eventName, metadata) => {},
  onExit: (error, metadata) => {},
  onLoad: () => {},
  onSuccess: (publicToken, metadata) => {},
  processing: false,
};

const PlaidUnauthenticated = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <ButtonPlaid
        key="unauthenticated"
        linked={false}
        isAuthenticated={false}
        onClick={() => setModalVisible(true)}
        available={true}
      />
      <ModalDialogPlaidSignIn show={modalVisible} onClose={() => setModalVisible(false)} />
    </>
  );
};

const PlaidLinked = (props) => {
  return (
    <ButtonPlaid
      key="linked"
      linked={true}
      loading={false}
      onClickDisconnect={props.onDisconnect}
      disabled={false}
      available={true}
    >
      {props.message}
    </ButtonPlaid>
  );
};

const PlaidLoading = () => {
  return (
    <ButtonPlaid key="loading" linked={false} loading={true} disabled={true} available={true}>
      Loading...
    </ButtonPlaid>
  );
};

const PlaidUnavailable = () => {
  return (
    <ButtonPlaid key="unavailable" linked={false} loading={false} disabled={true} available={false}>
      Plaid is currently unavailable
    </ButtonPlaid>
  );
};

const PlaidUnlinked = (props) => {
  const context = useContext(FrameContext);

  const config = {
    onEvent: props.onEvent,
    onExit: props.onExit,
    onLoad: props.onLoad,
    onSuccess: props.onSuccess,
    token: props.linkToken,
  };
  const theWindow = context?.window ?? window;
  const { open, ready, error } = usePlaidLink(config, theWindow);

  const handleClicked = (e) => {
    e.preventDefault();
    open();
  };

  const disabled = props.processing === true || ready !== true;
  const loading = props.processing === true || ready !== true;
  const available = error === undefined;

  return (
    <ButtonPlaid
      key="unlinked"
      linked={false}
      loading={loading}
      onClick={handleClicked}
      onClickDisconnect={props.onDisconnect}
      disabled={disabled}
      available={available}
    >
      {props.message}
    </ButtonPlaid>
  );
};

const LinkTokenErrorCooldownMinutes = 5;
const useLinkToken = () => {
  const user = useSelector((state) => state.user.currentUser);
  const [linkData, setLinkData] = useState({
    token: undefined,
    expiration: new Date(0),
    error: undefined,
  });
  const [loading, setLoading] = useState(undefined);

  const userId = { id: 'person_anonymous', ...user }['id'];

  const refreshToken = useCallback(async () => {
    if (loading === true) {
      return;
    }
    if (linkData.expiration > new Date()) {
      return;
    }

    setLoading(true);

    try {
      const tokenData = await getPlaidLinkToken(userId);
      setLinkData({
        token: tokenData.token,
        expiration: new Date(tokenData.expiration),
        error: undefined,
      });
    } catch (error) {
      console.error(error);
      setLinkData({
        token: undefined,
        expiration: dateFns.addMinutes(new Date(), LinkTokenErrorCooldownMinutes),
        error: error,
      });
    }

    setLoading(false);
  }, [linkData.expiration, loading, userId]);

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  return [linkData.token, loading !== false, linkData.error];
};
