import { useCallback, useState } from 'react';

export function useHistoryStack(history) {
  const initialStack = [history.location.pathname];
  const [stack, setStack] = useState(initialStack);

  const push = useCallback(
    (path) => {
      setStack([...stack, path]);
      history.replace(path);
    },
    [history, stack]
  );

  const pop = useCallback(() => {
    const next = stack.slice(0, -1);
    setStack([...next]);
    const prev = next.pop();
    history.replace(prev);
  }, [history, stack]);

  return { push: push, pop: pop, stack: stack };
}
