import { createReducer } from '@reduxjs/toolkit';
import { deepFreeze } from '@shared/lib/deepFreeze';
import { formatValueWithPrefix } from '@shared/lib/format';
import { setFirstName, setLastName, setMiddleName } from '../actions/stepSmartNameActions';

export const selectName = (state) => state;

export const selectMessage = (config, data) => {
  return formatValueWithPrefix(
    config.valuePrefixLabel,
    [data?.value?.firstName, data?.value?.middleName, data?.value?.lastName].join(' ').trim(),
    config.valuePrefixSeparator
  );
};

const initialState = deepFreeze({
  firstName: '',
  lastName: '',
  middleName: '',
});

export const init = (arg) => ({
  ...initialState,
  firstName: arg.data?.value?.firstName ?? arg.nameAttribute?.value?.first_name ?? initialState.firstName,
  lastName: arg.data?.value?.lastName ?? arg.nameAttribute?.value?.last_name ?? initialState.lastName,
  middleName: arg.data?.value?.middleName ?? arg.nameAttribute?.value?.middle_name ?? initialState.middleName,
});

export const stepSmartNameReducer = createReducer(initialState, {
  [setFirstName]: (state, action) => ({
    ...state,
    firstName: action.payload,
  }),
  [setLastName]: (state, action) => ({
    ...state,
    lastName: action.payload,
  }),
  [setMiddleName]: (state, action) => ({
    ...state,
    middleName: action.payload,
  }),
});
