export const COOKIE_LAUNCHER_DISCOVERED = 'launcher_discovered_v2';
export const COOKIE_FEATURED_ITEM_CALLOUT = 'featured_item_callout';
export const COOKIE_BANNER_DISMISSED = 'ncu_banner_dismissed';

export const DISCOVERY_MESSAGE_FREQUENCY_IN_DAYS = 1;
export const DISCOVERY_MESSAGE_FREQUENCY_IN_DAYS_WHEN_DISCOVERED = 7;
export const DISCOVERY_MESSAGE_DELAY = 3000;
export const DISCOVERY_MESSAGE_DURATION = 10000;

export const FEATURE_ITEM_CALLOUT_FREQUENCY_IN_DAYS_WHEN_ACTIVATED = 3;
export const FEATURE_ITEM_CALLOUT_FREQUENCY_IN_DAYS_WHEN_DISMISSED = 5;
export const FEATURED_ITEM_CALLOUT_DELAY = 750;

export const BANNER_DISMISS_INTERVAL_DEFAULT = 'day';
export const BANNER_DISMISS_VALUE_DEFAULT = 5;
export const BANNER_ACTION_DISMISS_INTERVAL_DEFAULT = 'day';
export const BANNER_ACTION_DISMISS_VALUE_DEFAULT = 3;

export const NUCLEUS_LAUNCHER_ACTION_TRIGGER = 'nucleuslauncher';

export const ACTION_OPEN_LAUNCHER = 'openlauncher';
export const ACTION_OPEN_LAUNCHER_ALIASES = ['', 'true', '1', 'open'];
export const ACTION_OPEN_FEATURED_ITEM = 'openfeatureditem';
export const ACTION_OPEN_SEARCH = 'opensearch';
export const ACTION_OPEN_SIGN_IN = 'opensignin';
export const ACTION_OPEN_FLOW = 'openflow';
export const ACTION_OPEN_PRAYER_HUB = 'openprayerhub';

export const enum LAUNCHER_ITEM_TYPE {
  Button = 'button',
  Collection = 'collection',
}

export const LAUNCHER_ACTIONS = [
  ACTION_OPEN_LAUNCHER,
  ACTION_OPEN_FEATURED_ITEM,
  ACTION_OPEN_SEARCH,
  ACTION_OPEN_SIGN_IN,
  ACTION_OPEN_FLOW,
];

export const LAUNCHER_HOME_MAX_ITEMS = 6;
export const LAUNCHER_HOME_RECENT_MAX_ITEMS = 2;

export const LAUNCHER_OVERLAY_HEIGHT = 700;
export const LAUNCHER_OVERLAY_WIDTH = 360;

export const PREFER_LARGER = 'PREFER_LARGER';
export const PREFER_SMALLER = 'PREFER_SMALLER';
export const PREFERRED_SIZES = Object.freeze([PREFER_LARGER, PREFER_SMALLER]);
